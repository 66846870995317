<ng-container *ngIf="service.loaded$ | async">
  <ng-container *ngIf="websiteWorking === false; else stillWorkingTemplate">
    <mat-progress-bar mode="indeterminate" *ngIf="websiteUrlControl.pending"></mat-progress-bar>
    <ng-container *ngIf="hasWebsite$ | async; else noWebsiteTemplate">
      <ng-container *ngIf="(isFacebookPage$ | async) === false; else facebookWebsiteTemplate">
        <ng-container
          *ngIf="(service.hasGrade$ | async) || (hasAllSubsectionsHidden$ | async); else stillWorkingTemplate"
        >
          <ng-container *ngIf="sameLocale$ | async; else stillWorkingTemplate">
            <snap-content-banner
              *ngIf="showBanner"
              class="hide-mobile"
              [hideControl]="controls.controls['hideContent']"
              [messageControl]="controls.controls['customizedMessage']"
              [videoUrl]="service.videoUrl$ | async"
              [customizedMessage]="service.message$ | async"
              [contentId]="service.messageId$ | async"
              [edit]="edit"
              [hideVideo]="false"
            ></snap-content-banner>

            <snap-website-homepage-subsection [edit]="edit"></snap-website-homepage-subsection>
            <snap-website-performance-subsection [edit]="edit"></snap-website-performance-subsection>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #noWebsiteTemplate>
  <snap-subsection
    heading="{{ 'WEBSITE.NO_WEBSITE' | translate }}"
    [edit]="edit"
    [hideControl]="controls.controls['hideMissingWebsiteWarning']"
  >
    <div class="not-found">
      <glxy-empty-state [size]="'small'" [widthConstraint]="false">
        <glxy-empty-state-hero>
          <mat-icon>computer</mat-icon>
        </glxy-empty-state-hero>
        <glxy-empty-state-title>
          {{ 'WEBSITE.NO_SITE_ERROR_TITLE' | translate }}
        </glxy-empty-state-title>
        <p>
          {{ 'WEBSITE.NO_SITE_ERROR_DESCRIPTION' | translate }}
          <br />
          {{ 'WEBSITE.NO_SITE_ERROR_TAGLINE' | translate }}
        </p>
        <ng-container *ngIf="edit && (snapshotService.isExpired$ | async) === false">
          <p>{{ 'WEBSITE.NO_SITE_ERROR_WEBSITE_URL' | translate }}</p>
          <div class="form-field">
            <glxy-input
              dense
              class="website-url"
              [formControl]="websiteUrlControl"
              (keyup.enter)="addWebsiteUrl()"
              placeholder="{{ 'WEBSITE.ADD_WEBSITE_URL' | translate }}"
            ></glxy-input>

            <div class="submit-button">
              <a
                mat-stroked-button
                [disabled]="websiteUrlControl.invalid || websiteUrlControl.pending"
                (click)="addWebsiteUrl()"
              >
                <mat-icon>arrow_forward</mat-icon>
              </a>
            </div>
          </div>
        </ng-container>
      </glxy-empty-state>
    </div>
  </snap-subsection>
</ng-template>

<ng-template #stillWorkingTemplate>
  <snap-still-working></snap-still-working>
</ng-template>

<ng-template #facebookWebsiteTemplate>
  <snap-subsection
    heading="{{ 'WEBSITE.NO_WEBSITE' | translate }}"
    [edit]="edit"
    [hideControl]="controls.controls['hideMissingWebsiteWarning']"
  >
    <div class="not-found">
      <glxy-empty-state [size]="'small'" [widthConstraint]="false">
        <glxy-empty-state-hero>
          <mat-icon>computer</mat-icon>
        </glxy-empty-state-hero>
        <glxy-empty-state-title>
          {{ 'WEBSITE.FACEBOOK_AS_WEBSITE_TITLE' | translate }}
        </glxy-empty-state-title>
        <p>{{ 'WEBSITE.FACEBOOK_AS_WEBSITE_DESCRIPTION' | translate }}</p>
      </glxy-empty-state>
    </div>
  </snap-subsection>
</ng-template>
