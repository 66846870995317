import {
  SectionTableCompetitorItemInterface,
  SectionTableDataInterface,
  SectionTableItem,
} from '../section-table/section-table';
import { AbstractControl } from '@angular/forms';

export function createCompetitorGradeTableItem(companyName: string, data: any): SectionTableCompetitorItemInterface {
  return {
    name: companyName,
    value: data || 0,
  };
}

export function createCompetitorGradeTable(
  competitors: SectionTableCompetitorItemInterface[],
  title: string,
  data: any,
  control?: AbstractControl,
  subtitle?: string,
): SectionTableItem {
  return {
    title: title,
    subtitle: subtitle,
    control: control,
    data:
      { ...data, hasColor: true } ||
      <SectionTableDataInterface>{
        business: 0,
        industryAverage: 0,
        industryLeader: 0,
        hasColor: true,
      },
    competitors: competitors,
  };
}
