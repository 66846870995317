<ng-container *ngIf="showAllRows$ | async; else showReducedListings">
  <ng-container
    *ngIf="listings$ | async as listings; else loading"
    [ngTemplateOutlet]="listingsTable"
    [ngTemplateOutletContext]="{ listings: listings, showingAllRows: true }"
  ></ng-container>
</ng-container>
<ng-template #showReducedListings>
  <ng-container
    *ngIf="reducedListings$ | async as listings; else loading"
    [ngTemplateOutlet]="listingsTable"
    [ngTemplateOutletContext]="{ listings: listings, showingAllRows: false }"
  ></ng-container>
</ng-template>
<ng-template #loading>
  <glxy-loading-spinner></glxy-loading-spinner>
</ng-template>
<ng-template #listingsTable let-listings="listings" let-showingAllRows="showingAllRows">
  <mat-table [dataSource]="listings">
    <ng-container matColumnDef="listingSite">
      <mat-header-cell *matHeaderCellDef>
        {{ 'LISTINGS.DETAILS.LISTING_SITE' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let listing" class="source">
        <glxy-avatar
          class="source-icon"
          [src]="listing.source?.sourceIconUrl"
          [width]="32"
          [shape]="'circle'"
        ></glxy-avatar>
        <div>
          <div *ngIf="!!listing?.listingUrl; else noListingUrl">
            <a
              target="_blank"
              class="source-name"
              [href]="listing.listingUrl"
              [trackEvent]="{
                eventName: 'view-listing',
                category: 'cta',
                action: 'click'
              }"
            >
              {{ listing?.source?.sourceName }}
            </a>
          </div>
          <ng-template #noListingUrl>
            <span class="source-name">{{ listing?.source?.sourceName }}</span>
          </ng-template>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="businessName">
      <mat-header-cell *matHeaderCellDef>
        {{ 'LISTINGS.DETAILS.BUSINESS_NAME' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let listing">
        <ng-container *ngIf="!listing?.companyName?.ignore; else ignore">
          <ng-container *ngIf="!!listing?.companyName?.value; else isMissing">
            <span [ngClass]="{ inaccurate: !listing.companyName?.match }">
              {{ listing?.companyName?.value }}
            </span>
          </ng-container>
        </ng-container>
        <ng-template #isMissing>
          <ng-container *ngIf="listing?.status === ListingStatus.NOT_FOUND && !isStillGettingData; else notReady">
            <ng-container *ngTemplateOutlet="notFound"></ng-container>
          </ng-container>
        </ng-template>
        <ng-template #notReady>
          <ng-container *ngIf="!listing.companyName?.match">
            <ng-container *ngTemplateOutlet="stillWorkingOnIt; context: { $implicit: listing }"></ng-container>
          </ng-container>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef>
        {{ 'LISTINGS.DETAILS.ADDRESS' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let listing">
        <ng-container
          *ngIf="{
            notIgnored: !(
              listing.address?.ignore &&
              listing.city?.ignore &&
              listing.state?.ignore &&
              listing.zip?.ignore
            ),
            present: listing.address?.value || listing.city?.value || listing.state?.value || listing.zip?.value,
            inaccurate: !listing.address?.match || !listing.city?.match || !listing.state?.match || !listing.zip?.match
          } as current"
        >
          <ng-container *ngIf="current.notIgnored; else ignore">
            <ng-container *ngIf="current.present; else notReady">
              <span [ngClass]="{ inaccurate: current.inaccurate }">
                <div>{{ listing.address?.value }}</div>
                <div>
                  <ng-container *ngIf="!!listing.city?.value">{{ listing.city?.value }},</ng-container>
                  {{ listing.state?.value }} {{ listing.zip?.value }}
                </div>
              </span>
            </ng-container>
          </ng-container>
          <ng-template #notReady>
            <ng-container *ngIf="current.inaccurate">
              <ng-container *ngTemplateOutlet="stillWorkingOnIt; context: { $implicit: listing }"></ng-container>
            </ng-container>
          </ng-template>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="website">
      <mat-header-cell *matHeaderCellDef>
        {{ 'LISTINGS.DETAILS.WEBSITE' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let listing">
        <ng-container *ngIf="!listing.website?.ignore; else ignore">
          <ng-container *ngIf="!!listing.website?.value; else notReady">
            <span class="truncate" [ngClass]="{ inaccurate: !listing.website?.match }">
              {{ listing.website.value }}
            </span>
          </ng-container>
        </ng-container>

        <ng-template #notReady>
          <ng-container *ngIf="!listing.website?.match">
            <ng-container *ngTemplateOutlet="stillWorkingOnIt; context: { $implicit: listing }"></ng-container>
          </ng-container>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="phone">
      <mat-header-cell *matHeaderCellDef>
        {{ 'LISTINGS.DETAILS.PHONE' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let listing">
        <ng-container *ngIf="!listing.phone?.ignore; else ignore">
          <ng-container *ngIf="!!listing.phone?.value; else notReady">
            <span [ngClass]="{ inaccurate: !listing.phone?.match }">
              {{ listing.phone.value }}
            </span>
          </ng-container>
        </ng-container>
        <ng-template #notReady>
          <ng-container *ngIf="!listing.phone?.match">
            <ng-container *ngTemplateOutlet="stillWorkingOnIt; context: { $implicit: listing }"></ng-container>
          </ng-container>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let listing">
        <ng-container *ngIf="controls.get(listing?.source?.sourceId) as control">
          <mat-checkbox
            *ngIf="!getLoadingStatus(listing?.source?.sourceId); else loadingSpinner"
            [formControl]="control"
            (change)="toggleListing($event, listing)"
          ></mat-checkbox>
        </ng-container>
        <ng-template #loadingSpinner>
          <glxy-loading-spinner [size]="'small'"></glxy-loading-spinner>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="simplifiedListing">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let listing" class="status">
        <div class="simplified-listing" *ngIf="!!listing.companyName?.value; else notReady">
          <span *ngIf="listing.status === ListingStatus.ACCURATE" class="accurate">
            {{ 'LISTINGS.DETAILS.ACCURATE_LISTING_FOUND' | translate }}
          </span>
          <span *ngIf="listing.status === ListingStatus.INACCURATE" class="inaccurate">
            {{ 'LISTINGS.DETAILS.INACCURATE_LISTING_FOUND' | translate }}
          </span>
        </div>

        <ng-template #notReady>
          <ng-container *ngIf="listing.status === ListingStatus.NOT_FOUND">
            <span *ngIf="isStillGettingData" class="working">
              {{ 'LISTINGS.DETAILS.IN_PROGRESS' | translate }}
            </span>
            <span *ngIf="!isStillGettingData" class="not-found">
              {{ 'LISTINGS.DETAILS.LISTING_NOT_FOUND' | translate }}
            </span>
          </ng-container>
        </ng-template>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns$ | async" class="hide-mobile"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns$ | async" matRipple></mat-row>
  </mat-table>
  <ng-container *ngIf="totalRows$ | async as totalRows">
    <div class="view-more" *ngIf="totalRows > defaultRows">
      <button
        mat-stroked-button
        color="primary"
        type="button"
        (click)="toggleRows(showingAllRows)"
        [trackEvent]="{
          eventName: showingAllRows ? 'hide-rows' : 'show-rows',
          category: 'cta',
          action: 'click'
        }"
      >
        <div class="view-more-label">
          <ng-container *ngIf="!showingAllRows; else viewLess">
            <mat-icon>add</mat-icon>
            <span>{{ 'LISTINGS.DETAILS.VIEW_MORE' | translate : { listingsCount: totalRows - defaultRows } }}</span>
          </ng-container>
          <ng-template #viewLess>
            <mat-icon>remove</mat-icon>
            <span>{{ 'LISTINGS.DETAILS.VIEW_LESS' | translate }}</span>
          </ng-template>
        </div>
      </button>
    </div>
  </ng-container>
</ng-template>

<ng-template #missing>
  <span class="inaccurate">{{ 'LISTINGS.DETAILS.MISSING' | translate }}</span>
</ng-template>

<ng-template #notFound>
  <span class="not-found">{{ 'LISTINGS.DETAILS.LISTING_NOT_FOUND' | translate }}</span>
</ng-template>

<ng-template #ignore></ng-template>

<ng-template #stillWorkingOnIt let-listing>
  <ng-container [ngSwitch]="listing.status">
    <ng-container *ngSwitchCase="ListingStatus.IGNORED">
      <span class="ignored">-</span>
    </ng-container>
    <ng-container *ngSwitchCase="ListingStatus.NOT_FOUND">
      <span class="working" *ngIf="isStillGettingData">
        <div>{{ 'LISTINGS.DETAILS.IN_PROGRESS' | translate }}</div>
      </span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="missing"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>
