import { Directive, HostListener, Input } from '@angular/core';
import { Properties } from 'posthog-js';
import { ProductAnalyticsService } from './product-analytics.service';

export interface TrackEvent {
  eventName: string;
  category: string;
  action?: string;
  value?: number;
  properties?: Properties;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[trackEvent]',
})
export class TrackEventDirective {
  @Input({ required: true }) trackEvent!: TrackEvent;

  constructor(private readonly productAnalyticsService: ProductAnalyticsService) {}

  @HostListener('click')
  onClick(): void {
    this.productAnalyticsService.trackEvent(
      this.trackEvent.eventName,
      this.trackEvent.category,
      this.trackEvent.action ?? '',
      this.trackEvent.value,
      this.trackEvent.properties,
    );
  }
}
