import {
  AdvertisingDataInterface,
  AdwordsDataInterface,
  ClicksInterface,
  ImpressionsInterface,
  IntRangeInterface,
  FloatRangeInterface,
  PayPerClickDataInterface,
  DomainMetricsInterface,
} from '@vendasta/snapshot';

export class FloatRange {
  minimum: number;
  maximum: number;

  constructor(data: FloatRangeInterface) {
    this.minimum = data.minimum || 0;
    this.maximum = data.maximum || 0;
  }
}

export class IntRange {
  minimum: number;
  maximum: number;

  constructor(data: IntRangeInterface) {
    this.minimum = data.minimum || 0;
    this.maximum = data.maximum || 0;
  }
}

export class Impressions {
  keyword: string;
  impressionsPerMonth: FloatRange;

  constructor(data: ImpressionsInterface) {
    this.keyword = data.keyword || '';
    this.impressionsPerMonth = new FloatRange(data.impressionsPerMonth || {});
  }
}

export class Clicks {
  keyword: string;
  clicksPerMonth: FloatRange;
  averageCostPerClick: IntRange;

  constructor(data: ClicksInterface) {
    this.keyword = data.keyword || '';
    this.clicksPerMonth = new FloatRange(data.clicksPerMonth || {});
    this.averageCostPerClick = new IntRange(data.averageCostPerClick || {});
  }
}

export class AdwordsData implements AdwordsDataInterface {
  impressions: Impressions[];
  clicks: Clicks[];
  stillWorking: boolean;

  constructor(data: AdwordsDataInterface) {
    this.impressions = data.impressions ? data.impressions.map((i) => new Impressions(i)) : [];
    this.clicks = data.clicks ? data.clicks.map((i) => new Clicks(i)) : [];
    this.stillWorking = data.stillWorking || false;
  }
}

export class DomainMetrics {
  domainName: string;
  averageAdPosition: number;
  numberOfAdvertisers: number;
  numberOfPaidKeywords: number;
  paidClicksPerMonth: number;
  dailyAdwordsBudget: number;
  monthlyAdwordsBudget: number;
  paidDomainRanking: number;
  overlap: number;

  constructor(data: DomainMetricsInterface) {
    this.domainName = data.domainName || '';
    this.averageAdPosition = data.averageAdPosition || 0;
    this.numberOfAdvertisers = data.numberOfAdvertisers || 0;
    this.numberOfPaidKeywords = data.numberOfPaidKeywords || 0;
    this.paidClicksPerMonth = data.paidClicksPerMonth || 0;
    this.dailyAdwordsBudget = data.dailyAdwordsBudget || 0;
    this.monthlyAdwordsBudget = data.monthlyAdwordsBudget || 0;
    this.paidDomainRanking = data.paidDomainRanking || 0;
    this.overlap = data.overlap || 0;
  }
}

export class PayPerClickData {
  business: DomainMetrics;
  competitors: DomainMetrics[];

  constructor(data: PayPerClickDataInterface) {
    this.business = new DomainMetrics(data.business || {});
    this.competitors = data.competitors ? data.competitors.map((i) => new DomainMetrics(i)) : [];
  }
}

export class AdvertisingData implements AdvertisingDataInterface {
  adwordsData: AdwordsData;
  payPerClickData: PayPerClickData;
  isRetargeting: boolean;

  constructor(data: AdvertisingDataInterface) {
    this.adwordsData = new AdwordsData(data.adwordsData || {});
    this.payPerClickData = new PayPerClickData(data.payPerClickData || {});
    this.isRetargeting = data.isRetargeting || false;
  }
}
