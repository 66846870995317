<ng-container *ngIf="(listingPresenceCompetitors$ | async)?.length > 0; else noPresenceCompetitors">
  <snap-subsection
    heading="{{ 'LISTINGS.PRESENCE.HEADING' | translate }}"
    [hideControl]="controls.controls['hideListingPresence']"
    [edit]="edit"
  >
    <snap-social-table
      class="hide-mobile"
      *ngIf="(service.listingPresenceVersion$ | async) === listingPresenceVersions.VERSION_1"
      [socialItems]="socialItems$ | async"
      [edit]="edit"
      [createdAt]="(snapshotService.snapshot$ | async)?.created"
    ></snap-social-table>

    <div class="listing-presence">
      <div
        *ngIf="listingPresenceFoundCount$ | async as lpfc"
        class="business flex align-space-between-center default-spacing"
      >
        <ng-container *ngIf="listingAvailableSources$ | async as las">
          <div class="chart">
            <snap-listing-chart
              [chartTitles]="listingPresenceChartTitles"
              [businessName]="(snapshotService.snapshotData$ | async).companyName"
              [businessCount]="lpfc"
              [competitors]="listingPresenceCompetitors$ | async"
              [referenceData]="listingPresenceReferenceData$ | async"
              [maxCount]="las"
            ></snap-listing-chart>
          </div>
        </ng-container>
      </div>
    </div>

    <snap-presence-table
      *ngIf="(service.listingPresenceVersion$ | async) === listingPresenceVersions.VERSION_0"
      [hideGrades]="snapshotService.hideSubGrades$ | async"
      [edit]="edit"
      [items]="presenceTableItems$ | async"
    ></snap-presence-table>
  </snap-subsection>
</ng-container>
<ng-template #noPresenceCompetitors>
  <snap-subsection
    heading="{{ 'LISTINGS.PRESENCE.HEADING' | translate }}"
    subheading="{{ 'LISTINGS.PRESENCE.SUBHEADING' | translate }}"
    [hideControl]="controls.controls['hideListingPresence']"
    [edit]="edit"
  >
    <snap-social-table
      class="hide-mobile"
      *ngIf="(service.listingPresenceVersion$ | async) === listingPresenceVersions.VERSION_1"
      [socialItems]="socialItems$ | async"
      [edit]="edit"
      [createdAt]="(snapshotService.snapshot$ | async)?.created"
    ></snap-social-table>

    <div class="listing-presence">
      <div class="business flex">
        <div class="label">{{ 'COMMON.YOUR_BUSINESS' | translate }}</div>
        <div class="chart flex align-space-between-center default-spacing">
          <div
            class="fill"
            [ngStyle]="{
              width: computePresenceFillPercentage(listingPresenceFoundCount$ | async, listingAvailableSources$ | async)
            }"
          >
            &nbsp;
          </div>
          <div>{{ listingPresenceFoundCount$ | async }}</div>
        </div>
      </div>
      <div class="industry flex">
        <div class="label">
          {{ 'LISTINGS.PRESENCE.INDUSTRY_FOUND' | translate }}
        </div>
        <div class="chart flex align-space-between-center default-spacing">
          <div class="fill" [ngStyle]="{ width: '100%' }">&nbsp;</div>
          <div>{{ listingAvailableSources$ | async }}</div>
        </div>
      </div>
    </div>

    <snap-presence-table
      *ngIf="(service.listingPresenceVersion$ | async) === listingPresenceVersions.VERSION_0"
      [hideGrades]="snapshotService.hideSubGrades$ | async"
      [edit]="edit"
      [items]="presenceTableItems$ | async"
    >
      >
    </snap-presence-table>
  </snap-subsection>
</ng-template>
