import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'snap-grade-tagline',
  template: `
    <div class="title-container">
      <div class="grade-container">
        <snap-grade data-testid="grade" *ngIf="!hideGrade" [grade]="grade" size="extra-small"></snap-grade>
      </div>

      <div class="text-container small-header">
        <div class="heading" data-testid="heading">{{ heading }}</div>
        <div class="tagline hide-mobile hide-to-print" data-testid="tagline">
          {{ tagline }}
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./section-container.component.scss', './grade-tagline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GradeTaglineComponent {
  @Input() grade?: number;
  @Input() heading: string;
  @Input() tagline: string;
  @Input() hideGrade: boolean;
}
