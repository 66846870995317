import { SnapshotService as SnapshotApiService } from '@vendasta/snapshot';
import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { SnapshotService } from '@galaxy/snapshot';

export interface ContactInterface {
  firstName?: string;
  lastName?: string;
  email: string;
  fullName: string;
}

export interface EmailInterface {
  body: string;
  subject: string;
  greeting: string;
  closing: string;
  buttonText: string;
}

@Injectable()
export class ShareReportService {
  private businessId$ = this.snapshotService.businessId$;
  private snapshotId$ = this.snapshotService.snapshotId$;
  constructor(private snapshotService: SnapshotService, private snapshotApiService: SnapshotApiService) {}

  public shareReport(sender: ContactInterface, recipients: ContactInterface[], email: EmailInterface): Observable<any> {
    return combineLatest([this.businessId$, this.snapshotId$]).pipe(
      mergeMap(([businessId, snapshotId]: [string, string]) =>
        this.snapshotApiService.shareSnapshot(
          businessId,
          sender,
          recipients,
          snapshotId,
          email,
          window.location.hostname,
        ),
      ),
    );
  }
}
