<snap-toggled-item [hideControl]="hideControl" [edit]="edit">
  <div class="subsection-container">
    <div class="subsection-header">
      <div class="subsection">
        <snap-grade *ngIf="grade && !hideGrade" [grade]="grade" size="extra-small"></snap-grade>
        <div>
          <div class="heading" data-testid="subsection-heading">
            {{ heading }}
          </div>
        </div>
      </div>

      <div class="subheading hide-to-print">
        <div *ngIf="subheading" class="tooltip" data-testid="subsection-subheading">
          <mat-icon [glxyTooltip]="subheading" [tooltipPositions]="left">help_outline</mat-icon>
        </div>
        <ng-content class="hide-mobile" select="[subheading]"></ng-content>
      </div>
      <snap-status-summary *ngIf="statusSummary" [statusSummary]="statusSummary"></snap-status-summary>
      <div class="edit-subsection">
        <ng-template snapItemToggle></ng-template>
      </div>
    </div>
    <div class="subsection-body" [@toggleSection]="hideControl && hideControl.value ? 'off' : 'on'">
      <ng-content></ng-content>
    </div>
  </div>
</snap-toggled-item>
