import { combineLatest as observableCombineLatest, of as observableOf, ReplaySubject, Observable } from 'rxjs';
import { delay, catchError, filter, shareReplay, switchMap, tap, map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { ListingScan } from '../listing-section/listing-data';
import { SalesPerson, SnapshotLiteService as SnapshotLiteApi } from '@vendasta/snapshot';
import { ListingSectionService } from '@vendasta/snapshot';
import { Business } from './business';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { GooglePlace } from './google-place';
import { GooglePlacesService } from './google-places.service';
import { PartnerMarketService } from '../whitelabel/whitelabel.service';
import { ROUTE_GENERATOR_TOKEN, RouteType, RoutingServiceInterface } from '../providers/providers';

@Injectable()
export class SnapshotLiteService implements PartnerMarketService {
  businessId: string;
  googleAttributionDiv: HTMLDivElement;
  isFetching = false;

  private business$$: ReplaySubject<Business> = new ReplaySubject<Business>(1);
  private salesperson$$: ReplaySubject<SalesPerson | null> = new ReplaySubject<SalesPerson | null>(1);
  private partialListingScan$$: ReplaySubject<ListingScan> = new ReplaySubject<ListingScan>(1);
  private listingScan$$: ReplaySubject<ListingScan> = new ReplaySubject<ListingScan>(1);

  business$ = this.business$$.asObservable();
  partnerId$: Observable<string>;
  marketId$: Observable<string>;
  partialListingScan$ = this.partialListingScan$$.asObservable();
  listingScan$ = this.listingScan$$.asObservable();
  salesperson$ = this.salesperson$$.asObservable();
  googlePlace$: Observable<GooglePlace | null>;

  constructor(
    private snapshotLiteApi: SnapshotLiteApi,
    private listingsApi: ListingSectionService,
    public googlePlacesService: GooglePlacesService,
    private router: Router,
    @Inject(ROUTE_GENERATOR_TOKEN) private routeService: RoutingServiceInterface,
  ) {
    this.googlePlace$ = this.business$.pipe(
      filter((x) => x !== undefined),
      switchMap((biz) => this.googlePlacesService.getGooglePlaceForBusiness(biz)),
      catchError(() => observableOf(null)),
      shareReplay(),
    );

    observableCombineLatest([this.business$, this.partialListingScan$, this.googlePlace$])
      .pipe(
        filter(([biz, scan, place]) => biz !== undefined && scan !== undefined && place !== undefined),
        tap(([biz, scan, place]) => {
          if (place !== null) {
            scan.addGoogleListing(biz, place);
          }
          this.listingScan$$.next(scan);
        }),
      )
      .subscribe();

    this.partnerId$ = this.business$.pipe(map((b) => b.partnerId));
    this.marketId$ = this.business$.pipe(map((b) => b.marketId));
  }

  refresh(): void {
    this.business$$.next(undefined);
    this.listingScan$$.next(undefined);
    this.partialListingScan$$.next(undefined);
    this.init(this.businessId, this.googleAttributionDiv);
  }

  init(businessId: string, googleAttributionDiv: HTMLDivElement): void {
    this.businessId = businessId;
    this.googleAttributionDiv = googleAttributionDiv;
    this.googlePlacesService.init(this.googleAttributionDiv);

    this.snapshotLiteApi.get(businessId).subscribe(
      (s) => {
        this.business$$.next(new Business(s.business));
        this.salesperson$$.next(s.salesPerson ? new SalesPerson(s.salesPerson) : null);
      },
      (e) => {
        this.handleError(e);
      },
    );
  }

  startFetching(businessId: string): Observable<any> {
    // initial listingScan fetch
    return this.listingsApi.getListingScan(businessId).pipe(
      tap((l) => {
        this.startFetchLoop();
        this.partialListingScan$$.next(new ListingScan(l));
      }),
    );
  }

  handleError(e: HttpErrorResponse): void {
    const route = this.routeService.getRoute({ routeType: RouteType.Error });
    if (!route) {
      return;
    }
    if (e.status === 404) {
      this.router.navigate([route], {
        skipLocationChange: true,
        queryParams: { error: 'NOT_FOUND.TITLE' },
      });
    }
    if (e.status === 429) {
      this.router.navigate([route], {
        skipLocationChange: true,
        queryParams: { error: 'ERRORS.OVER_QUOTA' },
      });
    }
    this.router.navigate([route], {
      skipLocationChange: true,
      queryParams: { error: 'ERRORS.ERROR_LOADING_PAGE' },
    });
  }

  private startFetchLoop(): void {
    if (!this.isFetching) {
      this.isFetching = true;

      // poll for new listingScan data every 10 seconds
      this.partialListingScan$
        .pipe(
          delay(10000),
          switchMap(() => this.listingsApi.getListingScan(this.businessId)),
        )
        .subscribe((listing) => this.partialListingScan$$.next(new ListingScan(listing)));
    }
  }
}
