import { AbstractControl } from '@angular/forms';
import { Grade } from '@vendasta/snapshot';

export interface SectionTableItemExampleInterface {
  name: string;
  value: string | number;
}

export interface SectionTableCompetitorItemInterface {
  name: string;
  value: number | boolean | null;
}

export interface SectionTableDataInterface {
  business?: number | string | boolean;
  link?: string;
  grade?: Grade;
  hasColor?: boolean;
  industryAverage?: number | string;
  industryLeader?: number;
  examples?: SectionTableItemExampleInterface[];
}

export enum FormatType {
  Currency = 'currency',
  Bar = 'bar',
  Link = 'link',
  Check = 'check',
  Number = 'number',
}

export interface SectionTableItem {
  title: string;
  labelFormat?: FormatType;
  dataFormat?: FormatType;
  data?: SectionTableDataInterface;
  subtitle?: string;
  tooltip?: string;
  format?: FormatType;
  control?: AbstractControl;
  competitors?: SectionTableCompetitorItemInterface[];
  available?: boolean;
  numberOfSolutions?: number;
  datatestid?: string;
}

export enum Icons {
  Found = 'check',
  NotFound = 'close',
}
