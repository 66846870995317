import { Grade } from '@vendasta/snapshot';

export interface ReviewItemInterface {
  grade: Grade;
  business?: number;
  industryAverage?: number;
  industryLeader?: number;
  sampleSourceCounts?: SampleSourceCountInterface[];
}

export interface SampleSourceCountInterface {
  sourceName: string;
  reviewCount: number;
}

export class ReviewItemExample {
  name: string;
  value: number;

  constructor(name: string, value: number) {
    this.name = name;
    this.value = value;
  }
}

export class ReviewItem {
  grade: Grade;
  business: number;
  industryAverage: number;
  industryLeader: number;
  examples?: ReviewItemExample[];

  constructor(item: ReviewItemInterface, examples?: SampleSourceCountInterface[]) {
    this.grade = item.grade;
    this.business = item.business || 0;
    this.industryAverage = item.industryAverage || 0;
    this.examples = (examples || []).map((s) => {
      return new ReviewItemExample(s.sourceName, s.reviewCount);
    });
    this.industryLeader = item.industryLeader || 0;
  }
}
