<ng-container *ngIf="(service.loaded$ | async) && (service.error$ | async) === false">
  <snap-subsection
    *ngIf="(snapshotService.snapshotData$ | async)?.country === 'US'"
    heading="{{ 'LISTINGS.PROVIDERS.HEADING' | translate }}"
    subheading="{{ 'LISTINGS.PROVIDERS.SUBHEADING' | translate }}"
    [hideControl]="controls.controls['hideListingDistribution']"
    [edit]="edit"
  >
    <snap-content-banner
      class="hide-mobile"
      [hideControl]="controls.controls['hideListingDistributionContent']"
      [messageControl]="controls.controls['customizedListingDistributionMessage']"
      [videoUrl]="service.listingDistributionVideoUrl$ | async"
      [customizedMessage]="service.listingDistributionMessage$ | async"
      [contentId]="service.listingDistributionMessageId$ | async"
      [edit]="edit"
      [hideVideo]="false"
    ></snap-content-banner>

    <snap-listing-distribution-table
      [dataProviderAccuracy]="listingDistributionAccuracy$ | async"
      [visibleDataProviderSources]="visibleDataProviderSources$ | async"
    ></snap-listing-distribution-table>
  </snap-subsection>
</ng-container>
