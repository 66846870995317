import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { first, map } from 'rxjs/operators';
import { SectionServiceInterfaceToken } from '../../../section/section.service';
import { SEOService } from '../../seo.service';
import { TranslateService } from '@ngx-translate/core';
import { SnapshotService } from '../../../snapshot.service';
import { Observable } from 'rxjs';
import { SNAPSHOT_NAME_TOKEN } from '../../../providers/providers';
import { RefreshReportService } from '../../../refresh-report/refresh-report.service';

@Component({
  selector: 'snap-custom-keyword',
  templateUrl: './custom-keyword.component.html',
  styleUrls: ['./custom-keyword.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: SEOService,
    },
  ],
})
export class CustomKeywordComponent implements OnInit {
  @Input() isExpired: boolean;
  @Input() customKeyword: UntypedFormControl;

  customKeywordControl: UntypedFormControl = new UntypedFormControl('');

  disableUpdateButton = false;

  localSEOSearchKeyword$: Observable<string> = new Observable<string>();
  configCustomKeyword$: Observable<string> = new Observable<string>();

  validators = [
    {
      validatorFn: Validators.maxLength(100),
      errorMessage: this.translateService.instant('EDIT_REPORT.LOCAL_SEO.CHARACTERS_LENGTH_ERROR'),
    },
  ];

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: SEOService,
    @Inject(SNAPSHOT_NAME_TOKEN) readonly snapshotName$: Observable<string>,
    public translateService: TranslateService,
    public snapshotService: SnapshotService,
    public refreshService: RefreshReportService,
  ) {}
  ngOnInit(): void {
    this.service.init();
    this.configCustomKeyword$ = this.service.config$.pipe(map((c) => c.customKeyword));
    this.configCustomKeyword$
      .pipe(first())
      .subscribe((customKeyword) => this.customKeywordControl.setValue(customKeyword));
    this.localSEOSearchKeyword$ = this.service.data$.pipe(map((d) => d.localSEOData?.searchKeyword));
    if (this.isExpired) {
      this.customKeywordControl.disable();
    }
  }

  updateKeyword(): void {
    this.customKeyword.setValue(this.customKeywordControl.value);
    this.disableUpdateButton = true;
  }

  openRefreshDialog(): void {
    this.refreshService.openRefreshDialog();
  }
}
