import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { interval } from 'rxjs';

declare const showu: {
  launch(): void;
};

@Injectable()
export class CrankwheelWidgetService {
  constructor(@Inject(DOCUMENT) document: Document) {
    document.addEventListener('DOMContentLoaded', () => {
      if ((window as any).showu) {
        console.log('crankwheel initialized');

        const subscription = interval(1000).subscribe(() => {
          const elements = document.getElementsByClassName('crankwheel-com-showu-launch-button');
          for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            element.addEventListener('click', () => {
              console.log('crankwheel CTA clicked');
              showu.launch();
            });
            subscription.unsubscribe();
          }
        });
      }
    });
  }
}
