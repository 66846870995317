import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { PhonePipe, WhitelabelConfiguration } from '@galaxy/snapshot';
import { SalesPerson } from '@vendasta/snapshot';

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CallToActionComponent implements OnChanges, AfterViewInit {
  @Input() customHtml: string;
  @Input() salesperson: SalesPerson;
  @Input() config: WhitelabelConfiguration;

  @Output() ctaClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _clickHandler: any;

  constructor(private _elementRef: ElementRef) {
    this._clickHandler = this.handleClick.bind(this);
  }

  ngAfterViewInit(): void {
    this.addHTMLBindings();
  }

  ngOnChanges(): void {
    this.addHTMLBindings();
  }

  addHTMLBindings(): void {
    this._addClaimBinding();
    this._addPhoneNumber(this.salesperson);
  }

  private _addClaimBinding(): void {
    const buttons = this._elementRef.nativeElement.querySelectorAll('.snapshot-cta-button');
    Array.from(buttons).forEach((b: any) => {
      b.removeEventListener('click', this._clickHandler); // ensure we only emit once per click
      b.addEventListener('click', this._clickHandler);
    });
  }

  private handleClick(): void {
    this.ctaClick.emit(true);
  }

  private _addPhoneNumber(salesperson?: SalesPerson): void {
    if (!(salesperson && salesperson.phoneNumbers && salesperson.phoneNumbers.length > 0)) {
      return;
    }
    const containers = this._elementRef.nativeElement.querySelectorAll('.snapshot-salesperson-phone-container');
    const phoneNumbers = this._elementRef.nativeElement.querySelectorAll('.snapshot-salesperson-phone');
    Array.from(containers).forEach((c: any) => {
      c.style.display = 'inline';
    });
    Array.from(phoneNumbers).forEach((c: any) => {
      c.style.display = 'inline';
    });
    Array.from(phoneNumbers).forEach((p: any) => {
      const parts = salesperson.phoneNumbers[0].split(',');
      p.href = 'tel:' + parts[0];
      if (salesperson.country === 'AU') {
        // use international format without extension
        p.href = 'tel:' + new PhonePipe().transform(parts[0], salesperson.country);
      }
      p.innerHTML = new PhonePipe().transform(salesperson.phoneNumbers[0], salesperson.country);
    });
  }
}
