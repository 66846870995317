import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-skeleton-toolbar',
  templateUrl: './skeleton-toolbar.component.html',
  styleUrls: ['./skeleton-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonToolbarComponent {
  @Input() logoUrl = '';
  @Input() reportName = '';
  @Input() ctaLabel?: string;
  @Input() ctaTargetUrl?: string;
  @Input() ctaTargetProduct?: string;
  @Output() ctaClick = new EventEmitter<void>();

  protected readonly defaultCtaLabel = 'COMMON.GET_IN_TOUCH';

  async handleCtaClick(): Promise<void> {
    this.ctaClick.emit();
  }
}
