import { Observable } from 'rxjs';
import { ObservableWorkStateMap } from './observable-work-state-map';
import { WorkStates } from './work-states';

const MONO_ID = 'ObservableWorkStates-MONOID';

/**
 * ObservableWorkStates handles running work asynchronously and provides
 * loading, success, and result streams.
 */
export class ObservableWorkState<RESULT> implements WorkStates<RESULT> {
  readonly isLoading$: Observable<boolean>;
  readonly isSuccess$: Observable<boolean>;
  readonly successEvents$: Observable<boolean>;
  readonly workResults$: Observable<RESULT>;

  private delegate = new ObservableWorkStateMap<string, RESULT>();

  constructor() {
    this.isLoading$ = this.delegate.isLoading$(MONO_ID);
    this.isSuccess$ = this.delegate.isSuccess$(MONO_ID);
    this.successEvents$ = this.delegate.successEvent$(MONO_ID);
    this.workResults$ = this.delegate.getWorkResults$(MONO_ID);
  }

  async startWork(work: Observable<RESULT>): Promise<void> {
    this.delegate.startWork(MONO_ID, work);
  }
}
