<glxy-confirmation-body>
  <mat-icon glxyConfirmationIcon class="icon" inline="true" [class.warn]="config.type === 'warn'">
    {{ config.type === 'warn' ? 'warning' : 'info' }}
  </mat-icon>

  <glxy-confirmation-title>
    {{ config.title | translate }}
  </glxy-confirmation-title>

  <glxy-confirmation-message>
    {{ config.message ?? '' | translate }}
  </glxy-confirmation-message>
</glxy-confirmation-body>

<glxy-confirmation-actions>
  @if (config.showCheckbox) {
    <glxy-confirmation-secondary-actions>
      <mat-checkbox>{{ config.checkboxText ?? 'GALAXY.CONFIRMATION_MODAL.DONT_SHOW_AGAIN' | translate }}</mat-checkbox>
    </glxy-confirmation-secondary-actions>
  }

  <glxy-confirmation-primary-actions>
    @if (!config.hideCancel) {
      <button mat-stroked-button id="confirmation-modal-cancel-button" matDialogClose>
        {{ config.cancelButtonText ?? 'GALAXY.CONFIRMATION_MODAL.CANCEL' | translate }}
      </button>
    }

    <button mat-flat-button [color]="config.type === 'warn' ? 'warn' : 'primary'" (click)="dialogActionClicked()">
      {{ config.confirmButtonText ?? 'GALAXY.CONFIRMATION_MODAL.CONFIRM' | translate }}
    </button>
  </glxy-confirmation-primary-actions>
</glxy-confirmation-actions>
