import { Config, ConfigInterface } from '../section/section';

export interface EcommerceConfigInterface extends ConfigInterface {
  hideEcommerceSolution?: boolean;
  hideOnlinePayments?: boolean;
  hideAdRetargeting?: boolean;
  hideAppointmentScheduling?: boolean;
}

export class EcommerceConfig extends Config implements EcommerceConfigInterface {
  hideEcommerceSolution: boolean;
  hideOnlinePayments: boolean;
  hideAdRetargeting: boolean;
  hideAppointmentScheduling: boolean;

  constructor(data: EcommerceConfigInterface) {
    super(data);
    this.hideContent = !!data.hideContent;
    this.customizedMessage = data.customizedMessage || '';
    this.hideFooter = !!data.hideFooter;
    this.hideEcommerceSolution = !!data.hideEcommerceSolution;
    this.hideOnlinePayments = !!data.hideOnlinePayments;
    this.hideAdRetargeting = !!data.hideAdRetargeting;
    this.hideAppointmentScheduling = !!data.hideAppointmentScheduling;
  }
}
