import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { EcommerceSectionItems } from '../ecommerce-subsection.component';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

interface TableConfig {
  data: EcommerceSectionItems[];
  columnNames: string[];
  editMode: boolean;
}

@Component({
  selector: 'snap-ecommerce-table',
  templateUrl: './ecommerce-table.component.html',
  styleUrls: ['./ecommerce-table.component.scss', '../../ecommerce-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EcommerceTableComponent implements OnInit {
  protected tableConfig$: Observable<TableConfig> = new Observable<TableConfig>();
  @Input() set items(val: EcommerceSectionItems[]) {
    this.items$$.next(val);
  }
  private items$$: ReplaySubject<EcommerceSectionItems[]> = new ReplaySubject<EcommerceSectionItems[]>(1);

  @Input() set editMode(val: boolean) {
    this.editMode$$.next(val);
  }
  private editMode$$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  protected editMode$: Observable<boolean> = this.editMode$$.asObservable().pipe(startWith(false));

  ngOnInit() {
    this.tableConfig$ = combineLatest([this.items$$, this.editMode$]).pipe(
      filter(([rows]) => !!rows),
      distinctUntilChanged(),
      map(([rows, edit]) => {
        return {
          data: rows,
          columnNames: edit ? ['rowLabel', 'found', 'why', 'edit'] : ['rowLabel', 'found', 'why'],
          editMode: edit,
        } as TableConfig;
      }),
    );
  }
}
