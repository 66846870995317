<div class="thermometer" *ngIf="!!sections" [class.thermometer-inputs]="true">
  <span class="section section-label section-first" [ngClass]="getSectionClass(sections.first?.sectionClass)">
    <span class="label">
      {{ sections.first?.label | translate | uppercase }}
    </span>
    {{ sections.first?.value }}
  </span>
  <span class="section section-label section-second" [ngClass]="getSectionClass(sections.second?.sectionClass)">
    <span class="label">
      {{ sections.second?.label | translate | uppercase }}
    </span>
    {{ sections.second?.value }}
  </span>
  <span
    class="section section-label section-third section-last"
    [ngClass]="getSectionClass(sections.third?.sectionClass)"
  >
    <span class="label">
      {{ sections.third?.label | translate | uppercase }}
    </span>
    {{ sections.third?.value }}
  </span>
  <ng-container *ngIf="!!thermometer.markerTop">
    <span class="marker marker-top marker-{{ percentiles?.top }}">
      <span class="marker-label">
        {{ thermometer.markerTop.label | translate }}
      </span>
      <span class="marker-label marker-value">
        {{ thermometer.markerTop.value }}
      </span>
    </span>
  </ng-container>
  <ng-container *ngIf="!!thermometer.markerBottom">
    <span class="marker marker-bottom marker-{{ percentiles?.bottom }}">
      <span class="marker-label marker-value">
        {{ thermometer.markerBottom.value }}
      </span>
      <span class="marker-label">
        {{ thermometer.markerBottom.label | translate }}
      </span>
    </span>
  </ng-container>
</div>
