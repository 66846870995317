import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PartnerServiceInterface, PartnerServiceInterfaceToken } from './interfaces';

@Injectable({ providedIn: 'root' })
export class PartnerService implements PartnerServiceInterface {
  constructor(@Inject(PartnerServiceInterfaceToken) private innerService: PartnerServiceInterface) {}
  getPartnerId(): Observable<string> {
    return this.innerService.getPartnerId();
  }
}
