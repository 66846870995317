<ng-container *ngIf="organicDomainMetrics$ | async as domainMetrics">
  <ng-container *ngIf="domainMetrics?.business?.numberOfOrganicKeywords > 0">
    <snap-subsection
      heading="{{ 'LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.HEADING' | translate }}"
      subheading="{{ 'LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.SUBHEADING' | translate }}"
      [edit]="edit"
      [hideControl]="controls.controls['hideOrganicKeywordData']"
    >
      <snap-organic-keyword-ranking
        [organicKeywordMetricsData]="organicKeywordMetrics$ | async"
        [showMonthlyClicks]="showMonthlyClicks$ | async"
        [mobileRankingItems]="mobileRankingItems$ | async"
      ></snap-organic-keyword-ranking>
    </snap-subsection>
  </ng-container>
</ng-container>
