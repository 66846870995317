import { Section, SectionSummary } from '@vendasta/snapshot';

export type CustomProposalSnapshotSectionType = 'overview' | 'cover';
export type ProposalSnapshotSectionType = Section | CustomProposalSnapshotSectionType;

export interface ProposalSnapshotSection extends Omit<SectionSummary, 'sectionId' | 'toApiJson'> {
  isSelected: boolean;
  type: ProposalSnapshotSectionType;
}

export interface CustomProposalSnapshotSection extends Omit<ProposalSnapshotSection, 'type'> {
  type: CustomProposalSnapshotSectionType;
}

export const isCustomProposalSnapshotSectionType = (
  type: string | number,
): type is CustomProposalSnapshotSectionType => {
  return type === 'cover' || type === 'overview';
};

export const isCustomProposalSnapshotSection = (
  section: ProposalSnapshotSection,
): section is CustomProposalSnapshotSection => {
  return section.type === 'cover' || section.type === 'overview';
};
