import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, booleanAttribute, effect, inject, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SessionService } from '@galaxy/core';
import { HostService } from '@vendasta/atlas';
import { IAMService, UserIdentifier } from '@vendasta/iamv2';
import { Observable, combineLatest, map, shareReplay, skipWhile, switchMap, take } from 'rxjs';
import { AtlasConfigService, AtlasDataService } from '../../../../core/src';
import { ModalService } from '../../core-components/modal/modal.service';
import { EmailVerificationComponent } from '../../email-verification/email-verification.component';
import { ATLAS_CONFIG_TOKEN } from '../../module-common';
import { SettingsComponent } from '../../notifications/settings/settings.component';
import { CustomUserDropdownItem } from '../../user-dropdown/user-dropdown.interface';
import { AtlasUserLanguageModalService } from '../../user-language-modal/user-language-modal.service';
import { AtlasTopBarService } from '../top-bar.service';

@Component({
  selector: 'atlas-top-bar-items',
  templateUrl: './top-bar-items.component.html',
  styleUrl: './top-bar-items.component.scss',
  providers: [AtlasUserLanguageModalService],
})
export class TopBarItemsComponent implements OnInit {
  protected readonly dataService = inject(AtlasDataService);
  protected readonly topbarService = inject(AtlasTopBarService);
  protected readonly configService = inject(AtlasConfigService);

  private readonly http = inject(HttpClient);
  private readonly iamService = inject(IAMService);
  private readonly userLanguageModalService = inject(AtlasUserLanguageModalService);
  private readonly modalService = inject(ModalService);
  private readonly hostService = inject(HostService);
  private readonly sessionService = inject(SessionService);

  dropdownItems = input<CustomUserDropdownItem[]>([]);
  hideCenters = input(false, { transform: booleanAttribute });
  hideNotifications = input(false, { transform: booleanAttribute });
  hideLanguageSelector = input(false, { transform: booleanAttribute });
  hideUserDropdown = input(false, { transform: booleanAttribute });
  useNewTopBar = input(false, { transform: booleanAttribute });
  protected readonly atlasConfig$ = inject(ATLAS_CONFIG_TOKEN, { optional: true }); // TODO: move this into the config service

  constructor() {
    this.userLanguageModalService.init();

    if (this.atlasConfig$) {
      this.atlasConfig$.pipe(takeUntilDestroyed()).subscribe((config) => {
        /* We only want to init the config here in O&O Apps.
         *  Business App provides this in the APP_INITIALIZER */
        if (!config || config.serviceProviderId === 'VBC') {
          return;
        }

        this.configService.initialize(
          config.serviceProviderId,
          config.partnerId,
          config.marketId,
          config.accountId,
          config.serviceProviderId,
          config.groupPath,
        );
      });
    }

    effect(() => {
      this.topbarService.setDropdownItems(this.dropdownItems());
    });

    effect(() => {
      this.topbarService.setCenterVisibility(this.hideCenters() === false);
    });

    effect(() => {
      this.topbarService.setNotificationsVisibility(this.hideNotifications() === false);
    });

    effect(() => {
      this.topbarService.setUserDropdownVisibility(this.hideUserDropdown() === false);
    });

    effect(() => {
      this.topbarService.setLanguageSelectorVisibility(this.hideLanguageSelector() === false);
    });

    effect(() => {
      this.topbarService.setUserDropdownVisibility(this.hideUserDropdown() === false);
    });
  }

  ngOnInit(): void {
    if (window.location.search.indexOf(SettingsComponent.SETTINGS_DIALOG_PARAM_ID) >= 0) {
      this.modalService.openList(SettingsComponent);
    }

    this.getShouldShowEmailVerificationInterstitial().subscribe((resp) => {
      if (resp.display) {
        this.modalService.openFullscreen(EmailVerificationComponent);
      }
    });
  }

  userPic$ = this.dataService.userId$.pipe(
    switchMap((userId) => {
      const uid = new UserIdentifier({
        userId: userId,
      });
      return this.iamService.getUser(uid);
    }),
    map((user) => user?.profileImageUrl || ''),
  );

  hideUserDropdown$ = combineLatest([
    this.topbarService.hideUserDropdown$,
    this.topbarService.dropdownItems$,
    this.dataService.email$,
  ]).pipe(
    map(([hideUserDropdown, dropdownItems, email]) => {
      if (hideUserDropdown) {
        return true;
      }
      return !dropdownItems && !email;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  notificationsEnabled$ = combineLatest([
    this.dataService.notificationsEnabled$,
    this.topbarService.hideNotifications$,
  ]).pipe(
    map(([dataNotificationsEnabled, hideNotifications]) => {
      if (!dataNotificationsEnabled || hideNotifications) {
        return false;
      }
      return true;
    }),
  );

  getShouldShowEmailVerificationInterstitial(): Observable<{ display: boolean }> {
    const shouldDisplay = this.http.post<{ display: boolean }>(
      this.hostService.hostWithScheme + '/to-display-email-verification',
      null,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      },
    );
    return this.sessionService.getSessionId().pipe(
      skipWhile((sess) => !sess),
      switchMap(() => shouldDisplay),
      take(1),
    );
  }
}
