import { InjectionToken } from '@angular/core';
import { retryer } from '@vendasta/rx-utils';
import { Grade } from '@vendasta/snapshot';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest as observableCombineLatest,
  of as observableOf,
} from 'rxjs';
import { catchError, distinctUntilChanged, map, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { SectionServiceInterface } from '../common/interfaces';
import { GradeExplanationData } from '../grade/grade-explainer-dialog.component';
import { ContentService } from '../snapshot-report/content.service';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { ConfigInterface, ContentInterface, SectionInterface } from './section';

export const SectionServiceInterfaceToken: InjectionToken<SectionServiceInterface> =
  new InjectionToken<SectionServiceInterface>('SectionService');

export abstract class AbstractSectionService<Content extends ContentInterface, Data, Config extends ConfigInterface>
  implements SectionServiceInterface
{
  snapshotId: string;
  sectionId: string;

  private _configurationChanged$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _grade$$ = new ReplaySubject<Grade>(1);
  private _config$$ = new ReplaySubject<Config>(1);
  private _data$$ = new ReplaySubject<Data>(1);
  private _content$$ = new ReplaySubject<Content>(1);
  private _error$$ = new BehaviorSubject<boolean>(false);
  private _loaded$$ = new BehaviorSubject<boolean>(false);
  private _initialized = false;

  protected subscriptions: Subscription[] = [];

  public loaded$: Observable<boolean>;
  public error$: Observable<boolean>;
  public grade$: Observable<Grade>;
  public hasGrade$: Observable<boolean>;
  public content$: Observable<Content>;
  public config$: Observable<Config>;
  public data$: Observable<Data>;
  public message$: Observable<string>;
  public messageId$: Observable<string>;
  public videoUrl$: Observable<string>;
  public footerMessageId$: Observable<string>;
  public footerMessage$: Observable<string>;
  public footerCtaLabel$: Observable<string>;
  public footerCtaURL$: Observable<string>;
  public footerCtaProduct$: Observable<string>;

  constructor(private _contentService: ContentService, private _snapshotService: SnapshotReportService) {
    this.loaded$ = this._loaded$$.asObservable();
    this.error$ = this._error$$.asObservable();
    this.grade$ = this._grade$$.asObservable().pipe(skipWhile((x) => x === undefined));
    this.content$ = this._content$$.asObservable().pipe(skipWhile((x) => !x));
    this.config$ = this._config$$.asObservable().pipe(skipWhile((x) => !x));
    this.data$ = this._data$$.asObservable().pipe(skipWhile((x) => !x));
    this.hasGrade$ = this.grade$.pipe(
      map((grade) => grade !== Grade.NO_GRADE),
      distinctUntilChanged(),
    );
    this.messageId$ = this.content$.pipe(
      map((c) => c.messageId),
      distinctUntilChanged(),
    );
    this.message$ = this.config$.pipe(
      map((c) => c.customizedMessage || ''),
      distinctUntilChanged(),
    );
    this.footerMessageId$ = this.content$.pipe(
      map((c) => c.footerMessageId),
      distinctUntilChanged(),
    );
    this.footerMessage$ = this.config$.pipe(
      map((c) => c.customizedFooterMessage || ''),
      distinctUntilChanged(),
    );
    this.footerCtaLabel$ = this.config$.pipe(
      map((c) => c.customizedFooterCtaLabel || ''),
      distinctUntilChanged(),
    );
    this.footerCtaURL$ = this.config$.pipe(
      map((c) => c.customizedFooterCtaTargetUrl || ''),
      distinctUntilChanged(),
    );
    this.footerCtaProduct$ = this.config$.pipe(
      map((c) => c.customizedFooterCtaTargetProduct || ''),
      distinctUntilChanged(),
    );
    this.videoUrl$ = observableCombineLatest([this.content$, this._snapshotService.videoStyle$]).pipe(
      map(([content, videoStyle]) => {
        return this._contentService.getVideoUrl(content.videoId, videoStyle);
      }),
      distinctUntilChanged(),
    );
  }

  public init(): void {
    if (this._initialized) {
      return;
    }
    this._initialized = true;
    this.subscriptions.push(
      observableCombineLatest([this._snapshotService.snapshotId$, this._configurationChanged$$.asObservable()])
        .pipe(
          tap(([snapshotId]) => (this.snapshotId = snapshotId)),
          switchMap(() => this._load()),
        )
        .subscribe(),
    );

    this.createGradeSubscription();
    // register the service to handle the section data
    this._snapshotService.sectionServices.set(this.sectionId, this);
  }

  reload(): void {
    this._loaded$$.next(false);
    this._load().pipe(take(1)).subscribe();
  }

  private _load(): Observable<boolean> {
    const retryConfig = {
      maxAttempts: 5,
      retryDelay: 100,
      timeoutMilliseconds: 10000,
    };

    return this.load().pipe(
      retryer(retryConfig),
      map((section) => {
        this._loaded$$.next(true);
        this._error$$.next(false);
        this._grade$$.next(section?.grade || Grade.NO_GRADE);
        this._data$$.next(section?.data);
        this._config$$.next(section?.config);
        this._content$$.next(section?.content);
      }),
      catchError((e) => {
        console.error(e);
        this._loaded$$.next(true);
        this._error$$.next(true);
        this._grade$$.next(Grade.NO_GRADE);
        return observableOf(false);
      }),
      map(() => true),
    );
  }

  updateConfig(c: Config): Observable<boolean> {
    return this._updateConfig(c).pipe(
      tap(() => {
        this._configurationChanged$$.next(true);
      }),
    );
  }

  // implement this to return a new Config object
  abstract newConfig(): Config;

  // implement this to load a section and return an object satisfying SectionInterface
  abstract load(): Observable<SectionInterface>;

  // implement this to call your section API to update its config
  abstract _updateConfig(c: Config): Observable<boolean>;

  // implement this to call set<Section>Grade() on the snapshot service
  abstract createGradeSubscription(): void;

  // implement this to get the grade explanation data for the section
  abstract getGradeExplanationData(): GradeExplanationData;
}
