interface BusinessInterface {
  partnerId: string;
  marketId?: string;
  companyName: string;
  address: string;
  city: string;
  state: string;
  zip?: string;
  phoneNumber?: string;
}

export class Business {
  partnerId: string;
  marketId: string;
  companyName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;

  constructor(business: BusinessInterface) {
    this.partnerId = business.partnerId;
    this.marketId = business.marketId || '';
    this.companyName = business.companyName;
    this.address = business.address;
    this.city = business.city;
    this.state = business.state;
    this.zip = business.zip || '';
    this.phoneNumber = business.phoneNumber || '';
  }
}
