import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'snap-grade-thermometer',
  template: `
    <div class="thermometer">
      <span class="section section-F">F</span>
      <span class="section section-D">D</span>
      <span class="section section-C">C</span>
      <span class="section section-B">B</span>
      <span class="section section-A section-last">A</span>
    </div>
  `,
  styleUrls: ['./grade-thermometer.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GradeThermometerComponent {}
