/// <reference types="@types/google.maps" />
import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { bindCallback, from, Observable, throwError as observableThrowError } from 'rxjs';
import { filter, map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Business } from './business';
import { GooglePlace } from './google-place';

@Injectable()
export class GooglePlacesService {
  private placesService$: Observable<google.maps.places.PlacesService>;
  private geocoderService$: Observable<google.maps.Geocoder>;

  public init(attrContainer: HTMLDivElement): void {
    const loader = new Loader({
      apiKey: 'AIzaSyAmspLS1TMGKwvdwkoCxLzhhCVji_okch0',
      libraries: ['places'],
      version: 'quarterly',
    });
    const loaded$ = from(loader.load()).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.placesService$ = loaded$.pipe(
      map((google) => {
        return new google.maps.places.PlacesService(attrContainer);
      }),
      shareReplay(),
    );
    this.geocoderService$ = loaded$.pipe(
      map((google) => {
        return new google.maps.Geocoder();
      }),
      shareReplay(),
    );
  }

  public getGooglePlaceForBusiness(business: Business): Observable<GooglePlace> {
    const term = [business.companyName, business.address].join(', ');
    let placeResult: google.maps.places.PlaceResult;
    return this.textSearch(term).pipe(
      switchMap((places) => {
        if (places.length < 1) {
          observableThrowError(new Error('Not Found'));
        }
        return this.getDetails(places[0].place_id || '');
      }),
      tap((place) => (placeResult = place)),
      switchMap((place) => this.geocode(place.place_id || '')),
      map((geocode) => GooglePlace.convertGeocodeAndPlaceToGooglePlace(geocode, placeResult)),
      shareReplay(),
    );
  }

  private textSearch(queryString: string): Observable<google.maps.places.PlaceResult[]> {
    return this.placesService$.pipe(
      filter((x) => x !== undefined),
      mergeMap((service) => {
        const textSearchCallback = service.findPlaceFromQuery.bind(service);
        const textSearch$ = bindCallback(
          textSearchCallback,
          (results: google.maps.places.PlaceResult[], status: google.maps.places.PlacesServiceStatus) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              results = results.slice(0, 10);
              return results;
            } else {
              return [];
            }
          },
        );
        return textSearch$({
          query: queryString,
          fields: ['place_id'],
        });
      }),
    );
  }

  private getDetails(placeId: string): Observable<google.maps.places.PlaceResult> {
    return this.placesService$.pipe(
      filter((x) => x !== undefined),
      mergeMap((service) => {
        const getDetailsCallback = service.getDetails.bind(service);
        const getDetails$ = bindCallback(
          getDetailsCallback,
          (result: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              return result;
            } else {
              observableThrowError(new Error('Not Found'));
            }
          },
        );
        return getDetails$({
          placeId: placeId,
          fields: [
            'place_id',
            'name',
            'formatted_address',
            'formatted_phone_number',
            'international_phone_number',
            'url',
          ],
        });
      }),
    );
  }

  private geocode(placeId: string): Observable<google.maps.GeocoderResult> {
    return this.geocoderService$.pipe(
      filter((x) => x !== undefined),
      mergeMap((service) => {
        const geocodeCallback = service.geocode.bind(service);
        const geocode$ = bindCallback(
          geocodeCallback,
          (result: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
            if (status === google.maps.GeocoderStatus.OK) {
              if (result.length < 1) {
                observableThrowError(new Error('Not Found'));
              }
              return result[0];
            } else {
              observableThrowError(new Error('Not Found'));
            }
          },
        );
        return geocode$({
          placeId: placeId,
        });
      }),
    );
  }
}
