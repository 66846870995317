import { Injectable, ModuleWithProviders } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { RouteGeneratorParams, RouteType, RoutingServiceInterface } from '@galaxy/snapshot';
import { LoginComponent } from './login';
import { HasSessionAuthGuardService } from './auth-guard/auth-guard.service';
import { SnapshotLiteComponent, SnapshotLiteSalesforceComponent } from '@galaxy/snapshot';
import { LogoutComponent } from './logout';
import { SnapshotSessionRedirectComponent } from './session/session-redirect.component';
import { AuthService } from './auth/auth.service';
import { NotFoundComponent } from './error/not-found.component';
import { ErrorComponent } from './error/error.component';
import { SnapshotReportComponent } from './report/snapshot-report.component';
import { SnapshotReportEditComponent } from './report/snapshot-report-edit.component';

export const v2routes: Route[] = [
  // entry route from Vendasta Login Center
  {
    path: 'redirect',
    component: NotFoundComponent,
    canActivate: [AuthService],
  },
  // handles the redirect to/from Vendasta Login Center
  {
    path: 'session-transfer/:partnerId/:snapshotId/:action',
    component: SnapshotSessionRedirectComponent,
    canActivate: [AuthService],
  },
  // gathers the snapshot data to add to the route before redirecting to the login
  {
    path: 'session',
    component: SnapshotSessionRedirectComponent,
  },
  // edit route for authenticated users
  {
    path: 'edit/:snapshotId',
    component: SnapshotReportEditComponent,
    resolve: { reloadOnInvalidSession: () => true },
    canActivate: [AuthService],
  },
  // view route
  {
    path: 'view/:snapshotId',
    component: SnapshotReportComponent,
  },
  // legacy-like route redirection to keep user experience the same as v1 routes under the OAuth2 auth
  {
    path: ':snapshotId/:action',
    component: SnapshotSessionRedirectComponent,
  },
  {
    path: ':snapshotId',
    redirectTo: 'view/:snapshotId',
    pathMatch: 'full',
  },
];

export const routes: Route[] = [
  { path: 'snapshot/v2', children: v2routes },
  { path: 'snapshot/login', component: LoginComponent },
  { path: 'snapshot/logout', component: LogoutComponent },
  { path: 'snapshot/404', component: NotFoundComponent },
  { path: 'snapshot/error', component: ErrorComponent },
  { path: 'snapshot/lite/sfid/:salesforceId', component: SnapshotLiteSalesforceComponent },
  { path: 'snapshot/lite/:businessId', component: SnapshotLiteComponent },
  { path: 'snapshot/:snapshotId', component: SnapshotReportComponent },
  {
    path: 'snapshot/:snapshotId/edit',
    component: SnapshotReportEditComponent,
    canActivate: [HasSessionAuthGuardService],
  },
  {
    path: 'config/snapshot/:snapshotId/edit',
    component: SnapshotReportEditComponent,
    canActivate: [HasSessionAuthGuardService],
  },
  { path: '**', component: NotFoundComponent },
];

export const AppRoutingModule: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
  onSameUrlNavigation: 'reload',
  bindToComponentInputs: true,
});

@Injectable({
  providedIn: 'root',
})
export class RoutingService implements RoutingServiceInterface {
  getRoute(params: RouteGeneratorParams): string {
    switch (params.routeType) {
      case RouteType.View:
        return '/snapshot/' + params.snapshotId;
      case RouteType.Edit:
        return '/snapshot/' + params.snapshotId + '/edit';
      case RouteType.Lite:
        return 'snapshot/lite/' + params.businessId;
      case RouteType.Error:
        return '/snapshot/error';
      case RouteType.EditV2:
        return '/snapshot/v2/' + params.snapshotId + '/edit';
    }
  }

  isOldEditRoute(route: string): boolean {
    return route.match('/snapshot/[^/]*/edit') && !route.match('/snapshot/v2/edit/');
  }
}
