import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// HTTP status codes that indicate an unauthorized request we want to handle
const UnauthorizedStatus = [401, 403];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  readonly validSession$ = new BehaviorSubject<boolean>(true);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if (UnauthorizedStatus.includes(error.status)) {
            this.validSession$.next(false);
          }
        }
        return throwError(() => error);
      }),
    );
  }
}
