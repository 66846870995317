<div [ngClass]="{ child: isChild }" class="toggle-container">
  <div>
    <mat-progress-spinner mode="indeterminate" *ngIf="hideControl.pending" [diameter]="25"></mat-progress-spinner>
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'checkbox'">
        <mat-checkbox
          *ngIf="!hideControl.pending"
          [checked]="!hideControl.value && !disabled"
          (change)="onChange($event)"
          [disabled]="disabled"
        ></mat-checkbox>
      </ng-container>
      <ng-container *ngSwitchCase="'slideToggle'">
        <mat-slide-toggle
          *ngIf="!hideControl.pending"
          [checked]="!hideControl.value && !disabled"
          (change)="onChange($event)"
          [disabled]="disabled"
        ></mat-slide-toggle>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="label" [ngClass]="{ disabled: disabled }">
    {{ label }}
  </div>
</div>
