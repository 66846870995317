import { ControlType } from '../vform.models';

export interface FieldBaseOptions {
  id: string;
  label: string;
  name: string;
  required?: boolean;
  description?: string;
}

export interface FieldData extends FieldBaseOptions {
  control?: string;
  value?: any;
  datatype?: string;
  placeholder?: string;
  section?: string;
  altersForm?: boolean;
  visible?: boolean;
  enabled?: boolean;
  locked?: boolean;
  message?: string | VFormMultiErrorMessage[];
  options?: any;
  includeBlank?: boolean;
  selected?: any;
  regexp?: RegExp;
  maxItems?: number;
  maxMultiples?: number;
  tokenSeparators?: string[]; // ?
  max?: number | string;
  min?: number | string;
  lockedMessage?: string;
  ratingMessages?: any;
  tinymceSettings?: any;
  tooltip?: string;
  fileurl?: string;
  filepath?: string;
  filename?: string;
  allowMultiples?: boolean;
  useSelect2?: boolean;
}

export interface VFormMultiErrorMessage {
  index: number;
  message: string;
}

export class FieldBase<T> implements FieldData {
  name: string;
  value: T;
  id: string;
  label: string;
  required: boolean;
  order: number;
  description: string; // Tooltip of vform
  controlType: ControlType;

  // Vform compatible fields data
  control?: string;
  datatype?: string;
  placeholder?: string;
  section?: string;
  altersForm?: boolean;
  visible?: boolean;
  enabled?: boolean;
  locked?: boolean;
  message?: string | VFormMultiErrorMessage[];
  options?: any;
  includeBlank?: boolean;
  selected?: any;
  regexp?: RegExp;
  maxItems?: number;
  maxMultiples?: number;
  tokenSeparators?: string[]; // ?
  max?: number | string;
  min?: number | string;
  lockedMessage?: string;
  ratingMessages?: any;
  tinymceSettings?: any;
  enableInternetDataProviders?: boolean;
  columns?: number;
  allowMultiples?: boolean;
  useSelect2?: boolean;
  tooltip?: string;

  setOptions(options: FieldBaseOptions): void {
    if (!options) {
      return;
    }
    this.id = options.id;
    this.label = options.label;
    this.required = !!options.required;
    this.description = options.description || null;
  }

  setFieldData(fieldData: FieldData): void {
    Object.assign(this, fieldData);
    this.controlType = ControlType[fieldData.control.toUpperCase()];
    this.label = this.decodeHtml(this.label, this.controlType === ControlType['TEXT-SEPARATOR']);
    if (this.options && this.options.length > 0) {
      this.options.forEach((option: { label: string }) => {
        option.label = this.decodeHtml(option.label);
      });
    }
    switch (this.controlType) {
      case ControlType.CHECKBOX:
      case ControlType.TOGGLE:
        this.value = fieldData.value || false;
        break;
      case ControlType.TEXT:
      case ControlType.HIDDEN:
      case ControlType.TEXTBOX:
        this.value = fieldData.value || '';
        break;
      case ControlType.MULTITEXT:
        this.value = fieldData.value || [''];
        break;
    }
  }

  public setServerError(m: string | { message: string }): void {
    if (typeof m === 'string') {
      this.message = m;
    } else {
      this.message = m.message;
    }
  }

  public clearServerError(): void {
    this.message = null;
  }

  public updateField(fieldUpdateData: FieldData): void {
    if (fieldUpdateData.visible !== undefined) {
      this.visible = fieldUpdateData.visible;
    }
    if (fieldUpdateData.enabled !== undefined) {
      this.enabled = fieldUpdateData.enabled;
    }
    if (fieldUpdateData.locked !== undefined) {
      this.locked = fieldUpdateData.locked;
    }
    if (fieldUpdateData.required !== undefined) {
      this.required = fieldUpdateData.required;
    }
    if (fieldUpdateData.message !== undefined) {
      this.message = fieldUpdateData.message;
    }
    if (fieldUpdateData.tooltip !== undefined) {
      this.description = fieldUpdateData.tooltip;
    }
    if (fieldUpdateData.value !== undefined) {
      this.value = fieldUpdateData.value;
    }
    if (
      this.controlType === ControlType.DROPDOWN ||
      this.controlType === ControlType.RADIO ||
      this.controlType === ControlType.CHECKBOX ||
      this.controlType === ControlType.MULTISELECT ||
      this.controlType === ControlType.COUNTRYSTATE ||
      this.controlType === ControlType.TAG
    ) {
      if (fieldUpdateData.options !== undefined) {
        if (fieldUpdateData.includeBlank) {
          fieldUpdateData.options.unshift({ label: this.decodeHtml(this.placeholder) || '', value: '' });
        }
        if (this.controlType !== ControlType.COUNTRYSTATE) {
          fieldUpdateData.options.forEach((option: { label: string }) => {
            option.label = this.decodeHtml(option.label);
          });
        }
        this.options = fieldUpdateData.options;
      }
      if (fieldUpdateData.selected !== undefined) {
        this.selected = fieldUpdateData.selected;
        this.value = fieldUpdateData.selected;
      }
    }
  }

  public toString(): any {
    if (this.controlType === ControlType.COUNTRYSTATE) {
      return JSON.stringify(this.value);
    } else {
      return this.value.toString();
    }
  }

  decodeHtml(input: string, takeAllContent = false): string {
    if ('DOMParser' in window) {
      const doc = new DOMParser().parseFromString(input, 'text/html');
      if (!takeAllContent) {
        return doc.documentElement.textContent;
      } else {
        return doc.documentElement.innerHTML;
      }
    }
    return input;
  }
}
