import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { Grade } from '@vendasta/snapshot';
import { firstValueFrom } from 'rxjs';
import { SectionServiceInterface } from '../common/interfaces';
import { GradeExplainerDialogComponent } from '../grade/grade-explainer-dialog.component';
import { Media, MediaToken } from '../providers/providers';
import { SectionServiceInterfaceToken } from '../section/section.service';
import { SnapshotService } from '../snapshot.service';

@Component({
  selector: 'snap-section-container',
  templateUrl: './section-container.component.html',
  styleUrls: ['./section-container.component.scss'],
  animations: [
    trigger('toggleSection', [
      state('on', style({ height: '*' })),
      state('off', style({ height: '0' })),
      transition('on <=> off', animate('600ms ease-in')),
    ]),
  ],
})
export class SectionContainerComponent {
  @Input() grade?: Grade;
  @Input() hideGrade: boolean;
  @Input() hideControl: UntypedFormControl;
  @Input() videoUrl: string;
  @Input() edit: boolean;

  constructor(
    @Inject(SectionServiceInterfaceToken) public sectionService: SectionServiceInterface,
    public snapshotService: SnapshotService,
    public analyticsService: ProductAnalyticsService,
    @Inject(MediaToken) private readonly media: Media,
    private _dialog: MatDialog,
  ) {}

  async openExplainer(): Promise<void> {
    const location = this.sectionService.sectionId;
    this.analyticsService.trackEvent(location, 'grade-explanation', 'click');

    const gradeData = this.sectionService.getGradeExplanationData();
    const isMobile = await firstValueFrom(this.media.mobile$);
    this._dialog.open(GradeExplainerDialogComponent, {
      data: {
        ...gradeData,
        videoUrl: isMobile ? this.videoUrl : '', // only pass video url in mobile view
      },
      maxWidth: '960px',
    });
  }
}
