<snap-toggled-item [edit]="edit" [hideControl]="socialItem.control">
  <div class="listing-box">
    <div class="listing">
      <ng-container *ngIf="socialItem?.foundCount > 0; else notFound">
        <div class="image">
          <ng-container *ngIf="!!socialItem?.exampleListingUrl; else noLink">
            <a href="{{ socialItem?.exampleListingUrl }}" target="_blank">
              <img [src]="socialItem?.sourceIcon" />
            </a>
          </ng-container>
          <ng-template #noLink>
            <img [src]="socialItem?.sourceIcon" />
          </ng-template>
          <span>{{ socialItem?.name }}</span>
        </div>
        <div class="center">
          <div class="bold">
            <span>{{ socialItem?.name }}</span>
          </div>

          <div>
            <span class="found">
              {{ 'LISTINGS.PRESENCE.BUSINESS_FOUND' | translate }}
            </span>
          </div>
        </div>
      </ng-container>
      <div class="center">
        <div class="found-online">
          {{
            'LISTINGS.PRESENCE.FOUND_ONLINE'
              | translate
                : {
                    percentage: socialItem?.industryAverageFound | metricNotation,
                    source: socialItem?.name
                  }
          }}
        </div>
      </div>
    </div>
    <ng-template snapItemToggle></ng-template>
  </div>
</snap-toggled-item>

<ng-template #notFound>
  <div class="image">
    <img [src]="socialItem?.sourceIcon" />
    <span>{{ socialItem?.name }}</span>
  </div>
  <div class="center">
    <div class="bold">
      <span>{{ socialItem?.name }}</span>
    </div>

    <div>
      <div
        *ngIf="isInconclusive; else notFoundText"
        class="inconclusive"
        [glxyPopover]="inconclusivePopover"
        (mouseenter)="showPopover()"
        (mouseleave)="hidePopover()"
      >
        <span>{{ 'LISTINGS.PRESENCE.INCONCLUSIVE' | translate }}</span>
        <mat-icon class="hide-to-print">help_outline</mat-icon>
        <glxy-popover #inconclusivePopover [isOpen]="showingPopover" [positions]="TOOLTIP_RIGHT">
          <glxy-popover-title>{{ 'LISTINGS.PRESENCE.INCONCLUSIVE_TITLE' | translate }}</glxy-popover-title>
          {{ 'LISTINGS.PRESENCE.INCONCLUSIVE_MESSAGE' | translate : { social: socialItem.name } }}
        </glxy-popover>
      </div>
      <ng-template #notFoundText>
        <span class="not-found">
          {{ 'LISTINGS.PRESENCE.BUSINESS_NOT_FOUND' | translate }}
        </span>
      </ng-template>
    </div>
  </div>
</ng-template>
