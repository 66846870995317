<ng-container *ngIf="showDomainMetrics || competitorItems[0]?.competitors?.length > 0; else noDomainMetrics">
  <ng-container *ngIf="media.mobile$ | async; else desktopView">
    <snap-mobile-view-data [hideGrades]="true" [edit]="edit" [subsections]="competitorItems"></snap-mobile-view-data>
  </ng-container>
  <ng-template #desktopView>
    <snap-table [items]="competitorItems" [showIndustryAverage]="false" [linkCompetitors]="true"></snap-table>
  </ng-template>
</ng-container>

<ng-template #noDomainMetrics>
  <div class="not-found">
    <glxy-empty-state [size]="'small'" [widthConstraint]="false">
      <glxy-empty-state-hero>
        <mat-icon>campaign</mat-icon>
      </glxy-empty-state-hero>
      <glxy-empty-state-title>
        {{ 'ADVERTISING.PAY_PER_CLICK.ERROR_TITLE' | translate }}
      </glxy-empty-state-title>
      <p>{{ 'ADVERTISING.PAY_PER_CLICK.ERROR_MESSAGE' | translate }}</p>
    </glxy-empty-state>
  </div>
</ng-template>
