export enum GradeColor {
  positive = 'positive', //$grade-positive-color
  neutral = 'neutral', //$grade-neutral-color
  negative = 'negative', //$grade-negative-color
}

export const ChartColors = {
  businessCount: '#4caf50',
  availableSources: '#FFFFFF',
  competitors: ['#bbdefb', '#64b5f6', '#2196f3'],
  competitorsLabel: '#2196f3',
  datalabel: '#FFFFFF',
  referenceLabel: '#f1b79d',
};
