<h2 mat-dialog-title>{{ 'SNAPSHOT_LITE.QUESTION_HEADER' | translate }}</h2>
<mat-dialog-content>
  <mat-card appearance="outlined" class="mat-elevation-z0">
    <ng-container>
      <mat-card-header *ngIf="salesPerson$ | async; let salesPerson; else: noSalesPersonTemplate">
        <div
          *ngIf="salesPerson?.photoUrl"
          mat-card-avatar
          class="sales-contact-icon"
          [ngStyle]="{
            'background-image': 'url(' + salesPerson.photoUrl + ')'
          }"
        ></div>
        <div
          *ngIf="!salesPerson?.photoUrl"
          mat-card-avatar
          class="sales-contact-icon"
          [ngStyle]="{
            'background-image':
              'url(https://vstatic-prod.apigateway.co/snapshot-client.9223372035128602063.prod/assets/images/default_sales_person.jpg)'
          }"
        ></div>
        <mat-card-subtitle>{{ salesPerson?.firstName }} {{ salesPerson?.lastName }}</mat-card-subtitle>
        <mat-card-subtitle>
          {{ salesPerson?.jobTitle }}
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="salesPerson?.phoneNumbers?.length > 0">
          {{ salesPerson.phoneNumbers[0] | phone : salesPerson.country }}
        </mat-card-subtitle>
        <mat-card-subtitle>
          <a [href]="'mailto:' + salesPerson?.email" tabIndex="-1">
            {{ salesPerson?.email }}
          </a>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p class="question-body">
          {{ 'SNAPSHOT_LITE.QUESTION_BODY' | translate }}
        </p>
        <form class="form-container flex-column" [formGroup]="contactForm">
          <mat-form-field>
            <input matInput placeholder="{{ 'COMMON.FIRST_NAME' | translate }}" formControlName="firstName" />
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="{{ 'COMMON.LAST_NAME' | translate }}" formControlName="lastName" />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'COMMON.PHONE_NUMBER' | translate }}"
              required
              formControlName="phoneNumber"
            />
            <mat-error *ngIf="this.contactForm.controls.phoneNumber.hasError('required')">
              {{ 'ERRORS.PHONE_NUMBER_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <textarea
              matInput
              cdkTextareaAutosize
              required
              placeholder="{{ 'CONTACT_US.MESSAGE_PLACEHOLDER' | translate }}"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5"
              maxlength="500"
              formControlName="message"
            ></textarea>
            <mat-hint align="end">{{ contactForm.controls.message.value.length }} / 500</mat-hint>
            <mat-error *ngIf="contactForm.controls.message.invalid">
              {{ 'ERRORS.MESSAGE_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>
        </form>
      </mat-card-content>
    </ng-container>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="close()">
    {{ 'COMMON.CANCEL' | translate }}
  </button>
  <button mat-flat-button color="primary" (click)="submit()" [disabled]="!contactForm.valid">
    {{ 'COMMON.SEND' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #noSalesPersonTemplate>
  <mat-card-header>
    <div
      mat-card-avatar
      class="sales-contact-icon"
      style="
        background-image: url('https://vstatic-prod.apigateway.co/snapshot-client.9223372035128602063.prod/assets/images/ault_sales_person.jpg');
      "
    ></div>
    <mat-card-subtitle>
      {{ whitelabelService.partnerName$ | async }}
    </mat-card-subtitle>
  </mat-card-header>
</ng-template>
