import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { CompetitionAnalysis, InstagramDataInstagramDataStatus as InstagramDataStatus } from '@vendasta/snapshot';
import { Observable, combineLatest, combineLatest as observableCombineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { SectionTableCompetitorItemInterface, SectionTableItem } from '../../section-table/section-table';
import { Media, MediaToken } from '../../providers/providers';
import { BaseSectionComponent } from '../../section/base-section.component';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';
import { INSTAGRAM_STATUS_OPTIONS } from '../social-data';
import { SocialService } from '../social.service';
import { createCompetitorGradeTable, createCompetitorGradeTableItem } from '../util';

@Component({
  selector: 'snap-instagram-subsection',
  templateUrl: './instagram-subsection.component.html',
  styleUrls: ['../social-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: SocialService,
    },
  ],
})
export class InstagramSubsectionComponent extends BaseSectionComponent implements OnInit {
  instagramTableItems$: Observable<SectionTableItem[]> = new Observable<SectionTableItem[]>();
  competitorInstagramTableItems$: Observable<SectionTableItem[]> = new Observable<SectionTableItem[]>();

  isInstagramWorking = false;
  instagramUrlControl: UntypedFormControl = new UntypedFormControl('');
  instagramStatus$: Observable<InstagramDataStatus>;
  invalidUserIdStatus = INSTAGRAM_STATUS_OPTIONS.INVALID_USER_ID;
  inProgressStatus = INSTAGRAM_STATUS_OPTIONS.IN_PROGRESS;
  reelDetectedStatus = INSTAGRAM_STATUS_OPTIONS.REEL_DETECTED;

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: SocialService,
    public snapshotService: SnapshotReportService,
    @Inject(MediaToken) public readonly media: Media,
    public translateService: TranslateService,
    public snackbarService: SnackbarService,
  ) {
    super(service, snackbarService, translateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.instagramStatus$ = observableCombineLatest([this.service.data$, this.snapshotService.snapshotData$]).pipe(
      map(([socialData, snapshotData]) => {
        if (snapshotData.instagramUrl === '') {
          // Successfully fetched no data for a business without Instagram
          return INSTAGRAM_STATUS_OPTIONS.SUCCESS;
        }
        if (!socialData.instagramData) {
          // We should always save something for Instagram data when we have a url, so it must be in progress until we get data
          return INSTAGRAM_STATUS_OPTIONS.IN_PROGRESS;
        }
        return socialData.instagramData.status;
      }),
    );

    this.instagramTableItems$ = this.service.data$.pipe(
      map((sd) => {
        return !sd.instagramData ||
          sd.instagramData.status !== INSTAGRAM_STATUS_OPTIONS.SUCCESS ||
          !sd.instagramData.followers ||
          !sd.instagramData.posts
          ? []
          : [
              {
                title: 'COMMON.FOLLOWERS',
                data: { ...sd.instagramData.followers, hasColor: true },
              },
              {
                title: 'SOCIAL.INSTAGRAM.POSTS',
                data: { ...sd.instagramData.posts, hasColor: true },
              },
            ];
      }),
    );

    this.competitorInstagramTableItems$ = combineLatest([
      this.service.data$,
      this.snapshotService.competitionAnalysis$,
    ]).pipe(
      filter(([sc]) => !!sc.instagramData),
      map(([sc, ca]) => {
        if (ca === CompetitionAnalysis.INDUSTRY_DATA) {
          return [];
        }
        const competitorInstagramFollowers: SectionTableCompetitorItemInterface[] = [];
        sc.competitors.forEach((c: any) => {
          competitorInstagramFollowers.push(createCompetitorGradeTableItem(c.companyName, c.instagramData?.followers));
        });
        const instagramFollowers = createCompetitorGradeTable(
          competitorInstagramFollowers,
          'COMMON.FOLLOWERS',
          sc.instagramData?.followers,
        );

        const competitorInstagramPosts: SectionTableCompetitorItemInterface[] = [];
        sc.competitors.forEach((c: any) => {
          competitorInstagramPosts.push(createCompetitorGradeTableItem(c.companyName, c.instagramData?.posts));
        });
        const instagramPosts = createCompetitorGradeTable(
          competitorInstagramPosts,
          'SOCIAL.INSTAGRAM.POSTS',
          sc.instagramData?.posts,
        );
        return [instagramFollowers, instagramPosts];
      }),
      startWith([]),
    );
  }

  addInstagramUrl(): void {
    const instagramUrl = this.instagramUrlControl.value;
    if (!instagramUrl) {
      return;
    }
    this.instagramUrlControl.markAsPending();
    this.service.updateInstagramUrl(this.snapshotService.businessId, this.instagramUrlControl.value).subscribe(
      () => {
        this.isInstagramWorking = true;
      },
      (err) => {
        this.instagramUrlControl.setErrors({ error: err.message });
        this.instagramUrlControl.setErrors({ message: 'COMMON.INVALID_URL' });
      },
    );
  }
}
