<div class="status-summary-container">
  <div class="status-summary-item" *ngFor="let item of statusSummary.SummaryItems">
    <mat-icon
      [ngClass]="{
        accurate: item.iconColor === GradeColor.positive,
        error: item.iconColor === GradeColor.neutral,
        'not-found': item.iconColor === GradeColor.negative,
      }"
    >
      {{ item.icon }}
    </mat-icon>
    <span>{{ item.value }}</span>
    <span class="hide-mobile text">{{ item.description }}</span>
  </div>
</div>
