import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Percentiles, Sections, SummaryThermometer } from './summary-thermometer';

@Component({
  selector: 'snap-summary-thermometer',
  templateUrl: './summary-thermometer.component.html',
  styleUrls: ['./summary-thermometer.component.scss'],
})
export class SummaryThermometerComponent implements OnChanges {
  @Input() thermometer: SummaryThermometer;
  @Input() percentiles: Percentiles;

  markerTopPercentile: number;
  markerBottomPercentile: number;
  sections: Sections;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.percentiles?.currentValue) {
      this.markerTopPercentile = parseFloat(this.percentiles?.top) || -1;
      this.markerBottomPercentile = parseFloat(this.percentiles?.bottom) || -1;
    }
    if (changes.thermometer?.currentValue) {
      this.sections = this.thermometer?.sections;
    }
  }

  getSectionClass(className: string): string {
    switch (className) {
      // only apply the section class styling if there is a percentile in the section
      case 'positive':
        return (this.markerTopPercentile >= 0 && this.markerTopPercentile <= 33) ||
          (this.markerBottomPercentile >= 0 && this.markerBottomPercentile <= 33)
          ? className
          : 'default';
      case 'neutral':
        return (this.markerTopPercentile > 33 && this.markerTopPercentile <= 66) ||
          (this.markerBottomPercentile > 33 && this.markerBottomPercentile <= 66)
          ? className
          : 'default';
      case 'negative':
        return this.markerTopPercentile > 66 || this.markerBottomPercentile > 66 ? className : 'default';
      default:
        return 'default';
    }
  }
}
