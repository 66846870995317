import { Injectable } from '@angular/core';
import * as marked from 'marked';

interface Link {
  href: string;
  text: string;
}

@Injectable({
  providedIn: 'root',
})
export class MarkedParserService {
  private md: any;

  constructor() {
    this.md = marked;
  }

  removeLinks(src: string, linkTextArr?: string[]): { html: string; links: Link[] } {
    const renderer = new this.md.Renderer();
    const links: Link[] = [];
    renderer.link = function (href: string, title: string, text: string): string {
      if (!linkTextArr || linkTextArr.filter((a) => text.toLowerCase() === a.toLowerCase()).length > 0) {
        links.push({ href: href, text: text } as Link);
        return '';
      }
      return marked.Renderer.prototype.link
        .call(this, href, title, text)
        .replace('<a', '<a target="_blank" rel="nofollow noopener noreferrer"');
    };
    return {
      html: this.md.parse(src, { renderer: renderer }),
      links: links,
    };
  }

  convert(src: string): string {
    const renderer = new this.md.Renderer();
    renderer.link = function (href: string, title: string, text: string): string {
      const link = marked.Renderer.prototype.link.call(this, href, title, text);
      return link.replace('<a', '<a target="_blank" rel="nofollow noopener noreferrer"');
    };
    return this.md.parse(src, { renderer: renderer });
  }
}
