import { MonoTypeOperatorFunction, ObservableInput, of, switchMap } from 'rxjs';

type validatorFn<T> = (value: T) => boolean;

/**
 * RxJs operator to switch to a new Observable when a condition is met.
 * @param next new Observable to switch to.
 * @param when validator function to determine when to switch. The source Observable value is passed to this function.
 * @returns the resulting Observable.
 * @example
 * ```ts
 * const source = of('first value');
 * const next = of('alternate value');
 * const result = source.pipe(switchWhen(next, (value) => value === 'first value'));
 * result.subscribe((value) => console.log(value)); // logs 'alternate value'
 * ```
 */
export function switchWhen<T>(next: ObservableInput<T>, when: validatorFn<T>): MonoTypeOperatorFunction<T> {
  return switchMap((value) => {
    if (when?.(value)) {
      return next;
    }
    return of(value);
  });
}

/**
 * RxJs operator to switch to a new Observable when the value of the source Observable is falsy.
 * @param next new Observable to switch to.
 * @returns the resulting Observable.
 * @example
 * ```ts
 * const source = of('');
 * const next = of('alternate value');
 * const result = source.pipe(switchWhenFalsy(next));
 * result.subscribe((value) => console.log(value)); // logs 'alternate value'
 * ```
 */
export function switchWhenFalsy<T>(next: ObservableInput<T>): MonoTypeOperatorFunction<T> {
  return switchWhen(next, (value) => !value);
}
