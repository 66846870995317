<ng-container *ngIf="snapshotName$ | async as sn">
  <h2 mat-dialog-title>{{ 'REFRESH_REPORT.TITLE' | translate : { snapshotName: sn | translate } }}</h2>
  <mat-dialog-content>
    <ng-container
      *ngIf="refreshReportService.provisioningInProgress$ | async; then inprogress; else description"
    ></ng-container>
    <ng-template #description>
      <p>{{ 'REFRESH_REPORT.FEES' | translate : { snapshotName: sn | translate } }}</p>
      <p>{{ 'REFRESH_REPORT.POPULATE' | translate }}</p>
    </ng-template>
    <ng-template #inprogress>
      <p>{{ 'REFRESH_REPORT.REDIRECTING' | translate }}</p>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close [disabled]="loading$$ | async">{{ 'COMMON.CANCEL' | translate }}</button>
    <button mat-raised-button color="primary" (click)="refresh()" [disabled]="loading$$ | async">
      <glxy-button-loading-indicator [isLoading]="loading$$ | async">
        {{ 'EDIT_REPORT.REFRESH' | translate }}
      </glxy-button-loading-indicator>
    </button>
  </mat-dialog-actions>
</ng-container>
