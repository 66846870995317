<div class="container">
  <mat-toolbar class="toolbar" color="primary">
    <mat-toolbar-row class="primary-row">
      <div class="start">
        <button mat-icon-button><mat-icon>menu</mat-icon></button>
        <h1>{{ reportName | translate }}</h1>
      </div>
      <div class="end">
        <app-share-report-button></app-share-report-button>
        <snap-icon-button icon="file_download"></snap-icon-button>
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="secondary-row">
      <div class="start">
        <img *ngIf="logoUrl" [src]="logoUrl" />
      </div>
      <div class="end">
        <button mat-flat-button color="primary" (click)="handleCtaClick()">
          {{ ctaLabel || (defaultCtaLabel | translate) }}
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="no-content"></div>
</div>
