<h2 *ngIf="data?.videoUrl" mat-dialog-title class="title-with-video">
  <span>{{ data.mobileTitleTextID | translate }}</span>
  <button mat-button color="primary" (click)="clickVideo(data?.videoUrl)">
    <mat-icon>play_circle_outline</mat-icon>
    <span>{{ 'COMMON.WATCH_VIDEO' | translate }}</span>
  </button>
</h2>
<h2 *ngIf="!data?.videoUrl" mat-dialog-title>
  {{ data.titleTextID | translate }}
</h2>
<mat-dialog-content class="add-bottom-spacing">
  <div [innerHtml]="data.primaryTextID | translate"></div>
  <div class="clarification-text" [innerHtml]="data.clarificationTextID | translate"></div>

  <div *ngIf="data.showThermometer">
    <snap-grade-thermometer></snap-grade-thermometer>
    <div class="percentile">{{ 'COMMON.PERCENTILE' | translate }}</div>
  </div>
  <div>
    <strong>{{ data.secondaryTitleTextID | translate }}</strong>
  </div>

  <div [innerHtml]="data.secondaryPreScaleTextID | translate"></div>

  <div class="grade-scale" *ngIf="data.showGradeScale">A = 4 | B = 3 | C = 2 | D = 1 | F = 0</div>

  <div [innerHtml]="data.secondaryPostScaleTextID | translate"></div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="close()">Close</button>
</mat-dialog-actions>
