<div class="mat-table">
  <div class="mat-header-row">
    <div class="mat-header-cell name"></div>
    <div class="mat-header-cell" class="hide-lt-md">
      {{ 'COMMON.YOUR_BUSINESS' | translate }}
      <br />
      {{ 'LISTINGS.PRESENCE.NUMBER_OF_LISTINGS' | translate }}
    </div>
    <div class="mat-header-cell"></div>
    <div class="mat-header-cell industryAverageHeader">
      {{ 'COMMON.INDUSTRY_AVERAGE' | translate }}
      <br />
      {{ 'LISTINGS.PRESENCE.PERCENT_SOCIAL' | translate }}
    </div>
    <div class="mat-header-cell toggle"></div>
  </div>
  <mat-divider></mat-divider>

  <snap-toggled-item *ngFor="let item of items; let last = last" [edit]="edit" [hideControl]="item.control">
    <div class="mat-row" [ngClass]="{ last: last }">
      <div class="mat-cell name flex align-start-center">
        <mat-icon
          [ngClass]="{
            'negative-grade': item.color === GradeColor.negative,
            'positive-grade': item.color === GradeColor.positive,
            'neutral-grade': item.color === GradeColor.neutral
          }"
        >
          {{ item.icon }}
        </mat-icon>
        <img *ngIf="item.sourceIcon !== ''" [src]="item.sourceIcon" class="hide-lt-md" />
        <span>{{ item.name }}</span>
        <span *ngIf="item.showSocialSite">
          {{ 'LISTINGS.PRESENCE.SOCIAL_SITE' | translate }}
        </span>
        <span *ngIf="item.showSocialSites">
          {{ 'LISTINGS.PRESENCE.SOCIAL_SITES' | translate }}
        </span>
      </div>

      <div class="mat-cell business" class="hide-lt-md">
        <span [ngStyle]="{ color: item.color }" *ngIf="item.foundCount > 0" class="foundCount">
          {{ formatNumber(item.foundCount) }}
        </span>
      </div>

      <div class="mat-cell business">
        <div class="example-data flex align-space-between-center">
          <span class="example-data" [ngStyle]="{ color: item.color }" *ngIf="item.errorMessage">
            <span>{{ 'COMMON.NOT_FOUND' | translate }}</span>
          </span>
          <span class="example-data" *ngIf="item.exampleListingUrl">
            <a
              target="_blank"
              [href]="item.exampleListingUrl"
              [trackEvent]="{
                eventName: item.name,
                category: 'external-link',
                action: 'click'
              }"
            >
              {{ 'COMMON.VIEW_EXAMPLE' | translate }}
            </a>
          </span>
        </div>
      </div>

      <div class="mat-cell industryAverageFound">
        <span *ngIf="!item.showSocialSite && !item.showSocialSites">
          {{ formatNumber(item.industryAverageFound) }}%
        </span>
      </div>

      <div class="mat-cell toggle">
        <ng-template snapItemToggle></ng-template>
      </div>
    </div>
  </snap-toggled-item>
</div>
