import { SocialItem, SocialItemInterface } from './social-item';
import { SocialCompetitor, SocialCompetitorInterface } from './social-competitor';
import { FacebookPost, FacebookPostInterface } from '../facebook-post/facebook-post';
import { InstagramDataInstagramDataStatus as InstagramDataStatus } from '@vendasta/snapshot';
import * as e from '@vendasta/snapshot';

interface FacebookDataInterface {
  averageSharesPerPost: SocialItemInterface;
  averageLikesPerPost: SocialItemInterface;
  likes: SocialItemInterface;
  posts?: SocialItemInterface;
  averagePostsPerMonth?: SocialItemInterface;
  recentPosts?: FacebookPostInterface[];
  url?: string;
}

interface TwitterDataInterface {
  followers: SocialItemInterface;
  following: SocialItemInterface;
  tweets: SocialItemInterface;
  url?: string;
}

interface InstagramDataInterface {
  followers?: SocialItemInterface;
  posts?: SocialItemInterface;
  url?: string;
  status?: e.InstagramDataInstagramDataStatus;
}

interface SocialDataInterface {
  facebookData?: FacebookDataInterface;
  twitterData?: TwitterDataInterface;
  instagramData?: InstagramDataInterface;
  competitors?: SocialCompetitorInterface[];
}

class FacebookData {
  averageSharesPerPost: SocialItem;
  averageLikesPerPost: SocialItem;
  likes: SocialItem;
  posts?: SocialItem;
  averagePostsPerMonth?: SocialItem;
  recentPosts: FacebookPost[];
  url: string;

  constructor(f: FacebookDataInterface) {
    this.averageSharesPerPost = new SocialItem(f.averageSharesPerPost);
    this.averageLikesPerPost = new SocialItem(f.averageLikesPerPost);
    this.likes = new SocialItem(f.likes);
    this.posts = f.posts ? new SocialItem(f.posts) : undefined;
    this.averagePostsPerMonth = f.averagePostsPerMonth ? new SocialItem(f.averagePostsPerMonth) : undefined;
    this.recentPosts = f.recentPosts ? f.recentPosts.map((p) => new FacebookPost(p)) : [];
    this.url = f.url || '';
  }
}

class TwitterData {
  followers: SocialItem;
  following: SocialItem;
  tweets: SocialItem;
  url: string;

  constructor(t: TwitterDataInterface) {
    this.followers = new SocialItem(t.followers);
    this.following = new SocialItem(t.following);
    this.tweets = new SocialItem(t.tweets);
    this.url = t.url || '';
  }
}

class InstagramData {
  followers?: SocialItem;
  posts?: SocialItem;
  url: string;
  status: e.InstagramDataInstagramDataStatus;

  constructor(ig: InstagramDataInterface) {
    this.followers = ig.followers ? new SocialItem(ig.followers) : undefined;
    this.posts = ig.posts ? new SocialItem(ig.posts) : undefined;
    this.url = ig.url || '';
    this.status =
      ig.status === undefined
        ? e.InstagramDataInstagramDataStatus.INSTAGRAM_DATA_INSTAGRAM_DATA_STATUS_IN_PROGRESS
        : ig.status;
  }
}

export class SocialData {
  facebookData: FacebookData | null;
  twitterData: TwitterData | null;
  instagramData: InstagramData | null;
  competitors: SocialCompetitor[];

  constructor(data: SocialDataInterface) {
    this.facebookData = data.facebookData ? new FacebookData(data.facebookData) : null;
    this.twitterData = data.twitterData ? new TwitterData(data.twitterData) : null;
    this.instagramData = data.instagramData ? new InstagramData(data.instagramData) : null;
    this.competitors = (data.competitors || []).map((c) => {
      return new SocialCompetitor(c);
    });
  }
}

export const INSTAGRAM_STATUS_OPTIONS = {
  IN_PROGRESS: InstagramDataStatus.INSTAGRAM_DATA_INSTAGRAM_DATA_STATUS_IN_PROGRESS,
  SUCCESS: InstagramDataStatus.INSTAGRAM_DATA_INSTAGRAM_DATA_STATUS_SUCCESS,
  INVALID_USER_ID: InstagramDataStatus.INSTAGRAM_DATA_INSTAGRAM_DATA_STATUS_INVALID_USER_ID,
  UNKNOWN_ERROR: InstagramDataStatus.INSTAGRAM_DATA_INSTAGRAM_DATA_STATUS_UNKNOWN_ERROR,
  REEL_DETECTED: InstagramDataStatus.INSTAGRAM_DATA_INSTAGRAM_DATA_STATUS_REEL_DETECTED,
};
