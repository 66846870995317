import { Component, Input } from '@angular/core';
import { DataProviderAccuracy } from '../listing-data';
import { ListingDistributionVisibilityStatus } from './listing-distribution';

@Component({
  selector: 'snap-listing-distribution-table',
  templateUrl: './listing-distribution-table.component.html',
  styleUrls: ['./listing-distribution-table.component.scss'],
})
export class ListingDistributionTableComponent {
  @Input() dataProviderAccuracy: DataProviderAccuracy;
  @Input() visibleDataProviderSources: ListingDistributionVisibilityStatus;
}
