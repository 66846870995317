<div class="row status">
  <div class="col col-xs-6 col-md-12">
    <div class="provider">
      <img [ngClass]="source" [src]="image" />
    </div>
  </div>
  <div class="col col-xs-6 col-md-12">
    <div [ngClass]="class">
      <mat-icon class="hide-mobile">{{ icon }}</mat-icon>
      <span>{{ text | translate }}</span>
    </div>
  </div>
</div>
