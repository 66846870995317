import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'metricNotation' })
export class MetricNotationPipe implements PipeTransform {
  static processNumber(input: number): string {
    const suffixes = ['K', 'M', 'B', 'T'];
    let suffixIndex = -1;
    let val = Math.abs(input);
    if (isNaN(val)) {
      if (input === undefined || input === null) {
        return input as unknown as string;
      }
      return input.toString();
    }
    const sign = input < 0 ? '-' : '';
    while (val >= 1000) {
      val = val / 1000;
      suffixIndex += 1;
    }

    const finalIndex = suffixes.length - 1;
    if (suffixIndex > finalIndex) {
      return input.toString();
    }
    const suffix = suffixIndex >= 0 ? suffixes[suffixIndex] : null;
    const truncatedNumber = Math.floor(val * 100) / 100;
    return suffix ? `${sign}${truncatedNumber.toFixed(2)}${suffix}` : truncatedNumber.toLocaleString('en');
  }

  transform(input: number): string {
    return MetricNotationPipe.processNumber(input);
  }
}
