import { HttpErrorResponse } from '@angular/common/http';
import {
  Observable,
  of as observableOf,
  race,
  range,
  ReplaySubject,
  throwError as observableThrowError,
  timer,
} from 'rxjs';
import { delayWhen, first, merge, retryWhen, skip, switchMap, tap, zip } from 'rxjs/operators';

export interface RetryConfig {
  // Maximimum amount of time to retry before giving up.
  timeoutMilliseconds?: number;
  // Maximimum number of times to retry before giving up.
  maxAttempts?: number;
  // Initial delay between retries. The delay doubles on each subsequent retry.
  retryDelay?: number;
  // A custom predicate to determine whether to retry on an http error.
  customRetryChecker?: (errResp: HttpErrorResponse) => boolean;
}

const DEFAULT_TIMEOUT_MILLIS = 5000;
const DEFAULT_MAX_ATTEMPTS = 10;
const DEFAULT_RETRY_DELAY_MILLIS = 200;
const DEFAULT_RETRY_CHECKER: ((errResp: HttpErrorResponse) => boolean | null) | null = null;

/**
 * This class wraps an http call in retry logic according to a configuration.
 *
 * Only to be used for idempotent calls.
 */
export class Retryer<T> {
  public result$: Observable<T>;
  private retriedErrors$$ = new ReplaySubject<HttpErrorResponse>(1);

  private timeoutMilliseconds: number;
  private maxAttempts: number | null;
  private retryDelay: number;
  private customRetryChecker: ((errResp: HttpErrorResponse) => boolean | null) | null;

  /**
   *
   * @param call The http call or observable to be retried through subscribing
   * @param config Configure the retry logic
   */
  constructor(call: Observable<T>, config?: RetryConfig) {
    const {
      timeoutMilliseconds = DEFAULT_TIMEOUT_MILLIS,
      maxAttempts = DEFAULT_MAX_ATTEMPTS,
      retryDelay = DEFAULT_RETRY_DELAY_MILLIS,
      customRetryChecker = DEFAULT_RETRY_CHECKER,
    } = config || {};
    this.timeoutMilliseconds = timeoutMilliseconds;
    this.maxAttempts = maxAttempts;
    this.retryDelay = retryDelay;
    this.customRetryChecker = customRetryChecker;

    this.result$ = call.pipe(
      retryWhen((errors$: Observable<HttpErrorResponse>) => this.getRetries(errors$)),
      first(),
    );
  }

  private getRetries(errors$: Observable<HttpErrorResponse>): Observable<any> {
    return errors$.pipe(
      tap((err) => this.retriedErrors$$.next(err)),
      switchMap((errResp) => (this.shouldRetry(errResp) ? observableOf(errResp) : observableThrowError(errResp))),
      zip(range(0, this.maxAttempts - 1), (err, tryNumber) => tryNumber),
      delayWhen((tryNumber) => this.getDelayTimer(tryNumber)),
      merge(this.getCutoffNotifier(errors$)),
    );
  }

  private getCutoffNotifier(errors$: Observable<HttpErrorResponse>): Observable<HttpErrorResponse> {
    const maxTimeout$ = timer(this.timeoutMilliseconds);
    const throwLastError$ = this.retriedErrors$$.pipe(switchMap((err) => observableThrowError(err)));
    const maxAttempts$ = errors$.pipe(skip(this.maxAttempts - 1), first());

    return race<any>(maxTimeout$, maxAttempts$).pipe(switchMap(() => throwLastError$));
  }

  private shouldRetry(err: HttpErrorResponse): boolean {
    if (this.customRetryChecker !== null && this.customRetryChecker !== undefined) {
      return this.customRetryChecker(err);
    }
    if (err.error instanceof Error) {
      // non HTTP Error means a client error or network error occurred, we should retry
      return true;
    }
    return err.status >= 500;
  }

  private getDelayTimer(tryNumber: number): Observable<any> {
    const exponentialBackoffDelay = Math.pow(2, tryNumber) * this.retryDelay;
    return timer(exponentialBackoffDelay).pipe(first());
  }
}

/**
 * Usage: httpClient.get('example.com').let(retryWith(retryConfig))
 * OR:    httpClient.get('example.com').pipe( retryWith(retryConfig) );
 * @param config Retry configuration
 */
export const retryer =
  <T>(config?: RetryConfig) =>
  (call: Observable<T>): Observable<T> => {
    return new Retryer(call, config).result$;
  };
