import { AfterViewInit, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomizedFooterControlsInterface } from '../interfaces';

@Component({
  selector: 'app-customize-primary-action',
  templateUrl: './customize-primary-action.component.html',
  styleUrls: ['./customize-primary-action.component.scss'],
})
export class CustomizePrimaryActionComponent implements AfterViewInit {
  @Input() controls: FormGroup<CustomizedFooterControlsInterface>;

  label?: string;
  targetUrl?: string;
  targetProductId?: string;
  validationMessage?: string;

  ngAfterViewInit(): void {
    this.label = this.controls.controls.customizedFooterCtaLabel.value;
    this.targetUrl = this.controls.controls.customizedFooterCtaTargetUrl.value;
    this.targetProductId = this.controls.controls.customizedFooterCtaTargetProduct.value;
  }

  protected async handleSaveClick(): Promise<void> {
    let targetUrl = this.targetUrl ?? '';
    if (targetUrl !== '' && !targetUrl.startsWith('https://') && !targetUrl.startsWith('http://')) {
      targetUrl = 'https://' + targetUrl;
    }
    this.controls.setValue({
      customizedFooterCtaLabel: this.label ?? '',
      customizedFooterCtaTargetUrl: targetUrl,
      customizedFooterCtaTargetProduct: this.targetProductId ?? '',
    });
    this.controls.markAsDirty();
  }
}
