{
  "MENU_TOGGLE": "Menu",
  "DROPDOWN_MENU": {
    "SIGNED_IN_AS": "Signed in as",
    "IMPERSONATING_USER_AS": "as",
    "EMAIL_IS_VERIFIED": "Your email address is verified",
    "EMAIL_IS_UNVERIFIED": "Your email address is not verified",
    "SWITCH_USER": "Switch user"
  },
  "NOTIFICATIONS": {
    "TITLE": "Notifications",
    "LOAD_FAILURE": "Failed to load notifications.",
    "NO_NOTIFICATIONS": "You have no notifications.",
    "ACTIONS": {
      "SETTINGS": "Settings",
      "MARK_ALL_AS_READ": "Mark all as read",
      "MARK_AS_READ": "Mark as read",
      "MARK_AS_UNREAD": "Mark as unread",
      "TURN_OFF_NOTIFICATION": "Turn off notification",
      "TURN_ON_NOTIFICATION": "Turn on notification",
      "DISCARD_CHANGES": "Discard changes",
      "SAVE": "Save",
      "UNDO": "Undo",
      "DISMISS": "Dismiss",
      "CONFIGURE_SMB_SETTING": "Configure notification settings for this account."
    },
    "EMPTY_NOTIFICATION_SETTING": {
      "TITLE": "No notification settings available",
      "TEXT": "Notifications are currently turned off. To start receiving notifications, contact your account representative."
    },
    "UNSUBSCRIBE_TEXT": "You will no longer receive this notification.",
    "SUBSCRIBE_TEXT": "You will now receive this notification.",
    "SETTINGS": {
      "ALL_DISABLED": "All notifications have been disabled. Emails will not be received.",
      "NO_ACCESS": "You don't have access to any settings.",
      "LOAD_FAILURE": "Failed to load settings. Please try again.",
      "ACTIVITY_SETTINGS_DESCRIPTION": "These notifications are currently in beta, you can view and update your activity notification settings <a href=\"{{ url }}\">here</a>",
      "BETA": "BETA",
      "DESCRIPTION": {
        "SOME_ON": "Some on",
        "ALL_ON": "All on",
        "ALL_OFF": "All off",
        "ALL_EVENTS": "All events",
        "ALL_EVENTS_TOOLTIP": "Notify me for all events of this type.",
        "ONLY_MY_EVENTS": "Only my events",
        "ONLY_MY_EVENTS_TOOLTIP": "Notify me only when an event directly involves me."
      },
      "MEDIUMS": {
        "APP": "App",
        "EMAIL": "Email",
        "UNKNOWN": "Unknown"
      },
      "UNAVAILABLE": "notifications are not available",
      "SNACKBAR": {
        "UPDATED": "Your notification settings were updated.",
        "FAILURE": "An error occurred while trying to load your notification settings, please try again.",
        "SAVE_FAILURE": "An error occurred trying to save your notification settings, please retry."
      }
    }
  },
  "VBC_NOTIFICATIONS": {
    "GLOBAL_SETTINGS": "Global settings",
    "REPORTS": "Reports",
    "GLOBALLY_DISABLED": "has been globally disabled",
    "MEDIUMS": {
      "IN_APP": "In-app",
      "INSTANT_EMAIL": "Instant email",
      "DIGEST_EMAIL": "Digest email",
      "WEEKLY_EMAIL": "Weekly email",
      "MONTHLY_EMAIL": "Monthly email"
    }
  },
  "COMMON": {
    "POWERED_BY": "Powered by",
    "ERROR_TRY_AGAIN": "An error occurred. Please try again."
  },
  "USER_LANGUAGE_MODAL": {
    "PROMPT_WITH_BROWSER_LANG": "We noticed your browser language is set to <strong>{{browserLang}}</strong>, but your user profile language is set to <strong>{{userLang}}</strong>.",
    "PROMPT_WITHOUT_BROWSER_LANG": "Your user profile language is currently set to <strong>{{userLang}}</strong>.",
    "WANT_TO_SWITCH": "Would you like to confirm your language preferences? We won't ask again for this device.",
    "USE_BROWSER_LANGUAGE": "Switch to {{browserLang}}",
    "CONFIRM_USER_LANGUAGE": "Use {{userLang}}",
    "SEE_ALL_LANGUAGE_OPTIONS": "Select another language"
  },
  "LANGUAGE": {
    "en": "English",
    "fr": "French",
    "fr-ca": "French (Canada)",
    "cs": "Czech",
    "de": "German",
    "nl": "Dutch",
    "es-419": "Spanish (Latin America)",
    "pt": "Portuguese (Brazil)",
    "ru": "Russian",
    "ja": "Japanese",
    "it": "Italian",
    "af": "Afrikaans",
    "he": "Hebrew"
  },
  "SWITCH_LANGUAGE_MODAL": {
    "SWITCH_LANGUAGE": "Switch language",
    "SEARCH_LANGUAGES": "Search languages",
    "TO_SELECT": "to select",
    "TO_NAVIGATE": "to navigate",
    "TO_CLOSE": "to close",
    "HELP_US_TRANSLATE": "Is your preferred language missing from this list? Are the translations incomplete? Help us translate using the Weblate translation portal!",
    "VISIT_TRANSLATION_PORTAL": "Visit Weblate",
    "IMPERSONATION_WARNING": "You are currently impersonating a user. Changing the language will affect your browser, but not change the user's language."
  },
  "EMAIL_VERIFICATION": {
    "TITLE": "Verify your email address",
    "TO_IMPROVE_SECURITY": "To improve security, you'll need to verify your email address to continue using your account.",
    "TO_IMPROVE_SECURITY_WITH_DEADLINE": "To improve security, you'll need to verify your email address before {{ verificationDeadline }} to continue using your account.",
    "CLICK_BELOW": "Click below to send a verification email to the following email",
    "SEND_VERIFICATION_EMAIL": "Send verification email",
    "REMIND_ME_LATER": "Remind me later",
    "EMAIL_SENT": "Email sent",
    "CHECK_YOUR_INBOX": "Check your inbox for an email like this",
    "CONTACT_SUPPORT": "Need help? Contact your support team"
  },
  "USER_SWITCHER_MODAL": {
    "CANCEL": "Cancel"
  },
  "THEME_SWITCHER": {
    "SWITCH_TO_LIGHT": "Switch to light mode",
    "SWITCH_TO_DARK": "Switch to dark mode"
  }
}
