<h2 mat-dialog-title>{{ 'COMMON.CONTACT_US' | translate }}</h2>
<mat-dialog-content>
  <div
    class="sales-header"
    *ngIf="salesPersonService.salesPerson$ | async; let salesPerson; else: noSalesPersonTemplate"
  >
    <div data-testid="cd-sales-contact" class="sales-contact">
      <div class="sales-contact-bio">
        <img
          *ngIf="salesPerson?.photoUrl"
          class="sales-icon"
          [src]="salesPerson?.photoUrl"
          [ngStyle]="{ 'background-image': 'url(' + salesPerson.photoUrl + ')' }"
        />
        <img
          *ngIf="!salesPerson?.photoUrl"
          class="sales-icon"
          [ngStyle]="{
            'background-image':
              'url(https://vstatic-prod.apigateway.co/snapshot-client.9223372035128602063.prod/assets/images/default_sales_person.jpg)'
          }"
        />

        <div class="salesperson-desc">
          <div class="title">{{ salesPerson?.firstName }} {{ salesPerson?.lastName }}</div>
          <div class="subtitle">
            {{ salesPerson?.jobTitle }}
          </div>
        </div>
      </div>
    </div>

    <div class="sales-contact-info">
      <ng-container *ngIf="showScheduleMeetingButton$ | async">
        <div *ngIf="bookMeetingUrl$ | async as bookMeetingUrl">
          <button
            mat-stroked-button
            color="primary"
            data-testid="cd-schedule-meeting"
            class="contact"
            (click)="this.scheduleMeetingService.openBookingUrl(bookMeetingUrl)"
            [trackEvent]="{
              eventName: 'book-meeting-sales',
              category: 'external-link',
              action: 'click'
            }"
            tabIndex="-1"
          >
            <mat-icon>event</mat-icon>
            {{ 'BOOKED_MEETINGS.BOOK_A_MEETING' | translate }}
          </button>
        </div>
      </ng-container>
      <div *ngIf="salesPerson?.phoneNumbers?.length > 0">
        <a [href]="'tel:' + (phoneNumber$ | async)" data-testid="cd-phone-salesperson">
          <button
            mat-stroked-button
            color="primary"
            class="contact"
            [trackEvent]="{
              eventName: 'call-sales-dialog',
              category: 'external-link',
              action: 'click'
            }"
            tabIndex="-1"
          >
            <mat-icon>local_phone</mat-icon>
            {{ 'COMMON.CALL' | translate }}
          </button>
        </a>
      </div>
      <div>
        <button
          mat-stroked-button
          color="primary"
          class="contact"
          data-testid="cd-show-send-message"
          (click)="openSendMessage()"
          [trackEvent]="{
            eventName: 'send-message-sales',
            category: 'cta',
            action: 'click'
          }"
          tabIndex="-1"
        >
          <mat-icon>chat_bubble</mat-icon>
          {{ 'CONTACT_US.SEND_MESSAGE' | translate }}
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="showMessageForm || (salesPersonService.salesPerson$ | async) === undefined">
    <mat-divider></mat-divider>

    <form class="form-container" [formGroup]="contactForm">
      <glxy-input
        data-testid="cd-input-first-name"
        label="{{ 'COMMON.FIRST_NAME' | translate }}"
        [formControl]="firstName"
        [required]="false"
      ></glxy-input>
      <glxy-input
        data-testid="cd-input-last-name"
        label="{{ 'COMMON.LAST_NAME' | translate }}"
        [formControl]="lastName"
        [required]="false"
      ></glxy-input>
      <glxy-email-input
        data-testid="cd-input-email"
        label="{{ 'COMMON.EMAIL_ADDRESS' | translate }}"
        [formControl]="email"
        [required]="true"
      ></glxy-email-input>
      <glxy-input
        data-testid="cd-input-message"
        label="{{ 'CONTACT_US.MESSAGE_PLACEHOLDER' | translate }}"
        [formControl]="message"
        hint="{{ contactForm.controls.message?.value?.length ?? 0 }} / 500"
        [required]="true"
        [validators]="maxLengthValidators"
      ></glxy-input>
    </form>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="button" data-testid="cd-cancel-button" mat-stroked-button (click)="close()">
    {{ 'COMMON.CANCEL' | translate }}
  </button>
  <button
    type="button"
    *ngIf="showMessageForm || (salesPersonService.salesPerson$ | async) === undefined"
    data-testid="cd-submit-button"
    mat-flat-button
    color="primary"
    (click)="submit()"
    [disabled]="!contactForm.valid"
  >
    {{ 'COMMON.SEND_MESSAGE' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #noSalesPersonTemplate>
  <div class="sales-container">
    <div
      class="sales-icon"
      [ngStyle]="{
        'background-image':
          'url(https://vstatic-prod.apigateway.co/snapshot-client.9223372035128602063.prod/assets/images/default_sales_person.jpg)'
      }"
    ></div>
    <span class="whitelabel-name">
      {{ whitelabel.partnerName$ | async }}
    </span>
  </div>
</ng-template>
