import {
  Grade,
  KeywordInterface,
  KeywordMetricsInterface,
  LatLngInterface,
  LocalSEODataInterface,
  OrganicDomainDataInterface,
  OrganicKeywordDataInterface,
  ResultsInterface,
  SEODataInterface,
  SEODomainMetricsInterface,
  SERPMetricsInterface,
  Vicinity,
  Status,
  GMBClaimStatus,
} from '@vendasta/snapshot';
import { ResultsReviewsInterface } from '@vendasta/snapshot';

export class SEODomainMetrics implements SEODomainMetricsInterface {
  domainName: string;
  numberOfOrganicKeywords: number;
  organicClicksPerMonth: number;
  dailyOrganicTrafficValue: number;
  organicDomainRanking: number;
  overlap: number;
  valuePerClick: number;

  constructor(data: SEODomainMetricsInterface) {
    this.domainName = data.domainName || '';
    this.numberOfOrganicKeywords = data.numberOfOrganicKeywords || 0;
    this.organicClicksPerMonth = data.organicClicksPerMonth || 0;
    this.dailyOrganicTrafficValue = data.dailyOrganicTrafficValue || 0;
    this.organicDomainRanking = data.organicDomainRanking || 0;
    this.overlap = data.overlap || 0;
    this.valuePerClick = data.valuePerClick || 0;
  }
}

export class OrganicDomainData implements OrganicDomainDataInterface {
  business: SEODomainMetrics;
  competitors: SEODomainMetrics[];
  grade: Grade;

  constructor(data: OrganicDomainDataInterface) {
    this.business = new SEODomainMetrics(data.business || {});
    this.competitors = data.competitors ? data.competitors.map((i) => new SEODomainMetrics(i)) : [];
    this.grade = data.grade || Grade.NO_GRADE;
  }
}

export class Keyword implements KeywordInterface {
  term: string;
  position: number;
  difficulty: number;
  monthlyClicks: number;
  monthlySearchVolume: number;
  monthlyLocalSearchVolume: number;

  constructor(data: KeywordInterface) {
    this.term = data.term || '';
    this.position = data.position || 0;
    this.difficulty = data.difficulty || 0;
    this.monthlyClicks = data.monthlyClicks || 0;
    this.monthlySearchVolume = data.monthlySearchVolume || 0;
    this.monthlyLocalSearchVolume = data.monthlyLocalSearchVolume || 0;
  }
}

export class KeywordMetrics implements KeywordMetricsInterface {
  domainName: string;
  keywords: Keyword[];

  constructor(data: KeywordMetricsInterface) {
    this.domainName = data.domainName || '';
    this.keywords = data.keywords ? data.keywords.map((d: KeywordInterface) => new Keyword(d)) : [];
  }

  get uniqueKeywords(): Keyword[] {
    const unique: { [term: string]: Keyword } = {};
    const keywords = this.keywords.slice();
    keywords.reverse().forEach((k) => {
      unique[k.term.toLowerCase()] = k;
    });
    const uniqueList = Object.keys(unique).map((key) => unique[key]);
    return uniqueList.sort((k1, k2) => (k1.position > k2.position ? 1 : -1));
  }
}

export class OrganicKeywordData implements OrganicKeywordDataInterface {
  business: KeywordMetrics;

  constructor(data: OrganicKeywordDataInterface) {
    this.business = new KeywordMetrics(data.business || {});
  }
}

export class Results implements ResultsInterface {
  businessName: string;
  address: string;
  url: string;
  rank: number;
  isMainBusiness: boolean;
  phoneNumber: string;
  claimStatus: GMBClaimStatus;
  reviews: Reviews;

  constructor(results: ResultsInterface) {
    this.businessName = results.businessName || '';
    this.address = results.address || '';
    this.url = results.url || '';
    this.rank = results.rank || null;
    this.isMainBusiness = results.isMainBusiness || false;
    this.phoneNumber = results.phoneNumber || '';
    this.claimStatus = results.claimStatus || GMBClaimStatus.GMB_CLAIM_STATUS_UNKNOWN;
    this.reviews = new Reviews(results.reviews);
  }
}

export class LatLng implements LatLngInterface {
  latitude: number;
  longitude: number;

  constructor(latLng: LatLngInterface) {
    this.latitude = latLng.latitude || 0;
    this.longitude = latLng.longitude || 0;
  }
}

export class SERPMetrics implements SERPMetricsInterface {
  searchTerm: string;
  vicinity: Vicinity;
  searchLocation: LatLng;
  results: Results[];

  constructor(serpMetrics: SERPMetricsInterface) {
    this.searchTerm = serpMetrics.searchTerm || '';
    this.vicinity = serpMetrics.vicinity || Vicinity.VICINITY_UNDEFINED;
    this.searchLocation = new LatLng(serpMetrics.searchLocation);
    this.results = serpMetrics.results ? serpMetrics.results.map((result) => new Results(result)) : [];
  }
}

export class Reviews implements ResultsReviewsInterface {
  rating?: number;
  count?: number;

  constructor(reviews: ResultsReviewsInterface) {
    this.rating = reviews.rating || 0;
    this.count = reviews.count || 0;
  }
}

export class LocalSEOData implements LocalSEODataInterface {
  searches?: SERPMetrics[];
  grade: Grade;
  status: Status;
  searchKeyword: string;

  constructor(localSeoData: LocalSEODataInterface) {
    this.searches = localSeoData.searches?.map((search) => new SERPMetrics(search)) ?? [];
    this.grade = localSeoData.grade || Grade.NO_GRADE;
    this.status = localSeoData.status || Status.STATUS_DOES_NOT_EXIST;
    this.searchKeyword = localSeoData.searchKeyword || '';
  }
}

export class SEOData implements SEODataInterface {
  organicDomainData: OrganicDomainData;
  organicKeywordData: OrganicKeywordData;
  localSEOData: LocalSEOData;

  constructor(data: SEODataInterface) {
    this.organicDomainData = new OrganicDomainData(data.organicDomainData || {});
    this.organicKeywordData = new OrganicKeywordData(data.organicKeywordData || {});
    this.localSEOData = new LocalSEOData(data.localSeoData || {});
  }
}
