import { Grade } from '@vendasta/snapshot';

export interface SocialItemInterface {
  grade: Grade;
  business?: number;
  industryAverage?: number;
  industryLeader?: number;
}

export class SocialItem {
  grade: Grade;
  business: number;
  industryAverage: number;
  industryLeader: number;

  constructor(item: SocialItemInterface) {
    this.grade = item.grade;
    this.business = item.business || 0;
    this.industryAverage = item.industryAverage || 0;
    this.industryLeader = item.industryLeader || 0;
  }
}
