<snap-subsection
  *ngIf="showRetargeting"
  heading="{{ 'ADVERTISING.RETARGETING.HEADING' | translate }}"
  subheading="{{ 'ADVERTISING.RETARGETING.SUBHEADING' | translate }}"
  [edit]="edit"
  [hideControl]="controls.controls['hideSemRetargeting']"
>
  <ng-container *ngIf="isRetargeting$ | async as isRetargeting; else noRetargeting">
    <div class="found">
      <glxy-empty-state [size]="'small'" [widthConstraint]="false">
        <glxy-empty-state-hero>
          <mat-icon>sentiment_very_satisfied</mat-icon>
        </glxy-empty-state-hero>
        <glxy-empty-state-title>
          {{ 'ADVERTISING.RETARGETING.SUCCESS_TITLE' | translate }}
        </glxy-empty-state-title>
        <p>{{ 'ADVERTISING.RETARGETING.SUCCESS_MESSAGE' | translate }}</p>
        <p>{{ 'ADVERTISING.RETARGETING.SUCCESS_ADVICE' | translate }}</p>
      </glxy-empty-state>
    </div>
  </ng-container>
</snap-subsection>

<ng-template #noRetargeting>
  <div class="not-found">
    <glxy-empty-state [size]="'small'" [widthConstraint]="false">
      <glxy-empty-state-hero>
        <mat-icon>person_search</mat-icon>
      </glxy-empty-state-hero>
      <glxy-empty-state-title>
        {{ 'ADVERTISING.RETARGETING.FAIL_TITLE' | translate }}
      </glxy-empty-state-title>
      <p>{{ 'ADVERTISING.RETARGETING.FAIL_MESSAGE' | translate }}</p>
    </glxy-empty-state>
  </div>
</ng-template>
