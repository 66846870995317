<ng-container *ngIf="(listingPresenceCompetitors$ | async)?.length > 0; else noAccuracyCompetitors">
  <snap-subsection
    heading="{{ 'LISTINGS.ACCURACY.HEADING' | translate }}"
    [hideControl]="controls.controls['hideListingAccuracy']"
    [edit]="edit"
  >
    <div class="listing-accuracy">
      <div
        *ngIf="listingAccuracyAccurateListings$ | async as al"
        class="business flex align-space-between-center default-spacing"
      >
        <div class="chart">
          <snap-listing-chart
            [chartTitles]="listingAccuracyChartTitles"
            [businessName]="(snapshotService.snapshotData$ | async).companyName"
            [businessCount]="formatNumber(al)"
            [competitors]="listingAccuracyCompetitors$ | async"
            [referenceData]="listingAccuracyReferenceData$ | async"
            [maxCount]="100"
            [units]="'%'"
          ></snap-listing-chart>
        </div>
      </div>
    </div>
    <snap-accuracy-table class="hide-mobile" [items]="accuracyTableItems$ | async" [edit]="edit"></snap-accuracy-table>
  </snap-subsection>
</ng-container>
<ng-template #noAccuracyCompetitors>
  <snap-subsection
    heading="{{ 'LISTINGS.ACCURACY.HEADING' | translate }}"
    subheading="{{ 'LISTINGS.ACCURACY.SUBHEADING' | translate }}"
    [hideControl]="controls.controls['hideListingAccuracy']"
    [edit]="edit"
  >
    <div class="listing-accuracy">
      <div class="business flex">
        <div class="label">
          {{ 'LISTINGS.ACCURACY.ACCURATE' | translate }}
        </div>
        <div class="chart flex align-space-between-center default-spacing">
          <div
            class="fill"
            [ngStyle]="{
              width: computeAccuracyFillPercentage(listingAccuracyAccurateListings$ | async)
            }"
          >
            &nbsp;
          </div>
          <div>{{ formatNumber(listingAccuracyAccurateListings$ | async) }}%</div>
        </div>
      </div>
      <div class="industry flex">
        <div class="label">
          {{ 'LISTINGS.ACCURACY.INDUSTRY_ACCURATE' | translate }}
        </div>
        <div class="chart flex align-space-between-center default-spacing">
          <div
            class="fill"
            [ngStyle]="{
              width: computeAccuracyFillPercentage(listingAccuracyIndustryAverageAccurateListings$ | async)
            }"
          >
            &nbsp;
          </div>
          <div>{{ listingAccuracyIndustryAverageAccurateListings$ | async }}%</div>
        </div>
      </div>
    </div>

    <snap-accuracy-table class="hide-mobile" [items]="accuracyTableItems$ | async" [edit]="edit"></snap-accuracy-table>
  </snap-subsection>
</ng-template>
