<div class="local-seo-container">
  <glxy-empty-state
    data-testid="ls-inprogress-state"
    *ngIf="localSEOData?.status === Status.STATUS_IN_PROGRESS"
    [size]="'small'"
    [widthConstraint]="false"
    class="empty-state-container"
  >
    <glxy-empty-state-hero>
      <mat-icon>pending</mat-icon>
    </glxy-empty-state-hero>
    <glxy-empty-state-title>
      {{ 'LOCAL_SEO.LOCAL_SEO.IN_PROGRESS_TITLE' | translate }}
    </glxy-empty-state-title>
    <p>{{ 'LOCAL_SEO.LOCAL_SEO.IN_PROGRESS_DESCRIPTION' | translate }}</p>
  </glxy-empty-state>

  <glxy-empty-state
    data-testid="ls-doesnotexist-state"
    *ngIf="localSEOData?.status === Status.STATUS_DOES_NOT_EXIST"
    [size]="'small'"
    [widthConstraint]="false"
    class="empty-state-container"
  >
    <glxy-empty-state-hero>
      <mat-icon>search_off</mat-icon>
    </glxy-empty-state-hero>
    <glxy-empty-state-title>
      {{ 'LOCAL_SEO.LOCAL_SEO.DOES_NOT_EXIST_TITLE' | translate }}
    </glxy-empty-state-title>
    <p>{{ 'LOCAL_SEO.LOCAL_SEO.DOES_NOT_EXIST_DESCRIPTION' | translate }}</p>
    <glxy-empty-state-actions *ngIf="edit">
      <button mat-flat-button color="primary" (click)="handleDoesNotExistAction()">
        {{ 'LOCAL_SEO.LOCAL_SEO.DOES_NOT_EXIST_ACTION' | translate }}
      </button>
    </glxy-empty-state-actions>
  </glxy-empty-state>

  <ng-container *ngIf="localSEOData?.status === Status.STATUS_FINISHED">
    <ng-container *ngIf="media.print$ | async; else tabResults" [ngTemplateOutlet]="printResults"></ng-container>
  </ng-container>
</div>

<ng-template #tabResults>
  <mat-tab-group>
    <mat-tab *ngIf="(snapshotService.snapshotData$ | async).serviceAreaBusiness === false && nearMeSearch?.length > 0">
      <ng-template #currentNearMeSearch let-current="current">
        <ng-template mat-tab-label>
          <div data-testid="ls-nearme-tab-label">
            {{ 'LOCAL_SEO.LOCAL_SEO.TABS.NEAR_ME' | translate }}
            <span
              data-testid="ls-nearme-rank"
              class="rank-chip"
              [ngClass]="{
                'top-performer': current?.mainBusinessPerformance === SEOPerformance.TOP,
                'average-performer': current?.mainBusinessPerformance === SEOPerformance.AVERAGE
              }"
            >
              {{ current?.mainBusiness?.rank || '-' }}
            </span>
          </div>
        </ng-template>
      </ng-template>

      <ng-container
        *ngTemplateOutlet="currentNearMeSearch; context: { current: currentNearMeSearch$ | async }"
      ></ng-container>

      <ng-container
        *ngTemplateOutlet="
          businessesRanking;
          context: {
            search: currentNearMeSearch$ | async,
            markers: nearMeMarkers
          }
        "
      ></ng-container>
    </mat-tab>

    <mat-tab>
      <ng-template #currentCitySearch let-current="current">
        <ng-template mat-tab-label>
          <div data-testid="ls-city-tab-label">
            {{ businessData?.city || 'BUSINESS_DETAILS.CITY' | translate }}
            <span
              data-testid="ls-city-rank"
              class="rank-chip"
              [ngClass]="{
                'top-performer': current?.mainBusinessPerformance === SEOPerformance.TOP,
                'average-performer': current?.mainBusinessPerformance === SEOPerformance.AVERAGE
              }"
            >
              {{ current?.mainBusiness?.rank || '-' }}
            </span>
          </div>
        </ng-template>
      </ng-template>

      <ng-container
        *ngTemplateOutlet="currentCitySearch; context: { current: currentCitySearch$ | async }"
      ></ng-container>

      <ng-container
        *ngTemplateOutlet="businessesRanking; context: { search: currentCitySearch$ | async, markers: cityMarkers }"
      ></ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-template>

<ng-template #printResults>
  <ng-container
    *ngTemplateOutlet="
      businessesRanking;
      context: {
        search: printSearch,
        markers: printMarkers
      }
    "
  ></ng-container>
</ng-template>

<ng-template #businessesRanking let-search="search" let-markers="markers">
  <div class="row row-gutters tab-container">
    <div class="col col-md-6 col-xs-12 hide-to-print">
      <snap-local-seo-map
        class="local-seo-map"
        [search]="search"
        [markers]="markers"
        (markerClick)="setNearMe($event?.valueOf())"
      ></snap-local-seo-map>
      <div
        *ngIf="search?.data?.vicinity !== Vicinity.VICINITY_CITY"
        class="map-info-text"
        data-testid="ls-map-info-text"
      >
        <mat-icon>ads_click</mat-icon>
        {{ 'LOCAL_SEO.LOCAL_SEO.MAP_INFO_TEXT' | translate }}
      </div>
    </div>
    <div class="col col-md-6 col-xs-12 ranking-container">
      <div
        class="description"
        [innerHTML]="
          'LOCAL_SEO.LOCAL_SEO.DESCRIPTION'
            | translate
              : {
                  searchTerm: search?.searchTerm,
                  vicinity: search?.data?.vicinity === Vicinity.VICINITY_CITY ? businessData?.city : 'near me'
                }
        "
      ></div>
      <ng-container *ngIf="search?.data?.results?.length > 0; else noSearchResults">
        <div
          data-testid="ls-business-item"
          *ngFor="let result of search?.data?.results; let index = index; let last = last"
        >
          <div class="row business-item">
            <div class="col">
              <div class="row business table-row">
                <span class="col col-xs-1" data-testid="ls-business-rank">
                  {{ result.rank || '-' }}
                </span>
                <span class="col col-xs-9">
                  <a
                    href="{{ result.url }}"
                    target="_blank"
                    data-testid="ls-business-url"
                    [class.disabled-link]="result.url !== null && result.url !== '' ? null : true"
                  >
                    {{ result.businessName }}
                  </a>
                </span>
                <span
                  data-testid="ls-main-business"
                  *ngIf="result.isMainBusiness"
                  class="business-chip col col-xs-2"
                  [ngClass]="{
                    'top-performer': search?.mainBusinessPerformance === SEOPerformance.TOP,
                    'average-performer': search?.mainBusinessPerformance === SEOPerformance.AVERAGE
                  }"
                >
                  {{ 'COMMON.YOU' | translate }}
                </span>
              </div>

              <div class="row table-row">
                <div class="col col-xs-1"></div>
                <span class="col col-xs-9">
                  <span
                    class="business-review-rating"
                    data-testid="ls-business-review-rating"
                    *ngIf="result.reviews?.rating"
                  >
                    {{ result.reviews?.rating }}
                    <mat-icon class="rating">star</mat-icon>
                  </span>
                  <span
                    class="business-review-count"
                    data-testid="ls-business-review-count"
                    *ngIf="result.reviews?.count > 0"
                  >
                    <span class="divider">•</span>
                    {{ 'LOCAL_SEO.LOCAL_SEO.REVIEWS_COUNT' | translate: { count: result.reviews?.count } }}
                  </span>
                  <span *ngTemplateOutlet="claimStatus; context: { claimStatus: result.claimStatus }"></span>
                </span>
              </div>

              <div class="row table-row">
                <div class="col col-xs-1"></div>
                <span class="col col-xs-9">
                  <span class="business-address" data-testid="ls-business-address">
                    {{ result.address }}
                  </span>
                </span>
              </div>

              <div class="row table-row">
                <div class="col col-xs-1"></div>
                <span class="col col-xs-9">
                  <span
                    class="business-phone"
                    data-testid="ls-business-phone"
                    *ngIf="result.phoneNumber; else phoneNotFound"
                  >
                    {{ result.phoneNumber | phone: businessData?.country || 'US' }}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <mat-divider *ngIf="!last" [ngClass]="{ 'last-business': index > 1 }"></mat-divider>
        </div>
        <ng-container *ngIf="search?.mainBusiness === null">
          <mat-divider class="last-business"></mat-divider>
          <div class="row">
            <div class="col col-xs-1"></div>
            <span
              data-testid="ls-business-notfound"
              class="business-not-found"
              [innerHTML]="
                'LOCAL_SEO.LOCAL_SEO.BUSINESS_NOT_FOUND' | translate: { businessName: businessData?.companyName }
              "
            ></span>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <ng-template #noSearchResults>
    <div class="no-results-container">
      <glxy-empty-state [size]="'small'" [widthConstraint]="false" class="empty-state-container">
        <glxy-empty-state-hero>
          <mat-icon>search_off</mat-icon>
        </glxy-empty-state-hero>
        <glxy-empty-state-title>
          {{ 'LOCAL_SEO.LOCAL_SEO.NO_RESULTS_TITLE' | translate }}
        </glxy-empty-state-title>
        <p
          [innerHTML]="
            'LOCAL_SEO.LOCAL_SEO.NO_RESULTS_IN_SELECTED_AREA'
              | translate
                : {
                    searchTerm: search?.searchTerm,
                    vicinity: search?.data?.vicinity === Vicinity.VICINITY_CITY ? businessData?.city : 'near me'
                  }
          "
        ></p>
      </glxy-empty-state>
    </div>
  </ng-template>

  <ng-template #phoneNotFound>
    <span class="business-phone negative">
      {{ 'LOCAL_SEO.LOCAL_SEO.PHONE_NOT_FOUND' | translate }}
    </span>
  </ng-template>

  <ng-template #claimStatus let-claimStatus="claimStatus">
    <ng-container [ngSwitch]="claimStatus">
      <ng-container *ngSwitchCase="GMBClaimStatus.GMB_CLAIM_STATUS_CLAIMED">
        <span class="claim-status" data-testid="ls-claim-status">
          <span class="divider">•</span>
          <mat-icon class="claim-icon positive">verified_user</mat-icon>
          {{ 'LOCAL_SEO.LOCAL_SEO.CLAIMED' | translate }}
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="GMBClaimStatus.GMB_CLAIM_STATUS_UNCLAIMED">
        <span class="claim-status negative" data-testid="ls-claim-status">
          <span class="divider">•</span>
          <mat-icon class="claim-icon material-icons-outlined">not_listed_location</mat-icon>
          {{ 'LOCAL_SEO.LOCAL_SEO.UNCLAIMED' | translate }}
        </span>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <!--don't show anything-->
      </ng-container>
    </ng-container>
  </ng-template>
</ng-template>
