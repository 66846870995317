import { Grade, SSLSummaryStatus } from '@vendasta/snapshot';
import { WebsiteCompetitor } from './website-competitor';
import { WebsiteDataCompetitorInterface } from '@vendasta/snapshot';

interface RuleDetailInterface {
  titleHtml: string;
  detailItems: string[];
}

interface RuleInterface {
  name: string;
  summaryHtml: string;
  impact: number;
  details: RuleDetailInterface[];
}

interface AuditDetailInterface {
  title: string;
  detailItems: string[];
}

interface AuditInterface {
  title: string;
  description: string;
  score: number;
  details: AuditDetailInterface[];
  id?: string;
}

interface HomepageDataInterface {
  grade?: Grade;
  size?: number;
  sizeIndustryAverage?: number;
  facebookPresent?: boolean;
  facebookPresentIndustryAverage?: number;
  twitterPresent?: boolean;
  twitterPresentIndustryAverage?: number;
  addressPresent?: boolean;
  addressPresentIndustryAverage?: number;
  phonePresent?: boolean;
  phonePresentIndustryAverage?: number;
  videoPresent?: boolean;
  videoPresentIndustryAverage?: number;
  instagramPresent?: boolean;
  instagramPresentIndustryAverage?: number;
}

interface RuleSummaryInterface {
  grade?: Grade;
  rules?: RuleInterface[];
  locale?: string;
}

interface AuditSummaryInterface {
  grade?: Grade;
  audits?: AuditInterface[];
  locale?: string;
  score?: number;
}

interface SSLSummaryInterface {
  status?: SSLSummaryStatus;
}

interface WebsiteDataInterface {
  mobileSpeed?: RuleSummaryInterface;
  desktopSpeed?: RuleSummaryInterface;
  mobileInsights?: AuditSummaryInterface;
  desktopInsights?: AuditSummaryInterface;
  userExperience: RuleSummaryInterface;
  homepageData: HomepageDataInterface;
  mobileScreenshot: string;
  desktopScreenshot: string;
  mobileGrade: Grade;
  mobileFriendly: boolean;
  competitors?: WebsiteDataCompetitorInterface[];
  ssl?: SSLSummary;
}

class RuleDetail {
  titleHtml: string;
  detailItems: string[];

  constructor(data: RuleDetailInterface) {
    this.titleHtml = data.titleHtml;
    this.detailItems = data.detailItems;
  }
}

export class Rule {
  name: string;
  summaryHtml: string;
  impact: number;
  details: RuleDetail[];

  constructor(data: RuleInterface) {
    this.name = data.name;
    this.summaryHtml = data.summaryHtml || '';
    this.impact = data.impact || 0;
    this.details = data.details ? data.details.map((i) => new RuleDetail(i)) : [];
  }
}

class AuditDetail {
  title: string;
  detailItems: string[];

  constructor(data: AuditDetailInterface) {
    this.title = data.title;
    this.detailItems = data.detailItems;
  }
}

export class Audit {
  title: string;
  description: string;
  score: number;
  details: AuditDetail[];
  id: string;

  constructor(data: AuditInterface) {
    this.title = data.title || '';
    this.description = data.description || '';
    this.score = data.score || 0;
    this.details = data.details ? data.details.map((i) => new AuditDetail(i)) : [];
    this.id = data.id || '';
  }
}

export class HomepageData {
  grade: Grade;
  size: number;
  sizeIndustryAverage: number;
  facebookPresent: boolean;
  facebookPresentIndustryAverage: number;
  twitterPresent: boolean;
  twitterPresentIndustryAverage: number;
  addressPresent: boolean;
  addressPresentIndustryAverage: number;
  phonePresent: boolean;
  phonePresentIndustryAverage: number;
  videoPresent: boolean;
  videoPresentIndustryAverage: number;
  instagramPresent: boolean;
  instagramPresentIndustryAverage: number;

  constructor(data: HomepageDataInterface) {
    this.grade = data.grade || Grade.NO_GRADE;
    this.size = data.size || 0;
    this.sizeIndustryAverage = data.sizeIndustryAverage || 0;
    this.facebookPresent = !!data.facebookPresent;
    this.facebookPresentIndustryAverage = data.facebookPresentIndustryAverage || 0;
    this.twitterPresent = !!data.twitterPresent;
    this.twitterPresentIndustryAverage = data.twitterPresentIndustryAverage || 0;
    this.addressPresent = !!data.addressPresent;
    this.addressPresentIndustryAverage = data.addressPresentIndustryAverage || 0;
    this.phonePresent = !!data.phonePresent;
    this.phonePresentIndustryAverage = data.phonePresentIndustryAverage || 0;
    this.videoPresent = !!data.videoPresent;
    this.videoPresentIndustryAverage = data.videoPresentIndustryAverage || 0;
    this.instagramPresent = !!data.instagramPresent;
    this.instagramPresentIndustryAverage = data.instagramPresentIndustryAverage || 0;
  }
}

export class RuleSummary {
  grade: Grade;
  rules: Rule[];
  locale: string;

  constructor(data: RuleSummaryInterface) {
    this.grade = data.grade || Grade.NO_GRADE;
    this.rules = data && data.rules ? data.rules.map((i) => new Rule(i)) : [];
    this.locale = data.locale || '';
  }
}

export class AuditSummary {
  grade: Grade;
  audits: Audit[];
  locale: string;
  score: number;

  constructor(data: AuditSummaryInterface) {
    this.grade = data.grade || Grade.NO_GRADE;
    this.audits = data && data.audits ? data.audits.map((i) => new Audit(i)) : [];
    this.locale = data.locale || '';
    this.score = data.score || 0;
  }
}

export class SSLSummary {
  status: SSLSummaryStatus;

  constructor(data: SSLSummaryInterface) {
    this.status = data?.status || SSLSummaryStatus.SSL_SUMMARY_STATUS_UNDETERMINED;
  }
}

export class WebsiteData {
  mobileSpeed: RuleSummary;
  desktopSpeed: RuleSummary;
  mobileInsights: AuditSummary;
  desktopInsights: AuditSummary;
  userExperience: RuleSummary;
  homepageData: HomepageData;
  mobileScreenshot: string;
  desktopScreenshot: string;
  mobileGrade: Grade;
  mobileFriendly: boolean;
  competitors: WebsiteCompetitor[];
  ssl: SSLSummary;

  constructor(data: WebsiteDataInterface) {
    this.mobileSpeed = new RuleSummary(data.mobileSpeed || {});
    this.desktopSpeed = new RuleSummary(data.desktopSpeed || {});
    this.mobileInsights = new AuditSummary(data.mobileInsights || {});
    this.desktopInsights = new AuditSummary(data.desktopInsights || {});
    this.userExperience = new RuleSummary(data.userExperience || {});
    this.homepageData = new HomepageData(data.homepageData || {});
    this.mobileScreenshot = data.mobileScreenshot;
    this.desktopScreenshot = data.desktopScreenshot;
    this.mobileGrade = data.mobileGrade || Grade.NO_GRADE;
    this.mobileFriendly = data.mobileFriendly;
    this.competitors = (data.competitors || []).map((c) => {
      return new WebsiteCompetitor(c);
    });
    this.ssl = new SSLSummary(data.ssl || {});
  }
}
