import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CompetitorInterface } from '@vendasta/account-group';
import { GooglePlacesService as GoogleMapsService, GooglePlace } from '@vendasta/businesses/easy-account-create';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { ConfirmationData, ConfirmationService } from '@vendasta/uikit';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, map, pairwise, take, takeUntil } from 'rxjs/operators';
import { Media, MediaToken } from '../../providers/providers';
import { UserEvent } from '../../snapshot-report/snapshot-report';
import { SnapshotService } from '../../snapshot.service';
import { CompetitorForm, CompetitorType } from './account-group-competitors';
import { AccountGroupCompetitorsService } from './account-group-competitors.service';

const MAX_COMPETITORS = 3;

@Component({
  selector: 'snap-account-group-competitors',
  templateUrl: './account-group-competitors.component.html',
  styleUrls: ['./account-group-competitors.component.scss'],
})
export class AccountGroupCompetitorsComponent implements OnInit, OnDestroy {
  @ViewChildren('search') searchElementRef: QueryList<ElementRef>;

  @Input() expanded: boolean;
  @Input() isExpired: boolean;
  @Input() businessId: string;

  @Output() userEvent: EventEmitter<UserEvent> = new EventEmitter<UserEvent>();

  googleMap: google.maps.Map;
  businesses$: Observable<GooglePlace[]>;
  originalCompetitors: any[] = [];
  maxCompetitors = MAX_COMPETITORS;
  cursor = '';
  indexLoading = -1;
  snackbarDuration = 3000;
  readonly urlPattern = 'https?://.+';

  private _destroyed$$: Subject<void> = new Subject<void>();
  private _googleDestroyed$$: Subject<void> = new Subject<void>();

  competitorsArray: UntypedFormArray = new UntypedFormArray([]);

  isUpdatingCompetitors = false;

  constructor(
    public snapshotService: SnapshotService,
    public translate: TranslateService,
    public snackbarService: SnackbarService,
    private googleMapsService: GoogleMapsService,
    private competitorsService: AccountGroupCompetitorsService,
    private confirmationService: ConfirmationService,
    @Inject(MediaToken) public readonly media: Media,
  ) {}

  static competitorChanged(prev: CompetitorType, next: CompetitorType): boolean {
    return !Object.keys(next).reduce(
      (accumulator: boolean, key: string) => accumulator && (key === 'competitorId' || next[key] === prev[key]),
      true,
    );
  }

  ngOnInit(): void {
    if (this.isExpired) {
      this.competitorsArray.disable();
    }

    this.competitorsService
      .getCompetitors(this.businessId, this.cursor, this.maxCompetitors)
      .pipe(takeUntil(this._destroyed$$))
      .subscribe((competitors: CompetitorInterface[]) => this.initForm(competitors));

    this.businesses$ = this.googleMapsService.googlePlaces$;
  }

  initForm(competitors: CompetitorInterface[]): void {
    this.competitorsArray.clear();
    if (competitors?.length > 0) {
      competitors.forEach((c: CompetitorInterface) => {
        const newCompetitor = {
          name: c.location?.name || '',
          url: c.location?.url || '',
          placeId: c.location?.placeId || '',
          competitorId: c.competitorId,
        } as CompetitorType;
        this.addCompetitor(true, newCompetitor);
      });
    } else {
      this.addCompetitor(false);
    }
  }

  onMapReady(googleMap: google.maps.Map): void {
    this.googleMap = googleMap;
  }

  onFocusInputSearch(index: number): void {
    const searchElements = this.searchElementRef.toArray();
    this.googleMapsService.init(this.googleMap, searchElements[index].nativeElement, 'simple');
    this.setWebsiteAndPlaceId(index);
  }

  setWebsiteAndPlaceId(index: number): void {
    this._googleDestroyed$$.next(); // unsubscribe from previous subscription before creating new subscription

    this.businesses$
      .pipe(
        map((businesses: GooglePlace[]) => businesses[0]),
        filter((b: GooglePlace) => !!b.name),
        takeUntil(this._googleDestroyed$$),
      )
      .subscribe((b: GooglePlace) => {
        // set website to empty if the GooglePlace does not have a website
        const website = b.website ? b.website : '';

        this.competitorsArray.at(index).patchValue({
          name: b.name,
          url: website,
          placeId: b.placeId,
        });
      });
  }

  addCompetitor(disableInputs: boolean, competitor?: CompetitorType): void {
    if (!competitor) {
      competitor = { name: '', url: '', placeId: '', competitorId: '' };
    }

    const c = new UntypedFormGroup({
      name: new UntypedFormControl({ value: competitor.name, disabled: disableInputs }, Validators.required),
      url: new UntypedFormControl({ value: competitor.url, disabled: disableInputs }),
      placeId: new UntypedFormControl({ value: competitor.placeId, disabled: disableInputs }),
      competitorId: new UntypedFormControl({ value: competitor.competitorId, disabled: disableInputs }),
    });

    c.valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
      const changed = AccountGroupCompetitorsComponent.competitorChanged(prev, next);
      if (prev.placeId && changed) {
        next.placeId = '';
        c.setValue(next);
      }
    });

    this.competitorsArray.push(c);
  }

  editCompetitor(index: number, competitor: CompetitorForm): void {
    // temporarily save competitor to restore later when user clicks cancel
    this.originalCompetitors.push({ key: index, value: competitor.value });
    this.competitorsArray.controls[index].enable();
  }

  cancel(index: number): void {
    const originalCompetitor = this.originalCompetitors.find((c) => c.key === index);
    if (originalCompetitor) {
      this.competitorsArray.controls[index].patchValue(originalCompetitor.value);
      this.competitorsArray.controls[index].disable();
    } else if (index === 0) {
      this.competitorsArray.controls[index].reset();
    } else {
      this.competitorsArray.removeAt(index);
    }
  }

  saveCompetitor(index: number, competitor: CompetitorForm): void {
    this.indexLoading = index;
    if (competitor.value?.competitorId) {
      this.competitorsService
        .update(this.businessId, competitor.value)
        .pipe(
          take(1),
          finalize(() => (this.indexLoading = -1)),
        )
        .subscribe(
          () => {
            // clear competitor saved from edit backup
            const removeIndex = this.originalCompetitors.indexOf(this.originalCompetitors.find((c) => c.key === index));
            this.originalCompetitors.splice(removeIndex);

            this.competitorsArray.controls[index].disable();

            this.snackbarService.openSuccessSnack('COMPETITORS.ACCOUNT_GROUP.COMPETITOR_SAVE_SUCCESS', {
              duration: this.snackbarDuration,
            });
          },
          () => {
            this.snackbarService.openErrorSnack('COMPETITORS.ACCOUNT_GROUP.COMPETITOR_SAVE_ERROR', {
              duration: this.snackbarDuration,
            });
          },
        );
    } else {
      this.competitorsService
        .create(this.businessId, competitor.value)
        .pipe(
          take(1),
          finalize(() => (this.indexLoading = -1)),
        )
        .subscribe(
          (res) => {
            // clear competitor saved from edit backup
            const removeIndex = this.originalCompetitors.indexOf(this.originalCompetitors.find((c) => c.key === index));
            this.originalCompetitors.splice(removeIndex);

            this.competitorsArray.controls[index]?.get('competitorId')?.setValue(res.competitorId);
            this.competitorsArray.controls[index].disable();
            this.snackbarService.openSuccessSnack('COMPETITORS.ACCOUNT_GROUP.COMPETITOR_SAVE_SUCCESS', {
              duration: this.snackbarDuration,
            });
          },
          () => {
            this.snackbarService.openErrorSnack('COMPETITORS.ACCOUNT_GROUP.COMPETITOR_SAVE_ERROR', {
              duration: this.snackbarDuration,
            });
          },
        );
    }
  }

  deleteCompetitor(index: number, competitor: CompetitorForm): void {
    const confirmOptions = {
      title: this.translate.instant('COMPETITORS.ACCOUNT_GROUP.COMPETITOR_DELETE', { name: competitor.value.name }),
      text: this.translate.instant('COMPETITORS.ACCOUNT_GROUP.COMPETITOR_DELETE_MESSAGE'),
      closeText: this.translate.instant('COMMON.CANCEL'),
      confirmText: this.translate.instant('COMMON.DELETE'),
      severity: 'warn',
    } as ConfirmationData;
    this.confirmationService.confirm(confirmOptions).then((confirmationClicked) => {
      if (confirmationClicked) {
        this.competitorsService
          .delete(this.businessId, competitor.value.competitorId)
          .pipe(take(1))
          .subscribe(
            () => {
              this.competitorsArray.removeAt(index);
              if (this.competitorsArray.length === 0) {
                this.addCompetitor(false);
              }
              this.snackbarService.openSuccessSnack('COMPETITORS.ACCOUNT_GROUP.COMPETITOR_DELETE_SUCCESS', {
                duration: this.snackbarDuration,
              });
            },
            () => {
              this.snackbarService.openErrorSnack('COMPETITORS.ACCOUNT_GROUP.COMPETITOR_DELETE_ERROR', {
                duration: this.snackbarDuration,
              });
            },
          );
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed$$.next();
    this._googleDestroyed$$.next();

    this._destroyed$$.complete();
    this._googleDestroyed$$.complete();
  }
}
