import { Component, Directive, HostBinding } from '@angular/core';

@Component({
  selector: 'glxy-confirmation-actions',
  standalone: true,
  imports: [],
  templateUrl: './modal-actions.component.html',
  styleUrls: ['./modal-actions.component.scss'],
})
export class ModalActionsComponent {}

// Directives

@Directive({
  standalone: true,
  selector: 'glxy-confirmation-primary-actions, [glxyConfirmationPrimaryActions]',
})
export class ConfirmationModalActionsDirective {
  @HostBinding('class') class = 'glxy-confirmation-primary-actions';
}

@Directive({
  standalone: true,
  selector: 'glxy-confirmation-secondary-actions, [glxyConfirmationSecondaryActions]',
})
export class ConfirmationModalSecondaryActionsDirective {
  @HostBinding('class') class = 'glxy-confirmation-secondary-actions';
}
