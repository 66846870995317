import { Injectable } from '@angular/core';
import { VideoStyle } from '@vendasta/snapshot';

@Injectable()
export class ContentService {
  getVideoUrl(videoId: string, videoStyle: VideoStyle): string {
    switch (videoStyle) {
      case VideoStyle.NO_VIDEOS:
        return '';
      case VideoStyle.NORTH_AMERICAN:
        switch (videoId) {
          case 'advertising':
            return 'https://fast.wistia.net/embed/iframe/51yujk8cqk';
          case 'website':
            return 'https://fast.wistia.net/embed/iframe/17vydhnni0';
          case 'listingDistribution':
            return '';
          case 'listingGoodPresenceBadAccuracy':
          case 'listingGoodPresenceGoodAccuracy':
          case 'listingBadPresenceGoodAccuracy':
          case 'listingBadPresenceBadAccuracy':
            return 'https://fast.wistia.net/embed/iframe/5c92pvee2g';
          case 'socialBad':
          case 'socialGood':
          case 'socialMissingAccounts':
          case 'socialMissingFacebook':
          case 'socialMissingTwitter':
            return 'https://fast.wistia.net/embed/iframe/up0nnfghre';
          case 'negative':
          case 'veryFew':
          case 'multipleSources':
            return 'https://fast.wistia.net/embed/iframe/aa3dhj101n';
          case 'seo':
            return 'https://fast.wistia.net/embed/iframe/q45kec0r0x';
          case 'ecommerce':
            return 'https://fast.wistia.com/embed/iframe/eudrtrcqjc';
          default:
            return '';
        }
      case VideoStyle.AUSTRALIAN:
        switch (videoId) {
          case 'advertising':
            return 'https://fast.wistia.net/embed/iframe/8tfimfhla5';
          case 'website':
            return 'https://fast.wistia.net/embed/iframe/2pve57evef';
          case 'listingDistribution':
            return '';
          case 'listingGoodPresenceBadAccuracy':
          case 'listingGoodPresenceGoodAccuracy':
          case 'listingBadPresenceGoodAccuracy':
          case 'listingBadPresenceBadAccuracy':
            return 'https://fast.wistia.net/embed/iframe/iauydtrwxn';
          case 'socialBad':
          case 'socialGood':
          case 'socialMissingAccounts':
          case 'socialMissingFacebook':
          case 'socialMissingTwitter':
            return 'https://fast.wistia.net/embed/iframe/rl39xfnkgf';
          case 'negative':
          case 'veryFew':
          case 'multipleSources':
            return 'https://fast.wistia.net/embed/iframe/2utdyzq9ql';
          case 'seo':
            return 'https://fast.wistia.net/embed/iframe/977yw2wj4o';
          case 'ecommerce':
            return ''; // TODO: add ecommerce video for region
          default:
            return '';
        }
      case VideoStyle.NORTH_AMERICAN_LEGACY:
        switch (videoId) {
          case 'advertising':
            return 'https://fast.wistia.net/embed/iframe/51yujk8cqk';
          case 'website':
            return 'https://fast.wistia.net/embed/iframe/ky2mvbkjxw';
          case 'listingDistribution':
            return '';
          case 'listingGoodPresenceBadAccuracy':
            return 'https://fast.wistia.net/embed/iframe/hp8b1apekh';
          case 'listingGoodPresenceGoodAccuracy':
            return 'https://fast.wistia.net/embed/iframe/mvg8vwwom9';
          case 'listingBadPresenceGoodAccuracy':
            return 'https://fast.wistia.net/embed/iframe/inczem3luh';
          case 'listingBadPresenceBadAccuracy':
            return 'https://fast.wistia.net/embed/iframe/9mwlmbhnxg';
          case 'socialBad':
            return 'https://fast.wistia.net/embed/iframe/omt2o3tbp2';
          case 'socialGood':
            return 'https://fast.wistia.net/embed/iframe/kfeoiugqr3';
          case 'socialMissingAccounts':
            return 'https://fast.wistia.net/embed/iframe/sl7pd0wwze';
          case 'socialMissingFacebook':
            return 'https://fast.wistia.net/embed/iframe/ov3yekc1gq';
          case 'socialMissingTwitter':
            return 'https://fast.wistia.net/embed/iframe/bjye22trcw';
          case 'negative':
            return 'https://fast.wistia.net/embed/iframe/nl7iprse7z';
          case 'veryFew':
            return 'https://fast.wistia.net/embed/iframe/egtwq5cnyu';
          case 'multipleSources':
            return 'https://fast.wistia.net/embed/iframe/iee61oblc1';
          case 'seo':
            return 'https://fast.wistia.net/embed/iframe/q45kec0r0x';
          case 'ecommerce':
            return 'https://fast.wistia.com/embed/iframe/eudrtrcqjc';
          default:
            return '';
        }
      case VideoStyle.UNITED_KINGDOM:
        switch (videoId) {
          case 'advertising':
            return 'https://fast.wistia.net/embed/iframe/4xacmwvasq';
          case 'website':
            return 'https://fast.wistia.net/embed/iframe/xdngpcnv0v';
          case 'listingDistribution':
            return '';
          case 'listingGoodPresenceBadAccuracy':
          case 'listingGoodPresenceGoodAccuracy':
          case 'listingBadPresenceGoodAccuracy':
          case 'listingBadPresenceBadAccuracy':
            return 'https://fast.wistia.net/embed/iframe/r1yuek71f6';
          case 'socialBad':
          case 'socialGood':
          case 'socialMissingAccounts':
          case 'socialMissingFacebook':
          case 'socialMissingTwitter':
            return 'https://fast.wistia.net/embed/iframe/c1xdtmwjjc';
          case 'negative':
          case 'veryFew':
          case 'multipleSources':
            return 'https://fast.wistia.net/embed/iframe/9z4mgnmy5o';
          case 'seo':
            return 'https://fast.wistia.net/embed/iframe/frptzllons';
          case 'ecommerce':
            return ''; // TODO: add ecommerce video for region
          default:
            return '';
        }
      case VideoStyle.SOUTH_AFRICA:
        switch (videoId) {
          case 'advertising':
            return 'https://fast.wistia.net/embed/iframe/7z6o7ifgvh';
          case 'website':
            return 'https://fast.wistia.net/embed/iframe/6ii0pfhmm6';
          case 'listingDistribution':
            return '';
          case 'listingGoodPresenceBadAccuracy':
          case 'listingGoodPresenceGoodAccuracy':
          case 'listingBadPresenceGoodAccuracy':
          case 'listingBadPresenceBadAccuracy':
            return 'https://fast.wistia.net/embed/iframe/dmaqnaz71e';
          case 'socialBad':
          case 'socialGood':
          case 'socialMissingAccounts':
          case 'socialMissingFacebook':
          case 'socialMissingTwitter':
            return 'https://fast.wistia.net/embed/iframe/tmf4cmuux1';
          case 'negative':
          case 'veryFew':
          case 'multipleSources':
            return 'https://fast.wistia.net/embed/iframe/qoos2l5iv8';
          case 'seo':
            return 'https://fast.wistia.net/embed/iframe/edna4imrcd';
          case 'ecommerce':
            return ''; // TODO: add ecommerce video for region
          default:
            return '';
        }
      case VideoStyle.FRENCH:
        switch (videoId) {
          case 'advertising':
            return 'https://fast.wistia.net/embed/iframe/b0v7vaybs1';
          case 'website':
            return 'https://fast.wistia.net/embed/iframe/vp0gbnsrdq';
          case 'listingDistribution':
            return ''; // no french language version of this video
          case 'listingGoodPresenceBadAccuracy':
          case 'listingGoodPresenceGoodAccuracy':
          case 'listingBadPresenceGoodAccuracy':
          case 'listingBadPresenceBadAccuracy':
            return 'https://fast.wistia.net/embed/iframe/egdwectmal';
          case 'socialBad':
          case 'socialGood':
          case 'socialMissingAccounts':
          case 'socialMissingFacebook':
          case 'socialMissingTwitter':
            return 'https://fast.wistia.net/embed/iframe/mzj3l7b0ce';
          case 'negative':
          case 'veryFew':
          case 'multipleSources':
            return 'https://fast.wistia.net/embed/iframe/49d59ddsqz';
          case 'seo':
            return 'https://fast.wistia.net/embed/iframe/1zw63b7lx6';
          case 'ecommerce':
            return ''; // TODO: add ecommerce video for region
          default:
            return '';
        }
      default:
        return '';
    }
  }
}
