import {
  SectionMarker,
  SummaryThermometer,
  MinMaxLimit,
} from '../../thermometers/summary-thermometer/summary-thermometer';
import {
  CORE_WEB_VITALS_CUMULATIVE_LAYOUT_SHIFT,
  CORE_WEB_VITALS_LARGEST_CONTENTFUL_PAINT,
  CORE_WEB_VITALS_MAX_POTENTIAL_FID,
  CORE_WEB_VITALS_SPEED_INDEX,
} from '@vendasta/snapshot';

const SpeedIndexThermometer = <SummaryThermometer>{
  sections: {
    first: <SectionMarker>{
      label: 'WEBSITE.CORE_WEB_VITALS.CARDS.SECTIONS.FAST',
      value: '(<4.3s)',
      sectionClass: 'positive',
    },
    second: <SectionMarker>{
      label: 'WEBSITE.CORE_WEB_VITALS.CARDS.SECTIONS.OKAY',
      sectionClass: 'neutral',
    },
    third: <SectionMarker>{
      label: 'WEBSITE.CORE_WEB_VITALS.CARDS.SECTIONS.SLOW',
      value: '(>5.8s)',
      sectionClass: 'negative',
    },
    limits: <MinMaxLimit>{
      min: 2.8,
      max: 7.3,
    },
  },
};

const LargestContentfulPaintThermometer = <SummaryThermometer>{
  sections: {
    first: <SectionMarker>{
      label: 'WEBSITE.CORE_WEB_VITALS.CARDS.SECTIONS.FAST',
      value: '(<2.5s)',
      sectionClass: 'positive',
    },
    second: <SectionMarker>{
      label: 'WEBSITE.CORE_WEB_VITALS.CARDS.SECTIONS.OKAY',
      sectionClass: 'neutral',
    },
    third: <SectionMarker>{
      label: 'WEBSITE.CORE_WEB_VITALS.CARDS.SECTIONS.SLOW',
      value: '(>4s)',
      sectionClass: 'negative',
    },
    limits: <MinMaxLimit>{
      min: 1.0,
      max: 5.5,
    },
  },
};

const MaxPotentialFirstInputDelayThermometer = <SummaryThermometer>{
  sections: {
    first: <SectionMarker>{
      label: 'WEBSITE.CORE_WEB_VITALS.CARDS.SECTIONS.GOOD',
      value: '(<100ms)',
      sectionClass: 'positive',
    },
    second: <SectionMarker>{
      label: 'WEBSITE.CORE_WEB_VITALS.CARDS.SECTIONS.OKAY',
      sectionClass: 'neutral',
    },
    third: <SectionMarker>{
      label: 'WEBSITE.CORE_WEB_VITALS.CARDS.SECTIONS.POOR',
      value: '(>300ms)',
      sectionClass: 'negative',
    },
    limits: <MinMaxLimit>{
      // this is negative because the graph is not actually split at 33% intervals
      min: -100,
      max: 500,
    },
  },
};

const CumulativeLayoutShiftThermometer = <SummaryThermometer>{
  sections: {
    first: <SectionMarker>{
      label: 'WEBSITE.CORE_WEB_VITALS.CARDS.SECTIONS.GOOD',
      value: '(<0.1)',
      sectionClass: 'positive',
    },
    second: <SectionMarker>{
      label: 'WEBSITE.CORE_WEB_VITALS.CARDS.SECTIONS.OKAY',
      sectionClass: 'neutral',
    },
    third: <SectionMarker>{
      label: 'WEBSITE.CORE_WEB_VITALS.CARDS.SECTIONS.POOR',
      value: '(>0.25)',
      sectionClass: 'negative',
    },
    limits: <MinMaxLimit>{
      // this is negative because the graph is not actually split at 33% intervals
      min: -0.05,
      max: 0.4,
    },
  },
};

export const Thermometers: Map<string, SummaryThermometer> = new Map<string, SummaryThermometer>([
  [CORE_WEB_VITALS_SPEED_INDEX, SpeedIndexThermometer],
  [CORE_WEB_VITALS_LARGEST_CONTENTFUL_PAINT, LargestContentfulPaintThermometer],
  [CORE_WEB_VITALS_MAX_POTENTIAL_FID, MaxPotentialFirstInputDelayThermometer],
  [CORE_WEB_VITALS_CUMULATIVE_LAYOUT_SHIFT, CumulativeLayoutShiftThermometer],
]);
