import { Component, Inject, Input, OnInit } from '@angular/core';
import { GalaxyOrdinalPipe } from '@vendasta/galaxy/pipes';
import { GMBClaimStatus, Status, Vicinity } from '@vendasta/snapshot';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Media, MediaToken } from '../../providers/providers';
import { SnapshotData } from '../../snapshot-report/snapshot-report';
import { SnapshotService } from '../../snapshot.service';
import { LocalSEOData, SERPMetrics } from '../seo-data';
import { BusinessPerformance, MapMarker, SEOPerformance, VicinitySearch } from './local-seo';

const selectedMarkerOpacity = 1;
const markerOpacity = 0.6;

@Component({
  selector: 'snap-local-seo',
  templateUrl: 'local-seo.component.html',
  styleUrls: ['local-seo.component.scss'],
  providers: [GalaxyOrdinalPipe],
})
export class LocalSEOComponent implements OnInit {
  @Input() businessData: SnapshotData;
  @Input() localSEOData: LocalSEOData;
  @Input() edit = false;

  nearMeSearch: VicinitySearch[];
  citySearch: VicinitySearch[];
  printSearch: VicinitySearch;

  private currentNearMeSearch$$: ReplaySubject<VicinitySearch> = new ReplaySubject<VicinitySearch>(1);
  currentNearMeSearch$: Observable<VicinitySearch> = this.currentNearMeSearch$$.asObservable();

  private currentCitySearch$$: ReplaySubject<VicinitySearch> = new ReplaySubject<VicinitySearch>(1);
  currentCitySearch$: Observable<VicinitySearch> = this.currentCitySearch$$.asObservable();

  nearMeMarkers: MapMarker[];
  cityMarkers: MapMarker[];
  printMarkers: MapMarker[];
  searchTerm: string;

  public get SEOPerformance(): typeof SEOPerformance {
    return SEOPerformance;
  }

  public get Vicinity(): typeof Vicinity {
    return Vicinity;
  }

  public get Status(): typeof Status {
    return Status;
  }

  public get GMBClaimStatus(): typeof GMBClaimStatus {
    return GMBClaimStatus;
  }

  constructor(
    private ordinalPipe: GalaxyOrdinalPipe,
    public snapshotService: SnapshotService,
    @Inject(MediaToken) public readonly media: Media,
  ) {}

  ngOnInit(): void {
    this.nearMeSearch = this.buildNearMeVicinityData(this.localSEOData);
    this.citySearch = this.buildCityVicinityData(this.localSEOData);
    this.currentNearMeSearch$$.next(this.nearMeSearch[0]);
    this.currentCitySearch$$.next(this.citySearch[0]);
    this.nearMeMarkers = this.buildMarkers(this.nearMeSearch);
    this.cityMarkers = this.buildMarkers(this.citySearch);
    // set the printable search to be used in the print or pdf version
    if (this.nearMeSearch.length > 0) {
      this.printSearch = this.nearMeSearch[0];
      this.printMarkers = this.nearMeMarkers;
    } else {
      this.printSearch = this.citySearch[0];
      this.printMarkers = this.cityMarkers;
    }
  }

  setNearMe(index: number): void {
    this.currentNearMeSearch$$.next(this.nearMeSearch[index]);
    this.setNearMeSelectedMarker(index);
  }

  businessPerformance(rank: number): BusinessPerformance {
    switch (true) {
      case rank > 0 && rank <= 3:
        return SEOPerformance.TOP;
      case rank > 3 && rank <= 20:
        return SEOPerformance.AVERAGE;
      case rank > 20 && rank <= 100:
        return SEOPerformance.BOTTOM;
      default:
        return SEOPerformance.UNKNOWN;
    }
  }

  buildMarkers(searches: VicinitySearch[]): MapMarker[] {
    const businessMarkers = [];

    searches.forEach((s) => {
      const markerBase = {
        path: 0,
        strokeWeight: 3,
        strokeColor: 'white',
        scale: 28,
        fillOpacity: this.setSelectedCenterMarker(s.data?.vicinity),
      };

      const mainBusinessFiltered = s.data.results?.filter((b) => b.isMainBusiness);
      const mainBusiness = mainBusinessFiltered.length > 0 ? mainBusinessFiltered[0] : null;
      if (mainBusiness) {
        const markerIcon = {
          ...markerBase,
          fillColor: this.businessPerformance(mainBusiness.rank).color,
        };

        businessMarkers.push({
          label: {
            text: mainBusiness?.rank ? this.ordinalPipe.transform(mainBusiness.rank) : '-',
            color: 'white',
          },
          lat: s.data.searchLocation.latitude,
          lng: s.data.searchLocation.longitude,
          icon: markerIcon,
        });
      } else {
        businessMarkers.push({
          label: {
            text: '-',
            color: 'white',
          },
          lat: s.data.searchLocation.latitude,
          lng: s.data.searchLocation.longitude,
          icon: {
            ...markerBase,
            fillColor: SEOPerformance.UNKNOWN.color,
          },
        });
      }
    });
    return businessMarkers;
  }

  buildVicinityData(search: SERPMetrics): VicinitySearch {
    let mainBusinessFiltered = [];
    const vicinitySearch: VicinitySearch = {
      data: null,
      searchTerm: '',
      mainBusiness: null,
      centerMap: null,
      mainBusinessPerformance: SEOPerformance.UNKNOWN,
    };

    if (search) {
      vicinitySearch.searchTerm = search.searchTerm.split('near')[0];
      vicinitySearch.data = search;
      if (vicinitySearch.data.results.length > 0) {
        mainBusinessFiltered = vicinitySearch.data.results.filter((sm) => sm.isMainBusiness);
        vicinitySearch.data.results.sort((a, b) => a.rank - b.rank);
      }
    }

    if (mainBusinessFiltered.length > 0) {
      vicinitySearch.mainBusiness = mainBusinessFiltered[0];
      vicinitySearch.mainBusinessPerformance = this.businessPerformance(vicinitySearch.mainBusiness.rank);
    }

    return vicinitySearch;
  }

  buildCityVicinityData(data: LocalSEOData): VicinitySearch[] {
    const cityVicinity = [];
    let citySearch = null;

    if (data?.searches?.length > 0) {
      const citySearchFiltered = data.searches.filter((vs) => vs.vicinity === Vicinity.VICINITY_CITY);
      citySearch = citySearchFiltered.length > 0 ? citySearchFiltered[0] : null;
    }
    cityVicinity.push(this.buildVicinityData(citySearch));
    this.setMapCenterLocation(cityVicinity[0]);
    return cityVicinity;
  }

  buildNearMeVicinityData(data: LocalSEOData): VicinitySearch[] {
    const nearMeVicinities = [];
    let mainNearMe = null;

    if (data?.searches?.length > 0) {
      const mainNearMeSearchFiltered = data.searches.filter((vs) => vs.vicinity === Vicinity.VICINITY_NEARME);
      mainNearMe = mainNearMeSearchFiltered.length > 0 ? mainNearMeSearchFiltered[0] : null;
      if (mainNearMe) {
        nearMeVicinities.push(this.buildVicinityData(mainNearMe));
        this.setMapCenterLocation(nearMeVicinities[0]);
      }

      const nearMeSearches = data.searches.filter((vs) => this.isNearMeCardinalVicinity(vs));
      nearMeSearches.forEach((ns) => {
        nearMeVicinities.push(this.buildVicinityData(ns));
      });
    }
    return nearMeVicinities;
  }

  isNearMeCardinalVicinity(vicinitySearch: SERPMetrics): boolean {
    switch (vicinitySearch.vicinity) {
      case Vicinity.VICINITY_NEARME_NORTH:
        return true;
      case Vicinity.VICINITY_NEARME_NORTHEAST:
        return true;
      case Vicinity.VICINITY_NEARME_NORTHWEST:
        return true;
      case Vicinity.VICINITY_NEARME_EAST:
        return true;
      case Vicinity.VICINITY_NEARME_SOUTHEAST:
        return true;
      case Vicinity.VICINITY_NEARME_SOUTH:
        return true;
      case Vicinity.VICINITY_NEARME_SOUTHWEST:
        return true;
      case Vicinity.VICINITY_NEARME_WEST:
        return true;
      default:
        return false;
    }
  }

  setMapCenterLocation(mainVicinitySearch: VicinitySearch): void {
    if (mainVicinitySearch?.data?.searchLocation) {
      mainVicinitySearch.centerMap = mainVicinitySearch.data.searchLocation;
    }
  }

  setSelectedCenterMarker(vicinity: Vicinity): number {
    if (vicinity === Vicinity.VICINITY_CITY || vicinity === Vicinity.VICINITY_NEARME) {
      return selectedMarkerOpacity;
    }
    return markerOpacity;
  }

  setNearMeSelectedMarker(index: number): void {
    this.nearMeMarkers.forEach((m) => {
      if (m.icon.fillOpacity === 1) {
        m.icon = { ...m.icon, fillOpacity: markerOpacity };
      }
    });
    this.nearMeMarkers[index].icon = { ...this.nearMeMarkers[index].icon, fillOpacity: selectedMarkerOpacity };
  }

  handleDoesNotExistAction(): void {
    this.snapshotService.partnerDomain$.pipe(take(1)).subscribe((domain) => {
      const url = `${domain}/info/${this.snapshotService.businessId}`;
      window.open(url, '_blank');
    });
  }
}
