<ng-container *ngIf="tableConfig$ | async as table; else loading">
  <table mat-table [dataSource]="table.data">
    <ng-container matColumnDef="rowLabel">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <div class="row-labels">
          <mat-icon [ngClass]="row.numberOfSolutions > 0 ? 'green-icon' : 'red-icon'">
            {{ row.numberOfSolutions > 0 ? 'check_circle' : 'cancel' }}
          </mat-icon>
          {{ row.attribute }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="found">
      <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.FOUND' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div *ngIf="row.numberOfSolutions > 0; else noneFound">
          {{ row.name }}
          {{ row.version ? row.version : '' }}
          <div *ngIf="row.numberOfSolutions > 1">
            (+{{ row.numberOfSolutions - 1 }} {{ 'COMMON.MORE' | translate }})
          </div>
        </div>
        <ng-template #noneFound>
          {{ 'COMMON.NONE_FOUND' | translate }}
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="why">
      <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.WHY_IT_MATTERS' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.recommendation }}
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          [checked]="!row?.control?.value"
          (change)="row.control.setValue(!row.control.value)"
        ></mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="table.columnNames"></tr>
    <tr mat-row *matRowDef="let row; columns: table.columnNames" [hidden]="!table.editMode && row?.control?.value"></tr>
  </table>
</ng-container>
<ng-template #loading>
  <glxy-loading-spinner></glxy-loading-spinner>
</ng-template>
