import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mapTo } from 'rxjs/operators';

const claimAccountUrl = '/ajax/v1/claim-account/';
const askQuestionUrl = '/ajax/v1/ask-question/';

@Injectable()
export class ClaimAccountService {
  constructor(private http: HttpClient) {}

  askQuestion(
    email: string,
    businessId: string,
    firstName: string,
    lastName: string,
    message: string,
    phoneNumber: string,
  ): any {
    const data = {
      email: email,
      accountGroupId: businessId,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      message: message,
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = {
      headers: headers,
    };
    return this.http.post<null>(askQuestionUrl, data, requestOptions);
  }

  doClaim(email: string, businessId: string): Observable<boolean> {
    const data = {
      email: email,
      accountGroupId: businessId,
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = {
      headers: headers,
    };
    return this.http.post<null>(claimAccountUrl, data, requestOptions).pipe(mapTo(true));
  }
}
