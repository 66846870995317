import { Audit } from './website-data';
import {
  CORE_WEB_VITALS_CUMULATIVE_LAYOUT_SHIFT,
  CORE_WEB_VITALS_MAX_POTENTIAL_FID,
  CORE_WEB_VITALS_LARGEST_CONTENTFUL_PAINT,
  CORE_WEB_VITALS_SPEED_INDEX,
} from '@vendasta/snapshot';

export const coreWebVitals = [
  CORE_WEB_VITALS_SPEED_INDEX,
  CORE_WEB_VITALS_LARGEST_CONTENTFUL_PAINT,
  CORE_WEB_VITALS_MAX_POTENTIAL_FID,
  CORE_WEB_VITALS_CUMULATIVE_LAYOUT_SHIFT,
];

export interface PartitionedAudits {
  shouldFix: Audit[];
  considerFix: Audit[];
  passed: Audit[];
}

// The following are the locales of countries that use commas as decimal separators ie. 2,2 (equivalent to 2.2 in english)
export const countriesWithCommaDecimalSeparators = [
  'de', // german
  'pt', // portuguese
  'fr', // french
];

export function partitionAudits(audits: Audit[]): PartitionedAudits {
  return {
    shouldFix: audits.filter((s) => s.score < 0.5),
    considerFix: audits.filter((s) => s.score >= 0.5 && s.score < 0.9),
    passed: audits.filter((s) => s.score >= 0.9),
  };
}

// realParseFloat takes any string input, strips the non-numerical type characters, and tries to parse a float
// it handles locales that use dots or commas as decimal separators
export function realParseFloat(s: string, locale: string): number {
  if (!s) {
    return null;
  }
  s = s.replace(/[^\d,.-]/g, ''); // strip everything except numbers, dots, commas and negative sign

  if (
    countriesWithCommaDecimalSeparators.indexOf(locale) === -1 &&
    /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/.test(s)
  ) {
    // if not in locale with comma decimal separators and matches #,###.######
    s = s.replace(/,/g, ''); // strip out commas
    return parseFloat(s); // convert to number
  } else if (/^-?(?:\d+|\d{1,3}(?:\.\d{3})+)(?:,\d+)?$/.test(s)) {
    // either in locale with comma decimal separators or not match #,###.###### and now matches #.###,########
    s = s.replace(/\./g, ''); // strip out dots
    s = s.replace(/,/g, '.'); // replace comma with dot
    return parseFloat(s);
  } // try #,###.###### anyway
  else {
    s = s.replace(/,/g, ''); // strip out commas
    return parseFloat(s); // convert to number
  }
}
