<div class="still-working-container">
  <glxy-empty-state [size]="'small'" [widthConstraint]="false">
    <glxy-empty-state-hero>
      <mat-icon>construction</mat-icon>
    </glxy-empty-state-hero>
    <glxy-empty-state-title>
      {{ 'ERRORS.STILL_WORKING_TITLE' | translate }}
    </glxy-empty-state-title>
    <p>{{ 'ERRORS.STILL_WORKING_DESCRIPTION' | translate }}</p>
  </glxy-empty-state>
</div>
