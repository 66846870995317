<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>
      {{ 'EDIT_REPORT.LOCAL_SEO.TITLE' | translate }}
    </mat-card-title>
    <mat-card-subtitle data-testid="kw-explanation-text">
      <ng-container *ngIf="configCustomKeyword$ | async; else defaultKeyword">
        {{ 'EDIT_REPORT.LOCAL_SEO.SUBTITLE_WITH_KEYWORD' | translate }}
      </ng-container>
      <ng-template #defaultKeyword>
        <ng-container *ngIf="localSEOSearchKeyword$ | async; else accountGroupCategory">
          {{ 'EDIT_REPORT.LOCAL_SEO.SUBTITLE_GMB_CATEGORY' | translate }}
        </ng-container>
        <ng-template #accountGroupCategory>
          {{ 'EDIT_REPORT.LOCAL_SEO.SUBTITLE_BUSINESS_PROFILE_CATEGORY' | translate }}
        </ng-template>
      </ng-template>
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content data-testid="custom-keyword-content">
    <div *ngIf="isExpired" class="expired-alert">
      <glxy-alert type="error">
        {{ 'EDIT_REPORT.LOCAL_SEO.SNAPSHOT_EXPIRED' | translate : { snapshotName: snapshotName$ | async | translate } }}
        <a (click)="openRefreshDialog()">{{ 'EDIT_REPORT.LOCAL_SEO.REFRESH_REPORT' | translate }}</a>
      </glxy-alert>
    </div>
    <div class="row">
      <div class="col col-xs-12 col-md-6">
        <glxy-input
          data-testid="keyword-input"
          label="{{ 'EDIT_REPORT.LOCAL_SEO.KEYWORD_LABEL' | translate }}"
          [formControl]="customKeywordControl"
          [validators]="validators"
          [attr.disabled]="isExpired"
        ></glxy-input>
        <mat-error class="required-error" *ngIf="customKeywordControl.hasError('required')">
          {{ 'EDIT_REPORT.LOCAL_SEO.KEYWORD_REQUIRED' | translate }}
        </mat-error>
      </div>
    </div>
    <ng-container *ngIf="customKeywordControl.value">
      <div class="row">
        <span
          data-testid="kw-near-me-preview"
          class="preview-text"
          [innerHTML]="'EDIT_REPORT.LOCAL_SEO.PREVIEW_NEAR_ME' | translate : { keyword: customKeywordControl.value }"
        ></span>
      </div>
      <div class="row">
        <span
          data-testid="kw-city-preview"
          class="preview-text"
          [innerHTML]="
            'EDIT_REPORT.LOCAL_SEO.PREVIEW_CITY'
              | translate
                : {
                    keyword: customKeywordControl.value,
                    city: (snapshotService.snapshotData$ | async)?.city
                  }
          "
        ></span>
      </div>
    </ng-container>

    <mat-divider></mat-divider>
    <span class="update-container">
      <button
        data-testid="update-kw-btn"
        mat-stroked-button
        class="update-button"
        [disabled]="customKeywordControl.invalid || disableUpdateButton || isExpired"
        (click)="updateKeyword()"
      >
        <div>
          {{ 'EDIT_REPORT.LOCAL_SEO.UPDATE_BUTTON' | translate }}
        </div>
      </button>
      <span class="update-warning">
        {{ 'EDIT_REPORT.LOCAL_SEO.UPDATE_WARNING' | translate }}
      </span>
    </span>
  </mat-card-content>
</mat-card>
