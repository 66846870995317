import { Component, Inject, Input } from '@angular/core';
import { Listing } from '../listing-data';
import { ListingListingStatus } from '@vendasta/snapshot';
import { Media, MediaToken } from '../../providers/providers';
import { Observable, distinctUntilChanged, map, shareReplay } from 'rxjs';

@Component({
  selector: 'snap-listing-table',
  templateUrl: './listing-table.component.html',
  styleUrls: ['./listing-table.component.scss'],
})
export class ListingTableComponent {
  private _desktopColumns: string[] = ['source', 'companyName', 'address', 'phone', 'status'];
  private _mobileColumns: string[] = ['source', 'listing'];

  @Input() listings: Listing[];

  LISTING_STATUS = ListingListingStatus; // for use in template

  readonly displayedColumns$: Observable<string[]> = this.media.mobile$.pipe(
    distinctUntilChanged(),
    map((isMobile) => (isMobile ? this._mobileColumns : this._desktopColumns)),
    shareReplay(1),
  );

  constructor(@Inject(MediaToken) public readonly media: Media) {}
}
