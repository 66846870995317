import {
  DataProviderAccuracyStatus,
  DataProviderAccuracyStatusV2,
  ListingListingStatus,
  ListingV2ListingStatus,
} from '@vendasta/snapshot';
import { Business } from '../snapshot-lite/business';
import { GooglePlace } from '../snapshot-lite/google-place';
import { ListingV2ListingFieldInterface, ListingV2SourceInterface } from '@vendasta/snapshot';

interface ListingDataInterface {
  listingPresence: ListingPresenceInterface;
  listingAccuracy: ListingAccuracyInterface;
  dataProviderAccuracy: DataProviderAccuracyInterface;
  listings?: ListingV2DetailInterface;
}

interface ListingV2DetailInterface {
  listings: ListingV2DetailItemInterface[];
}

export interface ListingV2DetailItemInterface {
  companyName?: ListingV2ListingFieldInterface;
  address?: ListingV2ListingFieldInterface;
  city?: ListingV2ListingFieldInterface;
  state?: ListingV2ListingFieldInterface;
  zip?: ListingV2ListingFieldInterface;
  website?: ListingV2ListingFieldInterface;
  phone?: ListingV2ListingFieldInterface;
  listingUrl?: string;
  status: ListingV2ListingStatusEdit;
  source: ListingV2SourceInterface;
}

interface ListingScanInterface {
  listings: ListingInterface[];
}

interface ListingInterface {
  sourceName: string;
  sourceId: number;
  sourceIconUrl: string;
  status: ListingListingStatus;
  url?: string;
  companyName?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phone?: string;
  phoneMatch?: boolean;
  companyNameMatch?: boolean;
  addressMatch?: boolean;
}

export interface ListingPresenceInterface {
  foundCount: number;
  industryAverageFound: number;
  availableSources: number;
  facebook: ListingSourceInterface;
  twitter: ListingSourceInterface;
  foursquare: ListingSourceInterface;
  google: ListingSourceInterface;
  instagram?: ListingSourceInterface;
  competitors?: CompetitorsPresenceInterface[];
}

interface ListingSourceInterface {
  foundCount?: number;
  exampleListingUrl?: string;
  industryAverageFound?: number;
}

export interface ListingAccuracyInterface {
  accurateListings: number;
  industryAverageAccurateListings: number;
  incorrectPhoneNumber: InaccurateDataInterface;
  missingPhoneNumber: InaccurateDataInterface;
  incorrectAddress: InaccurateDataInterface;
  missingAddress: InaccurateDataInterface;
  incorrectWebsite: InaccurateDataInterface;
  missingWebsite: InaccurateDataInterface;
  competitors?: CompetitorsAccuracyInterface[];
}

interface CompetitorsPresenceInterface {
  snapshotId: string;
  companyName: string;
  foundCount: number;
  updated: Date;
}

interface CompetitorsAccuracyInterface {
  snapshotId: string;
  companyName: string;
  accurateListings: number;
  updated: Date;
}

interface InaccurateDataInterface {
  inaccurateCount: number;
  exampleData: string;
  sourceName: string;
  exampleListingUrl: string;
}

interface DataProviderAccuracyInterface {
  neustarAccurate: DataProviderAccuracyStatus;
  factualAccurate: DataProviderAccuracyStatus;
  infogroupAccurate: DataProviderAccuracyStatus;
  neustarStatus: DataProviderAccuracyStatusV2;
  factualStatus: DataProviderAccuracyStatusV2;
  infogroupStatus: DataProviderAccuracyStatusV2;
  foursquareStatus: DataProviderAccuracyStatusV2;
}

export class CompetitorsPresence {
  snapshotId: string;
  companyName: string;
  foundCount: number;
  updated: Date;

  constructor(data: CompetitorsPresenceInterface) {
    this.snapshotId = data.snapshotId;
    this.companyName = data.companyName;
    this.foundCount = data.foundCount ? data.foundCount : 0;
    this.updated = data.updated;
  }
}

class CompetitorsAccuracy {
  snapshotId: string;
  companyName: string;
  accurateListings: number;
  updated: Date;

  constructor(data: CompetitorsAccuracyInterface) {
    this.snapshotId = data.snapshotId;
    this.companyName = data.companyName;
    this.accurateListings = data.accurateListings ? data.accurateListings : 0;
    this.updated = data.updated;
  }
}

class ListingSource {
  foundCount: number;
  exampleListingUrl: string;
  industryAverageFound: number;

  constructor(data: ListingSourceInterface) {
    this.foundCount = data.foundCount || 0;
    this.exampleListingUrl = data.exampleListingUrl || '';
    this.industryAverageFound = data.industryAverageFound || 0;
  }
}

class ListingPresence {
  foundCount: number;
  industryAverageFound: number;
  availableSources: number;
  facebook: ListingSource | null;
  twitter: ListingSource | null;
  foursquare: ListingSource | null;
  google: ListingSource | null;
  instagram: ListingSource | null;
  competitors: CompetitorsPresence[];

  constructor(data: ListingPresenceInterface) {
    this.foundCount = data?.foundCount || 0;
    this.industryAverageFound = data?.industryAverageFound || 0;
    this.availableSources = data?.availableSources;
    this.facebook = new ListingSource(data.facebook || {});
    this.twitter = new ListingSource(data.twitter || {});
    this.foursquare = new ListingSource(data.foursquare || {});
    this.instagram = new ListingSource(data.instagram || {});
    this.google = new ListingSource(data.google || {});
    this.competitors = (data.competitors || []).map((c) => {
      return new CompetitorsPresence(c);
    });
  }
}

class InaccurateData {
  static readonly GOOGLE_MAPS = 'Google Maps';
  static readonly GOOGLE = 'Google';

  inaccurateCount: number;
  exampleData: string;
  sourceName: string;
  exampleListingUrl: string;

  constructor(data: InaccurateDataInterface) {
    this.inaccurateCount = data.inaccurateCount;
    this.exampleData = data.exampleData;
    // Google Maps was renamed to Google in Core Services, but snapshots created prior to ~Jan 2020 will not have the updated name
    this.sourceName = data.sourceName === InaccurateData.GOOGLE_MAPS ? InaccurateData.GOOGLE : data.sourceName;
    this.exampleListingUrl = data.exampleListingUrl;
  }
}

class ListingAccuracy {
  accurateListings: number;
  industryAverageAccurateListings: number;
  incorrectPhoneNumber: InaccurateData;
  missingPhoneNumber: InaccurateData;
  incorrectAddress: InaccurateData;
  missingAddress: InaccurateData;
  incorrectWebsite: InaccurateData;
  missingWebsite: InaccurateData;
  competitors: CompetitorsAccuracy[];

  constructor(data: ListingAccuracyInterface) {
    this.accurateListings = data.accurateListings;
    this.industryAverageAccurateListings = data.industryAverageAccurateListings;
    this.incorrectPhoneNumber = new InaccurateData(data.incorrectPhoneNumber);
    this.missingPhoneNumber = new InaccurateData(data.missingPhoneNumber);
    this.incorrectAddress = new InaccurateData(data.incorrectAddress);
    this.missingAddress = new InaccurateData(data.missingAddress);
    this.incorrectWebsite = new InaccurateData(data.incorrectWebsite);
    this.missingWebsite = new InaccurateData(data.missingWebsite);
    this.competitors = (data.competitors || []).map((c) => {
      return new CompetitorsAccuracy(c);
    });
  }
}

export class DataProviderAccuracy {
  neustarAccurate: DataProviderAccuracyStatus;
  factualAccurate: DataProviderAccuracyStatus;
  infogroupAccurate: DataProviderAccuracyStatus;
  neustarStatus: DataProviderAccuracyStatusV2;
  factualStatus: DataProviderAccuracyStatusV2;
  infogroupStatus: DataProviderAccuracyStatusV2;
  foursquareStatus: DataProviderAccuracyStatusV2;

  constructor(data: DataProviderAccuracyInterface) {
    this.neustarAccurate = data.neustarAccurate || DataProviderAccuracyStatus.ACCURATE;
    this.factualAccurate = data.factualAccurate || DataProviderAccuracyStatus.ACCURATE;
    this.infogroupAccurate = data.infogroupAccurate || DataProviderAccuracyStatus.ACCURATE;

    this.neustarStatus = data.neustarStatus || DataProviderAccuracyStatusV2.UNSPECIFIED_V2;
    this.factualStatus = data.factualStatus || DataProviderAccuracyStatusV2.UNSPECIFIED_V2;
    this.infogroupStatus = data.infogroupStatus || DataProviderAccuracyStatusV2.UNSPECIFIED_V2;
    this.foursquareStatus = data.foursquareStatus || DataProviderAccuracyStatusV2.UNSPECIFIED_V2;
  }
}

export class Listing {
  sourceName: string;
  sourceId: number;
  sourceIconUrl: string;
  status: ListingListingStatus;
  url?: string;
  companyName?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phone?: string;
  phoneMatch?: boolean;
  companyNameMatch?: boolean;
  addressMatch?: boolean;
  phoneError?: boolean;
  companyNameError?: boolean;
  addressError?: boolean;

  constructor(listing: ListingInterface) {
    this.sourceName = listing.sourceName;
    this.sourceId = listing.sourceId;
    this.sourceIconUrl = listing.sourceIconUrl;
    this.status = listing.status || ListingListingStatus.ACCURATE;
    this.url = listing.url;
    this.companyName = listing.companyName;
    this.address = listing.address;
    this.city = listing.city;
    this.state = listing.state;
    this.zipCode = listing.zipCode;
    this.phone = listing.phone;
    this.phoneMatch = listing.phoneMatch;
    this.companyNameMatch = listing.companyNameMatch;
    this.addressMatch = listing.addressMatch;
    this.phoneError = !listing.phoneMatch && !!listing.phone;
    this.companyNameError = !listing.companyNameMatch && !!listing.companyName;
    this.addressError =
      !listing.addressMatch && (!!listing.address || !!listing.city || !!listing.state || !!listing.zipCode);
  }

  public setListingMatchInfo(business: Business, place: GooglePlace): void {
    this.companyNameMatch = (business.companyName || '').toLowerCase() === (this.companyName || '').toLowerCase();

    const cleanBusinessPhone = (business.phoneNumber || '').replace(/\W/g, '').toLowerCase();
    const cleanIntPhone = (place.internationalPhoneNumber || '').replace(/\W/g, '').toLowerCase();
    const cleanFormattedPhone = (place.formattedPhoneNumber || '').replace(/\W/g, '').toLowerCase();
    this.phoneMatch = cleanBusinessPhone === cleanIntPhone || cleanBusinessPhone === cleanFormattedPhone;

    // Yext only seems to compare street address?
    this.addressMatch =
      (business.address || '').toLowerCase() === (place.shortAddress || '').toLowerCase() ||
      (business.address || '').toLowerCase() === (place.longAddress || '').toLowerCase();

    this.companyNameError = !this.companyNameMatch && !!this.companyName;
    this.phoneError = !this.phoneMatch && !!this.phone;
    this.addressError = !this.addressMatch && (!!this.address || !!this.city || !!this.state || !!this.zipCode);

    this.status =
      this.companyNameMatch && this.phoneMatch && this.addressMatch
        ? ListingListingStatus.ACCURATE
        : ListingListingStatus.INACCURATE;
  }
}

export class ListingScan {
  listings: Listing[];

  constructor(scan: ListingScanInterface) {
    this.listings = scan.listings ? scan.listings.map((l) => new Listing(l)) : [];
  }

  public addGoogleListing(business: Business, googlePlace: GooglePlace): void {
    const googleListing = this.listings.find((l) => l.sourceId === 10010);
    if (googleListing !== undefined) {
      googleListing.companyName = googlePlace.name;
      googleListing.url = googlePlace.url;
      googleListing.address = googlePlace.shortAddress;
      googleListing.city = googlePlace.shortCity;
      googleListing.state = googlePlace.shortState;
      googleListing.zipCode = googlePlace.shortZip;
      googleListing.phone = googlePlace.formattedPhoneNumber;

      googleListing.setListingMatchInfo(business, googlePlace);
    }
  }
}

class ListingV2ListingField implements ListingV2ListingFieldInterface {
  value: string;
  match: boolean;
  ignore: boolean;

  constructor(data?: ListingV2ListingFieldInterface) {
    this.value = data?.value || '';
    this.match = data?.match || false;
    this.ignore = data?.ignore || false;
  }
}

class ListingV2Source implements ListingV2SourceInterface {
  sourceId: number;
  sourceName: string;
  sourceIconUrl: string;

  constructor(data: ListingV2SourceInterface) {
    this.sourceId = data.sourceId;
    this.sourceName = data.sourceName || '';
    this.sourceIconUrl = data.sourceIconUrl || '';
  }
}

export const ListingV2ListingStatusEdit = {
  ...ListingV2ListingStatus,
  IGNORED: -1,
};
export type ListingV2ListingStatusEdit = (typeof ListingV2ListingStatusEdit)[keyof typeof ListingV2ListingStatusEdit];

export class ListingV2DetailItem {
  companyName: ListingV2ListingFieldInterface;
  address: ListingV2ListingFieldInterface;
  city: ListingV2ListingFieldInterface;
  state: ListingV2ListingFieldInterface;
  zip: ListingV2ListingFieldInterface;
  website: ListingV2ListingFieldInterface;
  phone: ListingV2ListingFieldInterface;
  listingUrl: string;
  status: ListingV2ListingStatusEdit;
  source: ListingV2SourceInterface;
  ignore?: boolean;

  constructor(details: ListingV2DetailItemInterface) {
    this.companyName = new ListingV2ListingField(details.companyName);
    this.address = new ListingV2ListingField(details.address);
    this.city = new ListingV2ListingField(details.city);
    this.state = new ListingV2ListingField(details.state);
    this.zip = new ListingV2ListingField(details.zip);
    this.website = new ListingV2ListingField(details.website);
    this.phone = new ListingV2ListingField(details.phone);
    this.listingUrl = details.listingUrl || '';
    this.status = details.status ?? ListingV2ListingStatus.UNSPECIFIED;
    this.source = new ListingV2Source(details.source);
  }
}

export class ListingV2Detail {
  listings: ListingV2DetailItem[];

  constructor(listings: ListingV2DetailInterface) {
    this.listings = listings.listings ? listings.listings.map((l) => new ListingV2DetailItem(l)) : [];
  }
}

export class ListingData {
  listingPresence: ListingPresence;
  listingAccuracy: ListingAccuracy;
  dataProviderAccuracy: DataProviderAccuracy;
  listings?: ListingV2Detail | null;

  constructor(data: ListingDataInterface) {
    this.listingPresence = new ListingPresence(data.listingPresence);
    this.listingAccuracy = new ListingAccuracy(data.listingAccuracy);
    this.dataProviderAccuracy = new DataProviderAccuracy(data.dataProviderAccuracy);
    this.listings = data.listings ? new ListingV2Detail(data.listings) : null;
  }
}
