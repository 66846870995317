import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { TranslateService } from '@ngx-translate/core';
import { SEOService } from '../seo.service';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { KeywordMetrics, OrganicDomainData } from '../seo-data';
import { FormatType, SectionTableItem } from '../../section-table/section-table';
import { BaseSectionComponent } from '../../section/base-section.component';
import { SnapshotService } from '../../snapshot.service';

@Component({
  selector: 'snap-organic-keywords-ranking-subsection',
  templateUrl: './organic-keywords-ranking-subsection.component.html',
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: SEOService,
    },
  ],
})
export class OrganicKeywordsRankingSubsectionComponent extends BaseSectionComponent implements OnInit {
  @Output() customKeywordControl: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();
  organicDomainMetrics$: Observable<OrganicDomainData> = new Observable<OrganicDomainData>();
  organicKeywordMetrics$: Observable<KeywordMetrics> = new Observable<KeywordMetrics>();
  showMonthlyClicks$: Observable<boolean> = new Observable<boolean>();
  mobileRankingItems$: Observable<SectionTableItem[]>;

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: SEOService,
    public snapshotService: SnapshotService,
    public snackbarService: SnackbarService,
    public translateService: TranslateService,
  ) {
    super(service, snackbarService, translateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.customKeywordControl.emit(this.controls.controls['customKeyword']);
    this.organicDomainMetrics$ = this.service.data$.pipe(map((ls) => ls.organicDomainData));

    this.organicKeywordMetrics$ = this.service.data$.pipe(map((d) => d.organicKeywordData.business));

    this.showMonthlyClicks$ = this.organicKeywordMetrics$.pipe(
      map((metrics: KeywordMetrics) => {
        // only show monthly clicks if monthlyLocalSearchVolume is 0 and monthlyClicks is > 0

        return (
          metrics.keywords.length > 0 &&
          metrics.keywords[0].monthlyLocalSearchVolume === 0 &&
          metrics.keywords[0].monthlyClicks > 0
        );
      }),
    );

    this.mobileRankingItems$ = this.service.data$.pipe(
      map((ls) => {
        const dataItems: SectionTableItem[] = [];

        dataItems.push(
          {
            title: 'COMMON.RANK',
            competitors: ls.organicKeywordData.business.keywords.map((k) => {
              return {
                name: k.term,
                value: k.position,
              };
            }),
          },
          {
            title: 'LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.LOCAL_SEARCHES',
            competitors: ls.organicKeywordData.business.keywords.map((k) => {
              return {
                name: k.term,
                value: k.monthlyLocalSearchVolume,
              };
            }),
          },
          {
            title: 'LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.GLOBAL_SEARCHES',
            competitors: ls.organicKeywordData.business.keywords.map((k) => {
              return {
                name: k.term,
                value: k.monthlySearchVolume,
              };
            }),
          },
          {
            title: 'COMMON.COMPETITIVENESS',
            dataFormat: FormatType.Bar,
            competitors: ls.organicKeywordData.business.keywords.map((k) => {
              return {
                name: k.term,
                value: k.difficulty,
              };
            }),
          },
        );
        return dataItems;
      }),
    );
  }
}
