<h2 mat-dialog-title>{{ 'TITLES.BUSINESS_DETAILS' | translate }}</h2>
<mat-dialog-content>
  <form class="form-container flex-column" [formGroup]="editForm">
    <mat-form-field>
      <input matInput placeholder="{{ 'BUSINESS_DETAILS.NAME' | translate }}*" formControlName="companyName" />
      <mat-error *ngIf="this.editForm.controls.companyName.hasError('required')">
        {{ 'ERRORS.REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{ 'BUSINESS_DETAILS.ADDRESS' | translate }}*" formControlName="address" />
      <mat-error *ngIf="this.editForm.controls.address.hasError('required')">
        {{ 'ERRORS.REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{ 'BUSINESS_DETAILS.CITY' | translate }}" formControlName="city" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{ 'BUSINESS_DETAILS.STATE' | translate }}" formControlName="state" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{ 'BUSINESS_DETAILS.ZIP' | translate }}" formControlName="zip" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{ 'BUSINESS_DETAILS.PHONE_NUMBER' | translate }}*" formControlName="phoneNumber" />
      <mat-error *ngIf="this.editForm.controls.phoneNumber.hasError('required')">
        {{ 'ERRORS.PHONE_NUMBER_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="close()">
    {{ 'COMMON.CANCEL' | translate }}
  </button>
  <button mat-flat-button color="primary" (click)="submit()" [disabled]="!editForm.valid">
    {{ 'COMMON.EDIT' | translate }}
  </button>
</mat-dialog-actions>
