import { Component, OnInit } from '@angular/core';
import { OAuth2Service } from '@vendasta/oauth2';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {
  constructor(private oAuth2Service: OAuth2Service) {}

  ngOnInit() {
    this.oAuth2Service.logout();
  }
}
