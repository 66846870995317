<div class="multi-subsection-container" *ngIf="edit || !hide">
  <div class="multi-subsection-header">
    <div *ngFor="let subsection of subsections">
      <snap-toggled-item class="subsection" [hideControl]="subsection.hideControl" [edit]="edit">
        <div class="subsection">
          <snap-grade *ngIf="hideGrade === false" [grade]="subsection.grade" size="extra-small"></snap-grade>

          <div class="heading">{{ subsection.heading }}</div>

          <div class="edit-subsection">
            <ng-template snapItemToggle></ng-template>
          </div>
        </div>
      </snap-toggled-item>
    </div>
    <div *ngIf="edit || !hide" class="subheading">{{ subheading }}</div>
  </div>
  <div class="multi-subsection-body" [@toggleSection]="hide ? 'off' : 'on'">
    <ng-content></ng-content>
  </div>
</div>
