<ng-container *ngIf="homepageData$ | async as homepageData">
  <snap-subsection
    heading="{{ 'WEBSITE.HOMEPAGE.HEADING' | translate }}"
    [grade]="homepageData.grade"
    [hideGrade]="snapshotService.hideSubGrades$ | async"
    subheading="{{ 'WEBSITE.HOMEPAGE.SUBHEADING' | translate }}"
    [edit]="edit"
    [hideControl]="controls.controls['hideHomepageContent']"
  >
    <ng-container *ngIf="homepageContentTable$ | async as homepageContentTable">
      <ng-container *ngIf="websiteCompetitors$ | async as websiteCompetitors">
        <div class="homepage-content">
          <div class="business-info">
            <ng-container *ngIf="homepageContentTable.address">
              <mat-card appearance="outlined" class="business-info-card">
                <mat-icon class="icon" [ngClass]="homepageContentTable.address.icon">
                  {{ homepageContentTable.address.icon }}
                </mat-icon>
                <span>{{ homepageContentTable.address.title | translate }}</span>
              </mat-card>
            </ng-container>
            <mat-card appearance="outlined" class="business-info-card">
              <mat-icon class="icon" [ngClass]="homepageContentTable.phone.icon">
                {{ homepageContentTable.phone.icon }}
              </mat-icon>
              <span>{{ homepageContentTable.phone.title | translate }}</span>
            </mat-card>
            <ng-container *ngIf="sslCardData$ | async as sslCardData">
              <mat-card appearance="outlined" class="business-info-card">
                <mat-icon class="icon" [ngClass]="sslCardData.icon">
                  {{ sslCardData.icon }}
                </mat-icon>
                <span>{{ sslCardData.title | translate }}</span>
              </mat-card>
            </ng-container>
            <ng-container *ngIf="websiteCompetitors && websiteCompetitors.length > 0">
              <mat-card appearance="outlined" class="business-info-card">
                <mat-icon class="icon" [ngClass]="homepageContentTable.items[0].icon">
                  {{ homepageContentTable.items[0].icon }}
                </mat-icon>
                <span>{{ 'COMMON.SIZE' | translate }}</span>
                <span class="value" [ngClass]="homepageContentTable.items[0].icon">
                  {{ homepageContentTable.items[0].business }}
                </span>
              </mat-card>
            </ng-container>
          </div>

          <div class="table-scroll-wrapper">
            <ng-container *ngIf="media.mobile$ | async; else desktopView">
              <snap-mobile-view-data
                [hideGrades]="true"
                [edit]="false"
                [subsections]="competitorGradeTableData$ | async"
              ></snap-mobile-view-data>
            </ng-container>
            <ng-template #desktopView>
              <snap-table
                [items]="competitorGradeTableData$ | async"
                [showIndustryAverage]="!websiteCompetitors || websiteCompetitors?.length <= 0"
                [linkCompetitors]="true"
              ></snap-table>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </snap-subsection>
</ng-container>
