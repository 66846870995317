<div class="chart-label-container">
  <div class="chart-label">{{ chartTitles.title | translate }}</div>
</div>
<span class="chart-sub">{{ chartTitles.subtitle | translate }}</span>

<!--need to dynamically set the height of the chart so it doesn't default to 150px-->
<div
  class="chart-container"
  #listingChart
  [ngClass]="{
    'one-competitor-height': competitors.length === 1,
    'two-competitor-height': competitors.length === 2
  }"
>
  <canvas
    #chartGraph
    baseChart
    [data]="datasets"
    [options]="config.options"
    [legend]="config.options.plugins.legend"
    [type]="config.type"
  ></canvas>
</div>

<!--Show the chart image, and hide the #listingChart-->
<div
  class="chart-image-container"
  #listingImage
  [ngClass]="{
    'one-competitor-height': competitors.length === 1,
    'two-competitor-height': competitors.length === 2
  }"
>
  <img class="chart-image" #chartImage />
</div>
