import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SnapshotService } from '../snapshot.service';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { MarketplaceServiceInterface, MarketplaceServiceToken, SalesPersonToken } from '../providers/providers';
import { Observable, firstValueFrom } from 'rxjs';
import { SalesPerson } from '@vendasta/snapshot';
import { FooterCtaConfigInterface } from '../section/section';

declare let window: any;

export enum analyticsCategory {
  LISTINGS = 'listings-footer',
  REVIEWS = 'reviews-footer',
  SOCIAL = 'social-footer',
  WEBSITE = 'website-footer',
  ADVERTISING = 'advertising-footer',
  SEO = 'seo-footer',
  ECOMMERCE = 'ecommerce-footer',
  TECHNOLOGY = 'technology-footer',
}

@Component({
  selector: 'snap-section-footer',
  templateUrl: './section-footer.component.html',
  styleUrls: ['./section-footer.component.scss'],
})
export class SectionFooterComponent {
  @Input() edit: boolean;
  @Input() hideControl: UntypedFormControl;
  @Input() messageControl: UntypedFormControl;
  @Input() editButtonFormGroup: UntypedFormGroup;
  @Input() ctaLabel: string;
  @Input() ctaTarget: string;
  @Input() contentId: string;
  @Input() customizedMessage: string;
  @Input() targetProductId: string;
  @Input() analyticsCategory: analyticsCategory;

  @Output() sectionFooterClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sectionFooterEditClick: EventEmitter<FooterCtaConfigInterface> =
    new EventEmitter<FooterCtaConfigInterface>();
  @ViewChild('messageContent', { read: ElementRef }) messageElement: ElementRef;

  editingMessage = false;
  originalMessage = '';
  defaultCTALabel = 'COMMON.GET_IN_TOUCH';
  loading = false;

  constructor(
    @Inject(SalesPersonToken) public readonly salesPerson$: Observable<SalesPerson | undefined>,
    public service: SnapshotService,
    @Inject(MarketplaceServiceToken) private readonly marketplaceService: MarketplaceServiceInterface,
    public analyticsService: ProductAnalyticsService,
  ) {}

  async handleClick(): Promise<void> {
    if (this.ctaTarget) {
      this.analyticsService.trackEvent(this.ctaTarget, this.analyticsCategory, 'click', 0, {
        snapshotId: this.service.snapshotId,
      });
      window.open(this.ctaTarget, '_blank', 'noopener');
    } else if (this.targetProductId) {
      this.loading = true;
      await this.validatePackageAndOpenPublicStore();
      this.loading = false;
    } else {
      this.analyticsService.trackEvent('contact-form', this.analyticsCategory, 'click', 0, {
        snapshotId: this.service.snapshotId,
      });
      this.sectionFooterClick.emit(true);
    }
  }

  editMessage(): void {
    if (this.customizedMessage === '') {
      this.customizedMessage = this.messageElement.nativeElement.textContent;
    }
    this.originalMessage = this.customizedMessage;
    this.editingMessage = true;
  }

  saveMessage(s: string): void {
    this.editingMessage = false;
    this.messageControl.setValue(s);
    this.messageControl.markAsPending();
  }

  cancelMessageEdit(): void {
    this.editingMessage = false;
    this.customizedMessage = this.originalMessage;
  }

  resetMessageToDefault(): void {
    this.customizedMessage = '';
    this.saveMessage('');
  }

  editCTA(): void {
    const value: FooterCtaConfigInterface = {
      targetUrl: this.ctaTarget,
      defaultLabel: this.defaultCTALabel,
      label: this.ctaLabel,
      targetProductId: this.targetProductId,
      formGroup: this.editButtonFormGroup,
    };
    this.sectionFooterEditClick.emit(value);
  }

  private async validatePackageAndOpenPublicStore(): Promise<void> {
    const packages = await firstValueFrom(this.marketplaceService.activatablePackages$);

    if (packages.find((s) => s.packageId === this.targetProductId)) {
      this.analyticsService.trackEvent(this.targetProductId, this.analyticsCategory, 'click', 0, {
        snapshotId: this.service.snapshotId,
      });
      const url = await firstValueFrom(this.marketplaceService.packageUrl$(this.targetProductId));
      window.open(url);
    } else {
      this.analyticsService.trackEvent('contact-form', this.analyticsCategory, 'click', 0, {
        snapshotId: this.service.snapshotId,
      });
      this.sectionFooterClick.emit(true);
    }
  }
}
