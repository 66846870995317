import { inject, Inject, Injectable, InjectionToken } from '@angular/core';
import {
  BrandingInterface,
  BrandingV2Interface,
  BrandingV2Service,
  WhitelabelService as PartnerWhitelabelService,
} from '@galaxy/partner';
import { retryer } from '@vendasta/rx-utils';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { SnapshotLiteService } from '../snapshot-lite/snapshot-lite.service';
import { SnapshotService } from '../snapshot.service';
import { Branding } from './branding';
import { WhitelabelConfiguration } from './whitelabel';

export interface PartnerMarketService {
  partnerId$: Observable<string>;
  marketId$: Observable<string>;
}

function useSnapshotLiteService(href: string): boolean {
  return href.includes('snapshot/lite/') && !location.href.includes('snapshot/lite/sfid');
}
export const partnerMarketServiceToken = new InjectionToken('token for snapshot whitelabeling', {
  providedIn: 'root',
  factory: () => (useSnapshotLiteService(location.href) ? inject(SnapshotLiteService) : inject(SnapshotService)),
});

@Injectable()
export class WhitelabelService {
  public branding$: Observable<Branding>;
  public whitelabel$: Observable<WhitelabelConfiguration>;
  logoUrl$: Observable<string>;
  faviconUrl$: Observable<string>;
  primaryColor$: Observable<string>;
  businessCenterName$: Observable<string>;
  partnerName$: Observable<string>;
  allowClaimUser$: Observable<boolean>;
  mailingCountry$: Observable<string>;
  snapshotReportName$: Observable<string>;

  constructor(
    public _api: PartnerWhitelabelService,
    private brandingV2Service: BrandingV2Service,
    @Inject(partnerMarketServiceToken) private readonly partnerMarketService: PartnerMarketService,
  ) {
    const retryConfig = {
      maxAttempts: 5,
      retryDelay: 100,
      timeoutMilliseconds: 10000,
    };

    const identifiers$ = combineLatest([partnerMarketService.partnerId$, partnerMarketService.marketId$]).pipe(
      map(([partnerId, marketId]) => ({ partnerId: partnerId, marketId: marketId })),
    );

    const partnerBranding$ = identifiers$.pipe(
      switchMap((d: any) => this._api.getBranding(d.partnerId)),
      retryer(retryConfig),
    );

    const marketBranding$ = identifiers$.pipe(
      switchMap((d: any) => this._api.getBranding(d.partnerId, d.marketId)),
      retryer(retryConfig),
    );

    const brandingV2$ = identifiers$.pipe(
      switchMap((d: any) => this.brandingV2Service.getBranding(d.partnerId, d.marketId)),
      retryer(retryConfig),
    );

    this.branding$ = combineLatest([brandingV2$, partnerBranding$, marketBranding$]).pipe(
      map(([brandingV2, partnerBranding, marketBranding]: [BrandingV2Interface, BrandingInterface, any]) => {
        const branding = marketBranding ? marketBranding : partnerBranding;
        return new Branding({
          partnerName: brandingV2.name,
          primaryColor: brandingV2.primaryColor,
          faviconUrl: brandingV2.faviconUrl,
          logoUrl: brandingV2.logoUrl,
          businessCenterName: 'VBC' in branding.apps ? branding.apps['VBC'].name : '',
          reputationManagementName: 'RM' in branding.apps ? branding.apps['RM'].name : '',
        });
      }),
      shareReplay(1),
    );

    this.whitelabel$ = identifiers$.pipe(
      switchMap((ids) => this._api.getConfiguration(ids.partnerId, ids.marketId)),
      retryer(retryConfig),
      map((whitelabel) => {
        return new WhitelabelConfiguration({
          showPpc: whitelabel.snapshotConfiguration ? whitelabel.snapshotConfiguration.ppc : false,
          showRemarketing: whitelabel.snapshotConfiguration ? whitelabel.snapshotConfiguration.remarketing : false,
          useCustomSnapshotHeader: whitelabel.snapshotConfiguration
            ? whitelabel.snapshotConfiguration.useCustomSnapshotHeader
            : false,
          customSnapshotHeader: whitelabel.snapshotConfiguration
            ? whitelabel.snapshotConfiguration.customSnapshotHeader
            : '',
          useCustomSnapshotFooter: whitelabel.snapshotConfiguration
            ? whitelabel.snapshotConfiguration.useCustomSnapshotFooter
            : false,
          customSnapshotFooter: whitelabel.snapshotConfiguration
            ? whitelabel.snapshotConfiguration.customSnapshotFooter
            : '',
          useCustomSnapshotPopupMessage: whitelabel.snapshotConfiguration
            ? whitelabel.snapshotConfiguration.useCustomSnapshotPopupMessage
            : false,
          customSnapshotPopupMessage: whitelabel.snapshotConfiguration
            ? whitelabel.snapshotConfiguration.customSnapshotPopupMessage
            : '',
          allowClaimUser: whitelabel.snapshotConfiguration ? whitelabel.snapshotConfiguration.allowClaimUser : false,
          mailingConfiguration: whitelabel.mailingConfiguration ? whitelabel.mailingConfiguration : {},
          snapshotReportName: whitelabel.snapshotConfiguration
            ? whitelabel.snapshotConfiguration.snapshotReportName
            : '',
          subscriptionTierId: whitelabel.subscriptionTierId,
        });
      }),
      shareReplay(1),
    );
    this.logoUrl$ = this.branding$.pipe(map((b) => b.logoUrl));
    this.faviconUrl$ = this.branding$.pipe(map((b) => b.faviconUrl));
    this.primaryColor$ = this.branding$.pipe(map((b) => '#' + b.primaryColor));
    this.businessCenterName$ = this.branding$.pipe(map((b) => b.businessCenterName));
    this.partnerName$ = this.branding$.pipe(map((b) => b.partnerName));
    this.allowClaimUser$ = this.whitelabel$.pipe(map((b) => b.allowClaimUser));
    this.mailingCountry$ = this.whitelabel$.pipe(map((wl) => wl.mailingConfiguration.mailingCountry));
    this.snapshotReportName$ = this.whitelabel$.pipe(map((wl) => wl.snapshotReportName));
  }
}
