import { SortDirection, SortField } from '@vendasta/account-group';
export { SortDirection, SortField };

export interface SortOptions {
  direction: SortDirection;
  field: SortField;
}

const sortFieldsByName = {
  Created: SortField.Created,
  Modified: SortField.Modified,
  CompanyName: SortField.CompanyName,
};

export function fieldNameToSortField(fieldName: string): SortField {
  return sortFieldsByName[fieldName];
}
