import {
  CompetitionAnalysis,
  GlobalConfigEnabledSection as EnabledSection,
  Grade,
  Section,
  VideoStyle,
} from '@vendasta/snapshot';

export interface UserEvent {
  category: string;
  action: string;
  label: string;
  ctaType?: string;
}

export interface CompetitorInterface {
  name?: string;
  website?: string;
  snapshotId?: string;
}

export interface InferredFieldsInterface {
  website?: string;
}

export interface SnapshotConfigInterface {
  hideGrades?: boolean;
  hideSubsectionGrades?: boolean;
  videoStyle?: VideoStyle;
  hideImages?: boolean;
  enabledSections?: EnabledSection[];
  locale?: string;
  competitors?: CompetitorInterface[];
  hideScheduleMeetingOption?: boolean;
  competitionAnalysis?: CompetitionAnalysis;
  customizedFooterCtaLabel?: string;
  customizedFooterCtaTargetUrl?: string;
  customizedFooterCtaTargetProduct?: string;
}

export interface LanguageConfigInterface {
  locale?: string;
  videoStyle?: VideoStyle;
}

interface SnapshotDataInterface {
  companyName?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  phoneNumbers?: string[];
  website?: string;
  serviceAreaBusiness?: boolean;
  taxonomyId?: string;
  taxonomyName?: string;
  imageUrls?: string[];
  partnerId?: string;
  marketId?: string;
  facebookUrl: string;
  twitterUrl: string;
  instagramUrl: string;
  inferredFields?: InferredFields;
  vcategoryId?: string;
}

interface SnapshotInterface {
  snapshotId: string;
  businessId: string;
  config: SnapshotConfigInterface;
  data: SnapshotDataInterface;
  created: Date;
  expired: Date;
  isPartnerConfig?: boolean;
}

export class Competitor {
  name: string;
  website?: string;
  snapshotId?: string;

  constructor(c?: CompetitorInterface) {
    this.name = c?.name || '';
    this.website = c?.website || '';
    this.snapshotId = c?.snapshotId || '';
  }
}

export class SnapshotConfig {
  hideGrades: boolean;
  hideSubsectionGrades: boolean;
  videoStyle: VideoStyle;
  hideImages: boolean;
  enabledSections: EnabledSection[];
  locale: string;
  competitors: Competitor[];
  hideScheduleMeetingOption: boolean;
  competitionAnalysis: CompetitionAnalysis;
  customizedFooterCtaLabel: string;
  customizedFooterCtaTargetUrl: string;
  customizedFooterCtaTargetProduct: string;

  constructor(c?: SnapshotConfigInterface) {
    this.hideGrades = !!c?.hideGrades;
    this.hideSubsectionGrades = !!c?.hideSubsectionGrades;
    this.videoStyle = c?.videoStyle || VideoStyle.NO_VIDEOS;
    this.hideImages = !!c?.hideImages;
    this.enabledSections = c?.enabledSections || [];
    this.locale = c?.locale || 'en-us';
    this.competitors = c?.competitors ? c?.competitors.map((comp) => new Competitor(comp)) : [];
    this.hideScheduleMeetingOption = !!c?.hideScheduleMeetingOption;
    this.competitionAnalysis = c?.competitionAnalysis || CompetitionAnalysis.UNSET;
    this.customizedFooterCtaLabel = c?.customizedFooterCtaLabel ?? '';
    this.customizedFooterCtaTargetUrl = c?.customizedFooterCtaTargetUrl ?? '';
    this.customizedFooterCtaTargetProduct = c?.customizedFooterCtaTargetProduct ?? '';
  }
}

export interface InferredFieldsInterface {
  website?: string;
}

export class InferredFields {
  website?: string;

  constructor(i: InferredFieldsInterface) {
    this.website = i.website || '';
  }
}

export class SnapshotData {
  companyName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phoneNumbers: string[];
  website: string;
  serviceAreaBusiness: boolean;
  taxonomyId: string;
  taxonomyName: string;
  imageUrls: string[];
  partnerId: string;
  marketId: string;
  facebookUrl: string;
  twitterUrl: string;
  instagramUrl: string;
  inferredFields?: InferredFields;
  vcategoryId: string;

  constructor(d?: SnapshotDataInterface) {
    this.companyName = d?.companyName || '';
    this.address = d?.address || '';
    this.city = d?.city || '';
    this.state = d?.state || '';
    this.zip = d?.zip || '';
    this.country = d?.country || '';
    this.phoneNumbers = d?.phoneNumbers || [];
    this.website = d?.website || '';
    this.serviceAreaBusiness = d?.serviceAreaBusiness || false;
    this.taxonomyId = d?.taxonomyId || '';
    this.taxonomyName = d?.taxonomyName || '';
    this.imageUrls = d?.imageUrls || [];
    this.partnerId = d?.partnerId || '';
    this.marketId = d?.marketId || '';
    this.facebookUrl = d?.facebookUrl || '';
    this.twitterUrl = d?.twitterUrl || '';
    this.instagramUrl = d?.instagramUrl || '';
    this.inferredFields = new InferredFields(d?.inferredFields || {});
    this.vcategoryId = d?.vcategoryId || '';
  }

  splitTaxonomies(): string[] {
    const built: string[] = [];
    const split = this.taxonomyId.split(':');
    split.forEach((value, index) => {
      let full = split[0];
      for (let i = 1; i <= index; i++) {
        full = full + ':' + split[i];
      }
      built.push(full);
    });
    return built;
  }
}

export class SnapshotReport {
  snapshotId: string;
  businessId: string;
  created: Date;
  expired: Date;
  config: SnapshotConfig;
  data: SnapshotData;
  isPartnerConfig: boolean;

  constructor(s: SnapshotInterface) {
    this.snapshotId = s.snapshotId;
    this.businessId = s.businessId;
    this.created = s.created;
    this.expired = s.expired;
    this.config = new SnapshotConfig(s.config);
    this.data = new SnapshotData(s.data);
    this.isPartnerConfig = !!s.isPartnerConfig;
  }
}

interface SnapshotSummaryInterface {
  snapshotId?: string;
  businessId?: string;
  sections?: SectionSummary[];
}

export class SnapshotSummary {
  snapshotId?: string;
  businessId?: string;
  sections?: SectionSummary[];

  constructor(s: SnapshotSummaryInterface) {
    this.snapshotId = s.snapshotId || '';
    this.businessId = s.businessId || '';
    this.sections = s.sections ? s.sections.map((ss) => new SectionSummary(ss)) : [];
  }
}

interface SectionSummaryInterface {
  sectionName?: string;
  grade?: Grade;
  sectionId?: Section;
}

export class SectionSummary {
  sectionName?: string;
  grade?: Grade;
  sectionId?: Section;

  constructor(ss: SectionSummaryInterface) {
    this.sectionName = ss.sectionName || '';
    this.grade = ss.grade || Grade.NO_GRADE;
    this.sectionId = ss.sectionId || Section.REVIEWS;
  }
}
