import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SocialItemData } from './social-item-data';

@Component({
  selector: 'snap-social-table',
  templateUrl: './social-table.component.html',
  styleUrls: ['./social-table.component.scss'],
})
export class SocialTableComponent implements OnChanges {
  @Input() socialItems: SocialItemData[];
  @Input() edit: boolean;
  @Input() createdAt: Date;

  enabledSocialItems: SocialItemData[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.socialItems?.currentValue) {
      this.enabledSocialItems = this.socialItems.filter((i) => !!i.control && !i.control?.value);
    }
  }
}
