import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '@galaxy/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class HasSessionAuthGuardService {
  constructor(private router: Router, private session: SessionService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.session.getSessionId().pipe(
      map((sessionId) => !!sessionId),
      tap((canActivate) => {
        if (!canActivate) {
          window.location.href = '/';
        }
      }),
    );
  }
}
