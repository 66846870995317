import { SampleSourceCountInterface, ReviewItemInterface, ReviewItem } from './review-item';
import { ReviewCompetitor, ReviewCompetitorInterface } from './review-competitor';

interface ReviewDataInterface {
  reviewsFound: ReviewItemInterface;
  reviewsFoundPerMonth: ReviewItemInterface;
  averageReviewScore: ReviewItemInterface;
  numberOfReviewSources: ReviewItemInterface;
  sampleSourceCounts?: SampleSourceCountInterface[];
  competitors?: ReviewCompetitorInterface[];
}

export class ReviewData {
  reviewsFound: ReviewItem;
  reviewsFoundPerMonth: ReviewItem;
  averageReviewScore: ReviewItem;
  numberOfReviewSources: ReviewItem;
  competitors: ReviewCompetitor[];

  constructor(data: ReviewDataInterface) {
    this.reviewsFound = new ReviewItem(data.reviewsFound, data.sampleSourceCounts);
    this.reviewsFoundPerMonth = new ReviewItem(data.reviewsFoundPerMonth);
    this.averageReviewScore = new ReviewItem(data.averageReviewScore);
    this.numberOfReviewSources = new ReviewItem(data.numberOfReviewSources);
    this.competitors = (data.competitors || []).map((c) => {
      return new ReviewCompetitor(c);
    });
  }
}
