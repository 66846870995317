<div class="social-presence">
  <ng-container *ngIf="edit; else viewMode">
    <div *ngFor="let item of socialItems">
      <snap-social-item [socialItem]="item" [edit]="edit" [createdAt]="createdAt"></snap-social-item>
    </div>
  </ng-container>

  <!-- Using enabledSocialItems is a workaround to avoid displaying empty columns -->
  <ng-template #viewMode>
    <div *ngFor="let item of enabledSocialItems">
      <snap-social-item [socialItem]="item" [edit]="edit" [createdAt]="createdAt"></snap-social-item>
    </div>
  </ng-template>
</div>
