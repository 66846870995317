<snap-toggled-item
  *ngFor="let subsection of subsections; let last = last"
  [edit]="edit"
  [hideControl]="subsection.control"
  [attr.data-testid]="subsection.datatestid || ''"
>
  <div data-testid="subsection" class="data-container">
    <div class="title-row">
      <div class="text-and-grades">
        <snap-grade *ngIf="!hideGrades" [grade]="subsection.data?.grade" size="extra-small"></snap-grade>
        <span class="title-text">
          {{ subsection.title | translate }}
        </span>
      </div>
      <ng-template snapItemToggle></ng-template>
    </div>
    <div class="row" *ngIf="subsection.data">
      <ng-container [ngSwitch]="subsection.labelFormat">
        <span *ngSwitchDefault data-testid="business-title">
          {{ 'COMMON.YOUR_BUSINESS' | translate }}
        </span>
        <span *ngSwitchCase="'link'" class="your-business-chip">
          <a href="http://{{ subsection.data?.link | httpStrip }}">
            {{ subsection.data?.link | httpCondense }}
          </a>
          <span class="business-chip">{{ 'COMMON.YOU' | translate }}</span>
        </span>
      </ng-container>
      <ng-container *ngIf="subsection.data.business !== null; else notFound">
        <ng-container *ngIf="subsection.data.hasColor; else noColor">
          <div class="data-padding">
            <div
              class="data"
              [ngClass]="getGradeClass(subsection.data?.grade)"
              *ngIf="!subsection.dataFormat"
              data-testid="business-value"
            >
              {{ subsection.data?.business | metricNotation }}
            </div>
            <div
              class="data"
              [ngClass]="getGradeClass(subsection.data?.grade)"
              *ngIf="subsection.dataFormat === 'currency'"
              data-testid="business-value"
            >
              {{ subsection.data?.business | currency : 'USD' : 'symbol' : '1.0-0' }}
            </div>
          </div>
        </ng-container>
        <ng-template #noColor>
          <div class="data-padding">
            <div class="data" *ngIf="!subsection.dataFormat" data-testid="business-value">
              {{ subsection.data?.business | metricNotation }}
            </div>
            <div class="data" *ngIf="subsection.dataFormat === 'currency'" data-testid="business-value">
              {{ subsection.data?.business | currency : 'USD' : 'symbol' : '1.0-0' }}
            </div>
            <div class="data" *ngIf="subsection.dataFormat === 'bar'">
              <mat-progress-bar mode="determinate" [value]="subsection.data.business"></mat-progress-bar>
            </div>
            <mat-icon
              class="data"
              *ngIf="subsection.dataFormat === 'check'"
              [ngClass]="!!subsection.data?.business ? 'green-icon' : 'red-icon'"
            >
              {{ subsection.data?.business ? 'check' : 'close' }}
            </mat-icon>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <!--      Competitors-->
    <ng-container *ngIf="subsection.competitors?.length > 0; else industryData">
      <div *ngFor="let competitor of subsection.competitors" class="row">
        <ng-container [ngSwitch]="subsection.labelFormat">
          <span *ngSwitchDefault data-testid="competitor-title">
            {{ competitor.name }}
          </span>
          <span *ngSwitchCase="'link'">
            <a href="http://{{ competitor.name | httpStrip }}">
              {{ competitor.name | httpCondense }}
            </a>
          </span>
        </ng-container>
        <ng-container *ngIf="competitor.value !== null; else notFound">
          <div class="data-padding">
            <div class="data" *ngIf="!subsection.dataFormat">
              {{ competitor.value | metricNotation }}
            </div>
            <div class="data" *ngIf="subsection.dataFormat === 'currency'">
              {{ competitor.value | currency : 'USD' : 'symbol' : '1.0-0' }}
            </div>
            <div class="data" *ngIf="subsection.dataFormat === 'bar'">
              <mat-progress-bar mode="determinate" [value]="competitor.value"></mat-progress-bar>
            </div>
            <mat-icon
              class="data"
              *ngIf="subsection.dataFormat === 'check'"
              [ngClass]="!!competitor.value ? 'green-icon' : 'red-icon'"
            >
              {{ competitor.value ? 'check' : 'close' }}
            </mat-icon>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #industryData>
      <div *ngIf="isDataAvailable(subsection.data?.industryAverage)" class="row">
        <span data-testid="industry-average-title">
          {{ 'COMMON.INDUSTRY_AVERAGE' | translate }}
        </span>
        <div class="data-padding">
          <div class="data" data-testid="industry-average-value">
            {{ subsection.data?.industryAverage | metricNotation }}
          </div>
        </div>
      </div>
      <div *ngIf="isDataAvailable(subsection.data?.industryLeader)" class="row">
        <span data-testid="industry-leader-title">
          {{ 'COMMON.INDUSTRY_LEADER' | translate }}
        </span>
        <div class="data-padding">
          <div class="data" data-testid="industry-leader-value">
            {{ subsection.data?.industryLeader | metricNotation }}
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <mat-divider *ngIf="!last"></mat-divider>
</snap-toggled-item>
<ng-template #notFound>
  <div class="not-found">{{ 'COMMON.NOT_FOUND' | translate }}</div>
</ng-template>
