interface BrandingInterface {
  primaryColor?: string;
  faviconUrl: string;
  logoUrl: string;
  partnerName: string;
  businessCenterName?: string;
  reputationManagementName?: string;
}

export class Branding {
  primaryColor: string;
  faviconUrl: string;
  logoUrl: string;
  businessCenterName: string;
  reputationManagementName: string;
  partnerName: string;

  constructor(d: BrandingInterface) {
    this.primaryColor = d.primaryColor || '';
    this.partnerName = d.partnerName;
    this.faviconUrl = d.faviconUrl;
    this.logoUrl = d.logoUrl;
    this.businessCenterName = d.businessCenterName || 'Business Center';
    this.reputationManagementName = d.reputationManagementName || 'Reputation Management';
  }
}
