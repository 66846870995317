<ng-container *ngIf="organicKeywordMetricsData?.keywords?.length > 0; else noKeywordsTemplate">
  <ng-container *ngIf="media.mobile$ | async; else desktopRanking">
    <snap-mobile-view-data [hideGrades]="true" [subsections]="mobileRankingItems"></snap-mobile-view-data>
  </ng-container>
  <ng-template #desktopRanking>
    <div class="mat-table">
      <div class="mat-header-row">
        <div class="mat-header-cell width-30 flex align-start-stretch">
          <div
            class="title flex align-start-center"
            [glxyTooltip]="'LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.TOOLTIPS.KEYWORDS' | translate"
          >
            <span>{{ 'LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.RANKED_KEYWORDS' | translate }}</span>
            <mat-icon>help_outline</mat-icon>
          </div>
        </div>
        <div class="mat-header-cell overlap hide-to-print width-15 flex hide-xs">
          <div
            class="title flex align-start-center"
            [glxyTooltip]="'LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.TOOLTIPS.COMPETITIVENESS' | translate"
          >
            <span>{{ 'COMMON.COMPETITIVENESS' | translate }}</span>
            <mat-icon>help_outline</mat-icon>
          </div>
        </div>
        <div class="mat-header-cell rank width-15 flex align-center">
          <div
            class="title flex align-start-center"
            [glxyTooltip]="'LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.TOOLTIPS.RANK' | translate"
          >
            <span>{{ 'COMMON.RANK' | translate }}</span>
            <mat-icon>help_outline</mat-icon>
          </div>
        </div>
        <div class="mat-header-cell monthlyClicks width-20 flex align-center">
          <div
            class="title flex align-start-center"
            [glxyTooltip]="'LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.TOOLTIPS.MONTHLY_LOCAL_SEARCHES' | translate"
          >
            <!-- show monthly clicks header if true else show monthly local search volume header -->
            <span>
              {{
                showMonthlyClicks
                  ? ('LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.CLICKS' | translate)
                  : ('LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.LOCAL_SEARCHES' | translate)
              }}
            </span>
            <mat-icon>help_outline</mat-icon>
          </div>
        </div>
        <div class="mat-header-cell monthlySearches width-20 flex align-center">
          <div
            class="title flex align-start-center"
            [glxyTooltip]="'LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.TOOLTIPS.MONTHLY_SEARCHES' | translate"
          >
            <span>
              {{ 'LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.GLOBAL_SEARCHES' | translate }}
            </span>
            <mat-icon>help_outline</mat-icon>
          </div>
        </div>
      </div>
      <div class="mat-row" *ngFor="let keyword of organicKeywordMetricsData?.uniqueKeywords">
        <div class="mat-cell keyword width-30 flex align-start-stretch">
          {{ keyword?.term }}
        </div>
        <div class="mat-cell overlap hide-to-print width-15 flex hide-xs">
          <mat-progress-bar mode="determinate" [value]="keyword?.difficulty"></mat-progress-bar>
        </div>
        <div class="mat-cell rank width-15 flex align-center">
          {{ keyword?.position | metricNotation }}
        </div>
        <div class="mat-cell monthlyClicks width-20 flex align-center">
          <!-- show monthly clicks if true else show monthly local search volume -->
          {{ (showMonthlyClicks ? keyword?.monthlyClicks : keyword?.monthlyLocalSearchVolume) | metricNotation }}
        </div>
        <div class="mat-cell monthlySearches width-20 flex align-center">
          {{ keyword?.monthlySearchVolume | metricNotation }}
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #noKeywordsTemplate>
  <div class="not-found">
    <glxy-empty-state [size]="'small'" [widthConstraint]="false">
      <glxy-empty-state-hero>
        <mat-icon>travel_explore</mat-icon>
      </glxy-empty-state-hero>
      <glxy-empty-state-title>
        {{ 'LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.NOT_AVAILABLE_TITLE' | translate }}
      </glxy-empty-state-title>
      <p>
        {{ 'LOCAL_SEO.ORGANIC_KEYWORDS.RANKING.NOT_AVAILABLE_MESSAGE' | translate }}
      </p>
    </glxy-empty-state>
  </div>
</ng-template>
