import { Injectable } from '@angular/core';
import {
  CategoriesApiService,
  Category,
  CategorySearchResponse,
  CategoryType,
  PagedRequestOptionsInterface,
  SortOptionsInterface,
} from '@vendasta/category';
import { Observable } from 'rxjs';
import { CategoryRequest } from '@vendasta/category';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BusinessCategoryService {
  constructor(private readonly categoryService: CategoriesApiService) {}

  getCategoryMapping(externalId: string, inputType: CategoryType, outputType: CategoryType): Observable<Category> {
    return this.categoryService
      .getCategoryMapping({ externalId, inputType, outputType })
      .pipe(map((res) => res?.category));
  }

  getCategoryByExternalIDsAndType(categories: CategoryRequest[], locale?: string): Observable<Category[]> {
    return this.categoryService
      .getCategoryByExternalIDsAndType({ categories, locale })
      .pipe(map((res) => res?.category));
  }

  getCategorySearchByTerm(
    searchTerm: string,
    type: CategoryType,
    pagingOptions?: PagedRequestOptionsInterface,
    sortOptions?: SortOptionsInterface[],
  ): Observable<CategorySearchResponse> {
    return this.categoryService.getCategoryBySearchTerm({ searchTerm, type, pagingOptions, sortOptions });
  }
}
