import { SnapshotLanguages } from './language';

export const HowTheGradeIsCalculated = `
  Here's how the grade in the report is calculated: 
  Listing: The Listings grade is a reflection of your business's online listings. Each listing source is assigned a score based on how popular the site is. For example, having an accurate listing on a popular site like Google will have a greater influence on your Listing Score. The Listings grade is determined by the percentile range your business falls into when compared to other businesses in the same industry.
  Reviews: Review grades are a reflection of your business's online review performance. Each grade in the Reviews section is determined by the percentile range your business falls into when compared to other businesses in the same industry. To determine your overall Review grade, the individual sections are first converted into numerical scores. This is done using the scale below:
  A = 4 | B = 3 | C = 2 | D = 1 | F = 0
  We divide the sum of these scores by the number of individual grades within the Reviews section. Finally, we convert that score back into a letter grade.
  Social: Social grades are a reflection of your business's social media presence. Each grade in the Social section is determined by the percentile range your business falls into when compared to other businesses in the same industry.
  To determine your overall Social grade, the individual sections are first converted into numerical scores. This is done using the scale below:
  A = 4 | B = 3 | C = 2 | D = 1 | F = 0
  We divide the sum of these scores by the number of individual grades within the Social section. Finally, we convert that score back into a letter grade.
  Website: Website grades are a reflection of your business's website performance, based on Google's PageSpeed Insights. Each grade in the Website section is determined by the percentile range your business falls into when compared to other businesses in the same industry.
  To determine your overall Website grade, the individual sections are first converted into numerical scores. This is done using the scale below:
  A = 4 | B = 3 | C = 2 | D = 1 | F = 0
  We divide the sum of these scores by the number of individual grades within the Website section. Finally, we convert that score back into a letter grade.
  The Ecommerce grade is an indication of how well your business is optimized for online transactions. This grade is weighted to match the insights of sales professionals, giving you a score that helps you focus on the areas that matter most. These areas are weighted as follows:
  Online storefront = high
  Online payments = medium
  Lead engagement = low
  Your business is then ranked against the industry to determine your overall grade.
  Advertising: The Advertising grade is a reflection of your business's online campaign performance. The grade is determined by the percentile range your campaign's estimated cost per click falls into when compared to other businesses in the same industry. Estimated Cost Per Click = Estimated Monthly Ad Budget / Estimated Monthly Paid Clicks
  SEO: The Search Engine Optimization (SEO) grade is a reflection of your business's search engine visibility and performance. The Local Search Results grade is calculated by adding the score of your ranking for a single keyword, based on nine local searches around your business location, and a search of the entire city.
  The Organic Keyword Performance grade is determined by the percentile range your keywords' estimated value per click falls into when compared to other businesses in the same industry using the following scale.
  Estimated Value Per Click = Estimated Monthly Value of Clicks / Estimated Monthly Clicks
  To determine your overall SEO grade, the individual sections are first converted into numerical scores. This is done using the scale below: A = 4 | B = 3 | C = 2 | D = 1 | F = 0 Then we divide the sum of these scores by the number of individual grades within the SEO section. Finally, we convert that score back into a letter grade.
`;

/** Convert a boolean value to `Found` or `Not Found` string */
export function bool2found(v?: boolean): string {
  return v ? 'Found' : 'Not Found';
}

/**
 * AI prompt to translate the message to the language based on the locale.
 * If the resulting language is English, then return an empty string.
 */
export function promptTranslation(locale: string): string {
  const english = 'english';
  const language = SnapshotLanguages.find((l) => l.code === locale)?.name || english;
  // avoid prompting to translate to English
  if (language.toLowerCase() === english) {
    return '';
  }
  return `The result output should be in ${language}.`;
}
