@if (!useNewProfileDropdown()) {
  <atlas-item interactable>
    <span class="atlas-navbar__user-dropdown">
      <ng-container *ngIf="!impersonateeUsername">
        <span class="atlas-navbar__user-content-wrapper">
          {{ username }}
          <i class="material-icons">arrow_drop_down</i>
        </span>
      </ng-container>
      <ng-container *ngIf="!!impersonateeUsername">
        <span class="atlas-navbar__user-content-wrapper">
          <div>
            <div class="atlas-navbar__user-content-wrapper-username">{{ username }} as</div>
            <div class="atlas-navbar__user-content-wrapper-impersonatee-username">
              {{ impersonateeUsername }}
            </div>
          </div>
          <i class="material-icons">arrow_drop_down</i>
        </span>
      </ng-container>
    </span>
    <span class="atlas-navbar__user-dropdown--mobile">
      <span class="atlas-navbar__user-content-wrapper">
        <ng-container *ngIf="!impersonateeUsername">
          <i class="material-icons">person</i>
        </ng-container>
        <ng-container *ngIf="!!impersonateeUsername">
          <i class="material-icons">people_alt</i>
        </ng-container>
      </span>
    </span>
    <atlas-menu id="atlas-navbar__user-dropdown">
      <div class="atlas-navbar__menu">
        <div class="atlas-navbar__menu__static" (click)="intercept($event)">
          <div
            class="atlas-navbar__user-info"
            *ngIf="!!email"
            [ngClass]="{
              'atlas-navbar__user-info--impersonation': !!impersonateeUsername,
            }"
          >
            <div>
              {{ 'DROPDOWN_MENU.SIGNED_IN_AS' | translate }}
            </div>
            <div class="atlas-navbar__user-email">
              {{ email }}
            </div>
          </div>
          <ng-container *ngIf="!!impersonateeUsername">
            <div class="atlas-navbar__user-info__username" *ngIf="!!email">
              <div>
                {{ username }}
                {{ 'DROPDOWN_MENU.IMPERSONATING_USER_AS' | translate }}
              </div>
              <div class="atlas-navbar__user-info__impersonatee">
                {{ impersonateeUsername }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!!userSwitcherData">
            <div class="atlas-navbar__menu-divider"></div>
            <div class="atlas-navbar__user-switcher">
              <div class="atlas-navbar__user-switcher-partner-name">{{ partnerName }}</div>
              <a (click)="openUserSwitcher()">{{ 'DROPDOWN_MENU.SWITCH_USER' | translate }}</a>
            </div>
          </ng-container>
          <div class="atlas-navbar__menu-divider" *ngIf="!!email"></div>
          <div *ngIf="showLanguageSelector()">
            <atlas-menu-item (click)="showSwitchLanguageDialog()">
              <span class="atlas-navbar__menu__language-selector__label">
                <i class="material-icons" *ngIf="!(currentLanguage$ | async)?.flag">language</i>
                <span class="atlas-navbar__menu__language-selector__flag" *ngIf="(currentLanguage$ | async)?.flag">
                  {{ (currentLanguage$ | async)?.flag }}
                </span>
                {{ languageLabel$ | async }}
              </span>
            </atlas-menu-item>
          </div>
        </div>
        <ng-container *ngIf="extraMenuItems()?.length > 0">
          <ng-container *ngFor="let menuItem of extraMenuItems()">
            <atlas-menu-item
              *ngIf="!menuItem.callback && !menuItem.eventId"
              [label]="menuItem.label"
              [url]="menuItem.url"
              [route]="menuItem.route"
            ></atlas-menu-item>
            <atlas-menu-item
              *ngIf="!!menuItem.callback || !!menuItem.eventId"
              [label]="menuItem.label"
              (click)="itemClick(menuItem)"
            ></atlas-menu-item>
          </ng-container>
        </ng-container>
        <atlas-menu-item
          *ngFor="let menuItem of menuItems()"
          [label]="menuItem.text"
          [url]="menuItem.url"
        ></atlas-menu-item>
        <atlas-menu-item *ngIf="!!signOutUrl" [label]="'Sign out'" [url]="signOutUrl"></atlas-menu-item>
      </div>
    </atlas-menu>
  </atlas-item>
}

@if (!!useNewProfileDropdown()) {
  <div cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <button mat-icon-button (click)="open() === true ? open.set(false) : open.set(true)" cdkOverlayOrigin>
      <glxy-avatar matListItemAvatar [src]="avatarButtonUrl()" [name]="username" [width]="24" />
    </button>
  </div>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="open()"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayPanelClass]="['atlas-navbar__user-dropdown']"
    (backdropClick)="open.set(false)"
  >
    <div class="useDropdownPortal mat-elevation-z8">
      <div>
        <div
          class="atlas-navbar__user-info"
          *ngIf="!!email"
          [ngClass]="{
            'atlas-navbar__user-info--impersonation': !!impersonateeUsername,
          }"
        >
          <div>
            {{ 'DROPDOWN_MENU.SIGNED_IN_AS' | translate }}
          </div>
          <div class="atlas-navbar__user-email">
            {{ email }}
          </div>
        </div>
        <ng-container *ngIf="!!impersonateeUsername">
          <div class="atlas-navbar__user-info__username" *ngIf="!!email">
            <div>
              {{ username }}
              {{ 'DROPDOWN_MENU.IMPERSONATING_USER_AS' | translate }}
            </div>
            <div class="atlas-navbar__user-info__impersonatee">
              {{ impersonateeUsername }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!!userSwitcherData">
          <mat-divider></mat-divider>
          <div class="atlas-navbar__user-switcher">
            <div class="atlas-navbar__user-switcher-partner-name">{{ partnerName }}</div>
            <a (click)="openUserSwitcher()">{{ 'DROPDOWN_MENU.SWITCH_USER' | translate }}</a>
          </div>
        </ng-container>
        <mat-divider></mat-divider>
        {{ (currentLanguage$ | async)?.flag }}
        <mat-action-list>
          <div *ngIf="showLanguageSelector()">
            <mat-list-item (click)="showSwitchLanguageDialog()">
              <mat-icon matListItemIcon *ngIf="!(currentLanguage$ | async)?.flag">language</mat-icon>
              <img
                matListItemAvatar
                *ngIf="(currentLanguage$ | async)?.flag"
                src="{{ (currentLanguage$ | async)?.flag }}"
              />

              <p matListItemTitle>
                {{ languageLabel$ | async }}
              </p>
            </mat-list-item>
          </div>
          @if ((dataService.theme$ | async) === UITheme.UI_THEME_USER_PREFERENCE) {
            <atlas-theme-switcher></atlas-theme-switcher>
          }
          @for (menuItem of extraMenuItems() || []; track $index) {
            @if (!menuItem.callback && !menuItem.eventId) {
              @if (menuItem.url) {
                <a mat-list-item [href]="menuItem.url">{{ menuItem.label | translate }}</a>
              } @else {
                <a mat-list-item [routerLink]="menuItem.route">{{ menuItem.label | translate }}</a>
              }
            } @else if (!!menuItem.callback || !!menuItem.eventId) {
              <button mat-list-item (click)="itemClick(menuItem)">{{ menuItem.label | translate }}</button>
            }
          }
          @for (menuItem of menuItems(); track $index) {
            <a mat-list-item [href]="menuItem.url">{{ menuItem.text }}</a>
          }
          @if (!!signOutUrl) {
            <a mat-list-item [href]="signOutUrl">Sign out</a>
          }
        </mat-action-list>
      </div>
    </div>
  </ng-template>
}
