import { NgModule } from '@angular/core';
import { LogoutComponent } from './logout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [BrowserAnimationsModule],
  providers: [],
  declarations: [LogoutComponent],
  exports: [LogoutComponent],
})
export class LogoutModule {}
