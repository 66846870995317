<div class="row" *ngIf="!!dataProviderAccuracy">
  <ng-container *ngIf="!!visibleDataProviderSources">
    <div class="col col-xs-12 col-md-4" *ngIf="visibleDataProviderSources.showInfogroup">
      <snap-listing-distribution-status
        [status]="dataProviderAccuracy?.infogroupStatus"
        source="infogroup"
      ></snap-listing-distribution-status>
    </div>

    <div class="col col-xs-12 col-md-4" *ngIf="visibleDataProviderSources.showNeustar">
      <snap-listing-distribution-status
        [status]="dataProviderAccuracy?.neustarStatus"
        source="localeze"
      ></snap-listing-distribution-status>
    </div>

    <ng-container *ngIf="visibleDataProviderSources.showFoursquare">
      <div class="col col-xs-12 col-md-4">
        <snap-listing-distribution-status
          [status]="dataProviderAccuracy?.foursquareStatus"
          source="foursquare"
        ></snap-listing-distribution-status>
      </div>
    </ng-container>
    <ng-container *ngIf="visibleDataProviderSources.showFactual">
      <div class="col col-xs-12 col-md-4">
        <snap-listing-distribution-status
          [status]="dataProviderAccuracy?.factualStatus"
          source="factual"
        ></snap-listing-distribution-status>
      </div>
    </ng-container>
  </ng-container>
</div>
