import { LatLng, Results, SERPMetrics } from '../seo-data';

export interface BusinessPerformance {
  ranking: string;
  color: string;
}

export interface MapMarker {
  label: google.maps.MarkerLabel;
  lat: string;
  lng: string;
  icon: google.maps.Symbol;
}

export const SEOPerformance = {
  TOP: { ranking: 'top', color: '#4CAF50' },
  AVERAGE: { ranking: 'average', color: '#FFB300' },
  BOTTOM: { ranking: 'bottom', color: '#C62828' },
  UNKNOWN: { ranking: 'unknown', color: '#C62828' },
};

export interface VicinitySearch {
  data: SERPMetrics;
  searchTerm: string;
  centerMap: LatLng;
  mainBusiness: Results;
  mainBusinessPerformance: BusinessPerformance;
}
