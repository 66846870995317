import { Component, Inject, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SectionServiceInterfaceToken } from '../section/section.service';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { EcommerceService } from './ecommerce.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseSectionComponent } from '../section/base-section.component';
import { analyticsCategory } from '../section-footer/section-footer.component';
import { SnapshotService } from '../snapshot.service';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { EcommerceData } from './ecommerce.data';

export interface EcommerceSectionItems {
  attribute?: string;
  numberOfSolutions?: number;
  name?: string;
  version?: string;
  recommendation?: string;
  control?: AbstractControl;
  available: boolean;
  datatestid?: string;
}

@Component({
  selector: 'snap-ecommerce-section',
  templateUrl: './ecommerce-section.component.html',
  styleUrls: ['./ecommerce-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: EcommerceService,
    },
  ],
})
export class EcommerceSectionComponent extends BaseSectionComponent implements OnInit {
  @Input() isPartnerConfig: boolean;

  hasWebsite$: Observable<boolean> = new Observable<boolean>();
  footerCategory = analyticsCategory.ECOMMERCE;

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: EcommerceService,
    public translate: TranslateService,
    public snapshotService: SnapshotService,
    public snackbarService: SnackbarService,
  ) {
    super(service, snackbarService, translate);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.hasWebsite$ = combineLatest([this.service.data$, this.snapshotService.snapshotData$]).pipe(
      map(([ed, sd]) => {
        if (sd.website || ((this.hasValidEcommerceData(ed) || ed.website) && sd.inferredFields?.website)) {
          return true;
        }
        return false;
      }),
    );
  }

  hasValidEcommerceData(ed: EcommerceData): boolean {
    return (
      ed.adRetargeting.length > 0 ||
      ed.competitors.length > 0 ||
      ed.ecommerceSolution.length > 0 ||
      ed.onlinePayments.length > 0 ||
      ed.appointmentScheduling.length > 0
    );
  }
}
