import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { Observable } from 'rxjs';
import { BaseSectionComponent } from '../../section/base-section.component';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { SnapshotService } from '../../snapshot.service';
import { AdvertisingService } from '../advertising.service';
import { ChartData } from '../adwords-bar-chart';

@Component({
  selector: 'snap-advertising-adwords-subsection',
  templateUrl: './advertising-adwords-subsection.component.html',
  styleUrls: ['../advertising-section.component.scss', '../general.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: AdvertisingService,
    },
  ],
})
export class AdvertisingAdwordsSubsectionComponent extends BaseSectionComponent implements OnInit, AfterViewChecked {
  impressions$: Observable<ChartData> = new Observable<ChartData>();
  clicks$: Observable<ChartData> = new Observable<ChartData>();
  possibleImpressions$: Observable<number> = new Observable<number>();
  possibleClicks$: Observable<number> = new Observable<number>();

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: AdvertisingService,
    public snapshotService: SnapshotService,
    private cdRef: ChangeDetectorRef,
    public translateService: TranslateService,
    public snackbarService: SnackbarService,
  ) {
    super(service, snackbarService, translateService);
  }

  adwordsNotVisibleMessage(country: string, website: string): string {
    if (country !== 'US') {
      return 'ADVERTISING.ADWORDS.NOT_US';
    }
    if (website === '') {
      return 'ADVERTISING.ADWORDS.NO_WEBSITE';
    }
    return 'ADVERTISING.ADWORDS.DEFAULT_MESSAGE';
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.impressions$ = this.service.impressionsChartData$;
    this.possibleImpressions$ = this.service.possibleImpressions$;

    this.clicks$ = this.service.clicks$;
    this.possibleClicks$ = this.service.possibleClicks$;
  }
}
