import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  getSrc(imageName: string): string {
    return `${this.getHost()}/${imageName}`;
  }

  getFlagSrc(imageName: string): string {
    return `${this.getHost()}/flags/${imageName}`;
  }

  getIconSrc(imageName: string): string {
    return `${this.getHost()}/icons/${imageName}`;
  }

  getTechnologySrc(imageName: string): string {
    return `${this.getHost()}/technology-icons/${imageName}`;
  }

  private getHost(): string {
    if (window.location.hostname === 'localhost') {
      return '/assets/images';
    }
    return 'https://vstatic-prod.apigateway.co/snapshot-client.9223372035128602063.prod/assets/images';
  }
}
