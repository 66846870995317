<button mat-icon-button [matMenuTriggerFor]="languageSelector">
  <mat-icon>language</mat-icon>
  {{ snapshotService.locale$ | async | uppercase | slice : 0 : 2 }}
</button>
<mat-menu #languageSelector="matMenu" yPosition="below" [overlapTrigger]="false" class="language-selector__menu">
  <button *ngFor="let language of languageOptions" mat-menu-item (click)="selectLanguage(language)">
    <span class="language-selector__label">
      <img class="language-selector__flag" *ngIf="language.flagUrl" [src]="language.flagUrl | safe" />
      {{ language.label }}
    </span>
  </button>
</mat-menu>
