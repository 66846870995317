import { Config, ConfigInterface } from '../section/section';

export interface SEOConfigInterface extends ConfigInterface {
  hideOrganicSeoData?: boolean;
  hideOrganicKeywordData?: boolean;
  hideLocalSeoData?: boolean;
  customKeyword?: string;
}

export class SEOConfig extends Config implements SEOConfigInterface {
  hideOrganicSeoData: boolean;
  hideOrganicKeywordData: boolean;
  hideLocalSeoData: boolean;
  customKeyword: string;

  constructor(data: SEOConfigInterface) {
    super(data);
    this.hideOrganicSeoData = !!data.hideOrganicSeoData;
    this.hideOrganicKeywordData = !!data.hideOrganicKeywordData;
    this.hideLocalSeoData = !!data.hideLocalSeoData;
    this.customKeyword = data.customKeyword || '';
  }
}
