<ng-container *ngIf="localSeoDataMetrics$ | async as localSeoDataMetrics">
  <snap-subsection
    *ngIf="
      localSeoDataMetrics.searches?.length > 0 ||
      localSeoDataMetrics.status === Status.STATUS_IN_PROGRESS ||
      localSeoDataMetrics.status === Status.STATUS_FINISHED ||
      localSeoDataMetrics.status === Status.STATUS_DOES_NOT_EXIST
    "
    heading="{{ 'LOCAL_SEO.LOCAL_SEO.HEADING' | translate }}"
    subheading="{{ 'LOCAL_SEO.LOCAL_SEO.SUBHEADING' | translate }}"
    [edit]="edit"
    [hideControl]="controls.controls['hideLocalSeoData']"
    [grade]="localSeoDataMetrics?.grade"
    [hideGrade]="snapshotReportService.hideSubGrades$ | async"
  >
    <snap-local-seo
      [edit]="edit"
      [localSEOData]="localSeoDataMetrics"
      [businessData]="snapshotService.snapshotData$ | async"
    ></snap-local-seo>
  </snap-subsection>
</ng-container>
