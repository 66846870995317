<ng-container [ngSwitch]="previewState">
  <div *ngSwitchCase="'showNone'"></div>

  <div *ngSwitchCase="'showMobile'">
    <div class="mobile-preview" *ngIf="mobileScreenshot">
      <img [src]="mobileScreenshot" />
    </div>
    <div *ngIf="isMobileFriendly; then mobileFriendly; else notMobileFriendly"></div>
  </div>

  <div *ngSwitchCase="'showDesktop'">
    <div class="laptop-preview" *ngIf="desktopScreenshot">
      <img [src]="desktopScreenshot" />
    </div>
  </div>

  <div *ngSwitchCase="'showAll'">
    <div class="laptop-preview" *ngIf="desktopScreenshot">
      <img [src]="desktopScreenshot" />
    </div>
    <div class="mobile-overlap" *ngIf="mobileScreenshot">
      <div class="mobile-preview">
        <img [src]="mobileScreenshot" />
      </div>
    </div>
    <div *ngIf="isMobileFriendly; then mobileFriendly; else notMobileFriendly"></div>
  </div>
</ng-container>

<ng-template #mobileFriendly>
  <div class="mobile-friendly">
    <mat-icon class="check_circle">check_circle</mat-icon>
    <span>{{ 'WEBSITE.MOBILE_FRIENDLY' | translate }}</span>
  </div>
</ng-template>

<ng-template #notMobileFriendly>
  <div class="mobile-friendly">
    <mat-icon class="error">error</mat-icon>
    <span>{{ 'WEBSITE.NOT_MOBILE_FRIENDLY' | translate }}</span>
  </div>
</ng-template>
