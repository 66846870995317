<snap-subsection
  *ngIf="snapshotService.snapshotData$ | async as snapshotData"
  heading="Facebook"
  [hideControl]="controls.controls['hideFacebook']"
  [edit]="edit"
>
  <div subheading *ngIf="facebookTableItems$ | async as facebookTableItems">
    {{ 'SOCIAL.COMMON_SUBHEADING' | translate }}
    <a
      *ngIf="snapshotData?.facebookUrl && facebookTableItems.length > 0; else facebookLinkText"
      class="facebook-url"
      href="{{ snapshotData?.facebookUrl }}"
      target="_blank"
      rel="nofollow"
    >
      <ng-container [ngTemplateOutlet]="facebookLinkText"></ng-container>
    </a>
    <ng-template #facebookLinkText>
      {{ 'SOCIAL.FACEBOOK.LINK_TEXT' | translate }}
    </ng-template>
  </div>
  <ng-container *ngIf="!isFacebookWorking; else stillWorkingTemplate">
    <ng-container *ngIf="media.mobile$ | async; else desktopView">
      <ng-container *ngIf="competitorFacebookTableItems$ | async as items; else industryMobileView">
        <ng-container *ngIf="items.length > 0 && items[0].competitors?.length > 0; else industryMobileView">
          <snap-mobile-view-data
            [hideGrades]="snapshotService.hideSubGrades$ | async"
            [edit]="edit"
            [subsections]="items"
          ></snap-mobile-view-data>
        </ng-container>
      </ng-container>

      <ng-template #industryMobileView>
        <snap-mobile-view-data
          [hideGrades]="snapshotService.hideSubGrades$ | async"
          [edit]="edit"
          [subsections]="facebookTableItems$ | async"
        ></snap-mobile-view-data>
      </ng-template>
    </ng-container>

    <ng-template #desktopView>
      <ng-container *ngIf="competitorFacebookTableItems$ | async as items; else appGradeTable">
        <ng-container *ngIf="items.length > 0 && items[0].competitors?.length > 0; else appGradeTable">
          <snap-table
            [editMode]="edit"
            [items]="items"
            [showIndustryAverage]="true"
            [hideGrades]="snapshotService.hideSubGrades$ | async"
            [colorTextYourBusiness]="true"
          ></snap-table>
        </ng-container>
      </ng-container>
      <ng-template #appGradeTable>
        <ng-container *ngIf="facebookTableItems$ | async as facebookTableItems">
          <ng-container *ngIf="facebookTableItems.length > 0">
            <snap-table
              *ngIf="facebookTableItems.length > 0"
              [editMode]="edit"
              [items]="facebookTableItems"
              [showIndustryAverage]="true"
              [showIndustryLeader]="true"
              [hideGrades]="snapshotService.hideSubGrades$ | async"
              [colorTextYourBusiness]="true"
            ></snap-table>
          </ng-container>
        </ng-container>
      </ng-template>
    </ng-template>

    <mat-progress-bar mode="indeterminate" *ngIf="facebookUrlControl.pending"></mat-progress-bar>

    <div class="not-found" *ngIf="facebookTableItems$ | async as facebookTableItems">
      <ng-container *ngIf="facebookTableItems.length === 0">
        <ng-container *ngIf="snapshotData?.facebookUrl === ''; else restrictedFacebookPageTemplate">
          <glxy-empty-state [size]="'small'" [widthConstraint]="false">
            <glxy-empty-state-hero>
              <div class="image">
                <img [src]="'https://www.cdnstyles.com/static/images/icon50/sourceId-10050.png'" />
              </div>
            </glxy-empty-state-hero>
            <glxy-empty-state-title>
              {{ 'SOCIAL.FACEBOOK.NO_PAGE_ERROR_TITLE' | translate }}
            </glxy-empty-state-title>
            <p [innerHtml]="'SOCIAL.FACEBOOK.NO_PAGE_ERROR_DESCRIPTION' | translate | iTrustThisHtml"></p>
            <ng-container *ngIf="(snapshotService.isExpired$ | async) === false && edit">
              <glxy-empty-state-actions>
                <div class="form-field">
                  <glxy-input
                    dense
                    class="social-url"
                    [formControl]="facebookUrlControl"
                    (keyup.enter)="addFacebookUrl()"
                    placeholder="{{ 'COMMON.ACCOUNT_URL' | translate }}"
                  ></glxy-input>

                  <div class="submit-button">
                    <a
                      mat-stroked-button
                      [disabled]="facebookUrlControl.invalid || facebookUrlControl.pending"
                      (click)="addFacebookUrl()"
                    >
                      <mat-icon>arrow_forward</mat-icon>
                    </a>
                  </div>
                </div>
              </glxy-empty-state-actions>
            </ng-container>
          </glxy-empty-state>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</snap-subsection>

<ng-template #restrictedFacebookPageTemplate>
  <glxy-empty-state [size]="'small'" [widthConstraint]="false">
    <glxy-empty-state-hero>
      <div class="image">
        <img [src]="'https://www.cdnstyles.com/static/images/icon50/sourceId-10050.png'" />
      </div>
    </glxy-empty-state-hero>
    <glxy-empty-state-title>
      {{ 'SOCIAL.FACEBOOK.RESTRICTED_PAGE_TITLE' | translate }}
    </glxy-empty-state-title>
    <p
      [innerHtml]="
        'SOCIAL.FACEBOOK.RESTRICTED_PAGE_DESCRIPTION'
          | translate
            : {
                facebookPageUrl: (snapshotService.snapshotData$ | async)?.facebookUrl
              }
      "
    ></p>
    <glxy-empty-state-actions>
      <a mat-stroked-button href="https://www.facebook.com/help/537592652938493" target="_blank" rel="nofollow">
        {{ 'COMMON.LEARN_MORE' | translate }}
      </a>
    </glxy-empty-state-actions>
  </glxy-empty-state>
</ng-template>

<ng-template #stillWorkingTemplate>
  <snap-still-working></snap-still-working>
</ng-template>
