import { Config, ConfigInterface } from '../section/section';

export enum ListingPresenceVersions {
  VERSION_0 = 0,
  VERSION_1 = 1,
}

export interface ListingConfigInterface extends ConfigInterface {
  hideListingPresence?: boolean;
  hideListingPresenceFacebook?: boolean;
  hideListingPresenceTwitter?: boolean;
  hideListingPresenceGoogle?: boolean;
  hideListingPresenceMissing?: boolean;
  hideListingPresenceFoursquare?: boolean;
  hideListingPresenceInstagram?: boolean;
  hideListingAccuracy?: boolean;
  hideListingAccuracyMissingPhone?: boolean;
  hideListingAccuracyIncorrectPhone?: boolean;
  hideListingAccuracyMissingAddress?: boolean;
  hideListingAccuracyIncorrectAddress?: boolean;
  hideListingAccuracyMissingWebsite?: boolean;
  hideListingAccuracyIncorrectWebsite?: boolean;
  hideListingDistribution?: boolean;
  hideListingDistributionContent?: boolean;
  customizedListingDistributionMessage?: string;
  hideListingDetails?: boolean;
  listingPresenceVersion?: number;
}

export class ListingConfig extends Config implements ListingConfigInterface {
  hideListingPresence: boolean;
  hideListingPresenceFacebook: boolean;
  hideListingPresenceTwitter: boolean;
  hideListingPresenceGoogle: boolean;
  hideListingPresenceFoursquare: boolean;
  hideListingPresenceInstagram: boolean;
  hideListingPresenceMissing: boolean;

  hideListingAccuracy: boolean;
  hideListingAccuracyMissingPhone: boolean;
  hideListingAccuracyIncorrectPhone: boolean;
  hideListingAccuracyMissingAddress: boolean;
  hideListingAccuracyIncorrectAddress: boolean;
  hideListingAccuracyMissingWebsite: boolean;
  hideListingAccuracyIncorrectWebsite: boolean;

  hideListingDistribution: boolean;
  hideListingDistributionContent: boolean;
  customizedListingDistributionMessage: string;

  hideListingDetails: boolean;

  listingPresenceVersion: number;

  constructor(config: ListingConfigInterface) {
    super(config);
    this.hideListingPresence = !!config.hideListingPresence;
    this.hideListingPresenceFacebook = !!config.hideListingPresenceFacebook;
    this.hideListingPresenceTwitter = !!config.hideListingPresenceTwitter;
    this.hideListingPresenceGoogle = !!config.hideListingPresenceGoogle;
    this.hideListingPresenceFoursquare = !!config.hideListingPresenceFoursquare;
    this.hideListingPresenceInstagram = !!config.hideListingPresenceInstagram;
    this.hideListingPresenceMissing = !!config.hideListingPresenceMissing;
    this.hideListingAccuracy = !!config.hideListingAccuracy;
    this.hideListingAccuracyMissingPhone = !!config.hideListingAccuracyMissingPhone;
    this.hideListingAccuracyIncorrectPhone = !!config.hideListingAccuracyIncorrectPhone;
    this.hideListingAccuracyMissingAddress = !!config.hideListingAccuracyMissingAddress;
    this.hideListingAccuracyIncorrectAddress = !!config.hideListingAccuracyIncorrectAddress;
    this.hideListingAccuracyMissingWebsite = !!config.hideListingAccuracyMissingWebsite;
    this.hideListingAccuracyIncorrectWebsite = !!config.hideListingAccuracyIncorrectWebsite;
    this.hideListingDistribution = !!config.hideListingDistribution;
    this.hideListingDistributionContent = !!config.hideListingDistributionContent;
    this.customizedListingDistributionMessage = config.customizedListingDistributionMessage || '';
    this.hideListingDetails = !!config.hideListingDetails;
    this.listingPresenceVersion = config.listingPresenceVersion || 0;
  }
}
