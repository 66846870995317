import { Component, Input } from '@angular/core';
import { BaseHeaderComponent } from '../section/base-header.component';
import { WebsiteService } from './website.service';

@Component({
  selector: 'snap-website-section-header',
  template: `
    <snap-grade-tagline
      [hideGrade]="hideGrade"
      [grade]="service.grade$ | async"
      heading="{{ 'TITLES.WEBSITE' | translate }}"
      tagline="{{ 'WEBSITE.TAGLINE' | translate }}"
    ></snap-grade-tagline>
  `,
  styleUrls: ['./website-section.component.scss'],
})
export class WebsiteSectionHeaderComponent extends BaseHeaderComponent {
  @Input() hideGrade: boolean;

  constructor(public service: WebsiteService) {
    super(service);
  }
}
