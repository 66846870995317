import { Injectable } from '@angular/core';
import { CurrentSnapshotService, SnapshotService } from '@vendasta/snapshot';
import { DomainService } from '@vendasta/domain';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class BusinessDetailsService {
  constructor(
    private currentSnapshotService: CurrentSnapshotService,
    private snapshotService: SnapshotService,
    private domainService: DomainService,
  ) {}

  getDomain(partnerId: string): Observable<string> {
    return this.domainService.getDomainByIdentifier(`/application/ST/partner/${partnerId}`).pipe(
      take(1),
      map((resp) => {
        return resp.primary.domain;
      }),
    );
  }
}
