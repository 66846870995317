<snap-toggled-item [edit]="edit" [hideControl]="hideControl" [type]="'slideToggle'">
  <mat-card appearance="outlined" class="snap-section-container">
    <mat-card-title data-testid="section-container-title">
      <ng-content select="[header]"></ng-content>
      <div class="text-container small-header">
        <div *ngIf="!hideGrade" class="more-info" (click)="openExplainer()">
          <span class="hide-mobile" data-testid="help-info">
            {{ 'COMMON.GRADE_INFO' | translate }}
          </span>
          <mat-icon class="hide-to-print" [inline]="true" data-testid="icon">help_outline</mat-icon>
        </div>
        <div class="toggle-section">
          <ng-template snapItemToggle></ng-template>
        </div>
      </div>
    </mat-card-title>
    <mat-card-content [@toggleSection]="hideControl && hideControl.value ? 'off' : 'on'">
      <ng-container *ngIf="(sectionService.loaded$ | async) && (sectionService.error$ | async) === false">
        <ng-content></ng-content>
      </ng-container>
      <ng-container *ngIf="sectionService.error$ | async">
        <snap-try-again (refreshSectionClickedEvent)="sectionService.reload()"></snap-try-again>
      </ng-container>
      <snap-section-stencil *ngIf="(sectionService.loaded$ | async) === false"></snap-section-stencil>
    </mat-card-content>
  </mat-card>
</snap-toggled-item>
