<snap-section-container *ngIf="showSection$ | async" [hideGrade]="true" [hideControl]="hideControl" [edit]="edit">
  <snap-technology-section-header header></snap-technology-section-header>
  <snap-content-banner
    [hideControl]="controls.controls['hideContent']"
    [messageControl]="controls.controls['customizedMessage']"
    [customizedMessage]="service.message$ | async"
    [contentId]="service.messageId$ | async"
    [edit]="edit"
    [hideVideo]="true"
  ></snap-content-banner>

  <snap-technology-section-content></snap-technology-section-content>

  <snap-section-footer
    class="hide-mobile"
    data-testid="footer-technology"
    [hideControl]="controls.controls['hideFooter']"
    [edit]="edit"
    [contentId]="service.footerMessageId$ | async"
    [messageControl]="controls.controls['customizedFooterMessage']"
    [customizedMessage]="service.footerMessage$ | async"
    [ctaLabel]="service.footerCtaLabel$ | async"
    [ctaTarget]="service.footerCtaURL$ | async"
    [targetProductId]="service.footerCtaProduct$ | async"
    [editButtonFormGroup]="controls.controls['footerCta']"
    [analyticsCategory]="footerCategory"
    (sectionFooterClick)="handleSectionFooterClick()"
    (sectionFooterEditClick)="handleSectionFooterEditClick($event)"
  ></snap-section-footer>
</snap-section-container>
