<ng-container *ngIf="isSnapshotLoaded; else loading">
  <ng-container *ngIf="isSnapshotReady; else notReady">
    <h2 mat-dialog-title data-testid="snapshot-dialog-title">Insert Snapshot</h2>
    <mat-dialog-content
      [attr.data-testid]="'insert-snapshot-dialog-content'"
      class="snapshot-details-container"
      *ngIf="snapshotSummary$ | async as summary; else loading"
    >
      <ng-container>
        <div class="score-card">
          <ng-container>
            <div class="scorecard-header-container">
              <mat-checkbox
                class="checkbox"
                [checked]="allSectionsSelected"
                [indeterminate]="someSectionsSelected()"
                [attr.data-action]="'snapshot-dialog-all-sections-checkbox'"
                (change)="setAllSections($event.checked)"
              ></mat-checkbox>
              <div class="header-content-container">
                <snap-overall-score [overallScore]="summary?.score ?? '0%'"></snap-overall-score>

                <div class="header-details">
                  <span class="business-name">{{ summary?.companyName }}</span>
                  <span class="business-address">{{ address }}</span>
                  <span class="created-date">
                    {{ 'COMMON.CREATED' | translate }} {{ summary.created | date : 'longDate' }}
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="score-card">
          <ng-container *ngFor="let section of sections">
            <snap-document-snapshot-section-grade
              [section]="section"
              [showGrade]="!isCustomProposalSnapshotSection(section)"
              (selectionChanged)="checkSelectedSections()"
            ></snap-document-snapshot-section-grade>
          </ng-container>
        </div>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button color="primary" data-action="close-snapshot-dialog" (click)="cancel()">Cancel</button>
      <button mat-raised-button color="primary" data-action="add-snapshot-sections-to-proposal" (click)="close()">
        {{ createProposal ? 'Create a proposal' : 'Add to proposal' }}
      </button>
    </mat-dialog-actions>
  </ng-container>
</ng-container>
<ng-template #loading>
  <mat-spinner [diameter]="30" class="loading"></mat-spinner>
</ng-template>
<ng-template #notReady>
  <snap-document-snapshot-not-ready></snap-document-snapshot-not-ready>
</ng-template>
