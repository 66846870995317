<div class="page-container">
  <div class="hero-banner">
    <div class="loading flex-column align-center-center">
      <mat-progress-spinner mode="indeterminate" diameter="100"></mat-progress-spinner>
      <div>
        {{ 'SNAPSHOT_LITE.LOADING' | translate }}
      </div>
    </div>
  </div>
</div>
