import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OverallScore, RouteType, SnapshotReportService, WhitelabelService } from '@galaxy/snapshot';
import { GlobalConfigEnabledSection, SnapshotData } from '@vendasta/snapshot';
import { RoutingService } from '../../app.routes';
import { SectionsReorderingEvent } from '../interfaces';
import { FREE_SUBSCRIPTION_ID } from '@galaxy/partner';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMenuComponent {
  @Input() activeNavItem = '';
  @Input() canEnableTechnologySection = false;
  @Input() displayEcommerceSection = false;
  @Input() edit = false;
  @Input() hideGrades = false;
  @Input() overallScore: OverallScore;
  @Input() loading = false;
  @Input() logoUrl = '';
  @Input() sections: GlobalConfigEnabledSection[] = [];
  @Input() snapshotData: SnapshotData;
  @Input() snapshotId: string;
  @Output() menuItemClick = new EventEmitter<void>();
  @Output() sectionsReorder = new EventEmitter<SectionsReorderingEvent>();

  readonly Section = GlobalConfigEnabledSection;
  navSectionElement: any;

  //  Show the powered by Vendasta co-branding if the partner is on the free plan
  readonly showPoweredByVendasta$ = this.whiteLabelService.whitelabel$.pipe(
    map((whiteLabelConfig) => FREE_SUBSCRIPTION_ID === whiteLabelConfig.subscriptionTierId),
  );

  constructor(
    private readonly routingService: RoutingService,
    private readonly whiteLabelService: WhitelabelService,
    public readonly snapshotService: SnapshotReportService,
  ) {}

  buildRouterLink(): string {
    const routeType = this.edit ? RouteType.Edit : RouteType.View;
    return this.routingService.getRoute({ routeType, snapshotId: this.snapshotId });
  }

  handleMenuItemClick(): void {
    this.menuItemClick.emit();
  }

  isbefore(a: any, b: any): boolean {
    if (a.parentNode === b.parentNode) {
      for (let cur = a; cur; cur = cur.previousSibling) {
        if (cur === b) {
          return true;
        }
      }
    }
    return false;
  }

  dragenter($event: any): void {
    const target: any = $event.currentTarget;
    if (this.isbefore(this.navSectionElement, target)) {
      target.parentNode.insertBefore(this.navSectionElement, target); // insert before
    } else {
      target.parentNode.insertBefore(this.navSectionElement, target.nextSibling); // insert after
    }
  }

  dragstart($event: any): void {
    this.navSectionElement = $event.currentTarget;
    this.navSectionElement.classList.add('dragging');
    $event.dataTransfer.effectAllowed = 'move';
  }

  dragend($event: any): void {
    this.navSectionElement.classList.remove('dragging');
    const target: any = $event.currentTarget;
    const elements: any = Array.from(target.parentNode.childNodes);
    const firstLevelChildren = elements.filter((child: any) => child.nodeType === 1);
    const sections: any = firstLevelChildren.map((x: any) => Number(x.dataset.section));
    const sanitizedSections = sections.filter((x: number) => x != null && !isNaN(x));
    const trigger = this.navSectionElement.dataset.section;
    this.sectionsReorder.emit({ trigger, sections: sanitizedSections });
  }
}
