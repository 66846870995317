import {
  LifecycleStage,
  LookupRequestAccountStatus,
  LookupRequestListingDistributionFilterActivationStatus,
  LookupRequestListingDistributionFilterAutoRenew,
  LookupRequestPresence,
} from '@vendasta/account-group';

export class AccountFilter {
  productId: string;
  status: LookupRequestAccountStatus;
  editionId: string;

  constructor(productId: string, status: LookupRequestAccountStatus, editionId: string) {
    this.productId = productId;
    this.status = status;
    this.editionId = editionId;
  }
}

export class ApiLookupFilter {
  partnerId: string;
  marketIds: string[];
  salesPersonId: string;
  tags: string[];
  accountFilters?: AccountFilters;
  listingDistributionFilter?: ListingDistributionFilter;
  listingSyncProFilter?: ListingSyncProFilter;
  createdDateFilter?: CreatedDateFilter;
  trialFilter?: ApiTrialFilter;
  taxonomyIds?: string[];
  presenceFilter?: PresenceFilter;
  locationFilter?: LocationFilter;
  snapshotFilter?: SnapshotFilter;
  statusFilter?: StatusFilter;
  accountGroupIds?: string[];
  lifecycleStageFilter?: LifecycleStage;

  constructor(
    partnerId: string,
    marketIds: string[],
    salesPersonId: string,
    tags: string[],
    accountFilters?: AccountFilters,
    listingDistributionFilter?: ListingDistributionFilter,
    listingSyncProFilter?: ListingSyncProFilter,
    createdDateFilter?: CreatedDateFilter,
    trialFilter?: ApiTrialFilter,
    taxonomyIds?: string[],
    presenceFilter?: PresenceFilter,
    locationFilter?: LocationFilter,
    snapshotFilter?: SnapshotFilter,
    statusFilter?: StatusFilter,
    accountGroupIds?: string[],
    lifecycleStageFilter?: LifecycleStage,
  ) {
    this.partnerId = partnerId;
    this.marketIds = marketIds;
    this.salesPersonId = salesPersonId;
    this.tags = tags;
    this.accountFilters = accountFilters;
    this.listingDistributionFilter = listingDistributionFilter;
    this.listingSyncProFilter = listingSyncProFilter;
    this.createdDateFilter = createdDateFilter;
    this.trialFilter = trialFilter;
    this.taxonomyIds = taxonomyIds;
    this.presenceFilter = presenceFilter;
    this.locationFilter = locationFilter;
    this.snapshotFilter = snapshotFilter;
    this.statusFilter = statusFilter;
    this.accountGroupIds = accountGroupIds;
    this.lifecycleStageFilter = lifecycleStageFilter;
  }

  toApiJson(): object {
    let lifecycleFilter: LifecycleStage[];
    if (
      this.lifecycleStageFilter === LifecycleStage.LIFECYCLE_STAGE_CUSTOMER ||
      this.lifecycleStageFilter === LifecycleStage.LIFECYCLE_STAGE_LEAD ||
      this.lifecycleStageFilter === LifecycleStage.LIFECYCLE_STAGE_PROSPECT
    ) {
      lifecycleFilter = [this.lifecycleStageFilter];
    } else {
      lifecycleFilter = null;
    }

    return {
      partnerId: this.partnerId,
      marketIds: typeof this.marketIds !== 'undefined' ? this.marketIds : null,
      salesPersonId: typeof this.salesPersonId !== 'undefined' ? this.salesPersonId : null,
      tags: typeof this.tags !== 'undefined' ? this.tags : null,
      accountFilters: this.accountFilters.toApiJson(),
      listingDistributionFilter: this.listingDistributionFilter.toApiJson(),
      createdDateFilter: this.createdDateFilter.toApiJson(),
      listingSyncProFilter: this.listingSyncProFilter.toApiJson(),
      trialFilter: this.trialFilter.toApiJson(),
      taxonomyIds: typeof this.taxonomyIds !== 'undefined' ? this.taxonomyIds : null,
      presenceFilter: this.presenceFilter ? this.presenceFilter.toApiJson() : null,
      locationFilter: this.locationFilter ? this.locationFilter.toApiJson() : null,
      snapshotFilter: this.snapshotFilter ? this.snapshotFilter.toApiJson() : null,
      statusFilter: this.statusFilter ? this.statusFilter.toApiJson() : null,
      accountGroupIds: this.accountGroupIds ? this.accountGroupIds : null,
      lifecycleStage: lifecycleFilter,
    };
  }
}

export class AccountFilters {
  private filters: AccountFilter[] = [];

  public addFilter(productId: string, status?: LookupRequestAccountStatus, editionId?: string): void {
    this.filters.push(new AccountFilter(productId, status, editionId));
  }

  public removeFilter(productId: string): void {
    const index = this.filters.findIndex((filter: AccountFilter) => filter.productId === productId);
    if (index === -1) {
      return;
    }
    this.filters.splice(index, 1);
  }

  public changeStatus(productId: string, status: LookupRequestAccountStatus): void {
    this.filters.find((filter: AccountFilter) => filter.productId === productId).status = status;
  }

  public clearFilters(): void {
    this.filters = [];
  }

  public getFilters(): AccountFilter[] {
    return this.filters;
  }

  public toApiJson(): object[] {
    const result = this.filters.map((filter) => {
      return {
        marketplaceAppId: filter.productId,
        accountStatus: filter.status,
        editionId: filter.editionId,
      };
    });

    return result.length > 0 ? result : null;
  }
}

export class ListingDistributionFilter {
  activationStatus: LookupRequestListingDistributionFilterActivationStatus;
  autoRenew: LookupRequestListingDistributionFilterAutoRenew;
  expiresInDays: number;
  expiredInDays: number;
  renewsInDays: number;

  constructor(
    activationStatus?: LookupRequestListingDistributionFilterActivationStatus,
    autoRenew?: LookupRequestListingDistributionFilterAutoRenew,
    expiresInDays?: number,
    expiredInDays?: number,
    renewsInDays?: number,
  ) {
    this.activationStatus = activationStatus;
    this.autoRenew = autoRenew;
    this.expiresInDays = expiresInDays;
    this.expiredInDays = expiredInDays;
    this.renewsInDays = renewsInDays;
  }

  toApiJson(): object {
    if (
      typeof this.activationStatus === 'undefined' &&
      typeof this.autoRenew === 'undefined' &&
      typeof this.expiresInDays === 'undefined' &&
      typeof this.expiredInDays === 'undefined' &&
      typeof this.renewsInDays === 'undefined'
    ) {
      return null;
    }
    return {
      activationStatus: this.activationStatus,
      autoRenew: this.autoRenew,
      expiresInDays: this.expiresInDays,
      expiredInDays: this.expiredInDays,
      renewsInDays: this.renewsInDays,
    };
  }
}

export class ListingSyncProFilter {
  active: boolean;
  autoRenew: boolean;
  autoRenewDisabled: boolean;
  inactive: boolean;
  expired: boolean;
  expiresInDays: number;
  expiredInDays: number;

  constructor(
    active?: boolean,
    autoRenew?: boolean,
    autoRenewDisabled?: boolean,
    inactive?: boolean,
    expired?: boolean,
    expiresInDays?: number,
    expiredInDays?: number,
  ) {
    this.active = active;
    this.autoRenew = autoRenew;
    this.autoRenewDisabled = autoRenewDisabled;
    this.inactive = inactive;
    this.expired = expired;
    this.expiresInDays = expiresInDays;
    this.expiredInDays = expiredInDays;
  }

  toApiJson(): object {
    if (
      typeof this.active === 'undefined' &&
      typeof this.autoRenew === 'undefined' &&
      typeof this.autoRenewDisabled === 'undefined' &&
      typeof this.inactive === 'undefined' &&
      typeof this.expired === 'undefined' &&
      typeof this.expiresInDays === 'undefined' &&
      typeof this.expiredInDays === 'undefined'
    ) {
      return null;
    }
    return {
      active: this.active,
      autoRenew: this.autoRenew,
      autoRenewDisabled: this.autoRenewDisabled,
      inactive: this.inactive,
      expired: this.expired,
      expiresInDays: this.expiresInDays,
      expiredInDays: this.expiredInDays,
    };
  }
}

export class CreatedDateFilter {
  beginRange: Date;
  endRange: Date;

  constructor(beginRange?: Date, endRange?: Date) {
    this.beginRange = beginRange;
    this.endRange = endRange;
  }

  toApiJson(): object {
    if (typeof this.beginRange === 'undefined' && typeof this.endRange === 'undefined') {
      return null;
    }
    return {
      beginRange: typeof this.beginRange !== 'undefined' ? this.beginRange.toISOString() : null,
      endRange: typeof this.endRange !== 'undefined' ? this.endRange.toISOString() : null,
    };
  }
}

export class ApiTrialFilter {
  isSuspended: boolean;

  constructor(isSuspended?: boolean) {
    this.isSuspended = isSuspended;
  }

  toApiJson(): object {
    if (typeof this.isSuspended === 'undefined') {
      return null;
    }
    return {
      isSuspended: this.isSuspended,
    };
  }
}

export class TrialFilter {
  active: boolean;
  overLimit: boolean;

  constructor(active?: boolean, overLimit?: boolean) {
    this.active = active;
    this.overLimit = overLimit;
  }
}

export class PresenceFilter {
  constructor(
    public facebook?: LookupRequestPresence,
    public foursquare?: LookupRequestPresence,
    public googleplus?: LookupRequestPresence,
    public linkedin?: LookupRequestPresence,
    public twitter?: LookupRequestPresence,
    public pinterest?: LookupRequestPresence,
    public instagram?: LookupRequestPresence,
    public youtube?: LookupRequestPresence,
    public website?: LookupRequestPresence,
  ) {}

  toApiJson(): object {
    if (
      this.facebook === undefined &&
      this.foursquare === undefined &&
      this.googleplus === undefined &&
      this.linkedin === undefined &&
      this.twitter === undefined &&
      this.pinterest === undefined &&
      this.instagram === undefined &&
      this.youtube === undefined &&
      this.website === undefined
    ) {
      return null;
    }
    return {
      facebook: this.facebook,
      foursquare: this.foursquare,
      googleplus: this.googleplus,
      linkedin: this.linkedin,
      twitter: this.twitter,
      pinterest: this.pinterest,
      instagram: this.instagram,
      youtube: this.youtube,
      website: this.website,
    };
  }
}

export class LocationFilter {
  constructor(
    public country?: string,
    public state?: string,
  ) {}

  toApiJson(): object {
    if (this.country === undefined && this.state === undefined) {
      return null;
    }
    return {
      country: this.country,
      state: this.state,
    };
  }
}

export class SnapshotFilter {
  active: boolean;
  expired: boolean;
  noSnapshotsCreated: boolean;

  constructor(active?: boolean, expired?: boolean, noSnapshotsCreated?: boolean) {
    this.active = active;
    this.expired = expired;
    this.noSnapshotsCreated = noSnapshotsCreated;
  }

  toApiJson(): object {
    if (
      typeof this.active === 'undefined' &&
      typeof this.expired === 'undefined' &&
      typeof this.noSnapshotsCreated === 'undefined'
    ) {
      return null;
    }
    return {
      active: this.active,
      expired: this.expired,
      noSnapshotsCreated: this.noSnapshotsCreated,
    };
  }
}

export class StatusFilter {
  hasAlert: boolean;

  constructor(hasAlert?: boolean) {
    this.hasAlert = hasAlert;
  }

  toApiJson(): object {
    if (typeof this.hasAlert === 'undefined') {
      return null;
    }
    return {
      hasAlert: this.hasAlert,
    };
  }
}

export class LookupFilter {
  salesPersonId: string;
  tags: string[];
  accountFilters?: AccountFilters;
  listingDistributionFilter?: ListingDistributionFilter;
  listingSyncProFilter?: ListingSyncProFilter;
  createdDateFilter?: CreatedDateFilter;
  trialFilter?: TrialFilter;
  taxonomyIds?: string[];
  presenceFilter?: PresenceFilter;
  locationFilter?: LocationFilter;
  snapshotFilter?: SnapshotFilter;
  statusFilter?: StatusFilter;
  accountGroupIds?: string[];
  lifecycleStageFilter?: LifecycleStage;

  constructor(
    salesPersonId?: string,
    tags?: string[],
    accountFilters?: AccountFilters,
    listingDistributionFilter?: ListingDistributionFilter,
    listingSyncProFilter?: ListingSyncProFilter,
    createdDateFilter?: CreatedDateFilter,
    trialFilter?: TrialFilter,
    taxonomyIds?: string[],
    presenceFilter?: PresenceFilter,
    locationFilter?: LocationFilter,
    snapshotFilter?: SnapshotFilter,
    statusFilter?: StatusFilter,
    accountGroupIds?: string[],
    lifecycleStageFilter?: LifecycleStage,
  ) {
    this.salesPersonId = salesPersonId;
    this.tags = tags;
    this.accountFilters = accountFilters || new AccountFilters();
    this.listingDistributionFilter = listingDistributionFilter || new ListingDistributionFilter();
    this.listingSyncProFilter = listingSyncProFilter || new ListingSyncProFilter();
    this.createdDateFilter = createdDateFilter || new CreatedDateFilter();
    this.trialFilter = trialFilter || new TrialFilter();
    this.taxonomyIds = taxonomyIds;
    this.presenceFilter = presenceFilter || new PresenceFilter();
    this.locationFilter = locationFilter || new LocationFilter();
    this.snapshotFilter = snapshotFilter || new SnapshotFilter();
    this.statusFilter = statusFilter || new StatusFilter();
    this.accountGroupIds = accountGroupIds;
    this.lifecycleStageFilter = lifecycleStageFilter;
  }
}
