import { combineLatest as observableCombineLatest, BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import {
  mergeMap,
  filter,
  switchMap,
  map,
  take,
  shareReplay,
  switchMapTo,
  startWith,
  tap,
  debounceTime,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  Competitor,
  LanguageConfigInterface,
  SnapshotConfig,
  SnapshotConfigInterface,
  SnapshotData,
  SnapshotReport,
  SnapshotSummary,
} from './snapshot-report';
import {
  GlobalConfigEnabledSection as EnabledSection,
  VideoStyle,
  Grade,
  Section,
  CompetitionAnalysis,
  SnapshotApiService,
} from '@vendasta/snapshot';
import { PartnerServiceInterface } from '@galaxy/partner';
import { SSOService, newPartnerServiceContext } from '@vendasta/sso';
import { SnapshotService } from '../snapshot.service';
import { WhitelabelService } from '../whitelabel/whitelabel.service';
import { differenceInHours } from 'date-fns';
import { SectionServiceInterface } from '../common/interfaces';
import { ObservableWorkState } from '@vendasta/rx-utils/work-state';

// This is required so that a score of 0 will still be rendered async
export class OverallScore {
  score: number;

  constructor(score: number) {
    this.score = score;
  }
}

@Injectable()
export class SnapshotReportService implements PartnerServiceInterface {
  private _reviewGrade$$: ReplaySubject<Grade> = new ReplaySubject(1);
  private _socialGrade$$: ReplaySubject<Grade> = new ReplaySubject(1);
  private _listingGrade$$: ReplaySubject<Grade> = new ReplaySubject(1);
  private _websiteGrade$$: ReplaySubject<Grade> = new ReplaySubject(1);
  private _advertisingGrade$$: ReplaySubject<Grade> = new ReplaySubject(1);
  private _seoGrade$$: ReplaySubject<Grade> = new ReplaySubject(1);
  private _ecommerceGrade$$: ReplaySubject<Grade> = new ReplaySubject(1);
  private _enabledSections$$: BehaviorSubject<EnabledSection[]> = new BehaviorSubject<EnabledSection[]>([]);

  public businessId$: Observable<string>;
  public snapshotId$: Observable<string>;
  public snapshot$: Observable<SnapshotReport>;
  public snapshotConfig$: Observable<SnapshotConfig>;
  public snapshotData$: Observable<SnapshotData>;
  public isPartnerConfig$: Observable<boolean>;

  public listingSectionEnabled$: Observable<boolean>;
  public reviewSectionEnabled$: Observable<boolean>;
  public socialSectionEnabled$: Observable<boolean>;
  public websiteSectionEnabled$: Observable<boolean>;
  public advertisingSectionEnabled$: Observable<boolean>;
  public seoSectionEnabled$: Observable<boolean>;
  public ecommerceSectionEnabled$: Observable<boolean>;
  public technologySectionEnabled$: Observable<boolean>;

  public reviewGrade$: Observable<Grade>;
  public socialGrade$: Observable<Grade>;
  public listingGrade$: Observable<Grade>;
  public websiteGrade$: Observable<Grade>;
  public advertisingGrade$: Observable<Grade>;
  public seoGrade$: Observable<Grade>;
  public ecommerceGrade$: Observable<Grade>;
  // TODO: remove ecommerce check mark when we get grades for old snapshots

  public videoStyle$: Observable<VideoStyle>;
  public hideImages$: Observable<boolean>;
  public hideGrades$: Observable<boolean>;
  public hideSubsectionGrades$: Observable<boolean>;
  public hideSubGrades$: Observable<boolean>;
  public locale$: Observable<string>;
  public competitors$: Observable<Competitor[]>;
  public hideScheduleMeetingOption$: Observable<boolean>;
  public competitionAnalysis$: Observable<CompetitionAnalysis>;
  public hasCompetitorsConfigured$: Observable<boolean>;

  public expiryDate$: Observable<Date>;
  public isExpired$: Observable<boolean>;
  public isNonPartnerConfigExpired$: Observable<boolean>;
  public isLessThan24HoursOld$: Observable<boolean>;
  public isLessThan12HoursOld$: Observable<boolean>;

  public overallScore$: Observable<OverallScore>;

  public readonly previewUrl$: Observable<string>;
  public readonly sectionServices: Map<string, SectionServiceInterface> = new Map();
  public readonly generatePdfWorker = new ObservableWorkState<string>();

  static scoreForGrade(g: Grade): number {
    switch (g) {
      case Grade.A:
        return 100;
      case Grade.B:
        return 75;
      case Grade.C:
        return 50;
      case Grade.D:
        return 25;
      default:
        return 0;
    }
  }

  static overallScoreForGrades(grades: Grade[]): OverallScore {
    if (grades.length === 0) {
      return new OverallScore(0);
    }
    let sum = 0;
    grades.forEach((g) => {
      sum += SnapshotReportService.scoreForGrade(g);
    });
    return new OverallScore(Math.round(sum / grades.length));
  }

  get businessId(): string {
    return this.snapshotService.businessId;
  }

  get snapshotId(): string {
    return this.snapshotService.snapshotId;
  }

  get partnerId(): string {
    return this.snapshotService.partnerId;
  }

  get marketId(): string {
    return this.snapshotService.marketId;
  }

  setReviewGrade(g: Grade): void {
    this._reviewGrade$$.next(g);
  }

  setSocialGrade(g: Grade): void {
    this._socialGrade$$.next(g);
  }

  setWebsiteGrade(g: Grade): void {
    this._websiteGrade$$.next(g);
  }

  setListingGrade(g: Grade): void {
    this._listingGrade$$.next(g);
  }

  setAdvertisingGrade(g: Grade): void {
    this._advertisingGrade$$.next(g);
  }

  setSEOGrade(g: Grade): void {
    this._seoGrade$$.next(g);
  }

  setEcommerceGrade(g: Grade): void {
    this._ecommerceGrade$$.next(g);
  }

  constructor(
    private snapshotService: SnapshotService,
    private ssoService: SSOService,
    public whitelabel: WhitelabelService,
    public readonly snapshotApiService: SnapshotApiService,
  ) {
    this.snapshotService.snapshot$.subscribe((ss: SnapshotReport) => {
      this._enabledSections$$.next(ss.config.enabledSections);
    });
    this.snapshotService.summary$.subscribe((ss: SnapshotSummary) => {
      for (const section of ss.sections) {
        switch (section.sectionId) {
          case Section.WEBSITE:
            this._websiteGrade$$.next(section.grade);
            break;
          case Section.LISTINGS:
            this._listingGrade$$.next(section.grade);
            break;
          case Section.SOCIAL:
            this._socialGrade$$.next(section.grade);
            break;
          case Section.SEO:
            this._seoGrade$$.next(section.grade);
            break;
          case Section.ADVERTISING:
            this._advertisingGrade$$.next(section.grade);
            break;
          case Section.ECOMMERCE:
            this._ecommerceGrade$$.next(section.grade);
            break;
          case Section.REVIEWS:
            this._reviewGrade$$.next(section.grade);
            break;
          default:
            break;
        }
      }
    });

    this.snapshotId$ = this.snapshotService.snapshotId$;
    this.businessId$ = this.snapshotService.businessId$;
    this.snapshot$ = this.snapshotService.snapshot$;
    this.snapshotConfig$ = this.snapshotService.snapshotConfig$;
    this.snapshotData$ = this.snapshotService.snapshotData$;
    this.isPartnerConfig$ = this.snapshot$.pipe(
      take(1),
      map((s) => s.isPartnerConfig),
    );

    this.listingSectionEnabled$ = this.snapshotConfig$.pipe(
      map((c) => c.enabledSections.indexOf(EnabledSection.LISTINGS) >= 0),
    );
    this.reviewSectionEnabled$ = this.snapshotConfig$.pipe(
      map((c) => c.enabledSections.indexOf(EnabledSection.REVIEWS) >= 0),
    );
    this.socialSectionEnabled$ = this.snapshotConfig$.pipe(
      map((c) => c.enabledSections.indexOf(EnabledSection.SOCIAL) >= 0),
    );
    this.websiteSectionEnabled$ = this.snapshotConfig$.pipe(
      map((c) => c.enabledSections.indexOf(EnabledSection.WEBSITE) >= 0),
    );
    this.advertisingSectionEnabled$ = this.snapshotConfig$.pipe(
      map((c) => c.enabledSections.indexOf(EnabledSection.ADVERTISING) >= 0),
    );
    this.seoSectionEnabled$ = this.snapshotConfig$.pipe(map((c) => c.enabledSections.indexOf(EnabledSection.SEO) >= 0));
    this.ecommerceSectionEnabled$ = this.snapshotConfig$.pipe(
      map((c) => c.enabledSections.indexOf(EnabledSection.ECOMMERCE) >= 0),
    );
    this.technologySectionEnabled$ = this.snapshotConfig$.pipe(
      map((c) => c.enabledSections.indexOf(EnabledSection.TECHNOLOGY) >= 0),
    );

    this.reviewGrade$ = this._reviewGrade$$.asObservable();
    this.listingGrade$ = this._listingGrade$$.asObservable();
    this.socialGrade$ = this._socialGrade$$.asObservable();
    this.websiteGrade$ = this._websiteGrade$$.asObservable();
    this.advertisingGrade$ = this._advertisingGrade$$.asObservable();
    this.seoGrade$ = this._seoGrade$$.asObservable();
    this.ecommerceGrade$ = this._ecommerceGrade$$.asObservable();

    this.videoStyle$ = this.snapshotService.videoStyle$;
    this.hideImages$ = this.snapshotConfig$.pipe(map((c) => c.hideImages));
    this.hideGrades$ = this.snapshotService.hideGrades$;
    this.hideSubsectionGrades$ = this.snapshotConfig$.pipe(map((c) => c.hideSubsectionGrades));
    this.hideScheduleMeetingOption$ = this.snapshotConfig$.pipe(map((c) => c.hideScheduleMeetingOption));
    this.competitionAnalysis$ = this.snapshotConfig$.pipe(map((c) => c.competitionAnalysis));

    // This controls whether subsection letter grades are visible
    this.hideSubGrades$ = observableCombineLatest([this.hideGrades$, this.hideSubsectionGrades$]).pipe(
      map(([hideGrades, hideSubGrades]) => {
        return hideGrades || hideSubGrades;
      }),
    );

    this.locale$ = this.snapshotConfig$.pipe(map((c) => c.locale));

    this.competitors$ = this.snapshotService.competitors$;

    this.expiryDate$ = this.snapshot$.pipe(map((c) => c.expired));
    this.isExpired$ = this.expiryDate$.pipe(map((d) => d < new Date()));
    this.isLessThan24HoursOld$ = this.snapshot$.pipe(map((c) => this.isLessThanXHoursOld(c.created, 24)));
    this.isLessThan12HoursOld$ = this.snapshot$.pipe(map((c) => this.isLessThanXHoursOld(c.created, 12)));

    // This checks whether a snapshot is expired but excludes partner config snapshots from the check
    this.isNonPartnerConfigExpired$ = this.businessId$.pipe(
      filter((id) => id !== 'PARTNER_CONFIG'),
      switchMapTo(this.isExpired$),
      startWith(false),
    );

    this.hasCompetitorsConfigured$ = this.snapshotConfig$.pipe(
      take(1),
      map((c) => c.competitors.length > 0),
    );

    const enabledGrades$ = observableCombineLatest([
      this.listingSectionEnabled$,
      this.reviewSectionEnabled$,
      this.socialSectionEnabled$,
      this.websiteSectionEnabled$,
      this.advertisingSectionEnabled$,
      this.seoSectionEnabled$,
      this.ecommerceSectionEnabled$,
    ]).pipe(
      map(
        ([
          listingsEnabled,
          reviewsEnabled,
          socialEnabled,
          websiteEnabled,
          advertisingEnabled,
          seoEnabled,
          ecommerceEnabled,
        ]) => {
          const enabledGrades: Observable<Grade>[] = [];
          if (listingsEnabled) {
            enabledGrades.push(this.listingGrade$);
          }
          if (reviewsEnabled) {
            enabledGrades.push(this.reviewGrade$);
          }
          if (socialEnabled) {
            enabledGrades.push(this.socialGrade$);
          }
          if (websiteEnabled) {
            enabledGrades.push(this.websiteGrade$);
          }
          if (advertisingEnabled) {
            enabledGrades.push(this.advertisingGrade$);
          }
          if (seoEnabled) {
            enabledGrades.push(this.seoGrade$);
          }
          if (ecommerceEnabled) {
            enabledGrades.push(this.ecommerceGrade$);
          }
          return enabledGrades;
        },
      ),
    );

    this.overallScore$ = enabledGrades$.pipe(
      mergeMap((grades$) => {
        return observableCombineLatest(grades$).pipe(
          map((grades) => {
            const filteredGrades = grades.filter((grade) => grade !== Grade.NO_GRADE);
            return SnapshotReportService.overallScoreForGrades(filteredGrades);
          }),
        );
      }),
    );

    this.previewUrl$ = this.snapshot$.pipe(
      filter((snapshot) => !!snapshot),
      switchMap((snapshot) => {
        return this.ssoService.getEntryUrl('VBC', newPartnerServiceContext(snapshot.data.partnerId)).pipe(
          map((entryUrl) => {
            const previewUrl = entryUrl.replace('/entry', '/preview');
            const urlObj = new URL(previewUrl);
            const params = new URLSearchParams(urlObj.search);
            params.append('businessId', snapshot.businessId);
            urlObj.search = params.toString();
            return urlObj.toString();
          }),
        );
      }),
      take(1),
      shareReplay(1),
    );
  }

  updateConfig(config: SnapshotConfigInterface): Observable<any> {
    return this.snapshotService.updateConfig(config);
  }

  updateLanguageConfig(config: LanguageConfigInterface): Observable<any> {
    return this.snapshotService.updateLanguageConfig(config);
  }

  toggleSection(s: EnabledSection, hide: boolean): Observable<any> {
    let enabledSections = this._enabledSections$$.getValue().slice(); // slice to create copy
    if (!hide && enabledSections.indexOf(s) < 0) {
      enabledSections.push(s);
    } else if (hide) {
      enabledSections = enabledSections.filter((es: EnabledSection) => es !== s);
    }
    return this.snapshotService.updateConfig({ enabledSections: enabledSections });
  }

  getPartnerId(): Observable<string> {
    return this.snapshotData$.pipe(map((sd) => sd.partnerId));
  }

  getMarketId(): Observable<string> {
    return this.snapshotData$.pipe(map((sd) => sd.marketId));
  }

  isLessThanXHoursOld(created: Date, hoursAgo: number): boolean {
    const createdInHoursAgo = differenceInHours(new Date(), created);
    return createdInHoursAgo < hoursAgo;
  }

  generatePdf(): Observable<string> {
    const work$ = this.snapshotId$.pipe(
      take(1),
      switchMap((snapshotId) => this.snapshotApiService.generatePdf({ snapshotId })),
      map((response) => response.pdf),
    );
    this.generatePdfWorker.startWork(work$);
    return this.generatePdfWorker.isLoading$.pipe(
      debounceTime(100),
      filter((isLoading) => !isLoading),
      take(1),
      switchMap(() => this.generatePdfWorker.isSuccess$),
      tap((isSuccess) => {
        if (!isSuccess) {
          throw new Error('PDF generation failed');
        }
      }),
      switchMap(() => this.generatePdfWorker.workResults$),
    );
  }
}
