import { Component, Input } from '@angular/core';

@Component({
  selector: 'va-badge',
  styleUrls: ['./va-badge.component.scss'],
  template: ` <span class="badge badge--{{ this.color }}"><ng-content /></span> `,
})
export class VaBadgeComponent {
  @Input() color: string;
}
