import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UIKitModule } from '@vendasta/uikit';
import { LoginComponent } from './login.component';

const MaterialModules = [MatCardModule, MatButtonModule, MatInputModule, MatSnackBarModule];

@NgModule({
  imports: [MaterialModules, FormsModule, BrowserAnimationsModule, UIKitModule],
  declarations: [LoginComponent],
})
export class LoginModule {}
