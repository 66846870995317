class Legend {
  position: string;

  constructor(data: any) {
    this.position = data.position;
  }
}

class TextStyle {
  color: string;

  constructor(data: any) {
    this.color = data.color;
  }
}

class Axis {
  textStyle: TextStyle;

  constructor(data: any) {
    this.textStyle = new TextStyle(data.textStyle);
  }
}

class ToolTip {
  isHtml: boolean;

  constructor(data: any) {
    this.isHtml = data.isHtml;
  }
}

class ChartArea {
  left: any;
  top: any;

  constructor(data: any) {
    this.left = data.left;
    this.top = data.top;
  }
}

class IntervalOptions {
  style: string;
  color: string;
  fillOpacity: number;
  lineWidth: number;
  barWidth: number;

  constructor(data: any) {
    this.style = data.style;
    this.color = data.color;
    this.fillOpacity = data.fillOpacity;
    this.lineWidth = data.lineWidth;
    this.barWidth = data.barWidth;
  }
}

class BarOptions {
  groupWidth: string;

  constructor(data: any) {
    this.groupWidth = data.groupWidth;
  }
}

class Options {
  fontName: string;
  fontSize: number;
  legend: Legend;
  axisTitlesPosition: string;
  colors: string[];
  intervals: IntervalOptions;
  bar: BarOptions;
  hAxis: Axis;
  vAxis: Axis;
  tooltip: ToolTip;
  chartArea: ChartArea;
  height: number;

  constructor(data: any) {
    this.fontName = data.fontName;
    this.fontSize = data.fontSize;
    this.legend = new Legend(data.legend);
    this.axisTitlesPosition = data.axisTitlesPosition;
    this.colors = data.colors;
    this.intervals = new IntervalOptions(data.intervals);
    this.bar = new BarOptions(data.bar);
    this.hAxis = new Axis(data.hAxis);
    this.vAxis = new Axis(data.vAxis);
    this.tooltip = new ToolTip(data.tooltip);
    this.chartArea = new ChartArea(data.chartArea);
    this.height = data.height;
  }
}

export class ChartData {
  chartType: string;
  dataTable: any[];
  options: Options;

  constructor(data: any) {
    this.chartType = 'BarChart';
    this.dataTable = [
      [
        'keyword',
        'value',
        {
          type: 'string',
          role: 'tooltip',
          p: { html: true },
        },
      ],
    ];
    this.dataTable = this.dataTable.concat(data);
    this.options = new Options({
      fontName: 'Arial',
      fontSize: 12,
      legend: { position: 'none' },
      axisTitlesPosition: 'none',
      colors: ['#358fea'],
      intervals: { position: 'none' },
      bar: { groupWidth: '60%' },
      hAxis: {
        textStyle: {
          color: '#999999',
        },
      },
      vAxis: {
        textStyle: {
          color: '#999999',
        },
      },
      tooltip: { isHtml: true },
      chartArea: {
        left: '30%',
        top: 30,
      },
      height: 300,
    });
  }
}
