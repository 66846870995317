import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, QueryParamsHandling, Router } from '@angular/router';
import { RetryConfig, retryer } from '@vendasta/rx-utils';
import { Snapshot, SnapshotApiService } from '@vendasta/snapshot';
import { catchError, combineLatest, map, Observable, of, switchMap, take } from 'rxjs';
import { devServer } from '../../globals';
import { AuthService } from '../auth/auth.service';

type RouteAction = 'view' | 'edit';
interface RouteData {
  snapshotId?: string;
  partnerId?: string;
  action?: RouteAction;
  loggedIn?: boolean;
}
interface FullPath {
  url: string;
}
interface RelativePath {
  path: string[];
  queryParams?: Params;
  queryParamsHandling?: QueryParamsHandling;
  relativeToParent?: boolean;
}
type NextUrl = FullPath | RelativePath;

const NotFoundUrl = '/snapshot/404';

/**
 * This component is used to redirect the user to the correct page based on the route params and query params.
 * It is used to handle the case where the user is not logged in and needs to be redirected to the login page,
 * or to handle the case where the user is logged in and needs to be redirected to the snapshot page.
 *
 * Redirects for unauthenticated users:
 * - `/<SNAPSHOT_ID>/edit`
 * - `/<SNAPSHOT_ID>/edit?partnerId=<PARTNER_ID>`
 * - `/session-transfer/<PARTNER_ID>/<SNAPSHOT_ID>/edit`
 * - `login-{env}.apigateway.co/<PARTNER_ID>/account-selector/login`
 * - `/redirect?state=SSO_STATE&code=CODE`
 * - `/session-transfer/<PARTNER_ID>/<SNAPSHOT_ID>/edit?partnerId=<PARTNER_ID>`
 * - `/edit/<SNAPSHOT_ID>`
 *
 * Redirects for authenticated users:
 * - `/<SNAPSHOT_ID>/edit`
 * - `/edit/<SNAPSHOT_ID>`
 */
@Component({
  selector: 'app-snapshot-session-redirect',
  template: `
    <mat-spinner diameter="48"></mat-spinner>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    `,
  ],
})
export class SnapshotSessionRedirectComponent implements OnInit {
  constructor(
    private readonly snapshotService: SnapshotApiService,
    private readonly authService: AuthService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.activatedRoute.paramMap,
      this.activatedRoute.queryParamMap,
      this.authService.getOAuth2SessionId(),
    ])
      .pipe(
        map(([pathParams, queryParams, sessionId]) => this.buildRouteData(pathParams, queryParams, Boolean(sessionId))),
        switchMap((data) => this.getNextUrl(data)),
        catchError(() => of(null)),
      )
      .subscribe((next) => this.navigate(next));
  }

  private parseAction(action?: string): RouteAction {
    if (action === 'edit') {
      return 'edit';
    }
    return 'view';
  }

  private buildRouteData(pathParams: ParamMap, queryParams: ParamMap, loggedIn: boolean): RouteData {
    const snapshotId = pathParams.get('snapshotId') ?? queryParams.get('snapshotId');
    const partnerId = pathParams.get('partnerId') ?? queryParams.get('partnerId');
    const action = pathParams.get('action') ?? queryParams.get('action');
    return { loggedIn, snapshotId, partnerId, action: this.parseAction(action) } as RouteData;
  }

  private getNextUrl(data: RouteData): Observable<NextUrl> {
    if (data.snapshotId) {
      if (data.loggedIn) {
        // redirects to the snapshot page
        return of({ path: [data.action, data.snapshotId], relativeToParent: true } as RelativePath);
      } else {
        if (data.partnerId) {
          // redirects to the guarded login page
          // adds the env param for local development to be redirected back to localhost
          const queryParams: Params = devServer ? { env: 'local' } : {};
          return of({
            path: ['session-transfer', data.partnerId, data.snapshotId, data.action],
            queryParams,
            relativeToParent: true,
          } as RelativePath);
        } else {
          // adds partnerId to the query params
          return this.getSnapshot(data.snapshotId).pipe(
            map((snapshot) => this.validPartnerId(snapshot)),
            map(
              (partnerId) => ({ path: [], queryParams: { partnerId }, queryParamsHandling: 'merge' } as RelativePath),
            ),
          );
        }
      }
    }
    throw new Error('Invalid route');
  }

  private getSnapshot(snapshotId: string): Observable<Snapshot> {
    const retryConfig: RetryConfig = {
      maxAttempts: 5,
      retryDelay: 100,
      timeoutMilliseconds: 10000,
    };
    return this.snapshotService.get({ snapshotId }).pipe(
      take(1),
      retryer(retryConfig),
      map((snapshot) => snapshot?.snapshot),
    );
  }

  private validPartnerId(snapshot: Snapshot): string {
    if (snapshot?.data?.partnerId) {
      return snapshot.data.partnerId;
    }
    throw new Error('Invalid snapshot');
  }

  private navigate(nextUrl?: NextUrl): void {
    if (nextUrl) {
      if (this.isFullPath(nextUrl)) {
        this.router.navigateByUrl(nextUrl.url);
      } else {
        this.router.navigate(nextUrl.path, {
          relativeTo: nextUrl.relativeToParent ? this.activatedRoute.parent : this.activatedRoute,
          queryParams: nextUrl.queryParams,
          queryParamsHandling: nextUrl.queryParamsHandling,
        });
      }
    } else {
      this.router.navigateByUrl(NotFoundUrl);
    }
  }

  private isFullPath(path: FullPath | RelativePath): path is FullPath {
    return (path as FullPath).url !== undefined;
  }
}
