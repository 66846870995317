<div class="rule flex align-start-start">
  <mat-icon class="rule-icon" [ngClass]="icon">{{ icon }}</mat-icon>
  <div>
    <h4>{{ audit.title }}</h4>
    <div>
      <span [innerHtml]="parsedAuditDescription"></span>
    </div>
    <ng-container *ngIf="audit.details.length > 0">
      <div class="details-container" [@expandRules]="showDetails ? 'on' : 'off'">
        <div *ngFor="let d of audit.details" class="details flex-column">
          <h4 [innerHtml]="d.title"></h4>
          <div *ngFor="let i of d.detailItems">{{ i }}</div>
        </div>
      </div>
      <a
        class="show-how-to-fix"
        (click)="showDetails = !showDetails"
        [trackEvent]="{
          eventName: !showDetails ? 'show-how-to-fix' : 'hide-how-to-fix',
          category: 'anchor',
          action: 'click'
        }"
      >
        <span *ngIf="!showDetails">
          {{ 'WEBSITE.SHOW_DETAILS' | translate }}
        </span>
        <span *ngIf="showDetails">
          {{ 'WEBSITE.HIDE_DETAILS' | translate }}
        </span>
      </a>
    </ng-container>
  </div>
</div>
