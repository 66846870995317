import { Injectable } from '@angular/core';
import { Package, PackageService, Status } from '@vendasta/marketplace-packages';
import { AppDomain, Domain, SnapshotService } from '@vendasta/snapshot';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MarketplaceService {
  private readonly partnerId$$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private readonly partnerId$ = this.partnerId$$.asObservable().pipe(distinctUntilChanged());
  private readonly marketId$$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private readonly marketId$ = this.marketId$$.asObservable().pipe(distinctUntilChanged());
  private readonly vbcDomain$: Observable<string>;
  private readonly packageBaseUrl$: Observable<string>;

  readonly activatablePackages$: Observable<Package[]>;

  constructor(private readonly snapshotService: SnapshotService, private readonly packageService: PackageService) {
    this.activatablePackages$ = combineLatest([this.partnerId$, this.marketId$]).pipe(
      switchMap(([partnerId, marketId]) =>
        this.packageService.loadAllPackages(partnerId, [Status.PUBLISHED], marketId, true, 10000),
      ),
      map((packages) => packages?.packages || []),
      catchError(() => of([])),
      shareReplay(1),
    );

    this.vbcDomain$ = this.partnerId$.pipe(
      switchMap((partnerId: string) => this.snapshotService.getDomain(partnerId, AppDomain.APP_DOMAIN_VBC)),
      map((d) => this.urlFromDomain(d)),
      shareReplay(1),
    );

    this.packageBaseUrl$ = combineLatest([this.vbcDomain$, this.partnerId$, this.marketId$]).pipe(
      map(([domain, pid, mid]) => `${domain}/public/store/${pid}/${mid}/package/`),
      shareReplay(1),
    );
  }

  load(partnerId: string, marketId: string): void {
    this.partnerId$$.next(partnerId);
    this.marketId$$.next(marketId || 'default');
  }

  packageUrl$(packageId: string): Observable<string> {
    return this.packageBaseUrl$.pipe(map((url) => url + packageId));
  }

  private urlFromDomain(domain: Domain): string {
    const protocol = domain.secure ? 'https' : 'http';
    return `${protocol}://${domain.domain}`;
  }
}
