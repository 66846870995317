import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { CompetitorApiService, CompetitorInterface, CreateCompetitorResponseInterface } from '@vendasta/account-group';

@Injectable()
export class AccountGroupCompetitorsService {
  constructor(private api: CompetitorApiService) {}

  public getCompetitors(businessId: string, cursor: string, pageSize: number): Observable<CompetitorInterface[]> {
    const req = { businessId: businessId, pagingOptions: { cursor: cursor, pageSize: pageSize } };
    return this.api.getCompetitors(req).pipe(
      map((res) => res.competitors),
      catchError(() => observableOf([])),
    );
  }

  public create(businessId: string, competitor: any): Observable<CreateCompetitorResponseInterface> {
    const req = {
      businessId: businessId,
      location: {
        name: competitor.name,
        url: competitor.url,
        placeId: competitor.placeId,
      },
    };
    return this.api.create(req);
  }

  public update(businessId: string, competitor: any): Observable<HttpResponse<null>> {
    const req = {
      competitor: {
        businessId: businessId,
        competitorId: competitor.competitorId,
        location: {
          name: competitor.name,
          url: competitor.url,
          placeId: competitor.placeId,
        },
      },
      fieldMask: {
        paths: ['name', 'url', 'placeId'],
      },
    };
    return this.api.update(req);
  }

  public delete(businessId: string, competitorId: string): Observable<HttpResponse<null>> {
    const req = {
      businessId: businessId,
      competitorId: competitorId,
    };
    return this.api.delete(req);
  }
}
