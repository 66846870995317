import { Component, ContentChild, Input, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ItemToggleComponent } from './item-toggle/item-toggle.component';
import { ItemToggleDirective } from './item-toggle/item-toggle.directive';
import { ToggleType } from './toggled-item';

@Component({
  selector: 'snap-toggled-item',
  templateUrl: './toggled-item.component.html',
  styleUrls: ['./toggled-item.component.scss'],
})
export class ToggledItemComponent implements OnChanges {
  @Input() hideControl: UntypedFormControl;
  @Input() edit: boolean;
  @Input() type: ToggleType = 'checkbox';
  @ContentChild(ItemToggleDirective, { static: true }) itemToggleRef: ItemToggleDirective;
  private _itemToggleInstance: ItemToggleComponent = null;

  get hide(): boolean {
    return this.hideControl?.value ?? false;
  }

  ngOnChanges(): void {
    this._injectItemToggle();
  }
  private _injectItemToggle(): void {
    if (this.edit && this.hideControl !== undefined && this.itemToggleRef && !this._itemToggleInstance) {
      const viewContainerRef = this.itemToggleRef.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent(ItemToggleComponent);
      this._itemToggleInstance = <ItemToggleComponent>componentRef.instance;
      this._itemToggleInstance.hideControl = this.hideControl;
      this._itemToggleInstance.type = this.type;
    }
  }
}
