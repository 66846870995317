<mat-card-content>
  <div class="prompt-container">
    <hr />
    <div class="prompt">
      {{
        'COMPETITORS.KEYWORD_COMPETITORS.DESCRIPTION'
          | translate : { snapshotReportsLabel: ('TITLES.SNAPSHOT_REPORTS' | translate) }
      }}
    </div>
  </div>
  <form [formGroup]="competitorsForm" (ngSubmit)="onSubmit(competitorsForm.value)" class="form">
    <div formArrayName="competitors" *ngFor="let competitor of formCompetitors.controls; let i = index">
      <div class="competitor-label">{{ 'COMPETITORS.COMPETITOR' | translate }}&nbsp;{{ i + 1 }}</div>
      <div [formGroupName]="i" class="row">
        <mat-form-field class="competitor-name col" appearance="outline">
          <mat-label>
            {{ 'COMPETITORS.KEYWORD_COMPETITORS.BUSINESS_NAME' | translate }}
          </mat-label>
          <button mat-icon-button type="button" class="grey-icon" (click)="clearName(i)" matSuffix>
            <mat-icon>close</mat-icon>
          </button>
          <div class="map-page">
            <div class="map-search">
              <div class="map-search--field">
                <input
                  #search
                  matInput
                  formControlName="name"
                  (focus)="onFocusInputSearch(i)"
                  autocorrect="off"
                  placeholder=""
                  autocapitalize="off"
                  spellcheck="off"
                />
              </div>
            </div>
          </div>
        </mat-form-field>
        <mat-form-field class="col">
          <mat-label>
            {{ 'COMPETITORS.KEYWORD_COMPETITORS.WEBSITE_URL' | translate }}
          </mat-label>
          <button mat-icon-button type="button" class="grey-icon" (click)="clearWebsite(i)" matSuffix>
            <mat-icon>close</mat-icon>
          </button>
          <input matInput formControlName="website" />
        </mat-form-field>
      </div>
    </div>
    <!-- map object to create the google search autocomplete field  -->
    <div class="hidden">
      <google-map (mapInitialized)="onMapReady($event)"></google-map>
    </div>

    <div class="form-element save-button row">
      <button
        mat-flat-button
        color="primary"
        type="submit"
        class="button update-competitors"
        [disabled]="isExpired || isUpdatingCompetitors"
      >
        <span *ngIf="!isUpdatingCompetitors">
          {{ 'COMPETITORS.SAVE_BUTTON' | translate }}
        </span>
        <span *ngIf="isUpdatingCompetitors">
          <mat-progress-spinner mode="indeterminate" diameter="24"></mat-progress-spinner>
        </span>
      </button>
      <mat-hint>
        <span class="footer-hint">
          {{ 'COMPETITORS.UPDATE_COMPETITORS_DISCLAIMER' | translate }}
        </span>
      </mat-hint>
    </div>
  </form>
</mat-card-content>
