import { Config, ConfigInterface } from '../section/section';

export interface ReviewConfigInterface extends ConfigInterface {
  hideReviewsFound?: boolean;
  hideReviewsFoundPerMonth?: boolean;
  hideAverageReviewScore?: boolean;
  hideNumberOfReviewSources?: boolean;
}

export class ReviewConfig extends Config implements ReviewConfigInterface {
  hideReviewsFound: boolean;
  hideReviewsFoundPerMonth: boolean;
  hideAverageReviewScore: boolean;
  hideNumberOfReviewSources: boolean;

  constructor(config: ReviewConfigInterface) {
    super(config);
    this.hideReviewsFound = !!config.hideReviewsFound;
    this.hideReviewsFoundPerMonth = !!config.hideReviewsFoundPerMonth;
    this.hideAverageReviewScore = !!config.hideAverageReviewScore;
    this.hideNumberOfReviewSources = !!config.hideNumberOfReviewSources;
  }
}
