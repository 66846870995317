<div class="grade-table flex-column">
  <div class="heading flex align-start-center">
    <span>{{ title }}</span>
  </div>
  <a [href]="defaultLink" target="_blank">
    <div class="rule should-fix flex align-start-center" *ngIf="errorCount > 0">
      <mat-icon>error</mat-icon>
      <div class="flex align-start-center">
        <span>
          {{ errorCount }}
          <span>{{ 'WEBSITE.SHOULD_FIX' | translate }}</span>
        </span>
      </div>
    </div>
  </a>
  <a [href]="defaultLink" target="_blank">
    <div class="rule consider-fixing flex align-start-center" *ngIf="warningCount > 0">
      <mat-icon>warning</mat-icon>
      <div class="flex align-start-center">
        <span>
          {{ warningCount }}
          <span>{{ 'WEBSITE.CONSIDER_FIX' | translate }}</span>
        </span>
      </div>
    </div>
  </a>
  <a [href]="defaultLink" target="_blank">
    <div class="rule passed-rules flex align-start-center" *ngIf="passedCount > 0">
      <mat-icon>check_circle</mat-icon>
      <div class="flex align-start-center">
        <span>
          {{ passedCount }}
          <span>{{ 'WEBSITE.PASSED_RULES' | translate }}</span>
        </span>
      </div>
    </div>
  </a>
</div>
