import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataProviderAccuracyStatusV2 } from '@vendasta/snapshot';
import { ImageService } from '../../../core';

@Component({
  selector: 'snap-listing-distribution-status',
  templateUrl: './listing-distribution-status.component.html',
  styleUrls: ['./listing-distribution-status.component.scss'],
})
export class ListingDistributionStatusComponent implements OnChanges {
  @Input() status: DataProviderAccuracyStatusV2;
  @Input() source: string;

  icon: string;
  text: string;
  class: string;
  image: string;

  constructor(private imageService: ImageService) {}

  ngOnChanges(ch: SimpleChanges): void {
    if (ch?.status?.currentValue) {
      const status = ch.status.currentValue;
      this.icon = this.statusIcon(status);
      this.text = this.statusText(status);
      this.class = this.statusClass(status);
    }
    if (ch?.source?.currentValue) {
      const src = ch.source.currentValue;
      this.image = this.statusImage(src);
    }
  }

  statusText(status: DataProviderAccuracyStatusV2): string {
    switch (status) {
      case DataProviderAccuracyStatusV2.ACCURATE_V2:
        return 'COMMON.ACCURATE';
      case DataProviderAccuracyStatusV2.CONTAINS_ERRORS_V2:
        return 'COMMON.CONTAINS_ERRORS';
      case DataProviderAccuracyStatusV2.NOT_FOUND_V2:
        return 'COMMON.NOT_FOUND';
      default:
        return '';
    }
  }

  statusIcon(status: DataProviderAccuracyStatusV2): string {
    switch (status) {
      case DataProviderAccuracyStatusV2.ACCURATE_V2:
        return 'check_circle';
      case DataProviderAccuracyStatusV2.CONTAINS_ERRORS_V2:
        return 'warning';
      case DataProviderAccuracyStatusV2.NOT_FOUND_V2:
        return 'cancel';
      default:
        return '';
    }
  }

  statusClass(status: DataProviderAccuracyStatusV2): string {
    switch (status) {
      case DataProviderAccuracyStatusV2.ACCURATE_V2:
        return 'status-text accurate';
      case DataProviderAccuracyStatusV2.CONTAINS_ERRORS_V2:
        return 'status-text contains_errors';
      case DataProviderAccuracyStatusV2.NOT_FOUND_V2:
        return 'status-text not_found';
      default:
        return '';
    }
  }

  statusImage(source: string): string {
    switch (source) {
      case 'factual':
        return this.imageService.getSrc('factual.png');
      case 'localeze':
        return this.imageService.getSrc('neustar.png');
      case 'infogroup':
        return this.imageService.getSrc('data-axle.png');
      case 'foursquare':
        return this.imageService.getSrc('foursquare.png');
      default:
        return '';
    }
  }
}
