<ng-container *ngIf="organicDomainMetrics$ | async as domainMetrics">
  <snap-subsection
    heading="{{ 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.HEADING' | translate }}"
    subheading="{{ 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.SUBHEADING' | translate }}"
    [edit]="edit"
    [hideControl]="controls.controls['hideOrganicSeoData']"
    [grade]="domainMetrics?.grade"
    [hideGrade]="snapshotReportService.hideSubGrades$ | async"
  >
    <snap-organic-keywords
      [business]="domainMetrics?.business"
      [competitors]="competitorsOrganicDomainMetrics$ | async"
    ></snap-organic-keywords>
  </snap-subsection>
</ng-container>
