<ng-container *ngIf="score !== null">
  <svg [attr.width]="diameter + 'px'" [attr.height]="diameter + 'px'" viewBox="0 0 38 38" class="donut">
    <circle class="donut-hole" cx="19" cy="19" r="15.91549430918952" fill="#fff"></circle>
    <text class="score-text" text-anchor="middle" x="19" y="23">{{ score }}%</text>
    <circle
      class="donut-ring"
      cx="19"
      cy="19"
      r="15.91549430918952"
      fill="transparent"
      stroke="#efefef"
      [attr.stroke-width]="strokeWidth"
    ></circle>
    <circle
      class="donut-segment"
      cx="19"
      cy="19"
      r="15.91549430918952"
      fill="transparent"
      [ngClass]="{
        'red-stroke': score < 50,
        'yellow-stroke': score >= 50 && score < 80,
        'green-stroke': score >= 80
      }"
      [attr.stroke-width]="strokeWidth"
      [attr.stroke-dasharray]="score + ' ' + (100 - score)"
      stroke-dashoffset="25"
    ></circle>
  </svg>
</ng-container>
<mat-spinner *ngIf="score === null" mode="indeterminate" [diameter]="60"></mat-spinner>
