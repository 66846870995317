<mat-accordion>
  <mat-expansion-panel
    *ngIf="snapshotService.snapshotData$ | async as snapshotData"
    [expanded]="(media.mobile$ | async) === false || (media.print$ | async)"
    [disabled]="(media.mobile$ | async) === false || (media.print$ | async)"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header
      [trackEvent]="{
        eventName: panelOpenState ? 'collapse-business-details' : 'expand-business-details',
        category: 'anchor',
        action: 'click'
      }"
      data-testid="business-details-header"
    >
      <mat-panel-title>
        <span class="header">{{ 'TITLES.BUSINESS_DETAILS' | translate }}</span>
      </mat-panel-title>

      <glxy-popover
        #popover
        [isOpen]="showPopover"
        [hasBackdrop]="true"
        (backdropClick)="hidePopover()"
        [highContrast]="true"
      >
        <div *ngIf="edit; else viewModePopup">
          {{ 'INCORRECT_WEBSITE_INFO.TOOLTIP_EDIT_MODE' | translate }}
          <a (click)="redirectToEditBusinessDetails()" data-testid="redirect-to-website-link">
            {{ 'BUSINESS_DETAILS.EDIT_ACCOUNT' | translate }}
          </a>
        </div>

        <ng-template #viewModePopup>
          {{ 'INCORRECT_WEBSITE_INFO.TOOLTIP_VIEW_MODE' | translate }}
          <a (click)="openAskQuestionDialog('mobile-header')" data-testid="contact-us-link">
            {{ 'COMMON.CONTACT_US' | translate | lowercase }}
          </a>
        </ng-template>
      </glxy-popover>

      <div class="text-container small-header hide-to-print" *ngIf="(hasWebsite$ | async) === false">
        <div class="more-info-tooltip" (click)="openPopover()">
          <span class="hide-mobile" data-testid="wrong-info-link" [glxyPopover]="popover">
            {{ 'INCORRECT_WEBSITE_INFO.TITLE' | translate }}
          </span>
          <mat-icon [inline]="true" data-testid="icon">help_outline</mat-icon>
        </div>
        <div class="toggle-section">
          <ng-template snapItemToggle></ng-template>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="row" data-testid="business-details-body">
      <div class="col-xs-12 nap" [ngClass]="{ 'col-sm-3': layout !== 'compact' }">
        <div class="company-name" data-testid="company-name">
          <div class="label">{{ 'BUSINESS_DETAILS.NAME' | translate }}</div>
          <div class="value">{{ snapshotData?.companyName }}</div>
        </div>
        <div class="address" *ngIf="!snapshotData?.serviceAreaBusiness" data-testid="address">
          <div class="label">{{ 'BUSINESS_DETAILS.ADDRESS' | translate }}</div>
          <div class="value">{{ snapshotData?.address }}</div>
          <div class="value">
            <span>{{ snapshotData?.city | cityState : snapshotData?.state }}</span>
          </div>
          <div class="value">{{ snapshotData?.zip }}</div>
        </div>
        <div class="phone" data-testid="phone">
          <div class="label">
            <span>{{ 'BUSINESS_DETAILS.PHONE_NUMBER' | translate }}</span>
          </div>
          <div class="value">
            <ng-container *ngIf="snapshotData?.phoneNumbers?.length > 0">
              <span>{{ snapshotData?.phoneNumbers[0] | phone : snapshotData?.country }}</span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-xs-12 nap" [ngClass]="{ 'col-sm-3': layout !== 'compact' }">
        <div class="website" data-testid="website">
          <div class="label">
            <span>{{ 'BUSINESS_DETAILS.WEBSITE' | translate }}</span>
          </div>
          <div class="value">
            <div *ngIf="hasWebsite$ | async; else websiteMissing">
              <a
                target="_blank"
                [trackEvent]="{
                  eventName: 'website-link',
                  category: 'external-link',
                  action: 'click'
                }"
                data-testid="business-details-website"
                href="{{ snapshotData?.website || snapshotData?.inferredFields?.website }}"
              >
                <span>{{ snapshotData?.website || snapshotData?.inferredFields?.website }}</span>
              </a>
            </div>
            <ng-template #websiteMissing>
              <span class="nap-field-missing" data-testid="business-details-website-missing">
                {{ 'COMMON.MISSING' | translate }}
              </span>
            </ng-template>
          </div>
        </div>
        <div class="taxonomy" data-testid="taxonomy">
          <div class="label">{{ 'BUSINESS_DETAILS.CATEGORY' | translate }}</div>
          <div class="value">
            <category-overview
              [categoryType]="categoryType$ | async"
              [categoryIds]="categoryIds$ | async"
              [placeholder]="'BUSINESS_DETAILS.NO_CATEGORY_SELECTED' | translate"
            ></category-overview>
          </div>
        </div>
      </div>

      <div
        class="col-xs-12"
        [ngClass]="{ 'col-sm-6': layout !== 'compact' }"
        *ngIf="(snapshotService.hideImages$ | async) === false"
      >
        <div class="row business-images">
          <div *ngFor="let url of imageUrls$ | async">
            <img [src]="url" />
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
