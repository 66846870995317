import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Language, Media, MediaToken } from '@galaxy/snapshot';
import { SalesPersonInterface } from '@vendasta/snapshot';

@Component({
  selector: 'app-sidebar-contact',
  templateUrl: './sidebar-contact.component.html',
  styleUrls: ['./sidebar-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarContactComponent {
  @Input() salesPerson: SalesPersonInterface;
  @Input() partnerName = '';
  @Output() askQuestionClick = new EventEmitter<string>();
  @Output() languageSelected = new EventEmitter<Language>();

  constructor(@Inject(MediaToken) public readonly media: Media) {}

  handleAskQuestionClick(label: string): void {
    this.askQuestionClick.emit(label);
  }

  handleLanguageSelected(language: Language): void {
    this.languageSelected.emit(language);
  }
}
