<h2 mat-dialog-title>Snapshot Report is still generating</h2>
<mat-dialog-content
  [attr.data-testid]="'snapshot-not-ready-dialog-content'"
  class="snapshot-details-container not-ready-container"
>
  <div class="snapshot-status-container">
    <div class="icon-wrapper">
      <div>
        <mat-icon aria-label="Watch later" color="primary" class="watch-later-icon">watch_later</mat-icon>
      </div>
    </div>
    <h4>{{ 'SNAPSHOT_STATUS.ALMOST_READY' | translate }}</h4>
    <p>{{ 'SNAPSHOT_STATUS.CHECK_SOON' | translate }}</p>
    <glxy-alert type="tip" [size]="'small'">
      <span [innerHTML]="'SNAPSHOT_STATUS.NOTIFY_WHEN_IS_READY' | translate"></span>
    </glxy-alert>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    mat-dialog-close
    data-action="close-snapshot-not-ready-dialog"
    data-testid="confirm-snapshot-not-ready"
  >
    Okay
  </button>
</mat-dialog-actions>
