import { Injectable } from '@angular/core';
import { Environment, EnvironmentService } from '@galaxy/core';
import { MarketplaceApp, MarketplaceAppService } from '@galaxy/marketplace-apps/v1';
import { Observable, combineLatest, zip } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { IncludedProduct } from './business-create.service';
import { ConfigurationService } from './configuration/configuration.service';

// Owned and operated App IDs
export const AppIDs = {
  ReputationManagement: 'RM',
  SocialMarketing: 'SM',
  ListingBuilder: 'MS',
  AdvertisingIntelligence: 'MP-94072e44d5364872b672d7ab4fc7a7e8',
  CustomerVoice: 'MP-c4974d390a044c28aec31e421aa662b2',
  CalendarHero: 'MP-58G85F84DZBND7CK228578MWLD38SS4S',
};

// TODO: replace these with configurations on the partner level or on the product level
const DEFAULT_INCLUDED_PRODUCTS_PROD = [
  { appId: 'RM', editionId: 'EDITION-F7JZ5TV8' },
  { appId: AppIDs.ListingBuilder, editionId: '' },
  { appId: 'SM', editionId: 'EDITION-FVGBNLVZ' },
  { appId: 'MP-c4974d390a044c28aec31e421aa662b2', editionId: 'EDITION-TC8HJZNS' }, // Customer Voice
  { appId: AppIDs.AdvertisingIntelligence, editionId: '' }, // AdIntel
  { appId: AppIDs.CalendarHero, editionId: '' },
];

const DEFAULT_INCLUDED_PRODUCTS_DEMO = [
  { appId: 'RM', editionId: 'EDITION-38SMW45H' },
  { appId: AppIDs.ListingBuilder, editionId: '' },
  { appId: 'SM', editionId: 'EDITION-SWVF3WH8' },
  { appId: 'MP-fba21121b71148c9bb33e11fcd92d520', editionId: 'EDITION-4WWZC3RJ' }, // Customer Voice
  { appId: AppIDs.AdvertisingIntelligence, editionId: '' }, // AdIntel
  { appId: 'MP-M2R4V4KZH4QLSRM3ZZ6DQB7N8Z6PL5NL', editionId: '' }, // CalendarHero
];

const DEFAULT_INCLUDED_PRODUCTS = {
  [Environment.PROD]: DEFAULT_INCLUDED_PRODUCTS_PROD,
  [Environment.DEMO]: DEFAULT_INCLUDED_PRODUCTS_DEMO,
};

interface Configuration {
  maxShareOfVoiceKeywords: number;
  maxCompetitors: number;
  rmProductName: string;
  smProductName: string;
  msProductName: string;
  msDefaultEnabled: boolean;
  adintelDefaultEnabled: boolean;
}

const maxShareOfVoiceKey = 'rm_competition_max_services';
const maxCompetitorsKey = 'rm_competition_max_competitors';
const rmProductNameKey = 'rm_product_name';
const smProductNameKey = 'sm_product_name';
const msProductNameKey = 'ms_product_name';
const msDefaultEnabledKey = 'ms_always_enabled';
const adintelDefaultEnabledKey = 'adintel_default_enable_flag';

@Injectable()
export class IncludedProductsService {
  private readonly configKeys = [
    maxShareOfVoiceKey,
    maxCompetitorsKey,
    rmProductNameKey,
    smProductNameKey,
    msProductNameKey,
    msDefaultEnabledKey,
    adintelDefaultEnabledKey,
  ];

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly vconfigService: ConfigurationService,
    private readonly marketplaceAppService: MarketplaceAppService,
  ) {}

  getProductNames(appIds: string[]): Observable<{ appId: string; name: string }[]> {
    return this.marketplaceAppService.getMultiApps(appIds).pipe(
      map((apps: (MarketplaceApp | null)[]): { appId: string; name: string }[] => {
        return apps.map((a) => ({ appId: a.appId, name: a.name }));
      }),
    );
  }

  private getConfig(partnerId: string, marketId: string): Observable<Configuration> {
    return this.vconfigService.getConfigurations(partnerId, this.configKeys, marketId).pipe(
      shareReplay(1),
      map((val) => {
        return {
          maxShareOfVoiceKeywords: val[maxShareOfVoiceKey],
          maxCompetitors: val[maxCompetitorsKey],
          rmProductName: val[rmProductNameKey],
          smProductName: val[smProductNameKey],
          msProductName: val[msProductNameKey],
          msDefaultEnabled: val[msDefaultEnabledKey],
          adintelDefaultEnabled: val[adintelDefaultEnabledKey],
        };
      }),
    );
  }

  getIncludedProductsText(partnerId: string, marketId: string): Observable<string> {
    const configurations$ = this.getConfig(partnerId, marketId);

    const listingBuilderAppId = 'MS';
    const adIntelAppId = 'MP-94072e44d5364872b672d7ab4fc7a7e8';
    const alwaysIncludedProducts$ = this.getProductNames([listingBuilderAppId, adIntelAppId]);

    return zip(configurations$, alwaysIncludedProducts$).pipe(
      filter(([config]) => config.msDefaultEnabled || config.adintelDefaultEnabled),
      map(([config, products]) => {
        const includedProductNames = [];
        if (config.msDefaultEnabled) {
          const name = products ? products.filter((p) => p.appId === listingBuilderAppId)[0].name : 'Listing Builder';
          includedProductNames.push(name);
        }
        if (config.adintelDefaultEnabled) {
          const name = products ? products.filter((p) => p.appId === adIntelAppId)[0].name : 'Advertising Intelligence';
          includedProductNames.push(name);
        }
        return includedProductNames.join(', ');
      }),
    );
  }

  getIncludedProducts(partnerId: string, marketId: string): Observable<IncludedProduct[]> {
    const listingBuilderAppId = 'MS';
    const adIntelAppId = 'MP-94072e44d5364872b672d7ab4fc7a7e8';
    const alwaysIncludedProducts$ = this.getProductNames([listingBuilderAppId, adIntelAppId]);

    const configurations$ = this.getConfig(partnerId, marketId);

    const env = this.environmentService.getEnvironment();
    return combineLatest([configurations$, alwaysIncludedProducts$]).pipe(
      map(([config, products]) => {
        if (!(config.msDefaultEnabled || config.adintelDefaultEnabled)) {
          return [];
        }

        const includedProducts = [];
        if (products) {
          if (config.msDefaultEnabled) {
            const item: IncludedProduct = {
              appId: listingBuilderAppId,
              editionId: DEFAULT_INCLUDED_PRODUCTS[env].find((id) => id.appId === listingBuilderAppId).editionId || '',
            };
            includedProducts.push(item);
          }
          if (config.adintelDefaultEnabled) {
            const item: IncludedProduct = {
              appId: adIntelAppId,
              editionId: DEFAULT_INCLUDED_PRODUCTS[env].find((id) => id.appId === adIntelAppId).editionId || '',
            };
            includedProducts.push(item);
          }
        }
        return includedProducts;
      }),
    );
  }
}
