import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { LexiconModule } from '@galaxy/lexicon';
import { TaxonomyComponent } from './taxonomy.component';
import baseTranslation from './assets/i18n/en_devel.json';
import { WEBLATE_COMPONENT_NAME } from './constants';

@NgModule({
  imports: [
    TranslateModule,
    LexiconModule.forChild({
      componentName: WEBLATE_COMPONENT_NAME,
      baseTranslation: baseTranslation,
    }),
  ],
  exports: [TaxonomyComponent],
  declarations: [TaxonomyComponent],
  providers: [],
})
export class TaxonomyI18nModule {}
