import { Config, ConfigInterface } from '../section/section';

export interface AdvertisingConfigInterface extends ConfigInterface {
  hideSemSection?: boolean;
  hideSemPayPerClick?: boolean;
  hideSemRetargeting?: boolean;
  hideSemContent?: boolean;
  customizedSemMessage?: string;
  hideAdwordsSection?: boolean;

  hideSeoSection?: boolean;
  hideSeoContent?: boolean;
  customizedSeoMessage?: string;
}

export class AdvertisingConfig extends Config implements AdvertisingConfigInterface {
  hideSemSection: boolean;
  hideSemPayPerClick: boolean;
  hideSemRetargeting: boolean;
  hideSemContent: boolean;
  customizedSemMessage: string;
  hideAdwordsSection: boolean;

  hideSeoSection: boolean;
  hideSeoContent: boolean;
  customizedSeoMessage: string;

  constructor(data: AdvertisingConfigInterface) {
    super(data);
    this.hideSemSection = !!data.hideSemSection;
    this.hideSemPayPerClick = !!data.hideSemPayPerClick;
    this.hideSemRetargeting = !!data.hideSemRetargeting;
    this.hideSemContent = !!data.hideSemContent;
    this.customizedSemMessage = data.customizedSemMessage || '';
    this.hideAdwordsSection = !!data.hideAdwordsSection;
    this.hideSeoSection = !!data.hideSeoSection;
    this.hideSeoContent = !!data.hideSeoContent;
    this.customizedSeoMessage = data.customizedSeoMessage || '';
  }
}
