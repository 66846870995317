import { Component, Input } from '@angular/core';
import { ListingPresenceData } from './presence-table-item';
import { GradeColor } from '../../../core';

@Component({
  selector: 'snap-presence-table',
  templateUrl: './presence-table.component.html',
  styleUrls: ['./presence-table.component.scss'],
})
export class PresenceTableComponent {
  @Input() hideGrades: boolean;
  @Input() edit: boolean;
  @Input() items: ListingPresenceData[];

  // formats the number to at most 2 decimal places
  formatNumber(n: number): number {
    return Math.round(n * 100) / 100;
  }
  protected readonly GradeColor = GradeColor;
}
