import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureFlagStatusInterface, FeatureFlagService as PartnerFFService } from '@galaxy/partner';
import { SnapshotFeatureFlags, SnapshotReportService } from '@galaxy/snapshot';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';

enum FeatureFlags {
  Unset = '', // placeholder so we don't have an empty enum when no FF are set
  PrintReport = 'snap_print_button',
}

@Injectable()
export class FeatureFlagService {
  private readonly features: string[] = Object.values(FeatureFlags).filter(Boolean);
  private featuresEnabled$: Observable<FeatureFlagStatusInterface>;

  activeFeatures$: Observable<SnapshotFeatureFlags>;
  crankwheelEnabled$: Observable<boolean>;

  constructor(
    private readonly http: HttpClient,
    private readonly partnerFFService: PartnerFFService,
    private readonly snapshotReportService: SnapshotReportService,
  ) {
    this.setupFeatureFlags();
  }

  private setupFeatureFlags(): void {
    this.featuresEnabled$ = this.snapshotReportService.getPartnerId().pipe(
      distinctUntilChanged(),
      switchMap((partnerId) => this.fetchFeatureFlags(partnerId)),
      shareReplay(1),
    );
    this.activeFeatures$ = this.featuresEnabled$.pipe(
      map((featuresEnabled) => {
        const activeFeatures: SnapshotFeatureFlags = {
          unset: featuresEnabled[FeatureFlags.Unset],
          printReport: featuresEnabled[FeatureFlags.PrintReport],
        };
        return activeFeatures;
      }),
    );
  }

  private fetchFeatureFlags(partnerId: string): Observable<FeatureFlagStatusInterface> {
    if (this.features.length > 0) {
      return this.partnerFFService.batchGetStatus(partnerId, '', this.features);
    }
    return of({});
  }

  // Determining whether Crankwheel is enabled cannot currently be done with a feature flag, due to explicit
  // configuration needed on both ST and Crankwheel's end. This will eventually change to something permanent once
  // we are dynamically configuring Crankwheel.
  crankwheelEnabled(): Observable<boolean> {
    if (this.crankwheelEnabled$ == null) {
      this.crankwheelEnabled$ = this.http.head('/crankwheel/enabled').pipe(
        map(() => true),
        catchError(() => of(false) as Observable<boolean>),
        shareReplay(),
      );
    }
    return this.crankwheelEnabled$;
  }
}
