import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetSummaryResponse, Grade, LocationInterface, Section, SnapshotService } from '@vendasta/snapshot';
import { differenceInHours } from 'date-fns';
import { Observable, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  isCustomProposalSnapshotSection,
  ProposalSnapshotSection,
  ProposalSnapshotSectionType,
} from './select-snapshot-content';

export interface SnapshotReportDialogData {
  snapshotId: string;
  createProposal: boolean;
}

@Component({
  templateUrl: './select-snapshot-content.component.html',
  styleUrls: ['./select-snapshot-content.component.scss'],
})
export class SelectSnapshotContentComponent implements OnInit, OnDestroy {
  readonly snapshotSummary$: Observable<GetSummaryResponse | undefined>;
  sections: ProposalSnapshotSection[] = [];
  address: string;
  allSectionsSelected: boolean;
  isSnapshotReady: boolean;
  isSnapshotLoaded = false;
  createProposal = false;
  private _subscriptions: Subscription[] = [];
  isCustomProposalSnapshotSection = isCustomProposalSnapshotSection;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SnapshotReportDialogData,
    private readonly snapshotService: SnapshotService,
    public dialogRef: MatDialogRef<SelectSnapshotContentComponent, ProposalSnapshotSection[] | false>,
  ) {
    this.snapshotSummary$ = this.snapshotService.getSummary(data.snapshotId).pipe(catchError(() => of(undefined)));
    this.allSectionsSelected = true;
    this.createProposal = data.createProposal;
  }

  ngOnInit(): void {
    const snapshotSub = this.snapshotSummary$.subscribe((summary) => {
      if (!summary) {
        this.isSnapshotReady = false;
      } else {
        this.address = this.getAddress(summary.location);
        // filter out technology section
        const validSections = summary.sections.filter((s) => s.sectionId !== Section.TECHNOLOGY);
        const sections: ProposalSnapshotSection[] = validSections.map((s) => ({
          ...s,
          type: s.sectionId ?? 0,
          isSelected: this.allSectionsSelected,
        }));
        this.sections = [
          this.createSection('Cover page', this.allSectionsSelected, 'cover'),
          this.createSection('Overview page', this.allSectionsSelected, 'overview'),
          ...sections,
        ];
        this.isSnapshotReady =
          parseInt(summary.score, 0) > 0 ||
          summary.sections.some((s) => s.grade > Grade.NO_GRADE && s.grade < Grade.F) ||
          differenceInHours(new Date(), summary.created) >= 24;
      }

      this.isSnapshotLoaded = true;
    });

    this._subscriptions.push(snapshotSub);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  close(): void {
    this.dialogRef.close(this.sections.filter((s) => s.isSelected));
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  checkSelectedSections() {
    this.allSectionsSelected = this.sections.every((s) => s.isSelected);
  }

  someSectionsSelected(): boolean {
    return this.sections.some((s) => s.isSelected) && !this.allSectionsSelected;
  }

  setAllSections(selected: boolean) {
    this.allSectionsSelected = selected;

    this.sections.forEach((s) => (s.isSelected = selected));
  }

  getAddress(location: LocationInterface): string {
    if (!location) {
      return '';
    }
    let address = location.address || '';
    if (location.city) {
      address += ` ${location.city}`;
    }
    if (location.state) {
      address += `, ${location.state}`;
    }
    if (location.country) {
      address += `, ${location.country}`;
    }
    return address;
  }

  createSection(name: string, selected = false, type: ProposalSnapshotSectionType): ProposalSnapshotSection {
    return {
      sectionName: name,
      grade: Grade.NO_GRADE,
      isSelected: selected,
      type,
    };
  }
}
