import { Component, Input, OnInit } from '@angular/core';

const COLORS = {
  gradePositive: '#4CAF50',
  gradeNeutral: '#FFB300',
  gradeNegative: '#D32F2F',
};

@Component({
  selector: 'snap-overall-score',
  templateUrl: './overall-score.component.html',
  styleUrls: ['./overall-score.component.scss'],
})
export class OverallScoreComponent implements OnInit {
  @Input() overallScore: string;

  score: number;

  ngOnInit(): void {
    this.score = parseInt(this.overallScore, 0);
  }

  strokeColorForScore(score: number): string {
    if (score >= 80) {
      return COLORS.gradePositive;
    }
    if (score >= 50) {
      return COLORS.gradeNeutral;
    }
    return COLORS.gradeNegative;
  }
}
