{
  "TITLES": {
    "SNAPSHOT_REPORT": "Snapshot Report",
    "SNAPSHOT_REPORTS": "Snapshot Reports",
    "BUSINESS_DETAILS": "Business details",
    "OVERALL_SCORE": "Overall score",
    "LISTINGS": "Listings",
    "REVIEWS": "Reviews",
    "WEBSITE": "Website",
    "ADVERTISING": "Advertising",
    "SOCIAL": "Social",
    "SEO": "SEO",
    "ECOMMERCE": "Ecommerce",
    "TECHNOLOGY": "Technology"
  },
  "OVERALL": {
    "DESKTOP_TITLE": "Overall score for {{name}}",
    "GRADE_EXPLANATION": {
      "TITLE": "Overall score",
      "SECONDARY_PRE_SCALE": "We calculate your overall score by converting each section grade into a numerical score. We follow this scoring chart:",
      "SECONDARY_POST_SCALE": "Then, we add these scores and divide by a perfect score (4 X # of section grades). The result is displayed as a percentage and rounded to the nearest whole number."
    }
  },
  "SIDEBAR": {
    "NEED_ASSISTANCE": "Need assistance?"
  },
  "EDIT_REPORT": {
    "EMAIL_REPORT": "Email report",
    "VIEW_REPORT": "View report",
    "REFRESH": "Refresh",
    "CUSTOMIZE_REPORT": "Customize report",
    "SHOW_GRADES": "Show letter grades and overall score",
    "SHOW_SECONDARY_GRADES": "Show secondary letter grades",
    "SHOW_IMAGES": "Show images",
    "VIDEO_STYLE": "Video style",
    "LANGUAGE": "Language",
    "LANGUAGE_SWITCHER_NOTE": "Translation of the website section may take up to five minutes.",
    "EDIT_MESSAGE": "Edit message",
    "SAVE_MESSAGE": "Save message",
    "RESET": "Reset to default",
    "EDIT_PRIMARY_BUTTON": "Edit primary button",
    "SEND_EMAIL": "Send report via email",
    "SCREENSHARE_REPORT": "Screenshare report",
    "COMPETITORS": "Competitors",
    "UPDATE_COMPETITORS_DESCRIPTION": "Enter up to 3 competitors for the SEO and Advertising sections",
    "UPDATE_COMPETITORS": "Update competitors",
    "UPDATE_COMPETITORS_SUCCESS": "Successfully updated competitors, please refresh the page in a few minutes to get the new data.",
    "DIRECT_COMPETITORS": "Direct competitors",
    "UPDATE_DIRECT_COMPETITORS_DESCRIPTION": "Select up to 3 competitors for the Listing and Review sections",
    "SNAPSHOT_CREATED": "snapshot created: {{created}}",
    "BUTTON_PREVIEW": "Button preview",
    "CUSTOM_BUTTON_TEXT": "Custom button text",
    "CUSTOM_BUTTON_URL": "Custom button URL",
    "RADIO_GROUP_LABEL": "When clicked take users to...",
    "CONTACT_US_FORM": "Contact us form",
    "PRODUCT": "Product",
    "ERRORS": {
      "CUSTOM_URL_INVALID": "Please provide a valid URL",
      "PRODUCT_ID_INVALID": "Please select a Product",
      "DEACTIVATED_PRODUCT_ID": "The previous selected product is no longer valid",
      "UPDATE_CONFIG": "Error updating section configuration"
    },
    "NO_PRODUCTS_IN_STORE": "No products available in store",
    "UPDATE_CONFIG_SUCCESS": "Section configuration was updated",
    "LOCAL_SEO": {
      "TITLE": "Local SEO keyword",
      "SUBTITLE_GMB_CATEGORY": "Editing this business' keyword will refresh the results in the Local SEO section. By default, this keyword is set to the business category pulled in from Google Business Profile on account creation.",
      "SUBTITLE_WITH_KEYWORD": "Editing this business' keyword will refresh the results in the Local SEO section.",
      "SUBTITLE_BUSINESS_PROFILE_CATEGORY": "Editing this business' keyword will refresh the results in the Local SEO section. By default, this keyword is set to the category pulled in from the business category set on the business details.",
      "KEYWORD_LABEL": "Keyword",
      "UPDATE_BUTTON": "Update keyword",
      "UPDATE_WARNING": "It may take a few minutes for these results to appear. Please check back later.",
      "CHARACTERS_LENGTH_ERROR": "Keyword must not exceed 100 characters",
      "SNAPSHOT_EXPIRED": "This {{snapshotName}} is expired. In order to update this business' keyword, you'll first need to ",
      "REFRESH_REPORT": "refresh this report.",
      "PREVIEW_NEAR_ME": "'{{keyword}} near me'",
      "PREVIEW_CITY": "'{{keyword}} in {{city}}'",
      "KEYWORD_REQUIRED": "Keyword is required"
    },
    "MOBILE_PRIMARY_ACTION": {
      "TITLE": "Mobile primary button",
      "SUBTITLE": "Edit the report-wide primary button that appears instead of section-specific buttons when viewing the report on mobile."
    },
    "LINK_TO": "Link to",
    "BUTTON_LABEL": "Button label",
    "PREVIEW": "Preview"
  },
  "BOOKED_MEETINGS": {
    "TITLE": "Meetings",
    "BOOK_A_MEETING": "Book a meeting",
    "LABEL": "Allow viewers to book meetings",
    "SET_UP_MEETING_SCHEDULER": "Set up Meeting Scheduler"
  },
  "SHARE_SNAPSHOT": {
    "TITLE": "Send {{snapshotReportName}}",
    "SEND_FROM_TITLE": "Send from",
    "SEND_TO_TITLE": "Send to",
    "EMAIL_CONTENT_TITLE": "Email content",
    "SUCCESS": "Email scheduled to be sent.",
    "ERROR": "Failed to send email. Please try again later.",
    "REMOVE_RECIPIENT": "Remove recipient",
    "ADD_ANOTHER_RECIPIENT": "+ Add another recipient",
    "LABELS": {
      "FIRST_NAME": "First name",
      "LAST_NAME": "Last name",
      "EMAIL_MESSAGE": "Message",
      "ADD_CONTACT": "Add a contact email"
    },
    "EMAIL": {
      "SUBJECT": "Take a look at this report for {{companyName}}",
      "MESSAGE": "Hi,\n\nYou might be interested in this report that showcases how the business is performing online.\n\nRegards",
      "DIALOG_SALES_PERSON_MESSAGE": "Hi,\n\nThe {{snapshotName}} is a peek into what your business looks like online and how customers see you. It shows you where you're listed online from directories to review sites, social networks to blogs. See if you're easily searchable, what your customers think about you and how you compare to the industry average.\n\nFull information will be available on the report within 30 minutes. If you have any questions please contact your sales representative {{salesPersonName}} -{{salesPersonJobPosition}} at {{salesPersonEmail}} {{salesPersonPhoneNumber}}\n\nRegards",
      "DIALOG_NO_SALES_PERSON_DATA_MESSAGE": "Hi,\n\nThe {{snapshotName}} is a peek into what your business looks like online and how customers see you. It shows you where you're listed online from directories to review sites, social networks to blogs. See if you're easily searchable, what your customers think about you and how you compare to the industry average.\n\nFull information will be available on the report within 30 minutes.\n\nRegards",
      "GREETING": "Hey there",
      "OR_CALL": "or call",
      "BUTTON_TEXT": "View {{snapshotName}}",
      "CLOSING": "Kind regards"
    },
    "DIALOG": {
      "TO": "To",
      "SUBJECT": "Subject",
      "MESSAGE": "Message",
      "SENDER_NAME": "Sender name",
      "SENDER_EMAIL": "Sender email"
    },
    "AI_ACTIONS": {
      "SUGGEST_CONTENT": "Suggest content",
      "SUGGEST_GENERAL_CONTENT": "Suggest general content",
      "SUGGEST_SECTION_CONTENT": "Suggest section-specific content",
      "SUGGEST_SECTION_PLACEHOLDER": "Suggest content focused on...",
      "SUGGEST_SECTION_LABEL": "Suggest content focused on:",
      "FAILED": "Failed to generate the message"
    }
  },
  "COMPETITORS": {
    "COMPETITOR": "Competitor",
    "COMPETITORS_SECTION_DESCRIPTION": "Including competitors helps personalize your {{snapshotReportsLabel}} and connect with your prospects.",
    "SNAPSHOT_EXPIRED": "This {{snapshotName}} is expired. In order to edit competitors, you'll first need to ",
    "REFRESH_REPORT": "refresh this report.",
    "INDUSTRY_AVERAGE_ONLY": "Only compare to industry",
    "SPECIFIC": "Specific",
    "SAVE_BUTTON": "Save competitors",
    "UPDATE_COMPETITORS_DISCLAIMER": "The update may take a few minutes",
    "KEYWORD_COMPETITORS": {
      "RADIO_BUTTON": "Use competitors' websites",
      "DESCRIPTION": "'Use competitors' websites' will add competitor data for the Website, Ecommerce, SEO, and Advertising sections. To populate all sections, select 'Use competitors' {{snapshotReportsLabel}}'",
      "BUSINESS_NAME": "Business name",
      "WEBSITE_URL": "Website URL"
    },
    "DIRECT_COMPETITORS": {
      "RADIO_BUTTON": "Use competitors' {{snapshotReportsLabel}} in the same industry",
      "DESCRIPTION_1": "Requires existing {{snapshotReportsLabel}}",
      "DESCRIPTION_2": "'Use competitors' {{snapshotReportsLabel}} in the same industry' can be used if you've created {{snapshotReportsLabel}} for this business' competitors. This will add competitor data for all sections of the report."
    },
    "ACCOUNT_GROUP": {
      "COMPETITOR_NAME": "Competitor name",
      "COMPETITOR_URL": "Competitor URL",
      "COMPETITOR_DELETE_MESSAGE": "This competitor will be removed from business competitors.",
      "COMPETITOR_SAVE_SUCCESS": "Successfully updated competitors, please refresh the page in a few minutes to get the new data.",
      "COMPETITOR_SAVE_ERROR": "Error saving competitor",
      "COMPETITOR_DELETE_SUCCESS": "Competitor deleted successfully",
      "COMPETITOR_DELETE_ERROR": "Error deleting competitor",
      "COMPETITOR_NAME_REQUIRED": "Name is required",
      "COMPETITOR_DELETE": "Delete {{name}}?",
      "ADD_COMPETITOR": "+ Add competitor"
    },
    "CONFIRMATION_DATA": {
      "TITLE": "Change competitors",
      "TEXT": "You will lose the existing results by changing competitors. Do you want to proceed?"
    }
  },
  "VIEW_REPORT": {
    "LAST_UPDATED": "Report content last updated:",
    "PREPARED_BY": "PREPARED BY"
  },
  "REFRESH_REPORT": {
    "TITLE": "Refresh {{ snapshotName }}",
    "FEES": "Standard {{ snapshotName }} fees apply",
    "POPULATE": "Please be aware that data will take time to populate",
    "REOPEN": "Report is being refreshed. Please re-open the report shortly.",
    "REDIRECTING": "Report is refreshing. You will be redirected shortly.",
    "ERROR_PROVISIONING": "Failed to refresh. Please try again later."
  },
  "BUSINESS_DETAILS": {
    "NAME": "Name",
    "ADDRESS": "Address",
    "CITY": "City",
    "STATE": "State",
    "ZIP": "Zip",
    "PHONE_NUMBER": "Phone number",
    "WEBSITE": "Website",
    "INFERRED_WEBSITE": "Inferred website",
    "EDIT_ACCOUNT": "Edit the account",
    "CATEGORY": "Business category",
    "NO_CATEGORY_SELECTED": "Please select a category for this business"
  },
  "INCORRECT_WEBSITE_INFO": {
    "TITLE": "Not the right information?",
    "TOOLTIP_VIEW_MODE": "We inferred your data from Google. If it’s not correct information",
    "TOOLTIP_EDIT_MODE": "We inferred this information from Google. If it's not correct"
  },
  "COMMON": {
    "SHARE": "Share",
    "MISSING": "Missing",
    "CONTACT_US": "Contact us",
    "SPEED": "Speed",
    "SHOW_ALL": "Show all",
    "HIDE": "Hide",
    "OVERLAP": "Overlap",
    "KEYWORDS": "Keywords",
    "CLICKS": "Clicks",
    "BUDGET": "Budget",
    "VALUE": "Value",
    "YOU": "You",
    "YOUR_BUSINESS": "Your business",
    "YOUR_COMPETITORS": "Your competitors",
    "INDUSTRY_AVERAGE": "Industry average",
    "INDUSTRY_LEADER": "Industry leaders",
    "AVERAGE": "Average",
    "FIRST_NAME": "First name",
    "LAST_NAME": "Last name",
    "CANCEL": "Cancel",
    "SAVE": "Save",
    "DELETE": "Delete",
    "SEND": "Send",
    "ACCESS_NOW": "Access now",
    "NOT_FOUND": "Not found",
    "NO_MATCH": "No match",
    "VIEW_EXAMPLE": "View example",
    "TRY_AGAIN": "Try again",
    "ACCURATE": "Accurate",
    "CONTAINS_ERRORS": "Contains errors",
    "FOUND": "Found",
    "EMAIL_ADDRESS": "Email address",
    "ENTER_EMAIL_ADDRESS": "Enter email address",
    "GET_IN_TOUCH": "Get in touch",
    "ADD": "Add",
    "INVALID_URL": "Invalid URL",
    "UPDATE": "Update",
    "RANK": "Rank",
    "COMPETITIVENESS": "Competitiveness",
    "SEARCHES": "Searches",
    "FOLLOWERS": "Followers",
    "PHONE_NUMBER": "Phone number",
    "GRADE_INFO": "How is this grade calculated?",
    "PERCENTILE": "Percentile",
    "EDIT": "Edit",
    "PROCEED": "Proceed",
    "LEARN_MORE": "Learn more",
    "RECOMMENDATION": "Recommendation",
    "WHY_IT_MATTERS": "Why it matters",
    "MORE": "more",
    "NONE_FOUND": "None found",
    "PREVIEW": "Preview",
    "SUBJECT": "Subject",
    "GREETING": "Greeting",
    "CLOSING": "Closing",
    "BUTTON_TEXT": "Button text",
    "LINK": "Link",
    "SIZE": "Size: ",
    "CALL": "Call",
    "SEND_MESSAGE": "Send message",
    "CREATED": "Created",
    "ACCOUNT_URL": "Account URL",
    "WATCH_VIDEO": "Watch video",
    "OTHER": "other",
    "OTHERS": "others",
    "DOWNLOAD": "Download",
    "PRINT": "Print"
  },
  "ERRORS": {
    "ERROR_LOADING_PAGE": "There was an error loading this page",
    "EMAIL_REQUIRED": "An email is required",
    "EMAIL_INVALID": "Not a valid email",
    "PHONE_NUMBER_REQUIRED": "A phone number is required",
    "MESSAGE_REQUIRED": "A message is required",
    "MESSAGE_TOO_LONG": "Message is too long",
    "CONTACT_SALES_REP": "There was an error contacting your sales representative, please try again.",
    "ERROR_LOADING_TITLE": "Something's missing here...",
    "ERROR_LOADING_MESSAGE": "We're having trouble loading this section. Let's try again.",
    "STILL_WORKING_TITLE": "We're still working on this",
    "STILL_WORKING_DESCRIPTION": "It can take up 24 hours for us to finish gathering data. Check back soon!",
    "ALREADY_CLAIMED": "This account has already been claimed.",
    "ERROR_CLAIMING": "There was an error claiming this account.",
    "competitor-name-required": "Competitor name is required",
    "must-be-in-form-of-http://google.com": "Website must be in the form of http://google.com",
    "GENERIC_ERROR": "We've encountered an error, please try again later.",
    "REQUIRED": "Required",
    "MISSING_SCAN_DATA": "Please correct your business data to view your scan",
    "OVER_QUOTA": "Listing Scan is currently over quota, please wait 30 minutes and try again. Sorry for the inconvenience",
    "DIRECT_COMPETITOR_INVALID": "Please select one business as a competitor",
    "DIRECT_COMPETITOR_DUPLICATED": "Please select another business as a competitor",
    "ERROR_GENERATING_PDF": "There was an error generating the PDF",
    "ERROR_LOADING_LISTINGS": "Could not load listings, please try again."
  },
  "LISTINGS": {
    "TAGLINE": "Can consumers find your business?",
    "PRESENCE": {
      "HEADING": "Listing presence",
      "SUBHEADING": "Total number of online listings found on sites monitored for your business",
      "FOUND": "Total listings found for your business",
      "FOUND_COMPETITORS": "Your listings vs. the competition",
      "COMPARE": "Here’s how many listings you have compared to your competitors",
      "INDUSTRY_FOUND": "Available listing sites",
      "SOCIAL_SITE": "Social site",
      "SOCIAL_SITES": "Social sites",
      "NUMBER_OF_LISTINGS": "Number of listings",
      "PERCENT_SOCIAL": "Percent with Social Profile",
      "BUSINESS_FOUND": "Your business was found",
      "BUSINESS_NOT_FOUND": "Your business was not found",
      "FOUND_ONLINE": "{{ percentage }}% of your industry is on {{ source }}",
      "INCONCLUSIVE": "Inconclusive",
      "INCONCLUSIVE_TITLE": "No results found for your business",
      "INCONCLUSIVE_MESSAGE": "While we couldn't find any signs of your business listed on {{ social }}, it could exist under a different name or address that caused us to miss it."
    },
    "ACCURACY": {
      "HEADING": "Listing accuracy",
      "SUBHEADING": "Percentage of accurate listings found for your business",
      "ACCURATE": "Your accurate listings",
      "ACCURATE_COMPETITORS": "You vs. your competitors",
      "INDUSTRY_ACCURATE": "Industry average",
      "INDUSTRY_ACCURATE_COMPETITORS": "What percentage of relevant sites are you accurate on?",
      "INCORRECT_PHONE": "Incorrect phone numbers:",
      "MISSING_PHONE": "Missing phone numbers:",
      "INCORRECT_ADDRESS": "Incorrect addresses:",
      "MISSING_ADDRESS": "Missing addresses:",
      "INCORRECT_WEBSITE": "Incorrect websites:",
      "MISSING_WEBSITE": "Missing websites:",
      "NUMBER_INCORRECT": "Number of incorrect listings"
    },
    "SCAN": {
      "HEADING": "Scan",
      "SUBHEADING": "How your business appears across various listing sites",
      "LISTING": "Listing",
      "SITE": "Site",
      "MISSING": "MISSING",
      "BANNER_TEXT": "<strong>{{scanScore}}%</strong> of the time customers search for you, they will see the correct information.",
      "VIEW_LISTING": "View listing"
    },
    "DETAILS": {
      "HEADING": "Listing details",
      "LISTING_SITE": "Listing site",
      "BUSINESS_NAME": "Business name",
      "ADDRESS": "Address",
      "WEBSITE": "Website",
      "PHONE": "Phone",
      "MISSING": "Missing",
      "VIEW_LISTING": "View listing",
      "VIEW_MORE": "View {{listingsCount}} more",
      "VIEW_LESS": "View less",
      "IN_PROGRESS": "In progress...",
      "ACCURATE_LISTING_FOUND": "Listing found",
      "INACCURATE_LISTING_FOUND": "Found with errors",
      "LISTING_NOT_FOUND": "Listing not found",
      "UNABLE_TO_MODIFY_LISTING": "Unable to modify listing. Please try again later.",
      "SNAPSHOT_EXPIRED": "This {{snapshotName}} is expired. In order to edit listing details, you'll first need to ",
      "REFRESH_REPORT": "refresh this report.",
      "LISTING_MODIFIED": "Section updated. Changes may take a few moments to appear in the report."
    },
    "PROVIDERS": {
      "HEADING": "Data provider accuracy",
      "SUBHEADING": "How your business appears on data provider sites"
    },
    "GRADE_EXPLANATION": {
      "TITLE": "How is the Listings grade calculated?",
      "MOBILE_TITLE": "Listings",
      "PRIMARY": "The <strong>Listings grade</strong> is a reflection of your business’s online listings. Each listing source is assigned a score based on how popular the site is. For example, having an accurate listing on a popular site like Google will have a greater influence on your Listing score. The Listings grade is determined by the percentile range your business falls into when compared to other businesses in the same industry."
    }
  },
  "SNAPSHOT_LITE": {
    "LOADING": "Your report is loading...",
    "CALL_TO_ACTION": "Fix my score",
    "QUESTION_HEADER": "Request to fix business listings",
    "QUESTION_BODY": "Sync your business listing information on many popular online sites. Send us a message and we'll contact you to get set up.",
    "DEFAULT_MESSAGE": "Hi, I've just viewed my report and I'm interested in fixing my online business listings. Please contact me to get my business set up.",
    "STATUS": "Status"
  },
  "REVIEWS": {
    "TAGLINE": "Do consumers trust your business?",
    "FOUND": {
      "HEADING": "Online reviews found on select sites",
      "SUBHEADING": "Information about your business's online reviews"
    },
    "REVIEWS_FOUND": "Reviews found",
    "REVIEWS_FOUND_PER_MONTH": "Reviews found per month",
    "AVERAGE_REVIEW_SCORE": "Average review score",
    "NUMBER_OF_REVIEW_SOURCE": "# of review sources",
    "GRADE_EXPLANATION": {
      "TITLE": "How are Review grades calculated?",
      "MOBILE_TITLE": "Reviews",
      "PRIMARY": "<strong>Review grades</strong> are a reflection of your business’s online review performance. Each grade in the Reviews section is determined by the percentile range your business falls into when compared to other businesses in the same industry.",
      "SECONDARY_TITLE": "Overall Review grade",
      "SECONDARY_PRE_SCALE": "To determine your overall Review grade, the individual sections are first converted into numerical scores. This is done using the scale below:",
      "SECONDARY_POST_SCALE": "We divide the sum of these scores by the number of individual grades within the Reviews section. Finally, we convert that score back into a letter grade."
    }
  },
  "SOCIAL": {
    "TAGLINE": "Do consumers like your business?",
    "COMMON_SUBHEADING": "Information about your business's",
    "FACEBOOK": {
      "LINK_TEXT": "Facebook page",
      "NO_PAGE_ERROR_TITLE": "Get eyes on your business with Facebook",
      "NO_PAGE_ERROR_DESCRIPTION": "Facebook is the most popular social network in the world, and consumers are using it to search for local businesses. What's more, it offers <a href='https://www.facebook.com/business/insights/tools' target='blank'>free tools</a> to help connect with your target customers.",
      "LIKES": "Likes",
      "AVERAGE_POSTS_PER_MONTH": "Average posts / month",
      "AVERAGE_LIKES_PER_POST": "Average likes / post",
      "AVERAGE_SHARES_PER_POST": "Average shares / post",
      "RESTRICTED_PAGE_TITLE": "You may be losing out on page visits...",
      "RESTRICTED_PAGE_DESCRIPTION": "We found a <a href='{{facebookPageUrl}}' target='_blank' rel='nofollow'>page</a> for your business, but weren’t able to access it. This is likely due to Facebook Page restrictions, which can also impact customers who are trying to find you. To reach the widest audience possible, we recommend making your page available to everyone.",
      "POSTS": "Posts"
    },
    "TWITTER": {
      "LINK_TEXT": "X profile",
      "NO_PAGE_ERROR_TITLE": "Interact with your customers on X",
      "NO_PAGE_ERROR_DESCRIPTION": "We didn't find a X profile for your business. It's a great, cost-effective way to reach customers. We can help you tap into this invaluable pool of customer interaction!",
      "FOLLOWING": "Following",
      "TWEETS": "Posts",
      "URL": "X URL"
    },
    "INSTAGRAM": {
      "LINK_TEXT": "Instagram profile",
      "NO_PAGE_ERROR_TITLE": "Build your brand's personality on Instagram",
      "NO_PAGE_ERROR_DESCRIPTION": "Put a face to your business by sharing photos and videos. You can even create shoppable posts, leading customers straight to your online store! Let us help you craft a winning strategy.",
      "POSTS": "Posts",
      "NOT_BUSINESS_TITLE": "Looks like you're using a personal account...",
      "NOT_BUSINESS_DESCRIPTION": "We found a <a href='{{instagramPageUrl}}' target='_blank' rel='nofollow'>page</a> associated with your business, but weren't able to analyze it because you're using a personal profile rather than a business profile. A business profile gives you access to valuable features like Instagram Insights, contact information, and ads.",
      "URL": "Instagram URL"
    },
    "SIX_MONTHS": "(last 6 months)",
    "GRADE_EXPLANATION": {
      "TITLE": "How are Social grades calculated?",
      "MOBILE_TITLE": "Social",
      "PRIMARY": "<strong>Social grades</strong> are a reflection of your business’s social media presence. Each grade in the Social section is determined by the percentile range your business falls into when compared to other businesses in the same industry.",
      "SECONDARY_TITLE": "Overall Social grade ",
      "SECONDARY_PRE_SCALE": "To determine your overall Social grade, the individual sections are first converted into numerical scores. This is done using the scale below:",
      "SECONDARY_POST_SCALE": "We divide the sum of these scores by the number of individual grades within the Social section. Finally, we convert that score back into a letter grade."
    }
  },
  "WEBSITE": {
    "ADD_WEBSITE_URL": "Add website URL",
    "MULTI_SUBSECTION": {
      "MOBILE_HEADING": "Mobile performance",
      "DESKTOP_HEADING": "Desktop performance",
      "SUBHEADING": "Overall performance of your website"
    },
    "TAGLINE": "Can your business website convert visitors into customers?",
    "NO_SITE_ERROR_TITLE": "Earn more customers with a fast, high converting website",
    "NO_SITE_ERROR_DESCRIPTION": "We can't seem to find your website. Consumers search online before making purchase decisions. If they don't find your website, chances are they'll find your competitors.",
    "NO_SITE_ERROR_TAGLINE": "We can help!",
    "NO_SITE_ERROR_WEBSITE_URL": "Have one we didn't find?",
    "MOBILE": {
      "HEADING": "Mobile",
      "SUBHEADING": "Overall performance of your website on mobile",
      "USER_EXPERIENCE": "User experience"
    },
    "MOBILE_FRIENDLY": "Mobile friendly",
    "NOT_MOBILE_FRIENDLY": "Not mobile friendly",
    "DESKTOP": {
      "HEADING": "Desktop",
      "SUBHEADING": "Overall performance of your website on desktop"
    },
    "HOMEPAGE": {
      "HEADING": "Homepage content",
      "SUBHEADING": "Key business information found on your homepage",
      "SIZE": "Homepage size",
      "PHONE_NUMBER_FOUND": "Phone number",
      "ADDRESS_FOUND": "Business address",
      "FACEBOOK_FOUND": "Facebook link",
      "TWITTER_FOUND": "X link",
      "INSTAGRAM_FOUND": "Instagram link",
      "VIDEO": {
        "HEADING": "Video on homepage"
      }
    },
    "VIEW_FULL_REPORT": "View full report",
    "VIEW_FULL_REPORT_TYPE": "View full {{reportType}} report",
    "SHOW_FIX": "Show how to fix",
    "SHOW_DETAILS": "Show details",
    "HIDE_DETAILS": "Hide details",
    "SHOULD_FIX": "Should fix",
    "CONSIDER_FIX": "Consider fixing",
    "PASSED_RULES": "Passed rules",
    "BELOW_AVERAGE": "Below average",
    "ABOVE_AVERAGE": "Above average",
    "NO_WEBSITE": "No website",
    "SHOW_MOBILE_PREVIEW": "Show mobile preview",
    "SHOW_DESKTOP_PREVIEW": "Show desktop preview",
    "ERROR_FETCHING_DESKTOP_DATA": "Unable to determine performance on desktop",
    "ERROR_FETCHING_MOBILE_DATA": "Unable to determine performance on mobile",
    "ERROR_FETCHING_ALL_DATA": "Unable to determine performance",
    "FACEBOOK_AS_WEBSITE_TITLE": "It looks like you're using a Facebook page for your website...",
    "FACEBOOK_AS_WEBSITE_DESCRIPTION": "Facebook is great, but it doesn't replace the need for a website. A good website can help you show up in Google searches, build credibility, and get more customers. Ask us how!",
    "GRADE_EXPLANATION": {
      "TITLE": "How are Website grades calculated?",
      "MOBILE_TITLE": "Website",
      "PRIMARY": "<strong>Website grades</strong> are a reflection of your business’s website performance, based on Google’s PageSpeed Insights. Each grade in the Website section is determined by the percentile range your business falls into when compared to other businesses in the same industry.",
      "SECONDARY_TITLE": "Overall Website grade",
      "SECONDARY_PRE_SCALE": "To determine your overall Website grade, the individual sections are first converted into numerical scores. This is done using the scale below:",
      "SECONDARY_POST_SCALE": "We divide the sum of these scores by the number of individual grades within the Website section. Finally, we convert that score back into a letter grade."
    },
    "CORE_WEB_VITALS": {
      "TITLE": "Core web vitals",
      "DESCRIPTION": "Core web vitals provide key indicators that measure your website's performance and user experience. Google Search refers to these when evaluating page experience, so it's important they're monitored and actioned upon.",
      "CARDS": {
        "PAGE_SPEED_TITLE": "Page speed",
        "PAGE_SPEED_DESCRIPTION": "How long it takes your page to load.",
        "LARGE_CONTENT_TITLE": "Large content",
        "LARGE_CONTENT_DESCRIPTION": "How long it takes for the largest element within view to load.",
        "ESTIMATED_INPUT_LATENCY_TITLE": "Interactivity",
        "ESTIMATED_INPUT_LATENCY_DESCRIPTION": "How long it takes your site to respond after a user clicks on something.",
        "MAX_POTENTIAL_FID_TITLE": "Interactivity",
        "MAX_POTENTIAL_FID_DESCRIPTION": "How long it takes your site to respond after a user clicks on something.",
        "VISUAL_STABILITY_TITLE": "Visual stability",
        "VISUAL_STABILITY_DESCRIPTION": "Measures unexpected shifts in your website layout, usually due to elements loading out of sync.",
        "SECTIONS": {
          "OKAY": "Okay",
          "FAST": "Fast",
          "SLOW": "Slow",
          "GOOD": "Good",
          "POOR": "Poor"
        }
      }
    },
    "SECURE": "Secure website (HTTPS)"
  },
  "ADVERTISING": {
    "TAGLINE": "Do consumers know about your business?",
    "NOT_FOUND": "Not found",
    "ADWORDS": {
      "HEADING": "Recommended keywords",
      "SUBHEADING": "Top 5 keywords for your business",
      "DESCRIPTION": "Advertise on Google and capture customers who are searching precisely for the products or services your business offers.",
      "POSSIBLE_IMPRESSIONS": "Possible impressions",
      "POSSIBLE_IMPRESSIONS_DESCRIPTION": "Estimated views of your ads (per month)",
      "POSSIBLE_CLICKS": "Possible clicks",
      "POSSIBLE_CLICKS_DESCRIPTION": "Estimated clicks on your ads (per month)",
      "FOOTNOTE": "Figures shown assume a maximum cost per click (CPC) of $5.00 with an unlimited budget. Keywords are generated using content gathered from your website. Actual results may vary due to specific advertising budgets, goals, and methods used.",
      "NOT_AVAILABLE": "We weren't able to recommend keywords for your business...",
      "NOT_US": "This section is available in the US only.",
      "NO_WEBSITE": "We couldn't find your website, so we weren't able to recommend keywords for your business.",
      "DEFAULT_MESSAGE": "To ensure that potential customers find your business when they search for products and services nearby, we recommend that you run Google AdWords campaigns with relevant keywords. We can help!",
      "TOOLTIP": {
        "IMPRESSIONS": "<div>Impressions</div><div><strong>{{impressions}}</strong></div>",
        "CLICKS": "<div>Clicks</div><div><strong>{{clicks}}</strong></div><br/><div>Cost per click</div><div><b>{{costPerClick}}</b></div>"
      }
    },
    "PAY_PER_CLICK": {
      "HEADING": "Paid search traffic performance",
      "SUBHEADING": "Here's how your ad campaigns compare to those of your competitors",
      "PAID_KEYWORDS": "Paid keywords",
      "PAID_CLICKS": "Paid traffic (clicks)",
      "PAID_COST": "Paid traffic cost",
      "TOOLTIPS": {
        "OVERLAP": "How similar your campaigns are to your competitors, based on common keywords.",
        "KEYWORDS": "Keywords purchased on PPC ads on the search results page in the last month for you and your competitors.",
        "MONTHLY_PAID_CLICKS": "This is a monthly estimate of the number of clicks you and your competitors receive from all of your paid advertising on Google Ads.",
        "MONTHLY_BUDGET": "Estimated average monthly cost for PPC ads on the Google Ads search results page for you and your competitors.",
        "OVERLAP_NOT_AVAILABLE": "How similar your campaigns are to your competitors, based on common keywords. Overlap only appears when overlapping keywords are found."
      },
      "ERROR_TITLE": "Promote your business where people are looking",
      "ERROR_MESSAGE": "We couldn’t find any ads for your business. Digital advertising puts your business in front of your target audience on search engines, social media, and other relevant sites across the web. Ask us how!"
    },
    "RETARGETING": {
      "HEADING": "Retargeting",
      "SUBHEADING": "Information about your business's retargeting",
      "SUCCESS_MESSAGE": "It looks like you're taking advantage of retargeting!",
      "SUCCESS_ADVICE": "Make sure to test your ads frequently so you know which ones work best with your customers.",
      "SUCCESS_TITLE": "Great work!",
      "FAIL_TITLE": "Start taking advantage of retargeting ads!",
      "FAIL_MESSAGE": "When visitors leave your website, they’re likely still interested; they’re just not ready to buy yet. You need to stay top-of-mind until they are. Let us show you how to continue promoting your business to interested prospects on relevant sites around the web!"
    },
    "GRADE_EXPLANATION": {
      "TITLE": "How is the Advertising grade calculated?",
      "MOBILE_TITLE": "Advertising",
      "PRIMARY": "The <strong>Advertising grade</strong> is a reflection of your business’s online campaign performance. The grade is determined by the percentile range your campaign’s estimated cost per click falls into when compared to other businesses in the same industry.",
      "CLARIFICATION": "<i>Estimated cost per click = estimated monthly ad budget / estimated monthly paid clicks</i>",
      "SECONDARY_PRE_SCALE": "Note: only Google AdWords are considered in this section.</br>The industry leader with the lowest cost per click is the 100th percentile."
    }
  },
  "LOCAL_SEO": {
    "TAGLINE": "Can consumers find you in search?",
    "NOT_FOUND": "Not found",
    "LOCAL_SEO": {
      "HEADING": "Local search results",
      "SUBHEADING": "Who shows up when customers search for your business category?",
      "DESCRIPTION": "This is your average ranking on Google Maps when someone searches for <strong>'{{searchTerm}} + {{vicinity}}'</strong>, depending on their location",
      "BUSINESS_NOT_FOUND": "Your business <strong>{{businessName}}</strong> wasn't found in the top 100 search results",
      "NO_RESULTS_TITLE": "No results",
      "NO_RESULTS_DESCRIPTION": "We couldn’t find any search results for {{searchTerm}} + {{vicinity}}.",
      "NO_RESULTS_IN_SELECTED_AREA": "We couldn't find any search results in the selected area of the map.",
      "IN_PROGRESS_TITLE": "Searching in progress",
      "IN_PROGRESS_DESCRIPTION": "We are currently searching your keywords. Please check back soon.",
      "DOES_NOT_EXIST_TITLE": "We couldn't find your business on Google",
      "DOES_NOT_EXIST_DESCRIPTION": "We couldn't confirm the address of your business on Google, so we can't evaluate your local search results.",
      "DOES_NOT_EXIST_ACTION": "Edit business information",
      "MAP_INFO_TEXT": "Click hotspots to see results change based on location",
      "TABS": {
        "NEAR_ME": "'Near me'"
      },
      "CLAIMED": "Claimed",
      "UNCLAIMED": "Unclaimed",
      "REVIEWS_COUNT": "{{count}} reviews",
      "PHONE_NOT_FOUND": "Phone not found"
    },
    "ORGANIC_KEYWORDS": {
      "PERFORMANCE": {
        "HEADING": "Organic search traffic performance",
        "SUBHEADING": "Here's how your website stacks up to competitors with the same keywords",
        "TOOLTIPS": {
          "OVERLAP": "How similar you are to your competitors, based on common SEO keywords.",
          "KEYWORDS": "The number of keywords your business appears in Google's top 100 search results.",
          "CLICKS": "The estimated monthly website visits from the keywords entered by users in search engines.",
          "VALUE": "An estimate of how much money it would cost to get the same amount of traffic from paid keywords (ads) each month. Good SEO reduces the need for paid keywords.",
          "VALUE_PER_CLICK": "The estimated value of each organic click. A high value indicates that the keywords driving organic clicks are driving significant traffic. We grade your business based on how your click value compares to others in your industry.",
          "OVERLAP_NOT_AVAILABLE": "How similar you are to your competitors, based on common SEO keywords. Overlap only appears when overlapping SEO keywords are found."
        },
        "NOT_AVAILABLE_TITLE": "People can’t find your website on Google Search...",
        "NOT_AVAILABLE_MESSAGE": "We couldn’t find your site in the top 50 search results for any of the organic keywords we track—that means potential customers won’t be able to either. Let us help you optimize your website with relevant keywords!",
        "NOT_AVAILABLE_MESSAGE_HEADER": "We couldn’t find your site in the top 50 search results for any of the organic keywords we track—that means potential customers won’t be able to find your site either.",
        "NOT_AVAILABLE_MESSAGE_FOOTER": "To ensure that potential customers find your business when they search for products and services nearby, we recommend that you optimize your website with relevant keywords. We can help!\n\n",
        "ORG_RANKED_SEARCH_TERMS": "Organic keywords",
        "ESTIMATED_SEARCH_TRAFFIC": "Estimated search traffic",
        "EQUI_COST_PAID_TRAFFIC": "Estimated search traffic value",
        "SEO_VALUE_PER_CLICK": "Organic click value"
      },
      "RANKING": {
        "HEADING": "Organic keyword ranking",
        "SUBHEADING": "How your business shows up on Google Search",
        "NOT_AVAILABLE_TITLE": "People can’t find your website on Google Search...",
        "NOT_AVAILABLE_MESSAGE": "We couldn’t find your site in the top 50 search results for any of the organic keywords we track—that means potential customers won’t be able to either. Let us help you optimize your website with relevant keywords!",
        "TOOLTIPS": {
          "KEYWORDS": "The top 5 organic keywords that your business is ranking for on Google over the last 30 days.",
          "COMPETITIVENESS": "The difficulty of ranking in the top 10 results on Google for a specific keyword, as compared with other keywords. Higher values indicate greater difficulty.",
          "RANK": "The position of your website in Google Search results.",
          "MONTHLY_LOCAL_SEARCHES": "The average monthly organic traffic generated from the keyword.",
          "MONTHLY_SEARCHES": "The average monthly search volume for this keyword in your country over the last 12 months."
        },
        "LOCAL_SEARCHES": "Local searches",
        "GLOBAL_SEARCHES": "Search volume",
        "CLICKS": "Clicks",
        "RANKED_KEYWORDS": "Ranked keywords"
      },
      "VALUE_PER_CLICK": "Value per click"
    },
    "GRADE_EXPLANATION": {
      "TITLE": "How is the SEO grade calculated?",
      "MOBILE_TITLE": "SEO",
      "PRIMARY": "The <strong>Search engine optimization (SEO)</strong> grade is a reflection of your business’s search engine visibility and performance.</br></br>The <strong>Local search results</strong> grade is calculated by adding the score of your ranking for a single keyword, based on nine local searches around your business location, and a search of the entire city.</br></br>The <strong>Organic keyword performance</strong> grade is determined by the percentile range your keywords’ estimated value per click falls into when compared to other businesses in the same industry using the following scale.",
      "CLARIFICATION": "<i>Estimated value per click = estimated monthly value of clicks / estimated monthly clicks</i>",
      "SECONDARY_PRE_SCALE": "<strong>Overall SEO grade</strong></br>To determine your overall SEO grade, the individual sections are first converted into numerical scores. This is done using the scale below:",
      "SECONDARY_POST_SCALE": "Then we divide the sum of these scores by the number of individual grades within the SEO section. Finally, we convert that score back into a letter grade."
    }
  },
  "ECOMMERCE": {
    "BUSINESS_HEADER": "Your company",
    "TAGLINE": "Can consumers instantly buy from you online?",
    "HEADING": "Checklist",
    "SUBHEADING": "Drive sales and compete online",
    "NO_WEBSITE": "No website",
    "NO_SITE_ERROR_TITLE": "People can’t find your website!",
    "NO_SITE_ERROR_DESCRIPTION": "Potential customers might need to research your business before giving you their hard-earned money, but they can’t find your website! Either you don’t have a site or the one you have needs optimization.",
    "NO_SITE_ERROR_TAGLINE": "To inform potential buyers and convert them into customers, we recommend that you get an informative, fast, and mobile-friendly website. We can help!",
    "ERROR_FETCHING_DATA": "Unable to determine performance",
    "ECOMMERCE_SOLUTION": "Online storefront",
    "ONLINE_PAYMENTS": "Online payments",
    "AD_RETARGETING": "Lead engagement",
    "APPOINTMENT_SCHEDULING": "Online scheduler",
    "ECOMMERCE_SOLUTION_RECOMMENDATION": "Online sales of products and services are up 110% year over year",
    "ONLINE_PAYMENTS_RECOMMENDATION": "As online transactions have increased by 140%, you'll want to make sure customers can pay you online",
    "AD_RETARGETING_RECOMMENDATION": "Engaging customers with relevant content can move them down the sales funnel",
    "APPOINTMENT_SCHEDULING_RECOMMENDATION": "Bring in more customer bookings in less time by automating your meeting and appointment scheduling",
    "GRADE_EXPLANATION": {
      "TITLE": "How is the Ecommerce grade calculated?",
      "MOBILE_TITLE": "Ecommerce",
      "PRIMARY": "The <strong>Ecommerce grade</strong> is an indication of how well your business is optimized for online transactions. This grade is weighted to match the insights of sales professionals, giving you a score that helps you focus on the areas that matter most. These areas are weighted as follows:",
      "CLARIFICATION": "<i>Online storefront = high<br/>Online payments = medium<br/>Lead engagement = low</i>",
      "SECONDARY_PRE_SCALE": "Your business is then ranked against the industry to determine your overall grade."
    }
  },
  "TECHNOLOGY": {
    "TAGLINE": "What technology do you use to manage your business online?",
    "VIEW_MORE": "View more",
    "VIEW_LESS": "View less",
    "NO_SITE_ERROR_TITLE": "We weren’t able to look up technologies used by your website",
    "NO_SITE_ERROR_DESCRIPTION": "We either couldn’t find a website, or the website we found prevented us from looking up the technologies it uses.",
    "NO_SITE_ERROR_TAGLINE": "We can help!"
  },
  "NOT_FOUND": {
    "TITLE": "The resource you've requested does not exist.",
    "MESSAGE": "The resource you've requested does not exist."
  },
  "CONTACT_US": {
    "SEND_MESSAGE": "Send a message",
    "MESSAGE_PLACEHOLDER": "How can we help?",
    "MESSAGE_SUCCESS": "Message successfully sent."
  },
  "CTA": {
    "TITLE": "Dominate your local market",
    "GET_STARTED": "Get started",
    "CALL": "Call us at",
    "DEFAULT_MESSAGE": "73% of consumers lose trust in a business with inaccurate listings and 88% of consumers look to online reviews when making buying decisions. <strong>Stop losing customers</strong> to your competitors and <strong>start winning them back</strong>. Fix your listings, improve your review scores, and hear what people are saying about you—all from one dashboard.",
    "DIALOG_DESCRIPTION": "With {{businessCenterName}}, you can access world-class solutions that will help you dominate your local market. Enter your email address and we'll send you a verification email!",
    "DIALOG_TITLE": "Access {{businessCenterName}} today!",
    "BUILD_YOUR_BUSINESS": "Build your online business presence for free!",
    "VIEW_REPORT": "View full report",
    "REFRESH_REPORT": "Refresh",
    "REFRESH_WARNING": "We recommend refreshing the {{snapshotName}} after 90 days for more relevant information.",
    "COPY_URL": "Copy report URL",
    "EDIT_REPORT": "Edit report"
  },
  "BANNER_MESSAGE": {
    "websiteLowScore": "We see that you have a website, but Google is telling us it might need a makeover. If you’re interested, we’re here to help!",
    "websiteMediumScore": "We see that you have a website, but Google is telling us it might need a makeover. If you’re interested, we’re here to help!",
    "websiteHighScore": "Great website! If it ain’t broke, don’t fix it. However, in the case of the first page of Google, more really is better. Let us help you own more spots on that first page!",
    "advertisingMessage": "Potential customers are within your reach, whether they’re searching Google, scrolling through Facebook, or browsing other sites around the web. With digital advertising, you can put your business in the spotlight so that those people consider you before your competitors.",
    "seoMessage": "Potential customers are searching online for the products or services that your business offers. With SEO (Search Engine Optimization), you can boost your visibility in those search results so that people find you before your competitors.",
    "listingDistributionAllAccurate": "Congrats, your information is accurate on all data providers. It's important to keep it that way! Find out more about how Listing Distribution can help with this.",
    "listingDistributionDefault": "Alert! As a business you cannot afford to have wrong information on even one of these major data providers. This incorrect information will be distributed to literally hundreds of online sources including review sites, directories, social sites, search engines, GPS services and more.",
    "listingFullPresenceHighAccuracy": "Great work! You're better than the average bear. However, there’s still work to do. That last little bit always seems the hardest. We can show you exactly what’s left, and even do it for you if you’d like.",
    "listingHighPresenceHighAccuracy": "Your listings are the virtual doorway to your business. You’re listed on some of the sites you need to be on, but there’s work to do. You don’t want customers to find your competitor's listings instead of yours, do you? Without correct listings, your business is invisible to your customers.",
    "listingHighPresenceLowAccuracy": "Yikes! Your business listings are inaccurate. 85% of consumers search for a business online, which means you’re losing revenue to your competitors because of bad listing information.\n\nFix your listings to turn searchers into customers.",
    "listingLowPresenceHighAccuracy": "Bad news: we can’t find many listings for your business, which means customers can’t either. On the positive side, the listings that we found are mostly accurate. With 85% of consumers searching for a business online, you’re losing revenue to your competitors.\n\nAdd your business to review sites, business directories, and social sites to turn online searchers into customers.",
    "listingLowPresenceLowAccuracy": "Bad news: we can’t find many listings for your business, which means customers can’t either. What’s worse is that the listings we found are mostly inaccurate. With 85% of consumers searching for a business online, you’re losing revenue to your competitors.\n\nAdd your business to review sites, business directories, and social sites to turn online searchers into customers.",
    "listingNoListings": "Bad news: we can’t find listings for your business, which means customers can’t either. With 85% of consumers searching for a business online, you’re losing revenue to your competitors.\n\nAdd your business to review sites, business directories, and social sites to turn online searchers into customers.",
    "socialAllActive": "Nice work! You’re rocking the social media world.\n\nAs much as you like social media, we know you have a job to do. We can help you build a strong social presence by regularly posting timely and relevant content. Give us a call!",
    "socialSomeActive": "Nice work! You’re rocking at least half the social media world, but there are other users out there that are missing out on your wisdom, humor, and sage advice.\n\nYou don’t have to do it all yourself. As much as you like social media, we know you have a job to do. We can help you build a strong social presence by regularly posting timely and relevant content. Give us a call!",
    "socialInactive": "Uh oh! It looks like you tried social media, but either got bored or weren’t having fun. That’s too bad—there are a lot of potential customers out there that are missing out on your wisdom, humor, and sage advice.\n\nWe can help you build a strong social presence by regularly posting timely and relevant content. Give us a call!",
    "socialLowEngagement": "It looks like you’re posting on a billboard in the middle of nowhere! There are potential customers out there missing out on your wisdom, humor, and sage advice.\n\nWe can help you build a strong social presence by regularly posting timely and relevant content. Give us a call!",
    "socialMissingAccounts": "It appears that social media is not your bag. That’s too bad—there are a lot of potential customers out there that are missing out on your wisdom, humor, and sage advice.\n\nWe can help you build a strong social presence by regularly posting timely and relevant content. Give us a call!",
    "socialAB": "Nice work! You’re rocking the social media world.\n\nAs much as you like social media, we know you have a job to do. We can help you maintain your strong social presence by regularly posting timely and relevant content. Give us a call!",
    "socialCD": "You’re rocking at least half of the social media world, but there are other users out there that are missing out on your wisdom, humor, and sage advice.\n\nYou don’t have to do it all yourself. As much as you like social media, we know you have a job to do. We can help you build a strong social presence by regularly posting timely and relevant content. Give us a call!",
    "socialF": "It appears that social media is not your bag. It’s too bad because there are a lot of potential customers out there that are missing out on your wisdom, humor, and sage advice.\n\nWe can help you build a strong social presence by regularly posting timely and relevant content. Give us a call!",
    "highVolumeHighScore": "You rock! You get lots of reviews, and customers are saying good things. You can’t afford to monitor the internet continuously, and you certainly can’t afford to miss a review. One negative review gone unanswered can ruin all your hard work! Let us alert you about new reviews so that you can address negative reviews and share positive reviews promptly.",
    "highVolumeLowScore": "You have a lot of negative reviews that thousands of online searchers will see. Nearly 90% of consumers agree that positive online reviews influence their buying decisions, which means you’re losing money to your competition.\n\nBoost your average review rating by uncovering areas in need of improvement and responding to negative feedback promptly. Call us; we can help!",
    "lowVolumeHighScore": "You don’t have enough reviews to maximize your local online presence. However, most people think you’re awesome. Let’s get more of that positive word out. We can help you generate new reviews!",
    "lowVolumeLowScore": "Alert! You don’t have enough reviews, and the ones you have aren’t saying good things! The recency, frequency, and quality of reviews are all significant drivers for clicks, calls, and search engine ranking.\n\nBoost your average review rating by asking your customers for reviews, uncovering areas in need of improvement, and responding to negative feedback promptly. Call us; we can help!",
    "noReviews": "Alert! You don’t have any reviews! Did you know that Google ranks businesses with reviews ahead of businesses with no reviews? The recency, frequency, and quality of reviews are all significant drivers for clicks, calls, and search engine ranking\n\nDon’t leave new business on the table. We’ll help you generate more reviews!",
    "ecommerceMessage": "More businesses than ever before are selling products and services online. Consumers see an online store as a sign of a reputable, established business.",
    "technologyMessage": "Here's an overview of the technologies you are using on your website. It is important to assess whether your business possesses all the necessary tools and capabilities to thrive in the online environment. Is your business equipped with everything it needs to succeed?"
  },
  "SECTION_FOOTER_MESSAGE": {
    "listingsPositive": "Do you want to protect and maintain your listings so that customers will continue to find {{companyName}}? We’d be happy to help!",
    "listingsNegative": "Do you want to fix your listings so that more customers can find {{companyName}}? We’d be happy to help!",
    "reviewsPositive": "Would you like to generate fresh reviews and further improve your average rating? We can help!",
    "reviewsNegative": "Would you like to get more reviews and boost your average rating? We can help!",
    "socialPositive": "Want to learn how to take your social media presence to the next level? We’d love to help!",
    "socialNegative": "Want to learn how to grow your social media presence? We’d love to help!",
    "websitePositive": "Ready to further enhance your website and boost online engagement? Find out how!",
    "websiteNegative": "Ready to optimize your website and boost online engagement? Find out how!",
    "advertisingPositive": "Let’s continue to build brand awareness and get more customers in the door!",
    "advertisingNegative": "Want to run more effective ad campaigns and get more customers in the door? We can help!",
    "seoPositive": "Are you ready to further improve your search engine ranking and help more customers find your business online?",
    "seoNegative": "Are you ready to improve your search engine ranking and help more customers find your business online?",
    "ecommerceDefault": "Is your online store working for you? We can help optimize.",
    "technologyDefault": "Let's talk about the technology you use to run your business!"
  },
  "SCREENSHARE": {
    "DIALOG_TITLE": "Screenshare this report",
    "SELECT_CONTACT": "Select a contact",
    "OPEN": "Open Crankwheel"
  },
  "VALIDATORS": {
    "REQUIRED": "This is required",
    "EMAIL": "Invalid email"
  },
  "STATUS_SUMMARY": {
    "ACCURATE": "accurate",
    "POSSIBLE_ERRORS": "possible errors",
    "NOT_FOUND": "not found"
  },
  "SNAPSHOT_STATUS": {
    "NOT_READY": "It can take up to <strong>24 hours</strong> to finish gathering data. Report grades are subject to change.",
    "CHECK_SOON": "It can take up to 24 hours to finish gathering data for the report. Check back soon",
    "ALMOST_READY": "Almost ready",
    "NOTIFY_WHEN_IS_READY": "You will be notified when the Snapshot report is ready"
  },
  "SECTIONS": {
    "LISTINGS": "Listings",
    "REVIEWS": "Reviews",
    "SOCIAL": "Social",
    "WEBSITE": "Website",
    "ADVERTISING": "Advertising",
    "SEO": "SEO",
    "ECOMMERCE": "Ecommerce",
    "TECHNOLOGY": "Technology"
  },
  "SESSION_EXPIRED": {
    "TITLE": "Reload page",
    "CONTENT": "Your session has expired. Reload this page to continue editing this report.",
    "BUTTON": "Reload",
    "CLOSE": "Close"
  },
  "EMPTY_STATE": {
    "NOT_READY": {
      "TITLE": "Almost ready...",
      "DESCRIPTION": "It takes a few moments to generate the {{snapshotName}}. Check back soon."
    }
  }
}
