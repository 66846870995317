import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { GetCurrentResponseInterface } from '@vendasta/snapshot';
import { RefreshReportService } from './refresh-report.service';
import { PoolTimeoutError } from '../common/pool-timeout-error.model';

export interface RefreshParams {
  snapshotName$: Observable<string>;
  snapshotID: string;
  businessID: string;
}

export interface RefreshResults {
  snapshotID: string;
  error: boolean;
}

@Component({
  selector: 'snap-refresh-report',
  templateUrl: './refresh-dialog.component.html',
  styleUrls: ['./refresh-dialog.component.scss'],
})
export class RefreshDialogComponent implements OnInit {
  loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  snapshotName$: Observable<string>;

  constructor(
    public refreshReportService: RefreshReportService,
    public dialogRef: MatDialogRef<RefreshDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RefreshParams,
  ) {}

  ngOnInit(): void {
    this.snapshotName$ = this.data.snapshotName$;
  }

  refresh(): void {
    this.loading$$.next(true);
    this.dialogRef.disableClose = true;

    this.refreshReportService
      .provision(this.data.businessID, this.data.snapshotID)
      .pipe(
        switchMap(() => this.refreshReportService.startPolling(this.data.businessID)),
        take(1),
      )
      .subscribe({
        next: (currentSnapshot: GetCurrentResponseInterface) => {
          this.dialogRef.close(<RefreshResults>{ snapshotID: currentSnapshot?.snapshot?.snapshotId });
        },
        error: (e) => {
          // if the error is a PoolTimeoutError, we want to communicate the user that
          // we provisioned the snapshot but it's taking too long to get the new ID
          // so we are not flagging it as an error, but leaving the response without the new ID
          const error = !(e instanceof PoolTimeoutError);
          this.dialogRef.close(<RefreshResults>{ error });
        },
        complete: () => {
          this.loading$$.next(false);
        },
      });
  }
}
