import { map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { SocialConfig, SocialConfigInterface } from './social-config';
import { SocialData } from './social-data';
import { SocialContent } from './social-content';
import { ContentService } from '../snapshot-report/content.service';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { SectionInterface } from '../section/section';
import { AbstractSectionService } from '../section/section.service';
import { SocialSectionService } from '@vendasta/snapshot';
import { AccountGroupService, UpdateOperations, SocialURLsUpdateOperation } from '@galaxy/account-group';
import { GradeExplanationData } from '../grade/grade-explainer-dialog.component';
import { gradeToLetter } from '../common/utils';

@Injectable()
export class SocialService
  extends AbstractSectionService<SocialContent, SocialData, SocialConfigInterface>
  implements OnDestroy
{
  sectionId = 'social';

  constructor(
    private _api: SocialSectionService,
    private _cs: ContentService,
    private _ss: SnapshotReportService,
    private _ag: AccountGroupService,
  ) {
    super(_cs, _ss);
  }

  createGradeSubscription(): void {
    this.subscriptions.push(this.grade$.subscribe((g) => this._ss.setSocialGrade(g)));
  }

  load(): Observable<SectionInterface> {
    return this._api.get(this.snapshotId).pipe(
      map((section) => {
        return {
          grade: section.grade,
          config: new SocialConfig(section.config || {}),
          data: new SocialData(section.data),
          content: new SocialContent(section.content),
        };
      }),
    );
  }

  _updateConfig(config: SocialConfigInterface): Observable<boolean> {
    return this._api.updateConfig(this.snapshotId, config);
  }

  newConfig(): SocialConfigInterface {
    return new SocialConfig({});
  }

  updateTwitterUrl(businessId: string, twitterUrl: string): Observable<any> {
    const updateOperations = new UpdateOperations();
    updateOperations.addUpdateOperation(new SocialURLsUpdateOperation({ twitterUrl: twitterUrl }));
    return this._ag.bulkUpdate(businessId, updateOperations);
  }

  updateFacebookUrl(businessId: string, facebookUrl: string): Observable<any> {
    const updateOperations = new UpdateOperations();
    updateOperations.addUpdateOperation(new SocialURLsUpdateOperation({ facebookUrl: facebookUrl }));
    return this._ag.bulkUpdate(businessId, updateOperations);
  }

  updateInstagramUrl(businessId: string, instagramUrl: string): Observable<any> {
    const updateOperations = new UpdateOperations();
    updateOperations.addUpdateOperation(new SocialURLsUpdateOperation({ instagramUrl: instagramUrl }));
    return this._ag.bulkUpdate(businessId, updateOperations);
  }

  getGradeExplanationData(): GradeExplanationData {
    return {
      titleTextID: 'SOCIAL.GRADE_EXPLANATION.TITLE',
      mobileTitleTextID: 'SOCIAL.GRADE_EXPLANATION.MOBILE_TITLE',
      primaryTextID: 'SOCIAL.GRADE_EXPLANATION.PRIMARY',
      showThermometer: true,
      secondaryTitleTextID: 'SOCIAL.GRADE_EXPLANATION.SECONDARY_TITLE',
      secondaryPreScaleTextID: 'SOCIAL.GRADE_EXPLANATION.SECONDARY_PRE_SCALE',
      showGradeScale: true,
      secondaryPostScaleTextID: 'SOCIAL.GRADE_EXPLANATION.SECONDARY_POST_SCALE',
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getAiPromptDetails(): Observable<string> {
    return combineLatest([this._ss.socialSectionEnabled$, this.data$, this.grade$, this.config$]).pipe(
      map(([sectionEnabled, data, grade, config]) => {
        if (!sectionEnabled) {
          return '';
        }
        const result: string[] = [];
        const letterGrade = gradeToLetter(grade);
        result.push(`Social grade: ${letterGrade}`);

        if (!config.hideFacebook && data?.facebookData) {
          result.push('Facebook:');
          if (data.facebookData.likes) {
            const fbLikes = `${data.facebookData.likes.business} / ${data.facebookData.likes.industryLeader}`;
            result.push(`- Likes vs industry leader: ${fbLikes}`);
          }
          if (data.facebookData.averagePostsPerMonth) {
            const fbAvgPosts = `${data.facebookData.averagePostsPerMonth.business} / ${data.facebookData.averagePostsPerMonth.industryLeader}`;
            result.push(`- Average posts/month vs industry leader: ${fbAvgPosts}`);
          }
          if (data.facebookData.averageLikesPerPost) {
            const fbAvgLikes = `${data.facebookData.averageLikesPerPost.business} / ${data.facebookData.averageLikesPerPost.industryLeader}`;
            result.push(`- Average likes/post vs industry leader: ${fbAvgLikes}`);
          }
          if (data.facebookData.averageSharesPerPost) {
            const fbAvgShares = `${data.facebookData.averageSharesPerPost.business} / ${data.facebookData.averageSharesPerPost.industryLeader}`;
            result.push(`- Average shares/post vs industry leader: ${fbAvgShares}`);
          }
        }
        if (!config.hideTwitter && data?.twitterData) {
          result.push('X:');
          if (data.twitterData.followers) {
            const twFollowers = `${data.twitterData.followers.business} / ${data.twitterData.followers.industryLeader}`;
            result.push(`- Followers vs industry leader: ${twFollowers}`);
          }
          if (data.twitterData.following) {
            const twFollowing = `${data.twitterData.following.business} / ${data.twitterData.following.industryLeader}`;
            result.push(`- Following vs industry leader: ${twFollowing}`);
          }
          if (data.twitterData.tweets) {
            const twTweets = `${data.twitterData.tweets.business} / ${data.twitterData.tweets.industryLeader}`;
            result.push(`- Posts vs industry leader: ${twTweets}`);
          }
        }
        if (!config.hideInstagram && data?.instagramData) {
          result.push('Instagram:');
          if (data.instagramData.followers) {
            const igFollowers = `${data.instagramData.followers.business} / ${data.instagramData.followers.industryLeader}`;
            result.push(`- Followers vs industry leader: ${igFollowers}`);
          }
          if (data.instagramData.posts) {
            const igPosts = `${data.instagramData.posts.business} / ${data.instagramData.posts.industryLeader}`;
            result.push(`- Posts vs industry leader: ${igPosts}`);
          }
        }
        return result.join('\n');
      }),
    );
  }
}
