<mat-accordion displayMode="flat" multi>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
      <mat-panel-title>
        <mat-icon [ngClass]="icon">{{ icon }}</mat-icon>
        <span class="title">{{ audit.title }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <span class="description" [innerHtml]="parsedAuditDescription"></span>
    </div>
    <ng-container *ngIf="audit.details.length > 0">
      <div class="details-container">
        <div class="details" *ngFor="let d of audit.details">
          <span *ngIf="!!d.title" [innerHtml]="d.title"></span>
          <div *ngFor="let i of d.detailItems">{{ i }}</div>
        </div>
      </div>
    </ng-container>
    <a
      *ngIf="learnMoreUrl"
      class="detail-button"
      mat-stroked-button
      target="_blank"
      rel="nofollow noopener noreferrer"
      href="{{ learnMoreUrl }}"
      [trackEvent]="{
        eventName: 'learn-more',
        category: 'cta',
        action: 'click'
      }"
    >
      {{ 'COMMON.LEARN_MORE' | translate }}
    </a>
  </mat-expansion-panel>
</mat-accordion>
