import { Injectable, OnDestroy } from '@angular/core';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { ContentService } from '../snapshot-report/content.service';
import { AbstractSectionService } from '../section/section.service';
import {
  TechnologySection,
  TechnologyApiService,
  TechnologySectionService,
  GetMultiTechnologyResponseInterface,
  GetMultiTechnologyCategoryResponseInterface,
} from '@vendasta/snapshot';
import { Observable, map, switchMap, of } from 'rxjs';
import { SectionInterface } from '../section/section';
import { GradeExplanationData } from '../grade/grade-explainer-dialog.component';
import { TechnologyContent } from './technology-content';
import { TechnologyData } from './technology-data';
import { TechnologyConfig, TechnologyConfigInterface } from './technology-config';
import { TechnologyCategoryInterface } from '../technology-category/technology';
import { customCategorySort } from './category-sort';
import { ImageService } from '../../core';

interface MultiResponseResult {
  categories: Map<string, TechnologyCategoryInterface>;
  IDs: string[];
}

@Injectable()
export class TechnologyService
  extends AbstractSectionService<TechnologyContent, TechnologyData, TechnologyConfigInterface>
  implements OnDestroy
{
  sectionId = 'technology';

  constructor(
    readonly contentService: ContentService,
    readonly snapshotReportService: SnapshotReportService,
    private readonly technologySectionService: TechnologySectionService,
    private readonly technologyApiService: TechnologyApiService,
    private readonly imageService: ImageService,
  ) {
    super(contentService, snapshotReportService);
  }

  createGradeSubscription(): void {
    return;
  }

  load(): Observable<SectionInterface> {
    return this.technologySectionService
      .get(this.snapshotId)
      .pipe(map((section) => this.technologyToSectionInterface(section)));
  }

  _updateConfig(config: TechnologyConfigInterface): Observable<boolean> {
    return this.technologySectionService.updateConfig(this.snapshotId, config);
  }

  newConfig(): TechnologyConfigInterface {
    return new TechnologyConfig({});
  }

  getGradeExplanationData(): GradeExplanationData {
    return {};
  }

  getGroupingsFromTechnologyIds(technologyIds: string[]): Observable<TechnologyCategoryInterface[]> {
    return this.technologyApiService.getMulti({ technologyIds: technologyIds }).pipe(
      switchMap((response) => this.handleGetMultiResponse(response)),
      switchMap((multiResponseResult) =>
        this.technologyApiService
          .getMultiCategory({ categoryIds: multiResponseResult.IDs })
          .pipe(map((response) => this.updateCategoryNames(response, multiResponseResult.categories))),
      ),
      map((categories) => categories.sort(customCategorySort)),
    );
  }

  private handleGetMultiResponse(response: GetMultiTechnologyResponseInterface): Observable<MultiResponseResult> {
    const categoryIdToTechnology = new Map<string, TechnologyCategoryInterface>();
    const categoryIds: string[] = [];

    response?.technologies?.forEach((technology) => {
      technology.categoryIds?.forEach((categoryId) => {
        categoryIds.push(categoryId);

        if (!categoryIdToTechnology.has(categoryId)) {
          categoryIdToTechnology.set(categoryId, {
            categoryName: '',
            technologyItems: [],
          });
        }

        categoryIdToTechnology.get(categoryId).technologyItems.push({
          name: technology.name,
          website: technology.website,
          icon: this.getTechnologyIcon(technology.iconName),
        });
      });
    });

    return of({
      categories: categoryIdToTechnology,
      IDs: categoryIds,
    });
  }

  private updateCategoryNames(
    response: GetMultiTechnologyCategoryResponseInterface,
    categoryIdToTechnology: Map<string, TechnologyCategoryInterface>,
  ): TechnologyCategoryInterface[] {
    response?.technologyCategories?.forEach((category) => {
      if (categoryIdToTechnology.has(category.categoryId)) {
        categoryIdToTechnology.get(category.categoryId).categoryName = category.name;
      }
    });

    return [...categoryIdToTechnology.values()];
  }

  getTechnologyIcon(iconName: string): string {
    const icon = iconName || 'default.svg';
    return this.imageService.getTechnologySrc(icon);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private technologyToSectionInterface(section?: TechnologySection): SectionInterface | null {
    if (section) {
      return {
        grade: section.grade,
        config: new TechnologyConfig(section.config || {}),
        data: new TechnologyData(section.data),
        content: new TechnologyContent(section.content),
      } as SectionInterface;
    }
    return null;
  }
}
