import { TextFieldModule } from '@angular/cdk/text-field';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Injector, NgModule, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BusinessCategoryModule, LANGUAGE_TOKEN } from '@galaxy/business-category';
import { CoreModule } from '@galaxy/core';
import { LexiconModule } from '@galaxy/lexicon';
import { PartnerServiceInterfaceToken } from '@galaxy/partner';
import { TranslateModule } from '@ngx-translate/core';
import {
  GooglePlacesService as GoogleMapsService,
  IncludedProductsService,
} from '@vendasta/businesses/easy-account-create';
import { GalaxyAlertModule } from '@vendasta/galaxy/alert';
import { GalaxyButtonLoadingIndicatorModule } from '@vendasta/galaxy/button-loading-indicator';
import { GalaxyEmptyStateModule } from '@vendasta/galaxy/empty-state';
import { GalaxyI18NModule } from '@vendasta/galaxy/i18n';
import { GalaxyInputModule } from '@vendasta/galaxy/input';
import { GalaxyPopoverModule } from '@vendasta/galaxy/popover';
import { GalaxySnackbarModule, SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { GalaxyTooltipModule } from '@vendasta/galaxy/tooltip';
import { ProductAnalyticsModule } from '@vendasta/product-analytics';
import { TaxonomyI18nModule } from '@vendasta/taxonomy-i18n';
import { ConfirmationService, UIKitModule, VaMaterialTableModule } from '@vendasta/uikit';
import { NgChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxWebstorageModule } from 'ngx-webstorage';
import English from '../assets/i18n/en_devel.json';
import { AdvertisingRetargetingSubsectionComponent } from './advertising-section/adertising-retargeting-subsection/advertising-retargeting-subsection.component';
import { AdvertisingAdwordsSubsectionComponent } from './advertising-section/advertising-adwords-subsection/advertising-adwords-subsection.component';
import { AdvertisingSectionHeaderComponent } from './advertising-section/advertising-section-header.component';
import { AdvertisingSectionComponent } from './advertising-section/advertising-section.component';
import { AdvertisingService } from './advertising-section/advertising.service';
import { CampaignPerformanceSubsectionComponent } from './advertising-section/campaign-performance-subsection/campaign-performance-subsection.component';
import { CampaignPerformanceComponent } from './advertising-section/campaign-performance/campaign-performance.component';
import { BusinessDetailsComponent } from './business-details/business-details.component';
import { BusinessDetailsService } from './business-details/business-details.service';
import { ClaimAccountService } from './claim-account/claim-account.service';
import { AccountGroupCompetitorsComponent } from './competitors/account-group/account-group-competitors.component';
import { AccountGroupCompetitorsService } from './competitors/account-group/account-group-competitors.service';
import { SnapTableComponent } from './section-table/snap-table/snap-table.component';
import { CompetitorsComponent } from './competitors/competitors.component';
import { DirectCompetitorsComponent } from './competitors/direct-competitors/direct-competitors.component';
import { DirectCompetitorsService } from './competitors/direct-competitors/direct-competitors.service';
import { OptionsScrollDirective } from './competitors/direct-competitors/options-scroll.directive';
import { KeywordCompetitorsComponent } from './competitors/keyword-competitors/keyword-competitors.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ContactsService } from './contacts/contacts.service';
import { EcommerceSectionHeaderComponent } from './ecommerce-section/ecommerce-section-header.component';
import { EcommerceSectionComponent } from './ecommerce-section/ecommerce-section.component';
import { EcommerceSubsectionComponent } from './ecommerce-section/ecommerce-subsection/ecommerce-subsection.component';
import { EcommerceService } from './ecommerce-section/ecommerce.service';
import { StillWorkingComponent } from './generic-empty-states/still-working/still-working.component';
import { TryAgainComponent } from './generic-empty-states/try-again/try-again.component';
import { MobileViewDataComponent } from './section-table/mobile-view-data/mobile-view-data.component';
import { GradeExplainerDialogComponent } from './grade/grade-explainer-dialog.component';
import { AccuracyTableComponent } from './listing-section/accuracy-table/accuracy-table.component';
import { ChartComponent as ListingChartComponent } from './listing-section/chart/chart.component';
import { DetailsTableComponent } from './listing-section/details-table/details-table.component';
import { ListingAccuracySubsectionComponent } from './listing-section/listing-accuracy-subsection/listing-accuracy-subsection.component';
import { ListingDetailsSubsectionComponent } from './listing-section/listing-details-subsection/listing-details-subsection.component';
import { ListingDistributionStatusComponent } from './listing-section/listing-distribution/listing-distribution-status.component';
import { ListingDistributionTableComponent } from './listing-section/listing-distribution/listing-distribution-table.component';
import { ListingPresenceSubsectionComponent } from './listing-section/listing-presence-subsection/listing-presence-subsection.component';
import { ListingProviderSubsectionComponent } from './listing-section/listing-provider-subsection/listing-provider-subsection.component';
import { ListingSectionHeaderComponent } from './listing-section/listing-section-header.component';
import { ListingSectionComponent } from './listing-section/listing-section.component';
import { ListingTableComponent } from './listing-section/listing-table/listing-table.component';
import { ListingService } from './listing-section/listing.service';
import { PresenceTableComponent } from './listing-section/presence/presence-table.component';
import { SocialItemComponent } from './listing-section/presence/social-table/social-item.component';
import { SocialTableComponent } from './listing-section/presence/social-table/social-table.component';
import { LiteQuestionDialogComponent } from './lite-question-dialog/lite-question-dialog.component';
import { MarkedParserService } from './marked-parser.service';
import { OverallScoreComponent } from './overall-score/overall-score.component';
import { PartnerService } from './partner/partner.service';
import { getPartnerIdFromService, getSnapshotName, PARTNER_ID_TOKEN, SNAPSHOT_NAME_TOKEN } from './providers/providers';
import { RefreshDialogComponent } from './refresh-report/refresh-dialog.component';
import { RefreshReportService } from './refresh-report/refresh-report.service';
import { ReviewSectionHeaderComponent } from './review-section/review-section-header.component';
import { ReviewSectionComponent } from './review-section/review-section.component';
import { ReviewSubsectionComponent } from './review-section/review-subsection/review-subsection.component';
import { ReviewService } from './review-section/review.service';
import { GradeTaglineComponent } from './section-container/grade-tagline.component';
import { SectionContainerComponent } from './section-container/section-container.component';
import { SectionStencilComponent } from './section-container/section-stencil.component';
import { SectionFooterComponent } from './section-footer/section-footer.component';
import { LocalSeoSubsectionComponent } from './seo-section/local-seo-subsection/local-seo-subsection.component';
import { CustomKeywordComponent } from './seo-section/local-seo/custom-keyword/custom-keyword.component';
import { LocalSEOComponent } from './seo-section/local-seo/local-seo.component';
import { OrganicKeywordRankingComponent } from './seo-section/organic-keyword-ranking/organic-keyword-ranking.component';
import { OrganicKeywordsPerformanceSubsectionComponent } from './seo-section/organic-keywords-performance-subsection/organic-keywords-performance-subsection.component';
import { OrganicKeywordsRankingSubsectionComponent } from './seo-section/organic-keywords-ranking-subsection/organic-keywords-ranking-subsection.component';
import { OrganicKeywordsComponent } from './seo-section/organic-keywords/organic-keywords.component';
import { SeoSectionHeaderComponent } from './seo-section/seo-section-header.component';
import { SEOSectionComponent } from './seo-section/seo-section.component';
import { SEOService } from './seo-section/seo.service';
import { GooglePlacesService } from './snapshot-lite/google-places.service';
import { OverrideBusinessDataDialogComponent } from './snapshot-lite/override-business-data-dialog/override-business-data-dialog.component';
import { SnapshotLiteSalesforceComponent } from './snapshot-lite/snapshot-lite-salesforce.component';
import { SnapshotLiteComponent } from './snapshot-lite/snapshot-lite.component';
import { SnapshotLiteService } from './snapshot-lite/snapshot-lite.service';
import { ContentService } from './snapshot-report/content.service';
import { SectionAvailabilityService } from './snapshot-report/section-availability.service';
import { SnapshotReportService } from './snapshot-report/snapshot-report.service';
import { SnapshotService } from './snapshot.service';
import { FacebookSubsectionComponent } from './social-section/facebook-subsection/facebook-subsection.component';
import { InstagramSubsectionComponent } from './social-section/instagram-subsection/instagram-subsection.component';
import { SocialSectionHeaderComponent } from './social-section/social-section-header.component';
import { SocialSectionComponent } from './social-section/social-section.component';
import { SocialService } from './social-section/social.service';
import { TwitterSubsectionComponent } from './social-section/twitter-subsection/twitter-subsection.component';
import { StatusSummaryComponent } from './status-summary/status-summary.component';
import { MultiSubsectionComponent } from './subsection/multi-subsection/multi-subsection.component';
import { SubsectionComponent } from './subsection/subsection.component';
import { SummaryThermometerComponent } from './thermometers/summary-thermometer/summary-thermometer.component';
import { PerformanceCardComponent } from './website-section/performance-card/performance-card.component';
import { AuditReportDetailsComponent } from './website-section/website-audit-details/audit-report-details/audit-report-details.component';
import { AuditReportComponent } from './website-section/website-audit-details/audit-report/audit-report.component';
import { WebsiteAuditDetailsComponent } from './website-section/website-audit-details/website-audit-details.component';
import { WebsiteAuditSummaryComponent } from './website-section/website-audit-summary/website-audit-summary.component';
import { WebsiteHomepageSubsectionComponent } from './website-section/website-homepage/website-homepage.component';
import { WebsitePerformanceSubsectionComponent } from './website-section/website-performance/website-performance.component';
import { WebsitePreviewComponent } from './website-section/website-preview/website-preview.component';
import { WebsiteSectionContentComponent } from './website-section/website-section-content/website-section-content.component';
import { WebsiteSectionHeaderComponent } from './website-section/website-section-header.component';
import { WebsiteSectionComponent } from './website-section/website-section.component';
import { WebsiteService } from './website-section/website.service';
import { WhitelabelService } from './whitelabel/whitelabel.service';
import { TechnologySectionComponent, TechnologySectionHeaderComponent, TechnologyService } from './technology';
import { TechnologySectionContentComponent } from './technology/technology-section-content/technology-section-content.component';
import { TechnologySectionExistsToken } from './providers/providers';
import { combineLatest, map } from 'rxjs';
import { GalaxyPipesModule } from '@vendasta/galaxy/pipes';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import {
  ContentBannerComponent,
  GradeComponent,
  GradeThermometerComponent,
  PipesModule,
  ScoreDonutComponent,
  ToggledItemModule,
} from '../core';
import { GalaxyLoadingSpinnerModule } from '@vendasta/galaxy/loading-spinner';
import { GalaxyAvatarModule } from '@vendasta/galaxy/avatar';
import { LocalSEOMapComponent } from './seo-section/local-seo/map/local-seo-map.component';
import { IconButtonComponent } from '../core/button/icon-button.component';
import { EcommerceTableComponent } from './ecommerce-section/ecommerce-subsection/ecommerce-table/ecommerce-table.component';

const CoreComponents = [
  ContentBannerComponent,
  IconButtonComponent,
  GradeComponent,
  GradeThermometerComponent,
  PipesModule,
  ScoreDonutComponent,
  ToggledItemModule,
];
const SectionComponents = [
  OverallScoreComponent,
  BusinessDetailsComponent,
  ListingSectionComponent,
  ReviewSectionComponent,
  SocialSectionComponent,
  WebsiteSectionComponent,
  EcommerceSectionComponent,
  AdvertisingSectionComponent,
  SEOSectionComponent,
  TechnologySectionComponent,
];
// TODO: check which components should be moved to the snapshot-client
const ComponentsToExport = [...SectionComponents, CustomKeywordComponent, CompetitorsComponent];

@NgModule({
  declarations: [
    ReviewSectionComponent,
    ReviewSubsectionComponent,
    SectionContainerComponent,
    GradeTaglineComponent,
    SubsectionComponent,
    OptionsScrollDirective,
    SectionFooterComponent,
    SocialSectionComponent,
    ListingSectionComponent,
    ListingPresenceSubsectionComponent,
    ListingAccuracySubsectionComponent,
    ListingDetailsSubsectionComponent,
    ListingProviderSubsectionComponent,
    AccuracyTableComponent,
    PresenceTableComponent,
    WebsiteSectionComponent,
    ListingDistributionStatusComponent,
    AdvertisingSectionComponent,
    AdvertisingAdwordsSubsectionComponent,
    CampaignPerformanceSubsectionComponent,
    AdvertisingRetargetingSubsectionComponent,
    SEOSectionComponent,
    LocalSeoSubsectionComponent,
    OrganicKeywordsRankingSubsectionComponent,
    OrganicKeywordsPerformanceSubsectionComponent,
    LiteQuestionDialogComponent,
    ListingTableComponent,
    SnapshotLiteComponent,
    SnapshotLiteSalesforceComponent,
    RefreshDialogComponent,
    GradeExplainerDialogComponent,
    OverrideBusinessDataDialogComponent,
    CompetitorsComponent,
    DirectCompetitorsComponent,
    ListingChartComponent,
    ConfirmationDialogComponent,
    KeywordCompetitorsComponent,
    WebsiteAuditSummaryComponent,
    WebsiteAuditDetailsComponent,
    EcommerceSectionComponent,
    EcommerceSubsectionComponent,
    EcommerceTableComponent,
    WebsiteSectionContentComponent,
    WebsiteHomepageSubsectionComponent,
    WebsitePerformanceSubsectionComponent,
    SnapTableComponent,
    AccountGroupCompetitorsComponent,
    DetailsTableComponent,
    StatusSummaryComponent,
    SocialItemComponent,
    SocialTableComponent,
    AuditReportComponent,
    AuditReportDetailsComponent,
    PerformanceCardComponent,
    MultiSubsectionComponent,
    SummaryThermometerComponent,
    WebsitePreviewComponent,
    StillWorkingComponent,
    TryAgainComponent,
    OverallScoreComponent,
    CampaignPerformanceComponent,
    MobileViewDataComponent,
    BusinessDetailsComponent,
    OrganicKeywordsComponent,
    FacebookSubsectionComponent,
    TwitterSubsectionComponent,
    InstagramSubsectionComponent,
    ListingDistributionTableComponent,
    LocalSEOComponent,
    LocalSEOMapComponent,
    CustomKeywordComponent,
    OrganicKeywordRankingComponent,
    SectionStencilComponent,
    SocialSectionHeaderComponent,
    WebsiteSectionHeaderComponent,
    SeoSectionHeaderComponent,
    ReviewSectionHeaderComponent,
    ListingSectionHeaderComponent,
    EcommerceSectionHeaderComponent,
    AdvertisingSectionHeaderComponent,
    TechnologySectionHeaderComponent,
    TechnologySectionComponent,
  ],
  exports: ComponentsToExport,
  imports: [
    MatCardModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    FormsModule,
    MatSnackBarModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatChipsModule,
    RouterModule.forChild([]),
    CoreModule,
    NgxWebstorageModule.forRoot(),
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,
    Ng2GoogleChartsModule,
    MatDialogModule,
    ProductAnalyticsModule,
    MatMenuModule,
    MatListModule,
    MatAutocompleteModule,
    GalaxyI18NModule,
    GalaxyPopoverModule,
    TranslateModule,
    LexiconModule.forChild({
      componentName: 'common/snapshot',
      baseTranslation: English,
    }),
    UIKitModule,
    GoogleMapsModule,
    NgChartsModule,
    TaxonomyI18nModule,
    VaMaterialTableModule,
    GalaxyInputModule,
    GalaxyAlertModule,
    GalaxyEmptyStateModule,
    GalaxyTooltipModule,
    GalaxySnackbarModule,
    GalaxyButtonLoadingIndicatorModule,
    BusinessCategoryModule,
    MatFormFieldModule,
    TextFieldModule,
    TechnologySectionContentComponent,
    GalaxyPipesModule,
    GalaxyFormFieldModule,
    CoreComponents,
    GalaxyLoadingSpinnerModule,
    GalaxyAvatarModule,
  ],
  providers: [
    AdvertisingService,
    SEOService,
    ReviewService,
    WebsiteService,
    SocialService,
    ListingService,
    EcommerceService,
    ContentService,
    SnapshotReportService,
    WhitelabelService,
    ClaimAccountService,
    SnapshotLiteService,
    GooglePlacesService,
    ContactsService,
    PartnerService,
    GoogleMapsService,
    IncludedProductsService,
    DirectCompetitorsService,
    MarkedParserService,
    SectionAvailabilityService,
    SnapshotService,
    AccountGroupCompetitorsService,
    SnackbarService,
    ConfirmationService,
    BusinessDetailsService,
    RefreshReportService,
    TechnologyService,
    { provide: PartnerServiceInterfaceToken, useExisting: SnapshotReportService },
    { provide: PARTNER_ID_TOKEN, useFactory: getPartnerIdFromService, deps: [PartnerService] },
    { provide: SNAPSHOT_NAME_TOKEN, useFactory: getSnapshotName, deps: [WhitelabelService] },
    { provide: 'PARTNER_ID', useExisting: PARTNER_ID_TOKEN },
    {
      provide: LANGUAGE_TOKEN,
      useFactory: (s: SnapshotReportService) => s.locale$,
      deps: [SnapshotReportService],
    },
    // TODO: provide this token in snapshot-client because we it is used there as well
    {
      provide: TechnologySectionExistsToken,
      useFactory: (s: TechnologyService) =>
        combineLatest([s.loaded$, s.error$]).pipe(map(([loaded, error]) => loaded && !error)),
      deps: [TechnologyService],
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class SectionsModule {
  constructor(private injector: Injector) {
    createAllCustomElements(injector);
  }
}

const snapshotCustomElements: [string, Type<any>][] = [
  ['snapshot-subsection-overall-score', OverallScoreComponent],
  ['snapshot-subsection-business-details', BusinessDetailsComponent],
  ['snapshot-subsection-organic-keywords-ranking', OrganicKeywordsRankingSubsectionComponent],
  ['snapshot-subsection-organic-keywords-performance', OrganicKeywordsPerformanceSubsectionComponent],
  ['snapshot-subsection-local-seo', LocalSeoSubsectionComponent],
  ['snapshot-subsection-listing-accuracy', ListingAccuracySubsectionComponent],
  ['snapshot-subsection-listing-details', ListingDetailsSubsectionComponent],
  ['snapshot-subsection-listing-presence', ListingPresenceSubsectionComponent],
  ['snapshot-subsection-listing-provider', ListingProviderSubsectionComponent],
  ['snapshot-subsection-website-homepage', WebsiteHomepageSubsectionComponent],
  ['snapshot-subsection-website-performance', WebsitePerformanceSubsectionComponent],
  ['snapshot-subsection-ecommerce', EcommerceSubsectionComponent],
  ['snapshot-subsection-facebook', FacebookSubsectionComponent],
  ['snapshot-subsection-instagram', InstagramSubsectionComponent],
  ['snapshot-subsection-twitter', TwitterSubsectionComponent],
  ['snapshot-subsection-advertising-adwords', AdvertisingAdwordsSubsectionComponent],
  ['snapshot-subsection-advertising-retargeting', AdvertisingRetargetingSubsectionComponent],
  ['snapshot-subsection-campaign-performance', CampaignPerformanceSubsectionComponent],
  ['snapshot-subsection-review', ReviewSubsectionComponent],
  ['snapshot-section-header-social', SocialSectionHeaderComponent],
  ['snapshot-section-header-website', WebsiteSectionHeaderComponent],
  ['snapshot-section-header-seo', SeoSectionHeaderComponent],
  ['snapshot-section-header-review', ReviewSectionHeaderComponent],
  ['snapshot-section-header-listing', ListingSectionHeaderComponent],
  ['snapshot-section-header-ecommerce', EcommerceSectionHeaderComponent],
  ['snapshot-section-header-advertising', AdvertisingSectionHeaderComponent],
];

function createAllCustomElements(injector: Injector) {
  snapshotCustomElements.forEach(([tag, component]) => {
    if (!customElements.get(tag)) {
      customElements.define(tag, createCustomElement(component, { injector }));
    }
  });
}
