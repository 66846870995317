<mat-card appearance="outlined" *ngIf="(snapshotService.hideGrades$ | async) === false">
  <mat-card-content>
    <div class="row title" *ngIf="businessName$ | async as businessName">
      <span class="header" *ngIf="media.mobile$ | async">
        {{ businessName }}
      </span>
      <span class="header" *ngIf="(media.mobile$ | async) === false">
        {{ 'OVERALL.DESKTOP_TITLE' | translate : { name: businessName } }}
      </span>
      <span *ngIf="!hideHelp">
        <mat-icon class="grade-info hide-to-print" (click)="openExplainer()">help_outline</mat-icon>
      </span>
    </div>
    <div class="row row-gutters">
      <div class="col-xs-12" [ngClass]="{ 'col-md-2': layout !== 'compact' }">
        <div class="score">
          <snap-score-donut
            [score]="(snapshotService.overallScore$ | async)?.score"
            [diameter]="layout === 'compact' && 100"
          ></snap-score-donut>
        </div>
      </div>

      <div class="col" *ngIf="enabledSections$ | async as enabledSections">
        <div class="row">
          <ng-container *ngFor="let section of enabledSections" [ngSwitch]="section">
            <ng-container *ngSwitchCase="sectionsEnum.LISTINGS">
              <div class="col grades">
                <a
                  [routerLink]="buildRouterLink()"
                  fragment="listings"
                  [trackEvent]="{
                    eventName: 'listings',
                    category: 'anchor',
                    action: 'click'
                  }"
                >
                  <div class="section-grade">
                    <snap-grade
                      [grade]="snapshotService.listingGrade$ | async"
                      [size]="gradeSize$ | async"
                    ></snap-grade>
                  </div>
                  <div>{{ 'TITLES.LISTINGS' | translate }}</div>
                </a>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="sectionsEnum.REVIEWS">
              <div class="col grades">
                <a
                  [routerLink]="buildRouterLink()"
                  fragment="reviews"
                  [trackEvent]="{
                    eventName: 'reviews',
                    category: 'anchor',
                    action: 'click'
                  }"
                >
                  <div class="section-grade">
                    <snap-grade [grade]="snapshotService.reviewGrade$ | async" [size]="gradeSize$ | async"></snap-grade>
                  </div>
                  <div>{{ 'TITLES.REVIEWS' | translate }}</div>
                </a>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="sectionsEnum.SOCIAL">
              <div class="col grades">
                <a
                  [routerLink]="buildRouterLink()"
                  fragment="social"
                  [trackEvent]="{
                    eventName: 'social',
                    category: 'anchor',
                    action: 'click'
                  }"
                >
                  <div class="section-grade">
                    <snap-grade [grade]="snapshotService.socialGrade$ | async" [size]="gradeSize$ | async"></snap-grade>
                  </div>
                  <div>{{ 'TITLES.SOCIAL' | translate }}</div>
                </a>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="sectionsEnum.WEBSITE">
              <div class="col grades">
                <a
                  [routerLink]="buildRouterLink()"
                  fragment="website"
                  [trackEvent]="{
                    eventName: 'website',
                    category: 'anchor',
                    action: 'click'
                  }"
                >
                  <div class="section-grade">
                    <snap-grade
                      [grade]="snapshotService.websiteGrade$ | async"
                      [size]="gradeSize$ | async"
                    ></snap-grade>
                  </div>
                  <div>{{ 'TITLES.WEBSITE' | translate }}</div>
                </a>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="sectionsEnum.ADVERTISING">
              <div class="col grades">
                <a
                  [routerLink]="buildRouterLink()"
                  fragment="advertising"
                  [trackEvent]="{
                    eventName: 'advertising',
                    category: 'anchor',
                    action: 'click'
                  }"
                >
                  <div class="section-grade">
                    <snap-grade
                      [grade]="snapshotService.advertisingGrade$ | async"
                      [size]="gradeSize$ | async"
                    ></snap-grade>
                  </div>
                  <div>{{ 'TITLES.ADVERTISING' | translate }}</div>
                </a>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="sectionsEnum.SEO">
              <div class="col grades">
                <a
                  [routerLink]="buildRouterLink()"
                  fragment="seo"
                  [trackEvent]="{
                    eventName: 'seo',
                    category: 'anchor',
                    action: 'click'
                  }"
                >
                  <div class="section-grade">
                    <snap-grade [grade]="snapshotService.seoGrade$ | async" [size]="gradeSize$ | async"></snap-grade>
                  </div>
                  <div>{{ 'TITLES.SEO' | translate }}</div>
                </a>
              </div>
            </ng-container>

            <ng-container *ngIf="snapshotService.ecommerceGrade$ | async as grade">
              <ng-container *ngSwitchCase="sectionsEnum.ECOMMERCE">
                <div class="col grades">
                  <a
                    [routerLink]="buildRouterLink()"
                    fragment="ecommerce"
                    [trackEvent]="{
                      eventName: 'ecommerce',
                      category: 'anchor',
                      action: 'click'
                    }"
                  >
                    <div class="section-grade">
                      <snap-grade [grade]="grade" [size]="gradeSize$ | async"></snap-grade>
                    </div>
                    <div>{{ 'TITLES.ECOMMERCE' | translate }}</div>
                  </a>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
