<snap-subsection
  *ngIf="showPPC"
  heading="{{ 'ADVERTISING.PAY_PER_CLICK.HEADING' | translate }}"
  subheading="{{ 'ADVERTISING.PAY_PER_CLICK.SUBHEADING' | translate }}"
  [edit]="edit"
  [hideControl]="controls.controls['hideSemPayPerClick']"
>
  <snap-campaign-performance
    [businessPPCDomainMetrics]="businessPPCDomainMetrics$ | async"
    [competitorsPPCDomainMetrics]="competitorsPPCDomainMetrics$ | async"
    [edit]="edit"
  ></snap-campaign-performance>
</snap-subsection>
