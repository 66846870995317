import { CompetitorHomepageDataInterface, WebsiteDataCompetitorInterface } from '@vendasta/snapshot';

export class CompetitorHomepageData implements CompetitorHomepageDataInterface {
  size: number;
  facebookPresent: boolean;
  twitterPresent: boolean;
  instagramPresent: boolean;
  videoPresent: boolean;

  constructor(data: CompetitorHomepageDataInterface) {
    this.size = data.size || 0;
    this.facebookPresent = !!data.facebookPresent;
    this.twitterPresent = !!data.twitterPresent;
    this.instagramPresent = !!data.instagramPresent;
    this.videoPresent = !!data.videoPresent;
  }
}

export class WebsiteCompetitor implements WebsiteDataCompetitorInterface {
  website: string;
  name: string;
  homepageData: CompetitorHomepageData;
  constructor(data: WebsiteDataCompetitorInterface) {
    this.website = data.website || '';
    this.name = data.companyName || '';
    this.homepageData = new CompetitorHomepageData(data.homepageData || {});
  }
}
