import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { CompetitionAnalysis } from '@vendasta/snapshot';
import { AbstractControl } from '@angular/forms';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { TranslateService } from '@ngx-translate/core';
import { SEOService } from '../seo.service';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { OrganicDomainData, SEODomainMetrics } from '../seo-data';
import { BaseSectionComponent } from '../../section/base-section.component';
import { SnapshotService } from '../../snapshot.service';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';

@Component({
  selector: 'snap-organic-keywords-performance-subsection',
  templateUrl: './organic-keywords-performance-subsection.component.html',
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: SEOService,
    },
  ],
})
export class OrganicKeywordsPerformanceSubsectionComponent extends BaseSectionComponent implements OnInit {
  @Output() customKeywordControl: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();
  organicDomainMetrics$: Observable<OrganicDomainData> = new Observable<OrganicDomainData>();
  competitorsOrganicDomainMetrics$: Observable<SEODomainMetrics[]> = new Observable<SEODomainMetrics[]>();

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: SEOService,
    public snapshotService: SnapshotService,
    public snackbarService: SnackbarService,
    public translateService: TranslateService,
    public snapshotReportService: SnapshotReportService,
  ) {
    super(service, snackbarService, translateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.customKeywordControl.emit(this.controls.controls['customKeyword']);
    this.organicDomainMetrics$ = this.service.data$.pipe(map((ls) => ls.organicDomainData));

    this.competitorsOrganicDomainMetrics$ = combineLatest([
      this.organicDomainMetrics$,
      this.snapshotService.competitionAnalysis$,
    ]).pipe(
      map(([organicDomainData, ca]) => {
        const dataItems: SEODomainMetrics[] = [];
        if (ca === CompetitionAnalysis.INDUSTRY_DATA) {
          return dataItems;
        }
        organicDomainData.competitors.forEach((s) => {
          dataItems.push(s);
        });

        return dataItems;
      }),
    );
  }
}
