import { ApplicationInterface } from '@vendasta/snapshot';

export class Application implements ApplicationInterface {
  name: string;
  version?: string;

  constructor(data: ApplicationInterface) {
    this.name = data.name || '';
    this.version = data.version || '';
  }
}
