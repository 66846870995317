import { combineLatest as observableCombineLatest, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, skipWhile, switchMap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { AbstractSectionService } from '../section/section.service';
import { ListingContent } from './listing-content';
import { ListingData, ListingV2DetailItem, ListingV2ListingStatusEdit } from './listing-data';
import { ListingConfig, ListingConfigInterface } from './listing-config';
import { ContentService } from '../snapshot-report/content.service';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { SectionInterface } from '../section/section';
import { AvailableListingSourceInterface, ListingSectionService, ListingV2ListingStatus } from '@vendasta/snapshot';
import { GradeExplanationData } from '../grade/grade-explainer-dialog.component';
import { gradeToLetter } from '../common/utils';
import { bool2found } from '../common/ai-utils';

@Injectable()
export class ListingService
  extends AbstractSectionService<ListingContent, ListingData, ListingConfigInterface>
  implements OnDestroy
{
  sectionId = 'listing';

  private _listingDistributionContent$$: ReplaySubject<ListingContent> = new ReplaySubject(1);
  public listingDistributionContent$: Observable<ListingContent>;
  public listingDistributionMessage$: Observable<string>;
  public listingDistributionMessageId$: Observable<string>;
  public listingDistributionVideoUrl$: Observable<string>;
  public listingPresenceVersion$: Observable<number>;
  public availableListingSources$: Observable<AvailableListingSourceInterface[]>;
  public snapshotEnabledListings$: Observable<ListingV2DetailItem[]>;

  constructor(
    private _api: ListingSectionService,
    private _cs: ContentService,
    private _ss: SnapshotReportService,
  ) {
    super(_cs, _ss);
    this.listingDistributionContent$ = this._listingDistributionContent$$.asObservable().pipe(skipWhile((x) => !x));
    this.listingDistributionMessageId$ = this.listingDistributionContent$.pipe(
      map((c) => c.messageId),
      distinctUntilChanged(),
    );
    this.listingDistributionMessage$ = this.config$.pipe(
      map((c) => c.customizedListingDistributionMessage || ''),
      distinctUntilChanged(),
    );

    this.listingDistributionVideoUrl$ = observableCombineLatest([
      this.listingDistributionContent$,
      this._ss.videoStyle$,
    ]).pipe(
      map(([content, videoStyle]) => {
        return this._cs.getVideoUrl(content.videoId, videoStyle);
      }),
      distinctUntilChanged(),
    );

    this.listingPresenceVersion$ = this.config$.pipe(
      map((c) => c.listingPresenceVersion),
      distinctUntilChanged(),
    );

    this.availableListingSources$ = this._ss.snapshotId$.pipe(
      distinctUntilChanged(),
      switchMap((snapshotId) => this._api.getAvailableListingSources(snapshotId)),
    );

    this.snapshotEnabledListings$ = this.data$.pipe(map((data) => data.listings?.listings));
  }

  createGradeSubscription(): void {
    this.subscriptions.push(this.grade$.subscribe((g) => this._ss.setListingGrade(g)));
  }

  updateListing(snapshotID: string, sourceID: string, enable: boolean): Observable<string> {
    if (enable) {
      return this._api.unignoreListingSource(snapshotID, sourceID);
    } else {
      return this._api.ignoreListingSource(snapshotID, sourceID);
    }
  }

  load(): Observable<SectionInterface> {
    return this._api.get(this.snapshotId).pipe(
      map((section) => {
        this._listingDistributionContent$$.next(section.listingDistributionContent);
        return {
          grade: section.grade,
          config: new ListingConfig(section.config || {}),
          data: new ListingData(section.data),
          content: new ListingContent(section.content),
        };
      }),
    );
  }

  newConfig(): ListingConfigInterface {
    return new ListingConfig({});
  }

  _updateConfig(config: ListingConfigInterface): Observable<boolean> {
    return this._api.updateConfig(this.snapshotId, config);
  }

  getGradeExplanationData(): GradeExplanationData {
    return {
      titleTextID: 'LISTINGS.GRADE_EXPLANATION.TITLE',
      mobileTitleTextID: 'LISTINGS.GRADE_EXPLANATION.MOBILE_TITLE',
      primaryTextID: 'LISTINGS.GRADE_EXPLANATION.PRIMARY',
      showThermometer: true,
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private sourceStatus(v?: ListingV2ListingStatusEdit): string {
    switch (v) {
      case ListingV2ListingStatus.ACCURATE:
        return 'Accurate';
      case ListingV2ListingStatus.NOT_FOUND:
        return 'Not Found';
      case ListingV2ListingStatus.INACCURATE:
        return 'Possible Errors';
    }
    return 'Ignored';
  }

  getAiPromptDetails(): Observable<string> {
    return combineLatest([this._ss.listingSectionEnabled$, this.data$, this.grade$]).pipe(
      map(([sectionEnabled, data, grade]) => {
        if (!sectionEnabled) {
          return '';
        }
        const result: string[] = [];
        const letterGrade = gradeToLetter(grade);
        result.push(`Listing grade: ${letterGrade}`);

        const foundGoogle = bool2found(data?.listingPresence?.google?.foundCount > 0);
        result.push(`- Google: ${foundGoogle}`);
        const foundFacebook = bool2found(data?.listingPresence?.facebook?.foundCount > 0);
        result.push(`- Facebook: ${foundFacebook}`);
        const foundTwitter = bool2found(data?.listingPresence?.twitter?.foundCount > 0);
        result.push(`- X: ${foundTwitter}`);

        const listings = data?.listings?.listings ?? [];
        const accurate = listings.filter((l) => l.status === ListingV2ListingStatus.ACCURATE)?.length ?? 0;
        result.push(`- Accurate listing: ${accurate}`);
        const notFound = listings.filter((l) => l.status === ListingV2ListingStatus.NOT_FOUND)?.length ?? 0;
        result.push(`- Listing not found: ${notFound}`);
        const possibleError = listings.filter((l) => l.status === ListingV2ListingStatus.INACCURATE)?.length ?? 0;
        result.push(`- Possible listing error: ${possibleError}`);

        // limit to top 4 sources (same as the visible rows of the details table)
        const sources = listings.slice(0, 4) ?? [];
        if (sources.length > 0) {
          result.push(
            `Below are some major data providers, that would help you distribute your listing to hundreds of online sources including review sites, directories, social sites, search engines, GPS services and more.`,
          );
          sources.forEach((s) => {
            const status = this.sourceStatus(s?.status);
            result.push(`- ${s?.source?.sourceName}: ${status}`);
          });
        }

        return result.join('\n');
      }),
    );
  }

  allListings(): Observable<ListingV2DetailItem[]> {
    const allListings$ = this.availableListingSources$.pipe(
      map((sources) => sources.map(this.convertSourceToListingItem)),
    );

    const listings$ = combineLatest([allListings$, this.snapshotEnabledListings$]).pipe(
      map(([sources, listings]) => {
        return this.joinListings(sources, listings);
      }),
    );

    return listings$;
  }

  private joinListings(
    allListings: ListingV2DetailItem[],
    enabledListings: ListingV2DetailItem[],
  ): ListingV2DetailItem[] {
    allListings.forEach((sourceWithoutData) => {
      const listing = enabledListings.find(
        (listing) => listing?.source?.sourceId === sourceWithoutData?.source?.sourceId,
      );
      if (listing) {
        sourceWithoutData.address = listing.address;
        sourceWithoutData.city = listing.city;
        sourceWithoutData.zip = listing.zip;
        sourceWithoutData.phone = listing.phone;
        sourceWithoutData.state = listing.state;
        sourceWithoutData.companyName = listing.companyName;
        sourceWithoutData.website = listing.website;
        sourceWithoutData.status = listing.status;
        sourceWithoutData.listingUrl = listing?.listingUrl || '';
      }
    });

    allListings.sort((a, b) => {
      const aIndex = enabledListings.findIndex((listing) => listing.source.sourceId === a.source.sourceId);
      const bIndex = enabledListings.findIndex((listing) => listing.source.sourceId === b.source.sourceId);

      if (aIndex >= 0 && bIndex >= 0) {
        return aIndex - bIndex;
      } else if (aIndex >= 0) {
        return -1;
      } else if (bIndex >= 0) {
        return 1;
      } else {
        return 0;
      }
    });

    return allListings;
  }

  private convertSourceToListingItem(source: AvailableListingSourceInterface): ListingV2DetailItem {
    return {
      source: { ...source },
      status: -1,
      ignore: source.ignored,
    } as ListingV2DetailItem;
  }
}
