<div class="cover-page-container">
  <div class="cover-page flex-column align-space-between-start">
    <div class="cover-page-header">
      <div class="logo-container flex align-space-between-center">
        <img *ngIf="whitelabel.logoUrl$ | async" [src]="whitelabel.logoUrl$ | async" />
        <div class="snapshot-title flex align-start-center">
          <mat-icon>assignment</mat-icon>
          <span>{{ snapshotName$ | async | translate | uppercase }}</span>
        </div>
      </div>
      <div class="separator" [ngStyle]="{ 'background-color': (whitelabel.primaryColor$ | async) }"></div>
      <h1 id="report-title">{{ title$ | async }}</h1>
      <div class="address flex align-start-center">
        <mat-icon>location_on</mat-icon>
        <span>{{ address$ | async }}</span>
      </div>
    </div>
    <div class="cover-page-contact" *ngIf="salesPersonService.salesPerson$ | async as sp">
      <div class="prepared">{{ 'VIEW_REPORT.PREPARED_BY' | translate }}</div>
      <div *ngIf="sp.firstName || sp.lastName" class="flex cover-page-contact-details">
        <div class="flex-column align-center-start">
          <span class="name">{{ sp.firstName }} {{ sp.lastName }}</span>
          <span class="job-title">{{ sp.jobTitle }}</span>
          <div *ngIf="sp.phoneNumbers?.length > 0">
            <span>{{ sp.phoneNumbers[0] | phone : sp.country }}</span>
          </div>
          <span>{{ sp.email }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
