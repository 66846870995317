import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, throwError as observableThrowError } from 'rxjs';
import { catchError, map, share, take } from 'rxjs/operators';

import { PartnerService } from '@galaxy/partner';
import { AccountGroup } from './account-group';
import { errorCanBeHandled, findAccessibleAccountGroupIds } from './account-group-helpers';
import { AccountGroupApiService } from './account-group.api.service';
import { PagedResponse } from './account-group.api.service.response';
import { ApiLookupFilter, ApiTrialFilter, LookupFilter } from './lookup-filters';
import { ProjectionFilter } from './projection-filter';
import { ReadFilter } from './read-filter';
import { SearchOptions } from './search-options';
import { fieldNameToSortField, SortDirection, SortField, SortOptions } from './sort-options';
import { UpdateOperations } from './update-operations';

@Injectable({
  providedIn: 'root',
})
export class AccountGroupService implements OnDestroy {
  private cursor: string = null;
  private hasMoreSubject$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hasMore$: Observable<boolean> = this.hasMoreSubject$$.asObservable();
  private accountGroupSubject$$: BehaviorSubject<AccountGroup[]> = new BehaviorSubject<AccountGroup[]>([]);
  private accountGroup$: Observable<AccountGroup[]> = this.accountGroupSubject$$.asObservable();
  private totalResultsSubject$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private totalResults$: Observable<number> = this.totalResultsSubject$$.asObservable();
  private loadingSubject$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loading$: Observable<boolean> = this.loadingSubject$$.asObservable();
  private partnerId: string;
  private subscriptions: Subscription[] = [];

  private latestResponse: BehaviorSubject<PagedResponse> = new BehaviorSubject<PagedResponse>(null);

  private sortOptions: SortOptions = {
    field: SortField.Created,
    direction: SortDirection.Descending,
  };

  constructor(private accountGroupApiService: AccountGroupApiService, private partnerService: PartnerService) {
    this.subscriptions.push(
      this.partnerService.getPartnerId().subscribe((partnerId) => {
        this.partnerId = partnerId;
      }),
    );
  }

  get hasMore(): Observable<boolean> {
    return this.hasMore$;
  }

  get accountGroups(): Observable<AccountGroup[]> {
    return this.accountGroup$;
  }

  get totalResults(): Observable<number> {
    return this.totalResults$;
  }

  get loading(): Observable<boolean> {
    return this.loading$;
  }

  lookup(
    projectionFilter: ProjectionFilter,
    marketIds: string[] = null,
    searchTerm: string = null,
    filters: LookupFilter = null,
    pageSize = 10,
    searchOptions?: SearchOptions,
  ): Observable<AccountGroup[]> {
    return this.lookupAccountGroups(marketIds, projectionFilter, searchTerm, filters, '', pageSize, searchOptions);
  }

  get(accountGroupId: string, projectionFilter: ProjectionFilter): Observable<AccountGroup> {
    return this.accountGroupApiService.get(accountGroupId, projectionFilter);
  }

  getMulti(
    accountGroupIds: string[],
    projectionFilter: ProjectionFilter,
    readFilter?: ReadFilter,
    returnOnlyAccessibleAccountGroups = false,
  ): Observable<AccountGroup[]> {
    if (returnOnlyAccessibleAccountGroups === false) {
      return this.accountGroupApiService.getMulti(accountGroupIds, projectionFilter, readFilter);
    } else {
      return this.accountGroupApiService
        .getMulti(accountGroupIds, projectionFilter, readFilter)
        .pipe(catchError((error) => this.handleError(error, accountGroupIds, projectionFilter, readFilter)));
    }
  }

  private handleError(
    error: any,
    accountGroupIds: string[],
    projectionFilter: ProjectionFilter,
    readFilter: ReadFilter,
  ): Observable<AccountGroup[]> {
    if (errorCanBeHandled(error)) {
      const validAccountGroups: string[] = findAccessibleAccountGroupIds(error, accountGroupIds);

      if ((validAccountGroups.length ?? 0) === 0) {
        throw error;
      }

      return this.getValidAccountGroups(validAccountGroups, projectionFilter, readFilter);
    }
    throw error;
  }

  getValidAccountGroups(
    accountGroupIds: string[],
    projectionFilter: ProjectionFilter,
    readFilter: ReadFilter,
  ): Observable<AccountGroup[]> {
    return this.accountGroupApiService.getMulti(accountGroupIds, projectionFilter, readFilter);
  }

  bulkUpdate(accountGroupId: string, updateOperations: UpdateOperations): Observable<HttpResponse<null>> {
    const call = this.accountGroupApiService.bulkUpdate(accountGroupId, updateOperations).pipe(
      catchError((err: HttpErrorResponse) => {
        let message;
        if (err.status === 401) {
          message = 'Invalid Session. Please try again.';
        } else if (err.status === 400) {
          message = (err.error && err.error.message) || 'Invalid submission';
        } else {
          message = 'Failed to update';
        }
        return observableThrowError({ message: message, retryable: err.status === 401 });
      }),
      share(),
    );
    call.subscribe(null, () => {
      // Only handling errors so that later subscribers will also receive them https://github.com/ReactiveX/rxjs/issues/2145
    });
    return call;
  }

  loadMore(
    projectionFilter: ProjectionFilter,
    marketIds: string[],
    searchTerm: string,
    filters: LookupFilter,
    pageSize = 10,
    searchOptions?: SearchOptions,
  ): Observable<AccountGroup[]> {
    if (this.hasMoreSubject$$.getValue()) {
      return this.lookupAccountGroups(
        marketIds,
        projectionFilter,
        searchTerm,
        filters,
        this.cursor,
        pageSize,
        searchOptions,
      );
    }
    return observableThrowError(new Error('No more results'));
  }

  removeAccountGroupFromList(accountGroupId: string): void {
    const newAccountGroups: AccountGroup[] = this.accountGroupSubject$$
      .getValue()
      .filter((accountGroup) => accountGroup.accountGroupId !== accountGroupId);

    if (newAccountGroups.length < this.accountGroupSubject$$.getValue().length) {
      this.accountGroupSubject$$.next(newAccountGroups);
      this.totalResultsSubject$$.next(this.totalResultsSubject$$.getValue() - 1);
    }
  }

  setSortOptions(fieldName: string, ascending = true): void {
    this.sortOptions = {
      field: fieldNameToSortField(fieldName),
      direction: ascending ? SortDirection.Ascending : SortDirection.Descending,
    };
  }

  private lookupAccountGroups(
    marketIds: string[],
    projectionFilter: ProjectionFilter,
    searchTerm: string,
    filters: LookupFilter,
    cursor: string,
    pageSize: number,
    searchOptions?: SearchOptions,
  ): Observable<AccountGroup[]> {
    if (!filters) {
      filters = new LookupFilter();
    }

    this.cursor = null;
    if (!cursor) {
      this.accountGroupSubject$$.next(null);
    }
    this.hasMoreSubject$$.next(false);
    this.loadingSubject$$.next(true);
    return this.lookupApi(
      filters,
      projectionFilter,
      marketIds,
      cursor,
      pageSize,
      searchTerm,
      this.sortOptions,
      searchOptions,
    );
  }

  private lookupApi(
    filters: LookupFilter,
    projectionFilter: ProjectionFilter,
    marketIds: string[],
    cursor: string,
    pageSize: number,
    searchTerm: string,
    sortOptions: SortOptions,
    searchOptions?: SearchOptions,
  ): Observable<AccountGroup[]> {
    let isSuspended: boolean;
    // If overLimit and active are either both true, or both falsy, we don't care about the filter - it has the same result
    if (
      (filters.trialFilter.overLimit && !filters.trialFilter.active) ||
      (!filters.trialFilter.overLimit && filters.trialFilter.active)
    ) {
      // Otherwise one of the filters is true; set isSuspended to the filter that has a value
      isSuspended = filters.trialFilter.overLimit ? filters.trialFilter.overLimit : !filters.trialFilter.active;
    }

    const apiTrialFilter = new ApiTrialFilter(isSuspended);
    const apiFilters: ApiLookupFilter = new ApiLookupFilter(
      this.partnerId,
      marketIds,
      filters.salesPersonId,
      filters.tags,
      filters.accountFilters,
      filters.listingDistributionFilter,
      filters.listingSyncProFilter,
      filters.createdDateFilter,
      apiTrialFilter,
      filters.taxonomyIds,
      filters.presenceFilter,
      filters.locationFilter,
      filters.snapshotFilter,
      filters.statusFilter,
      filters.accountGroupIds,
      filters.lifecycleStageFilter,
    );

    this.accountGroupApiService
      .lookup(projectionFilter, apiFilters, cursor, pageSize, searchTerm, sortOptions, searchOptions)
      .pipe(map(this.handleResponse(!!cursor)), take(1))
      .subscribe(this.loadNextPagedResponse.bind(this));

    return this.accountGroups;
  }

  private handleResponse(appendResults: boolean): (pagedResponse: PagedResponse) => PagedResponse {
    return (pagedResponse: PagedResponse) => {
      if (appendResults && this.latestResponse.getValue()) {
        return {
          accountGroups: this.latestResponse.getValue().accountGroups.concat(pagedResponse.accountGroups),
          nextCursor: pagedResponse.nextCursor,
          hasMore: pagedResponse.hasMore,
          totalResults: pagedResponse.totalResults,
        };
      }
      return pagedResponse;
    };
  }

  private loadNextPagedResponse(pagedResponse: PagedResponse): void {
    this.latestResponse.next(pagedResponse);
    this.cursor = pagedResponse.nextCursor;
    this.hasMoreSubject$$.next(pagedResponse.hasMore);
    this.totalResultsSubject$$.next(pagedResponse.totalResults);
    this.accountGroupSubject$$.next(pagedResponse.accountGroups);
    this.loadingSubject$$.next(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.accountGroupSubject$$.complete();
    this.hasMoreSubject$$.complete();
    this.totalResultsSubject$$.complete();
    this.loadingSubject$$.complete();
    this.latestResponse.complete();
  }
}
