<ng-container *ngIf="hasWebsite$ | async; else noWebsiteTemplate">
  <snap-content-banner
    class="hide-mobile"
    [hideControl]="controls.controls['hideContent']"
    [messageControl]="controls.controls['customizedMessage']"
    [videoUrl]="service.videoUrl$ | async"
    [customizedMessage]="service.message$ | async"
    [contentId]="service.messageId$ | async"
    [edit]="edit"
    [hideVideo]="false"
  ></snap-content-banner>
  <snap-subsection
    heading="{{ 'ECOMMERCE.HEADING' | translate }}"
    subheading="{{ 'ECOMMERCE.SUBHEADING' | translate }}"
    [edit]="edit"
    [hideControl]=""
  >
    <ng-container *ngIf="service.grade$ | async; else noEcommerceData">
      <ng-container *ngIf="hasCompetitorsItems$ | async as competitors">
        <ng-container *ngIf="competitors?.length > 0; else noCompetitors">
          <ng-container *ngIf="media.mobile$ | async; else desktopCompetitors">
            <snap-mobile-view-data
              [hideGrades]="true"
              [edit]="edit"
              [subsections]="competitors"
            ></snap-mobile-view-data>
          </ng-container>

          <ng-template #desktopCompetitors>
            <snap-table [showIndustryAverage]="false" [editMode]="edit" [items]="competitors"></snap-table>
          </ng-template>
        </ng-container>
      </ng-container>

      <ng-template #noCompetitors>
        <span *ngIf="noCompetitorsItems$ | async as items">
          <ng-container *ngIf="media.mobile$ | async; else desktopIndustry">
            <snap-toggled-item
              *ngFor="let item of items || []; let last = last"
              [edit]="edit"
              [hideControl]="item.control"
              [attr.data-testid]="item.datatestid || ''"
            >
              <div class="mobile-data-container">
                <div class="title-row">
                  <div class="text-and-grades">
                    <mat-icon class="icon" [ngClass]="item.numberOfSolutions > 0 ? 'green-icon' : 'red-icon'">
                      {{ item.numberOfSolutions > 0 ? 'check_circle' : 'cancel' }}
                    </mat-icon>
                    <span class="title-text">{{ item.attribute }}</span>
                  </div>
                  <ng-template snapItemToggle></ng-template>
                </div>
                {{ item.recommendation }}
              </div>
              <mat-divider *ngIf="!last"></mat-divider>
            </snap-toggled-item>
          </ng-container>

          <ng-template #desktopIndustry>
            <snap-ecommerce-table [items]="items" [editMode]="edit"></snap-ecommerce-table>
          </ng-template>
        </span>
      </ng-template>
    </ng-container>

    <ng-template #noEcommerceData>
      <div class="data-not-found" data-testid="data-not-found">
        <glxy-empty-state [size]="'small'" [widthConstraint]="false">
          <glxy-empty-state-hero>
            <mat-icon>error</mat-icon>
          </glxy-empty-state-hero>
          <glxy-empty-state-title>
            {{ 'ECOMMERCE.ERROR_FETCHING_DATA' | translate }}
          </glxy-empty-state-title>
        </glxy-empty-state>
      </div>
    </ng-template>
  </snap-subsection>
</ng-container>

<ng-template #noWebsiteTemplate>
  <snap-subsection heading="{{ 'ECOMMERCE.NO_WEBSITE' | translate }}" [edit]="edit">
    <div class="no-website">
      <glxy-empty-state [size]="'small'" [widthConstraint]="false">
        <glxy-empty-state-hero>
          <mat-icon>computer</mat-icon>
        </glxy-empty-state-hero>
        <glxy-empty-state-title>
          {{ 'ECOMMERCE.NO_SITE_ERROR_TITLE' | translate }}
        </glxy-empty-state-title>
        <p>
          {{ 'ECOMMERCE.NO_SITE_ERROR_DESCRIPTION' | translate }}
          <br />
          {{ 'ECOMMERCE.NO_SITE_ERROR_TAGLINE' | translate }}
        </p>
      </glxy-empty-state>
    </div>
  </snap-subsection>
</ng-template>
