import {
  AccountGroupExternalIdentifiers,
  AdditionalCompanyInfo,
  ContactDetails,
  GeoPoint,
  HoursOfOperation,
  LegacyProductDetails,
  ListingDistributionDetails,
  ListingSyncProDetails,
  MarketingInfo,
  RichData,
  ServiceArea,
  Snapshot,
  SocialURLs,
  Status,
} from './account-group';
// tslint:disable-next-line:import-blacklist
import { EstimatedAnnualRevenue, LifecycleStage, MarketingClassification } from '@vendasta/account-group';

/**
 * This is a copy of internal rxjs implementation details. This function used to be exported, but is now unexported as
 * it wasn't intended to be used outside the library itself. We copied this as a low effort way to migrate to rxjs 7.
 * We should remove this and use the public API.
 *
 * Ultimately, this is based off the alternative implementation of the `applyMixins` function from the TypeScript handbook:
 * https://www.typescriptlang.org/docs/handbook/mixins.html#alternative-pattern
 *
 * @param derivedCtor The class that will be extended
 * @param baseCtors The mixins that will be applied to the derived class
 * @returns void
 *
 * It may make sense to move this to a shared lib, or look at the current typescript handbook implementation to see if
 * there is a better way to do this. I.e. without using `any` types.
 */

function applyMixins(derivedCtor: any, baseCtors: any[]): void {
  for (let i = 0, len = baseCtors.length; i < len; i++) {
    const baseCtor = baseCtors[i];
    const propertyKeys = Object.getOwnPropertyNames(baseCtor.prototype);
    for (let j = 0, len2 = propertyKeys.length; j < len2; j++) {
      const name = propertyKeys[j];
      derivedCtor.prototype[name] = baseCtor.prototype[name];
    }
  }
}

export class UpdateOperations {
  private operations: AbstractUpdateOperation[] = [];

  addUpdateOperation(updateOperation: AbstractUpdateOperation): void {
    this.operations.push(updateOperation);
  }

  toApiJson(): object[] {
    if (this.operations.length === 0) {
      return null;
    }

    return this.operations
      .map((operation: AbstractUpdateOperation) => {
        const op: any = {};
        const json = operation.toApiJson();

        if (json === null) {
          return null;
        }

        const mask = operation.getMask();

        op[operation.OPERATION_ID] = this.cleanNonFieldMaskProperties(json, mask);
        op.fieldMask = {
          paths: mask,
        };
        return op;
      })
      .filter((op) => !!op);
  }

  cleanNonFieldMaskProperties(json: Record<string, any>, paths: string[]): object {
    for (const key in json) {
      if (key in json && paths.indexOf(key) === -1) {
        delete json[key];
      }
    }
    return json;
  }
}

export abstract class AbstractUpdateOperation {
  OPERATION_ID: string;

  abstract toApiJson(): object;

  getMask(): string[] {
    const mask: string[] = [];
    for (const key in this) {
      if (key in this && typeof this[key] !== 'undefined' && key !== 'OPERATION_ID') {
        mask.push(key);
      }
    }

    return mask;
  }
}

export class NapUpdateOperation extends AbstractUpdateOperation {
  OPERATION_ID = 'accountGroupNap';
  companyName: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  serviceAreaBusiness: boolean;
  serviceArea: ServiceArea;
  website: string;
  workNumber: string[];
  callTrackingNumber: string[];
  location: GeoPoint;
  timezone: string;

  constructor(kwargs?: Partial<NapUpdateOperation>) {
    super();
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.companyName === 'undefined' &&
      typeof this.address === 'undefined' &&
      typeof this.address2 === 'undefined' &&
      typeof this.city === 'undefined' &&
      typeof this.state === 'undefined' &&
      typeof this.zip === 'undefined' &&
      typeof this.country === 'undefined' &&
      typeof this.website === 'undefined' &&
      typeof this.workNumber === 'undefined' &&
      typeof this.callTrackingNumber === 'undefined' &&
      typeof this.location === 'undefined' &&
      typeof this.timezone === 'undefined' &&
      typeof this.serviceAreaBusiness === 'undefined' &&
      typeof this.serviceArea === 'undefined'
    ) {
      return null;
    }
    return {
      companyName: typeof this.companyName !== 'undefined' ? this.companyName : null,
      address: typeof this.address !== 'undefined' ? this.address : null,
      address2: typeof this.address2 !== 'undefined' ? this.address2 : null,
      city: typeof this.city !== 'undefined' ? this.city : null,
      state: typeof this.state !== 'undefined' ? this.state : null,
      zip: typeof this.zip !== 'undefined' ? this.zip : null,
      country: typeof this.country !== 'undefined' ? this.country : null,
      serviceAreaBusiness: typeof this.serviceAreaBusiness !== 'undefined' ? this.serviceAreaBusiness : null,
      website: typeof this.website !== 'undefined' ? this.website : null,
      workNumber: typeof this.workNumber !== 'undefined' ? this.workNumber : null,
      callTrackingNumber: typeof this.callTrackingNumber !== 'undefined' ? this.callTrackingNumber : null,
      location: typeof this.location !== 'undefined' ? this.location.toApiJson() : null,
      timezone: typeof this.timezone !== 'undefined' ? this.timezone : null,
      serviceArea: typeof this.serviceArea !== 'undefined' ? this.serviceArea?.toApiJson() : null,
    };
  }
}

export class SnapshotsUpdateOperation extends AbstractUpdateOperation {
  OPERATION_ID = 'snapshots';
  snapshots: Snapshot[];

  constructor(kwargs?: Partial<SnapshotsUpdateOperation>) {
    super();
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (typeof this.snapshots === 'undefined') {
      return null;
    }
    return {
      snapshots: typeof this.snapshots !== 'undefined' ? this.snapshots.map((snapshot) => snapshot.toApiJson()) : null,
    };
  }
}

export class HealthUpdateOperation extends AbstractUpdateOperation {
  OPERATION_ID = 'health';
  rating?: number;
  reason?: string;

  constructor(kwargs?: Partial<HealthUpdateOperation>) {
    super();
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (typeof this.rating === 'undefined' && typeof this.reason === 'undefined') {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.rating !== 'undefined') {
      toReturn['rating'] = this.rating;
    }
    if (typeof this.reason !== 'undefined') {
      toReturn['reason'] = this.reason;
    }
    return toReturn;
  }
}

export class HoursOfOperationUpdateOperation extends AbstractUpdateOperation {
  OPERATION_ID = 'hoursOfOperation';
  hoursOfOperation: HoursOfOperation[];

  constructor(kwargs?: Partial<HoursOfOperationUpdateOperation>) {
    super();
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (typeof this.hoursOfOperation === 'undefined') {
      return null;
    }

    return {
      hoursOfOperation:
        typeof this.hoursOfOperation !== 'undefined' ? this.hoursOfOperation.map((span) => span.toApiJson()) : null,
    };
  }
}
applyMixins(HoursOfOperationUpdateOperation, [AbstractUpdateOperation]);

export class ListingDistributionDetailsUpdateOperation
  extends ListingDistributionDetails
  implements AbstractUpdateOperation
{
  OPERATION_ID = 'listingDistribution';
  getMask: () => string[];
  toApiJsonWithMask: () => object;
}
applyMixins(ListingDistributionDetailsUpdateOperation, [AbstractUpdateOperation]);

export class ListingSyncProUpdateOperation extends ListingSyncProDetails implements AbstractUpdateOperation {
  OPERATION_ID = 'listingSyncProto';
  getMask: () => string[];
  toApiJsonWithMask: () => object;
}
applyMixins(ListingSyncProUpdateOperation, [AbstractUpdateOperation]);

export class AccountGroupExternalIdentifiersUpdateOperation
  extends AccountGroupExternalIdentifiers
  implements AbstractUpdateOperation
{
  OPERATION_ID = 'accountGroupExternalIdentifiers';
  getMask: () => string[];
  toApiJsonWithMask: () => object;
}
applyMixins(AccountGroupExternalIdentifiersUpdateOperation, [AbstractUpdateOperation]);

export class SocialURLsUpdateOperation extends SocialURLs implements AbstractUpdateOperation {
  OPERATION_ID = 'socialUrls';
  getMask: () => string[];
  toApiJsonWithMask: () => object;
}
applyMixins(SocialURLsUpdateOperation, [AbstractUpdateOperation]);

export class ContactDetailsUpdateOperation extends ContactDetails implements AbstractUpdateOperation {
  OPERATION_ID = 'contactDetails';
  getMask: () => string[];
  toApiJsonWithMask: () => object;
}
applyMixins(ContactDetailsUpdateOperation, [AbstractUpdateOperation]);

export class LegacyProductDetailsUpdateOperation extends LegacyProductDetails implements AbstractUpdateOperation {
  OPERATION_ID = 'legacyProductDetails';
  getMask: () => string[];
  toApiJsonWithMask: () => object;
}
applyMixins(LegacyProductDetailsUpdateOperation, [AbstractUpdateOperation]);

export class RichDataUpdateOperation extends RichData implements AbstractUpdateOperation {
  OPERATION_ID = 'richData';
  getMask: () => string[];
  toApiJsonWithMask: () => object;
}
applyMixins(RichDataUpdateOperation, [AbstractUpdateOperation]);

export class StatusUpdateOperation extends Status implements AbstractUpdateOperation {
  OPERATION_ID = 'status';
  getMask: () => string[];
  toApiJsonWithMask: () => object;
}
applyMixins(StatusUpdateOperation, [AbstractUpdateOperation]);

export class AdditionalCompanyInfoOperation extends AdditionalCompanyInfo implements AbstractUpdateOperation {
  OPERATION_ID = 'additionalCompanyInfo';

  getMask: () => string[];
  numEmployees: number;
  estimatedAnnualRevenue: EstimatedAnnualRevenue;

  constructor(kwargs?: Partial<AdditionalCompanyInfoOperation>) {
    super();
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (typeof this.numEmployees === 'undefined' && typeof this.estimatedAnnualRevenue === 'undefined') {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.numEmployees !== 'undefined') {
      toReturn['numEmployees'] = this.numEmployees;
    }
    if (typeof this.estimatedAnnualRevenue !== 'undefined') {
      toReturn['estimatedAnnualRevenue'] = this.estimatedAnnualRevenue;
    }
    return toReturn;
  }
}
applyMixins(AdditionalCompanyInfoOperation, [AbstractUpdateOperation]);

export class MarketingInfoOperation extends MarketingInfo implements AbstractUpdateOperation {
  OPERATION_ID = 'marketingInfo';
  getMask: () => string[];

  conversionPoint: string;
  marketingClassification: MarketingClassification;
  lifecycleStage: LifecycleStage;

  constructor(kwargs?: Partial<MarketingInfo>) {
    super();
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.conversionPoint === 'undefined' &&
      typeof this.marketingClassification === 'undefined' &&
      typeof this.lifecycleStage === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.conversionPoint !== 'undefined') {
      toReturn['conversionPoint'] = this.conversionPoint;
    }
    if (typeof this.marketingClassification !== 'undefined') {
      toReturn['marketingClassification'] = this.marketingClassification;
    }
    if (typeof this.lifecycleStage !== 'undefined') {
      toReturn['lifecycleStage'] = this.lifecycleStage;
    }

    return toReturn;
  }
}
applyMixins(MarketingInfoOperation, [AbstractUpdateOperation]);
