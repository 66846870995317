import { Component, Input } from '@angular/core';
import { BaseHeaderComponent } from '../section/base-header.component';
import { ReviewService } from './review.service';

@Component({
  selector: 'snap-review-section-header',
  template: `
    <snap-grade-tagline
      [hideGrade]="hideGrade"
      [grade]="service.grade$ | async"
      heading="{{ 'TITLES.REVIEWS' | translate }}"
      tagline="{{ 'REVIEWS.TAGLINE' | translate }}"
    ></snap-grade-tagline>
  `,
  styleUrls: ['./review-section.component.scss'],
})
export class ReviewSectionHeaderComponent extends BaseHeaderComponent {
  @Input() hideGrade: boolean;

  constructor(public service: ReviewService) {
    super(service);
  }
}
