<div class="page-container">
  <div class="report-container flex-column">
    <div *ngIf="whitelabel.partnerName$ | async" class="header flex align-space-between-center">
      <img class="logo" *ngIf="whitelabel.logoUrl$ | async as logoUrl" [src]="logoUrl" />
      <div class="salesperson-info flex align-start-center" (click)="openAskQuestionDialog('lite-salesperson')">
        <ng-container *ngIf="snapshotLiteService.salesperson$ | async as sp; else noSalesPersonTemplate">
          <div *ngIf="sp.photoUrl" class="flex salesperson-image-container">
            <img class="salesperson-image" [src]="sp.photoUrl" />
          </div>
          <div *ngIf="!sp.photoUrl" class="flex salesperson-image-container">
            <img
              class="salesperson-image"
              src="https://vstatic-prod.apigateway.co/snapshot-client.9223372035128602063.prod/assets/images/default_sales_person.jpg"
            />
          </div>
          <div class="flex-column salesperson-details-container">
            <span class="salesperson-name">{{ sp.firstName }} {{ sp.lastName }}</span>
            <span class="job-title">{{ sp.jobTitle }}</span>
            <div *ngIf="sp.phoneNumbers?.length > 0">
              <span>{{ sp.phoneNumbers[0] | phone : sp.country }}</span>
            </div>
            <div>
              <span class="salesperson-email">{{ sp.email }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="hero-banner">
    <ng-container *ngIf="loaded$ | async; else loadingTemplate">
      <div class="score-nap flex align-center-center">
        <snap-score-donut [score]="scanScore$ | async" diameter="300" strokeWidth="2"></snap-score-donut>
        <div class="nap flex-column align-start-start" *ngIf="snapshotLiteService.business$ | async as napData">
          <div class="name">{{ napData.companyName }}</div>
          <button mat-icon-button (click)="openEditDialog()" [disableRipple]="true" class="edit-business-data">
            <mat-icon>mode_edit</mat-icon>
            <span>{{ 'COMMON.EDIT' | translate }}</span>
          </button>
          <div>{{ napData.address }}</div>
          <div>{{ napData.city }}, {{ napData.state }} {{ napData.zip }}</div>
          <div *ngIf="napData.phoneNumber">
            {{ napData.phoneNumber | phone : 'US' }}
          </div>
          <button class="call-to-action" mat-flat-button color="primary" (click)="openAskQuestionDialog('lite-header')">
            {{ 'SNAPSHOT_LITE.CALL_TO_ACTION' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="report-container">
    <mat-card appearance="outlined" *ngIf="loaded$ | async" class="listings">
      <snap-listing-table [listings]="(snapshotLiteService.listingScan$ | async)?.listings"></snap-listing-table>
    </mat-card>
  </div>

  <div id="google-attribution"></div>
</div>

<ng-template #noSalesPersonTemplate>
  <div class="salesperson-image-container">
    <img
      class="salesperson-image"
      src="https://vstatic-prod.apigateway.co/snapshot-client.9223372035128602063.prod/assets/images/default_sales_person.jpg"
    />
  </div>
  <div class="flex-column salesperson-details-container">
    <span class="salesperson-name">{{ whitelabel.partnerName$ | async }}</span>
    <div>
      <span class="salesperson-email">
        {{ 'COMMON.CONTACT_US' | translate }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="loading flex-column align-center-center">
    <mat-progress-spinner mode="indeterminate" diameter="100"></mat-progress-spinner>
    <div>
      {{ 'SNAPSHOT_LITE.LOADING' | translate }}
    </div>
  </div>
</ng-template>
