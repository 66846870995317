import { OAuth2Service, OAuth2ServiceConfig, ServiceProviderEnvConfig } from '@vendasta/oauth2';
import { Environment, SessionService } from '@galaxy/core';
import { devServer } from '../../globals';
import { inject, Injectable, InjectionToken } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { combineLatest, map, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { RoutingService } from '../app.routes';

const baseAuthConfig: AuthConfig = {};

const authConfigForEnv: ServiceProviderEnvConfig = {
  [Environment.LOCAL]: {
    serviceProviderId: 'snapshot',
    redirectUri: 'https://snapshot-api-demo.apigateway.co/redirect',
    silentRefreshRedirectUri: 'https://localhost:4200/silent-refresh.html',
    clientId: 'ce78ba0b-a079-4609-90e8-9cc4f6f495cf',
  },
  [Environment.DEMO]: {
    serviceProviderId: 'snapshot',
    redirectUri: 'https://snapshot-api-demo.apigateway.co/redirect',
    silentRefreshRedirectUri: 'https://vendasta-training-demo.snapshotreport.biz/silent-refresh.html',
    clientId: 'ce78ba0b-a079-4609-90e8-9cc4f6f495cf',
  },
  [Environment.PROD]: {
    serviceProviderId: 'snapshot',
    redirectUri: 'https://snapshot-api-prod.apigateway.co/redirect',
    silentRefreshRedirectUri: 'https://vendasta-training.snapshotreport.biz/silent-refresh.html',
    clientId: '6ab51f33-2bfe-4855-8422-906aa9e959c4',
  },
};

export function oauth2ConfigFactory(): OAuth2ServiceConfig {
  const config: OAuth2ServiceConfig = {
    oauthConfig: baseAuthConfig,
    scopes: ['admin', 'openid', 'profile', 'partner.admin', 'business', 'business:read'],
    serviceProviderConfigs: authConfigForEnv,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customQueryParamsFunc: (next: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot) => {
      const partner_id = next.paramMap.get('partnerId') ?? next.queryParamMap.get('partnerId');
      if (partner_id) {
        return { partner_id };
      }
      return {};
    },
  };
  // replicate the local config for demo if we're running locally
  if (devServer) {
    config.serviceProviderConfigs[Environment.DEMO] = { ...authConfigForEnv[Environment.LOCAL] };
  }
  // set the silentRefreshRedirectUri to the current domain and path
  for (const env in config.serviceProviderConfigs) {
    config.serviceProviderConfigs[env].silentRefreshRedirectUri = `${window.location.origin}/silent-refresh.html`;
  }
  return config;
}

export const salestoolSession = new InjectionToken<SessionService>('SessionService');
@Injectable()
export class AuthService extends OAuth2Service {
  private readonly sessionService: SessionService = inject(salestoolSession);
  private readonly routingService: RoutingService = inject(RoutingService);
  private readonly _router = inject(Router);

  public getSessionId(): Observable<string> {
    const oauthSessionId$ = super.getSessionId();
    const salestoolSessionId$ = this.sessionService.getSessionId();
    return combineLatest([oauthSessionId$, salestoolSessionId$]).pipe(
      map(([oauthSessionId, salestoolSessionId]) => {
        if (salestoolSessionId && this.routingService.isOldEditRoute(this._router.url)) {
          return salestoolSessionId;
        } else {
          return oauthSessionId ?? salestoolSessionId;
        }
      }),
    );
  }

  public setSessionId(sessionId: string): void {
    super.setSessionId(sessionId);
    this.sessionService.setSessionId(sessionId);
  }

  /**
   * Get the OAuth2 session ID.
   * The salestool session is not considered.
   */
  public getOAuth2SessionId(): Observable<string> {
    return super.getSessionId();
  }
}
