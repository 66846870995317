import { AbstractControl } from '@angular/forms';

export interface SocialItemData {
  name: string;
  sourceIcon: string;
  foundCount: number;
  exampleListingUrl: string;
  industryAverageFound: number;
  control: AbstractControl;
}

export const SocialItemGoogle = {
  name: 'Google',
  sourceIcon: 'https://www.cdnstyles.com/static/images/icon50/sourceId-10010.png',
};

export const SocialItemFacebook = {
  name: 'Facebook',
  sourceIcon: 'https://www.cdnstyles.com/static/images/icon50/sourceId-10050.png',
};

export const SocialItemInstagram = {
  name: 'Instagram',
  sourceIcon: 'https://www.cdnstyles.com/static/images/icon50/sourceId-11610.png',
};

export const SocialItemTwitter = {
  name: 'X',
  sourceIcon: 'https://www.cdnstyles.com/static/images/icon50/sourceId-10060.png',
};

export interface SocialUrls {
  facebookUrl: string;
  twitterUrl: string;
  instagramUrl: string;
}
