import { EcommerceDataCompetitorInterface } from '@vendasta/snapshot';
import { Application } from './ecommerce.application';

export class EcommerceCompetitor implements EcommerceDataCompetitorInterface {
  name: string;
  website: string;
  ecommerceSolution: Application[];
  onlinePayments: Application[];
  adRetargeting: Application[];
  appointmentScheduling: Application[];

  constructor(data: EcommerceDataCompetitorInterface) {
    this.name = data.companyName || '';
    this.website = data.website || '';
    this.ecommerceSolution = data.ecommerceSolution ? data.ecommerceSolution.map((i) => new Application(i)) : [];
    this.onlinePayments = data.onlinePayments ? data.onlinePayments.map((i) => new Application(i)) : [];
    this.adRetargeting = data.adRetargeting ? data.adRetargeting.map((i) => new Application(i)) : [];
    this.appointmentScheduling = data.appointmentScheduling
      ? data.appointmentScheduling.map((i) => new Application(i))
      : [];
  }
}
