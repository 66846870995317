<div class="login">
  <section class="mat-typography mat-elevation-z8 login-card">
    <h1 class="login-card__title">Sign In</h1>
    <form #f="ngForm" class="example-form" (ngSubmit)="login(f)">
      <mat-form-field class="login-card__field">
        <input
          matInput
          placeholder="Email"
          [(ngModel)]="email"
          name="email"
          required
        />
      </mat-form-field>
      <mat-form-field class="login-card__field">
        <input
          type="password"
          matInput
          placeholder="Password"
          [(ngModel)]="password"
          name="password"
          required
        />
      </mat-form-field>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        class="login-card__login-button"
      >
        Login
      </button>
    </form>
    <div class="sso-separator"><span class="sso-separator-or">or</span></div>
    <div class="sso-logins">
      <div class="google-sso-sign-in" (click)="loginWithGoogle()">
        <div class="google-sso-sign-in__icon"></div>
        <span class="google-sso-sign-in__text">Sign in with Google</span>
      </div>
    </div>
  </section>
</div>
