<mat-table [dataSource]="items$ | async">
  <ng-container matColumnDef="label" class="label">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let item">
      <mat-icon class="label-icon">{{ item.icon }}</mat-icon>
      <div class="label-contents">{{ item.attribute | translate }}</div>
      <div class="label-contents">{{ formatNumber(item.inaccurateCount) }}</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="example" class="mat-column-example">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let item" class="example">
      <div class="example-text">
        <div *ngIf="item.exampleData">
          {{ item.exampleData }}
        </div>
        <a target="_blank" [href]="item.exampleListingUrl">
          {{ item.sourceName }}
        </a>
      </div>
      <mat-checkbox
        *ngIf="edit$ | async"
        [checked]="!item.control.value"
        (change)="item.control.setValue(!$event.checked)"
      ></mat-checkbox>
    </mat-cell>
  </ng-container>

  <mat-row *matRowDef="let row; columns: displayColumns"></mat-row>
</mat-table>
