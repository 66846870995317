import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SEOService } from './seo.service';
import { SectionServiceInterfaceToken } from '../section/section.service';
import { BaseSectionComponent } from '../section/base-section.component';
import { analyticsCategory } from '../section-footer/section-footer.component';
import { SnapshotService } from '../snapshot.service';
import { AbstractControl } from '@angular/forms';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'snap-seo-section',
  templateUrl: './seo-section.component.html',
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: SEOService,
    },
  ],
})
export class SEOSectionComponent extends BaseSectionComponent implements OnInit {
  @Output() customKeywordControl: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();

  footerCategory = analyticsCategory.SEO;

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: SEOService,
    public snapshotService: SnapshotService,
    public snackbarService: SnackbarService,
    public translateService: TranslateService,
  ) {
    super(service, snackbarService, translateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.customKeywordControl.emit(this.controls.controls['customKeyword']);
  }
}
