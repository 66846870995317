import { Component, Input, OnInit } from '@angular/core';
import { PopoverPositions } from '@vendasta/galaxy/popover';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { googleInconclusiveStartDate } from '../../constants';
import { SocialItemData } from './social-item-data';

@Component({
  selector: 'snap-social-item',
  templateUrl: './social-item.component.html',
  styleUrls: ['./social-item.component.scss'],
})
export class SocialItemComponent implements OnInit {
  TOOLTIP_RIGHT = [{ ...PopoverPositions.Right }];

  showingPopover = false;
  isInconclusive = false;

  constructor(private readonly analyticsService: ProductAnalyticsService) {}

  @Input() socialItem: SocialItemData;
  @Input() edit: boolean;
  @Input() createdAt: Date;

  ngOnInit(): void {
    this.isInconclusive = this.socialItem?.name === 'Google' && this.createdAt > googleInconclusiveStartDate;
  }

  showPopover(): void {
    this.showingPopover = true;
    this.analyticsService.trackEvent('listing-inconclusive-more', 'snapshot', 'hover');
  }

  hidePopover(): void {
    this.showingPopover = false;
  }
}
