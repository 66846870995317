import { NgModule } from '@angular/core';
import { CityStatePipe } from './city-state.pipe';
import { FilterWebVitalsPipe } from './web-vitals.pipe';
import { HttpCondensePipe } from './http-condense.pipe';
import { HttpStripPipe } from './http-strip.pipe';
import { MetricNotationPipe } from './metric-notation.pipe';
import { PhonePipe } from './phone.pipe';
import { SafePipe } from './safe.pipe';
import { SslPipe } from './ssl.pipe';

const pipes = [
  CityStatePipe,
  FilterWebVitalsPipe,
  HttpCondensePipe,
  HttpStripPipe,
  MetricNotationPipe,
  PhonePipe,
  SafePipe,
  SslPipe,
];

@NgModule({
  declarations: pipes,
  imports: [],
  exports: pipes,
})
export class PipesModule {}
