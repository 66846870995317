<mat-toolbar class="toolbar" color="primary">
  <mat-toolbar-row class="primary-row" data-testid="toolbar-primary-row">
    <div class="start">
      <button mat-icon-button data-testid="toggle-sidebar" (click)="handleToggleSidenavClick()">
        <mat-icon>menu</mat-icon>
      </button>
      <h1 data-testid="snapshot-name">
        {{ reportName | translate }}
      </h1>
    </div>

    <div class="end">
      <button
        mat-flat-button
        class="create-proposal"
        *ngIf="allowCreateProposal"
        (click)="handleCreateProposalClick()"
        data-testid="create-proposal-button"
        data-action="create-proposal-from-snapshot"
      >
        {{ 'EDIT_REPORT.PROPOSAL_BUTTON' | translate }}
      </button>
      <app-schedule-meeting-button class="hide-mobile"></app-schedule-meeting-button>
      <app-share-report-button
        data-testid="share-report-button"
        [edit]="edit"
        [matTooltip]="edit ? ('COMMON.SEND' | translate) : ('COMMON.COPY_LINK' | translate)"
        (shareSnapshotDialog)="handleShareReportClick()"
      ></app-share-report-button>

      <snap-icon-button
        *ngIf="printReportFeature$ | async"
        icon="print"
        [matTooltip]="'COMMON.PRINT' | translate"
        (clicked)="handlePrintClick()"
      ></snap-icon-button>
      <snap-icon-button
        icon="file_download"
        [loading]="generatingPdf$ | async"
        [matTooltip]="'COMMON.DOWNLOAD' | translate"
        (clicked)="handleDownloadClick()"
      ></snap-icon-button>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row class="secondary-row" data-testid="toolbar-secondary-row">
    <div class="start">
      <img *ngIf="logoUrl" [src]="logoUrl" />
    </div>

    <div class="end">
      <button mat-flat-button color="primary" (click)="handleContactClick()" data-testid="contact-button">
        {{ ctaLabel || (defaultCtaLabel | translate) }}
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
