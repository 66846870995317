import { Injectable } from '@angular/core';
import { WhitelabelService, ConfigurationInterface, BrandingInterface } from '@galaxy/partner';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface PartnerConfigurationInterface {
  adintel_default_enable_flag: boolean;
  ms_always_enabled: boolean;
  rm_product_name: string;
  ms_product_name: string;
  sm_product_name: string;
  rm_competition_max_services: number;
  rm_competition_max_competitors: number;
}

enum ProductAbbreviations {
  ReputationManagement = 'RM',
  SocialMarketing = 'SM',
  ListingBuilder = 'MS',
}

@Injectable()
export class ConfigurationService {
  constructor(private readonly whitelabelService: WhitelabelService) {}

  private toConfigurations(
    branding: BrandingInterface,
    configuration: ConfigurationInterface,
  ): PartnerConfigurationInterface {
    return {
      rm_competition_max_competitors: configuration.reputationConfiguration.maxCompetitors,
      rm_competition_max_services: configuration.reputationConfiguration.maxServices,
      rm_product_name: branding.apps[ProductAbbreviations.ReputationManagement].name,
      sm_product_name: branding.apps[ProductAbbreviations.SocialMarketing].name,
      ms_product_name: branding.apps[ProductAbbreviations.ListingBuilder].name,
      ms_always_enabled: configuration.msAlwaysEnabled,
      adintel_default_enable_flag: configuration.adintelIsEnabled,
    };
  }

  getConfigurations(partnerId: string, keys: string[], marketId?: string): Observable<PartnerConfigurationInterface> {
    const branding$ = this.whitelabelService.getBranding(partnerId, marketId).pipe();
    const configuration$ = this.whitelabelService.getConfiguration(partnerId, marketId);
    return combineLatest([branding$, configuration$]).pipe(
      map(([branding, configuration]) => this.toConfigurations(branding, configuration)),
    );
  }
}
