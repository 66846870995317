import { Component, Input, OnInit } from '@angular/core';
import { ListingAccuracyData } from './accuracy-table-item';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'snap-accuracy-table',
  templateUrl: './accuracy-table.component.html',
  styleUrls: ['./accuracy-table.component.scss'],
})
export class AccuracyTableComponent implements OnInit {
  @Input() set items(val: ListingAccuracyData[]) {
    this.items$$.next(this.filterNullItems(val));
  }
  private items$$: ReplaySubject<ListingAccuracyData[]> = new ReplaySubject<ListingAccuracyData[]>(1);
  protected items$: Observable<ListingAccuracyData[]> = new Observable<ListingAccuracyData[]>();
  protected displayColumns: string[] = ['label', 'example'];

  @Input() set edit(val: boolean) {
    this.edit$$.next(val);
  }
  private edit$$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  protected edit$: Observable<boolean> = this.edit$$.asObservable();

  private filterNullItems(value?: ListingAccuracyData[]): ListingAccuracyData[] {
    return value?.filter((item) => item?.inaccurateCount > 0);
  }

  private filterForView(value?: ListingAccuracyData[]): ListingAccuracyData[] {
    return value?.filter((item) => !item.control?.value || true);
  }

  // formats the number to at most 2 decimal places
  formatNumber(n: number): number {
    return Math.round(n * 100) / 100;
  }

  ngOnInit(): void {
    this.items$ = combineLatest([this.items$$.asObservable(), this.edit$]).pipe(
      map(([items, edit]) => {
        if (edit) {
          return items;
        } else {
          return this.filterForView(items);
        }
      }),
    );
  }
}
