import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FormOptions, FormDefinition, Section, FormDefinitionHttpResponse } from './vform.models';
import { FieldBase, FieldData } from './fields/field-base';
import { ImageUploadField } from './fields/image-upload-field';
import { MediaUploadResult, FileUploadInfo } from '@vendasta/forms';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';

@Injectable()
export class VFormTranslationService {
  constructor(private http: HttpClient, private readonly alertService: SnackbarService) {}

  public requestFormData(formOptions: FormOptions, withCredentials: boolean): Observable<FormDefinition> {
    const url =
      formOptions.formUrl +
      '?__vformcmd__=fields&__vformctx__=' +
      encodeURIComponent(
        typeof formOptions.context === 'object' ? JSON.stringify(formOptions.context) : formOptions.context,
      );
    return this.http.post(url, {}, { withCredentials: withCredentials }).pipe(
      map((response: { data }) => response.data),
      map((response: FormDefinitionHttpResponse) => this.setFormData(response)),
    );
  }

  public setFormData(response: FormDefinitionHttpResponse): FormDefinition {
    if (!response) {
      return;
    }

    const rawSections = response.sections || [];
    const sections = rawSections.map((section) => new Section(section));

    // Convert formdata list to dict
    const fieldData: { [key: string]: FieldData } = {};
    response.formdata.forEach((fieldDataItem) => {
      const name: string = fieldDataItem.name;
      delete fieldDataItem.name;
      fieldData[name] = fieldDataItem;
    });

    // Create the fields from the response
    let field: FieldBase<any>;
    const fields = response.fields.map((fieldItem) => {
      // Set the field value if there is one
      if (fieldItem.name in fieldData) {
        Object.assign(fieldItem, fieldData[fieldItem.name]);
      }

      field = fieldItem.control !== 'image' ? new FieldBase<any>() : new ImageUploadField();
      field.setFieldData(fieldItem);

      return field;
    });

    sections.forEach((section: Section) => {
      section.fields = fields.filter((f: FieldBase<any>) => {
        return f.section === section.name;
      });
    });

    return {
      sections: sections,
      loading: false,
      catalogue: response.catalogue,
    };
  }

  public formAlteringElementChanged(
    formOptions: FormOptions,
    field: FieldBase<any>,
    encodedFormData: any,
  ): Observable<FieldData[]> {
    // This is called when the value changes on an element that is marked as having form-altering effects
    const url =
      formOptions.formUrl +
      '?__vformcmd__=options&__vformalt__=' +
      field.name +
      '&__vformctx__=' +
      encodeURIComponent(JSON.stringify(formOptions.context));
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http
      .post<{ data: FormDefinitionHttpResponse }>(url, encodedFormData, { headers: header })
      .pipe(map((response) => response.data.formdata || []));
  }

  submitForm(submitUrl: string, formData: string, context: any, withCredentials: boolean): Observable<any> {
    // Context should already be serialized by this point
    submitUrl += '?__vformcmd__=submit&__vformctx__=' + context;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    });
    return this.http.post(submitUrl, formData, { headers: headers, withCredentials: withCredentials });
  }

  fileUploader(fieldData: any, uploadUrl: string, file: File): Observable<MediaUploadResult> {
    const formData = new FormData();
    for (const key in fieldData) {
      if (fieldData.hasOwnProperty(key)) {
        formData.append(key, fieldData[key]);
      }
    }
    formData.append('files[]', file);
    return this.http.post(uploadUrl, formData).pipe(
      map((response: any) => {
        if (response.data[0].error === 'Upload failed. This file exceeds the maximum file size.') {
          this.alertService.openErrorSnack(
            `Upload failed. This file exceeds the maximum file size of ${fieldData.maxFileSize / 1000000}MB`,
          );
        }
        return {
          ...response.data[0],
          url: response.data[0].fileurl,
          name: response.data[0].filename,
        };
      }),
    ) as Observable<FileUploadInfo>;
  }
}
