import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';

export interface ConfirmationModalConfig {
  type?: 'confirm' | 'warn';
  title: string;
  message?: string;
  confirmButtonText?: string;
  hideCancel?: boolean;
  cancelButtonText?: string;
  actionOnEnterKey?: boolean;
  cancelOnEscapeKeyOrBackgroundClick?: boolean;
  width?: number;
  showCheckbox?: boolean; // for future updates
  checkboxText?: string; // for future updates
}

export const ConfirmationModalWidth = '540px';
export const ConfirmationModalMaxWidth = 'calc( 100vw - 8px )';

@Injectable({
  providedIn: 'root',
})
export class OpenConfirmationModalService {
  // in order to use this service, you MUST import the MatDialogModule in your base app.module
  // otherwise you will get an error like this:
  //
  //    ERROR Error: Uncaught (in promise): NullInjectorError:
  //    R3InjectorError(ConfirmationModalModule)[OpenConfirmationModalService -> OpenConfirmationModalService
  //        -> MatDialog -> MatDialog]: NullInjectorError: No provider for MatDialog!
  //

  private readonly dialog = inject(MatDialog);

  openModal(config: ConfirmationModalConfig): Observable<boolean> {
    return this.dialog
      .open(ConfirmationModalComponent, {
        data: config,
        width: config.width ? config.width + 'px' : ConfirmationModalWidth,
        maxWidth: ConfirmationModalMaxWidth,
        autoFocus: false,
        disableClose: config.cancelOnEscapeKeyOrBackgroundClick === false,
      })
      .afterClosed();
    // this observable autocloses after the first value is emitted
    // so no need to unsubscribe or use take(1)
  }
}
