<ng-container *ngIf="tableConfig$ | async as table; else loading">
  <va-mat-table>
    <table mat-table [dataSource]="table.data">
      <!--Static columns-->
      <ng-container matColumnDef="rowLabel">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div class="row-labels">
            {{ row.label | translate }}
            <mat-icon *ngIf="row.tooltip" class="hide-to-print" [glxyTooltip]="row.tooltip | translate">
              help_outline
            </mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="yourBusiness">
        <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.YOUR_BUSINESS' | translate }}</th>
        <td mat-cell *matCellDef="let row" [ngClass]="table.colorTextYourBusiness ? getGradeClass(row.grade) : ''">
          <ng-container
            [ngTemplateOutlet]="dataCell"
            [ngTemplateOutletContext]="{ data: row.yourBusiness, format: row.format }"
          ></ng-container>
          <div *ngFor="let ex of row.examples" class="examples">
            {{ ex.name }}
            <span *ngIf="ex.value">({{ ex.value }})</span>
          </div>
        </td>
      </ng-container>

      <ng-container *ngIf="table.showIndustryAverage" matColumnDef="industryAverage">
        <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.INDUSTRY_AVERAGE' | translate }}</th>
        <td mat-cell *matCellDef="let row">
          <ng-container
            [ngTemplateOutlet]="dataCell"
            [ngTemplateOutletContext]="{ data: row.industryAverage, format: row.format }"
          ></ng-container>
        </td>
      </ng-container>

      <ng-container *ngIf="table.showIndustryLeader" matColumnDef="industryLeader">
        <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.INDUSTRY_LEADER' | translate }}</th>
        <td mat-cell *matCellDef="let row">
          <ng-container
            [ngTemplateOutlet]="dataCell"
            [ngTemplateOutletContext]="{ data: row.industryLeader, format: row.format }"
          ></ng-container>
        </td>
      </ng-container>

      <ng-container *ngIf="table.editMode" matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <ng-container>
            <mat-checkbox
              [checked]="!row?.control?.value"
              (change)="row.control.setValue(!row.control.value)"
            ></mat-checkbox>
          </ng-container>
        </td>
      </ng-container>

      <ng-container *ngIf="!table.hideGrades" matColumnDef="grade">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <snap-grade [grade]="row.grade" size="extra-small"></snap-grade>
        </td>
      </ng-container>

      <!--competitors-->
      <ng-container [matColumnDef]="column.id" *ngFor="let column of table.competitorColumns">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="linkCompetitors$ | async; else noCompetitorLink">
            <a
              href="http://{{ column.name | httpStrip }}"
              target="_blank"
              [trackEvent]="{
                eventName: 'business-website-advertising',
                category: 'external-link',
                action: 'click'
              }"
            >
              {{ column.name | httpCondense }}
            </a>
          </ng-container>
          <ng-template #noCompetitorLink>
            {{ column.name | httpCondense }}
          </ng-template>
        </th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngIf="!isUndefinedOrNull(row?.competitors?.[column.id]); else notFound">
            <span
              *ngIf="row.title === 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.SEO_VALUE_PER_CLICK'; else notValuePerClick"
            >
              {{ row?.competitors?.[column.id] | currency: 'USD' : 'symbol' : '1.2-2' }}
            </span>
            <ng-template
              #notValuePerClick
              [ngTemplateOutlet]="dataCell"
              [ngTemplateOutletContext]="{ data: row.competitors[column.id], format: row.format }"
            ></ng-template>
          </ng-container>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="table.columnNames"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: table.columnNames"
        [hidden]="!table.editMode && row?.control?.value"
      ></tr>
    </table>
  </va-mat-table>
</ng-container>

<ng-template #dataCell let-format="format" let-data="data">
  <ng-container *ngIf="!isUndefinedOrNull(data); else notFound">
    <ng-container [ngSwitch]="format">
      <div *ngSwitchCase="'check'">
        <mat-icon *ngIf="data; else isNot" class="green-icon">check</mat-icon>
        <ng-template #isNot>
          <mat-icon class="red-icon">close</mat-icon>
        </ng-template>
      </div>
      <div *ngSwitchCase="'currency'">
        {{ data | currency: 'USD' : 'symbol' : '1.0-2' }}
      </div>
      <div *ngSwitchCase="'number'">
        {{ data | metricNotation }}
      </div>
      <div *ngSwitchDefault>
        {{ data | metricNotation }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #notFound>
  <div class="cell not-found">{{ 'COMMON.NOT_FOUND' | translate }}</div>
</ng-template>

<ng-template #loading>
  <glxy-loading-spinner></glxy-loading-spinner>
</ng-template>
