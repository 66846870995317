export const FREE_SUBSCRIPTION_ID = 'free_subscription',
  // deprecated tiers (do not use)
  BASIC = 'BASIC',
  PROFESSIONAL = 'PROFESSIONAL',
  ENTERPRISE = 'ENTERPRISE',
  PREMIUM = 'PREMIUM',
  ELITE = 'ELITE',
  PROFESSIONAL_PLUS = 'PROFESSIONAL_PLUS',
  ENTERPRISE_PLUS = 'ENTERPRISE_PLUS',
  // legacy tiers
  LITE_SUBSCRIPTION_ID = 'lite_subscription',
  BASIC_SUBSCRIPTION_ID = 'basic_subscription',
  PROFESSIONAL_SUBSCRIPTION_ID = 'professional_subscription',
  ENTERPRISE_SUBSCRIPTION_ID = 'enterprise_subscription',
  PREMIUM_SUBSCRIPTION_ID = 'premium_subscription',
  ELITE_SUBSCRIPTION_ID = 'elite_subscription',
  // Value based pricing tiers
  VBP_STARTER_SUBSCRIPTION_ID = 'starter_subscription',
  VBP_BASIC_SUBSCRIPTION_ID = 'vbp_basic_subscription',
  VBP_PROFESSIONAL = 'VBP_PROFESSIONAL',
  VBP_PROFESSIONAL_SUBSCRIPTION_ID = 'vbp_professional_subscription',
  VBP_ENTERPRISE_SUBSCRIPTION_ID = 'vbp_enterprise_subscription',
  VBP_ENTERPRISE_PILOT_SUBSCRIPTION_ID = 'vbp_enterprise_pilot_subscription',
  // Value based pricing 2.0 tiers
  VBP2_STARTUP_SUBSCRIPTION_ID = 'vbp2_startup_subscription',
  VBP2_ESSENTIALS_SUBSCRIPTION_ID = 'vbp2_essentials_subscription',
  VBP2_GROWTH_SUBSCRIPTION_ID = 'vbp2_growth_subscription',
  VBP2_SCALE_SUBSCRIPTION_ID = 'vbp2_scale_subscription',
  VBP2_PREMIUM_SUBSCRIPTION_ID = 'vbp2_premium_subscription',
  // Monetization tiers
  INDIVIDUAL_SUBSCRIPTION_ID = 'm3_solo_subscription',
  TEAM_SUBSCRIPTION_ID = 'm3_small_subscription',
  ADVANCE_SUBSCRIPTION_ID = 'm3_medium_subscription',
  CUSTOM_SUBSCRIPTION_ID = 'm3_large_subscription',
  // Current pricing tiers
  VBP2023_STARTER_SUBSCRIPTION_ID = 'vbp_2023_starter_subscription',
  VBP2022_PROFESSIONAL_SUBSCRIPTION_ID = 'vbp_2022_professional_subscription',
  VBP2022_PREMIUM_SUBSCRIPTION_ID = 'vbp_2022_premium_subscription',
  VBP2022_CUSTOM_SUBSCRIPTION_ID = 'vbp_2022_custom_subscription',
  // 2024 pricing tiers
  VBP2024_STARTER_SUBSCRIPTION_ID = 'vbp_2024_starter_subscription',
  VBP2024_PROFESSIONAL_SUBSCRIPTION_ID = 'vbp_2024_professional_subscription',
  VBP2024_PREMIUM_SUBSCRIPTION_ID = 'vbp_2024_premium_subscription';

export const VBP2022_VERSION = 'VBP2022';
export const VBP2023_VERSION = 'VBP2023';
export const VBP2024_VERSION = 'VBP2024';
export const M3_TIER_VERSION = 'M3';

export const STANDARD_SUPPORT_TIERS = [
  BASIC,
  BASIC_SUBSCRIPTION_ID,
  VBP_BASIC_SUBSCRIPTION_ID,
  VBP2_ESSENTIALS_SUBSCRIPTION_ID,
  PROFESSIONAL,
  PROFESSIONAL_PLUS,
  PROFESSIONAL_SUBSCRIPTION_ID,
  VBP_PROFESSIONAL_SUBSCRIPTION_ID,
  VBP2_GROWTH_SUBSCRIPTION_ID,
  VBP2_SCALE_SUBSCRIPTION_ID,
  TEAM_SUBSCRIPTION_ID,
  ADVANCE_SUBSCRIPTION_ID,
  VBP2022_PROFESSIONAL_SUBSCRIPTION_ID,
  VBP2022_PREMIUM_SUBSCRIPTION_ID,
  VBP2024_PROFESSIONAL_SUBSCRIPTION_ID,
  VBP2024_PREMIUM_SUBSCRIPTION_ID,
  VBP_PROFESSIONAL,
];

export const PREMIUM_SUPPORT_SUBSCRIPTION_TIERS = [
  ENTERPRISE,
  PREMIUM,
  ELITE,
  ENTERPRISE_PLUS,
  ENTERPRISE_SUBSCRIPTION_ID,
  PREMIUM_SUBSCRIPTION_ID,
  ELITE_SUBSCRIPTION_ID,
  VBP_ENTERPRISE_SUBSCRIPTION_ID,
  VBP2_PREMIUM_SUBSCRIPTION_ID,
  CUSTOM_SUBSCRIPTION_ID,
  VBP2022_CUSTOM_SUBSCRIPTION_ID,
];

export const CHAT_SUPPORT_TIERS = [
  INDIVIDUAL_SUBSCRIPTION_ID,
  ...STANDARD_SUPPORT_TIERS,
  ...PREMIUM_SUPPORT_SUBSCRIPTION_TIERS,
];
