import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PartnerServiceInterface } from '@galaxy/partner';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

const getPartnerIdUrl = '/ajax/v1/get-partner-id';

interface VAPIResponse<T> {
  data: T;
}

interface Partner {
  partner_id: string;
}

@Injectable()
export class PartnerService implements PartnerServiceInterface {
  constructor(private http: HttpClient) {}

  getPartnerId(): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const requestOptions = {
      headers: headers,
    };
    return this.http
      .get<VAPIResponse<Partner>>(getPartnerIdUrl, requestOptions)
      .pipe(map((resp) => resp.data.partner_id));
  }
}
