import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule, SessionService } from '@galaxy/core';
import { LexiconModule } from '@galaxy/lexicon';
import {
  createProposalFromSnapshotFactory,
  CREATE_PROPOSAL_FROM_SNAPSHOT_TOKEN,
  ROUTE_GENERATOR_TOKEN,
  SectionsModule,
  translationsAppInitializerFactory,
  IconButtonComponent,
  GradeComponent,
  PipesModule,
  SnapshotFeatureFlagsToken,
  SalesPersonToken,
  MediaToken,
  Media,
  ToggledItemModule,
  MarketplaceServiceToken,
} from '@galaxy/snapshot';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  GooglePlacesService as GoogleMapsService,
  IncludedProductsService,
} from '@vendasta/businesses/easy-account-create';
import { GalaxyAlertModule } from '@vendasta/galaxy/alert';
import { GalaxyEmptyStateModule } from '@vendasta/galaxy/empty-state';
import { GalaxyI18NModule } from '@vendasta/galaxy/i18n';
import { GalaxyInputModule } from '@vendasta/galaxy/input';
import { GalaxyPopoverModule } from '@vendasta/galaxy/popover';
import { GalaxySnackbarModule, SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { GalaxyTooltipModule } from '@vendasta/galaxy/tooltip';
import { ProductAnalyticsModule } from '@vendasta/product-analytics';
import { TaxonomyI18nModule } from '@vendasta/taxonomy-i18n';
import { ConfirmationService, UIKitModule, VaMaterialTableModule } from '@vendasta/uikit';
import { VFormModule } from '@vendasta/vform';
import { NgChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxWebstorageModule } from 'ngx-webstorage';
import English from '../assets/i18n/en_devel.json';
import { AppComponent } from './app.component';
import { AppRoutingModule, RoutingService } from './app.routes';
import { HasSessionAuthGuardService } from './auth-guard/auth-guard.service';
import { CrankwheelWidgetService } from './crankwheel/crankwheel-widget.service';
import { LoginModule } from './login';
import { OAuth2Module, OAuth2Service, OAuth2ServiceConfigToken } from '@vendasta/oauth2';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthService, oauth2ConfigFactory, salestoolSession } from './auth/auth.service';
import { LogoutModule } from './logout';
import { TargetBlankDirective } from './target-blank.directive';
import { SnapshotSessionRedirectComponent } from './session/session-redirect.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { NotFoundComponent } from './error/not-found.component';
import { ErrorComponent } from './error/error.component';
import { GalaxyDefaultProviderOverrides } from '@vendasta/galaxy/provider-default-overrides';
import { SnapshotReportComponent } from './report/snapshot-report.component';
import { SnapshotReportEditComponent } from './report/snapshot-report-edit.component';
import { GalaxyPipesModule } from '@vendasta/galaxy/pipes';
import { ShareReportButtonComponent } from './share-report/share-report-button.component';
import { ShareDialogComponent } from './share-report/dialog/dialog.component';
import { ShareReportService } from './share-report/share-report.service';
import { GalaxyLoadingSpinnerModule } from '@vendasta/galaxy/loading-spinner';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { GalaxyButtonGroupModule } from '@vendasta/galaxy/button-group';
import { ScreenshareDialogComponent } from './screenshare-dialog/screenshare-dialog.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { FeatureFlagService, MediaQueryService, SalesPersonService } from './services';
import { CoverPageComponent } from './report/cover-page/cover-page.component';
import { ScheduleMeetingComponent } from './report/schedule-meeting/schedule-meeting.component';
import { ScheduleMeetingButtonComponent } from './report/schedule-meeting/schedule-meeting-button/schedule-meeting-button.component';
import { ScheduleMeetingService } from './report/schedule-meeting/schedule-meeting.service';
import { MarketplaceService } from './services/marketplace.service';
import { EditButtonDialogComponent } from './report/edit-button-dialog/edit-button-dialog.component';
import { CustomizeReportComponent } from './report/customize-report/customize-report.component';
import { AskQuestionDialogComponent } from './report/ask-question-dialog/ask-question-dialog.component';
import { CallToActionComponent } from './report/call-to-action/call-to-action.component';
import { ToolbarComponent } from './report/toolbar/toolbar.component';
import { SidebarContactComponent } from './report/sidebar-contact/sidebar-contact.component';
import { SidebarMenuComponent } from './report/sidebar-menu/sidebar-menu.component';
import { CustomizePrimaryActionComponent } from './report/customize-primary-action/customize-primary-action.component';
import { EditButtonComponent } from './report/edit-button/edit-button.component';
import { GalaxyButtonLoadingIndicatorModule } from '@vendasta/galaxy/button-loading-indicator';
import { SkeletonToolbarComponent } from './report/skeleton-toolbar/skeleton-toolbar.component';

@NgModule({
  declarations: [
    AppComponent,
    TargetBlankDirective,
    SnapshotSessionRedirectComponent,
    NotFoundComponent,
    ErrorComponent,
    SnapshotReportComponent,
    SnapshotReportEditComponent,
    ShareReportButtonComponent,
    ShareDialogComponent,
    ScreenshareDialogComponent,
    LanguageSelectorComponent,
    CoverPageComponent,
    ScheduleMeetingComponent,
    ScheduleMeetingButtonComponent,
    EditButtonDialogComponent,
    CustomizeReportComponent,
    AskQuestionDialogComponent,
    CallToActionComponent,
    ToolbarComponent,
    SidebarContactComponent,
    SidebarMenuComponent,
    CustomizePrimaryActionComponent,
    EditButtonComponent,
    SkeletonToolbarComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    MatCardModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    FormsModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatChipsModule,
    AppRoutingModule,
    CoreModule,
    NgxWebstorageModule.forRoot(),
    LoginModule,
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,
    Ng2GoogleChartsModule,
    MatDialogModule,
    VFormModule,
    ProductAnalyticsModule,
    MatToolbarModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatAutocompleteModule,
    GalaxyI18NModule,
    GalaxyPopoverModule,
    TranslateModule,
    LexiconModule.forRoot({
      componentName: 'snapshot-report/snapshot-client',
      baseTranslation: English,
    }),
    UIKitModule,
    NgChartsModule,
    TaxonomyI18nModule,
    VaMaterialTableModule,
    GalaxyInputModule,
    GalaxyAlertModule,
    GalaxyEmptyStateModule,
    GalaxyInputModule,
    GalaxyTooltipModule,
    GalaxySnackbarModule,
    FormsModule,
    ReactiveFormsModule,
    SectionsModule,
    CommonModule,
    LogoutModule,
    OAuth2Module,
    OAuthModule.forRoot(),
    GalaxyPipesModule,
    IconButtonComponent,
    GradeComponent,
    GalaxyLoadingSpinnerModule,
    GalaxyFormFieldModule,
    GalaxyButtonGroupModule,
    PipesModule,
    ToggledItemModule,
    GalaxyButtonLoadingIndicatorModule,
  ],
  providers: [
    ...GalaxyDefaultProviderOverrides,
    HasSessionAuthGuardService,
    CrankwheelWidgetService,
    GoogleMapsService,
    IncludedProductsService,
    SnackbarService,
    ConfirmationService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslateService],
      useFactory: translationsAppInitializerFactory,
    },
    {
      provide: CREATE_PROPOSAL_FROM_SNAPSHOT_TOKEN,
      deps: [],
      useFactory: createProposalFromSnapshotFactory,
    },
    { provide: ROUTE_GENERATOR_TOKEN, useClass: RoutingService },
    ShareReportService,
    ScheduleMeetingService,
    // Use the AuthService service to handle sessions.
    // We are extending the OAuth2Service to handle both OAuth2 and salestool sessions
    AuthService,
    { provide: SessionService, useExisting: AuthService },
    { provide: OAuth2Service, useExisting: AuthService },
    { provide: OAuthStorage, useFactory: (): OAuthStorage => window.localStorage },
    { provide: OAuth2ServiceConfigToken, useFactory: oauth2ConfigFactory },
    // adds DI for SessionService to be used without OAuth2Service
    { provide: salestoolSession, useClass: SessionService },
    // intercepts the requests to check if the session is valid
    AuthInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: AuthInterceptor,
      multi: true,
    },
    // Active feature flags
    FeatureFlagService,
    {
      provide: SnapshotFeatureFlagsToken,
      useFactory: (service: FeatureFlagService) => service.activeFeatures$,
      deps: [FeatureFlagService],
    },
    // provides the Salesperson for the current snapshot
    SalesPersonService,
    {
      provide: SalesPersonToken,
      useFactory: (service: SalesPersonService) => service.salesPerson$,
      deps: [SalesPersonService],
    },
    // provide the media type for the snapshot layout
    {
      provide: MediaToken,
      useFactory: (service: MediaQueryService) =>
        ({
          mobile$: service.mobile$,
          print$: service.print$,
        }) as Media,
      deps: [MediaQueryService],
    },
    // provide the marketplace service
    {
      provide: MarketplaceServiceToken,
      useExisting: MarketplaceService,
      deps: [MarketplaceService],
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
