import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EcommerceSectionService } from '@vendasta/snapshot';

@Injectable()
export class SectionAvailabilityService {
  constructor(private ecommerceService: EcommerceSectionService) {}

  getSectionAvailability(snapshotId: string): Observable<boolean> {
    return this.ecommerceService.getSectionAvailability(snapshotId).pipe(map((isAvailable) => isAvailable));
  }
}
