import { Injectable } from '@angular/core';
import {
  AccountGroupApiService,
  ApiLookupFilter,
  ApiTrialFilter,
  LookupFilter,
  PagedResponse,
  ProjectionFilter,
  SnapshotFilter,
  SortDirection,
  SortField,
} from '@galaxy/account-group';
import { BehaviorSubject, ReplaySubject, combineLatest, of as observableOf } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { SnapshotService } from '../../snapshot.service';
import { BusinessDetails } from './direct-competitors';

const pageSize = 25;

@Injectable()
export class DirectCompetitorsService {
  private businesses$$: ReplaySubject<BusinessDetails[]> = new ReplaySubject<BusinessDetails[]>();
  private searchTerm$$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private projectionFilters: ProjectionFilter;
  private filters: LookupFilter;
  private apiTrialFilter: ApiTrialFilter;
  private snapshotFilter: SnapshotFilter;

  readonly businesses$ = this.businesses$$.asObservable();
  readonly searchTerm$ = this.searchTerm$$.asObservable();

  public nextBatch$$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  readonly nextBatch$ = this.nextBatch$$.asObservable();

  public cursor = '';
  public isSearchingBusiness$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private accountGroupApiService: AccountGroupApiService, private snapshotService: SnapshotService) {
    // setup filters with initial values
    this.projectionFilters = new ProjectionFilter({
      napData: true,
      snapshotReports: true,
    });
    this.filters = new LookupFilter();
    this.apiTrialFilter = new ApiTrialFilter(false);
    this.snapshotFilter = new SnapshotFilter(true, true, false);

    this.lookupBusinesses();
  }

  public setSearchTerm(term: string): void {
    this.searchTerm$$.next(term);
  }

  private lookupBusinesses(): void {
    combineLatest([this.searchTerm$.pipe(distinctUntilChanged()), this.snapshotService.snapshotData$, this.nextBatch$])
      .pipe(
        debounceTime(250),
        switchMap(([searchTerm, data, cursor]) => {
          const taxonomyIds = data.taxonomyId.split(':', 1); // get the top level taxonomy only
          const apiFilters = new ApiLookupFilter(
            data.partnerId,
            [data.marketId],
            this.filters.salesPersonId,
            this.filters.tags,
            this.filters.accountFilters,
            this.filters.listingDistributionFilter,
            this.filters.listingSyncProFilter,
            this.filters.createdDateFilter,
            this.apiTrialFilter,
            taxonomyIds,
            this.filters.presenceFilter,
            this.filters.locationFilter,
            this.snapshotFilter,
            this.filters.statusFilter,
            this.filters.accountGroupIds,
          );

          if (searchTerm !== '') {
            cursor = '';
            this.isSearchingBusiness$$.next(true);
          } else {
            this.isSearchingBusiness$$.next(false);
          }
          return this.accountGroupApiService
            .lookup(this.projectionFilters, apiFilters, cursor, pageSize, searchTerm, {
              field: SortField.CompanyName,
              direction: SortDirection.Ascending,
            })
            .pipe(
              map((res: PagedResponse) => {
                return res.accountGroups
                  .filter((ag) => ag.accountGroupId !== this.snapshotService.businessId)
                  .map((ag) => {
                    const address = ag?.napData?.address + ', ' + ag?.napData.city + ', ' + ag?.napData?.state;
                    const ss = ag.snapshotReports.pop();
                    this.cursor = res.nextCursor;
                    return <BusinessDetails>{
                      name: ag.napData.companyName,
                      snapshotId: ss ? ss.snapshotId : '',
                      snapshotCreated: ss ? ss.created : '',
                      address: address,
                    };
                  });
              }),
              catchError(() => observableOf([])),
            );
        }),
      )
      .subscribe((res) => {
        this.businesses$$.next(res);
      });
  }

  public setNextBatch(): void {
    if (this.cursor) {
      this.nextBatch$$.next(this.cursor);
    }
  }
}
