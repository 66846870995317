import { Injectable } from '@angular/core';
import { SnapshotReportService } from '@galaxy/snapshot';
import { retryer } from '@vendasta/rx-utils';
import { SalesPerson, SnapshotService } from '@vendasta/snapshot';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, shareReplay, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class SalesPersonService {
  salesPerson$: Observable<SalesPerson | undefined>;

  constructor(
    private readonly snapshotService: SnapshotService,
    private readonly snapshotReportService: SnapshotReportService,
  ) {
    this.setup();
  }

  private setup(): void {
    const retryConfig = {
      maxAttempts: 5,
      retryDelay: 100,
      timeoutMilliseconds: 10000,
    };
    this.salesPerson$ = this.snapshotReportService.snapshotId$.pipe(
      distinctUntilChanged(),
      switchMap((snapshotId) => this.snapshotService.getSalesPerson(snapshotId)),
      retryer(retryConfig),
      catchError(() => of(undefined)),
      tap((salesPerson) => this.assignDefaults(salesPerson)),
      shareReplay(1),
    );
  }

  private assignDefaults(salesPerson?: SalesPerson): void {
    if (!salesPerson) {
      return;
    }
    salesPerson.salesPersonId = salesPerson.salesPersonId || '';
    salesPerson.firstName = salesPerson.firstName || '';
    salesPerson.lastName = salesPerson.lastName || '';
    salesPerson.email = salesPerson.email || '';
    salesPerson.phoneNumbers = salesPerson.phoneNumbers || [];
    salesPerson.photoUrl = salesPerson.photoUrl || '';
    salesPerson.jobTitle = salesPerson.jobTitle || '';
    salesPerson.coverPageTitle = salesPerson.coverPageTitle || '';
    salesPerson.country = salesPerson.country || 'US';
  }
}
