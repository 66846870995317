<mat-accordion displayMode="flat">
  <mat-expansion-panel
    class="flat"
    [disabled]="media.print$ | async"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header
      [trackEvent]="{
        eventName: panelOpenState ? 'collapse-audits-' + reportType : 'expand-audits-' + reportType,
        category: 'anchor',
        action: 'click'
      }"
    >
      <mat-panel-title>
        <span *ngIf="media.mobile$ | async">{{ reportType | titlecase }}</span>
        <span *ngIf="(media.mobile$ | async) === false">
          {{ 'WEBSITE.VIEW_FULL_REPORT_TYPE' | translate : { reportType: reportType } }}
        </span>
      </mat-panel-title>
      <mat-panel-description class="end">
        <snap-status-summary *ngIf="statusSummary" [statusSummary]="statusSummary"></snap-status-summary>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div *ngFor="let audit of audits" class="details-container">
      <snap-audit-report-details [audit]="audit"></snap-audit-report-details>
    </div>
  </mat-expansion-panel>
</mat-accordion>
