<ng-container>
  <mat-card appearance="outlined" class="snapshot-banner">
    <div *ngIf="customHtml; else defaultCta" [innerHtml]="customHtml | iTrustThisHtml"></div>
  </mat-card>
</ng-container>

<ng-template #defaultCta>
  <div class="flex call-to-action">
    <div>
      <img src="https://vstatic-prod.apigateway.co/snapshot-client.9223372035128602063.prod/assets/images/cta-computer.png" />
    </div>
    <div class="flex-column inner-container">
      <div class="header">{{ 'CTA.TITLE' | translate }}</div>
      <div class="default-message" [innerHTML]="'CTA.DEFAULT_MESSAGE' | translate"></div>
      <div class="flex align-start-center cta-container">
        <a
          class="cta-button snapshot-cta-button"
          [trackEvent]="{
            eventName: 'get-started',
            category: 'cta',
            action: 'click'
          }"
        >
          {{ 'CTA.GET_STARTED' | translate }}
        </a>
        <span class="snapshot-salesperson-phone-container">
          <span>{{ 'CTA.CALL' | translate }}</span>
          <a
            class="snapshot-salesperson-phone action-phone-number"
            [trackEvent]="{
              eventName: 'call-sales-banner',
              category: 'external-link',
              action: 'click'
            }"
          ></a>
        </span>
      </div>
    </div>
  </div>
</ng-template>
