import { take, filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  titleId: string;

  constructor(private route: ActivatedRoute) {
    this.titleId = 'ERRORS.ERROR_LOADING_PAGE';
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        filter((params) => params.error),
        take(1),
      )
      .subscribe((params) => {
        this.titleId = params.error;
      });
  }
}
