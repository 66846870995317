/// <reference types="@types/google.maps" />

export class GooglePlaceInterface {
  name?: string;
  url?: string;
  formattedPhoneNumber?: string;
  internationalPhoneNumber?: string;
  longAddress?: string;
  shortAddress?: string;
  longCity?: string;
  shortCity?: string;
  longState?: string;
  shortState?: string;
  longZip?: string;
  shortZip?: string;
}

export class GooglePlace {
  name: string;
  url: string;
  formattedPhoneNumber: string;
  internationalPhoneNumber: string;
  longAddress: string;
  shortAddress: string;
  longCity: string;
  shortCity: string;
  longState: string;
  shortState: string;
  longZip: string;
  shortZip: string;

  constructor(place: GooglePlaceInterface) {
    this.name = place.name || '';
    this.url = place.url || '';
    this.formattedPhoneNumber = place.formattedPhoneNumber || '';
    this.internationalPhoneNumber = place.internationalPhoneNumber || '';
    this.longAddress = place.longAddress || '';
    this.shortAddress = place.shortAddress || '';
    this.longCity = place.longCity || '';
    this.shortCity = place.shortCity || '';
    this.longState = place.longState || '';
    this.shortState = place.shortState || '';
    this.longZip = place.longZip || '';
    this.shortZip = place.shortZip || '';
  }

  public static convertGeocodeAndPlaceToGooglePlace(
    geocode: google.maps.GeocoderResult,
    place: google.maps.places.PlaceResult,
  ): GooglePlace {
    const getAddressComponentByType = function (type: string): google.maps.GeocoderAddressComponent | undefined {
      return geocode.address_components.find((c) => c.types.includes(type));
    };

    // TODO: Implement more complicated address building from easy-account-create or libpostal?
    const streetNumber = getAddressComponentByType('street_number');
    const route = getAddressComponentByType('route');
    const premise = getAddressComponentByType('premise');
    const subpremise = getAddressComponentByType('subpremise');
    const addressParts = [streetNumber, route, premise, subpremise].filter((x) => x !== undefined);

    const longAddress = addressParts.map((x) => (x ? x.long_name : '')).join(' ');
    const shortAddress = addressParts.map((x) => (x ? x.short_name : '')).join(' ');

    const city = getAddressComponentByType('locality');
    const state = getAddressComponentByType('administrative_area_level_1');
    const zip = getAddressComponentByType('postal_code');

    return new GooglePlace({
      name: place.name,
      url: place.url,
      formattedPhoneNumber: place.formatted_phone_number,
      internationalPhoneNumber: place.international_phone_number,
      longAddress: longAddress,
      shortAddress: shortAddress,
      shortCity: city ? city.short_name : '',
      longCity: city ? city.long_name : '',
      shortState: state ? state.short_name : '',
      longState: state ? state.long_name : '',
      shortZip: zip ? zip.short_name : '',
      longZip: zip ? zip.short_name : '',
    });
  }
}
