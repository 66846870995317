<h2 mat-dialog-title>{{ 'EDIT_REPORT.EDIT_PRIMARY_BUTTON' | translate }}</h2>
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <app-edit-button
      [(label)]="label"
      [(targetUrl)]="targetUrl"
      [(targetProductId)]="targetProductId"
      (validationMessageChange)="validationMessage = $event"
    ></app-edit-button>
    @if (validationMessage) {
      <mat-error>
        {{ validationMessage | translate }}
        <mat-icon class="error-icon">error</mat-icon>
      </mat-error>
    }
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button color="primary" type="button" (click)="cancel()">
      {{ 'COMMON.CANCEL' | translate }}
    </button>
    <button mat-flat-button color="primary" type="submit" [disabled]="!!validationMessage">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</form>
