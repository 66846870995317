<snap-subsection
  *ngIf="snapshotEnabledListings$ | async as enabledListings"
  [statusSummary]="statusSummary$ | async"
  heading="{{ 'LISTINGS.DETAILS.HEADING' | translate }}"
  [hideControl]="controls.controls['hideListingDetails']"
  [edit]="edit"
>
  @let isPartnerConfig = snapshotService.isPartnerConfig$ | async;
  @let isExpired = snapshotService.isExpired$ | async;
  @let isLessThan12HoursOld = snapshotService.isLessThan12HoursOld$ | async;

  <div *ngIf="edit && isExpired && !isPartnerConfig" class="expired-alert">
    <glxy-alert type="error">
      {{ 'LISTINGS.DETAILS.SNAPSHOT_EXPIRED' | translate: { snapshotName: snapshotName$ | async | translate } }}
      <a (click)="openRefreshDialog()">{{ 'LISTINGS.DETAILS.REFRESH_REPORT' | translate }}</a>
    </glxy-alert>
  </div>

  <div *ngIf="edit && !isPartnerConfig; else viewMode">
    <snap-details-table
      [listings]="listings$ | async"
      [isStillGettingData]="isLessThan12HoursOld"
      [edit]="edit"
      [isExpired]="isExpired"
    ></snap-details-table>
  </div>
  <ng-template #viewMode>
    <snap-details-table
      [listings]="enabledListings"
      [isStillGettingData]="isLessThan12HoursOld"
      [edit]="edit"
      [isExpired]="isExpired"
    ></snap-details-table>
  </ng-template>
</snap-subsection>
