import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { SnapshotService as SnapshotApiService } from '@vendasta/snapshot';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ConfirmationData, ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { Competitor, SnapshotConfig, UserEvent } from '../../snapshot-report/snapshot-report';
import { SnapshotService } from '../../snapshot.service';
import { BusinessDetails } from './direct-competitors';
import { DirectCompetitorsService } from './direct-competitors.service';

@Component({
  selector: 'snap-direct-competitors',
  templateUrl: './direct-competitors.component.html',
  styleUrls: ['./direct-competitors.component.scss'],
})
export class DirectCompetitorsComponent implements OnInit, OnDestroy {
  @Input() expanded: boolean;
  @Input() isExpired: boolean;

  @Output() userEvent: EventEmitter<UserEvent> = new EventEmitter<UserEvent>();

  private _destroyed$$: Subject<void> = new Subject<void>();
  public businesses$: Observable<BusinessDetails[]>;
  public businesses: BusinessDetails[] = [];

  isUpdatingCompetitors = false;

  directCompetitorsForm: UntypedFormGroup = new UntypedFormGroup({
    competitors: new UntypedFormArray([
      new UntypedFormControl('', [
        Validators.nullValidator,
        this.competitorInvalid.bind(this),
        this.competitorDuplicated.bind(this),
      ]),
      new UntypedFormControl('', [
        Validators.nullValidator,
        this.competitorInvalid.bind(this),
        this.competitorDuplicated.bind(this),
      ]),
      new UntypedFormControl('', [
        Validators.nullValidator,
        this.competitorInvalid.bind(this),
        this.competitorDuplicated.bind(this),
      ]),
    ]),
  });

  get directFormCompetitors(): UntypedFormArray {
    return <UntypedFormArray>this.directCompetitorsForm.get('competitors');
  }

  constructor(
    public directCompetitorService: DirectCompetitorsService,
    public snapshotService: SnapshotService,
    public snapshotAPIService: SnapshotApiService,
    public translate: TranslateService,
    public snackbarService: SnackbarService,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    // TODO remove legacy direct competitors after migration complete. gscoular 20201213 SNAP-1335
    const legacyDirectCompetitors$ = this.snapshotService.snapshotConfig$.pipe(
      map((c: SnapshotConfig) =>
        c.competitors.slice(0, 3).filter((competitor: Competitor) => competitor?.name && competitor?.snapshotId),
      ),
    );

    const directCompetitors$ = this.snapshotService.snapshotId$.pipe(
      switchMap((id: string) => this.snapshotAPIService.getDirectCompetitors(id)),
    );

    combineLatest([legacyDirectCompetitors$, directCompetitors$])
      .pipe(
        map(([legacy, direct]) => {
          return direct?.length > 0 ? direct : legacy;
        }),
        takeUntil(this._destroyed$$),
      )
      .subscribe((c: Competitor[]) => {
        this.directCompetitorsForm.reset(); // reset the form to clear it, then patch in the values
        c.forEach((competitor, index) => {
          this.directFormCompetitors.at(index).patchValue({
            name: competitor.name,
            snapshotId: competitor.snapshotId,
          });
        });
      });

    if (this.isExpired) {
      this.directCompetitorsForm.disable();
    }

    this.directFormCompetitors.controls.forEach((control) => {
      control.valueChanges
        .pipe(takeUntil(this._destroyed$$), debounceTime(250), distinctUntilChanged())
        .subscribe((term) => {
          if (typeof term === 'string') {
            this.directCompetitorService.setSearchTerm(term);
          }
        });
    });

    this.directCompetitorService.businesses$
      .pipe(takeUntil(this._destroyed$$), distinctUntilChanged())
      .subscribe((b) => {
        if (this.directCompetitorService.isSearchingBusiness$$.getValue()) {
          this.businesses$ = of(b);
        } else {
          this.businesses = this.businesses.concat(b);
          this.businesses$ = of(this.businesses);
        }
      });
  }

  private competitorDuplicated(control: UntypedFormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (selection && this.directFormCompetitors) {
      const duplicated = this.directFormCompetitors.controls.filter(
        (c: any) =>
          c.value &&
          c.value.snapshotId === selection.snapshotId &&
          JSON.stringify(c.value).indexOf('snapshotId') !== -1,
      );
      if (duplicated.length > 1) {
        return { duplicatedOption: true };
      }
    }
    return null;
  }

  private competitorInvalid(control: UntypedFormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (selection && !selection.snapshotId) {
      return { isInvalid: true };
    }
    return null;
  }

  onSubmit(data: { competitors: BusinessDetails[] }): void {
    const snackbarDuration = 3000;
    const options: ConfirmationData = {
      title: this.translate.instant('COMPETITORS.CONFIRMATION_DATA.TITLE'),
      text: this.translate.instant('COMPETITORS.CONFIRMATION_DATA.TEXT'),
      confirmText: this.translate.instant('COMMON.PROCEED'),
      closeText: this.translate.instant('COMMON.CANCEL'),
    };

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '550px',
      data: options,
    });
    confirmationDialog.afterClosed().subscribe((clicked: boolean) => {
      if (clicked) {
        this.userEvent.emit(<UserEvent>{ category: 'direct-competitor-editor', action: 'click', label: 'update' });
        this.isUpdatingCompetitors = true;

        const competitorItems: Competitor[] = [];
        data.competitors.map((c) => {
          if (c && c.snapshotId) {
            competitorItems.push({ name: c.name, snapshotId: c.snapshotId });
          }
        });
        this.snapshotAPIService
          .saveDirectCompetitors(this.snapshotService.snapshotId, competitorItems)
          .pipe(take(1))
          .subscribe({
            error: (error) => {
              this.snackbarService.openErrorSnack('ERRORS.' + error.error.message, {
                duration: snackbarDuration,
              });
              this.isUpdatingCompetitors = false;
            },
            complete: () => {
              this.snackbarService.openSuccessSnack('EDIT_REPORT.UPDATE_COMPETITORS_SUCCESS', {
                duration: snackbarDuration,
              });
              this.isUpdatingCompetitors = false;
            },
          });
      }
    });
  }

  displayName(business: BusinessDetails): string {
    if (business) {
      return business.name;
    }
  }

  resetAutocomplete(term: any): void {
    if (typeof term === 'string') {
      this.directCompetitorService.setSearchTerm(term);
    } else {
      this.directCompetitorService.setSearchTerm('');
    }
  }

  clear(i: number): void {
    this.directFormCompetitors.at(i).reset();
  }

  onScroll(): void {
    this.directCompetitorService.setNextBatch();
  }

  ngOnDestroy(): void {
    this._destroyed$$.next();
    this._destroyed$$.complete();
  }
}
