<div class="section-grade-container">
  <mat-checkbox
    class="checkbox"
    [checked]="section.isSelected"
    [(ngModel)]="section.isSelected"
    (ngModelChange)="selectionChangedEvent($event.checked)"
    [attr.data-action]="'snapshot-section-' + section.sectionName.toLowerCase() + '-checkbox'"
  ></mat-checkbox>
  <mat-nav-list>
    <span matListItemLine>
      {{
        isCustomProposalSnapshotSection(section)
          ? section.sectionName
          : ('SECTIONS.' + section.sectionName | uppercase | translate)
      }}
    </span>
    <snap-grade [grade]="section.grade" [showGrade]="showGrade" size="extra-small"></snap-grade>
  </mat-nav-list>
</div>
