<snap-subsection
  *ngIf="snapshotService.snapshotData$ | async as snapshotData"
  heading="X"
  [hideControl]="controls.controls['hideTwitter']"
  [edit]="edit"
>
  <div subheading *ngIf="twitterTableItems$ | async as twitterTableItems">
    {{ 'SOCIAL.COMMON_SUBHEADING' | translate }}
    <a
      *ngIf="snapshotData?.twitterUrl && twitterTableItems.length > 0; else twitterLinkText"
      class="twitter-url"
      href="{{ snapshotData?.twitterUrl }}"
      target="_blank"
      rel="nofollow"
    >
      <ng-container [ngTemplateOutlet]="twitterLinkText"></ng-container>
    </a>
    <ng-template #twitterLinkText>
      {{ 'SOCIAL.TWITTER.LINK_TEXT' | translate }}
    </ng-template>
  </div>
  <ng-container *ngIf="!isTwitterWorking; else stillWorkingTemplate">
    <mat-progress-bar mode="indeterminate" *ngIf="twitterUrlControl.pending"></mat-progress-bar>
    <ng-container *ngIf="media.mobile$ | async; else desktopView">
      <ng-container *ngIf="competitorTwitterTableItems$ | async as items; else industryMobileView">
        <ng-container *ngIf="items.length > 0 && items[0].competitors?.length > 0; else industryMobileView">
          <snap-mobile-view-data
            [hideGrades]="snapshotService.hideSubGrades$ | async"
            [edit]="edit"
            [subsections]="items"
          ></snap-mobile-view-data>
        </ng-container>
      </ng-container>
      <ng-template #industryMobileView>
        <snap-mobile-view-data
          [hideGrades]="snapshotService.hideSubGrades$ | async"
          [edit]="edit"
          [subsections]="twitterTableItems$ | async"
        ></snap-mobile-view-data>
      </ng-template>
    </ng-container>

    <ng-template #desktopView>
      <ng-container *ngIf="competitorTwitterTableItems$ | async as items; else appGradeTable">
        <ng-container *ngIf="items.length > 0 && items[0].competitors?.length > 0; else appGradeTable">
          <snap-table
            [editMode]="edit"
            [items]="items"
            [showIndustryAverage]="true"
            [hideGrades]="snapshotService.hideSubGrades$ | async"
            [colorTextYourBusiness]="true"
          ></snap-table>
        </ng-container>
      </ng-container>
      <ng-template #appGradeTable>
        <ng-container *ngIf="twitterTableItems$ | async as twitterTableItems">
          <snap-table
            *ngIf="twitterTableItems.length > 0"
            [editMode]="edit"
            [items]="twitterTableItems"
            [showIndustryAverage]="true"
            [showIndustryLeader]="true"
            [hideGrades]="snapshotService.hideSubGrades$ | async"
            [colorTextYourBusiness]="true"
          ></snap-table>
        </ng-container>
      </ng-template>
    </ng-template>

    <div class="not-found" *ngIf="twitterTableItems$ | async as twitterTableItems">
      <ng-container *ngIf="twitterTableItems.length === 0">
        <glxy-empty-state [size]="'small'" [widthConstraint]="false">
          <glxy-empty-state-hero>
            <div class="image">
              <img [src]="'https://www.cdnstyles.com/static/images/icon50/sourceId-10060.png'" />
            </div>
          </glxy-empty-state-hero>
          <glxy-empty-state-title>
            {{ 'SOCIAL.TWITTER.NO_PAGE_ERROR_TITLE' | translate }}
          </glxy-empty-state-title>
          <p>{{ 'SOCIAL.TWITTER.NO_PAGE_ERROR_DESCRIPTION' | translate }}</p>
          <ng-container *ngIf="(snapshotService.isExpired$ | async) === false && edit">
            <glxy-empty-state-actions>
              <div class="form-field">
                <glxy-input
                  dense
                  class="social-url"
                  [formControl]="twitterUrlControl"
                  (keyup.enter)="addTwitterUrl()"
                  placeholder="{{ 'SOCIAL.TWITTER.URL' | translate }}"
                ></glxy-input>

                <div class="submit-button">
                  <a
                    mat-stroked-button
                    [disabled]="!twitterUrlControl.valid || twitterUrlControl.pending"
                    (click)="addTwitterUrl()"
                  >
                    <mat-icon>arrow_forward</mat-icon>
                  </a>
                </div>
              </div>
            </glxy-empty-state-actions>
          </ng-container>
        </glxy-empty-state>
      </ng-container>
    </div>
  </ng-container>
</snap-subsection>

<ng-template #stillWorkingTemplate>
  <snap-still-working></snap-still-working>
</ng-template>
