import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CategoryType } from '@vendasta/category';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { Media, MediaToken } from '../providers/providers';
import { SectionViewLayout } from '../section/section';
import { SnapshotData } from '../snapshot-report/snapshot-report';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { BusinessDetailsService } from './business-details.service';

declare let window: any;

const snapshotHasWebsite = (snapshotData: SnapshotData): boolean => {
  return Boolean(snapshotData?.website) || Boolean(snapshotData?.inferredFields?.website);
};

@Component({
  selector: 'snap-business-details',
  templateUrl: './business-details.component.html',
  styleUrls: ['./business-details.component.scss'],
})
export class BusinessDetailsComponent implements OnInit {
  @Input() edit = false;
  @Input() layout?: SectionViewLayout = 'standard';
  @Output() askQuestionClick = new EventEmitter<string>();

  categoryType$: Observable<CategoryType>;
  categoryIds$: Observable<string[]>;
  imageUrls$: Observable<string[]>;
  panelOpenState: boolean;
  showPopover = false;
  private editAccountUrl$: Observable<string>;
  hasWebsite$: Observable<boolean>;

  constructor(
    readonly snapshotService: SnapshotReportService,
    @Inject(MediaToken) public readonly media: Media,
    private _dialog: MatDialog,
    private router: Router,
    private businessDetailsService: BusinessDetailsService,
    public analyticsService: ProductAnalyticsService,
  ) {}

  ngOnInit(): void {
    this.imageUrls$ = this.snapshotService.snapshotData$.pipe(
      map((d) => {
        return d.imageUrls.map((u) => u.replace('s1600-h150', 's150-p'));
      }),
    );

    this.editAccountUrl$ = this.businessDetailsService.getDomain(this.snapshotService.partnerId).pipe(
      map((domain) => {
        return 'https://' + domain + '/info/' + this.snapshotService.businessId;
      }),
    );

    this.categoryType$ = this.snapshotService.snapshotData$.pipe(
      // show vTaxonomy if the snapshot was generated without a vCategory
      map((snapshotData) =>
        snapshotData?.vcategoryId ? CategoryType.CATEGORY_TYPE_V_CATEGORY : CategoryType.CATEGORY_TYPE_V_TAX,
      ),
      shareReplay(1),
    );
    this.categoryIds$ = combineLatest([this.snapshotService.snapshotData$, this.categoryType$]).pipe(
      map(([snapshotData, categoryType]) =>
        categoryType === CategoryType.CATEGORY_TYPE_V_CATEGORY
          ? [snapshotData?.vcategoryId]
          : [snapshotData?.taxonomyId],
      ),
    );

    this.hasWebsite$ = this.snapshotService.snapshotData$.pipe(map((d) => snapshotHasWebsite(d)));
  }

  redirectToEditBusinessDetails(): void {
    this.editAccountUrl$.pipe(take(1)).subscribe((link) => {
      this.router.navigate([]).then(() => {
        window.open(link, '_blank');
      });
    });
  }

  openPopover(): void {
    this.showPopover = true;
  }

  hidePopover(): void {
    this.showPopover = false;
  }

  trackUserEvents(category: string, action: string, name: string, ctaType?: string): void {
    this.analyticsService.trackEvent(name, category, action, 0, { ctaType: ctaType });
  }

  openAskQuestionDialog(name: string): void {
    this.askQuestionClick.emit(name);
  }
}
