import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DomainService } from '@vendasta/domain';
import { retryer } from '@vendasta/rx-utils';
import { CompetitionAnalysis, Snapshot, SnapshotService as SnapshotAPIService, VideoStyle } from '@vendasta/snapshot';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest as observableCombineLatest,
  of,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  share,
  shareReplay,
  skip,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs/operators';
import { SNAPSHOT_ID_TOKEN } from './providers/providers';
import {
  Competitor,
  LanguageConfigInterface,
  SnapshotConfig,
  SnapshotConfigInterface,
  SnapshotData,
  SnapshotReport,
  SnapshotSummary,
} from './snapshot-report/snapshot-report';
import { PartnerMarketService } from './whitelabel/whitelabel.service';

@Injectable()
export class SnapshotService implements PartnerMarketService, OnDestroy {
  private _businessId$$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _partnerId$$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _marketId$$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _snapshot$$: ReplaySubject<SnapshotReport> = new ReplaySubject(1);
  private _configurationChanged$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _summary$$: ReplaySubject<SnapshotSummary> = new ReplaySubject(1);

  private subscriptions: Subscription[] = [];

  public snapshotId: string;
  public snapshot$: Observable<SnapshotReport>;
  public businessId$: Observable<string>;
  public partnerId$: Observable<string>;
  public marketId$: Observable<string>;
  public summary$: Observable<SnapshotSummary> = this._summary$$.asObservable().pipe(skipWhile((s) => !s));

  public snapshotConfig$: Observable<SnapshotConfig>;
  public snapshotData$: Observable<SnapshotData>;
  public hideGrades$: Observable<boolean>;
  public competitors$: Observable<Competitor[]>;
  public videoStyle$: Observable<VideoStyle>;
  public competitionAnalysis$: Observable<CompetitionAnalysis>;
  public partnerDomain$: Observable<string>;

  constructor(
    private _api: SnapshotAPIService,
    private router: Router,
    @Inject(SNAPSHOT_ID_TOKEN) public snapshotId$: Observable<string | undefined>,
    private readonly domainService: DomainService,
  ) {
    this.subscriptions.push(
      this.snapshotId$
        .pipe(
          skipWhile((a) => !a),
          distinctUntilChanged(),
        )
        .subscribe((id) => {
          this.snapshotId = id;
        }),
    );
    this.initLoader();

    this.snapshot$ = this._snapshot$$.asObservable().pipe(skipWhile((s) => !s));
    this.businessId$ = this._businessId$$.asObservable().pipe(skipWhile((s) => !s));
    this.partnerId$ = this._partnerId$$.asObservable().pipe(skipWhile((s) => !s));
    this.marketId$ = this._marketId$$.asObservable();
    this.snapshotConfig$ = this.snapshot$.pipe(map((s) => s.config));
    this.snapshotData$ = this.snapshot$.pipe(map((s) => s.data));
    this.hideGrades$ = this.snapshotConfig$.pipe(map((c) => c.hideGrades));
    this.competitors$ = this.snapshotConfig$.pipe(
      skip(1),
      map((c) => c.competitors),
      distinctUntilChanged((previous, current) => this.isSameCompetitors(previous, current)),
      share(),
    );
    this.videoStyle$ = this.snapshotConfig$.pipe(map((c) => c.videoStyle));
    this.competitionAnalysis$ = this.snapshotConfig$.pipe(map((c) => c.competitionAnalysis));
    this.partnerDomain$ = this.partnerId$.pipe(
      distinctUntilChanged(),
      switchMap((partnerId) => this.domainService.getDomainByIdentifier(`/application/ST/partner/${partnerId}`)),
      map((resp) => {
        const scheme = resp.primary.secure ? 'https' : 'http';
        return `${scheme}://${resp.primary.domain}`;
      }),
      shareReplay(1),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private initLoader(): void {
    observableCombineLatest([this.snapshotId$, this._configurationChanged$$.asObservable()])
      .pipe(
        filter(([id]) => Boolean(id)),
        switchMap(([id]: [string, boolean]) => {
          const retryConfig = {
            maxAttempts: 5,
            retryDelay: 100,
            timeoutMilliseconds: 10000,
          };
          return this._api.get(id).pipe(
            retryer(retryConfig),
            catchError((err) => {
              if (err?.status === 404 && this.router.url.includes('/snapshot')) {
                this.router.navigate(['/snapshot/error'], {
                  skipLocationChange: true,
                  queryParams: { error: 'NOT_FOUND.TITLE' },
                });
              }
              return of(new Snapshot());
            }),
            tap((s) => {
              const ss = new SnapshotReport(s);
              this._snapshot$$.next(ss);
              this._businessId$$.next(ss.businessId);
              this._partnerId$$.next(ss.data.partnerId);
              this._marketId$$.next(ss.data.marketId);
            }),
            map(() => true),
          );
        }),
      )
      .subscribe();

    observableCombineLatest([this.snapshotId$, this._configurationChanged$$.asObservable()])
      .pipe(
        switchMap(([id]: [string, boolean]) => {
          const retryConfig = {
            maxAttempts: 5,
            retryDelay: 100,
            timeoutMilliseconds: 10000,
          };
          return this._api.getSummary(id).pipe(
            retryer(retryConfig),
            tap((s) => {
              const ss = new SnapshotSummary(s);
              this._summary$$.next(ss);
            }),
            map(() => true),
          );
        }),
      )
      .subscribe();
  }

  updateConfig(config: SnapshotConfigInterface): Observable<any> {
    return this.snapshotId$.pipe(
      switchMap((id) => this._api.updateConfig(id, config)),
      tap(() => this._configurationChanged$$.next(true)),
    );
  }

  updateLanguageConfig(config: LanguageConfigInterface): Observable<any> {
    return this.snapshotId$.pipe(
      switchMap((id) => this._api.updateLanguageConfig(id, config)),
      tap(() => this._configurationChanged$$.next(true)),
    );
  }

  get businessId(): string {
    return this._businessId$$.getValue();
  }

  get partnerId(): string {
    return this._partnerId$$.getValue();
  }

  get marketId(): string {
    return this._marketId$$.getValue();
  }

  private isSameCompetitors(previous: Competitor[], current: Competitor[]): boolean {
    if (previous.length !== current.length) {
      return false;
    }

    let isSame = true;
    current.forEach((_, index) => {
      if (
        previous[index].snapshotId !== current[index].snapshotId ||
        previous[index].website !== current[index].website ||
        previous[index].name !== current[index].name
      ) {
        isSame = false;
        return;
      }
    });
    return isSame;
  }
}
