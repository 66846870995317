import { Component, Input } from '@angular/core';

@Component({
  selector: 'snap-website-audit-summary',
  templateUrl: './website-audit-summary.component.html',
  styleUrls: ['./website-audit-summary.component.scss'],
})
export class WebsiteAuditSummaryComponent {
  defaultLink = 'https://developers.google.com/web/fundamentals/performance/why-performance-matters';

  @Input() title: string;
  @Input() errorCount: number;
  @Input() warningCount: number;
  @Input() passedCount: number;
}
