import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { CompetitionAnalysis, SSLSummaryStatus } from '@vendasta/snapshot';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FormatType,
  Icons,
  SectionTableCompetitorItemInterface,
  SectionTableItem,
} from '../../section-table/section-table';
import { BaseSectionComponent } from '../../section/base-section.component';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';
import { WebsiteCompetitor } from '../website-competitor';
import { HomepageData } from '../website-data';
import { WebsiteService } from '../website.service';
import { Media, MediaToken } from '../../providers/providers';

interface HomepageContentTable {
  address: BusinessItem | null;
  phone: BusinessItem;
  items: BusinessItem[];
}

interface BusinessItem {
  icon: string;
  title: string;
  business?: string;
  industry?: string;
}

@Component({
  selector: 'snap-website-homepage-subsection',
  templateUrl: './website-homepage.component.html',
  styleUrls: ['./website-homepage.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: WebsiteService,
    },
  ],
})
export class WebsiteHomepageSubsectionComponent extends BaseSectionComponent implements OnInit {
  homepageData$: Observable<HomepageData>;
  isServiceAreaBusiness$: Observable<boolean>;
  websiteCompetitors$: Observable<WebsiteCompetitor[]>;
  website$: Observable<string>;
  homepageContentTable$: Observable<HomepageContentTable>;
  sslCardData$: Observable<BusinessItem>;
  competitorGradeTableData$: Observable<SectionTableItem[]>;

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: WebsiteService,
    public snapshotService: SnapshotReportService,
    public translate: TranslateService,
    public snackbar: SnackbarService,
    @Inject(MediaToken) public readonly media: Media,
  ) {
    super(service, snackbar, translate);

    const websiteData$ = this.service.data$;

    this.homepageData$ = websiteData$.pipe(map((d) => d.homepageData));

    this.sslCardData$ = websiteData$.pipe(map((d) => this.buildSslCardData(d.ssl.status)));

    this.isServiceAreaBusiness$ = this.snapshotService.snapshotData$.pipe(map((sd) => sd.serviceAreaBusiness));

    this.homepageContentTable$ = combineLatest([this.homepageData$, this.isServiceAreaBusiness$]).pipe(
      map(([homepageData, isServiceAreaBusiness]) =>
        this.buildHomepageContentTable(homepageData, isServiceAreaBusiness),
      ),
    );

    this.websiteCompetitors$ = combineLatest([this.service.data$, this.snapshotService.competitionAnalysis$]).pipe(
      map(([d, ca]) => {
        if (ca === CompetitionAnalysis.INDUSTRY_DATA) {
          return [];
        }
        return d.competitors;
      }),
    );

    this.website$ = this.snapshotService.snapshotData$.pipe(map((s) => s.website));

    this.competitorGradeTableData$ = combineLatest([this.homepageData$, this.websiteCompetitors$, this.website$]).pipe(
      map(([homepageData, websiteCompetitors, website]) =>
        this.buildGradeTableData(homepageData, websiteCompetitors, website),
      ),
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  private buildSslCardData(sslStatus: SSLSummaryStatus): BusinessItem {
    let ssl;
    if (sslStatus !== SSLSummaryStatus.SSL_SUMMARY_STATUS_UNDETERMINED) {
      ssl = {
        icon: sslStatus === SSLSummaryStatus.SSL_SUMMARY_STATUS_VALID ? 'check_circle' : 'cancel',
        title: 'WEBSITE.SECURE',
      };
    }
    return ssl;
  }

  private buildHomepageContentTable(hd: HomepageData, isServiceAreaBusiness: boolean): HomepageContentTable {
    const address = this.buildAddressItem(isServiceAreaBusiness, hd);

    const phoneNumber = {
      icon: hd.phonePresent ? 'check_circle' : 'cancel',
      title: 'WEBSITE.HOMEPAGE.PHONE_NUMBER_FOUND',
    };

    const items = [
      {
        icon: hd.size > hd.sizeIndustryAverage ? 'error' : 'check_circle',
        title: 'WEBSITE.HOMEPAGE.SIZE',
        business: Math.round(hd.size / 1024) + 'KB',
        industry: Math.round(hd.sizeIndustryAverage / 1024) + 'KB',
      },
      {
        icon: hd.videoPresent ? 'check_circle' : 'cancel',
        title: 'WEBSITE.HOMEPAGE.VIDEO.HEADING',
        business: hd.videoPresent ? Icons.Found : Icons.NotFound,
        industry: this.formatNumber(hd.videoPresentIndustryAverage) + '%',
      },
      {
        icon: hd.facebookPresent ? 'check_circle' : 'cancel',
        title: 'WEBSITE.HOMEPAGE.FACEBOOK_FOUND',
        business: hd.facebookPresent ? Icons.Found : Icons.NotFound,
        industry: this.formatNumber(hd.facebookPresentIndustryAverage) + '%',
      },
      {
        icon: hd.twitterPresent ? 'check_circle' : 'cancel',
        title: 'WEBSITE.HOMEPAGE.TWITTER_FOUND',
        business: hd.twitterPresent ? Icons.Found : Icons.NotFound,
        industry: this.formatNumber(hd.twitterPresentIndustryAverage) + '%',
      },
      {
        icon: hd.instagramPresent ? 'check_circle' : 'cancel',
        title: 'WEBSITE.HOMEPAGE.INSTAGRAM_FOUND',
        business: hd.instagramPresent ? Icons.Found : Icons.NotFound,
        industry: this.formatNumber(hd.instagramPresentIndustryAverage) + '%',
      },
    ];

    const homepageContent = {
      address: address,
      phone: phoneNumber,
      items: items,
    };
    return homepageContent;
  }

  private buildGradeTableData(hd: HomepageData, wc: WebsiteCompetitor[], website: string): SectionTableItem[] {
    const items: SectionTableItem[] = [];
    const formattedWebsite = this.formatUrl(website);

    if (!wc || wc.length <= 0) {
      items.push(
        ...[
          {
            title: 'WEBSITE.HOMEPAGE.SIZE',
            data: {
              business: Math.round(hd.size / 1024) + 'KB',
              link: formattedWebsite,
              industryAverage: Math.round(hd.sizeIndustryAverage / 1024) + 'KB',
            },
          } as SectionTableItem,
        ],
      );
    }

    items.push({
      title: 'WEBSITE.HOMEPAGE.VIDEO.HEADING',
      labelFormat: FormatType.Link,
      data: {
        business: hd.videoPresent,
        link: formattedWebsite,
        industryAverage: this.formatNumber(hd.videoPresentIndustryAverage) + '%',
      },
      dataFormat: FormatType.Check,
      competitors: wc.map((c) => {
        return {
          name: c.website,
          value: c.homepageData.videoPresent,
        } as SectionTableCompetitorItemInterface;
      }),
    } as SectionTableItem);
    items.push({
      title: 'WEBSITE.HOMEPAGE.FACEBOOK_FOUND',
      labelFormat: FormatType.Link,
      dataFormat: FormatType.Check,
      data: {
        business: hd.facebookPresent,
        link: formattedWebsite,
        industryAverage: this.formatNumber(hd.facebookPresentIndustryAverage) + '%',
      },
      competitors: wc.map((c) => {
        return {
          name: c.website,
          value: c.homepageData.facebookPresent,
        };
      }),
    } as SectionTableItem);

    items.push({
      title: 'WEBSITE.HOMEPAGE.INSTAGRAM_FOUND',
      labelFormat: FormatType.Link,
      dataFormat: FormatType.Check,
      data: {
        business: hd.instagramPresent,
        link: formattedWebsite,
        industryAverage: this.formatNumber(hd.instagramPresentIndustryAverage) + '%',
      },
      competitors: wc.map((c) => {
        return {
          name: c.website,
          value: c.homepageData.instagramPresent,
        };
      }),
    } as SectionTableItem);
    items.push({
      title: 'WEBSITE.HOMEPAGE.TWITTER_FOUND',
      labelFormat: FormatType.Link,
      dataFormat: FormatType.Check,
      data: {
        business: hd.twitterPresent,
        link: formattedWebsite,
        industryAverage: this.formatNumber(hd.twitterPresentIndustryAverage) + '%',
      },
      competitors: wc.map((c) => {
        return {
          name: c.website,
          value: c.homepageData.twitterPresent,
        };
      }),
    } as SectionTableItem);

    return items;
  }

  // formats the number to at most 2 decimal places
  private formatNumber(n: number): number {
    return Math.round(n * 100) / 100;
  }

  private formatUrl(url: string): string {
    const match = url.match(/^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/);
    return match ? match[1] : url;
  }

  private buildAddressItem(serviceAreaBusiness: boolean, hd: HomepageData): BusinessItem | null {
    let address: BusinessItem | null = null;
    // if the business is not a service area business, put the address found check in the list
    if (!serviceAreaBusiness) {
      address = {
        icon: hd.addressPresent ? 'check_circle' : 'cancel',
        title: 'WEBSITE.HOMEPAGE.ADDRESS_FOUND',
      };
    }
    return address;
  }
}
