import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

const MEDIA_QUERIES = {
  mobile: '(max-width: 840px)',
  print: 'print',
};

@Injectable({
  providedIn: 'root',
})
export class MediaQueryService {
  private readonly printSim$$ = new BehaviorSubject<boolean>(false);

  readonly mobile$ = this.observeMedia(MEDIA_QUERIES.mobile);
  readonly print$ = this.someTrue([this.observeMedia(MEDIA_QUERIES.print), this.printSim$$]);

  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  private observeMedia(query: string): Observable<boolean> {
    return this.breakpointObserver.observe(query).pipe(
      map((v) => v.matches),
      shareReplay(1),
    );
  }

  private someTrue(list: Observable<boolean>[]): Observable<boolean> {
    return combineLatest(list).pipe(map((values) => values.some((item) => Boolean(item))));
  }

  simulatePrintMedia(print: boolean): void {
    this.printSim$$.next(print);
  }
}
