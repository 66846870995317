<ng-container
  *ngIf="
    (service.sectionVersion$ | async) === sectionVersions.VERSION_1;
    then mobileDesktopVersion1;
    else mobileDesktopVersion0
  "
></ng-container>

<ng-template #errorFetchingDesktopTemplate>
  <snap-subsection
    heading="{{ 'WEBSITE.DESKTOP.HEADING' | translate }}"
    subheading="{{ 'WEBSITE.DESKTOP.SUBHEADING' | translate }}"
    [edit]="edit"
    [hideControl]="controls.controls['hideDesktop']"
  >
    <div class="not-found">
      <glxy-empty-state [size]="'small'" [widthConstraint]="false">
        <glxy-empty-state-hero>
          <mat-icon>error</mat-icon>
        </glxy-empty-state-hero>
        <glxy-empty-state-title>
          {{ 'WEBSITE.ERROR_FETCHING_DESKTOP_DATA' | translate }}
        </glxy-empty-state-title>
      </glxy-empty-state>
    </div>
  </snap-subsection>
</ng-template>

<ng-template #errorFetchingMobileTemplate>
  <snap-subsection
    heading="{{ 'WEBSITE.MOBILE.HEADING' | translate }}"
    subheading="{{ 'WEBSITE.MOBILE.SUBHEADING' | translate }}"
    [edit]="edit"
    [hideControl]="controls.controls['hideMobile']"
  >
    <div class="not-found">
      <glxy-empty-state [size]="'small'" [widthConstraint]="false">
        <glxy-empty-state-hero>
          <mat-icon>error</mat-icon>
        </glxy-empty-state-hero>
        <glxy-empty-state-title>
          {{ 'WEBSITE.ERROR_FETCHING_MOBILE_DATA' | translate }}
        </glxy-empty-state-title>
      </glxy-empty-state>
    </div>
  </snap-subsection>
</ng-template>

<ng-template #errorFetchingTemplate>
  <snap-multi-subsection
    [subsections]="mobileDesktopSubsections$ | async"
    subheading="{{ 'WEBSITE.MULTI_SUBSECTION.SUBHEADING' | translate }}"
    [hideGrade]="snapshotService.hideSubGrades$ | async"
    [edit]="edit"
  >
    <div class="not-found">
      <glxy-empty-state [size]="'small'" [widthConstraint]="false">
        <glxy-empty-state-hero>
          <mat-icon>error</mat-icon>
        </glxy-empty-state-hero>
        <glxy-empty-state-title>
          {{ 'WEBSITE.ERROR_FETCHING_ALL_DATA' | translate }}
        </glxy-empty-state-title>
      </glxy-empty-state>
    </div>
  </snap-multi-subsection>
</ng-template>

<ng-template #screenshotLoading>
  <mat-spinner mode="indeterminate" diameter="25"></mat-spinner>
</ng-template>

<ng-template #notMobileFriendly>
  <div class="mobile-friendly flex align-center-center">
    <mat-icon class="error">error</mat-icon>
    <span>{{ 'WEBSITE.NOT_MOBILE_FRIENDLY' | translate }}</span>
  </div>
</ng-template>

<ng-template #mobileDesktopVersion0>
  <ng-container *ngIf="service.data$ | async as websiteData">
    <ng-container *ngIf="hasMobileGrade$ | async; else errorFetchingMobileTemplate">
      <!-- pageSpeed V5 Mobile -->
      <snap-subsection
        heading="{{ 'WEBSITE.MOBILE.HEADING' | translate }}"
        [grade]="websiteData.mobileGrade"
        [hideGrade]="snapshotService.hideSubGrades$ | async"
        subheading="{{ 'WEBSITE.MOBILE.SUBHEADING' | translate }}"
        [edit]="edit"
        [hideControl]="controls.controls['hideMobile']"
      >
        <div class="subsection">
          <div class="subsection-inner-container flex align-start-center">
            <div class="summary-container flex-column align-center-stretch">
              <snap-website-audit-summary
                title="{{ 'COMMON.SPEED' | translate }}"
                [errorCount]="(mobileInsightsPartitionedAudits$ | async)?.shouldFix.length"
                [warningCount]="(mobileInsightsPartitionedAudits$ | async)?.considerFix.length"
                [passedCount]="(mobileInsightsPartitionedAudits$ | async)?.passed.length"
              ></snap-website-audit-summary>
            </div>
            <snap-toggled-item [edit]="edit" [hideControl]="controls.controls['hideMobilePreview']">
              <div class="mobile-preview">
                <div class="toggle">
                  <ng-template snapItemToggle></ng-template>
                </div>
                <img *ngIf="websiteData; else screenshotLoading" [src]="websiteData.mobileScreenshot" />
              </div>
              <ng-container *ngIf="websiteData.mobileFriendly; else notMobileFriendly">
                <div class="mobile-friendly flex align-center-center">
                  <mat-icon class="check_circle">check_circle</mat-icon>
                  <span>{{ 'WEBSITE.MOBILE_FRIENDLY' | translate }}</span>
                </div>
              </ng-container>
            </snap-toggled-item>
          </div>
          <div class="rules">
            <h3>{{ 'COMMON.SPEED' | translate }}</h3>
            <hr />
            <ng-container *ngFor="let a of websiteData.mobileInsights.audits | slice: 0 : 2">
              <snap-website-audit-details [audit]="a"></snap-website-audit-details>
              <hr class="rule-separator" />
            </ng-container>
            <div [@expandRules]="showAllMobileSpeed ? 'on' : 'off'">
              <ng-container *ngFor="let a of websiteData.mobileInsights.audits | slice: 2">
                <snap-website-audit-details [audit]="a"></snap-website-audit-details>
                <hr class="rule-separator" />
              </ng-container>
            </div>
            <a
              class="show-all flex align-start-center"
              (click)="showAllMobileSpeed = !showAllMobileSpeed"
              [trackEvent]="{
                eventName: !showAllMobileSpeed ? 'show-all-details-mobile' : 'hide-all-details-mobile',
                category: 'anchor',
                action: 'click'
              }"
            >
              <ng-container *ngIf="!showAllMobileSpeed">
                <span>{{ 'COMMON.SHOW_ALL' | translate }}</span>
                <mat-icon>expand_more</mat-icon>
              </ng-container>
              <ng-container *ngIf="showAllMobileSpeed">
                <span>{{ 'COMMON.HIDE' | translate }}</span>
                <mat-icon>expand_less</mat-icon>
              </ng-container>
            </a>
          </div>
        </div>
      </snap-subsection>
    </ng-container>

    <!-- pageSpeed V5 Desktop -->
    <snap-subsection
      *ngIf="hasDesktopInsightsGrade$ | async; else errorFetchingDesktopTemplate"
      heading="{{ 'WEBSITE.DESKTOP.HEADING' | translate }}"
      subheading="{{ 'WEBSITE.DESKTOP.SUBHEADING' | translate }}"
      [grade]="websiteData.desktopInsights.grade"
      [hideGrade]="snapshotService.hideSubGrades$ | async"
      [edit]="edit"
      [hideControl]="controls.controls['hideDesktop']"
    >
      <div class="subsection">
        <div class="subsection-inner-container flex align-start-center">
          <div class="summary-container flex-column align-center-stretch">
            <snap-website-audit-summary
              title="{{ 'COMMON.SPEED' | translate }}"
              [errorCount]="(desktopInsightsPartitionedAudits$ | async)?.shouldFix.length"
              [warningCount]="(desktopInsightsPartitionedAudits$ | async)?.considerFix.length"
              [passedCount]="(desktopInsightsPartitionedAudits$ | async)?.passed.length"
            ></snap-website-audit-summary>
          </div>
          <snap-toggled-item [edit]="edit" [hideControl]="controls.controls['hideDesktopPreview']">
            <div class="desktop-preview">
              <div class="toggle">
                <ng-template snapItemToggle></ng-template>
              </div>
              <img *ngIf="websiteData; else screenshotLoading" [src]="websiteData.desktopScreenshot" />
            </div>
          </snap-toggled-item>
        </div>
        <div class="rules">
          <h3>{{ 'COMMON.SPEED' | translate }}</h3>
          <hr />
          <ng-container *ngFor="let a of websiteData.desktopInsights.audits | slice: 0 : 2">
            <snap-website-audit-details [audit]="a"></snap-website-audit-details>
            <hr class="rule-separator" />
          </ng-container>
          <div [@expandRules]="showAllDesktopSpeed ? 'on' : 'off'">
            <ng-container *ngFor="let a of websiteData.desktopInsights.audits | slice: 2">
              <snap-website-audit-details [audit]="a"></snap-website-audit-details>
              <hr class="rule-separator" />
            </ng-container>
          </div>
          <a
            class="show-all flex align-start-center"
            (click)="showAllDesktopSpeed = !showAllDesktopSpeed"
            [trackEvent]="{
              eventName: !showAllDesktopSpeed ? 'show-all-details-desktop' : 'hide-all-details-desktop',
              category: 'anchor',
              action: 'click'
            }"
          >
            <ng-container *ngIf="!showAllDesktopSpeed">
              <span>{{ 'COMMON.SHOW_ALL' | translate }}</span>
              <mat-icon>expand_more</mat-icon>
            </ng-container>
            <ng-container *ngIf="showAllDesktopSpeed">
              <span>{{ 'COMMON.HIDE' | translate }}</span>
              <mat-icon>expand_less</mat-icon>
            </ng-container>
          </a>
        </div>
      </div>
    </snap-subsection>
    <!-- /pageSpeed V5 Desktop-->
  </ng-container>
</ng-template>

<ng-template #mobileDesktopVersion1>
  <ng-container *ngIf="service.data$ | async as websiteData">
    <ng-container *ngIf="(hasMobileGrade$ | async) || (hasDesktopInsightsGrade$ | async); else errorFetchingTemplate">
      <snap-multi-subsection
        [subsections]="mobileDesktopSubsections$ | async"
        subheading="{{ 'WEBSITE.MULTI_SUBSECTION.SUBHEADING' | translate }}"
        [hideGrade]="snapshotService.hideSubGrades$ | async"
        [edit]="edit"
      >
        <ng-container
          *ngIf="{
            desktop: showDesktop$ | async,
            mobile: showMobile$ | async
          } as show"
        >
          <div class="subsection">
            <!-- subsection empty states -->
            <ng-container *ngIf="(hasMobileGrade$ | async) === false && show.mobile">
              <div>
                <glxy-empty-state [size]="'small'" [widthConstraint]="false">
                  <glxy-empty-state-hero>
                    <mat-icon>error</mat-icon>
                  </glxy-empty-state-hero>
                  <glxy-empty-state-title>
                    {{ 'WEBSITE.ERROR_FETCHING_MOBILE_DATA' | translate }}
                  </glxy-empty-state-title>
                </glxy-empty-state>
              </div>
            </ng-container>
            <ng-container *ngIf="(hasDesktopInsightsGrade$ | async) === false && show.desktop">
              <div>
                <glxy-empty-state [size]="'small'" [widthConstraint]="false">
                  <glxy-empty-state-hero>
                    <mat-icon>error</mat-icon>
                  </glxy-empty-state-hero>
                  <glxy-empty-state-title>
                    {{ 'WEBSITE.ERROR_FETCHING_DESKTOP_DATA' | translate }}
                  </glxy-empty-state-title>
                </glxy-empty-state>
              </div>
            </ng-container>

            <ng-container *ngIf="coreWebVitals$ | async as vitals">
              <ng-container *ngIf="edit">
                <div class="row preview-control-group">
                  <div class="preview-control">
                    <snap-item-toggle
                      *ngIf="show.desktop"
                      [hideControl]="controls.controls['hideDesktopPreview']"
                      [label]="'WEBSITE.SHOW_DESKTOP_PREVIEW' | translate"
                    ></snap-item-toggle>
                  </div>
                  <div class="preview-control">
                    <snap-item-toggle
                      *ngIf="show.mobile"
                      [hideControl]="controls.controls['hideMobilePreview']"
                      [label]="'WEBSITE.SHOW_MOBILE_PREVIEW' | translate"
                    ></snap-item-toggle>
                  </div>
                </div>
              </ng-container>
              <div class="row row-gutters center-flex-content">
                <ng-container
                  *ngIf="(showDesktopPreview$ | async) || (showMobilePreview$ | async); else previewDisabled"
                >
                  <div
                    class="col col-xs-12 col-sm-6 col-lg-7 preview-col center-flex-content print-performance-card"
                    *ngIf="websiteData"
                  >
                    <snap-website-preview
                      [showMobile]="showMobilePreview$ | async"
                      [showDesktop]="showDesktopPreview$ | async"
                      [mobileScreenshot]="websiteData.mobileScreenshot"
                      [desktopScreenshot]="websiteData.desktopScreenshot"
                      [isMobileFriendly]="websiteData.mobileFriendly"
                    ></snap-website-preview>
                  </div>
                  <div
                    class="col col-xs-12 col-sm-6 col-lg-5 center-flex-content print-performance-card"
                    *ngIf="vitals.get(SpeedIndex.auditId) as speedIndex"
                  >
                    <snap-performance-card
                      [title]="'WEBSITE.CORE_WEB_VITALS.CARDS.PAGE_SPEED_TITLE' | translate"
                      [subtitle]="'WEBSITE.CORE_WEB_VITALS.CARDS.PAGE_SPEED_DESCRIPTION' | translate"
                      [learnMoreUrl]="SpeedIndex.learnMoreUrl"
                    >
                      <snap-summary-thermometer
                        [thermometer]="speedIndex.thermometer"
                        [percentiles]="speedIndex.percentiles"
                      ></snap-summary-thermometer>
                    </snap-performance-card>
                  </div>
                </ng-container>
                <ng-template #previewDisabled>
                  <div
                    class="col col-xs-12 col-sm-6 col-lg-4 print-performance-card"
                    *ngIf="vitals.get(SpeedIndex.auditId) as speedIndex"
                  >
                    <snap-performance-card
                      [title]="'WEBSITE.CORE_WEB_VITALS.CARDS.PAGE_SPEED_TITLE' | translate"
                      [subtitle]="'WEBSITE.CORE_WEB_VITALS.CARDS.PAGE_SPEED_DESCRIPTION' | translate"
                      [learnMoreUrl]="SpeedIndex.learnMoreUrl"
                    >
                      <snap-summary-thermometer
                        [thermometer]="speedIndex.thermometer"
                        [percentiles]="speedIndex.percentiles"
                      ></snap-summary-thermometer>
                    </snap-performance-card>
                  </div>
                </ng-template>
              </div>

              <div class="vitals-title">
                {{ 'WEBSITE.CORE_WEB_VITALS.TITLE' | translate }}
              </div>
              <div class="hide-mobile" class="vitals-description">
                {{ 'WEBSITE.CORE_WEB_VITALS.DESCRIPTION' | translate }}
              </div>

              <div class="row row-gutters">
                <div
                  class="col col-xs-12 col-sm-6 col-lg-4 print-performance-card"
                  *ngIf="vitals.get(LargestContentfulPaint.auditId) as largestContentfulPaint"
                >
                  <snap-performance-card
                    [title]="'WEBSITE.CORE_WEB_VITALS.CARDS.LARGE_CONTENT_TITLE' | translate"
                    [subtitle]="'WEBSITE.CORE_WEB_VITALS.CARDS.LARGE_CONTENT_DESCRIPTION' | translate"
                    [learnMoreUrl]="LargestContentfulPaint.learnMoreUrl"
                  >
                    <snap-summary-thermometer
                      [thermometer]="largestContentfulPaint.thermometer"
                      [percentiles]="largestContentfulPaint.percentiles"
                    ></snap-summary-thermometer>
                  </snap-performance-card>
                </div>
                <div
                  class="col col-xs-12 col-sm-6 col-lg-4 print-performance-card"
                  *ngIf="vitals.get(MaxPotentialFirstInputDelay.auditId) as maxPotentialFirstInputDelay"
                >
                  <snap-performance-card
                    [title]="'WEBSITE.CORE_WEB_VITALS.CARDS.MAX_POTENTIAL_FID_TITLE' | translate"
                    [subtitle]="'WEBSITE.CORE_WEB_VITALS.CARDS.MAX_POTENTIAL_FID_DESCRIPTION' | translate"
                    [learnMoreUrl]="MaxPotentialFirstInputDelay.learnMoreUrl"
                  >
                    <snap-summary-thermometer
                      [thermometer]="maxPotentialFirstInputDelay.thermometer"
                      [percentiles]="maxPotentialFirstInputDelay.percentiles"
                    ></snap-summary-thermometer>
                  </snap-performance-card>
                </div>
                <div
                  class="col col-xs-12 col-sm-6 col-lg-4 print-performance-card"
                  *ngIf="vitals.get(CumulativeLayoutShift.auditId) as cumulativeLayoutShift"
                >
                  <snap-performance-card
                    [title]="'WEBSITE.CORE_WEB_VITALS.CARDS.VISUAL_STABILITY_TITLE' | translate"
                    [subtitle]="'WEBSITE.CORE_WEB_VITALS.CARDS.VISUAL_STABILITY_DESCRIPTION' | translate"
                    [learnMoreUrl]="CumulativeLayoutShift.learnMoreUrl"
                  >
                    <snap-summary-thermometer
                      [thermometer]="cumulativeLayoutShift.thermometer"
                      [percentiles]="cumulativeLayoutShift.percentiles"
                    ></snap-summary-thermometer>
                  </snap-performance-card>
                </div>
              </div>
            </ng-container>

            <div class="audit-details">
              <snap-audit-report
                *ngIf="show.mobile && websiteData.mobileInsights?.audits.length > 0"
                [audits]="mobileInsightsAudits$ | async | filterWebVitals"
                [reportType]="'mobile'"
              ></snap-audit-report>
              <snap-audit-report
                *ngIf="show.desktop && websiteData.desktopInsights?.audits.length > 0"
                [audits]="desktopInsightsAudits$ | async | filterWebVitals"
                [reportType]="'desktop'"
              ></snap-audit-report>
            </div>
          </div>
        </ng-container>
      </snap-multi-subsection>
    </ng-container>
  </ng-container>
</ng-template>
