import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'glxy-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonGroupComponent {
  @HostBinding('class') class = 'glxy-button-group';
}
