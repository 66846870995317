import { VideoStyle } from '@vendasta/snapshot';
import { ImageService } from '../../core';

const imageService = new ImageService();

export interface Language {
  /** language code (BCP 47 standard) */
  code: string;
  /** language name in English */
  name: string;
  /** language name in the language itself */
  label: string;
  /** url to the flag image */
  flagUrl: string;
  /** video style for the language */
  videoStyle?: VideoStyle;
}

/**
 * Available languages for the Snapshot report
 * The language code is not unique. We can have multiple languages with the same code with different flags and videoStyles.
 */
export const SnapshotLanguages: Language[] = [
  {
    code: 'cs',
    name: 'Czech',
    label: 'Čeština',
    flagUrl: imageService.getFlagSrc('czech_republic.svg'),
    videoStyle: VideoStyle.NO_VIDEOS,
  },
  {
    code: 'de',
    name: 'German',
    label: 'Deutsch',
    flagUrl: imageService.getFlagSrc('germany.svg'),
    videoStyle: VideoStyle.NO_VIDEOS,
  },
  {
    code: 'en-us',
    name: 'English',
    label: 'English',
    flagUrl: imageService.getFlagSrc('united_states.svg'),
    videoStyle: VideoStyle.NORTH_AMERICAN,
  },
  {
    code: 'en-us',
    name: 'English',
    label: 'English',
    flagUrl: imageService.getFlagSrc('australia.svg'),
    videoStyle: VideoStyle.AUSTRALIAN,
  },
  {
    code: 'en-us',
    name: 'English',
    label: 'English',
    flagUrl: imageService.getFlagSrc('united_kingdom.svg'),
    videoStyle: VideoStyle.UNITED_KINGDOM,
  },
  {
    code: 'en-us',
    name: 'English',
    label: 'English',
    flagUrl: imageService.getFlagSrc('south_africa.svg'),
    videoStyle: VideoStyle.SOUTH_AFRICA,
  },
  {
    code: 'es-419',
    name: 'Spanish',
    label: 'Español',
    flagUrl: imageService.getFlagSrc('spain.svg'),
    videoStyle: VideoStyle.NO_VIDEOS,
  },
  {
    code: 'fr-ca',
    name: 'French',
    label: 'Français',
    flagUrl: imageService.getFlagSrc('canada.svg'),
    videoStyle: VideoStyle.FRENCH,
  },
  {
    code: 'fr-fr',
    name: 'French',
    label: 'Français',
    flagUrl: imageService.getFlagSrc('france.svg'),
    videoStyle: VideoStyle.NO_VIDEOS,
  },
  {
    code: 'nl',
    name: 'Dutch',
    label: 'Nederlands',
    flagUrl: imageService.getFlagSrc('netherlands.svg'),
    videoStyle: VideoStyle.NO_VIDEOS,
  },
  {
    code: 'pt',
    name: 'Portuguese',
    label: 'Português',
    flagUrl: imageService.getFlagSrc('brazil.svg'),
    videoStyle: VideoStyle.NO_VIDEOS,
  },
  {
    code: 'ru',
    name: 'Russian',
    label: 'Русский',
    flagUrl: imageService.getFlagSrc('russia.svg'),
    videoStyle: VideoStyle.NO_VIDEOS,
  },
  {
    code: 'sv',
    name: 'Swedish',
    label: 'Svenska',
    flagUrl: imageService.getFlagSrc('sweden.svg'),
    videoStyle: VideoStyle.NO_VIDEOS,
  },
  {
    code: 'nn',
    name: 'Norwegian Nynorsk',
    label: 'Norsk Nynorsk',
    flagUrl: imageService.getFlagSrc('norway.svg'),
    videoStyle: VideoStyle.NO_VIDEOS,
  },
  {
    code: 'nb-NO',
    name: 'Norwegian Bokmål',
    label: 'Norsk Bokmål',
    flagUrl: imageService.getFlagSrc('norway.svg'),
    videoStyle: VideoStyle.NO_VIDEOS,
  },
];
