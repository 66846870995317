import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { AdvertisingService } from '../advertising.service';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { BaseSectionComponent } from '../../section/base-section.component';
import { SnapshotService } from '../../snapshot.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';

@Component({
  selector: 'snap-advertising-retargeting-subsection',
  templateUrl: './advertising-retargeting-subsection.component.html',
  styleUrls: ['../advertising-section.component.scss', '../general.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: AdvertisingService,
    },
  ],
})
export class AdvertisingRetargetingSubsectionComponent
  extends BaseSectionComponent
  implements OnInit, AfterViewChecked
{
  @Input() showRetargeting = false;
  isRetargeting$: Observable<boolean> = new Observable<boolean>();

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: AdvertisingService,
    public snapshotService: SnapshotService,
    private cdRef: ChangeDetectorRef,
    public translateService: TranslateService,
    public snackbarService: SnackbarService,
  ) {
    super(service, snackbarService, translateService);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isRetargeting$ = this.service.data$.pipe(map((d) => d.isRetargeting));
  }
}
