import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { delay, filter, map, shareReplay, tap } from 'rxjs/operators';
import { GooglePlace } from '../objects';

export const SIMPLE_SEARCH = 'simple';
export const PROSPECT_SEARCH = 'prospect';
export type SearchType = 'simple' | 'prospect';

@Injectable()
export class GooglePlacesService {
  private searchType$$: BehaviorSubject<SearchType> = new BehaviorSubject<SearchType>(SIMPLE_SEARCH);
  private readonly searchType$ = this.searchType$$.asObservable();
  private readonly loading$$: Subject<boolean> = new Subject<boolean>();
  private readonly competitorsLoading$$: Subject<boolean> = new Subject<boolean>();
  private readonly noResults$$: Subject<boolean> = new Subject();
  private subscriptions: Subscription[] = [];
  private googleMap: google.maps.Map;
  private readonly googlePlaces$$: Subject<google.maps.places.PlaceResult[]> = new Subject();
  public googlePlaces$: Observable<GooglePlace[]>;
  private readonly competitors$$: Subject<google.maps.places.PlaceResult[]> = new Subject();
  public competitors$: Observable<GooglePlace[]>;
  public bounds$: Observable<google.maps.LatLngBounds>;
  public readonly loading$: Observable<boolean> = this.loading$$.asObservable();
  public readonly competitorsLoading$: Observable<boolean> = this.competitorsLoading$$.asObservable();

  private competitorSearch$$: BehaviorSubject<GooglePlace> = new BehaviorSubject<GooglePlace>(null);

  public noResults$: Observable<boolean> = this.noResults$$.asObservable();

  private placesService: google.maps.places.PlacesService;

  constructor(private readonly ngZone: NgZone) {
    this.googlePlaces$ = this.googlePlaces$$.pipe(
      filter((placeResults) => !!placeResults),
      tap(() => {
        this.loading$$.next(true);
        this.noResults$$.next(false);
      }),
      map((results) => results.map((r, i) => this.convertToGooglePlace(r, i)).filter((p) => !!p)),
      tap((places) => {
        this.loading$$.next(false);
        this.noResults$$.next(places.length === 0);
      }),
    );

    this.competitors$ = this.competitors$$.pipe(
      filter((placeResults) => !!placeResults),
      map((results) => {
        const startingIndex = 1;
        return results.map((r, i) => this.convertToGooglePlace(r, i + startingIndex)).filter((p) => !!p);
      }),
      tap(() => this.competitorsLoading$$.next(false)),
    );
    this.initBounds();
  }

  public init(
    googleMap: google.maps.Map,
    htmlElement: HTMLInputElement,
    searchType: SearchType,
    defaultBounds: google.maps.LatLngBounds = null,
  ): void {
    this.googleMap = googleMap;
    this.searchType$$.next(searchType || SIMPLE_SEARCH);

    this.placesService = new google.maps.places.PlacesService(googleMap);

    const search$ = this.searchType$.pipe(
      map((sType) => {
        switch (sType) {
          case SIMPLE_SEARCH:
            return new google.maps.places.Autocomplete(htmlElement, {
              bounds: defaultBounds,
              fields: [
                'address_components',
                'geometry',
                'icon',
                'name',
                'formatted_address',
                'place_id',
                'photos',
                'formatted_phone_number',
                'website',
                'types',
              ],
              types: ['establishment'],
            });
          case PROSPECT_SEARCH:
            return new google.maps.places.SearchBox(htmlElement, { bounds: defaultBounds });
          default:
            throw new Error('Trying to create invalid Google Maps search!');
        }
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.destroySubscriptions();
    this.initSearch(search$);
  }

  private initSearch(search$: Observable<google.maps.places.SearchBox | google.maps.places.Autocomplete>): void {
    this.subscriptions.push(
      search$
        .pipe(
          tap((searchEl) => {
            if (searchEl instanceof google.maps.places.SearchBox) {
              searchEl.addListener('places_changed', () => {
                this.ngZone.run(() => {
                  const places: google.maps.places.PlaceResult[] = searchEl.getPlaces();
                  this.setGooglePlaces(places);
                });
              });
            } else if (searchEl instanceof google.maps.places.Autocomplete) {
              searchEl.addListener('place_changed', () => {
                this.ngZone.run(() => {
                  const place: google.maps.places.PlaceResult = searchEl.getPlace();
                  this.setGooglePlaces([place]);
                });
              });
            }
          }),
        )
        .subscribe(),
    );

    this.subscriptions.push(
      combineLatest([search$, this.bounds$]).subscribe(([searchEl, bounds]) => {
        searchEl.setBounds(bounds);
      }),
    );

    this.subscriptions.push(
      combineLatest([this.bounds$, this.competitorSearch$$])
        .pipe(
          tap(() => this.competitors$$.next([])),
          tap(([, place]) => this.competitorsLoading$$.next(!!place)),
          map(([bounds, place]) => {
            if (place) {
              const filteredType = place.types?.filter(this.isNonGenericType)[0] || '';
              this.placesService.nearbySearch(
                { radius: 5000, location: bounds.getCenter(), type: filteredType },
                (results: google.maps.places.PlaceResult[]) => {
                  results = results
                    .filter((p) => p.place_id !== place.placeId)
                    .sort((p1, p2) => ((p1.rating || 0) > (p2.rating || 0) ? -1 : 1));
                  this.competitors$$.next(results);
                },
              );
            }
          }),
        )
        .subscribe(),
    );
  }

  public setGooglePlaces(places: google.maps.places.PlaceResult[]): void {
    this.googlePlaces$$.next(places);
  }

  private initBounds(): void {
    this.bounds$ = this.googlePlaces$.pipe(
      delay(100),
      map((places) => {
        const bounds = new google.maps.LatLngBounds();
        for (const place of places) {
          bounds.extend({ lat: place.lat, lng: place.lng });
        }
        this.googleMap.fitBounds(bounds);
        return bounds;
      }),
    );
  }

  setPlacesSearch(searchTerm: string): void {
    const searchRequest: google.maps.places.TextSearchRequest = {
      query: searchTerm,
    };
    this.placesService.textSearch(searchRequest, (results) => this.setGooglePlaces(results));
  }

  public setCompetitorSearch(place: GooglePlace): void {
    this.competitorSearch$$.next(place);
  }

  public convertToGooglePlace(place: google.maps.places.PlaceResult, index: number): GooglePlace {
    return GooglePlace.fromMapsResult(place, index);
  }

  public isNonGenericType(type: string): boolean {
    return !['point_of_interest', 'establishment'].some((genericType) => genericType === type);
  }

  private destroySubscriptions(): void {
    this.subscriptions.map((s) => s.unsubscribe());
    this.subscriptions = [];
  }
}
