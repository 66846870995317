import { ProjectionFilter as sdkProjectionFilter } from '@vendasta/account-group';

export class ProjectionFilter extends sdkProjectionFilter {
  public enableAll(): ProjectionFilter {
    this.accounts = true;
    this.listingDistribution = true;
    this.listingSyncPro = true;
    this.associations = true;
    this.accountGroupExternalIdentifiers = true;
    this.socialUrls = true;
    this.hoursOfOperation = true;
    this.contactDetails = true;
    this.snapshotReports = true;
    this.legacyProductDetails = true;
    this.richData = true;
    this.status = true;
    this.napData = true;
    this.health = true;
    this.additionalCompanyInfo = true;
    this.marketingInfo = true;
    return this;
  }
}
