import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatChipGrid, MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SessionService } from '@galaxy/core';
import {
  Contact,
  ContactsService,
  HowTheGradeIsCalculated,
  PhonePipe,
  promptTranslation,
  SnapshotReportService,
} from '@galaxy/snapshot';
import { TranslateService } from '@ngx-translate/core';
import { GalaxyAiIconService } from '@vendasta/galaxy/ai-icon';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { BasePersona, IAMService, PersonaType } from '@vendasta/iam';
import { switchWhenFalsy } from '@vendasta/rx-utils';
import {
  AIPromptInterface,
  ArtificialIntelligenceApiService,
  GenerateMessageRequestInterface,
  GlobalConfigEnabledSection,
  Role,
  SalesPerson,
} from '@vendasta/snapshot';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { RoutingService } from '../../app.routes';
import { ContactInterface, EmailInterface } from '../share-report.service';

export interface ShareReportDialogParams {
  snapshotName: string;
  companyName: string;
  edit: boolean;
  businessId: string;
  partnerId: string;
  marketId: string;
  salesPerson: SalesPerson;
  enabledSections: GlobalConfigEnabledSection[];
}

interface FormValues {
  sender: ContactInterface;
  recipients: ContactInterface[];
  email: EmailInterface;
}

type AiContentType = 'general' | 'section';

@Component({
  selector: 'app-share-report-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class ShareDialogComponent implements OnInit, OnDestroy {
  @ViewChild('chipList') chipList: MatChipGrid;
  public shareSnapshotForm: UntypedFormGroup;
  public salesPerson: SalesPerson;
  contacts$: Observable<Contact[]>;
  private subscriptions: Subscription[] = [];
  loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  readonly separatorKeys = [ENTER, COMMA] as const;
  aiIconService = inject(GalaxyAiIconService);
  aiContentType: AiContentType = 'general';
  aiSelectedSections = new FormControl<string[]>([]);
  enabledSections: string[];
  private readonly crmSubjectName$ = this.fetchSubjectName(PersonaType.crm_role);
  private readonly partnerSubjectName$ = this.fetchSubjectName(PersonaType.partner);
  private readonly salespersonSubjectName$ = this.fetchSubjectName(PersonaType.sales_person);

  readonly loadingAIMessage$$ = new BehaviorSubject<boolean>(false);

  constructor(
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShareReportDialogParams,
    private contactsService: ContactsService,
    private readonly aiService: ArtificialIntelligenceApiService,
    private readonly snackbarService: SnackbarService,
    private readonly snapshotReportService: SnapshotReportService,
    private readonly iamService: IAMService,
    private readonly sessionService: SessionService,
    private readonly routingService: RoutingService,
    private readonly router: Router,
  ) {}

  get recipientsArray(): UntypedFormArray {
    return <UntypedFormArray>this.shareSnapshotForm.get('recipients');
  }

  ngOnInit(): void {
    this.shareSnapshotForm = this.initForm();

    this.subscriptions.push(
      this.shareSnapshotForm
        .get('recipients')
        .statusChanges.subscribe((status) => (this.chipList.errorState = status === 'INVALID')),
    );

    this.setDefaultFormFieldValues();

    this.subscriptions.push(this.translateService.onLangChange.subscribe(() => this.setDefaultFormFieldValues()));

    this.contacts$ = this.contactsService
      .listContacts(this.data?.businessId, this.data?.partnerId, this.data?.marketId)
      .pipe(
        catchError(() => of([])),
        tap(() => this.loading$$.next(false)),
      );

    this.enabledSections = this.data.enabledSections?.map((section) => this.sectionEnumToI18N(section)) || [];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private initForm(): UntypedFormGroup {
    const fromField =
      this.data?.edit && this.data?.salesPerson
        ? this.data?.salesPerson?.firstName + ' ' + this.data?.salesPerson?.lastName
        : '';
    const replyTo = this.data?.edit ? this.data?.salesPerson?.email : '';
    let replyToValidators = [Validators.email];

    if (this.data?.edit) {
      replyToValidators = replyToValidators.concat(Validators.required);
    }

    return this.formBuilder.group({
      recipients: this.formBuilder.array([], [Validators.required, Validators.minLength(1)]),
      from: this.formBuilder.control(fromField, [Validators.required]),
      replyTo: this.formBuilder.control(replyTo, replyToValidators),
      subject: this.formBuilder.control('', [Validators.required]),
      message: this.formBuilder.control('', [Validators.required]),
    });
  }

  private fetchSubjectName(personaType: PersonaType): Observable<string> {
    return this.sessionService.getSessionId().pipe(
      distinctUntilChanged(),
      switchMap((sessionId) => this.iamService.getSubjectBySession(sessionId, personaType)),
      map((subject) => this.extractNameFromSubject(subject)),
      catchError(() => of('')),
      shareReplay(1),
    );
  }

  private getUsername(): Observable<string> {
    // return the Sender Name from the form if available
    const senderName = this.shareSnapshotForm.get('from').value;
    if (senderName) {
      return of(senderName);
    }
    // fetch user info to get the name
    return this.fetchCurrentUsername();
  }

  private fetchCurrentUsername(): Observable<string> {
    // fetch current user subjects to find the name following a prefered order
    // - old edit route will use salesperson as the primary subject
    // - new route (V2) will use partner as the primary subject
    const isOldRoute = this.routingService.isOldEditRoute?.(this.router.url);
    if (isOldRoute) {
      return this.salespersonSubjectName$.pipe(
        switchWhenFalsy(this.crmSubjectName$),
        switchWhenFalsy(this.partnerSubjectName$),
      );
    }
    return this.partnerSubjectName$.pipe(
      switchWhenFalsy(this.salespersonSubjectName$),
      switchWhenFalsy(this.crmSubjectName$),
    );
  }

  private extractNameFromSubject(subject: BasePersona): string {
    return [subject?.firstName, subject?.lastName].filter(Boolean).join(' ') ?? '';
  }

  private setDefaultFormFieldValues(): void {
    const message = this.shareSnapshotForm.get('message');
    const subject = this.shareSnapshotForm.get('subject');

    if (!subject?.dirty) {
      subject?.patchValue(
        this.translateService.instant('SHARE_SNAPSHOT.EMAIL.SUBJECT', { companyName: this.data.companyName }),
      );
    }

    if (!message?.dirty) {
      if (this.data?.edit) {
        if (this.data.salesPerson) {
          const salesPerson = this.data.salesPerson;
          message?.patchValue(
            this.translateService.instant('SHARE_SNAPSHOT.EMAIL.DIALOG_SALES_PERSON_MESSAGE', {
              snapshotName: this.translateService.instant(this.data.snapshotName),
              salesPersonName: salesPerson.firstName + ' ' + salesPerson.lastName,
              salesPersonJobPosition: salesPerson.jobTitle ? ' ' + salesPerson.jobTitle : '',
              salesPersonEmail: salesPerson.email || '',
              salesPersonPhoneNumber: salesPerson.phoneNumbers?.[0]
                ? this.translateService.instant('SHARE_SNAPSHOT.EMAIL.OR_CALL') +
                  ' ' +
                  new PhonePipe().transform(salesPerson.phoneNumbers[0], salesPerson.country || 'US')
                : '',
            }),
          );
        } else {
          message?.patchValue(
            this.translateService.instant('SHARE_SNAPSHOT.EMAIL.DIALOG_NO_SALES_PERSON_DATA_MESSAGE', {
              snapshotName: this.translateService.instant(this.data.snapshotName),
            }),
          );
        }
      } else {
        message?.patchValue(this.translateService.instant('SHARE_SNAPSHOT.EMAIL.MESSAGE'));
      }
    }
  }

  forceValidation(f: UntypedFormGroup): void {
    const form = f;
    form.markAllAsTouched();
    this.shareSnapshotForm.setValue(form.value);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.forceValidation(this.shareSnapshotForm);
    if (!this.shareSnapshotForm.valid) {
      return;
    }
    const values = this.processForm(this.shareSnapshotForm.value);
    this.dialogRef.close(values);
  }

  addRecipient(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (!this.isContactListFull() && value && !this.isContactAdded(value)) {
      this.recipientsArray.push(
        this.formBuilder.group({
          name: '',
          email: new UntypedFormControl(value, [Validators.required, Validators.email]),
        }),
      );
      this.recipientsArray.updateValueAndValidity();
    }

    event.chipInput.clear();
  }

  isContactAdded(value: string): boolean {
    const isFound = this.recipientsArray.getRawValue().some((element) => {
      return element.email === value;
    });
    return isFound;
  }

  isContactListFull(): boolean {
    return this.recipientsArray.length >= 5;
  }

  addContact(contact: Contact): void {
    if (!this.isContactListFull() && !this.isContactAdded(contact.email)) {
      this.recipientsArray.push(
        this.formBuilder.group({
          name: new UntypedFormControl(contact.name),
          email: new UntypedFormControl(contact.email, [Validators.required, Validators.email]),
        }),
      );
      this.recipientsArray.updateValueAndValidity();
    }
  }

  removeRecipient(index: number): void {
    if (index >= 0) {
      this.recipientsArray.removeAt(index);
    }
  }

  private processForm(formValue: any): FormValues {
    const sender = <ContactInterface>{
      fullName: formValue.from,
      email: formValue.replyTo,
    };

    const recipients: ContactInterface[] = [];
    formValue.recipients.forEach((contact: Contact) => {
      const recipient = <ContactInterface>{
        fullName: contact.name,
        email: contact.email,
      };
      recipients.push(recipient);
    });

    const emailItems = <EmailInterface>{
      body: formValue.message,
      subject: formValue.subject,
    };
    return {
      sender: sender,
      recipients: recipients,
      email: emailItems,
    };
  }

  private sectionEnumValueToKey(section: GlobalConfigEnabledSection): string {
    return Object.keys(GlobalConfigEnabledSection)[Object.values(GlobalConfigEnabledSection).indexOf(section)];
  }

  private sectionEnumToI18N(section: GlobalConfigEnabledSection): string {
    const sectionName = this.sectionEnumValueToKey(section);
    return `TITLES.${sectionName}`;
  }

  private trimLines(text: string): string {
    return text
      .split('\n')
      .map((line) => line.trim())
      .filter(Boolean)
      .join('\n');
  }

  private generateAiSetupPrompt(): AIPromptInterface {
    const result = 'Assume the role as a marketing consultant with expertise in Digital Marketing';
    return {
      role: Role.ROLE_SYSTEM,
      prompt: result,
    } as AIPromptInterface;
  }

  private generateAiHowGradeWorksPrompt(): AIPromptInterface {
    const result = this.trimLines(HowTheGradeIsCalculated);
    return {
      role: Role.ROLE_ASSISTANT,
      prompt: result,
    } as AIPromptInterface;
  }

  private async generateAiBusinessDetailsPrompt(): Promise<AIPromptInterface> {
    // business data
    const businessDetails$ = combineLatest([
      this.snapshotReportService.snapshotData$,
      this.snapshotReportService.overallScore$,
    ]).pipe(
      map(([snapshotData, overallScore]) => {
        const addressFragments: string[] = [snapshotData?.city, snapshotData?.state];
        if (!snapshotData?.serviceAreaBusiness) {
          addressFragments.unshift(snapshotData?.address);
        }
        const address = addressFragments.filter(Boolean).join(', ');
        return `
          Now here's the result of the client's report:
          Business name: ${snapshotData?.companyName}
          Location: ${address}
          Overall Score: ${overallScore?.score}%
        `;
      }),
    );
    const result = await firstValueFrom(businessDetails$);
    return {
      role: Role.ROLE_ASSISTANT,
      prompt: this.trimLines(result),
    } as AIPromptInterface;
  }

  private async generateAiSectionsPrompt(): Promise<AIPromptInterface[]> {
    // get every section details from the services
    const sectionDetailsFetchers$: Observable<string>[] = [];
    this.snapshotReportService.sectionServices.forEach((sectionService) => {
      const details$ = sectionService.getAiPromptDetails?.();
      if (details$) {
        sectionDetailsFetchers$.push(details$);
      }
    });
    const sectionDetails$ = combineLatest([...sectionDetailsFetchers$]).pipe(
      map((details) => details.filter(Boolean).map(this.trimLines)),
      map((details) => details.map((prompt) => ({ role: Role.ROLE_ASSISTANT, prompt }) as AIPromptInterface)),
    );
    return [...(await firstValueFrom(sectionDetails$))];
  }

  private async generateAiActionPrompt(): Promise<AIPromptInterface> {
    const whatToDo = `
      Now, help me generate an email below 125 words, in the message, try to make it personalized to the business and seems like this is tailored to them.
      Explain in grade-five reading style what this means for the business and how it negatively impacts their overall digital presence.
      Conclude by how my agency can assist the customer resolving this issue. You don't need to mention all the aspects, just highlight a single one that you think could sparks the recipient's interests and could mention that the other results are available in the report.
      This is important that the email is short and sweet and to the point that will make the recipient want to take a closer look at the report.
      Make sure the tone is friendly.
    `;
    const locale = await firstValueFrom(this.snapshotReportService.locale$);
    const translation = promptTranslation(locale);
    let prompt = whatToDo.concat(translation);

    if (this.aiContentType === 'section') {
      const sections = this.aiSelectedSections.value;
      if (sections.length > 0) {
        const sectionNames = sections.map((section) => this.translateService.instant(section)).join(', ');
        const sectionNamesPrompt = `\nFocus the email on the following sections: ${sectionNames}.`;
        prompt = prompt.concat(sectionNamesPrompt);
      }
    }
    const name = await firstValueFrom(this.getUsername());
    if (name) {
      prompt = prompt + `\nSign the email with the name: ${name}`;
    }

    return {
      role: Role.ROLE_ASSISTANT,
      prompt: this.trimLines(prompt),
    } as AIPromptInterface;
  }

  onSectionSelectionOpenedChange(opened: boolean) {
    if (!opened && this.aiSelectedSections.value?.length > 0) {
      this.aiGenerate();
    }
  }

  onAIGenerateClick(): void {
    // ensure we are generating the email without section-specific content
    this.aiContentType = 'general';
    this.aiGenerate();
  }

  async aiGenerate(): Promise<void> {
    try {
      this.loadingAIMessage$$.next(true);
      const aiSetup = this.generateAiSetupPrompt();
      const aiGradeExplanation = this.generateAiHowGradeWorksPrompt();
      const aiBusiness = await this.generateAiBusinessDetailsPrompt();
      const aiSections = await this.generateAiSectionsPrompt();
      const aiAction = await this.generateAiActionPrompt();
      const prompt: GenerateMessageRequestInterface = {
        snapshotId: this.snapshotReportService.snapshotId,
        prompts: [aiSetup, aiGradeExplanation, aiBusiness, ...aiSections, aiAction],
      };
      const req = this.aiService.generateMessage(prompt).pipe(
        map((resp) => resp?.content || ''),
        catchError(() => of('')),
      );
      const aiResponse = await firstValueFrom(req);
      if (aiResponse !== '') {
        this.shareSnapshotForm.get('message').patchValue(aiResponse);
      } else {
        this.snackbarService.openErrorSnack('SHARE_SNAPSHOT.AI_ACTIONS.FAILED');
      }
    } finally {
      this.loadingAIMessage$$.next(false);
    }
  }
}
