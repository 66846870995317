<mat-table [dataSource]="listings">
  <ng-container matColumnDef="source">
    <mat-header-cell *matHeaderCellDef>
      {{ 'LISTINGS.SCAN.SITE' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let listing">
      <div class="source flex align-start-center">
        <img class="source-icon" [src]="listing.sourceIconUrl" />
        <div class="flex-column">
          <span class="source-name">{{ listing.sourceName }}</span>
          <a *ngIf="listing.url" target="_blank" class="view-listing" [href]="listing.url">
            {{ 'LISTINGS.SCAN.VIEW_LISTING' | translate }}
          </a>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="companyName">
    <mat-header-cell *matHeaderCellDef>
      {{ 'BUSINESS_DETAILS.NAME' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let listing">
      <ng-container *ngIf="!!listing.companyName; else missing">
        <span [ngClass]="{ incorrect: !listing.companyNameMatch }">
          {{ listing.companyName }}
        </span>
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="address">
    <mat-header-cell *matHeaderCellDef>
      {{ 'BUSINESS_DETAILS.ADDRESS' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let listing">
      <ng-container *ngIf="!!listing.address || !!listing.city || !!listing.state || !!listing.zipCode; else missing">
        <span [ngClass]="{ incorrect: !listing.addressMatch }">
          {{ listing.address }}
          <br />
          {{ listing.city }}, {{ listing.state }} {{ listing.zipCode }}
        </span>
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="phone">
    <mat-header-cell *matHeaderCellDef>
      {{ 'BUSINESS_DETAILS.PHONE_NUMBER' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let listing">
      <ng-container *ngIf="!!listing.phone; else missing">
        <span [ngClass]="{ incorrect: !listing.phoneMatch }">
          {{ listing.phone }}
        </span>
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>
      {{ 'SNAPSHOT_LITE.STATUS' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let listing">
      <ng-container *ngTemplateOutlet="statusBadge; context: { $implicit: listing }"></ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="listing">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let listing">
      <div *ngIf="listing.status !== LISTING_STATUS.NOT_FOUND; else notFound" class="full-listing flex-column">
        <span [ngClass]="{ incorrect: !listing.companyNameMatch }">
          {{ listing.companyName }}
        </span>
        <span [ngClass]="{ incorrect: !listing.addressMatch }">
          {{ listing.address }}
          <br />
          {{ listing.city }}, {{ listing.state }} {{ listing.zipCode }}
        </span>
        <span [ngClass]="{ incorrect: !listing.phoneMatch }">
          {{ listing.phone }}
        </span>
        <ng-container *ngTemplateOutlet="statusBadge; context: { $implicit: listing }"></ng-container>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns$ | async"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: displayedColumns$ | async; let isLast = last"
    [ngClass]="{ last: isLast }"
  ></mat-row>
</mat-table>

<ng-template #missing>
  <span class="missing">{{ 'LISTINGS.SCAN.MISSING' | translate }}</span>
</ng-template>

<ng-template #notFound>
  <va-badge color="warn">Not Found</va-badge>
</ng-template>

<ng-template #statusBadge let-listing>
  <va-badge color="positive" *ngIf="listing.status === LISTING_STATUS.ACCURATE">Appears Correct</va-badge>
  <va-badge color="warn" *ngIf="listing.status === LISTING_STATUS.INACCURATE">
    <span *ngIf="listing.companyNameError || listing.addressError || listing.phoneError">Errors Found</span>
    <span *ngIf="!listing.companyNameError && !listing.addressError && !listing.phoneError">Missing Data</span>
  </va-badge>
  <va-badge color="warn" *ngIf="listing.status === LISTING_STATUS.NOT_FOUND">Not Found</va-badge>
</ng-template>
