<!--  TODO: TURN THIS BACK ON WHEN ADWORDS STARTS WORKING AGAIN -->
<ng-container *ngIf="(service.data$ | async)?.adwordsData.stillWorking === false">
  <snap-subsection
    *ngIf="(snapshotService.snapshotData$ | async)?.country === 'US' || edit"
    heading="{{ 'ADVERTISING.ADWORDS.HEADING' | translate }}"
    subheading="{{ 'ADVERTISING.ADWORDS.SUBHEADING' | translate }}"
    [edit]="edit"
    [hideControl]="controls.controls['hideAdwordsSection']"
  >
    <ng-container *ngIf="!(service.data$ | async)?.adwordsData.stillWorking; else stillWorkingTemplate">
      <div
        class="adwords-wrapper"
        *ngIf="
          (
            (service.data$ | async)?.adwordsData.clicks.length > 0 &&
              (service.data$ | async)?.adwordsData.impressions.length > 0 &&
              snapshotService.snapshotData$ | async
          )?.country === 'US';
          else adwordsNotVisibleTemplate
        "
      >
        <div class="blurb">
          {{ 'ADVERTISING.ADWORDS.DESCRIPTION' | translate }}
        </div>
        <div class="adwords-impressions flex align-space-evenly-center">
          <div class="chart-summary flex-column align-center-center">
            <div>{{ possibleImpressions$ | async | metricNotation }}</div>
            <h2>
              {{ 'ADVERTISING.ADWORDS.POSSIBLE_IMPRESSIONS' | translate }}
            </h2>
            <span>
              {{ 'ADVERTISING.ADWORDS.POSSIBLE_IMPRESSIONS_DESCRIPTION' | translate }}
            </span>
          </div>
          <div class="chart flex adwords-chart">
            <google-chart *ngIf="impressions$ | async as impressions" [data]="impressions"></google-chart>
          </div>
        </div>
        <div class="adwords-clicks flex align-space-evenly-center">
          <div class="chart-summary flex-column align-center-center">
            <div>{{ possibleClicks$ | async | metricNotation }}</div>
            <h2>{{ 'ADVERTISING.ADWORDS.POSSIBLE_CLICKS' | translate }}</h2>
            <span>
              {{ 'ADVERTISING.ADWORDS.POSSIBLE_CLICKS_DESCRIPTION' | translate }}
            </span>
          </div>
          <div class="chart flex adwords-chart">
            <google-chart *ngIf="clicks$ | async as clicks" id="adwords-clicks-chart" [data]="clicks"></google-chart>
          </div>
        </div>
        <div class="adwords-footer">
          {{ 'ADVERTISING.ADWORDS.FOOTNOTE' | translate }}
        </div>
      </div>
    </ng-container>
  </snap-subsection>
</ng-container>

<ng-template #stillWorkingTemplate>
  <snap-still-working></snap-still-working>
</ng-template>

<ng-template #adwordsNotVisibleTemplate>
  <div class="not-found">
    <glxy-empty-state [size]="'small'" [widthConstraint]="false">
      <glxy-empty-state-hero>
        <mat-icon>travel_explore</mat-icon>
      </glxy-empty-state-hero>
      <glxy-empty-state-title>
        {{ 'ADVERTISING.ADWORDS.NOT_AVAILABLE' | translate }}
      </glxy-empty-state-title>
      <ng-container
        *ngIf="
          adwordsNotVisibleMessage(
            (snapshotService.snapshotData$ | async)?.country,
            (snapshotService.snapshotData$ | async)?.website
          ) as messageId
        "
      >
        <p>{{ messageId | translate }}</p>
      </ng-container>
    </glxy-empty-state>
  </div>
</ng-template>
