interface MailingConfigurationInterface {
  mailingCompanyName?: string;
  mailingAddress?: string;
  mailingAddress2?: string;
  mailingCity?: string;
  mailingState?: string;
  mailingCountry?: string;
  mailingPostalCode?: string;
  mailingPhoneNumber?: string;
  mailingWebsiteAddress?: string;
  mailingEmailAddress?: string;
}

export class MailingConfiguration {
  mailingCompanyName: string;
  mailingAddress: string;
  mailingAddress2: string;
  mailingCity: string;
  mailingState: string;
  mailingCountry: string;
  mailingPostalCode: string;
  mailingPhoneNumber: string;
  mailingWebsiteAddress: string;
  mailingEmailAddress: string;

  constructor(d: MailingConfigurationInterface) {
    this.mailingCompanyName = d.mailingCompanyName || '';
    this.mailingAddress = d.mailingAddress || '';
    this.mailingAddress2 = d.mailingAddress2 || '';
    this.mailingCity = d.mailingCity || '';
    this.mailingState = d.mailingState || '';
    this.mailingCountry = d.mailingCountry || 'US';
    this.mailingPostalCode = d.mailingPostalCode || '';
    this.mailingPhoneNumber = d.mailingPhoneNumber || '';
    this.mailingWebsiteAddress = d.mailingWebsiteAddress || '';
    this.mailingEmailAddress = d.mailingEmailAddress || '';
  }
}

interface WhitelabelConfigurationInterface {
  showPpc?: boolean;
  showRemarketing?: boolean;
  useCustomSnapshotHeader?: boolean;
  customSnapshotHeader?: string;
  useCustomSnapshotFooter?: boolean;
  customSnapshotFooter?: string;
  useCustomSnapshotPopupMessage?: boolean;
  customSnapshotPopupMessage?: string;
  allowClaimUser?: boolean;
  mailingConfiguration: MailingConfigurationInterface;
  snapshotReportName?: string;
  subscriptionTierId?: string;
}

export class WhitelabelConfiguration {
  showPpc: boolean;
  showRemarketing: boolean;
  useCustomSnapshotHeader: boolean;
  customSnapshotHeader: string;
  useCustomSnapshotFooter: boolean;
  customSnapshotFooter: string;
  useCustomSnapshotPopupMessage: boolean;
  customSnapshotPopupMessage: string;
  allowClaimUser: boolean;
  mailingConfiguration: MailingConfiguration;
  snapshotReportName: string;
  subscriptionTierId: string;

  constructor(d: WhitelabelConfigurationInterface) {
    this.showPpc = d.showPpc || false;
    this.showRemarketing = d.showRemarketing || false;
    this.useCustomSnapshotHeader = d.useCustomSnapshotHeader || false;
    this.customSnapshotHeader = d.customSnapshotHeader || '';
    this.useCustomSnapshotFooter = d.useCustomSnapshotFooter || false;
    this.customSnapshotFooter = d.customSnapshotFooter || '';
    this.useCustomSnapshotPopupMessage = d.useCustomSnapshotPopupMessage || false;
    this.customSnapshotPopupMessage = d.customSnapshotPopupMessage || '';
    this.allowClaimUser = d.allowClaimUser || false;
    this.mailingConfiguration = new MailingConfiguration(d.mailingConfiguration);
    this.snapshotReportName = d.snapshotReportName || '';
    this.subscriptionTierId = d.subscriptionTierId || '';
  }
}
