import { ContentInterface } from '../section/section';

export class EcommerceContent {
  videoId: string;
  messageId: string;
  footerMessageId: string;

  constructor(content: ContentInterface) {
    this.videoId = content.videoId;
    this.messageId = content.messageId;
    this.footerMessageId = content.footerMessageId;
  }
}
