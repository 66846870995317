<mat-card-content>
  <div class="prompt-container">
    <hr />
    <div class="prompt">
      {{
        'COMPETITORS.KEYWORD_COMPETITORS.DESCRIPTION'
          | translate : { snapshotReportsLabel: ('TITLES.SNAPSHOT_REPORTS' | translate) }
      }}
    </div>
  </div>

  <form [formGroup]="competitorsArray">
    <ng-container *ngFor="let c of competitorsArray.controls; let i = index; let last = last">
      <div class="competitor-container">
        <div class="mobile-inputs" *ngIf="media.mobile$ | async; else desktopInput">
          <ng-container *ngTemplateOutlet="inputFields; context: { control: c, index: i }"></ng-container>
        </div>
        <ng-template class="desktop-inputs" #desktopInput>
          <ng-container
            class="competitor-inputs"
            *ngTemplateOutlet="inputFields; context: { control: c, index: i }"
          ></ng-container>
        </ng-template>
        <div class="mobile-buttons" *ngIf="media.mobile$ | async; else desktopButtonsWrapper">
          <ng-container *ngTemplateOutlet="mobileButtons; context: { control: c, index: i }"></ng-container>
        </div>
        <ng-template class="desktop-buttons" #desktopButtonsWrapper>
          <ng-container *ngTemplateOutlet="desktopButtons; context: { control: c, index: i }"></ng-container>
        </ng-template>
      </div>
      <button
        mat-button
        color="primary"
        class="add-competitor"
        *ngIf="last && competitorsArray.controls.length < maxCompetitors"
        (click)="addCompetitor(false)"
      >
        {{ 'COMPETITORS.ACCOUNT_GROUP.ADD_COMPETITOR' | translate }}
      </button>
    </ng-container>
    <!-- map object to create the google search autocomplete field  -->
    <div class="hidden">
      <google-map (mapInitialized)="onMapReady($event)"></google-map>
    </div>
  </form>
</mat-card-content>

<ng-template #mobileButtons let-control="control" let-index="index">
  <div *ngIf="control.disabled">
    <button mat-icon-button color="primary" (click)="editCompetitor(index, control)">
      <mat-icon>mode_edit</mat-icon>
    </button>
  </div>
  <div *ngIf="control.enabled">
    <button
      mat-icon-button
      (click)="saveCompetitor(index, control)"
      [disabled]="control.get('name').invalid || control.get('url').invalid"
    >
      <span [ngClass]="{ loading: indexLoading === index }">
        <mat-icon color="primary">check</mat-icon>
      </span>
      <div class="spinner-container" *ngIf="indexLoading === index">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
    </button>
  </div>
  <div *ngIf="control.enabled">
    <button mat-icon-button color="warn" (click)="cancel(index)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="control.disabled">
    <button mat-icon-button color="warn" (click)="deleteCompetitor(index, control)">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #desktopButtons let-control="control" let-index="index">
  <div *ngIf="control.disabled">
    <button mat-icon-button color="primary" (click)="editCompetitor(index, control)">
      <mat-icon>mode_edit</mat-icon>
    </button>
  </div>
  <div *ngIf="control.enabled">
    <button
      mat-button
      color="primary"
      (click)="saveCompetitor(index, control)"
      [disabled]="control.get('name').invalid || control.get('url').invalid"
    >
      <span [ngClass]="{ loading: indexLoading === index }">
        {{ 'COMMON.SAVE' | translate }}
      </span>
      <div class="spinner-container" *ngIf="indexLoading === index">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
    </button>
  </div>
  <div *ngIf="control.enabled">
    <button mat-button (click)="cancel(index)">
      {{ 'COMMON.CANCEL' | translate }}
    </button>
  </div>
  <div *ngIf="control.disabled">
    <button mat-icon-button color="warn" (click)="deleteCompetitor(index, control)">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #inputFields let-control="control" let-index="index">
  <mat-form-field>
    <mat-label>
      {{ 'COMPETITORS.ACCOUNT_GROUP.COMPETITOR_NAME' | translate }}
    </mat-label>
    <input
      #search
      matInput
      [formControl]="control.get('name')"
      (focus)="onFocusInputSearch(index)"
      autocorrect="off"
      placeholder=""
      autocapitalize="off"
      spellcheck="off"
    />
    <mat-error
      *ngIf="control.get('name').invalid && (control.get('name').dirty || control.get('name').touched)"
      class="alert alert-danger"
    >
      <span *ngIf="control.get('name').errors.required">
        {{ 'COMPETITORS.ACCOUNT_GROUP.COMPETITOR_NAME_REQUIRED' | translate }}
      </span>
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>
      {{ 'COMPETITORS.ACCOUNT_GROUP.COMPETITOR_URL' | translate }}
    </mat-label>
    <input matInput [formControl]="control.get('url')" type="url" [pattern]="urlPattern" required />
    <mat-error
      *ngIf="control.get('url').invalid && (control.get('url').dirty || control.get('url').touched)"
      class="alert alert-danger"
    >
      <span *ngIf="control.get('url').errors.required || control.get('url').errors.pattern">
        {{ 'VALIDATORS.REQUIRED' | translate }}
      </span>
    </mat-error>
  </mat-form-field>
</ng-template>
