import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Contact, ContactsService } from '@galaxy/snapshot';

const crankwheelOpenUrl = `https://crankwheel.com/install#crankwheelmagicshowui:mainandclose`;

export interface ScreenshareParams {
  businessId: string;
  partnerId: string;
  marketId: string;
}

@Component({
  selector: 'app-screenshare-dialog',
  templateUrl: './screenshare-dialog.component.html',
  styleUrls: ['./screenshare-dialog.component.scss'],
})
export class ScreenshareDialogComponent implements OnInit {
  contacts$: Observable<Contact[]>;
  selectedContact = new UntypedFormControl();
  crankwheelUrl$: Observable<string>;
  loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private contactsService: ContactsService, @Inject(MAT_DIALOG_DATA) public data: ScreenshareParams) {}

  ngOnInit(): void {
    this.contacts$ = this.contactsService
      .listContacts(this.data.businessId, this.data.partnerId, this.data.marketId)
      .pipe(tap(() => this.loading$$.next(false)));

    this.crankwheelUrl$ = this.selectedContact.valueChanges.pipe(
      startWith({} as Contact),
      map((contact) => {
        let url = crankwheelOpenUrl;
        if (contact && (contact.email || contact.phoneNumber)) {
          const email = contact.email || '';
          const phoneNumber = contact.phoneNumber || '';
          url += `:extraparams:emailaddress=${email}&countrycode=1&phonenumber=${phoneNumber}`;
        }
        return url;
      }),
    );
  }
}
