import { Component, EventEmitter, Output } from '@angular/core';
import { Language, SnapshotReportService, SnapshotLanguages } from '@galaxy/snapshot';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  @Output() languageSelected = new EventEmitter<Language>();

  languageOptions = SnapshotLanguages;

  constructor(public snapshotService: SnapshotReportService) {}

  selectLanguage(language: Language): void {
    this.languageSelected.emit(language);
  }
}
