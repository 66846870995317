<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>{{ 'BOOKED_MEETINGS.TITLE' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <ng-container *ngIf="isPartnerConfig || (isHostConfigured$ | async)">
        <snap-item-toggle
          [hideControl]="hideControl"
          label="{{ 'BOOKED_MEETINGS.LABEL' | translate }}"
        ></snap-item-toggle>
      </ng-container>
      <ng-container *ngIf="isPartnerConfig === false && (isHostConfigured$ | async) === false">
        <button mat-flat-button color="primary" (click)="redirectToMeetingsSettings()">
          {{ 'BOOKED_MEETINGS.SET_UP_MEETING_SCHEDULER' | translate }}
        </button>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
