export interface ReviewCompetitorInterface {
  snapshotId: string;
  companyName: string;
  reviewsFound?: number;
  reviewsFoundPerMonth?: number;
  averageReviewScore?: number;
  numberOfReviewSources?: number;
  updated: Date;
}

export class ReviewCompetitor {
  snapshotId: string;
  companyName: string;
  reviewsFound: number;
  reviewsFoundPerMonth: number;
  averageReviewScore: number;
  numberOfReviewSources: number;
  updated: Date;

  constructor(data: ReviewCompetitorInterface) {
    this.snapshotId = data.snapshotId;
    this.companyName = data.companyName;
    this.reviewsFound = data.reviewsFound || 0;
    this.reviewsFoundPerMonth = data.reviewsFoundPerMonth || 0;
    this.averageReviewScore = data.averageReviewScore || 0;
    this.numberOfReviewSources = data.numberOfReviewSources || 0;
    this.updated = data.updated;
  }
}
