import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

type PreviewState = 'showAll' | 'showMobile' | 'showDesktop' | 'showNone';

@Component({
  selector: 'snap-website-preview',
  templateUrl: './website-preview.component.html',
  styleUrls: ['./website-preview.component.scss'],
})
export class WebsitePreviewComponent implements OnChanges {
  @Input() showMobile: boolean;
  @Input() showDesktop: boolean;
  @Input() mobileScreenshot: string;
  @Input() desktopScreenshot: string;
  @Input() isMobileFriendly: boolean;

  previewState: PreviewState;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showMobile || changes.showDesktop) {
      this.setPreviewState();
    }
  }

  private setPreviewState(): void {
    if (this.showMobile && this.showDesktop) {
      this.previewState =
        !!this.mobileScreenshot && !!this.desktopScreenshot
          ? 'showAll'
          : this.mobileScreenshot
          ? 'showMobile'
          : this.desktopScreenshot
          ? 'showDesktop'
          : 'showNone';
    }

    if (this.showMobile && !this.showDesktop) {
      this.previewState = this.mobileScreenshot ? 'showMobile' : 'showNone';
    }

    if (!this.showMobile && this.showDesktop) {
      this.previewState = this.desktopScreenshot ? 'showDesktop' : 'showNone';
    }

    if (!this.showMobile && !this.showDesktop) {
      this.previewState = 'showNone';
    }
  }
}
