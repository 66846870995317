const COUNTRY_KEY = 'country';
const REGION_KEY = 'administrative_area_level_1';
const SHORT_NAME_KEY = 'short_name';
const LONG_NAME_KEY = 'long_name';

export class GooglePlace {
  name: string;
  formattedAddress: string;
  shortAddress: string;
  placeId: string;
  lat: number;
  lng: number;
  markerLabel?: string;
  photo?: string;
  phoneNumber?: string;
  website?: string;
  country?: string;
  countryShort?: string;
  region?: string;
  regionShort: string;
  types?: string[];
  reviewScore?: number;
  reviewCount?: number;

  static fromMapsResult(place: google.maps.places.PlaceResult, index: number): GooglePlace {
    const countryInfo = this.getAddressComponent(place.address_components, COUNTRY_KEY);
    const regionInfo = this.getAddressComponent(place.address_components, REGION_KEY);
    if (!place.geometry) {
      return null;
    }
    return {
      name: place.name,
      formattedAddress: place.formatted_address ? place.formatted_address : place.vicinity,
      shortAddress: this.getShortAddress(place.formatted_address),
      placeId: place.place_id,
      lat: place.geometry.location?.lat(),
      lng: place.geometry.location?.lng(),
      markerLabel: String.fromCharCode('A'.charCodeAt(0) + index),
      photo: this.getPhoto(place.photos),
      phoneNumber: place.formatted_phone_number,
      website: place.website,
      country: LONG_NAME_KEY in countryInfo ? countryInfo[LONG_NAME_KEY] : null,
      countryShort: SHORT_NAME_KEY in countryInfo ? countryInfo[SHORT_NAME_KEY] : null,
      region: LONG_NAME_KEY in regionInfo ? regionInfo[LONG_NAME_KEY] : null,
      regionShort: SHORT_NAME_KEY in regionInfo ? regionInfo[SHORT_NAME_KEY] : null,
      types: place.types,
      reviewCount: place.user_ratings_total,
      reviewScore: place.rating,
    } as GooglePlace;
  }

  private static getAddressComponent(
    addressComponents: google.maps.GeocoderAddressComponent[],
    type: string,
  ): google.maps.GeocoderAddressComponent {
    if (addressComponents) {
      for (const component of addressComponents) {
        if (component['types'].indexOf(type) > -1) {
          return component;
        }
      }
    }
    return {} as google.maps.GeocoderAddressComponent;
  }

  private static getShortAddress(address: string): string {
    if (!address) {
      return '';
    }
    let addressComponents = address.split(',').map((a) => a.trim());
    const sliceLength = addressComponents.length - 2;
    addressComponents = addressComponents.slice(0, sliceLength > 2 ? sliceLength : 2);
    return addressComponents.join(', ');
  }

  private static getPhoto(photos: google.maps.places.PlacePhoto[]): string {
    return photos && photos.length > 0 ? photos[0].getUrl({ maxWidth: null, maxHeight: 150 }) : '';
  }
}
