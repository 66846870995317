<snap-subsection
  heading="{{ 'REVIEWS.FOUND.HEADING' | translate }}"
  subheading="{{ 'REVIEWS.FOUND.SUBHEADING' | translate }}"
>
  <ng-container *ngIf="media.mobile$ | async; else desktopView">
    <ng-container *ngIf="reviewCompetitorTableItems$ | async as items">
      <ng-container *ngIf="items.length > 0 && items[0].competitors?.length > 0; else industryMobileView">
        <snap-mobile-view-data
          data-testid="mobile-reviews-data"
          [hideGrades]="snapshotService.hideSubGrades$ | async"
          [edit]="edit"
          [subsections]="items"
        ></snap-mobile-view-data>
      </ng-container>
    </ng-container>
    <ng-template #industryMobileView>
      <snap-mobile-view-data
        [hideGrades]="snapshotService.hideSubGrades$ | async"
        [edit]="edit"
        [subsections]="reviewTableItems$ | async"
      ></snap-mobile-view-data>
    </ng-template>
  </ng-container>
  <ng-template #desktopView>
    <ng-container *ngIf="reviewCompetitorTableItems$ | async as items">
      <ng-container *ngIf="items.length > 0 && items[0].competitors?.length > 0; else appGradeTable">
        <snap-table
          [items]="items"
          [editMode]="edit"
          [hideGrades]="snapshotService.hideSubGrades$ | async"
          [showIndustryAverage]="true"
          [colorTextYourBusiness]="true"
        ></snap-table>
      </ng-container>
    </ng-container>
    <ng-template #appGradeTable>
      <snap-table
        [items]="reviewTableItems$ | async"
        [editMode]="edit"
        [hideGrades]="snapshotService.hideSubGrades$ | async"
        [showIndustryLeader]="true"
        [showIndustryAverage]="true"
        [colorTextYourBusiness]="true"
      ></snap-table>
    </ng-template>
  </ng-template>
</snap-subsection>
