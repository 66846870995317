import {
  AdditionalCompanyInfo,
  ClosedStatus,
  GooglePlaceInterface,
  HealthCareProfessionalInformationGender,
  IsAvailable,
  LifecycleStage,
  ListingSyncProBillingFrequency,
  ListingSyncProServiceProviders,
  MarketingInfo,
  RichDataPaymentMethods,
  ServiceAreaBusinessType,
  ServiceAreaInterface,
} from '@vendasta/account-group';
import { enumStringToValue } from './utils/proto-utils';
export { AdditionalCompanyInfo, MarketingInfo } from '@vendasta/account-group';

export class GooglePlace implements GooglePlaceInterface {
  placeId: string;
  placeName: string;
  city: string;

  static fromProto(proto: any): GooglePlace {
    let m = new GooglePlace();
    m = Object.assign(m, proto);
    return m;
  }

  constructor(kwargs?: GooglePlaceInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.placeId !== 'undefined') {
      toReturn['placeId'] = this.placeId;
    }
    if (typeof this.placeName !== 'undefined') {
      toReturn['placeName'] = this.placeName;
    }
    if (typeof this.city !== 'undefined') {
      toReturn['city'] = this.city;
    }
    return toReturn;
  }
}

export class ServiceArea implements ServiceAreaInterface {
  businessType: ServiceAreaBusinessType;
  places: GooglePlace[];

  static fromProto(proto: any): ServiceArea {
    let m = new ServiceArea();
    m = Object.assign(m, proto);
    if (proto?.businessType) {
      m.businessType = enumStringToValue<ServiceAreaBusinessType>(ServiceAreaBusinessType, proto?.businessType);
    }
    if (proto?.places) {
      m.places = proto?.places.map(GooglePlace.fromProto);
    }
    return m;
  }

  constructor(kwargs?: ServiceAreaInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.businessType !== 'undefined') {
      toReturn['businessType'] = this.businessType;
    }
    if (typeof this.places !== 'undefined' && this.places !== null) {
      toReturn['places'] = 'toApiJson' in this.places ? (this.places as any).toApiJson() : this.places;
    }
    return toReturn;
  }
}

export class GeoPoint {
  latitude: number;
  longitude: number;

  static fromProto(proto: any): GeoPoint {
    if (!proto) {
      return null;
    }
    const model = new GeoPoint();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    return model;
  }

  constructor(kwargs?: Partial<GeoPoint>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (typeof this.latitude === 'undefined' && typeof this.longitude === 'undefined') {
      return null;
    }
    return {
      latitude: typeof this.latitude !== 'undefined' ? this.latitude : null,
      longitude: typeof this.longitude !== 'undefined' ? this.longitude : null,
    };
  }
}

export class Account {
  isTrial: boolean;
  tags: string[];
  accountId: string;
  marketplaceAppId: string;
  expiry: Date;
  editionId: string;

  static fromProto(proto: any): Account {
    if (!proto) {
      return null;
    }
    const model = new Account();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    model.expiry = proto.expiry ? new Date(proto.expiry) : null;
    if (typeof model.isTrial === 'undefined') {
      model.isTrial = false;
    }
    return model;
  }

  constructor(kwargs?: Partial<Account>) {
    return Object.assign(this, kwargs);
  }

  expired(): boolean {
    if (this.expiry) {
      return this.expiry < new Date();
    }
    return false;
  }

  toApiJson(): object {
    if (
      typeof this.isTrial === 'undefined' &&
      typeof this.tags === 'undefined' &&
      typeof this.accountId === 'undefined' &&
      typeof this.marketplaceAppId === 'undefined' &&
      typeof this.expiry === 'undefined'
    ) {
      return null;
    }

    return {
      isTrial: typeof this.isTrial !== 'undefined' ? this.isTrial : null,
      tags: typeof this.tags !== 'undefined' ? this.tags : null,
      accountId: typeof this.accountId !== 'undefined' ? this.accountId : null,
      marketplaceAppId: typeof this.marketplaceAppId !== 'undefined' ? this.marketplaceAppId : null,
      expiry: typeof this.expiry !== 'undefined' ? this.expiry.toISOString() : null,
      editionId: typeof this.marketplaceAppId !== 'undefined' ? this.editionId : null,
    };
  }
}

export class ListingDistributionDetails {
  orderId: string;
  purchaseId: string;
  fromDate: Date;
  thruDate: Date;
  autoRenew: boolean;

  static fromProto(proto: any): ListingDistributionDetails {
    if (!proto) {
      return null;
    }
    const model = new ListingDistributionDetails();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    model.fromDate = new Date(proto.fromDate);
    model.thruDate = new Date(proto.thruDate);
    if (typeof model.autoRenew === 'undefined') {
      model.autoRenew = false;
    }
    return model;
  }

  constructor(kwargs?: Partial<ListingDistributionDetails>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.orderId === 'undefined' &&
      typeof this.purchaseId === 'undefined' &&
      typeof this.fromDate === 'undefined' &&
      typeof this.thruDate === 'undefined' &&
      typeof this.autoRenew === 'undefined'
    ) {
      return null;
    }

    return {
      orderId: typeof this.orderId !== 'undefined' ? this.orderId : null,
      purchaseId: typeof this.purchaseId !== 'undefined' ? this.purchaseId : null,
      fromDate: typeof this.fromDate !== 'undefined' ? this.fromDate.toISOString() : null,
      thruDate: typeof this.thruDate !== 'undefined' ? this.thruDate.toISOString() : null,
      autoRenew: typeof this.autoRenew !== 'undefined' ? this.autoRenew : null,
    };
  }

  isActive(): boolean {
    if (!this.thruDate) {
      return false;
    }
    return this.thruDate >= new Date();
  }
}

export class ListingSyncProDetails {
  purchaseDate: Date;
  billingFrequency: ListingSyncProBillingFrequency;
  expiryDate: Date;
  country: string;
  discountFlag: boolean;
  serviceProviders: ListingSyncProServiceProviders;

  static fromProto(proto: any): ListingSyncProDetails {
    if (!proto) {
      return null;
    }
    const model = new ListingSyncProDetails();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    model.purchaseDate = proto.purchaseDate ? new Date(proto.purchaseDate) : null;
    model.expiryDate = proto.expiryDate ? new Date(proto.expiryDate) : null;
    if (typeof model.discountFlag === 'undefined') {
      model.discountFlag = false;
    }
    if (proto.billingFrequency) {
      model.billingFrequency = enumStringToValue<ListingSyncProBillingFrequency>(
        ListingSyncProBillingFrequency,
        proto.billingFrequency,
      );
    }
    if (proto.serviceProviders) {
      model.serviceProviders = enumStringToValue<ListingSyncProServiceProviders>(
        ListingSyncProServiceProviders,
        proto.serviceProviders,
      );
    }
    return model;
  }

  constructor(kwargs?: Partial<ListingSyncProDetails>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.purchaseDate === 'undefined' &&
      typeof this.billingFrequency === 'undefined' &&
      typeof this.expiryDate === 'undefined' &&
      typeof this.country === 'undefined' &&
      typeof this.discountFlag === 'undefined' &&
      typeof this.serviceProviders === 'undefined'
    ) {
      return null;
    }

    return {
      purchaseDate: typeof this.purchaseDate !== 'undefined' ? this.purchaseDate.toISOString() : null,
      billingFrequency: typeof this.billingFrequency !== 'undefined' ? this.billingFrequency : null,
      expiryDate: typeof this.expiryDate !== 'undefined' ? this.expiryDate.toISOString() : null,
      country: typeof this.country !== 'undefined' ? this.country : null,
      discountFlag: typeof this.discountFlag !== 'undefined' ? this.discountFlag : null,
      serviceProviders: typeof this.serviceProviders !== 'undefined' ? this.serviceProviders : null,
    };
  }
}

export class Association {
  label: string;
  productId: string;
  productUserId: string;
  vbcUserId: string;
  defaultLocation: boolean;

  static fromProto(proto: any): Association {
    if (!proto) {
      return null;
    }
    const model = new Association();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    if (typeof model.defaultLocation === 'undefined') {
      model.defaultLocation = false;
    }
    return model;
  }

  constructor(kwargs?: Partial<Association>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.label === 'undefined' &&
      typeof this.productId === 'undefined' &&
      typeof this.productUserId === 'undefined' &&
      typeof this.vbcUserId === 'undefined' &&
      typeof this.defaultLocation === 'undefined'
    ) {
      return null;
    }

    return {
      label: typeof this.label !== 'undefined' ? this.label : null,
      productId: typeof this.productId !== 'undefined' ? this.productId : null,
      productUserId: typeof this.productUserId !== 'undefined' ? this.productUserId : null,
      vbcUserId: typeof this.vbcUserId !== 'undefined' ? this.vbcUserId : null,
      defaultLocation: typeof this.defaultLocation !== 'undefined' ? this.defaultLocation : null,
    };
  }
}

export class AccountGroupExternalIdentifiers {
  origin: string;
  jobId: string[];
  customerIdentifier: string;
  tags: string[];
  actionLists: string[];
  socialProfileId: string;
  partnerId: string;
  marketId: string;
  taxIds: string[];
  vCategoryIds: string[];
  salesPersonId: string;
  additionalSalesPersonIds: string[];
  platformCrmCompanyId?: string;

  static fromProto(proto: any): AccountGroupExternalIdentifiers {
    if (!proto) {
      return null;
    }
    const model = new AccountGroupExternalIdentifiers();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    return model;
  }

  constructor(kwargs?: Partial<AccountGroupExternalIdentifiers>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.origin === 'undefined' &&
      typeof this.jobId === 'undefined' &&
      typeof this.customerIdentifier === 'undefined' &&
      typeof this.tags === 'undefined' &&
      typeof this.actionLists === 'undefined' &&
      typeof this.socialProfileId === 'undefined' &&
      typeof this.partnerId === 'undefined' &&
      typeof this.marketId === 'undefined' &&
      typeof this.taxIds === 'undefined' &&
      typeof this.vCategoryIds === 'undefined' &&
      typeof this.salesPersonId === 'undefined' &&
      typeof this.additionalSalesPersonIds === 'undefined' &&
      typeof this.platformCrmCompanyId === 'undefined'
    ) {
      return null;
    }

    return {
      origin: typeof this.origin !== 'undefined' ? this.origin : null,
      jobId: typeof this.jobId !== 'undefined' ? this.jobId : null,
      customerIdentifier: typeof this.customerIdentifier !== 'undefined' ? this.customerIdentifier : null,
      tags: typeof this.tags !== 'undefined' ? this.tags : null,
      actionLists: typeof this.actionLists !== 'undefined' ? this.actionLists : null,
      socialProfileId: typeof this.socialProfileId !== 'undefined' ? this.socialProfileId : null,
      partnerId: typeof this.partnerId !== 'undefined' ? this.partnerId : null,
      marketId: typeof this.marketId !== 'undefined' ? this.marketId : null,
      taxIds: typeof this.taxIds !== 'undefined' ? this.taxIds : null,
      vCategoryIds: typeof this.vCategoryIds !== 'undefined' ? this.vCategoryIds : null,
      salesPersonId: typeof this.salesPersonId !== 'undefined' ? this.salesPersonId : null,
      additionalSalesPersonIds:
        typeof this.additionalSalesPersonIds !== 'undefined' ? this.additionalSalesPersonIds : null,
      companyId: typeof this.platformCrmCompanyId !== 'undefined' ? this.platformCrmCompanyId : null,
    };
  }
}

export class SocialURLs {
  googleplusUrl: string;
  linkedinUrl: string;
  foursquareUrl: string;
  twitterUrl: string;
  facebookUrl: string;
  rssUrl: string;
  youtubeUrl: string;
  instagramUrl: string;
  pinterestUrl: string;

  static fromProto(proto: any): SocialURLs {
    if (!proto) {
      return null;
    }
    const model = new SocialURLs();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    return model;
  }

  constructor(kwargs?: Partial<SocialURLs>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.googleplusUrl === 'undefined' &&
      typeof this.linkedinUrl === 'undefined' &&
      typeof this.foursquareUrl === 'undefined' &&
      typeof this.twitterUrl === 'undefined' &&
      typeof this.facebookUrl === 'undefined' &&
      typeof this.rssUrl === 'undefined' &&
      typeof this.youtubeUrl === 'undefined' &&
      typeof this.instagramUrl === 'undefined' &&
      typeof this.pinterestUrl === 'undefined'
    ) {
      return null;
    }

    return {
      googleplusUrl: typeof this.googleplusUrl !== 'undefined' ? this.googleplusUrl : null,
      linkedinUrl: typeof this.linkedinUrl !== 'undefined' ? this.linkedinUrl : null,
      foursquareUrl: typeof this.foursquareUrl !== 'undefined' ? this.foursquareUrl : null,
      twitterUrl: typeof this.twitterUrl !== 'undefined' ? this.twitterUrl : null,
      facebookUrl: typeof this.facebookUrl !== 'undefined' ? this.facebookUrl : null,
      rssUrl: typeof this.rssUrl !== 'undefined' ? this.rssUrl : null,
      youtubeUrl: typeof this.youtubeUrl !== 'undefined' ? this.youtubeUrl : null,
      instagramUrl: typeof this.instagramUrl !== 'undefined' ? this.instagramUrl : null,
      pinterestUrl: typeof this.pinterestUrl !== 'undefined' ? this.pinterestUrl : null,
    };
  }
}

export class HoursOfOperation {
  dayOfWeek: string[];
  opens: string;
  closes: string;
  description: string;

  static fromProto(proto: any): HoursOfOperation {
    if (!proto) {
      return null;
    }
    const model = new HoursOfOperation();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    return model;
  }

  constructor(kwargs?: Partial<HoursOfOperation>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.dayOfWeek === 'undefined' &&
      typeof this.opens === 'undefined' &&
      typeof this.closes === 'undefined' &&
      typeof this.description === 'undefined'
    ) {
      return null;
    }

    return {
      dayOfWeek: typeof this.dayOfWeek !== 'undefined' ? this.dayOfWeek : null,
      opens: typeof this.opens !== 'undefined' ? this.opens : null,
      closes: typeof this.closes !== 'undefined' ? this.closes : null,
      description: typeof this.description !== 'undefined' ? this.description : null,
    };
  }
}

export class ContactDetails {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;

  static fromProto(proto: any): ContactDetails {
    if (!proto) {
      return null;
    }
    const model = new ContactDetails();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    return model;
  }

  constructor(kwargs?: Partial<ContactDetails>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.firstName === 'undefined' &&
      typeof this.lastName === 'undefined' &&
      typeof this.email === 'undefined' &&
      typeof this.phoneNumber === 'undefined'
    ) {
      return null;
    }

    return {
      firstName: typeof this.firstName !== 'undefined' ? this.firstName : null,
      lastName: typeof this.lastName !== 'undefined' ? this.lastName : null,
      email: typeof this.email !== 'undefined' ? this.email : null,
      phoneNumber: typeof this.phoneNumber !== 'undefined' ? this.phoneNumber : null,
    };
  }
}

export class Snapshot {
  snapshotId: string;
  created: Date;
  expiry: Date;
  address: string;

  static fromProto(proto: any): Snapshot {
    if (!proto) {
      return null;
    }
    const model = new Snapshot();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    model.created = proto.created ? new Date(proto.created) : null;
    model.expiry = proto.expiry ? new Date(proto.expiry) : null;
    model.address = proto.address ? proto.address : '';
    return model;
  }

  constructor(kwargs?: Partial<Snapshot>) {
    return Object.assign(this, kwargs);
  }

  expired(): boolean {
    return this.expiry < new Date();
  }

  toApiJson(): object {
    if (
      typeof this.snapshotId === 'undefined' &&
      typeof this.created === 'undefined' &&
      typeof this.address === 'undefined' &&
      typeof this.expiry === 'undefined'
    ) {
      return null;
    }

    return {
      snapshotId: typeof this.snapshotId !== 'undefined' ? this.snapshotId : null,
      created: typeof this.created !== 'undefined' ? this.created.toISOString() : null,
      expiry: typeof this.expiry !== 'undefined' ? this.expiry.toISOString() : null,
      address: typeof this.address !== 'undefined' ? this.address : '',
    };
  }
}

export class LegacyProductDetails {
  keyPerson: string[];
  shareOfVoiceService: string[];
  faxNumber: string;
  commonName: string[];
  cellNumber: string;
  competitor: string[];
  adminNotes: string;
  seoCategory: string[];
  email: string;
  place: string;
  tagline: string;
  subscribedToCampaigns: boolean;

  static fromProto(proto: any): LegacyProductDetails {
    if (!proto) {
      return null;
    }
    const model = new LegacyProductDetails();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    if (typeof model.subscribedToCampaigns === 'undefined') {
      model.subscribedToCampaigns = false;
    }
    return model;
  }

  constructor(kwargs?: Partial<LegacyProductDetails>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.keyPerson === 'undefined' &&
      typeof this.shareOfVoiceService === 'undefined' &&
      typeof this.faxNumber === 'undefined' &&
      typeof this.commonName === 'undefined' &&
      typeof this.cellNumber === 'undefined' &&
      typeof this.competitor === 'undefined' &&
      typeof this.adminNotes === 'undefined' &&
      typeof this.seoCategory === 'undefined' &&
      typeof this.email === 'undefined' &&
      typeof this.place === 'undefined' &&
      typeof this.tagline === 'undefined' &&
      typeof this.subscribedToCampaigns === 'undefined'
    ) {
      return null;
    }

    return {
      keyPerson: typeof this.keyPerson !== 'undefined' ? this.keyPerson : null,
      shareOfVoiceService: typeof this.shareOfVoiceService !== 'undefined' ? this.shareOfVoiceService : null,
      faxNumber: typeof this.faxNumber !== 'undefined' ? this.faxNumber : null,
      commonName: typeof this.commonName !== 'undefined' ? this.commonName : null,
      cellNumber: typeof this.cellNumber !== 'undefined' ? this.cellNumber : null,
      competitor: typeof this.competitor !== 'undefined' ? this.competitor : null,
      adminNotes: typeof this.adminNotes !== 'undefined' ? this.adminNotes : null,
      seoCategory: typeof this.seoCategory !== 'undefined' ? this.seoCategory : null,
      email: typeof this.email !== 'undefined' ? this.email : null,
      place: typeof this.place !== 'undefined' ? this.place : null,
      tagline: typeof this.tagline !== 'undefined' ? this.tagline : null,
      subscribedToCampaigns: typeof this.subscribedToCampaigns !== 'undefined' ? this.subscribedToCampaigns : null,
    };
  }
}

export class CustomField {
  name: string;
  value: string;

  static fromProto(proto: any): CustomField {
    if (!proto) {
      return null;
    }
    const model = new CustomField();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    return model;
  }

  constructor(kwargs?: Partial<CustomField>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (typeof this.name === 'undefined' && typeof this.value === 'undefined') {
      return null;
    }

    return {
      name: typeof this.name !== 'undefined' ? this.name : null,
      value: typeof this.value !== 'undefined' ? this.value : null,
    };
  }
}

export class HealthCareProfessionalInformation {
  dateOfBirth: Date;
  email: string;
  fellowship: string[];
  firstName: string;
  gender: HealthCareProfessionalInformationGender;
  initials: string;
  insurancesAccepted: string[];
  isTakingPatients: boolean;
  lastName: string;
  medicalLicenseNumber: string;
  nationalProviderIdentifier: string;
  office: string;
  professionalCredential: string[];
  residency: string[];
  school: string[];
  specialty: string[];
  standardizedTitle: string;
  stateLicense: string;

  static fromProto(proto: any): HealthCareProfessionalInformation {
    if (!proto) {
      return null;
    }
    const model = new HealthCareProfessionalInformation();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    if (proto.gender) {
      model.gender = enumStringToValue<HealthCareProfessionalInformationGender>(
        HealthCareProfessionalInformationGender,
        proto.gender,
      );
    }
    return model;
  }

  constructor(kwargs?: Partial<HealthCareProfessionalInformation>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.dateOfBirth === 'undefined' &&
      typeof this.email === 'undefined' &&
      typeof this.fellowship === 'undefined' &&
      typeof this.firstName === 'undefined' &&
      typeof this.gender === 'undefined' &&
      typeof this.initials === 'undefined' &&
      typeof this.insurancesAccepted === 'undefined' &&
      typeof this.isTakingPatients === 'undefined' &&
      typeof this.lastName === 'undefined' &&
      typeof this.medicalLicenseNumber === 'undefined' &&
      typeof this.nationalProviderIdentifier === 'undefined' &&
      typeof this.office === 'undefined' &&
      typeof this.professionalCredential === 'undefined' &&
      typeof this.residency === 'undefined' &&
      typeof this.school === 'undefined' &&
      typeof this.specialty === 'undefined' &&
      typeof this.standardizedTitle === 'undefined' &&
      typeof this.stateLicense === 'undefined'
    ) {
      return null;
    }

    return {
      dateOfBirth: typeof this.dateOfBirth !== 'undefined' ? this.dateOfBirth.toISOString() : null,
      email: typeof this.email !== 'undefined' ? this.email : null,
      fellowship: typeof this.fellowship !== 'undefined' ? this.fellowship : null,
      firstName: typeof this.firstName !== 'undefined' ? this.firstName : null,
      gender: typeof this.gender !== 'undefined' ? this.gender : null,
      initials: typeof this.initials !== 'undefined' ? this.initials : null,
      insurancesAccepted: typeof this.insurancesAccepted !== 'undefined' ? this.insurancesAccepted : null,
      isTakingPatients: typeof this.isTakingPatients !== 'undefined' ? this.isTakingPatients : null,
      lastName: typeof this.lastName !== 'undefined' ? this.lastName : null,
      medicalLicenseNumber: typeof this.medicalLicenseNumber !== 'undefined' ? this.medicalLicenseNumber : null,
      nationalProviderIdentifier:
        typeof this.nationalProviderIdentifier !== 'undefined' ? this.nationalProviderIdentifier : null,
      office: typeof this.office !== 'undefined' ? this.office : null,
      professionalCredential: typeof this.professionalCredential !== 'undefined' ? this.professionalCredential : null,
      residency: typeof this.residency !== 'undefined' ? this.residency : null,
      school: typeof this.school !== 'undefined' ? this.school : null,
      specialty: typeof this.specialty !== 'undefined' ? this.specialty : null,
      standardizedTitle: typeof this.standardizedTitle !== 'undefined' ? this.standardizedTitle : null,
      stateLicense: typeof this.stateLicense !== 'undefined' ? this.stateLicense : null,
    };
  }
}

export class RichData {
  tollFreeNumber: string;
  description: string;
  shortDescription: string;
  servicesOffered: string[];
  brandsCarried: string[];
  landmark: string;
  paymentMethods: RichDataPaymentMethods[];
  customFields: CustomField[];
  healthCareProfessionalInformation: HealthCareProfessionalInformation;
  inferredAttributes: string[];
  serviceAvailability?: ServiceAvailability;

  static fromProto(proto: any): RichData {
    if (!proto) {
      return null;
    }
    const model = new RichData();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    model.healthCareProfessionalInformation = HealthCareProfessionalInformation.fromProto(
      proto.healthCareProfessionalInformation,
    );
    if (proto.paymentMethods && proto.paymentMethods.length) {
      model.paymentMethods = proto.paymentMethods.map((v: string) =>
        enumStringToValue<RichDataPaymentMethods>(RichDataPaymentMethods, v),
      );
    }
    model.serviceAvailability = ServiceAvailability.fromProto(proto.serviceAvailability);
    return model;
  }

  constructor(kwargs?: Partial<RichData>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.tollFreeNumber === 'undefined' &&
      typeof this.description === 'undefined' &&
      typeof this.shortDescription === 'undefined' &&
      typeof this.servicesOffered === 'undefined' &&
      typeof this.brandsCarried === 'undefined' &&
      typeof this.landmark === 'undefined' &&
      typeof this.paymentMethods === 'undefined' &&
      typeof this.customFields === 'undefined' &&
      typeof this.healthCareProfessionalInformation === 'undefined' &&
      typeof this.inferredAttributes === 'undefined' &&
      typeof this.inferredAttributes === 'undefined' &&
      typeof this.serviceAvailability === 'undefined'
    ) {
      return null;
    }

    return {
      tollFreeNumber: typeof this.tollFreeNumber !== 'undefined' ? this.tollFreeNumber : null,
      description: typeof this.description !== 'undefined' ? this.description : null,
      shortDescription: typeof this.shortDescription !== 'undefined' ? this.shortDescription : null,
      servicesOffered: typeof this.servicesOffered !== 'undefined' ? this.servicesOffered : null,
      brandsCarried: typeof this.brandsCarried !== 'undefined' ? this.brandsCarried : null,
      landmark: typeof this.landmark !== 'undefined' ? this.landmark : null,
      paymentMethods: typeof this.paymentMethods !== 'undefined' ? this.paymentMethods : null,
      customFields:
        typeof this.customFields !== 'undefined'
          ? this.customFields.map((customField: CustomField) => customField.toApiJson())
          : null,
      healthCareProfessionalInformation:
        typeof this.healthCareProfessionalInformation !== 'undefined'
          ? this.healthCareProfessionalInformation.toApiJson()
          : null,
      inferredAttributes: typeof this.inferredAttributes !== 'undefined' ? this.inferredAttributes : null,
      serviceAvailability: typeof this.serviceAvailability !== 'undefined' ? this.serviceAvailability : null,
    };
  }
}

export class ServiceAvailability {
  delivery?: IsAvailable;
  noContactDelivery?: IsAvailable;
  inStorePickup?: IsAvailable;
  curbsidePickup?: IsAvailable;
  appointmentsOnly?: IsAvailable;
  ecommerceOnly?: IsAvailable;
  closedStatus?: ClosedStatus;
  closedStatusDate?: Date;
  servesDineIn?: IsAvailable;
  reopeningDate?: Date;

  static fromProto(proto: any): ServiceAvailability {
    if (!proto) {
      return null;
    }
    let m = new ServiceAvailability();
    m = Object.assign(m, proto);
    if (proto.delivery) {
      m.delivery = enumStringToValue<IsAvailable>(IsAvailable, proto.delivery);
    }
    if (proto.noContactDelivery) {
      m.noContactDelivery = enumStringToValue<IsAvailable>(IsAvailable, proto.noContactDelivery);
    }
    if (proto.inStorePickup) {
      m.inStorePickup = enumStringToValue<IsAvailable>(IsAvailable, proto.inStorePickup);
    }
    if (proto.curbsidePickup) {
      m.curbsidePickup = enumStringToValue<IsAvailable>(IsAvailable, proto.curbsidePickup);
    }
    if (proto.appointmentsOnly) {
      m.appointmentsOnly = enumStringToValue<IsAvailable>(IsAvailable, proto.appointmentsOnly);
    }
    if (proto.ecommerceOnly) {
      m.ecommerceOnly = enumStringToValue<IsAvailable>(IsAvailable, proto.ecommerceOnly);
    }
    if (proto.closedStatus) {
      m.closedStatus = enumStringToValue<ClosedStatus>(ClosedStatus, proto.closedStatus);
    }
    if (proto.closedStatusDate) {
      m.closedStatusDate = new Date(proto.closedStatusDate);
    }
    if (proto.servesDineIn) {
      m.servesDineIn = enumStringToValue<IsAvailable>(IsAvailable, proto.servesDineIn);
    }
    if (proto.reopeningDate) {
      m.reopeningDate = new Date(proto.reopeningDate);
    }
    return m;
  }

  constructor(kwargs?: Partial<ServiceAvailability>) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.delivery === 'undefined' &&
      typeof this.noContactDelivery === 'undefined' &&
      typeof this.inStorePickup === 'undefined' &&
      typeof this.curbsidePickup === 'undefined' &&
      typeof this.appointmentsOnly === 'undefined' &&
      typeof this.ecommerceOnly === 'undefined' &&
      typeof this.closedStatus === 'undefined' &&
      typeof this.closedStatusDate === 'undefined' &&
      typeof this.servesDineIn === 'undefined' &&
      typeof this.reopeningDate === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.delivery !== 'undefined') {
      toReturn['delivery'] = this.delivery;
    }
    if (typeof this.noContactDelivery !== 'undefined') {
      toReturn['noContactDelivery'] = this.noContactDelivery;
    }
    if (typeof this.inStorePickup !== 'undefined') {
      toReturn['inStorePickup'] = this.inStorePickup;
    }
    if (typeof this.curbsidePickup !== 'undefined') {
      toReturn['curbsidePickup'] = this.curbsidePickup;
    }
    if (typeof this.appointmentsOnly !== 'undefined') {
      toReturn['appointmentsOnly'] = this.appointmentsOnly;
    }
    if (typeof this.ecommerceOnly !== 'undefined') {
      toReturn['ecommerceOnly'] = this.ecommerceOnly;
    }
    if (typeof this.closedStatus !== 'undefined') {
      toReturn['closedStatus'] = this.closedStatus;
    }
    if (typeof this.closedStatusDate !== 'undefined' && this.closedStatusDate !== null) {
      toReturn['closedStatusDate'] =
        'toApiJson' in this.closedStatusDate ? (this.closedStatusDate as any).toApiJson() : this.closedStatusDate;
    }
    if (typeof this.servesDineIn !== 'undefined') {
      toReturn['servesDineIn'] = this.servesDineIn;
    }
    if (typeof this.reopeningDate !== 'undefined' && this.reopeningDate !== null) {
      toReturn['reopeningDate'] =
        'toApiJson' in this.reopeningDate ? (this.reopeningDate as any).toApiJson() : this.reopeningDate;
    }
    return toReturn;
  }
}

export class Status {
  suspended: boolean;
  hasAlert: boolean;

  static fromProto(proto: any): Status {
    if (!proto) {
      return null;
    }
    const model = new Status();
    for (const key in proto) {
      if (key in proto) {
        model[key] = proto[key];
      }
    }
    if (typeof model.suspended === 'undefined') {
      model.suspended = false;
    }
    if (typeof model.hasAlert === 'undefined') {
      model.hasAlert = false;
    }
    return model;
  }

  constructor(kwargs?: Partial<Status>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (typeof this.suspended === 'undefined' || typeof this.hasAlert === 'undefined') {
      return null;
    }

    return {
      suspended: typeof this.suspended !== 'undefined' ? this.suspended : null,
      hasAlert: typeof this.hasAlert !== 'undefined' ? this.hasAlert : null,
    };
  }
}

export class AccountGroupLocation {
  companyName: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  serviceAreaBusiness: boolean;
  serviceArea: ServiceArea;
  website: string;
  workNumber: string[];
  callTrackingNumber: string[];
  location: GeoPoint;
  timezone: string;

  static fromProto(proto: any): AccountGroupLocation {
    if (!proto) {
      return null;
    }
    const napData = new AccountGroupLocation();
    for (const key in proto) {
      if (key in proto) {
        napData[key] = proto[key];
      }
    }

    napData.location = GeoPoint.fromProto(proto.location);
    napData.serviceArea = proto.serviceArea ? ServiceArea.fromProto(proto.serviceArea) : undefined;
    return napData;
  }

  constructor(kwargs?: Partial<AccountGroupLocation>) {
    return Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.companyName === 'undefined' &&
      typeof this.address === 'undefined' &&
      typeof this.address2 === 'undefined' &&
      typeof this.city === 'undefined' &&
      typeof this.state === 'undefined' &&
      typeof this.zip === 'undefined' &&
      typeof this.country === 'undefined' &&
      typeof this.website === 'undefined' &&
      typeof this.workNumber === 'undefined' &&
      typeof this.callTrackingNumber === 'undefined' &&
      typeof this.location === 'undefined' &&
      typeof this.timezone === 'undefined' &&
      typeof this.serviceAreaBusiness === 'undefined' &&
      typeof this.serviceArea === 'undefined'
    ) {
      return null;
    }

    return {
      companyName: typeof this.companyName !== 'undefined' ? this.companyName : null,
      address: typeof this.address !== 'undefined' ? this.address : null,
      address2: typeof this.address2 !== 'undefined' ? this.address2 : null,
      city: typeof this.city !== 'undefined' ? this.city : null,
      state: typeof this.state !== 'undefined' ? this.state : null,
      zip: typeof this.zip !== 'undefined' ? this.zip : null,
      country: typeof this.country !== 'undefined' ? this.country : null,
      serviceAreaBusiness: typeof this.serviceAreaBusiness !== 'undefined' ? this.serviceAreaBusiness : null,
      serviceArea: typeof this.serviceArea !== 'undefined' ? this.serviceArea?.toApiJson() : null,
      website: typeof this.website !== 'undefined' ? this.website : null,
      workNumber: typeof this.workNumber !== 'undefined' ? this.workNumber : null,
      callTrackingNumber: typeof this.callTrackingNumber !== 'undefined' ? this.callTrackingNumber : null,
      timezone: typeof this.timezone !== 'undefined' ? this.timezone : null,
      location: typeof this.location !== 'undefined' ? this.location.toApiJson() : null,
    };
  }
}

export class AccountGroupHealth {
  rating: number;
  reason: string;
  updatedBy: string;
  updatedOn: Date;

  static fromProto(proto: any): AccountGroupHealth {
    let m = new AccountGroupHealth();
    m = Object.assign(m, proto);
    if (proto.updatedOn) {
      m.updatedOn = new Date(proto.updatedOn);
    }
    return m;
  }

  constructor(kwargs?: Partial<AccountGroupHealth>) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.rating === 'undefined' &&
      typeof this.reason === 'undefined' &&
      typeof this.updatedBy === 'undefined' &&
      typeof this.updatedOn === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.rating !== 'undefined') {
      toReturn['rating'] = this.rating;
    }
    if (typeof this.reason !== 'undefined') {
      toReturn['reason'] = this.reason;
    }
    if (typeof this.updatedBy !== 'undefined') {
      toReturn['updatedBy'] = this.updatedBy;
    }
    if (typeof this.updatedOn !== 'undefined' && this.updatedOn !== null) {
      toReturn['updatedOn'] = 'toApiJson' in this.updatedOn ? (this.updatedOn as any).toApiJson() : this.updatedOn;
    }
    return toReturn;
  }
}

export class AccountGroup {
  accountGroupId: string;

  deleted: Date;
  created: Date;
  updated: Date;

  subscribedToCampaigns: boolean;

  accounts: Account[];
  listingDistribution: ListingDistributionDetails;
  listingSyncPro: ListingSyncProDetails;
  associations: Association[];
  externalIdentifiers: AccountGroupExternalIdentifiers;
  socialUrls: SocialURLs;
  hoursOfOperation: HoursOfOperation[];
  contactDetails: ContactDetails;
  snapshotReports: Snapshot[];
  legacyProductDetails: LegacyProductDetails;
  richData: RichData;
  napData: AccountGroupLocation;
  status: Status;
  health: AccountGroupHealth;
  additionalCompanyInfo: AdditionalCompanyInfo;
  marketingInfo: MarketingInfo;

  static fromProto(proto: any): AccountGroup {
    if (!proto) {
      return null;
    }

    const accountGroup = new AccountGroup();
    for (const key in proto) {
      if (key in proto) {
        accountGroup[key] = proto[key];
      }
    }
    if (typeof accountGroup.subscribedToCampaigns === 'undefined') {
      accountGroup.subscribedToCampaigns = false;
    }

    accountGroup.created = proto.created ? new Date(proto.created) : null;
    accountGroup.updated = proto.updated ? new Date(proto.updated) : null;
    accountGroup.deleted = proto.deleted ? new Date(proto.deleted) : null;

    accountGroup.napData = AccountGroupLocation.fromProto(proto.napData);
    accountGroup.listingDistribution = ListingDistributionDetails.fromProto(proto.listingDistribution);
    accountGroup.listingSyncPro = ListingSyncProDetails.fromProto(proto.listingSyncPro);
    accountGroup.externalIdentifiers = AccountGroupExternalIdentifiers.fromProto(proto.accountGroupExternalIdentifiers);
    accountGroup.socialUrls = SocialURLs.fromProto(proto.socialUrls);
    accountGroup.contactDetails = ContactDetails.fromProto(proto.contactDetails);
    accountGroup.legacyProductDetails = LegacyProductDetails.fromProto(proto.legacyProductDetails);
    accountGroup.richData = RichData.fromProto(proto.richData);
    accountGroup.status = Status.fromProto(proto.status);

    accountGroup.accounts =
      proto.accounts && proto.accounts.accounts ? proto.accounts.accounts.map(Account.fromProto) : [];
    accountGroup.associations = proto.associations ? proto.associations.map(Association.fromProto) : [];
    accountGroup.hoursOfOperation =
      proto.hoursOfOperation && proto.hoursOfOperation.hoursOfOperation
        ? proto.hoursOfOperation.hoursOfOperation.map(HoursOfOperation.fromProto)
        : [];
    accountGroup.snapshotReports =
      proto.snapshotReports && proto.snapshotReports.snapshots
        ? proto.snapshotReports.snapshots.map(Snapshot.fromProto)
        : [];
    if (proto.health) {
      accountGroup.health = AccountGroupHealth.fromProto(proto.health);
    }
    if (proto.additionalCompanyInfo) {
      accountGroup.additionalCompanyInfo = AdditionalCompanyInfo.fromProto(proto.additionalCompanyInfo);
    }
    if (proto.marketingInfo) {
      accountGroup.marketingInfo = MarketingInfo.fromProto(proto.marketingInfo);
    }

    return accountGroup;
  }

  constructor(kwargs?: Partial<AccountGroup>) {
    return Object.assign(this, kwargs);
  }

  hasAccount(marketplaceAppId: string): boolean {
    return !!this.getAccount(marketplaceAppId);
  }

  getAccount(marketplaceAppId: string): Account {
    return this.accounts.find((account) => account.marketplaceAppId === marketplaceAppId);
  }

  getLatestSnapshotReport(): Snapshot {
    if (!this.snapshotReports) {
      return null;
    }
    return this.snapshotReports[this.snapshotReports.length - 1];
  }

  getLifeCycleStage(): LifecycleStage {
    if (typeof this.marketingInfo === 'undefined' || typeof this.marketingInfo.lifecycleStage === 'undefined') {
      return LifecycleStage.LIFECYCLE_STAGE_UNSET;
    }

    return <LifecycleStage>(<unknown>this.marketingInfo.lifecycleStage);
  }

  toApiJson(): object {
    if (
      typeof this.accountGroupId === 'undefined' &&
      typeof this.napData === 'undefined' &&
      typeof this.deleted === 'undefined' &&
      typeof this.created === 'undefined' &&
      typeof this.updated === 'undefined' &&
      typeof this.subscribedToCampaigns === 'undefined' &&
      typeof this.accounts === 'undefined' &&
      typeof this.listingDistribution === 'undefined' &&
      typeof this.listingSyncPro === 'undefined' &&
      typeof this.associations === 'undefined' &&
      typeof this.externalIdentifiers === 'undefined' &&
      typeof this.socialUrls === 'undefined' &&
      typeof this.hoursOfOperation === 'undefined' &&
      typeof this.contactDetails === 'undefined' &&
      typeof this.snapshotReports === 'undefined' &&
      typeof this.legacyProductDetails === 'undefined' &&
      typeof this.richData === 'undefined' &&
      typeof this.status === 'undefined' &&
      typeof this.health === 'undefined' &&
      typeof this.additionalCompanyInfo === 'undefined' &&
      typeof this.marketingInfo === 'undefined'
    ) {
      return null;
    }

    return {
      accountGroupId: typeof this.accountGroupId !== 'undefined' ? this.accountGroupId : null,
      deleted: typeof this.deleted !== 'undefined' ? this.deleted.toISOString() : null,
      created: typeof this.created !== 'undefined' ? this.created.toISOString() : null,
      updated: typeof this.updated !== 'undefined' ? this.updated.toISOString() : null,
      subscribedToCampaigns: typeof this.subscribedToCampaigns !== 'undefined' ? this.subscribedToCampaigns : null,
      napData: typeof this.napData !== 'undefined' ? this.napData.toApiJson() : null,
      accounts: typeof this.accounts !== 'undefined' ? this.accounts.map((account) => account.toApiJson()) : null,
      listingDistribution:
        typeof this.listingDistribution !== 'undefined' ? this.listingDistribution.toApiJson() : null,
      listingSyncPro: typeof this.listingSyncPro !== 'undefined' ? this.listingSyncPro.toApiJson() : null,
      associations:
        typeof this.associations !== 'undefined'
          ? this.associations.map((association) => association.toApiJson())
          : null,
      accountGroupExternalIdentifiers:
        typeof this.externalIdentifiers !== 'undefined' ? this.externalIdentifiers.toApiJson() : null,
      socialUrls: typeof this.socialUrls !== 'undefined' ? this.socialUrls.toApiJson() : null,
      hoursOfOperation:
        typeof this.hoursOfOperation !== 'undefined'
          ? this.hoursOfOperation.map((hoursOfOperation) => hoursOfOperation.toApiJson())
          : null,
      contactDetails: typeof this.contactDetails !== 'undefined' ? this.contactDetails.toApiJson() : null,
      snapshotReports:
        typeof this.snapshotReports !== 'undefined'
          ? this.snapshotReports.map((snapshotReport) => snapshotReport.toApiJson())
          : null,
      legacyProductDetails:
        typeof this.legacyProductDetails !== 'undefined' ? this.legacyProductDetails.toApiJson() : null,
      richData: typeof this.richData !== 'undefined' ? this.richData.toApiJson() : null,
      status: typeof this.status !== 'undefined' ? this.status.toApiJson() : null,
      health: typeof this.health !== 'undefined' && this.health !== null ? this.health.toApiJson() : null,
      additionalCompanyInfo:
        typeof this.additionalCompanyInfo !== 'undefined' && this.additionalCompanyInfo !== null
          ? this.additionalCompanyInfo.toApiJson()
          : null,
      marketingInfo:
        typeof this.marketingInfo !== 'undefined' && this.marketingInfo !== null
          ? this.marketingInfo.toApiJson()
          : null,
    };
  }
}
