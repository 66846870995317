import { Config, ConfigInterface } from '../section/section';

export enum SectionVersions {
  VERSION_0 = 0,
  VERSION_1 = 1,
}

export interface WebsiteConfigInterface extends ConfigInterface {
  hideMissingWebsiteWarning?: boolean;
  hideMobile?: boolean;
  hideMobilePreview?: boolean;
  hideDesktop?: boolean;
  hideDesktopPreview?: boolean;
  hideHomepageContent?: boolean;
  hideHomepageVideo?: boolean;
  sectionVersion?: number;
}

export class WebsiteConfig extends Config implements WebsiteConfigInterface {
  hideMissingWebsiteWarning: boolean;
  hideMobile: boolean;
  hideMobilePreview: boolean;
  hideDesktop: boolean;
  hideDesktopPreview: boolean;
  hideHomepageContent: boolean;
  hideHomepageVideo: boolean;
  sectionVersion: number;

  constructor(config: WebsiteConfigInterface) {
    super(config);
    this.hideMissingWebsiteWarning = !!config.hideMissingWebsiteWarning;
    this.hideMobile = !!config.hideMobile;
    this.hideMobilePreview = !!config.hideMobilePreview;
    this.hideDesktop = !!config.hideDesktop;
    this.hideDesktopPreview = !!config.hideDesktopPreview;
    this.hideHomepageContent = !!config.hideHomepageContent;
    this.hideHomepageVideo = !!config.hideHomepageVideo;
    this.sectionVersion = config.sectionVersion || 0;
  }
}
