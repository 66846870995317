<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>
      {{ 'EDIT_REPORT.CUSTOMIZE_REPORT' | translate }}
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="row row-gutters">
      <div class="col col-xs-12 col-md-4">
        <div class="checkbox">
          <snap-item-toggle
            [hideControl]="hideGrades"
            [label]="'EDIT_REPORT.SHOW_GRADES' | translate"
          ></snap-item-toggle>
        </div>
        <div class="checkbox">
          <snap-item-toggle
            [hideControl]="hideSubsectionGrades"
            [label]="'EDIT_REPORT.SHOW_SECONDARY_GRADES' | translate"
            [disabled]="hideGrades.value"
            [isChild]="true"
          ></snap-item-toggle>
        </div>
        <div class="checkbox">
          <snap-item-toggle
            [hideControl]="hideImages"
            [label]="'EDIT_REPORT.SHOW_IMAGES' | translate"
          ></snap-item-toggle>
        </div>
      </div>
      <div class="col col-xs-12 col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'EDIT_REPORT.VIDEO_STYLE' | translate }}</mat-label>
          <mat-select [formControl]="videoStyle">
            <mat-option *ngFor="let option of videoStyleOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-xs-12 col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'EDIT_REPORT.LANGUAGE' | translate }}</mat-label>
          <mat-select [disabled]="expired" [formControl]="locale">
            <mat-option *ngFor="let option of localeOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
          <mat-hint>
            {{ 'EDIT_REPORT.LANGUAGE_SWITCHER_NOTE' | translate }}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
</mat-card>
