import { map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { ReviewConfig, ReviewConfigInterface } from './review-config';
import { ReviewData } from './review-data';
import { ReviewContent } from './review-content';
import { ContentService } from '../snapshot-report/content.service';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { SectionInterface } from '../section/section';
import { AbstractSectionService } from '../section/section.service';
import { ReviewSectionService } from '@vendasta/snapshot';
import { GradeExplanationData } from '../grade/grade-explainer-dialog.component';
import { gradeToLetter } from '../common/utils';

@Injectable()
export class ReviewService
  extends AbstractSectionService<ReviewContent, ReviewData, ReviewConfigInterface>
  implements OnDestroy
{
  sectionId = 'review';

  constructor(private _api: ReviewSectionService, private _cs: ContentService, private _ss: SnapshotReportService) {
    super(_cs, _ss);
  }

  createGradeSubscription(): void {
    this.subscriptions.push(this.grade$.subscribe((g) => this._ss.setReviewGrade(g)));
  }

  load(): Observable<SectionInterface> {
    return this._api.get(this.snapshotId).pipe(
      map((section) => {
        return {
          grade: section.grade,
          config: new ReviewConfig(section.config || {}),
          data: new ReviewData(section.data),
          content: new ReviewContent(section.content),
        };
      }),
    );
  }

  newConfig(): ReviewConfigInterface {
    return new ReviewConfig({});
  }

  _updateConfig(config: ReviewConfigInterface): Observable<boolean> {
    return this._api.updateConfig(this.snapshotId, config);
  }

  getGradeExplanationData(): GradeExplanationData {
    return {
      titleTextID: 'REVIEWS.GRADE_EXPLANATION.TITLE',
      mobileTitleTextID: 'REVIEWS.GRADE_EXPLANATION.MOBILE_TITLE',
      primaryTextID: 'REVIEWS.GRADE_EXPLANATION.PRIMARY',
      showThermometer: true,
      secondaryTitleTextID: 'REVIEWS.GRADE_EXPLANATION.SECONDARY_TITLE',
      secondaryPreScaleTextID: 'REVIEWS.GRADE_EXPLANATION.SECONDARY_PRE_SCALE',
      showGradeScale: true,
      secondaryPostScaleTextID: 'REVIEWS.GRADE_EXPLANATION.SECONDARY_POST_SCALE',
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getAiPromptDetails(): Observable<string> {
    return combineLatest([this._ss.reviewSectionEnabled$, this.data$, this.grade$]).pipe(
      map(([sectionEnabled, data, grade]) => {
        if (!sectionEnabled) {
          return '';
        }
        const result: string[] = [];
        const letterGrade = gradeToLetter(grade);
        result.push(`Review grade: ${letterGrade}`);
        if (data?.reviewsFound) {
          result.push(
            `- Reviews found vs industry leader: ${data.reviewsFound.business}/${data.reviewsFound.industryLeader}`,
          );
        }
        if (data?.reviewsFoundPerMonth) {
          result.push(
            `- Monthly reviews found in the last 6 months: ${data.reviewsFoundPerMonth.business}/${data.reviewsFoundPerMonth.industryLeader}`,
          );
        }
        if (data?.averageReviewScore) {
          result.push(
            `- Average review score vs industry leader: ${data.averageReviewScore.business}/${data.averageReviewScore.industryLeader}`,
          );
        }
        if (data?.numberOfReviewSources) {
          result.push(
            `- Number of review sources vs industry leader: ${data.numberOfReviewSources.business}/${data.numberOfReviewSources.industryLeader}`,
          );
        }
        return result.join('\n');
      }),
    );
  }
}
