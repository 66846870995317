import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface GradeExplanationData {
  titleTextID?: string;
  mobileTitleTextID?: string;
  primaryTextID?: string;
  clarificationTextID?: string;
  showThermometer?: boolean;
  secondaryTitleTextID?: string;
  showGradeScale?: boolean;
  videoUrl?: string;
  secondaryPreScaleTextID?: string;
  secondaryPostScaleTextID?: string;
}

@Component({
  selector: 'snap-grade-explainer-dialog',
  templateUrl: './grade-explainer-dialog.component.html',
  styleUrls: ['./grade-explainer-dialog.component.scss'],
})
export class GradeExplainerDialogComponent {
  data: GradeExplanationData;

  constructor(
    public dialogRef: MatDialogRef<GradeExplainerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public injectedData: any,
  ) {
    this.data = injectedData;
  }

  clickVideo(url: string): void {
    window.open(url, '_blank');
  }

  close(): void {
    this.dialogRef.close();
  }
}
