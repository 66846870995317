import { Component, Input } from '@angular/core';
import { GradeColor } from '../../core';
import { StatusSummaryInterface } from './status-summary';

@Component({
  selector: 'snap-status-summary',
  templateUrl: './status-summary.component.html',
  styleUrls: ['./status-summary.component.scss'],
})
export class StatusSummaryComponent {
  @Input() statusSummary: StatusSummaryInterface;
  protected readonly GradeColor = GradeColor;
}
