import { Config, ConfigInterface } from '../section/section';
import { FacebookPostInterface } from '../facebook-post/facebook-post';
import { FacebookPost } from '../facebook-post/facebook-post';

export interface SocialConfigInterface extends ConfigInterface {
  hideFacebook?: boolean;
  hideFacebookLikes?: boolean;
  hideFacebookPosts?: boolean;
  hideFacebookAverageLikesPerPost?: boolean;
  hideFacebookAverageSharesPerPost?: boolean;
  hideFacebookSelectedPost?: boolean;
  selectedFacebookPost?: FacebookPostInterface;
  hideTwitter?: boolean;
  hideTwitterFollowers?: boolean;
  hideTwitterFollowing?: boolean;
  hideTwitterTweets?: boolean;
  hideInstagram?: boolean;
}

export class SocialConfig extends Config implements SocialConfigInterface {
  hideFacebook: boolean;
  hideFacebookLikes: boolean;
  hideFacebookPosts: boolean;
  hideFacebookAverageLikesPerPost: boolean;
  hideFacebookAverageSharesPerPost: boolean;
  hideFacebookSelectedPost: boolean;
  selectedFacebookPost: FacebookPost | undefined;
  hideTwitter: boolean;
  hideTwitterFollowers: boolean;
  hideTwitterFollowing: boolean;
  hideTwitterTweets: boolean;
  hideInstagram: boolean;

  constructor(config: SocialConfigInterface) {
    super(config);
    this.hideFacebook = !!config.hideFacebook;
    this.hideFacebookLikes = !!config.hideFacebookLikes;
    this.hideFacebookPosts = !!config.hideFacebookPosts;
    this.hideFacebookAverageLikesPerPost = !!config.hideFacebookAverageLikesPerPost;
    this.hideFacebookAverageSharesPerPost = !!config.hideFacebookAverageSharesPerPost;
    this.hideFacebookSelectedPost = !!config.hideFacebookSelectedPost;
    this.selectedFacebookPost = config.selectedFacebookPost ? new FacebookPost(config.selectedFacebookPost) : undefined;
    this.hideTwitter = !!config.hideTwitter;
    this.hideTwitterFollowers = !!config.hideTwitterFollowers;
    this.hideTwitterFollowing = !!config.hideTwitterFollowing;
    this.hideTwitterTweets = !!config.hideTwitterTweets;
    this.hideInstagram = !!config.hideInstagram;
  }
}
