<snap-toggled-item [edit]="edit" [hideControl]="hideControl">
  <div class="heading flex align-space-between-center">
    <div *ngIf="edit && !editingMessage">
      <button mat-button (click)="editMessage()" [disableRipple]="true" class="edit-action-button">
        <mat-icon>mode_edit</mat-icon>
        <span>{{ 'EDIT_REPORT.EDIT_MESSAGE' | translate }}</span>
      </button>
    </div>
    <div *ngIf="edit && editingMessage" class="flex edit-actions-container">
      <button mat-button (click)="saveMessage(customizedMessage)" [disableRipple]="true" class="edit-action-button">
        <mat-icon>save</mat-icon>
        <span>{{ 'EDIT_REPORT.SAVE_MESSAGE' | translate }}</span>
      </button>
      <button mat-button (click)="cancelMessageEdit()" [disableRipple]="true" class="edit-action-button">
        <mat-icon>cancel</mat-icon>
        <span>{{ 'COMMON.CANCEL' | translate }}</span>
      </button>
      <button mat-button (click)="resetMessageToDefault()" [disableRipple]="true" class="edit-action-button">
        <mat-icon>refresh</mat-icon>
        <span>{{ 'EDIT_REPORT.RESET' | translate }}</span>
      </button>
    </div>
  </div>

  <div class="section-footer flex align-space-between-center">
    <div class="full-width cta section-footer-inner-container flex">
      <div *ngFor="let sp of [salesPerson$ | async]" class="section-footer-display full-width flex align-start-start">
        <div *ngIf="sp?.photoUrl">
          <img class="salesperson-image" [src]="sp.photoUrl" />
        </div>
        <div class="full-width flex section-footer-inner-container align-start-center">
          <div
            class="full-width"
            [ngClass]="{
              'section-footer-speech': sp?.photoUrl,
              'section-footer-message': !sp?.photoUrl
            }"
          >
            <div *ngIf="!editingMessage && !customizedMessage" #messageContent>
              {{
                'SECTION_FOOTER_MESSAGE.' + contentId
                  | translate
                    : {
                        companyName: (service.snapshotData$ | async).companyName
                      }
              }}
            </div>
            <div *ngIf="!editingMessage && customizedMessage">
              {{ customizedMessage }}
            </div>
            <mat-form-field *ngIf="editingMessage" class="full-width">
              <textarea matInput [(ngModel)]="customizedMessage" cdkAutosizeMinRows="2" cdkTextareaAutosize></textarea>
              <mat-hint align="end">{{ customizedMessage.length }}</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="flex-column align-center-center">
        <ng-container *ngIf="loading; else showCTAs">
          <mat-spinner [diameter]="20"></mat-spinner>
        </ng-container>
        <ng-template #showCTAs>
          <button mat-flat-button (click)="handleClick()" class="contact-button" data-testid="contact-button">
            {{ ctaLabel || (defaultCTALabel | translate) }}
          </button>
          <button *ngIf="edit" mat-button class="edit-cta" (click)="editCTA()" [disableRipple]="true">
            <mat-icon>mode_edit</mat-icon>
            <span>{{ 'EDIT_REPORT.EDIT_PRIMARY_BUTTON' | translate }}</span>
          </button>
        </ng-template>
      </div>
    </div>
    <ng-template snapItemToggle></ng-template>
  </div>
</snap-toggled-item>
