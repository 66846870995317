import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { ListingChartHeadingText, ListingChartReferenceData, ListingCompetitorsData } from '../chart/chart';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { ListingService } from '../listing.service';
import { ListingPresenceVersions } from '../listing-config';
import {
  SocialItemData,
  SocialItemFacebook,
  SocialItemGoogle,
  SocialItemInstagram,
  SocialItemTwitter,
} from '../presence/social-table/social-item-data';
import { map } from 'rxjs/operators';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';
import { ListingPresenceInterface } from '../listing-data';
import { formatNumber } from '../util';
import { ListingPresenceData } from '../presence/presence-table-item';
import { GradeColor } from '../../../core';
import { CompetitionAnalysis } from '@vendasta/snapshot';
import { BaseSectionComponent } from '../../section/base-section.component';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { SnapshotReport } from '../../snapshot-report/snapshot-report';
import { instagramSupportEndDate } from '../constants';

@Component({
  selector: 'snap-listing-presence-subsection',
  templateUrl: './listing-presence-subsection.component.html',
  styleUrls: ['../listing-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: ListingService,
    },
  ],
})
export class ListingPresenceSubsectionComponent extends BaseSectionComponent implements OnInit {
  listingPresenceChartTitles: ListingChartHeadingText = {
    title: 'LISTINGS.PRESENCE.FOUND_COMPETITORS',
    subtitle: 'LISTINGS.PRESENCE.COMPARE',
  };

  listingPresenceVersions = ListingPresenceVersions;
  socialItems$: Observable<SocialItemData[]> = new Observable<SocialItemData[]>();
  listingPresenceCompetitors$: Observable<ListingCompetitorsData[]> = new Observable<ListingCompetitorsData[]>();
  listingPresenceFoundCount$: Observable<number> = new Observable<number>();
  listingPresenceReferenceData$: Observable<ListingChartReferenceData> = new Observable<ListingChartReferenceData>();
  listingAvailableSources$: Observable<number> = new Observable<number>();
  presenceTableItems$: Observable<ListingPresenceData[]> = new Observable<ListingPresenceData[]>();

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: ListingService,
    public snapshotService: SnapshotReportService,
    public translateService: TranslateService,
    public snackbarService: SnackbarService,
  ) {
    super(service, snackbarService, translateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.listingPresenceCompetitors$ = combineLatest([
      this.service.data$,
      this.snapshotService.competitionAnalysis$,
    ]).pipe(
      map(([ld, ca]) => {
        if (ld.listingPresence == null || ca === CompetitionAnalysis.INDUSTRY_DATA) {
          return [];
        }
        return ld.listingPresence.competitors.map((c) => {
          return <ListingCompetitorsData>{
            name: c.companyName,
            updated: c.updated,
            value: c.foundCount,
          };
        });
      }),
    );

    this.presenceTableItems$ = this.service.data$.pipe(map((ld) => this.buildPresenceTableItems(ld.listingPresence)));

    this.socialItems$ = observableCombineLatest([this.service.data$, this.snapshotService.snapshot$]).pipe(
      map(([ld, snapshot]) => {
        return this.buildSocialItems(ld.listingPresence, snapshot);
      }),
    );

    this.listingPresenceFoundCount$ = this.service.data$.pipe(
      map((ld) => {
        if (ld.listingPresence != null && ld.listingPresence.foundCount != null) {
          return ld.listingPresence.foundCount;
        }
        return 0;
      }),
    );

    this.listingAvailableSources$ = this.service.data$.pipe(
      map((ld) => {
        if (ld.listingPresence != null && ld.listingPresence.availableSources != null) {
          return formatNumber(ld.listingPresence.availableSources);
        }
        return 60;
      }),
    );

    this.listingPresenceReferenceData$ = this.listingAvailableSources$.pipe(
      map((l) => {
        return <ListingChartReferenceData>{
          value: l,
          label: 'LISTINGS.PRESENCE.INDUSTRY_FOUND',
        };
      }),
    );
  }

  buildSocialItems(presenceItems: ListingPresenceInterface, snapshot: SnapshotReport): SocialItemData[] {
    const dataItems: SocialItemData[] = [];

    dataItems.push({
      ...SocialItemGoogle,
      foundCount: presenceItems.google?.foundCount,
      exampleListingUrl: presenceItems.google?.exampleListingUrl,
      industryAverageFound: formatNumber(presenceItems.google?.industryAverageFound),
      control: this.controls.controls['hideListingPresenceGoogle'],
    });

    dataItems.push({
      ...SocialItemFacebook,
      foundCount: presenceItems.facebook?.foundCount,
      exampleListingUrl: presenceItems.facebook?.exampleListingUrl,
      industryAverageFound: formatNumber(presenceItems.facebook?.industryAverageFound),
      control: this.controls.controls['hideListingPresenceFacebook'],
    });

    if (snapshot.created < instagramSupportEndDate) {
      dataItems.push({
        ...SocialItemInstagram,
        foundCount: presenceItems.instagram?.foundCount,
        exampleListingUrl: presenceItems.instagram?.exampleListingUrl,
        industryAverageFound: formatNumber(presenceItems.instagram?.industryAverageFound),
        control: this.controls.controls['hideListingPresenceInstagram'],
      });
    }

    dataItems.push({
      ...SocialItemTwitter,
      foundCount: presenceItems.twitter?.foundCount,
      exampleListingUrl: presenceItems.twitter?.exampleListingUrl,
      industryAverageFound: formatNumber(presenceItems.twitter?.industryAverageFound),
      control: this.controls.controls['hideListingPresenceTwitter'],
    });

    return dataItems;
  }

  buildPresenceTableItems(presenceItems: ListingPresenceInterface): ListingPresenceData[] {
    const dataItems: ListingPresenceData[] = [];
    if (presenceItems.google?.foundCount > 0) {
      dataItems.push({
        name: 'Google',
        icon: 'check_circle',
        color: GradeColor.positive,
        sourceIcon: 'https://www.cdnstyles.com/static/images/icon32/sourceId-10010.png',
        foundCount: presenceItems.google?.foundCount,
        exampleListingUrl: presenceItems.google?.exampleListingUrl,
        industryAverageFound: presenceItems.google?.industryAverageFound,
        control: this.controls.controls['hideListingPresenceGoogle'],
      });
    }
    if (presenceItems.facebook?.foundCount > 0) {
      dataItems.push({
        name: 'Facebook',
        icon: 'check_circle',
        color: GradeColor.positive,
        sourceIcon: 'https://www.cdnstyles.com/static/images/icon32/sourceId-10050.png',
        foundCount: presenceItems.facebook?.foundCount,
        exampleListingUrl: presenceItems.facebook?.exampleListingUrl,
        industryAverageFound: presenceItems.facebook?.industryAverageFound,
        control: this.controls.controls['hideListingPresenceFacebook'],
      });
    }
    if (presenceItems.foursquare?.foundCount > 0) {
      dataItems.push({
        name: 'Foursquare',
        icon: 'check_circle',
        color: GradeColor.positive,
        sourceIcon: 'https://www.cdnstyles.com/static/images/icon32/sourceId-10080.png',
        foundCount: presenceItems.foursquare?.foundCount,
        exampleListingUrl: presenceItems.foursquare?.exampleListingUrl,
        industryAverageFound: presenceItems.foursquare?.industryAverageFound,
        control: this.controls.controls['hideListingPresenceFoursquare'],
      });
    }
    if (presenceItems.twitter?.foundCount > 0) {
      dataItems.push({
        name: 'X',
        icon: 'check_circle',
        color: GradeColor.positive,
        sourceIcon: 'https://www.cdnstyles.com/static/images/icon32/sourceId-10060.png',
        foundCount: presenceItems.twitter?.foundCount,
        exampleListingUrl: presenceItems.twitter?.exampleListingUrl,
        industryAverageFound: presenceItems.twitter?.industryAverageFound,
        control: this.controls.controls['hideListingPresenceTwitter'],
      });
    }
    if (dataItems.length < 4) {
      let errorMessage = false;
      let name = '';
      let socialSite = false;
      let socialSites = false;
      if (4 - dataItems.length === 1) {
        errorMessage = true;
        name = '1';
        socialSite = true;
      }
      if (4 - dataItems.length > 1) {
        errorMessage = true;
        name = 4 - dataItems.length + '';
        socialSites = true;
      }
      dataItems.push({
        name: name,
        icon: 'cancel',
        color: GradeColor.negative,
        sourceIcon: '',
        foundCount: 0,
        exampleListingUrl: '',
        industryAverageFound: 0,
        errorMessage: errorMessage,
        showSocialSites: socialSites,
        showSocialSite: socialSite,
        control: this.controls.controls['hideListingPresenceMissing'],
      });
    }

    return dataItems;
  }

  computePresenceFillPercentage(n: number, d: number): string {
    return (n / d) * 100 + '%';
  }
}
