<snap-section-container
  [grade]="service.grade$ | async"
  [hideGrade]="snapshotService.hideGrades$ | async"
  [hideControl]="hideControl"
  [videoUrl]="service.videoUrl$ | async"
  [edit]="edit"
>
  <snap-advertising-section-header
    header
    [hideGrade]="snapshotService.hideGrades$ | async"
  ></snap-advertising-section-header>
  <snap-content-banner
    class="hide-mobile"
    [hideControl]="controls.controls['hideContent']"
    [messageControl]="controls.controls['customizedMessage']"
    [videoUrl]="service.videoUrl$ | async"
    [customizedMessage]="service.message$ | async"
    [contentId]="service.messageId$ | async"
    [edit]="edit"
    [hideVideo]="false"
  ></snap-content-banner>

  <snap-advertising-adwords-subsection [edit]="edit"></snap-advertising-adwords-subsection>
  <snap-campaign-performance-subsection [edit]="edit" [showPPC]="showPPC"></snap-campaign-performance-subsection>
  <snap-advertising-retargeting-subsection
    [edit]="edit"
    [showRetargeting]="showRetargeting"
  ></snap-advertising-retargeting-subsection>

  <snap-section-footer
    class="hide-mobile"
    data-testid="footer-advertising"
    [hideControl]="controls.controls['hideFooter']"
    [messageControl]="controls.controls['customizedFooterMessage']"
    [customizedMessage]="service.footerMessage$ | async"
    [edit]="edit"
    [contentId]="service.footerMessageId$ | async"
    [ctaLabel]="service.footerCtaLabel$ | async"
    [ctaTarget]="service.footerCtaURL$ | async"
    [targetProductId]="service.footerCtaProduct$ | async"
    [editButtonFormGroup]="controls.controls['footerCta']"
    [analyticsCategory]="footerCategory"
    (sectionFooterClick)="handleSectionFooterClick()"
    (sectionFooterEditClick)="handleSectionFooterEditClick($event)"
  ></snap-section-footer>
</snap-section-container>
