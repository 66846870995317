import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdvertisingSectionService } from '@vendasta/snapshot';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { bool2found } from '../common/ai-utils';
import { gradeToLetter } from '../common/utils';
import { GradeExplanationData } from '../grade/grade-explainer-dialog.component';
import { SectionInterface } from '../section/section';
import { AbstractSectionService } from '../section/section.service';
import { ContentService } from '../snapshot-report/content.service';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { AdvertisingConfig, AdvertisingConfigInterface } from './advertising-config';
import { AdvertisingContent } from './advertising-content';
import { AdvertisingData } from './advertising-data';
import { ChartData } from './adwords-bar-chart';

@Injectable()
export class AdvertisingService
  extends AbstractSectionService<AdvertisingContent, AdvertisingData, AdvertisingConfigInterface>
  implements OnDestroy
{
  sectionId = 'advertising';
  possibleImpressions$: Observable<number>;
  impressionsChartData$: Observable<ChartData>;
  clicks$: Observable<ChartData>;
  possibleClicks$: Observable<number>;

  constructor(
    private _api: AdvertisingSectionService,
    private _cs: ContentService,
    private _ss: SnapshotReportService,
    private translateService: TranslateService,
  ) {
    super(_cs, _ss);
    this.possibleImpressions$ = this.data$.pipe(map((adData) => this.mapPossibleImpressions(adData)));
    this.impressionsChartData$ = this.data$.pipe(map((adData) => this.mapImpressionChartData(adData)));
    this.possibleClicks$ = this.data$.pipe(map((adData) => this.mapPossibleClicks(adData)));
    this.clicks$ = this.data$.pipe(map((adData) => this.mapClicksChartData(adData)));
  }

  createGradeSubscription(): void {
    this.subscriptions.push(this.grade$.subscribe((g) => this._ss.setAdvertisingGrade(g)));
  }

  load(): Observable<SectionInterface> {
    return this._api.get(this.snapshotId).pipe(
      map((section) => {
        return {
          grade: section.grade,
          config: new AdvertisingConfig(section.config || {}),
          data: new AdvertisingData(section.data),
          content: new AdvertisingContent(section.content),
        };
      }),
    );
  }

  _updateConfig(config: AdvertisingConfigInterface): Observable<boolean> {
    return this._api.updateConfig(this.snapshotId, config);
  }

  newConfig(): AdvertisingConfigInterface {
    return new AdvertisingConfig({});
  }

  getGradeExplanationData(): GradeExplanationData {
    return {
      titleTextID: 'ADVERTISING.GRADE_EXPLANATION.TITLE',
      mobileTitleTextID: 'ADVERTISING.GRADE_EXPLANATION.MOBILE_TITLE',
      primaryTextID: 'ADVERTISING.GRADE_EXPLANATION.PRIMARY',
      clarificationTextID: 'ADVERTISING.GRADE_EXPLANATION.CLARIFICATION',
      showThermometer: true,
      secondaryPreScaleTextID: 'ADVERTISING.GRADE_EXPLANATION.SECONDARY_PRE_SCALE',
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getAiPromptDetails(): Observable<string> {
    return combineLatest([this._ss.advertisingSectionEnabled$, this.data$, this.grade$]).pipe(
      map(([sectionEnabled, data, grade]) => {
        if (!sectionEnabled) {
          return '';
        }
        const result: string[] = [];
        const letterGrade = gradeToLetter(grade);
        result.push(`Advertising grade: ${letterGrade}`);

        if (data?.payPerClickData?.business) {
          const kwMe = data.payPerClickData.business.numberOfPaidKeywords;
          const kwComp =
            data.payPerClickData.competitors
              ?.map((c) => c.numberOfPaidKeywords)
              ?.sort((a, b) => b - a)
              ?.shift() ?? 0;
          result.push(`- Paid keywords vs competitors: ${kwMe} / ${kwComp}`);

          const clicksMe = data.payPerClickData.business.paidClicksPerMonth;
          const clicksComp =
            data.payPerClickData.competitors
              ?.map((c) => c.paidClicksPerMonth)
              ?.sort((a, b) => b - a)
              ?.shift() ?? 0;
          result.push(`- Paid clicks vs competitors: ${clicksMe} / ${clicksComp}`);

          const budgetMe = data.payPerClickData.business.monthlyAdwordsBudget;
          const budgetComp =
            data.payPerClickData.competitors
              ?.map((c) => c.monthlyAdwordsBudget)
              ?.sort((a, b) => b - a)
              ?.shift() ?? 0;
          result.push(`- Monthly paid traffic budget vs competitors: ${budgetMe} / ${budgetComp}`);
        } else {
          result.push(`- Paid search: Not Found`);
        }

        const retargeting = bool2found(data?.isRetargeting);
        result.push(`- Retargeting: ${retargeting}`);

        return result.join('\n');
      }),
    );
  }

  formatNumber(n: number, precision: number): string {
    if (isNaN(n)) {
      return '';
    }
    return n.toLocaleString('en', {
      minimumFractionDigits: precision || 0,
      maximumFractionDigits: precision || 0,
      useGrouping: true,
    });
  }

  mapPossibleImpressions(adData: AdvertisingData): number {
    const topImpressions = adData.adwordsData.impressions.slice(0, 5);
    return topImpressions.reduce((ac, value) => ac + value.impressionsPerMonth.maximum, 0);
  }

  mapImpressionChartData(adData: AdvertisingData): ChartData {
    const topImpressions = adData.adwordsData.impressions.slice(0, 5);
    const dataItems: any[] = [];

    for (let i = 0; i < topImpressions.length; i++) {
      const average =
        (topImpressions[i].impressionsPerMonth.minimum + topImpressions[i].impressionsPerMonth.maximum) / 2;
      const tooltip = this.translateService.instant('ADVERTISING.ADWORDS.TOOLTIP.IMPRESSIONS', {
        impressions: this.formatNumber(average, 0),
      });
      dataItems.push([topImpressions[i].keyword, average, tooltip]);
    }
    return new ChartData(dataItems);
  }

  mapPossibleClicks(adData: AdvertisingData): number {
    const topClicks = adData.adwordsData.clicks.slice(0, 5);
    return topClicks.reduce((ac, value) => ac + value.clicksPerMonth.maximum, 0);
  }

  mapClicksChartData(adData: AdvertisingData): ChartData {
    const topClicks = adData.adwordsData.clicks.slice(0, 5);
    const dataItems: any[] = [];

    for (let i = 0; i < topClicks.length; i++) {
      const averageClicks = (topClicks[i].clicksPerMonth.minimum + topClicks[i].clicksPerMonth.maximum) / 2;
      const averageCost =
        (topClicks[i].averageCostPerClick.minimum + topClicks[i].averageCostPerClick.maximum) / 2 / 1000000;
      const tooltip = this.translateService.instant('ADVERTISING.ADWORDS.TOOLTIP.CLICKS', {
        clicks: this.formatNumber(averageClicks, 0),
        costPerClick: this.formatNumber(averageCost, 2),
      });
      dataItems.push([topClicks[i].keyword, averageClicks, tooltip]);
    }
    return new ChartData(dataItems);
  }
}
