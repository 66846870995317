import { EcommerceDataInterface } from '@vendasta/snapshot';
import { EcommerceCompetitor } from './ecommerce.competitor';
import { Application } from './ecommerce.application';

export const APPLICATIONS_AVAILABILITY = {
  ecommerceSolution: 'EcommerceSolution',
  onlinePayments: 'OnlinePayments',
  adRetargeting: 'AdRetargeting',
  appointmentScheduling: 'AppointmentScheduling',
};

export class EcommerceData implements EcommerceDataInterface {
  ecommerceSolution: Application[];
  onlinePayments: Application[];
  adRetargeting: Application[];
  appointmentScheduling: Application[];
  applicationsAvailability: { [key: string]: boolean };
  competitors?: EcommerceCompetitor[];
  website: string;

  constructor(data: EcommerceDataInterface) {
    this.ecommerceSolution = data.ecommerceSolution ? data.ecommerceSolution.map((i) => new Application(i)) : [];
    this.onlinePayments = data.onlinePayments ? data.onlinePayments.map((i) => new Application(i)) : [];
    this.adRetargeting = data.adRetargeting ? data.adRetargeting.map((i) => new Application(i)) : [];
    this.appointmentScheduling = data.appointmentScheduling
      ? data.appointmentScheduling.map((i) => new Application(i))
      : [];
    this.applicationsAvailability = data.applicationsAvailability;
    this.competitors = (data.competitors || []).map((c) => {
      return new EcommerceCompetitor(c);
    });
    this.website = data.website || '';
  }
}
