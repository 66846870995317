import {
  AfterViewInit,
  booleanAttribute,
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  model,
  Output,
  signal,
} from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { ReplaySubject, firstValueFrom } from 'rxjs';
import { PackageInterface } from '@vendasta/marketplace-packages';
import { MarketplaceService } from '../../services/marketplace.service';
import { WhitelabelService } from '@galaxy/snapshot';

const URL_REGEX = new RegExp(/^((https?:\/\/)?([\w-]+(\.[\w-]+)+|localhost)\.?(:\d+)?((\/)?\S*)?)$/i);

export enum ctaTargetOptions {
  CONTACT_FORM = 0,
  PRODUCT_ID = 1,
  URL = 2,
}

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss'],
})
export class EditButtonComponent implements AfterViewInit {
  label = model<string>();
  targetUrl = model<string>();
  targetProductId = model<string>();
  @Input({ transform: booleanAttribute }) toolbarPreview = false;
  @Output() validationMessageChange = new EventEmitter<string | undefined>();

  public readonly whitelabelService = inject(WhitelabelService);
  private readonly marketplaceService = inject(MarketplaceService);

  selectedCtaTargetOption: number;
  targetProduct: PackageInterface;

  marketplaceProducts$$ = new ReplaySubject<PackageInterface[]>(1);
  loading$$ = new ReplaySubject<boolean>(1);

  isUrlInvalid = signal(false);
  isProductInvalid = signal(false);
  isProductAvailable = signal(true);
  protected readonly defaultLabel = 'COMMON.GET_IN_TOUCH';
  protected readonly reportName$ = this.whitelabelService.snapshotReportName$;
  protected readonly logoUrl$ = this.whitelabelService.logoUrl$;

  constructor() {
    effect(() => {
      const isUrlInvalid = this.isUrlInvalid();
      const isProductInvalid = this.isProductInvalid();
      const isProductAvailable = this.isProductAvailable();
      if (isUrlInvalid) {
        this.validationMessageChange.emit('EDIT_REPORT.ERRORS.CUSTOM_URL_INVALID');
      } else if (isProductInvalid) {
        this.validationMessageChange.emit('EDIT_REPORT.ERRORS.PACKAGE_ID_INVALID');
      } else if (!isProductAvailable) {
        this.validationMessageChange.emit('EDIT_REPORT.ERRORS.DEACTIVATED_PACKAGE_ID');
      } else {
        this.validationMessageChange.emit(undefined);
      }
    });
  }

  ngAfterViewInit(): void {
    this.initAsyncValues();
  }

  private async initAsyncValues(): Promise<void> {
    this.loading$$.next(true);
    await this.setupForm();
    this.loading$$.next(false);
  }

  private async setupForm(): Promise<void> {
    const marketplaceProducts = await firstValueFrom(this.marketplaceService.activatablePackages$);
    this.marketplaceProducts$$.next(marketplaceProducts);

    const targetProductId = this.targetProductId();
    const targetProduct = marketplaceProducts.find((s) => s.packageId === targetProductId);
    if (targetProductId && targetProduct) {
      this.selectedCtaTargetOption = ctaTargetOptions.PRODUCT_ID;
      this.targetProduct = targetProduct;
    } else if (this.targetUrl()) {
      this.selectedCtaTargetOption = ctaTargetOptions.URL;
    } else {
      this.selectedCtaTargetOption = ctaTargetOptions.CONTACT_FORM;
      this.isProductAvailable.set(!targetProductId);
    }
  }

  async navigateToURL(): Promise<void> {
    if (this.selectedCtaTargetOption === ctaTargetOptions.PRODUCT_ID) {
      const url = await firstValueFrom(this.marketplaceService.packageUrl$(this.targetProduct.packageId));
      window.open(url, '_blank', 'noopener');
    }
    if (this.selectedCtaTargetOption === ctaTargetOptions.URL) {
      window.open(this.targetUrl(), '_blank', 'noopener');
    }
  }

  onFocusTargetUrlInput(): void {
    this.isProductAvailable.set(true);
    this.selectedCtaTargetOption = ctaTargetOptions.URL;
    this.targetProduct = { packageId: '', name: '', icon: '' };
    this.targetProductId.set('');
  }

  resetNoSelectedTargetCtaOptions(event: MatRadioChange): void {
    // To hide error message of previous selected product is not valid
    this.isProductAvailable.set(true);
    this.validateSelectedProduct(event.value);
    this.validateCustomUrl(event.value);
    switch (event.value) {
      case ctaTargetOptions.URL:
        this.targetProduct = { packageId: '', name: '', icon: '' };
        this.targetProductId.set('');
        break;
      case ctaTargetOptions.PRODUCT_ID:
        this.targetUrl.set('');
        break;
      case ctaTargetOptions.CONTACT_FORM:
        this.targetProduct = { packageId: '', name: '', icon: '' };
        this.targetProductId.set('');
        this.targetUrl.set('');
        break;
    }
  }

  validateCustomUrl(selected: number): void {
    if (selected === undefined) {
      return;
    }
    const invalidUrl = !URL_REGEX.test(this.targetUrl());
    this.isUrlInvalid.set(selected === ctaTargetOptions.URL && invalidUrl);
  }

  validateSelectedProduct(selected: number): void {
    const isProductInvalid = selected === ctaTargetOptions.PRODUCT_ID && !this.targetProduct?.packageId;
    this.isProductInvalid.set(isProductInvalid);
    if (!isProductInvalid) {
      this.targetProductId.set(this.targetProduct?.packageId);
    }
  }
}
