<mat-spinner *ngIf="loading$$ | async" mode="indeterminate" diameter="25"></mat-spinner>
<ng-container *ngIf="showScheduleMeetingMenu$ | async">
  <button
    mat-button
    class="schedule-meeting"
    [trackEvent]="{
      eventName: 'schedule-meeting',
      category: 'anchor',
      action: 'click'
    }"
    [matMenuTriggerFor]="belowMenu"
  >
    <mat-icon>event</mat-icon>
    {{ 'BOOKED_MEETINGS.BOOK_A_MEETING' | translate }}
  </button>
  <mat-menu #belowMenu="matMenu" [overlapTrigger]="false" yPosition="below">
    <ng-container *ngFor="let m of meetingTypeList$ | async">
      <button mat-menu-item (click)="this.scheduleMeetingService.openBookingUrl(m.bookingUrl)">
        {{ m.name }}
      </button>
    </ng-container>
  </mat-menu>
</ng-container>
