<ng-container *ngIf="business?.numberOfOrganicKeywords > 0 || competitors?.length > 0; else noOrganicKeywords">
  <ng-container *ngIf="media.mobile$ | async; else desktopPerformance">
    <snap-mobile-view-data [hideGrades]="true" [subsections]="competitorItems"></snap-mobile-view-data>
  </ng-container>
  <ng-template #desktopPerformance>
    <snap-table [items]="competitorItems" [showIndustryAverage]="false" [linkCompetitors]="true"></snap-table>
  </ng-template>
</ng-container>

<ng-template #noOrganicKeywords>
  <div class="not-found">
    <glxy-empty-state [size]="'small'" [widthConstraint]="false">
      <glxy-empty-state-hero>
        <mat-icon>travel_explore</mat-icon>
      </glxy-empty-state-hero>
      <glxy-empty-state-title>
        {{ 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.NOT_AVAILABLE_TITLE' | translate }}
      </glxy-empty-state-title>
      <p>
        {{ 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.NOT_AVAILABLE_MESSAGE' | translate }}
      </p>
    </glxy-empty-state>
  </div>
</ng-template>
