@if ((loading$$ | async) === false) {
  <div class="container">
    <div class="field--container">
      <glxy-form-field class="field--label">
        <glxy-label>{{ 'EDIT_REPORT.BUTTON_LABEL' | translate }}</glxy-label>
        <input matInput [(ngModel)]="label" />
      </glxy-form-field>
      <glxy-form-field bottomSpacing="none">
        <glxy-label>
          {{ 'EDIT_REPORT.LINK_TO' | translate }}
        </glxy-label>
        <mat-radio-group [(ngModel)]="selectedCtaTargetOption">
          <mat-radio-button [value]="0" (change)="resetNoSelectedTargetCtaOptions($event)" value="default">
            {{ 'EDIT_REPORT.CONTACT_US_FORM' | translate }}
          </mat-radio-button>
          @if (marketplaceProducts$$ | async; as marketplaceProducts) {
            <mat-radio-button
              [value]="1"
              (change)="resetNoSelectedTargetCtaOptions($event)"
              [disabled]="marketplaceProducts.length <= 0"
            >
              <div class="field--radio-label">{{ 'EDIT_REPORT.PACKAGE' | translate }}</div>
              <glxy-form-field bottomSpacing="none">
                <mat-select
                  [(ngModel)]="targetProduct"
                  (selectionChange)="validateSelectedProduct(this.selectedCtaTargetOption)"
                >
                  <mat-select-trigger>
                    <div class="product--container">
                      @if (targetProduct?.icon; as icon) {
                        <img [src]="icon" class="product--icon" />
                      } @else {
                        <mat-icon class="product--icon">store</mat-icon>
                      }
                      <span class="product--name">{{ targetProduct?.name }}</span>
                    </div>
                  </mat-select-trigger>
                  @if (marketplaceProducts.length <= 0) {
                    <mat-option [disabled]="true">
                      {{ 'EDIT_REPORT.NO_PACKAGES_IN_STORE' | translate }}
                    </mat-option>
                  }
                  @for (product of marketplaceProducts; track $index) {
                    <mat-option [value]="product">
                      @if (product.icon; as icon) {
                        <img [src]="icon" class="product--icon" />
                      } @else {
                        <mat-icon class="product--icon">store</mat-icon>
                      }
                      <span class="product--name">{{ product.name }}</span>
                    </mat-option>
                  }
                </mat-select>
              </glxy-form-field>
            </mat-radio-button>
          }
          <mat-radio-button [value]="2" (change)="resetNoSelectedTargetCtaOptions($event)">
            <div class="field--radio-label">{{ 'EDIT_REPORT.CUSTOM_BUTTON_URL' | translate }}</div>
            <glxy-form-field bottomSpacing="none">
              <input
                matInput
                [(ngModel)]="targetUrl"
                (ngModelChange)="validateCustomUrl(this.selectedCtaTargetOption)"
                (focus)="onFocusTargetUrlInput()"
              />
            </glxy-form-field>
          </mat-radio-button>
        </mat-radio-group>
      </glxy-form-field>
    </div>
    <div class="preview--container">
      <div class="preview--label">{{ 'EDIT_REPORT.PREVIEW' | translate }}</div>
      <div class="preview--frame">
        <ng-container *ngTemplateOutlet="toolbarPreview ? withToolbarPreview : noToolbarPreview"></ng-container>
      </div>
    </div>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="loading"></ng-container>
}

<ng-template #loading>
  <glxy-loading-spinner [fullWidth]="true"></glxy-loading-spinner>
</ng-template>

<ng-template #noToolbarPreview>
  <button type="button" class="preview--button" mat-flat-button color="primary" (click)="navigateToURL()">
    {{ label() || (defaultLabel | translate) }}
  </button>
</ng-template>

<ng-template #withToolbarPreview>
  <app-skeleton-toolbar
    class="preview--toolbar"
    [logoUrl]="logoUrl$ | async"
    [reportName]="reportName$ | async"
    [ctaLabel]="label()"
    [ctaTargetUrl]="targetUrl()"
    [ctaTargetProduct]="targetProductId()"
    (ctaClick)="navigateToURL()"
  ></app-skeleton-toolbar>
</ng-template>
