import { Pipe, PipeTransform } from '@angular/core';
import { Observable, catchError, defaultIfEmpty, map, of, startWith } from 'rxjs';

// Based on https://medium.com/angular-in-depth/9d8e5497dd8
// Thanks Alexey Zuev!

export enum Status {
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
  empty = 'empty',
}

export type GlxyAsyncStatus<T> =
  | {
      status: Status.loading;
    }
  | {
      status: Status.error;
      error?: string | Error;
    }
  | {
      status: Status.loaded;
      value: T;
      $implicit?: T;
    }
  | {
      status: Status.empty;
    };

export function isGalaxyAsyncStatus<T>(value: unknown): value is GlxyAsyncStatus<T> {
  // must have a status entry to be a valid GlxyAsyncStatus
  const statusKey = 'status';
  if (value === null || typeof value !== 'object') return false;
  if (!(statusKey in value)) return false;
  if (typeof value[statusKey] !== 'string') return false;
  if (!Object.values(Status).includes(value[statusKey] as Status)) return false;

  // if there is an error key, make sure it is the correct type
  if (value.status === Status.error) {
    const errorKey = 'error';
    if (errorKey in value && typeof value[errorKey] !== 'string' && !(value[errorKey] instanceof Error)) return false;
  }

  // if loaded status, make sure there is a value key, and that it is not undefined
  const valueKey = 'value';
  const implicitKey = '$implicit';
  if (value.status === Status.loaded) {
    if (!(valueKey in value)) return false;
    if (typeof value[valueKey] === 'undefined') return false;
  } else {
    if (valueKey in value && typeof value[valueKey] !== 'undefined') return false;
    if (implicitKey in value && typeof value[implicitKey] !== 'undefined') return false;
  }

  return true;
}

const defaultError = 'Something went wrong';

@Pipe({
  name: 'glxyAsyncStatus',
})
export class GalaxyAsyncStatusPipe implements PipeTransform {
  transform<T>(val: Observable<GlxyAsyncStatus<T> | T>): Observable<GlxyAsyncStatus<T>> {
    return val.pipe(
      map((value) => {
        if (
          value === null ||
          typeof value === 'undefined' ||
          (typeof value === 'object' && Object.keys(value).length == 0) ||
          (Array.isArray(value) && value.length === 0)
        ) {
          return {
            status: Status.empty,
          } as GlxyAsyncStatus<T>;
        }

        if (isGalaxyAsyncStatus<T>(value)) {
          if (value.status === Status.error && !value.error) {
            return {
              status: Status.error,
              error: defaultError,
            } as GlxyAsyncStatus<T>;
          }

          if (value.status === Status.loaded && !value.$implicit) {
            return {
              status: Status.loaded,
              value: value.value,
              $implicit: value.value,
            } as GlxyAsyncStatus<T>;
          }

          return value;
        }

        return {
          status: Status.loaded,
          value,
          $implicit: value,
        } as GlxyAsyncStatus<T>;
      }),
      defaultIfEmpty({ status: Status.empty } as GlxyAsyncStatus<T>),
      startWith({ status: Status.loading } as GlxyAsyncStatus<T>),
      catchError((error) => {
        let errorText = defaultError;
        if (typeof error === 'string') {
          errorText = error;
        } else if (error instanceof Error) {
          errorText = error.message;
        }

        console.log('glxyAsyncStatus Error - ' + error.name + ': ' + errorText);
        return of({
          status: Status.error,
          error: errorText,
        } as GlxyAsyncStatus<T>);
      }),
    );
  }
}
