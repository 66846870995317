<div *ngIf="markers.length > 1; else oneMarker">
  <google-map
    #map
    height="350px"
    width="100%"
    [options]="{
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    gestureHandling: 'none',
    scrollwheel: false,
  }"
  >
    <div *ngFor="let marker of markers; let i = index">
      <map-marker
        [position]="{ lat: marker.lat, lng: marker.lng }"
        [icon]="marker.icon"
        [label]="marker.label"
        (mapClick)="onMarkerClick(i, search?.data?.vicinity)"
      ></map-marker>
    </div>
  </google-map>
</div>
<ng-template #oneMarker>
  <google-map
    height="350px"
    width="100%"
    [options]="{
      disableDefaultUI: true,
      disableDoubleClickZoom: true,
      gestureHandling: 'none',
      scrollwheel: false,
      center: { lat: search?.centerMap?.latitude, lng: search?.centerMap?.longitude }
    }"
    [zoom]="search?.data?.vicinity === Vicinity.VICINITY_CITY ? 9 : 13"
  >
    <map-marker
      *ngIf="markers?.length === 1"
      [position]="{ lat: markers[0].lat, lng: markers[0].lng }"
      [icon]="markers[0].icon"
      [label]="markers[0].label"
      (mapClick)="onMarkerClick(0, search?.data?.vicinity)"
    ></map-marker>
  </google-map>
</ng-template>
