import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { Grade } from '@vendasta/snapshot';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BaseSectionComponent } from '../../section/base-section.component';
import { SectionServiceInterfaceToken } from '../../section/section.service';
import { SnapshotReportService } from '../../snapshot-report/snapshot-report.service';
import { WebsiteData } from '../website-data';
import { WebsiteService } from '../website.service';

@Component({
  selector: 'snap-website-section-content',
  templateUrl: './website-section-content.component.html',
  styleUrls: ['../website-section.component.scss'],
  providers: [
    {
      provide: SectionServiceInterfaceToken,
      useExisting: WebsiteService,
    },
  ],
})
export class WebsiteSectionContentComponent extends BaseSectionComponent implements OnInit {
  @Input() showBanner = true;
  @Output() hasWebsiteData = new EventEmitter<boolean>();

  hasAllSubsectionsHidden$: Observable<boolean>;
  hasWebsite$: Observable<boolean>;
  isFacebookPage$: Observable<boolean>;
  sameLocale$: Observable<boolean>;

  websiteUrlControl: UntypedFormControl = new UntypedFormControl('');
  websiteWorking = false;

  constructor(
    @Inject(SectionServiceInterfaceToken) public service: WebsiteService,
    public snapshotService: SnapshotReportService,
    public translate: TranslateService,
    public snackbar: SnackbarService,
  ) {
    super(service, snackbar, translate);

    this.hasAllSubsectionsHidden$ = this.service.config$.pipe(
      map((c) => c.hideHomepageContent && c.hideDesktop && c.hideMobile),
    );

    this.hasWebsite$ = combineLatest([this.service.data$, this.snapshotService.snapshotData$]).pipe(
      map(([wd, sd]) => {
        if (sd.website || (this.hasValidWebsiteData(wd) && sd.inferredFields?.website)) {
          this.hasWebsiteData.emit(true);
          return true;
        }
        this.hasWebsiteData.emit(false);
        return false;
      }),
    );

    this.isFacebookPage$ = this.snapshotService.snapshotData$.pipe(
      map((d) => {
        if (d.website) {
          return this.isFacebookPage(d.website);
        }
        if (d.inferredFields?.website) {
          return this.isFacebookPage(d.inferredFields.website);
        }
        return false;
      }),
    );

    this.sameLocale$ = combineLatest([this.service.data$, this.snapshotService.snapshotConfig$]).pipe(
      map(([data, config]) => {
        if (data.desktopInsights.grade !== Grade.NO_GRADE) {
          return data.desktopInsights.locale === config.locale;
        }
        if (data.mobileInsights.grade !== Grade.NO_GRADE) {
          return data.mobileInsights.locale === config.locale;
        }
        return true;
      }),
      distinctUntilChanged(),
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  isFacebookPage(websiteUrl: string): boolean {
    return websiteUrl.includes('facebook.com');
  }

  addWebsiteUrl(): void {
    const websiteUrl = this.websiteUrlControl.value;
    if (!websiteUrl) {
      return;
    }
    this.websiteUrlControl.markAsPending();
    this.service.updateWebsiteUrl(this.snapshotService.businessId, this.websiteUrlControl.value).subscribe(
      () => {
        this.websiteWorking = true;
      },
      (err) => {
        this.websiteUrlControl.setErrors({ error: err.message });
        this.websiteUrlControl.setErrors({ message: err.message });
      },
    );
  }

  hasValidWebsiteData(wd: WebsiteData): boolean {
    return (
      wd.homepageData?.grade !== Grade.NO_GRADE &&
      wd.desktopInsights?.grade !== Grade.NO_GRADE &&
      wd.mobileInsights?.grade !== Grade.NO_GRADE
    );
  }
}
