<snap-toggled-item [edit]="edit" [hideControl]="hideControl">
  <div class="banner-heading">
    <div *ngIf="edit && !editingMessage">
      <button mat-button (click)="editMessage()" [disableRipple]="true" class="edit-action-button">
        <mat-icon>mode_edit</mat-icon>
        <span>{{ 'EDIT_REPORT.EDIT_MESSAGE' | translate }}</span>
      </button>
    </div>
    <div *ngIf="edit && editingMessage">
      <button mat-button (click)="saveMessage(customizedMessage)" [disableRipple]="true" class="edit-action-button">
        <mat-icon>save</mat-icon>
        <span>{{ 'EDIT_REPORT.SAVE_MESSAGE' | translate }}</span>
      </button>
      <button mat-button (click)="cancel()" [disableRipple]="true" class="edit-action-button">
        <mat-icon>cancel</mat-icon>
        <span>{{ 'COMMON.CANCEL' | translate }}</span>
      </button>
      <button mat-button (click)="resetToDefault()" [disableRipple]="true" class="edit-action-button">
        <mat-icon>refresh</mat-icon>
        <span>{{ 'EDIT_REPORT.RESET' | translate }}</span>
      </button>
    </div>
    <ng-template snapItemToggle></ng-template>
  </div>
  <mat-progress-bar *ngIf="messageControl?.pending" mode="indeterminate"></mat-progress-bar>
  <div [@toggleSection]="hideControl && hideControl.value ? 'off' : 'on'">
    <div class="banner">
      <iframe
        *ngIf="videoUrl && !hideVideo"
        allowtransparency="true"
        frameborder="0"
        scrolling="no"
        class="wistia_embed"
        name="wistia_embed"
        allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        width="220"
        height="135"
        [src]="videoUrl | safe"
      ></iframe>
      <div *ngIf="!editingMessage && !customizedMessage" #messageContent class="message">
        {{ 'BANNER_MESSAGE.' + contentId | translate }}
      </div>
      <div *ngIf="!editingMessage && customizedMessage" class="message">
        {{ customizedMessage }}
      </div>
      <mat-form-field *ngIf="editingMessage">
        <textarea
          class="message"
          matInput
          [(ngModel)]="customizedMessage"
          cdkTextareaAutosize
          cdkAutosizeMinRows="5"
        ></textarea>
        <mat-hint align="end">{{ customizedMessage.length }}</mat-hint>
      </mat-form-field>
    </div>
  </div>
</snap-toggled-item>
