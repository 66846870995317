<mat-card appearance="outlined" class="performance-card">
  <mat-card-header>
    <mat-card-title>
      {{ title }}
      <a
        class="hide-to-print"
        target="_blank"
        rel="nofollow noopener noreferrer"
        href="{{ learnMoreUrl }}"
        [trackEvent]="{
          eventName: 'learn-more-web-vitals',
          category: 'cta',
          action: 'click'
        }"
      >
        <mat-icon>help_outline</mat-icon>
      </a>
    </mat-card-title>
    <mat-card-subtitle class="hide-mobile">
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
