<ng-container *ngIf="hasTechnologies$ | async; else noTechnologiesTemplate">
  <ng-container *ngIf="categories$ | async as categories; else noTechnologiesTemplate">
    <div class="category-container">
      <div class="category-item" *ngFor="let category of categories | slice : 0 : limitRows">
        <snap-technology-category [technologyCategory]="category"></snap-technology-category>
      </div>
    </div>

    <div *ngIf="showAllRows">
      <mat-divider></mat-divider>
      <div class="category-container">
        <div class="category-item" *ngFor="let category of categories | slice : limitRows : totalRows">
          <snap-technology-category [technologyCategory]="category"></snap-technology-category>
        </div>
      </div>
    </div>

    <div class="view-more" *ngIf="totalRows > limitRows">
      <button mat-button color="primary" (click)="toggleRows()">
        <div class="view-more-label">
          <ng-container *ngIf="!showAllRows">
            <span>{{ 'TECHNOLOGY.VIEW_MORE' | translate }}</span>
          </ng-container>
          <ng-container *ngIf="showAllRows">
            <span>{{ 'TECHNOLOGY.VIEW_LESS' | translate }}</span>
          </ng-container>
        </div>
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #noTechnologiesTemplate>
  <div class="not-found">
    <glxy-empty-state [widthConstraint]="false" [size]="'small'">
      <glxy-empty-state-hero>
        <mat-icon>computer</mat-icon>
      </glxy-empty-state-hero>
      <glxy-empty-state-title>
        <div class="empty-state-title">
          {{ 'TECHNOLOGY.NO_SITE_ERROR_TITLE' | translate }}
        </div>
      </glxy-empty-state-title>
      <p class="empty-state-text">
        {{ 'TECHNOLOGY.NO_SITE_ERROR_DESCRIPTION' | translate }}
      </p>
    </glxy-empty-state>
  </div>
</ng-template>
