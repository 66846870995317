import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Subsection } from './multi-subsection';

@Component({
  selector: 'snap-multi-subsection',
  templateUrl: './multi-subsection.component.html',
  styleUrls: ['./multi-subsection.component.scss'],
  animations: [
    trigger('toggleSection', [
      state('on', style({ height: '*' })),
      state('off', style({ height: '0' })),
      transition('on <=> off', animate('600ms ease-in')),
    ]),
  ],
})
export class MultiSubsectionComponent {
  @Input() subsections: Subsection[];
  @Input() subheading: string;
  @Input() hideGrade: boolean;
  @Input() edit: boolean;

  get hide(): boolean {
    return this.subsections.length === this.subsections.filter((s) => s.hideControl && s.hideControl.value).length;
  }
}
