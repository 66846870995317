import { map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { ContentService } from '../snapshot-report/content.service';
import { AbstractSectionService } from '../section/section.service';
import { SEOSectionService, Vicinity } from '@vendasta/snapshot';
import { SEOContent } from './seo-content';
import { SEOData } from './seo-data';
import { SEOConfig, SEOConfigInterface } from './seo-config';
import { Observable, combineLatest } from 'rxjs';
import { SectionInterface } from '../section/section';
import { GradeExplanationData } from '../grade/grade-explainer-dialog.component';
import { gradeToLetter } from '../common/utils';
import { GalaxyOrdinalPipe } from '@vendasta/galaxy/pipes';

@Injectable()
export class SEOService extends AbstractSectionService<SEOContent, SEOData, SEOConfigInterface> implements OnDestroy {
  sectionId = 'seo';

  constructor(private _api: SEOSectionService, private _cs: ContentService, private _ss: SnapshotReportService) {
    super(_cs, _ss);
  }

  createGradeSubscription(): void {
    this.subscriptions.push(this.grade$.subscribe((g) => this._ss.setSEOGrade(g)));
  }

  load(): Observable<SectionInterface> {
    return this._api.get(this.snapshotId).pipe(
      map((section) => {
        return {
          grade: section.grade,
          config: new SEOConfig(section.config || {}),
          data: new SEOData(section.data),
          content: new SEOContent(section.content),
        };
      }),
    );
  }

  _updateConfig(config: SEOConfigInterface): Observable<boolean> {
    return this._api.updateConfig(this.snapshotId, config);
  }

  newConfig(): SEOConfigInterface {
    return new SEOConfig({});
  }

  getGradeExplanationData(): GradeExplanationData {
    return {
      titleTextID: 'LOCAL_SEO.GRADE_EXPLANATION.TITLE',
      mobileTitleTextID: 'LOCAL_SEO.GRADE_EXPLANATION.MOBILE_TITLE',
      primaryTextID: 'LOCAL_SEO.GRADE_EXPLANATION.PRIMARY',
      clarificationTextID: 'LOCAL_SEO.GRADE_EXPLANATION.CLARIFICATION',
      showThermometer: true,
      secondaryPreScaleTextID: 'LOCAL_SEO.GRADE_EXPLANATION.SECONDARY_PRE_SCALE',
      secondaryPostScaleTextID: 'LOCAL_SEO.GRADE_EXPLANATION.SECONDARY_POST_SCALE',
      showGradeScale: true,
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getAiPromptDetails(): Observable<string> {
    const ordinalPipe = new GalaxyOrdinalPipe();
    const rankFn = (rank?: number) => {
      if (rank > 0) {
        return `Ranked ${ordinalPipe.transform(rank)}`;
      }
      return `Business wasn't found in the top 100 search results`;
    };

    return combineLatest([this._ss.seoSectionEnabled$, this.data$, this.grade$]).pipe(
      map(([sectionEnabled, data, grade]) => {
        if (!sectionEnabled) {
          return '';
        }
        const result: string[] = [];
        const letterGrade = gradeToLetter(grade);
        result.push(`SEO grade: ${letterGrade}`);
        if (data.localSEOData?.searchKeyword) {
          result.push(`Based on keyword: ${data.localSEOData?.searchKeyword}`);
        }
        const nearMeSearch = data?.localSEOData?.searches?.find((s) => s.vicinity === Vicinity.VICINITY_NEARME);
        const nearMeRank = rankFn(nearMeSearch?.results?.find((r) => r.isMainBusiness)?.rank);
        result.push(`- Local SEO performance (Near me):  ${nearMeRank}`);
        const citySearch = data?.localSEOData?.searches?.find((s) => s.vicinity === Vicinity.VICINITY_CITY);
        const cityRank = rankFn(citySearch?.results?.find((r) => r.isMainBusiness)?.rank);
        result.push(`- Local SEO performance (City): ${cityRank}`);

        const searchTerms = data?.organicDomainData?.business?.numberOfOrganicKeywords ?? '';
        result.push(`Number of organic search ranked search terms: ${searchTerms}`);
        const keywords = data?.organicKeywordData?.business?.keywords ?? [];
        if (keywords.length > 0) {
          keywords.forEach((v, i) => result.push(`- Ranked keyword ${i + 1} / Rank: ${v.term} / ${v.position}`));
        } else {
          result.push(
            `We couldn't find your site in the top 50 search results for any of the organic keywords we track—that means potential customers won't be able to either.`,
          );
        }

        return result.join('\n');
      }),
    );
  }
}
