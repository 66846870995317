import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share, map } from 'rxjs/operators';

import { EnvironmentService } from '@galaxy/core';
import { Environment } from '@galaxy/core';
import { SessionService } from '@galaxy/core';
import { ProjectionFilter } from './projection-filter';
import { ReadFilter } from './read-filter';
import { ApiLookupFilter } from './lookup-filters';
import { PagedResponse } from './account-group.api.service.response';
import { AccountGroup } from './account-group';
import { UpdateOperations } from './update-operations';
import { SortOptions } from './sort-options';
import { SearchOptions } from './search-options';

@Injectable({ providedIn: 'root' })
export class AccountGroupApiService {
  private host: string;
  private lookupUrl = `${this.accountGroupHost}/v1/account-group/lookup`;
  private getMultiUrl = `${this.accountGroupHost}/v1/account-group/get-multi`;
  private bulkUpdateUrl = `${this.accountGroupHost}/v1/account-group/bulk-update`;
  private sessionId = '';

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private sessionService: SessionService,
  ) {
    this.sessionService.getSessionId().subscribe((sessionId) => {
      this.sessionId = sessionId;
    });
  }

  private apiOptions(): { headers: HttpHeaders; withCredentials: boolean } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
  }

  get(accountGroupId: string, projectionFilter: ProjectionFilter): Observable<AccountGroup> {
    return this.getMulti([accountGroupId], projectionFilter).pipe(
      map((accountGroups) => accountGroups[0]),
      share(),
    );
  }

  getMulti(
    accountGroupIds: string[],
    projectionFilter: ProjectionFilter,
    readFilter?: ReadFilter,
  ): Observable<AccountGroup[]> {
    return this.http
      .post(
        this.getMultiUrl,
        {
          accountGroupIds: accountGroupIds,
          projectionFilter: projectionFilter,
          readFilter: readFilter,
        },
        this.apiOptions(),
      )
      .pipe(
        map((body: any): AccountGroup[] =>
          body.accountGroups.map((accountGroupContainer: any) =>
            AccountGroup.fromProto(accountGroupContainer.accountGroup),
          ),
        ),
        share(),
      );
  }

  lookup(
    projectionFilter: ProjectionFilter,
    filters: ApiLookupFilter,
    cursor: string,
    pageSize: number,
    searchTerm: string,
    sortOptions: SortOptions,
    searchOptions?: SearchOptions,
  ): Observable<PagedResponse> {
    return this.http
      .post(
        this.lookupUrl,
        {
          filters: filters.toApiJson(),
          pageSize: pageSize,
          projectionFilter: projectionFilter,
          cursor: cursor,
          searchTerm: searchTerm,
          sortOptions: sortOptions,
          searchOptions,
        },
        this.apiOptions(),
      )
      .pipe(map(this.handlePagedResponse), share());
  }

  bulkUpdate(accountGroupId: string, updateOperations: UpdateOperations): Observable<HttpResponse<null>> {
    return this.http.post<null>(
      this.bulkUpdateUrl,
      {
        accountGroupId: accountGroupId,
        updateOperations: updateOperations.toApiJson(),
      },
      this.apiOptions(),
    );
  }

  private handlePagedResponse(body: any): PagedResponse {
    if (!body.accountGroups) {
      return {
        accountGroups: [],
        nextCursor: '',
        hasMore: false,
        totalResults: 0,
      };
    }
    return {
      accountGroups: body.accountGroups.map(AccountGroup.fromProto),
      nextCursor: body.nextCursor || '',
      hasMore: body.hasMore || false,
      totalResults: Number(body.totalResults) || 0,
    };
  }

  private get accountGroupHost(): string {
    if (this.host) {
      return this.host;
    }

    switch (this.environmentService.getEnvironment()) {
      case Environment.LOCAL:
        this.host = 'http://localhost:22003';
        break;
      case Environment.TEST:
        this.host = 'https://account-group-api-test.apigateway.co';
        break;
      case Environment.DEMO:
        this.host = 'https://account-group-api-demo.apigateway.co';
        break;
      case Environment.PROD:
        this.host = 'https://account-group-api-prod.apigateway.co';
        break;
    }
    return this.host;
  }
}
