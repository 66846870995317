<mat-form-field
  class="galaxy-form-field"
  [ngClass]="{
    'no-hint-message': !hint && (!formControl.invalid || formControl.pristine),
  }"
  appearance="outline"
>
  <mat-label>{{ label | translate }}</mat-label>
  @if (disableAutoComplete) {
    <input style="display: none" type="text" name="disable-autocomplete" />
  }
  <!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
  <input
    [id]="id || 'email'"
    [name]="id || 'email'"
    matInput
    [formControl]="formControl"
    placeholder="{{ placeholder ? (placeholder | translate) : '' }}"
    [required]="!!required"
    (change)="writeValue($event.target.value)"
    [autocomplete]="disableAutoComplete ? 'chrome-off' : 'on'"
    (blur)="onBlur()"
    type="email"
  />
  @if (hint) {
    <mat-hint class="galaxy-hint">{{ hint | translate }}</mat-hint>
  }

  @if (validatorError$ | async; as validatorError) {
    <mat-error>
      @if (validatorError !== '') {
        {{ validatorError | translate }}
      }
    </mat-error>
  }
</mat-form-field>
