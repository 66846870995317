<h2 mat-dialog-title>
  {{ 'SHARE_SNAPSHOT.TITLE' | translate: { snapshotReportName: data.snapshotName | translate } }}
</h2>
<mat-dialog-content class="from-style share-dialog-container">
  <form [formGroup]="shareSnapshotForm" data-testid="sd-modal-form" class="share-form">
    <glxy-form-field bottomSpacing="none" class="half-fill">
      <glxy-label>{{ 'SHARE_SNAPSHOT.DIALOG.SENDER_NAME' | translate }}</glxy-label>
      <input matInput id="from" [formControl]="shareSnapshotForm.get('from')" data-testid="sd-from" />
    </glxy-form-field>

    @if (data.edit) {
      <glxy-form-field bottomSpacing="none" class="half-fill">
        <glxy-label>{{ 'SHARE_SNAPSHOT.DIALOG.SENDER_EMAIL' | translate }}</glxy-label>
        <input matInput id="replyTo" [formControl]="shareSnapshotForm.get('replyTo')" data-testid="sd-reply-to" />
      </glxy-form-field>
    }

    <glxy-form-field bottomSpacing="none" class="fill">
      <glxy-label>
        {{ 'SHARE_SNAPSHOT.LABELS.ADD_CONTACT' | translate }}
      </glxy-label>
      <mat-chip-grid #chipList required>
        <mat-chip-row
          class="recipient-chip"
          *ngFor="let recipient of recipientsArray.controls; let i = index"
          [removable]="true"
          (removed)="removeRecipient(i)"
        >
          {{ recipient.value?.email }}
          <mat-icon matChipRemove>close</mat-icon>
        </mat-chip-row>
        <input
          matInput
          [matChipInputFor]="chipList"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addRecipient($event)"
          [matChipInputSeparatorKeyCodes]="separatorKeys"
          data-testid="sd-recipients"
        />
      </mat-chip-grid>
      <mat-error *ngIf="recipientsArray.touched && recipientsArray?.length === 0" class="chips-error">
        {{ 'VALIDATORS.REQUIRED' | translate }}
      </mat-error>
    </glxy-form-field>

    <ng-container *ngIf="data.edit">
      <ng-container *ngIf="contacts$ | glxyAsyncStatus | async as contacts" [ngSwitch]="contacts.status">
        <ng-container *ngSwitchCase="'loading'" [ngTemplateOutlet]="loading"></ng-container>
        <ng-container *ngSwitchCase="'loaded'">
          <div class="contacts-selection" data-testid="sd-contacts-selection">
            <ng-container *ngFor="let contact of contacts.value">
              <div *ngIf="contact.email" class="contacts-form-row" data-testid="sd-contacts-row">
                <div class="contacts-information">
                  <div *ngIf="contact?.name" class="name">{{ contact.name }}</div>
                  <div class="email">{{ contact.email }}</div>
                </div>
                <button
                  mat-button
                  color="primary"
                  data-testid="sd-add-contact"
                  [disabled]="isContactAdded(contact.email) || isContactListFull()"
                  (click)="addContact(contact)"
                >
                  {{ 'COMMON.ADD' | translate }}
                </button>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <glxy-form-field bottomSpacing="none" class="fill">
      <glxy-label>{{ 'SHARE_SNAPSHOT.DIALOG.SUBJECT' | translate }}</glxy-label>
      <input matInput id="subject" [formControl]="shareSnapshotForm.get('subject')" data-testid="sd-subject" />
    </glxy-form-field>

    <glxy-form-field bottomSpacing="none" class="fill" *ngIf="{ loading: loadingAIMessage$$ | async } as fieldStatus">
      <glxy-label>
        {{ 'SHARE_SNAPSHOT.LABELS.EMAIL_MESSAGE' | translate }}
      </glxy-label>
      <textarea
        matInput
        [ngClass]="{ 'textarea-with-actions': data.edit }"
        formControlName="message"
        cdkAutosizeMinRows="6"
        cdkTextareaAutosize
        data-testid="sd-message"
      ></textarea>

      <div *ngIf="data.edit" insideInputWrapper class="textarea-actions">
        <ng-container *ngIf="!fieldStatus.loading; else loading">
          <glxy-button-group>
            <button
              mat-stroked-button
              class="ai-button-margins"
              data-action="clicked-snapshot-share-generate-with-ai-button"
              (click)="onAIGenerateClick()"
              [disabled]="fieldStatus.loading"
            >
              <mat-icon svgIcon="galaxy-ai-icon" class="textarea-button-icon" />
              {{ 'SHARE_SNAPSHOT.AI_ACTIONS.SUGGEST_CONTENT' | translate }}
            </button>
            <button
              [matMenuTriggerFor]="aiSuggestMenu"
              type="button"
              mat-stroked-button
              class="drop-down-button ai-button-margins"
            >
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          </glxy-button-group>
          <mat-menu #aiSuggestMenu yPosition="above">
            <button mat-menu-item (click)="onAIGenerateClick()">
              {{ 'SHARE_SNAPSHOT.AI_ACTIONS.SUGGEST_GENERAL_CONTENT' | translate }}
            </button>
            <button mat-menu-item (click)="aiContentType = 'section'">
              {{ 'SHARE_SNAPSHOT.AI_ACTIONS.SUGGEST_SECTION_CONTENT' | translate }}
            </button>
          </mat-menu>

          <glxy-form-field
            *ngIf="aiContentType === 'section'"
            [showLabel]="false"
            bottomSpacing="none"
            class="ai-section-selection"
          >
            <mat-select
              [formControl]="aiSelectedSections"
              multiple
              [placeholder]="'SHARE_SNAPSHOT.AI_ACTIONS.SUGGEST_SECTION_PLACEHOLDER' | translate"
              (openedChange)="onSectionSelectionOpenedChange($event)"
            >
              <mat-select-trigger>
                <div *ngIf="aiSelectedSections.value as value">
                  <span *ngIf="value?.length > 0">
                    {{ 'SHARE_SNAPSHOT.AI_ACTIONS.SUGGEST_SECTION_LABEL' | translate }}
                    {{ value?.[0] | translate }}
                    <ng-container *ngIf="value?.length > 1">, {{ value?.[1] | translate }}</ng-container>
                    <ng-container *ngIf="value?.length > 2">
                      (+{{ (value?.length || 0) - 2 }}
                      {{ (value?.length === 3 ? 'COMMON.OTHER' : 'COMMON.OTHERS') | translate }})
                    </ng-container>
                  </span>
                </div>
              </mat-select-trigger>
              <mat-option *ngFor="let section of enabledSections" [value]="section">
                {{ section | translate }}
              </mat-option>
            </mat-select>
          </glxy-form-field>
        </ng-container>
      </div>
    </glxy-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-button (click)="onCancel()" color="primary" data-testid="sd-cancel-button">
    {{ 'COMMON.CANCEL' | translate }}
  </button>
  <button mat-flat-button type="submit" color="primary" (click)="onSubmit()" data-testid="sd-submit-button">
    {{ 'COMMON.SEND' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #loading>
  <glxy-loading-spinner size="small"></glxy-loading-spinner>
</ng-template>
