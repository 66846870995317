<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>{{ 'EDIT_REPORT.MOBILE_PRIMARY_ACTION.TITLE' | translate }}</mat-card-title>
    <mat-card-subtitle>{{ 'EDIT_REPORT.MOBILE_PRIMARY_ACTION.SUBTITLE' | translate }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    @defer (on immediate) {
      <app-edit-button
        toolbarPreview
        [(label)]="label"
        [(targetUrl)]="targetUrl"
        [(targetProductId)]="targetProductId"
        (validationMessageChange)="validationMessage = $event"
      ></app-edit-button>
    }

    @if (validationMessage) {
      <mat-error>
        {{ validationMessage | translate }}
        <mat-icon class="error-icon">error</mat-icon>
      </mat-error>
    }
  </mat-card-content>

  <mat-card-actions>
    <button
      mat-flat-button
      color="primary"
      (click)="handleSaveClick()"
      [disabled]="validationMessage || controls?.dirty"
    >
      <glxy-button-loading-indicator [isLoading]="!!controls?.dirty">
        {{ 'COMMON.SAVE' | translate }}
      </glxy-button-loading-indicator>
    </button>
  </mat-card-actions>
</mat-card>
