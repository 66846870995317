import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Grade } from '@vendasta/snapshot';

export interface SectionInterface {
  grade: Grade;
  config: any;
  data: any;
  content: any;
}

export class ContentInterface {
  messageId: string;
  videoId: string;
  footerMessageId: string;
}

export interface ConfigInterface {
  hideContent?: boolean;
  customizedMessage?: string;
  customizedFooterMessage?: string;
  hideFooter?: boolean;
  customizedFooterCtaLabel?: string;
  customizedFooterCtaTargetUrl?: string;
  customizedFooterCtaTargetProduct?: string;
}

export class Config {
  hideContent: boolean;
  customizedMessage: string;
  customizedFooterMessage: string;
  hideFooter: boolean;
  customizedFooterCtaLabel: string;
  customizedFooterCtaTargetUrl: string;
  customizedFooterCtaTargetProduct: string;

  constructor(config: ConfigInterface) {
    this.hideContent = !!config.hideContent;
    this.customizedMessage = config.customizedMessage || '';
    this.customizedFooterMessage = config.customizedFooterMessage || '';
    this.hideFooter = !!config.hideFooter;
    this.customizedFooterCtaLabel = config.customizedFooterCtaLabel || '';
    this.customizedFooterCtaTargetUrl = config.customizedFooterCtaTargetUrl || '';
    this.customizedFooterCtaTargetProduct = config.customizedFooterCtaTargetProduct || '';
  }

  buildFormControls(): Map<string, UntypedFormControl> {
    const controls = new Map<string, UntypedFormControl>();

    for (const configTemplateKey in this) {
      // iterate config to dynamically build form controls
      if (Object.prototype.hasOwnProperty.call(this, configTemplateKey)) {
        controls.set(configTemplateKey, new UntypedFormControl());
      }
    }
    return controls;
  }

  buildFormGroups(): Map<string, UntypedFormGroup> {
    const groups = new Map<string, UntypedFormGroup>();

    const group = new UntypedFormGroup({});
    group.addControl('customizedFooterCtaLabel', new UntypedFormControl());
    group.addControl('customizedFooterCtaTargetUrl', new UntypedFormControl());
    group.addControl('customizedFooterCtaTargetProduct', new UntypedFormControl());
    groups.set('footerCta', group);
    return groups;
  }
}

export type SectionViewLayout = 'standard' | 'compact';

export interface FooterCtaConfigInterface {
  defaultLabel: string;
  label: string;
  targetUrl: string;
  targetProductId: string;
  formGroup: UntypedFormGroup;
}
