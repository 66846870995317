<h2 mat-dialog-title>{{ 'SCREENSHARE.DIALOG_TITLE' | translate }}</h2>
<mat-dialog-content>
  <mat-spinner *ngIf="loading$$ | async" mode="indeterminate" diameter="25"></mat-spinner>
  <mat-form-field>
    <mat-select required placeholder="{{ 'SCREENSHARE.SELECT_CONTACT' | translate }}" [formControl]="selectedContact">
      <mat-option *ngFor="let contact of contacts$ | async" [value]="contact">
        <span *ngIf="contact.name">{{ contact.name }}&nbsp;</span>
        <span *ngIf="contact.email">{{ contact.email }}&nbsp;</span>
        <span *ngIf="contact.phoneNumber">{{ contact.phoneNumber }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <a
    mat-flat-button
    color="primary"
    [disabled]="!selectedContact.valid"
    [href]="crankwheelUrl$ | async"
    target="_blank"
  >
    {{ 'SCREENSHARE.OPEN' | translate }}
  </a>
</mat-dialog-actions>
