{
  "FRONTEND": {
    "TAXONOMY": {
      "active": "Active Life",
      "amateursportsteams": "Amateur Sports Teams",
      "amusementparks": "Amusement Parks",
      "aquariums": "Aquariums",
      "archery": "Archery",
      "badminton": "Badminton",
      "basketballcourts": "Basketball Courts",
      "beaches": "Beaches",
      "bikerentals": "Bike Rentals",
      "boating": "Boating",
      "bowling": "Bowling",
      "climbing": "Climbing",
      "discgolf": "Disc Golf",
      "diving": "Diving",
      "freediving": "Free Diving",
      "scuba": "Scuba Diving",
      "fishing": "Fishing",
      "fitness": "Fitness & Instruction",
      "barreclasses": "Barre Classes",
      "bootcamps": "Boot Camps",
      "boxing": "Boxing",
      "dancestudio": "Dance Studios",
      "gyms": "Gyms",
      "healthtrainers": "Trainers",
      "martialarts": "Martial Arts",
      "pilates": "Pilates",
      "taichi": "Tai Chi",
      "yoga": "Yoga",
      "football": "Soccer",
      "gokarts": "Go Karts",
      "golf": "Golf",
      "gun_ranges": "Gun/Rifle Ranges",
      "gymnastics": "Gymnastics",
      "hanggliding": "Hang Gliding",
      "hiking": "Hiking",
      "horsebackriding": "Horseback Riding",
      "horseracing": "Horse Racing",
      "hot_air_balloons": "Hot Air Balloons",
      "kiteboarding": "Kiteboarding",
      "lakes": "Lakes",
      "lasertag": "Laser Tag",
      "leisure_centers": "Leisure Centers",
      "mini_golf": "Mini Golf",
      "mountainbiking": "Mountain Biking",
      "paddleboarding": "Paddleboarding",
      "paintball": "Paintball",
      "parks": "Parks",
      "dog_parks": "Dog Parks",
      "skate_parks": "Skate Parks",
      "playgrounds": "Playgrounds",
      "rafting": "Rafting/Kayaking",
      "recreation": "Recreation Centers",
      "rock_climbing": "Rock Climbing",
      "skatingrinks": "Skating Rinks",
      "skydiving": "Skydiving",
      "spinclasses": "Spin Classes",
      "sports_clubs": "Sports Clubs",
      "squash": "Squash",
      "summer_camps": "Summer Camps",
      "surfing": "Surfing",
      "swimmingpools": "Swimming Pools",
      "tennis": "Tennis",
      "trampoline": "Trampoline Parks",
      "tubing": "Tubing",
      "zoos": "Zoos",
      "arts": "Arts & Entertainment",
      "arcades": "Arcades",
      "casinos": "Casinos",
      "culturalcenter": "Cultural Center",
      "festivals": "Festivals",
      "galleries": "Art Galleries",
      "gardens": "Botanical Gardens",
      "jazzandblues": "Jazz & Blues",
      "movietheaters": "Cinema",
      "museums": "Museums",
      "musicvenues": "Music Venues",
      "opera": "Opera & Ballet",
      "psychic_astrology": "Psychics & Astrologers",
      "racetracks": "Race Tracks",
      "social_clubs": "Social Clubs",
      "sportsteams": "Professional Sports Teams",
      "stadiumsarenas": "Stadiums & Arenas",
      "theater": "Performing Arts",
      "ticketsales": "Ticket Sales",
      "wineries": "Wineries",
      "auto": "Automotive",
      "auto_detailing": "Auto Detailing",
      "autoglass": "Auto Glass Services",
      "autoloanproviders": "Auto Loan Providers",
      "autopartssupplies": "Auto Parts & Supplies",
      "autorepair": "Auto Repair",
      "boatdealers": "Boat Dealers",
      "bodyshops": "Body Shops",
      "car_dealers": "Car Dealers",
      "carwash": "Car Wash",
      "motorcycledealers": "Motorcycle Dealers",
      "motorcyclerepair": "Motorcycle Repair",
      "oilchange": "Oil Change Stations",
      "parking": "Parking",
      "rv_dealers": "RV Dealers",
      "servicestations": "Gas & Service Stations",
      "smog_check_stations": "Smog Check Stations",
      "stereo_installation": "Car Stereo Installation",
      "tires": "Tires",
      "towing": "Towing",
      "truck_rental": "Truck Rental",
      "windshieldinstallrepair": "Windshield Installation & Repair",
      "beautysvc": "Beauty & Spas",
      "barbers": "Barbers",
      "cosmetics": "Cosmetics & Beauty Supply",
      "eyelashservice": "Eyelash Service",
      "hair": "Hair Salons",
      "blowoutservices": "Blow Dry/Out Services",
      "hair_extensions": "Hair Extensions",
      "hairstylists": "Hair Stylists",
      "menshair": "Men's Hair Salons",
      "hairremoval": "Hair Removal",
      "laser_hair_removal": "Laser Hair Removal",
      "makeupartists": "Makeup Artists",
      "massage": "Massage",
      "othersalons": "Nail Salons",
      "permanentmakeup": "Permanent Makeup",
      "piercing": "Piercing",
      "rolfing": "Rolfing",
      "skincare": "Skin Care",
      "spas": "Day Spas",
      "tanning": "Tanning",
      "spraytanning": "Spray Tanning",
      "tanningbeds": "Tanning Beds",
      "tattoo": "Tattoo",
      "education": "Education",
      "adultedu": "Adult Education",
      "collegecounseling": "College Counseling",
      "collegeuniv": "Colleges & Universities",
      "educationservices": "Educational Services",
      "elementaryschools": "Elementary Schools",
      "highschools": "High Schools",
      "kindergarten": "Kindergarten",
      "middleschools": "Middle Schools",
      "preschools": "Preschools",
      "privateschools": "Private Schools",
      "privatetutors": "Private Tutors",
      "religiousschools": "Religious Schools",
      "specialed": "Special Education",
      "specialtyschools": "Specialty Schools",
      "artschools": "Art Schools",
      "cookingschools": "Cooking Schools",
      "cosmetology_schools": "Cosmetology Schools",
      "cprclasses": "CPR Classes",
      "dance_schools": "Dance Schools",
      "driving_schools": "Driving Schools",
      "firstaidclasses": "First Aid Classes",
      "flightinstruction": "Flight Instruction",
      "language_schools": "Language Schools",
      "massage_schools": "Massage Schools",
      "swimminglessons": "Swimming Lessons/Schools",
      "vocation": "Vocational & Technical School",
      "testprep": "Test Preparation",
      "tutoring": "Tutoring Centers",
      "eventservices": "Event Planning & Services",
      "bartenders": "Bartenders",
      "boatcharters": "Boat Charters",
      "catering": "Caterers",
      "clowns": "Clowns",
      "djs": "DJs",
      "eventplanning": "Party & Event Planning",
      "magicians": "Magicians",
      "musicians": "Musicians",
      "officiants": "Officiants",
      "partybusrentals": "Party Bus Rentals",
      "partyequipmentrentals": "Party Equipment Rentals",
      "partysupplies": "Party Supplies",
      "personalchefs": "Personal Chefs",
      "photographers": "Photographers",
      "eventphotography": "Event Photography",
      "sessionphotography": "Session Photography",
      "venues": "Venues & Event Spaces",
      "videographers": "Videographers",
      "wedding_planning": "Wedding Planning",
      "financialservices": "Financial Services",
      "banks": "Banks & Credit Unions",
      "financialadvising": "Financial Advising",
      "insurance": "Insurance",
      "investing": "Investing",
      "paydayloans": "Check Cashing/Pay-day Loans",
      "taxservices": "Tax Services",
      "food": "Food",
      "bagels": "Bagels",
      "bakeries": "Bakeries",
      "beer_and_wine": "Beer, Wine & Spirits",
      "breweries": "Breweries",
      "bubbletea": "Bubble Tea",
      "butcher": "Butcher",
      "coffee": "Coffee & Tea",
      "convenience": "Convenience Stores",
      "csa": "CSA",
      "desserts": "Desserts",
      "diyfood": "Do-It-Yourself Food",
      "donuts": "Donuts",
      "farmersmarket": "Farmers Market",
      "fooddeliveryservices": "Food Delivery Services",
      "foodtrucks": "Food Trucks",
      "gelato": "Gelato",
      "gourmet": "Specialty Food",
      "candy": "Candy Stores",
      "cheese": "Cheese Shops",
      "chocolate": "Chocolatiers & Shops",
      "ethnicmarkets": "Ethnic Food",
      "healthmarkets": "Health Markets",
      "herbsandspices": "Herbs & Spices",
      "markets": "Fruits & Veggies",
      "meats": "Meat Shops",
      "seafoodmarkets": "Seafood Markets",
      "grocery": "Grocery",
      "icecream": "Ice Cream & Frozen Yogurt",
      "internetcafe": "Internet Cafes",
      "juicebars": "Juice Bars & Smoothies",
      "pretzels": "Pretzels",
      "shavedice": "Shaved Ice",
      "streetvendors": "Street Vendors",
      "tea": "Tea Rooms",
      "health": "Health & Medical",
      "acupuncture": "Acupuncture",
      "assistance": "Assistance",
      "c_and_mh": "Counseling & Mental Health",
      "cannabis_clinics": "Cannabis Clinics",
      "chiropractors": "Chiropractors",
      "dentists": "Dentists",
      "cosmeticdentists": "Cosmetic Dentists",
      "endodontists": "Endodontists",
      "generaldentistry": "General Dentistry",
      "oralsurgeons": "Oral Surgeons",
      "orthodontists": "Orthodontists",
      "pediatric_dentists": "Pediatric Dentists",
      "periodontists": "Periodontists",
      "diagnosticservices": "Diagnostic Services",
      "diagnosticimaging": "Diagnostic Imaging",
      "laboratorytesting": "Laboratory Testing",
      "hearingaidproviders": "Hearing Aid Providers",
      "homehealthcare": "Home Health Care",
      "hospice": "Hospice",
      "hospitals": "Hospitals",
      "lactationservices": "Lactation Services",
      "laserlasikeyes": "Laser Eye Surgery/Lasik",
      "massage_therapy": "Massage Therapy",
      "medcenters": "Medical Centers",
      "medicalspa": "Medical Spas",
      "medicaltransportation": "Medical Transportation",
      "midwives": "Midwives",
      "nutritionists": "Nutritionists",
      "occupationaltherapy": "Occupational Therapy",
      "optometrists": "Optometrists",
      "pharmaceuticalsupplier": "Pharmaceutical Supplier",
      "pharmacy": "Pharmacy",
      "physicaltherapy": "Physical Therapy",
      "physicians": "Doctors",
      "addiction_specialist": "Addiction Specialist",
      "adolescent_medicine": "Adolescent Medicine Specialist",
      "allergist": "Allergists",
      "allergist_immunologist": "Allergist / Immunologist",
      "anesthesiologists": "Anesthesiologists",
      "audiologist": "Audiologist",
      "bariatric_surgeon": "Bariatric Surgeon",
      "behavioral_medicine": "Behavioral Medicine Specialist",
      "breast_surgeon": "Breast Surgeon",
      "cardiac_surgeon": "Cardiac Surgeon",
      "cardiology": "Cardiologists",
      "cardiothoracic_surgeon": "Cardiothoracic Surgeon",
      "colorectal_surgeon": "Colorectal Surgeon",
      "cosmeticsurgeons": "Cosmetic Surgeons",
      "dermatology": "Dermatologists",
      "earnosethroat": "Ear Nose & Throat",
      "familydr": "Family Practice",
      "fertility": "Fertility",
      "flight_surgeon": "Flight Surgeon",
      "gastroenterologist": "Gastroenterologist",
      "general_practioner": "General Practitioner",
      "general_surgeon": "General Surgeon",
      "geriatrics": "Geriatrics",
      "gerontologist": "Gerontologists",
      "hematologist": "Hematologist",
      "hepatologist": "Hepatologist",
      "immunologist": "Immunologist",
      "infectious_disease_specialist": "Infectious Disease Specialist",
      "internalmed": "Internal Medicine",
      "naturopathic": "Naturopathic/Holistic",
      "nephrologist": "Nephrologist",
      "neurologist": "Neurologist",
      "neurosurgeon": "Neurosurgeon",
      "nuclear_medicine_specialist": "Nuclear Medicine Specialist",
      "obgyn": "Obstetricians & Gynecologists",
      "oncologist": "Oncologist",
      "opthamalogists": "Ophthalmologists",
      "orthopedists": "Orthopedists",
      "osteopathicphysicians": "Osteopathic Physicians",
      "pain_management_specialist": "Pain Management Specialist",
      "pediatricians": "Pediatricians",
      "plastic_surgeon": "Plastic Surgeon",
      "podiatrists": "Podiatrists",
      "proctologist": "Proctologists",
      "psychiatrists": "Psychiatrists",
      "pulmonologist": "Pulmonologist",
      "sleep_medicine": "Sleep Medicine Doctor",
      "sportsmed": "Sports Medicine",
      "tattooremoval": "Tattoo Removal",
      "thoracic_surgeon": "Thoracic Surgeon",
      "transplan_surgeon": "Transplant Surgeon",
      "trauma_surgeon": "Trauma Surgeon",
      "urologists": "Urologists",
      "vascular_surgeon": "Vascular Surgeon",
      "reflexology": "Reflexology",
      "rehabilitation_center": "Rehabilitation Center",
      "retirement_homes": "Retirement Homes",
      "speech_therapists": "Speech Therapists",
      "tcm": "Traditional Chinese Medicine",
      "urgent_care": "Urgent Care",
      "weightlosscenters": "Weight Loss Centers",
      "homeservices": "Home Services",
      "blinds": "Shades & Blinds",
      "buildingsupplies": "Building Supplies",
      "carpeting": "Carpeting",
      "carpetinstallation": "Carpet Installation",
      "chimneyandfireplacecleaning": "Chimney & Fireplace Cleaning",
      "constructioncompany": "Construction Company",
      "contractors": "Contractors",
      "damagerestoration": "Damage Restoration",
      "electricians": "Electricians",
      "flooring": "Flooring",
      "garage_door_services": "Garage Door Services",
      "gardeners": "Gardeners",
      "handyman": "Handyman",
      "home_inspectors": "Home Inspectors",
      "home_organization": "Home Organization",
      "homecleaning": "Home Cleaning",
      "hometheatreinstallation": "Home Theatre Installation",
      "homewindowtinting": "Home Window Tinting",
      "hvac": "Heating & Air Conditioning/HVAC",
      "interiordesign": "Interior Design",
      "irrigation": "Irrigation",
      "isps": "Internet Service Providers",
      "landscapearchitects": "Landscape Architects",
      "landscaping": "Landscaping",
      "lighting": "Lighting Fixtures & Equipment",
      "locksmiths": "Keys & Locksmiths",
      "masonry_concrete": "Masonry/Concrete",
      "movers": "Movers",
      "painters": "Painters",
      "plumbing": "Plumbing",
      "poolcleaners": "Pool Cleaners",
      "realestate": "Real Estate",
      "commercialrealestate": "Commercial Real Estate",
      "mortgagebrokers": "Mortgage Brokers",
      "prefabricated": "Prefabricated",
      "propertymgmt": "Property Management",
      "realestateagents": "Real Estate Agents",
      "realestatesvcs": "Real Estate Services",
      "sharedofficespaces": "Shared Office Spaces",
      "roofing": "Roofing",
      "securitysystems": "Security Systems",
      "solarinstallation": "Solar Installation",
      "televisionserviceproviders": "Television Service Providers",
      "treeservices": "Tree Services",
      "utilities": "Utilities",
      "windowwashing": "Window Washing",
      "hotelstravel": "Hotels & Travel",
      "airports": "Airports",
      "bedbreakfast": "Bed & Breakfast",
      "campgrounds": "Campgrounds",
      "carrental": "Car Rental",
      "guesthouses": "Guest Houses",
      "hostels": "Hostels",
      "hotels": "Hotels",
      "motorcycle_rental": "Motorcycle Rental",
      "resorts": "Resorts",
      "rvparks": "RV Parks",
      "rvrental": "RV Rental",
      "skiresorts": "Ski Resorts",
      "tours": "Tours",
      "trainstations": "Train Stations",
      "transport": "Transportation",
      "airlines": "Airlines",
      "airport_shuttles": "Airport Shuttles",
      "limos": "Limos",
      "publictransport": "Public Transportation",
      "taxis": "Taxis",
      "travelservices": "Travel Services",
      "vacation_rentals": "Vacation Rentals",
      "vacationrentalagents": "Vacation Rental Agents",
      "industgoodsmanu": "Industrial Goods and Manufacturing",
      "manufacturer": "Manufacturer",
      "localservices": "Local Services",
      "bailbondsmen": "Bail Bondsmen",
      "bike_repair_maintenance": "Bike Repair/Maintenance",
      "carpet_cleaning": "Carpet Cleaning",
      "childcare": "Child Care & Day Care",
      "copyshops": "Printing Services",
      "couriers": "Couriers & Delivery Services",
      "drycleaninglaundry": "Dry Cleaning & Laundry",
      "electronicsrepair": "Electronics Repair",
      "equipmentrental": "Equipment Rental",
      "fireprotectionequipmentsupplier": "Fire Protection Equipment Supplier",
      "fireprotectionservice": "Fire Protection Service",
      "fireprotectionsystemsupplier": "Fire Protection System Supplier",
      "funeralservices": "Funeral Services & Cemeteries",
      "homeappliancerepair": "Appliances & Repair",
      "itservices": "IT Services & Computer Repair",
      "datarecovery": "Data Recovery",
      "mobilephonerepair": "Mobile Phone Repair",
      "jewelryrepair": "Jewelry Repair",
      "junkremovalandhauling": "Junk Removal & Hauling",
      "nannys": "Nanny Services",
      "nonprofit": "Community Service/Non-Profit",
      "notaries": "Notaries",
      "pest_control": "Pest Control",
      "recording_studios": "Recording & Rehearsal Studios",
      "recyclingcenter": "Recycling Center",
      "reupholstery": "Furniture Reupholstery",
      "screen_printing_tshirt_printing": "Screen Printing/T-Shirt Printing",
      "screenprinting": "Screen Printing",
      "selfstorage": "Self Storage",
      "sewingalterations": "Sewing & Alterations",
      "shipping_centers": "Shipping Centers",
      "shoerepair": "Shoe Repair",
      "shredding_service": "Shredding Service",
      "snowremoval": "Snow Removal",
      "software_development": "Software Development",
      "watch_repair": "Watch Repair",
      "massmedia": "Mass Media",
      "printmedia": "Print Media",
      "radiostations": "Radio Stations",
      "televisionstations": "Television Stations",
      "mineag": "Mining & Agriculture",
      "ag": "Agriculture",
      "farmequipmentseller": "Farm Equipment Seller",
      "horticulture": "Horticulture",
      "livestock": "Livestock",
      "mining": "Mining",
      "nightlife": "Nightlife",
      "adultentertainment": "Adult Entertainment",
      "bars": "Bars",
      "champagne_bars": "Champagne Bars",
      "cocktailbars": "Cocktail Bars",
      "divebars": "Dive Bars",
      "gaybars": "Gay Bars",
      "hookah_bars": "Hookah Bars",
      "lounges": "Lounges",
      "pubs": "Pubs",
      "sportsbars": "Sports Bars",
      "wine_bars": "Wine Bars",
      "comedyclubs": "Comedy Clubs",
      "countrydancehalls": "Country Dance Halls",
      "danceclubs": "Dance Clubs",
      "karaoke": "Karaoke",
      "pianobars": "Piano Bars",
      "poolhalls": "Pool Halls",
      "other": "Other",
      "pets": "Pets",
      "animalshelters": "Animal Shelters",
      "horse_boarding": "Horse Boarding",
      "petservices": "Pet Services",
      "dogwalkers": "Dog Walkers",
      "groomer": "Pet Groomers",
      "pet_sitting": "Pet Boarding/Pet Sitting",
      "pet_training": "Pet Training",
      "petstore": "Pet Stores",
      "vet": "Veterinarians",
      "professional": "Professional Services",
      "accountants": "Accountants",
      "advertising": "Advertising",
      "architects": "Architects",
      "audio_video_consulting": "Audio Visual Consultant",
      "boatrepair": "Boat Repair",
      "careercounseling": "Career Counseling",
      "editorialservices": "Editorial Services",
      "employmentagencies": "Employment Agencies",
      "graphicdesign": "Graphic Design",
      "lawyers": "Lawyers",
      "bankruptcy": "Bankruptcy Law",
      "businesslawyers": "Business Law",
      "criminaldefense": "Criminal Defense Law",
      "divorce": "Divorce & Family Law",
      "duilawyers": "DUI Law",
      "employmentlawyers": "Employment Law",
      "estateplanning": "Estate Planning Law",
      "general_litigation": "General Litigation",
      "immigrationlawyers": "Immigration Law",
      "personal_injury": "Personal Injury Law",
      "realestatelawyers": "Real Estate Law",
      "legalservices": "Legal Services",
      "lifecoach": "Life Coach",
      "marketing": "Marketing",
      "matchmakers": "Matchmakers",
      "officecleaning": "Office Cleaning",
      "payroll": "Payroll Services",
      "personalassistants": "Personal Assistants",
      "privateinvestigation": "Private Investigation",
      "publicrelations": "Public Relations",
      "talentagencies": "Talent Agencies",
      "taxidermy": "Taxidermy",
      "translationservices": "Translation Services",
      "videofilmproductions": "Video/Film Production",
      "web_design": "Web Design",
      "publicservicesgovt": "Public Services & Government",
      "courthouses": "Courthouses",
      "departmentsofmotorvehicles": "Departments of Motor Vehicles",
      "embassy": "Embassy",
      "firedepartments": "Fire Departments",
      "landmarks": "Landmarks & Historical Buildings",
      "libraries": "Libraries",
      "policedepartments": "Police Departments",
      "postoffices": "Post Offices",
      "apartments": "Apartments",
      "homestaging": "Home Staging",
      "university_housing": "University Housing",
      "religiousorgs": "Religious Organizations",
      "buddhist_temples": "Buddhist Temples",
      "churches": "Churches",
      "hindu_temples": "Hindu Temples",
      "mosques": "Mosques",
      "synagogues": "Synagogues",
      "restaurants": "Restaurants",
      "afghani": "Afghan",
      "african": "African",
      "senegalese": "Senegalese",
      "southafrican": "South African",
      "arabian": "Arabian",
      "argentine": "Argentine",
      "armenian": "Armenian",
      "asianfusion": "Asian Fusion",
      "australian": "Australian",
      "austrian": "Austrian",
      "bangladeshi": "Bangladeshi",
      "basque": "Basque",
      "bbq": "Barbeque",
      "belgian": "Belgian",
      "brasseries": "Brasseries",
      "brazilian": "Brazilian",
      "breakfast_brunch": "Breakfast & Brunch",
      "british": "British",
      "buffets": "Buffets",
      "burgers": "Burgers",
      "burmese": "Burmese",
      "cafes": "Cafes",
      "cafeteria": "Cafeteria",
      "cajun": "Cajun/Creole",
      "cambodian": "Cambodian",
      "caribbean": "Caribbean",
      "dominican": "Dominican",
      "haitian": "Haitian",
      "puertorican": "Puerto Rican",
      "trinidadian": "Trinidadian",
      "catalan": "Catalan",
      "cheesesteaks": "Cheesesteaks",
      "chicken_wings": "Chicken Wings",
      "chinese": "Chinese",
      "cantonese": "Cantonese",
      "dimsum": "Dim Sum",
      "shanghainese": "Shanghainese",
      "szechuan": "Szechuan",
      "comfortfood": "Comfort Food",
      "creperies": "Creperies",
      "cuban": "Cuban",
      "czech": "Czech",
      "delis": "Delis",
      "diners": "Diners",
      "ethiopian": "Ethiopian",
      "filipino": "Filipino",
      "fishnchips": "Fish & Chips",
      "fondue": "Fondue",
      "food_court": "Food Court",
      "foodstands": "Food Stands",
      "french": "French",
      "gastropubs": "Gastropubs",
      "german": "German",
      "gluten_free": "Gluten-Free",
      "greek": "Greek",
      "halal": "Halal",
      "hawaiian": "Hawaiian",
      "himalayan": "Himalayan/Nepalese",
      "hotdog": "Hot Dogs",
      "hotdogs": "Fast Food",
      "hotpot": "Hot Pot",
      "hungarian": "Hungarian",
      "iberian": "Iberian",
      "indonesian": "Indonesian",
      "indpak": "Indian",
      "irish": "Irish",
      "italian": "Italian",
      "japanese": "Japanese",
      "korean": "Korean",
      "kosher": "Kosher",
      "laotian": "Laotian",
      "latin": "Latin American",
      "colombian": "Colombian",
      "salvadoran": "Salvadoran",
      "venezuelan": "Venezuelan",
      "malaysian": "Malaysian",
      "mediterranean": "Mediterranean",
      "mexican": "Mexican",
      "mideastern": "Middle Eastern",
      "egyptian": "Egyptian",
      "lebanese": "Lebanese",
      "modern_european": "Modern European",
      "mongolian": "Mongolian",
      "moroccan": "Moroccan",
      "newamerican": "American (New)",
      "pakistani": "Pakistani",
      "persian": "Persian/Iranian",
      "peruvian": "Peruvian",
      "pizza": "Pizza",
      "polish": "Polish",
      "portuguese": "Portuguese",
      "raw_food": "Live/Raw Food",
      "russian": "Russian",
      "salad": "Salad",
      "sandwiches": "Sandwiches",
      "scandinavian": "Scandinavian",
      "scottish": "Scottish",
      "seafood": "Seafood",
      "singaporean": "Singaporean",
      "slovakian": "Slovakian",
      "soulfood": "Soul Food",
      "soup": "Soup",
      "southern": "Southern",
      "spanish": "Spanish",
      "steak": "Steakhouses",
      "sushi": "Sushi Bars",
      "taiwanese": "Taiwanese",
      "tapas": "Tapas Bars",
      "tapasmallplates": "Tapas/Small Plates",
      "tex_mex": "Tex-Mex",
      "thai": "Thai",
      "tradamerican": "American (Traditional)",
      "turkish": "Turkish",
      "ukrainian": "Ukrainian",
      "vegan": "Vegan",
      "vegetarian": "Vegetarian",
      "vietnamese": "Vietnamese",
      "shopping": "Shopping",
      "adult": "Adult",
      "antiques": "Antiques",
      "artsandcrafts": "Arts & Crafts",
      "artsupplies": "Art Supplies",
      "costumes": "Costumes",
      "fabricstores": "Fabric Stores",
      "framing": "Framing",
      "stationery": "Cards & Stationery",
      "auctionhouses": "Auction Houses",
      "baby_gear": "Baby Gear & Furniture",
      "bespoke": "Bespoke Clothing",
      "bridal": "Bridal",
      "cannabis_store": "Cannabis Store",
      "computers": "Computers",
      "deptstores": "Department Stores",
      "discountstore": "Discount Store",
      "drugstores": "Drugstores",
      "electronics": "Electronics",
      "fashion": "Fashion",
      "accessories": "Accessories",
      "childcloth": "Children's Clothing",
      "formalwear": "Formal Wear",
      "hats": "Hats",
      "leather": "Leather Goods",
      "lingerie": "Lingerie",
      "maternity": "Maternity Wear",
      "menscloth": "Men's Clothing",
      "plus_size_fashion": "Plus Size Fashion",
      "shoes": "Shoe Stores",
      "sportswear": "Sports Wear",
      "surfshop": "Surf Shop",
      "swimwear": "Swimwear",
      "vintage": "Used, Vintage & Consignment",
      "womenscloth": "Women's Clothing",
      "fireworks": "Fireworks",
      "fleamarkets": "Flea Markets",
      "flowers": "Flowers & Gifts",
      "florists": "Florists",
      "giftshops": "Gift Shops",
      "golfshops": "Golf Equipment Shops",
      "guns_and_ammo": "Guns & Ammo",
      "hobbyshops": "Hobby Shops",
      "homeandgarden": "Home & Garden",
      "appliances": "Appliances",
      "furniture": "Furniture Stores",
      "gardening": "Nurseries & Gardening",
      "hardware": "Hardware Stores",
      "homedecor": "Home Decor",
      "hottubandpool": "Hot Tub & Pool",
      "kitchenandbath": "Kitchen & Bath",
      "mattresses": "Mattresses",
      "jewelry": "Jewelry",
      "knittingsupplies": "Knitting Supplies",
      "luggage": "Luggage",
      "media": "Books, Mags, Music & Video",
      "bookstores": "Bookstores",
      "comicbooks": "Comic Books",
      "mags": "Newspapers & Magazines",
      "musicvideo": "Music & DVDs",
      "videoandgames": "Videos & Video Game Rental",
      "vinyl_records": "Vinyl Records",
      "medicalsupplies": "Medical Supplies",
      "mobilephones": "Mobile Phones",
      "motorcyclinggear": "Motorcycle Gear",
      "musicalinstrumentsandteachers": "Musical Instruments & Teachers",
      "officeequipment": "Office Equipment",
      "opticians": "Eyewear & Opticians",
      "outlet_stores": "Outlet Stores",
      "pawn": "Pawn Shops",
      "personal_shopping": "Personal Shopping",
      "photographystores": "Photography Stores & Services",
      "poolbilliards": "Pool & Billiards",
      "popupshops": "Pop-up Shops",
      "shoppingcenters": "Shopping Centers",
      "sportgoods": "Sporting Goods",
      "bikes": "Bikes",
      "golfequipment": "Golf Equipment",
      "outdoorgear": "Outdoor Gear",
      "thrift_stores": "Thrift Stores",
      "tobaccoshops": "Tobacco Shops",
      "toys": "Toy Stores",
      "trophyshops": "Trophy Shops",
      "uniforms": "Uniforms",
      "watches": "Watches",
      "wholesale_stores": "Wholesale Stores",
      "wigs": "Wigs",
      "transportation": "Transportation",
      "air": "Air",
      "railway": "Railway",
      "road": "Road",
      "water": "Water",
      "doormanufacturer": "Door Manufacturer",
      "doorsupplier": "Door Supplier",
      "excavatingcontractor": "Excavating Contractor",
      "railingcontractor": "Railing Contractor",
      "windowsinstallation": "Windows Installation",
      "doorshop": "Door Shop",
      "showerdoorshop": "Shower Door Shop",
      "linens_store": "Linens Store",
      "laundry_service": "Laundry Services",
      "janitorial_service": "Janitorial Services",
      "restaurant_supply_store": "Restaurant Supply Store",
      "ict": "Information and Communication Technology",
      "telecommunicationserviceprovider": "Telecommunications Service Provider",
      "managedserviceprovider": "Managed Service Provider",
      "valueaddedreseller": "Value Added Reseller",
      "cablecompany": "Cable Company",
      "internetserviceprovider": "Internet Service Provider",
      "signshop": "Sign Shop"
    }
  }
}
