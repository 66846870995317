import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { VaFormsModule } from '@vendasta/forms';
import { InViewportModule } from 'ng-in-viewport';
import { IsImageUploadFieldPipe } from './fields/image-upload-field';
import { ToBoolPipe } from './to-bool.pipe';
import { VFilterComponent } from './v-filter.component';
import { VFormTranslationService } from './vform-translation.service';
import { VFormComponent } from './vform.component';
import { FormArrayTypePipe, FormControlTypePipe } from './vform.models';
import { VsourceOptionComponent } from './vsource/vsource-option/vsource-option.component';
import { VsourceComponent } from './vsource/vsource.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    InViewportModule,
    TranslateModule,

    MatButtonModule,
    MatChipsModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatIconModule,
    MatAutocompleteModule,
    MatMenuModule,

    VaFormsModule,
  ],
  exports: [VFormComponent, VFilterComponent],
  declarations: [
    VFormComponent,
    VsourceComponent,
    VsourceOptionComponent,
    VFilterComponent,
    ToBoolPipe,
    FormArrayTypePipe,
    FormControlTypePipe,
    IsImageUploadFieldPipe,
  ],
  providers: [VFormTranslationService],
})
export class VFormModule {}
