<div class="flex align-center row">
  <mat-card appearance="outlined" class="error">
    <mat-card-header>
      <mat-card-title>
        <mat-icon>sentiment_dissatisfied</mat-icon>
        <ng-container *ngIf="titleId">
          <div>{{ titleId | translate }}</div>
        </ng-container>
      </mat-card-title>
    </mat-card-header>
  </mat-card>
</div>
