import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationData } from './confirmation-dialog.component';

interface DialogConfig {
  width: string;
  data: ConfirmationData;
  height?: string;
}

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
  constructor(private dialog: MatDialog) {}

  async confirm(options: ConfirmationData, height?: string): Promise<boolean> {
    const { ConfirmationDialogComponent } = await import('./confirmation-dialog.component');
    const dialogConfig: DialogConfig = {
      width: '550px',
      data: options,
    };
    if (height) {
      dialogConfig.height = height;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    return await dialogRef.afterClosed().toPromise();
  }
}
