import { distinctUntilChanged, map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { WebsiteConfig, WebsiteConfigInterface } from './website-config';
import { WebsiteData } from './website-data';
import { WebsiteContent } from './website-content';
import { ContentService } from '../snapshot-report/content.service';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { SectionInterface } from '../section/section';
import { AbstractSectionService } from '../section/section.service';
import {
  CORE_WEB_VITALS_CUMULATIVE_LAYOUT_SHIFT,
  CORE_WEB_VITALS_INTERACTIVE,
  CORE_WEB_VITALS_LARGEST_CONTENTFUL_PAINT,
  CORE_WEB_VITALS_SPEED_INDEX,
  WebsiteSectionService,
} from '@vendasta/snapshot';
import { GradeExplanationData } from '../grade/grade-explainer-dialog.component';
import { AccountGroupService, UpdateOperations, NapUpdateOperation } from '@galaxy/account-group';
import { gradeToLetter } from '../common/utils';
import { bool2found } from '../common/ai-utils';

@Injectable()
export class WebsiteService
  extends AbstractSectionService<WebsiteContent, WebsiteData, WebsiteConfigInterface>
  implements OnDestroy
{
  sectionId = 'website';
  public sectionVersion$: Observable<number>;

  constructor(
    private _cs: ContentService,
    private _ss: SnapshotReportService,
    private _api: WebsiteSectionService,
    private _ag: AccountGroupService,
  ) {
    super(_cs, _ss);

    this.sectionVersion$ = this.config$.pipe(
      map((c) => c.sectionVersion),
      distinctUntilChanged(),
    );
  }

  createGradeSubscription(): void {
    this.subscriptions.push(this.grade$.subscribe((g) => this._ss.setWebsiteGrade(g)));
  }

  load(): Observable<SectionInterface> {
    return this._api.get(this.snapshotId).pipe(
      map((section) => {
        return {
          grade: section.grade,
          config: new WebsiteConfig(section.config || {}),
          data: new WebsiteData(section.data),
          content: new WebsiteContent(section.content),
        };
      }),
    );
  }

  _updateConfig(config: WebsiteConfigInterface): Observable<boolean> {
    return this._api.updateConfig(this.snapshotId, config);
  }

  newConfig(): WebsiteConfigInterface {
    return new WebsiteConfig({});
  }

  getGradeExplanationData(): GradeExplanationData {
    return {
      titleTextID: 'WEBSITE.GRADE_EXPLANATION.TITLE',
      mobileTitleTextID: 'WEBSITE.GRADE_EXPLANATION.MOBILE_TITLE',
      primaryTextID: 'WEBSITE.GRADE_EXPLANATION.PRIMARY',
      showThermometer: true,
      secondaryTitleTextID: 'WEBSITE.GRADE_EXPLANATION.SECONDARY_TITLE',
      secondaryPreScaleTextID: 'WEBSITE.GRADE_EXPLANATION.SECONDARY_PRE_SCALE',
      showGradeScale: true,
      secondaryPostScaleTextID: 'WEBSITE.GRADE_EXPLANATION.SECONDARY_POST_SCALE',
    };
  }

  updateWebsiteUrl(businessId: string, websiteUrl: string): Observable<any> {
    const updateOperations = new UpdateOperations();
    updateOperations.addUpdateOperation(new NapUpdateOperation({ website: websiteUrl }));
    return this._ag.bulkUpdate(businessId, updateOperations);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getAiPromptDetails(): Observable<string> {
    return combineLatest([this._ss.websiteSectionEnabled$, this.data$, this.grade$]).pipe(
      map(([sectionEnabled, data, grade]) => {
        if (!sectionEnabled) {
          return '';
        }
        const letterGrade = gradeToLetter(grade);
        const hpVideo = bool2found(data?.homepageData?.videoPresent);
        const hpFacebook = bool2found(data?.homepageData?.facebookPresent);
        const hpTwitter = bool2found(data?.homepageData?.twitterPresent);
        const hpInstagram = bool2found(data?.homepageData?.instagramPresent);
        const deskPagespeed = data?.desktopInsights?.audits?.find((a) => a.id === CORE_WEB_VITALS_SPEED_INDEX)
          ?.details?.[0]?.title;
        const mobiPagespeed = data?.mobileInsights?.audits?.find((a) => a.id === CORE_WEB_VITALS_SPEED_INDEX)
          ?.details?.[0]?.title;
        const deskLargeCont = data?.desktopInsights?.audits?.find(
          (a) => a.id === CORE_WEB_VITALS_LARGEST_CONTENTFUL_PAINT,
        )?.details?.[0]?.title;
        const mobiLargeCont = data?.mobileInsights?.audits?.find(
          (a) => a.id === CORE_WEB_VITALS_LARGEST_CONTENTFUL_PAINT,
        )?.details?.[0]?.title;
        const deskInterac = data?.desktopInsights?.audits?.find((a) => a.id === CORE_WEB_VITALS_INTERACTIVE)
          ?.details?.[0]?.title;
        const mobiInterac = data?.mobileInsights?.audits?.find((a) => a.id === CORE_WEB_VITALS_INTERACTIVE)
          ?.details?.[0]?.title;
        const deskVisStab = data?.desktopInsights?.audits?.find((a) => a.id === CORE_WEB_VITALS_CUMULATIVE_LAYOUT_SHIFT)
          ?.details?.[0]?.title;
        const mobiVisStab = data?.mobileInsights?.audits?.find((a) => a.id === CORE_WEB_VITALS_CUMULATIVE_LAYOUT_SHIFT)
          ?.details?.[0]?.title;
        return `
          Website grade: ${letterGrade}
          - Video on home page: ${hpVideo}
          - Facebook link: ${hpFacebook}
          - X link: ${hpTwitter}
          - Instagram link: ${hpInstagram}
          - Pagespeed (Desktop/Mobile): ${deskPagespeed} / ${mobiPagespeed}
          - Large content load speed (Desktop/ Mobile): ${deskLargeCont} / ${mobiLargeCont}
          - Interactivity (Desktop/ Mobile): ${deskInterac} / ${mobiInterac}
          - Visual Stability (Desktop/ Mobile): ${deskVisStab} / ${mobiVisStab}
        `;
      }),
    );
  }
}
