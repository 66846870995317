import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Validator } from '@vendasta/shared';
import { GalaxyCoreInputDirective } from '../core/core-input.directive';
import { GalaxyInputValidator } from '../input.interface';

/** @deprecated - use Galaxy Field with matInput */
@Component({
  selector: 'glxy-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./../core/core.material-override.scss', './email-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailInputComponent),
      multi: true,
    },
  ],
})
export class EmailInputComponent extends GalaxyCoreInputDirective<string> implements OnInit {
  @HostBinding('class') class = 'glxy-email-input';

  @Input() hint = '';

  label = 'GALAXY.INPUT.EMAIL.LABEL';
  validators: GalaxyInputValidator[] = [
    {
      validatorFn: Validator.isEmail,
      errorMessage: 'GALAXY.INPUT.EMAIL.VALIDATION_ERROR',
    },
  ];

  ngOnInit(): void {
    this.setupControl();
  }
}
