/**
 * @param {string | number} value the value to get the ordinal suffix (e.g. st, nd, rd, th, etc.) for
 * @returns {string} the ordinal suffix
 */
export function ordinal(value: string | number): string {
  const num = Number(value);
  if (isNaN(num)) return '';

  if (num > 3 && num < 21) return 'th';

  const lastDigit = num % 10;
  return lastDigit === 1 ? 'st' : lastDigit === 2 ? 'nd' : lastDigit === 3 ? 'rd' : 'th';
}
