import { TechnologyCategoryInterface } from '../technology-category/technology';

const HARDCODED_ORDER: { [key: string]: number } = {
  Analytics: 1,
  Reviews: 2,
  Maps: 3,
  'Live chat': 4,
  Email: 5,
  Blogs: 6,
  SEO: 7,
  Advertising: 8,
  Retargeting: 9,
  'Marketing automation': 10,
  'Appointment scheduling': 11,
  CRM: 12,
  Ecommerce: 13,
  'Payment processing': 14,
  'Cart abandonment': 15,
  Security: 16,
  Authentication: 17,
  Accessibility: 18,
  Hosting: 19,
  'WordPress plugins': 20,
  'WordPress themes': 21,
  'UI frameworks': 22,
  'Mobile frameworks': 23,
  Editors: 24,
};

export function customCategorySort(a: TechnologyCategoryInterface, b: TechnologyCategoryInterface): number {
  const aOrder = HARDCODED_ORDER[a.categoryName] || Infinity;
  const bOrder = HARDCODED_ORDER[b.categoryName] || Infinity;

  if (aOrder !== bOrder) {
    return aOrder - bOrder;
  }

  return a.categoryName.localeCompare(b.categoryName);
}
