import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BrandingInterface, ConfigurationInterface } from '@vendasta/partner';
import { GetBrandingRequest, GetConfigurationRequest } from '@vendasta/partner';
import { WhitelabelApiService } from '@vendasta/partner';

@Injectable({ providedIn: 'root' })
export class WhitelabelService {
  constructor(private api: WhitelabelApiService) {}

  /**
   * @deprecated Use BrandingV2Service to get partner branding instead
   */
  getBranding(partnerId: string, marketId?: string): Observable<BrandingInterface> {
    const req = new GetBrandingRequest({ partnerId: partnerId, marketId: marketId });
    return this.api.getBranding(req).pipe(map((resp) => resp.branding));
  }

  getConfiguration(partnerId: string, marketId?: string): Observable<ConfigurationInterface> {
    const req = new GetConfigurationRequest({ partnerId: partnerId, marketId: marketId });
    return this.api.getConfiguration(req).pipe(map((resp) => resp.configuration));
  }
}
