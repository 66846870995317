interface ReviewContentInterface {
  videoId: string;
  messageId: string;
  footerMessageId: string;
}

export class ReviewContent {
  videoId: string;
  messageId: string;
  footerMessageId: string;

  constructor(content: ReviewContentInterface) {
    this.videoId = content.videoId;
    this.messageId = content.messageId;
    this.footerMessageId = content.footerMessageId;
  }
}
