import { NgModule } from '@angular/core';
import { ToggledItemComponent } from './toggled-item.component';
import { ItemToggleDirective } from './item-toggle/item-toggle.directive';
import { ItemToggleComponent } from './item-toggle/item-toggle.component';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [ToggledItemComponent, ItemToggleDirective, ItemToggleComponent],
  imports: [CommonModule, MatProgressSpinnerModule, MatCheckboxModule, MatSlideToggleModule],
  exports: [ToggledItemComponent, ItemToggleDirective, ItemToggleComponent],
})
export class ToggledItemModule {}
