interface FacebookCompetitorDataInterface {
  averageSharesPerPost?: number;
  averageLikesPerPost?: number;
  likes?: number;
  averagePostsPerMonth?: number;
}

interface TwitterCompetitorDataInterface {
  followers?: number;
  following?: number;
  tweets?: number;
}

interface InstagramCompetitorDataInterface {
  followers?: number;
  posts?: number;
}

export interface SocialCompetitorInterface {
  snapshotId: string;
  companyName: string;
  facebookData?: FacebookCompetitorData;
  twitterData?: TwitterCompetitorData;
  instagramData?: InstagramCompetitorData;
}

class FacebookCompetitorData {
  averageSharesPerPost: number;
  averageLikesPerPost: number;
  likes: number;
  averagePostsPerMonth: number;

  constructor(f: FacebookCompetitorDataInterface) {
    this.averageSharesPerPost = f.averageSharesPerPost || 0;
    this.averageLikesPerPost = f.averageLikesPerPost || 0;
    this.likes = f.likes || 0;
    this.averagePostsPerMonth = f.averagePostsPerMonth || 0;
  }
}

class TwitterCompetitorData {
  followers: number;
  following: number;
  tweets: number;

  constructor(t: TwitterCompetitorDataInterface) {
    this.followers = t.followers || 0;
    this.following = t.following || 0;
    this.tweets = t.tweets || 0;
  }
}

class InstagramCompetitorData {
  followers: number;
  posts: number;

  constructor(ig: InstagramCompetitorDataInterface) {
    this.followers = ig.followers || 0;
    this.posts = ig.posts || 0;
  }
}

export class SocialCompetitor {
  snapshotId: string;
  companyName: string;
  facebookData: FacebookCompetitorData | null;
  twitterData: TwitterCompetitorData | null;
  instagramData: InstagramCompetitorData | null;

  constructor(data: SocialCompetitorInterface) {
    this.snapshotId = data.snapshotId;
    this.companyName = data.companyName;
    this.facebookData = data.facebookData ? new FacebookCompetitorData(data.facebookData) : null;
    this.twitterData = data.twitterData ? new TwitterCompetitorData(data.twitterData) : null;
    this.instagramData = data.instagramData ? new InstagramCompetitorData(data.instagramData) : null;
  }
}
