import { Injectable, OnDestroy } from '@angular/core';
import { AbstractSectionService } from '../section/section.service';
import { ContentService } from '../snapshot-report/content.service';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { Observable, combineLatest } from 'rxjs';
import { SectionInterface } from '../section/section';
import { map } from 'rxjs/operators';
import { GradeExplanationData } from '../grade/grade-explainer-dialog.component';
import { EcommerceSectionService } from '@vendasta/snapshot';
import { EcommerceContent } from './ecommerce.content';
import { EcommerceData } from './ecommerce.data';
import { EcommerceConfig, EcommerceConfigInterface } from './ecommerce.config';
import { APPLICATIONS_AVAILABILITY } from './ecommerce.data';
import { gradeToLetter } from '../common/utils';
import { bool2found } from '../common/ai-utils';

@Injectable()
export class EcommerceService
  extends AbstractSectionService<EcommerceContent, EcommerceData, EcommerceConfigInterface>
  implements OnDestroy
{
  sectionId = 'ecommerce';

  constructor(private _api: EcommerceSectionService, private _cs: ContentService, private _ss: SnapshotReportService) {
    super(_cs, _ss);
  }

  createGradeSubscription(): void {
    this.subscriptions.push(this.grade$.subscribe((g) => this._ss.setEcommerceGrade(g)));
  }

  load(): Observable<SectionInterface> {
    return this._api.get(this.snapshotId).pipe(
      map((section) => {
        return {
          grade: section.grade,
          config: new EcommerceConfig(section.config || {}),
          data: new EcommerceData(section.data),
          content: new EcommerceContent(section.content),
        };
      }),
    );
  }

  _updateConfig(config: EcommerceConfigInterface): Observable<boolean> {
    return this._api.updateConfig(this.snapshotId, config);
  }

  newConfig(): EcommerceConfigInterface {
    return new EcommerceConfig({});
  }

  getGradeExplanationData(): GradeExplanationData {
    return {
      titleTextID: 'ECOMMERCE.GRADE_EXPLANATION.TITLE',
      mobileTitleTextID: 'ECOMMERCE.GRADE_EXPLANATION.MOBILE_TITLE',
      primaryTextID: 'ECOMMERCE.GRADE_EXPLANATION.PRIMARY',
      clarificationTextID: 'ECOMMERCE.GRADE_EXPLANATION.CLARIFICATION',
      secondaryPreScaleTextID: 'ECOMMERCE.GRADE_EXPLANATION.SECONDARY_PRE_SCALE',
      showThermometer: true,
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getAiPromptDetails(): Observable<string> {
    return combineLatest([this._ss.ecommerceSectionEnabled$, this.data$, this.grade$]).pipe(
      map(([sectionEnabled, data, grade]) => {
        if (!sectionEnabled) {
          return '';
        }
        const letterGrade = gradeToLetter(grade);
        const onlineStorefront = bool2found(
          data?.applicationsAvailability?.[APPLICATIONS_AVAILABILITY.ecommerceSolution],
        );
        const onlinePayments = bool2found(data?.applicationsAvailability?.[APPLICATIONS_AVAILABILITY.onlinePayments]);
        const leadEngagement = bool2found(data?.applicationsAvailability?.[APPLICATIONS_AVAILABILITY.adRetargeting]);
        const onlineScheduler = bool2found(
          data?.applicationsAvailability?.[APPLICATIONS_AVAILABILITY.appointmentScheduling],
        );
        return `
          eCommerce grade: ${letterGrade}
          - Online storefront: ${onlineStorefront}
          - Online payments: ${onlinePayments}
          - Lead engagement: ${leadEngagement}
          - Online Scheduler: ${onlineScheduler}
        `;
      }),
    );
  }
}
