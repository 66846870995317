<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>
      {{ 'EDIT_REPORT.COMPETITORS' | translate }}
    </mat-card-title>
    <mat-card-subtitle>
      {{
        'COMPETITORS.COMPETITORS_SECTION_DESCRIPTION'
          | translate : { snapshotReportsLabel: snapshotName$ | async | translate }
      }}
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div *ngIf="isExpired$ | async" class="expired-alert">
      <glxy-alert type="error">
        {{ 'COMPETITORS.SNAPSHOT_EXPIRED' | translate : { snapshotName: snapshotName$ | async | translate } }}
        <a (click)="openRefreshDialog()">{{ 'COMPETITORS.REFRESH_REPORT' | translate }}</a>
      </glxy-alert>
    </div>

    <mat-radio-group [(ngModel)]="selectedCompetitorType" [disabled]="(isExpired$ | async) && !allowEditCompetitors">
      <mat-radio-button
        class="radio-button"
        *ngFor="let option of competitorsAnalysisOptions; let i = index"
        [value]="i"
        (change)="onCompetitiveAnalysisChange(i)"
      >
        {{ option | translate : { snapshotReportsLabel: ('TITLES.SNAPSHOT_REPORTS' | translate) } }}
      </mat-radio-button>
    </mat-radio-group>

    <ng-container [ngSwitch]="selectedCompetitorType">
      <ng-container *ngSwitchCase="COMPETITOR_TYPE.KEYWORD_COMPETITORS">
        <ng-container *ngIf="(isExpired$ | async) === false; else keywordCompetitors">
          <snap-account-group-competitors
            [businessId]="this.snapshotService.businessId$ | async"
            [isExpired]="(isExpired$ | async) && !allowEditCompetitors"
            (userEvent)="handleUserEventEmitted($event)"
          ></snap-account-group-competitors>
        </ng-container>
        <ng-template #keywordCompetitors>
          <snap-keyword-competitors
            [isExpired]="(isExpired$ | async) && !allowEditCompetitors"
            (userEvent)="handleUserEventEmitted($event)"
          ></snap-keyword-competitors>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="COMPETITOR_TYPE.DIRECT_COMPETITORS">
        <snap-direct-competitors
          [isExpired]="(isExpired$ | async) && !allowEditCompetitors"
          (userEvent)="handleUserEventEmitted($event)"
        ></snap-direct-competitors>
      </ng-container>
    </ng-container>
  </mat-card-content>
</mat-card>
