import { Injectable } from '@angular/core';
import { MeetingType, GuestService } from '@vendasta/meetings';
import { Observable, of as observableOf } from 'rxjs';
import { shareReplay, switchMap, catchError, take, map } from 'rxjs/operators';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { ANALYTICS_CATEGORY } from './schedule-meeting';
import { SnapshotReportService } from '@galaxy/snapshot';
import { SalesPersonService } from '../../services';

@Injectable()
export class ScheduleMeetingService {
  isHostConfigured$: Observable<boolean>;
  meetingTypeList$: Observable<MeetingType[]>;

  constructor(
    private guestService: GuestService,
    private readonly salesPersonService: SalesPersonService,
    public analyticsService: ProductAnalyticsService,
    public snapshotReportService: SnapshotReportService,
  ) {
    this.isHostConfigured$ = this.salesPersonService.salesPerson$.pipe(
      take(1),
      switchMap((salesperson) => {
        if (salesperson) {
          return this.guestService.isHostConfigured({ hostId: salesperson.salesPersonId });
        }
        return observableOf(false);
      }),
      map((c) => !!c),
      catchError(() => observableOf(false)),
      shareReplay(1),
    );

    this.meetingTypeList$ = this.salesPersonService.salesPerson$.pipe(
      take(1),
      switchMap((salesperson) => {
        if (salesperson) {
          return this.guestService
            .listMeetingTypes({ hostId: salesperson.salesPersonId })
            .pipe(map((meetings) => meetings.sort((a, b) => parseInt(a.duration, 10) - parseInt(b.duration, 10))));
        }
        return observableOf([]);
      }),
      catchError(() => observableOf([])),
      shareReplay(1),
    );
  }

  openBookingUrl(url: string): void {
    if (url === '') {
      return;
    }

    this.analyticsService.trackEvent('open-booking-url', ANALYTICS_CATEGORY, 'click', 0, {
      snapshotId: this.snapshotReportService.snapshotId,
    });

    url =
      url +
      '?utm_source=snapshot' +
      '&msm_snapshot=' +
      this.snapshotReportService.snapshotId +
      '&msm_business=' +
      this.snapshotReportService.businessId +
      '&msm_partner=' +
      this.snapshotReportService.partnerId;
    window.open(url, '_blank');
  }
}
