<mat-card-content>
  <div class="prompt-container">
    <hr />
    <div class="prompt">
      {{
        'COMPETITORS.DIRECT_COMPETITORS.DESCRIPTION_2'
          | translate : { snapshotReportsLabel: ('TITLES.SNAPSHOT_REPORTS' | translate) }
      }}
    </div>
  </div>

  <ng-container *ngIf="businesses$ | async as businesses">
    <form [formGroup]="directCompetitorsForm" (ngSubmit)="onSubmit(directCompetitorsForm.value)" class="form">
      <div formArrayName="competitors" *ngFor="let competitor of directFormCompetitors.controls; let i = index">
        <div class="row">
          <mat-form-field class="autocomplete col">
            <mat-label>{{ 'COMPETITORS.COMPETITOR' | translate }} {{ i + 1 }}</mat-label>
            <button mat-icon-button type="button" class="grey-icon" (click)="clear(i)" matSuffix>
              <mat-icon>close</mat-icon>
            </button>
            <input
              matInput
              [matAutocomplete]="auto"
              formControlName="{{ i }}"
              (focus)="resetAutocomplete(competitor.value)"
            />
            <mat-error *ngIf="competitor.hasError('isInvalid')">
              {{ 'ERRORS.DIRECT_COMPETITOR_INVALID' | translate }}
              <mat-icon class="error-icon">error</mat-icon>
            </mat-error>
            <mat-error *ngIf="competitor.hasError('duplicatedOption')">
              {{ 'ERRORS.DIRECT_COMPETITOR_DUPLICATED' | translate }}
              <mat-icon class="error-icon">error</mat-icon>
            </mat-error>

            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayName"
              showPanel="true"
              (optionsScroll)="onScroll()"
            >
              <mat-option *ngFor="let business of businesses" [value]="business">
                <span>{{ business.name }}</span>
                |
                <span>{{ business.address ? business.address + ' | ' : '' }}</span>
                <small>
                  {{ business.snapshotCreated | date : 'MMM d' }}
                </small>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="form-element row">
        <button
          mat-flat-button
          color="primary"
          type="submit"
          class="button update-competitors"
          [disabled]="isExpired || isUpdatingCompetitors || directCompetitorsForm.invalid"
        >
          <span *ngIf="!isUpdatingCompetitors">
            {{ 'COMPETITORS.SAVE_BUTTON' | translate }}
          </span>
          <span *ngIf="isUpdatingCompetitors">
            <mat-progress-spinner mode="indeterminate" diameter="24"></mat-progress-spinner>
          </span>
        </button>
        <mat-hint>
          <span class="footer-hint">
            {{ 'COMPETITORS.UPDATE_COMPETITORS_DISCLAIMER' | translate }}
          </span>
        </mat-hint>
      </div>
    </form>
  </ng-container>
</mat-card-content>
