<snap-subsection
  *ngIf="snapshotService.snapshotData$ | async as snapshotData"
  heading="Instagram"
  [hideControl]="controls.controls['hideInstagram']"
  [edit]="edit"
>
  <div subheading *ngIf="instagramTableItems$ | async as instagramTableItems">
    {{ 'SOCIAL.COMMON_SUBHEADING' | translate }}
    <a
      *ngIf="snapshotData?.instagramUrl && instagramTableItems.length > 0; else instagramLinkText"
      class="instagram-url"
      href="{{ snapshotData?.instagramUrl }}"
      target="_blank"
      rel="nofollow"
    >
      <ng-container [ngTemplateOutlet]="instagramLinkText"></ng-container>
    </a>
    <ng-template #instagramLinkText>
      {{ 'SOCIAL.INSTAGRAM.LINK_TEXT' | translate }}
    </ng-template>
  </div>
  <ng-container
    *ngIf="!isInstagramWorking && (instagramStatus$ | async) !== inProgressStatus; else stillWorkingTemplate"
  >
    <mat-progress-bar mode="indeterminate" *ngIf="instagramUrlControl.pending"></mat-progress-bar>

    <ng-container *ngIf="media.mobile$ | async; else desktopView">
      <ng-container *ngIf="competitorInstagramTableItems$ | async as items; else industryMobileView">
        <ng-container *ngIf="items.length > 0 && items[0].competitors?.length > 0; else industryMobileView">
          <snap-mobile-view-data
            [hideGrades]="snapshotService.hideSubGrades$ | async"
            [edit]="edit"
            [subsections]="items"
          ></snap-mobile-view-data>
        </ng-container>
      </ng-container>
      <ng-template #industryMobileView>
        <snap-mobile-view-data
          [hideGrades]="snapshotService.hideSubGrades$ | async"
          [edit]="edit"
          [subsections]="instagramTableItems$ | async"
        ></snap-mobile-view-data>
      </ng-template>
    </ng-container>

    <ng-template #desktopView>
      <ng-container *ngIf="competitorInstagramTableItems$ | async as items; else appGradeTable">
        <ng-container *ngIf="items.length > 0 && items[0].competitors?.length > 0; else appGradeTable">
          <snap-table
            [editMode]="edit"
            [items]="items"
            [showIndustryAverage]="true"
            [hideGrades]="snapshotService.hideSubGrades$ | async"
            [colorTextYourBusiness]="true"
          ></snap-table>
        </ng-container>
      </ng-container>
      <ng-template #appGradeTable>
        <ng-container *ngIf="instagramTableItems$ | async as instagramTableItems; else notFound">
          <snap-table
            *ngIf="instagramTableItems.length > 0; else notFound"
            [editMode]="edit"
            [items]="instagramTableItems"
            [showIndustryAverage]="true"
            [showIndustryLeader]="true"
            [hideGrades]="snapshotService.hideSubGrades$ | async"
            [colorTextYourBusiness]="true"
          ></snap-table>
        </ng-container>
      </ng-template>
    </ng-template>

    <ng-template #notFound>
      <div class="not-found" *ngIf="instagramTableItems$ | async as instagramTableItems">
        <ng-container *ngIf="instagramTableItems.length === 0">
          <ng-container *ngIf="snapshotData?.instagramUrl === ''; else instagramErrorsTemplate">
            <glxy-empty-state [size]="'small'" [widthConstraint]="false">
              <glxy-empty-state-hero>
                <div class="image">
                  <img [src]="'https://www.cdnstyles.com/static/images/icon50/sourceId-11610.png'" />
                </div>
              </glxy-empty-state-hero>
              <glxy-empty-state-title>
                {{ 'SOCIAL.INSTAGRAM.NO_PAGE_ERROR_TITLE' | translate }}
              </glxy-empty-state-title>
              <p>{{ 'SOCIAL.INSTAGRAM.NO_PAGE_ERROR_DESCRIPTION' | translate }}</p>
              <ng-container *ngIf="(snapshotService.isExpired$ | async) === false && edit">
                <glxy-empty-state-actions>
                  <div class="form-field">
                    <glxy-input
                      dense
                      class="social-url"
                      [formControl]="instagramUrlControl"
                      (keyup.enter)="addInstagramUrl()"
                      placeholder="{{ 'SOCIAL.INSTAGRAM.URL' | translate }}"
                    ></glxy-input>

                    <div class="submit-button">
                      <a
                        mat-stroked-button
                        [disabled]="!instagramUrlControl.valid || instagramUrlControl.pending"
                        (click)="addInstagramUrl()"
                      >
                        <mat-icon>arrow_forward</mat-icon>
                      </a>
                    </div>
                  </div>
                </glxy-empty-state-actions>
              </ng-container>
            </glxy-empty-state>
          </ng-container>
        </ng-container>
        <ng-template #instagramErrorsTemplate>
          @switch (instagramStatus$ | async) {
            @case (invalidUserIdStatus) {
              <ng-container *ngTemplateOutlet="invalidUserTemplate"></ng-container>
            }
            @case (reelDetectedStatus) {
              <ng-container *ngTemplateOutlet="profileNotFoundTemplate"></ng-container>
            }
            @default {
              <ng-container *ngTemplateOutlet="unknownInstagramErrorTemplate"></ng-container>
            }
          }
        </ng-template>
      </div>
    </ng-template>
  </ng-container>
</snap-subsection>

<ng-template #stillWorkingTemplate>
  <snap-still-working></snap-still-working>
</ng-template>

<ng-template #notInstagramBusinessPage>
  <div class="not-found">
    <mat-icon>watch_later</mat-icon>
    <div class="error-title">
      {{ 'SOCIAL.INSTAGRAM.NOT_BUSINESS_TITLE' | translate }}
    </div>
    <p>{{ 'SOCIAL.INSTAGRAM.NOT_BUSINESS_DESCRIPTION' | translate }}</p>
  </div>
</ng-template>

<ng-template #invalidUserTemplate>
  <ng-container>
    <glxy-empty-state [size]="'small'" [widthConstraint]="false">
      <glxy-empty-state-hero>
        <div class="image">
          <img [src]="'https://www.cdnstyles.com/static/images/icon50/sourceId-11610.png'" />
        </div>
      </glxy-empty-state-hero>
      <glxy-empty-state-title>
        {{ 'SOCIAL.INSTAGRAM.NOT_BUSINESS_TITLE' | translate }}
      </glxy-empty-state-title>
      <p
        [innerHtml]="
          'SOCIAL.INSTAGRAM.NOT_BUSINESS_DESCRIPTION'
            | translate
              : {
                  instagramPageUrl: (snapshotService.snapshotData$ | async)?.instagramUrl,
                }
        "
      ></p>
      <glxy-empty-state-actions>
        <a mat-stroked-button href="https://help.instagram.com/1714412405508502" target="_blank" rel="nofollow">
          {{ 'COMMON.LEARN_MORE' | translate }}
        </a>
      </glxy-empty-state-actions>
    </glxy-empty-state>
  </ng-container>
</ng-template>

<ng-template #profileNotFoundTemplate>
  <ng-container>
    <glxy-empty-state [size]="'small'" [widthConstraint]="false">
      <glxy-empty-state-hero>
        <div class="image">
          <img [src]="'https://www.cdnstyles.com/static/images/icon50/sourceId-11610.png'" />
        </div>
      </glxy-empty-state-hero>
      <glxy-empty-state-title>
        {{ 'SOCIAL.INSTAGRAM.PROFILE_NOT_FOUND_TITLE' | translate }}
      </glxy-empty-state-title>
      <p [innerHtml]="'SOCIAL.INSTAGRAM.PROFILE_NOT_FOUND_DESCRIPTION' | translate"></p>
      <glxy-empty-state-actions>
        <a mat-stroked-button href="https://help.instagram.com/1714412405508502" target="_blank" rel="nofollow">
          {{ 'COMMON.LEARN_MORE' | translate }}
        </a>
      </glxy-empty-state-actions>
    </glxy-empty-state>
  </ng-container>
</ng-template>

<ng-template #unknownInstagramErrorTemplate>
  <glxy-empty-state [size]="'small'" [widthConstraint]="false">
    <glxy-empty-state-hero>
      <mat-icon>info</mat-icon>
    </glxy-empty-state-hero>
    <glxy-empty-state-title>
      {{ 'ERRORS.ERROR_LOADING_TITLE' | translate }}
    </glxy-empty-state-title>
  </glxy-empty-state>
</ng-template>
