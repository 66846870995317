import { Component, Input } from '@angular/core';
import { BaseHeaderComponent } from '../section/base-header.component';
import { AdvertisingService } from './advertising.service';

@Component({
  selector: 'snap-advertising-section-header',
  template: `
    <snap-grade-tagline
      [hideGrade]="hideGrade"
      [grade]="service.grade$ | async"
      heading="{{ 'TITLES.ADVERTISING' | translate }}"
      tagline="{{ 'ADVERTISING.TAGLINE' | translate }}"
    ></snap-grade-tagline>
  `,
  styleUrls: ['./advertising-section.component.scss'],
})
export class AdvertisingSectionHeaderComponent extends BaseHeaderComponent {
  @Input() hideGrade: boolean;

  constructor(public service: AdvertisingService) {
    super(service);
  }
}
