import { Inject, Injectable } from '@angular/core';
import { Observable, timer, throwError, BehaviorSubject, of, combineLatest } from 'rxjs';
import { CurrentSnapshotService, Origin, SnapshotService } from '@vendasta/snapshot';
import { catchError, filter, first, switchMap, take, tap, map, publishReplay, refCount } from 'rxjs/operators';
import { GetCurrentResponseInterface } from '@vendasta/snapshot';
import { SnapshotReportService } from '../snapshot-report/snapshot-report.service';
import { RefreshDialogComponent, RefreshResults } from './refresh-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { TranslateService } from '@ngx-translate/core';
import { SNAPSHOT_NAME_TOKEN } from '../../sections/providers/providers';
import { PoolTimeoutError } from '../common/pool-timeout-error.model';

interface RefreshData {
  snapshotID: string;
  businessID: string;
}

@Injectable()
export class RefreshReportService {
  provisioningInProgress$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public provisioningInProgress$: Observable<boolean>;
  private snapshotID$: Observable<string>;
  private businessID$: Observable<string>;
  private refreshData$: Observable<RefreshData>;

  constructor(
    private snapshotAPIService: SnapshotService,
    private currentSnapshotService: CurrentSnapshotService,
    public snapshotReportService: SnapshotReportService,
    public dialog: MatDialog,
    public snackbarService: SnackbarService,
    public translateService: TranslateService,
    @Inject(SNAPSHOT_NAME_TOKEN) readonly snapshotName$: Observable<string>,
  ) {
    this.provisioningInProgress$ = this.provisioningInProgress$$.asObservable();

    this.snapshotID$ = this.snapshotReportService.snapshotId$;
    this.businessID$ = this.snapshotReportService.businessId$;
    this.refreshData$ = combineLatest([this.snapshotID$, this.businessID$]).pipe(
      map(([s, b]) => {
        return <RefreshData>{ snapshotID: s, businessID: b };
      }),
      publishReplay(1),
      refCount(),
    );
  }

  openRefreshDialog(): void {
    this.refreshData$.pipe(take(1)).subscribe((sb) => {
      this.dialog
        .open(RefreshDialogComponent, {
          width: '400px',
          data: {
            snapshotName$: this.snapshotName$,
            snapshotID: sb.snapshotID,
            businessID: sb.businessID,
          },
        })
        .afterClosed()
        .subscribe((result: RefreshResults) => {
          if (result) {
            if (result.snapshotID) {
              window.location.href = `/snapshot/${result.snapshotID}/edit`;
            } else if (result.error) {
              this.snackbarService.openErrorSnack('REFRESH_REPORT.ERROR_PROVISIONING');
            } else {
              // this is a fallback in case the new report does not get provisioned in a reasonable amount of time
              this.snackbarService.openSuccessSnack('REFRESH_REPORT.REOPEN');
            }
          }
        });
    });
  }

  startPolling(businessID: string): Observable<GetCurrentResponseInterface> {
    const pollAttempts = 10;
    const poller$ = timer(2000, 2000).pipe(take(pollAttempts + 1));

    return poller$.pipe(
      switchMap((attempt) =>
        attempt >= pollAttempts
          ? throwError(() => new PoolTimeoutError('max attempts'))
          : this.currentSnapshotService.get(businessID).pipe(catchError(() => of(null))),
      ),
      filter((snap: GetCurrentResponseInterface) => {
        if (!snap) {
          return false;
        }
        const now = new Date();
        // a newly provisioned snapshot will have an expiry in the future
        return snap.snapshot?.expired > now;
      }),
      tap(() => this.provisioningInProgress$$.next(false)),
      first(),
    );
  }

  provision(businessID: string, snapshotID: string): Observable<string> {
    return this.snapshotAPIService
      .provision(businessID, Origin.SNAPSHOT, [snapshotID], true)
      .pipe(tap(() => this.provisioningInProgress$$.next(true)));
  }
}
