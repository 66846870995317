import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isCustomProposalSnapshotSection, ProposalSnapshotSection } from '../select-snapshot-content';

@Component({
  selector: 'snap-document-snapshot-section-grade',
  templateUrl: './section-grade.component.html',
  styleUrls: ['./section-grade.component.scss'],
})
export class SectionGradeComponent {
  @Input() section: ProposalSnapshotSection;
  @Input() showGrade: boolean;
  @Output() selectionChanged: EventEmitter<boolean> = new EventEmitter();
  isCustomProposalSnapshotSection = isCustomProposalSnapshotSection;

  selectionChangedEvent(selection: boolean): void {
    this.selectionChanged.next(selection);
  }
}
