import { Component, Inject, Input, OnChanges } from '@angular/core';
import { FormatType, SectionTableItem } from '../../section-table/section-table';
import { Media, MediaToken } from '../../providers/providers';
import { SEODomainMetrics } from '../seo-data';

@Component({
  selector: 'snap-organic-keywords',
  templateUrl: 'organic-keywords.component.html',
})
export class OrganicKeywordsComponent implements OnChanges {
  @Input() business: SEODomainMetrics;
  @Input() competitors: SEODomainMetrics[] = [];
  @Input() showOverlap = false;

  public readonly OVERLAP_SCALE = 91;

  competitorItems: SectionTableItem[] = [];
  showDomainMetrics = true;

  constructor(@Inject(MediaToken) public readonly media: Media) {}

  ngOnChanges(): void {
    this.showDomainMetrics = !this.hasDefaultSEODomainMetrics(this.business);

    this.competitorItems = [];
    this.competitorItems.push({
      title: 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.ORG_RANKED_SEARCH_TERMS',
      tooltip: 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.TOOLTIPS.KEYWORDS',
      labelFormat: FormatType.Link,
      data: {
        business: this.business.numberOfOrganicKeywords,
        link: this.business.domainName,
      },
      competitors: this.competitors.map((c) => {
        return {
          name: c.domainName,
          value: this.hasDefaultSEODomainMetrics(c) ? null : c.numberOfOrganicKeywords,
        };
      }),
    } as SectionTableItem);
    this.competitorItems.push({
      title: 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.ESTIMATED_SEARCH_TRAFFIC',
      tooltip: 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.TOOLTIPS.CLICKS',
      labelFormat: FormatType.Link,
      data: {
        business: this.business.organicClicksPerMonth,
        link: this.business.domainName,
      },
      competitors: this.competitors.map((c) => {
        return {
          name: c.domainName,
          value: this.hasDefaultSEODomainMetrics(c) ? null : c.organicClicksPerMonth,
        };
      }),
    } as SectionTableItem);
    this.competitorItems.push({
      title: 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.EQUI_COST_PAID_TRAFFIC',
      tooltip: 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.TOOLTIPS.VALUE',
      dataFormat: FormatType.Currency,
      labelFormat: FormatType.Link,
      data: {
        business: this.business.dailyOrganicTrafficValue,
        link: this.business.domainName,
      },
      competitors: this.competitors.map((c) => {
        return {
          name: c.domainName,
          value: this.hasDefaultSEODomainMetrics(c) ? null : c.dailyOrganicTrafficValue,
        };
      }),
    } as SectionTableItem);
    this.competitorItems.push({
      title: 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.SEO_VALUE_PER_CLICK',
      tooltip: 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.TOOLTIPS.VALUE_PER_CLICK',
      dataFormat: FormatType.Currency,
      labelFormat: FormatType.Link,
      data: {
        business: this.business.valuePerClick,
        link: this.business.domainName,
      },
      competitors: this.competitors.map((c) => {
        return {
          name: c.domainName,
          value: this.hasDefaultSEODomainMetrics(c) ? null : c.valuePerClick,
        };
      }),
    } as SectionTableItem);
    if (this.showOverlap) {
      this.competitorItems.push({
        title: 'COMMON.OVERLAP',
        tooltip: 'LOCAL_SEO.ORGANIC_KEYWORDS.PERFORMANCE.TOOLTIPS.OVERLAP',
        dataFormat: FormatType.Bar,
        labelFormat: FormatType.Link,
        data: {
          business: this.business.overlap * this.OVERLAP_SCALE,
          link: this.business.domainName,
        },
        competitors: this.competitors.map((c) => {
          return {
            name: c.domainName,
            value: this.hasDefaultSEODomainMetrics(c) ? null : c.overlap * this.OVERLAP_SCALE,
          };
        }),
      } as SectionTableItem);
    }
  }

  hasDefaultSEODomainMetrics(organicDomainMetrics: SEODomainMetrics): boolean {
    return (
      organicDomainMetrics.numberOfOrganicKeywords === 0 &&
      organicDomainMetrics.organicClicksPerMonth === 0 &&
      organicDomainMetrics.dailyOrganicTrafficValue === 0
    );
  }
}
