<div class="try-again-container">
  <glxy-empty-state [size]="'small'" [widthConstraint]="false">
    <glxy-empty-state-hero>
      <mat-icon>search</mat-icon>
    </glxy-empty-state-hero>
    <glxy-empty-state-title>
      {{ 'ERRORS.ERROR_LOADING_TITLE' | translate }}
    </glxy-empty-state-title>
    <p>{{ 'ERRORS.ERROR_LOADING_MESSAGE' | translate }}</p>
    <div class="submit-button">
      <button mat-stroked-button (click)="refreshSection()">
        {{ 'COMMON.TRY_AGAIN' | translate }}
      </button>
    </div>
  </glxy-empty-state>
</div>
