import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { SessionService } from '@galaxy/core';
import { ContactsService as ContactsApiService } from '@vendasta/sales-v2';

export interface Contact {
  name: string;
  title: string;
  phoneNumber: string;
  email: string;
}

@Injectable()
export class ContactsService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private contactsService: ContactsApiService,
  ) {}

  listContacts(businessId: string, partnerId: string, marketId: string): Observable<Contact[]> {
    return this.contactsService.getContactsForAccounts(partnerId, marketId, [businessId]).pipe(
      map((res) =>
        res.contacts.map((c) => {
          return <Contact>{
            phoneNumber: c.phoneNumber ?? '',
            email: c.contactEmail ?? '',
            name: [c.firstName, c.lastName].filter(Boolean).join(' '),
            title: c.title ?? '',
          };
        }),
      ),
      shareReplay(),
    );
  }
}
