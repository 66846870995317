<div class="overall-score-container" *ngIf="overallScore">
  <svg
    [attr.width]="120 + 'px'"
    [attr.height]="120 + 'px'"
    viewBox="0 0 38 38"
    class="donut"
  >
    <circle
      class="donut-hole"
      cx="19"
      cy="19"
      r="15.91549430918952"
      fill="#fff"
    ></circle>
    <text class="score-text" text-anchor="middle" x="19" y="23">
      {{ score + '%' }}
    </text>
    <circle
      class="donut-ring"
      cx="19"
      cy="19"
      r="15.91549430918952"
      fill="transparent"
      stroke="#efefef"
      [attr.stroke-width]="3"
    ></circle>
    <circle
      class="donut-segment"
      cx="19"
      cy="19"
      r="15.91549430918952"
      fill="transparent"
      [attr.stroke]="strokeColorForScore(score)"
      [attr.stroke-width]="3"
      [attr.stroke-dasharray]="score + ' ' + (100 - score)"
      stroke-dashoffset="25"
    ></circle>
  </svg>
</div>
