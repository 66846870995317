export function errorCanBeHandled(error): boolean {
  if (error.status !== 403) {
    return false;
  }
  return !!(error.status === 403 && error.error?.details[0]?.failures[0]);
}

export function findAccessibleAccountGroupIds(error: any, allAccountGroups: string[]): string[] {
  const failedAccountGroupIds = [];
  error.error.details[0].failures.forEach((ag) => {
    if (ag.identifiers?.account_group_id?.values) {
      failedAccountGroupIds.push(ag.identifiers.account_group_id.values[0]);
    } else {
      return [];
    }
  });

  const validAccountGroupIds = [];
  allAccountGroups.filter((ag) => {
    if (failedAccountGroupIds.find((a) => a === ag) === undefined) {
      validAccountGroupIds.push(ag);
    }
  });

  return validAccountGroupIds;
}
